import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { useLocation, generatePath } from 'react-router';

import Button from '@Components/Button';
import Footer from '@Components/Footer';
import ErrorDisplay from '@Components/form/inputs/ErrorDisplay';
import headerStyles from '@Components/Header/Header.scss';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Container from '@Components/layout/Container';
import LoadingSpinner from '@Components/LoadingSpinner';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  usePortalUserAccessCodeQuery,
  PortalUserUpdateMfaMutation,
  PortalUserUpdateMfaMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationPortalUserUpdateMfa } from '@Graphql/serviceProvidersPortal/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './ConfigurationMFA.scss';

export interface ConfigurationMFAProps {}

interface ConfigurationMFAPrivateProps extends ConfigurationMFAProps {}

const ConfigurationMFA: React.FunctionComponent<ConfigurationMFAPrivateProps> = () => {
  const t = useTranslations();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const accessCode = searchParams.get('access_code') || '';

  const [updateUserMfa] = useMutation<PortalUserUpdateMfaMutation, PortalUserUpdateMfaMutationVariables>(
    mutationPortalUserUpdateMfa,
    {
      onCompleted: (response: any) => {
        dispatch(notificationSuccess(t(response.portalUserUpdateMfa.message)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const { data, loading, error } = usePortalUserAccessCodeQuery({
    variables: {
      accessCode,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <Col size={8}>
        <LoadingSpinner />
      </Col>
    );
  }

  return (
    <>
      <Container style={{ marginTop: '30px' }}>
        <div className={headerStyles.logoContainer}>
          <a href={Links.lifeLedger}>
            <Icon icon="logo-full" size={IconSizes.logoFull} />
          </a>
        </div>
        <div className={styles.titleContainer}>
          <TitleWithSubtitle
            title={t(Messages.titleConfigureMfa)}
            subtitle={t(Messages.subtitleConfigureMfa)}
            userSection
          />
        </div>
        {error || data?.portalUserAccessCode.mfaUrl === null ? (
          <ErrorDisplay msg={normaliseGqlError(error ? error.message : '')} removePositionCss />
        ) : (
          <QRCode value={data?.portalUserAccessCode.mfaUrl || ''} level="H" size={128} />
        )}
        <div className={styles.linkContainer}>
          {error && (
            <Button
              label={t(Messages.linkRaiseNewRequest)}
              onClick={() =>
                updateUserMfa({
                  variables: {
                    input: {
                      accessCode,
                    },
                  },
                })
              }
            />
          )}
          {error === undefined && <Button label={t(Messages.buttonLogin)} onClick={() => generatePath(Links.login)} />}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ConfigurationMFA;
