import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonSizes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import { LocalStorage } from '@Config/constants';
import { logout } from '@Store/auth/auth.actions';
import { deleteFromLocalStorage } from '@Utils/localStorage';

import { ModalProviderProps } from '../Modal';

import styles from './SaveChangesModalBody.scss';

export interface SaveChangesModalProps {
  path: string;
  subpage?: string;
  id?: string;
  isLogout?: boolean;
  onClose?: () => void;
}

const SaveChangesModalBody: React.FunctionComponent<ModalProviderProps<SaveChangesModalProps>> = ({
  closeModal,
  modalData: { path, subpage, id, isLogout, onClose },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <Icon icon="alert-triangle" size={IconSizes.m} className={styles.iconColor} />
      <Typography size="xl" msg="Changes not saved" tag="h4" bold />
      <Typography
        msg="You have not saved the changes that you have made on this page, woud you like the save them before leaving?"
        tag="div"
        size="m"
        className={styles.bottomSpacing}
      />
      <Button onClick={() => closeModal()} className={styles.mb33} label="Stay on page" size={ButtonSizes.fill} />
      <LinkButton
        linkTitle="Leave without saving changes"
        onClick={() => {
          deleteFromLocalStorage(LocalStorage.settingFormChanged);
          closeModal();
          if (onClose) {
            onClose();
          } else if (isLogout) {
            dispatch(logout());
          } else if (subpage) {
            history.push(generatePath(path, { subpage }));
          } else {
            history.push(generatePath(path, { id }));
          }
        }}
        className={styles.linkFont}
      />
    </div>
  );
};

export default SaveChangesModalBody;
