import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from '@Components/Icon';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import { getUser } from '@Store/auth/auth.selector';

import styles from './Breadcrumb.scss';

export interface BreadcrumbItem {
  title: string;
  link?: string;
}

export interface BreadcrumbProps {
  breadcrumbItems?: BreadcrumbItem[];
  dashboardText?: string;
}

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({ breadcrumbItems, dashboardText = 'Dashboard' }) => {
  const user = useSelector(getUser);

  const dashboardLink = (): string => {
    if (user?.accountType === UserAccountType.ServiceProvider) {
      return Links.providerDashboard;
    }
    if (user?.isStaff) {
      return Links.staffDashboard;
    }
    return Links.home;
  };

  const homeItem: BreadcrumbItem = { title: dashboardText, link: dashboardLink() };
  const fullList = breadcrumbItems ? [homeItem].concat(breadcrumbItems) : [homeItem];

  return (
    <ul className={styles.breadcrumbNavigation}>
      {fullList.map((headerItem, idx) => {
        const { title, link } = headerItem;
        const element = <Typography className={styles.elementText} tag="span" size="l" msg={title} color="newGray" />;
        return (
          <li key={idx} className={styles.breadcrumbItem}>
            {link ? (
              <>
                <Link to={link} className={styles.link}>
                  {element}
                </Link>
                <Icon icon="chevronRight" className={styles.chevronIcon} />
              </>
            ) : (
              <span className={styles.makeBold}>{element}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumb;
