import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Button, { ButtonTypes } from '@Components/Button';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  ServiceProviderInvitationNode,
  ProfessionalPortalInvitationNode,
  UserAccountType,
  useServiceProviderPortalUsersLazyQuery,
  useServiceProviderUserInvitationsLazyQuery,
  useProfessionalPortalUsersLazyQuery,
  useProfessionalPortalUserInvitationsLazyQuery,
  useAgentPortalUsersLazyQuery,
  useAgentPortalUserInvitationsLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import CaseSummaryContainer from '@Routes/provider/AccountManagement/AccountManagementForm/UsersPage/CaseSummary/CaseSummaryContainer';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { getUser } from '@Store/auth/auth.selector';
import { addUserHandler } from '@Utils/modal';

import layoutStyles from '../AccountPage/AccountForm/AccountForm.scss';

import styles from './UsersPage.scss';

export interface UsersPageProps {}

const UsersPage: React.FunctionComponent<UsersPageProps> = () => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const [getSpUsers, spUsers] = useServiceProviderPortalUsersLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getSpUserInvites, spUserInvites] = useServiceProviderUserInvitationsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getProfUsers, profUsers] = useProfessionalPortalUsersLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getProfInvites, profInvites] = useProfessionalPortalUserInvitationsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getAgentUsers, agentUsers] = useAgentPortalUsersLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getAgentInvites, agentInvites] = useAgentPortalUserInvitationsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  React.useEffect(() => {
    if (user?.accountType === UserAccountType.ServiceProvider) {
      getSpUsers();
      getSpUserInvites();
    } else if (user?.accountType === UserAccountType.Professional) {
      getProfUsers();
      getProfInvites();
    } else {
      getAgentUsers();
      getAgentInvites();
    }
  }, [user, getSpUsers, getSpUserInvites, getProfUsers, getProfInvites, getAgentUsers, getAgentInvites]);

  if (
    spUsers.loading ||
    spUserInvites.loading ||
    profUsers.loading ||
    profInvites.loading ||
    spUsers.loading ||
    spUserInvites.loading
  ) {
    return (
      <Col size={8}>
        <LoadingSpinner />
      </Col>
    );
  }

  const mapDataForDataTable = (dataForTable: any) => {
    if (dataForTable) {
      return dataForTable.map((item: any) => {
        return {
          firstName: item.info.firstName,
          lastName: item.info.lastName,
          email: item.info.email,
          role: item.isAdmin,
          id: item.info.id,
          status: '',
          isMfaEnabled: item.isMfaEnabled,
        };
      });
    }
    return undefined;
  };

  const mapSpUserData = (dataForTable: any) => {
    if (dataForTable) {
      return dataForTable
        .map((item: any) => {
          return {
            firstName: item.info.firstName,
            lastName: item.info.lastName,
            email: item.info.email,
            role: item.isAdmin,
            id: item.info.id,
            status: '',
            isMfaEnabled: item.isMfaEnabled,
            serviceProvider: item.serviceProvider,
          };
        })
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName)); // Sorting by firstName
    }
    return undefined;
  };

  const mapDataForInvites = (dataForTable: any) => {
    if (dataForTable) {
      return dataForTable
        .map((item: ServiceProviderInvitationNode) => {
          return {
            firstName: '',
            lastName: '',
            email: item.email,
            role: item.isAdmin,
            id: item.id,
            status: item.status,
            isMfaEnabled: item.isMfaEnabled,
            serviceProvider: item.serviceProvider,
          };
        })
        .sort((a: any, b: any) => a.firstName.localeCompare(b.firstName)); // Sorting by firstName
    }
    return undefined;
  };

  const mapProfDataForInvites = (dataForTable: any) => {
    if (dataForTable) {
      return dataForTable.map((item: ProfessionalPortalInvitationNode) => {
        return {
          firstName: '',
          lastName: '',
          email: item.email,
          role: item.isAdmin,
          id: item.id,
          status: item.status,
          isMfaEnabled: item.isMfaEnabled,
        };
      });
    }
    return undefined;
  };

  const agentProfy =
    user?.accountType === UserAccountType.Professional
      ? profInvites.data?.professionalPortalUserInvitations
      : agentInvites.data?.agentPortalUserInvitations;

  const recentInvites =
    user?.accountType === UserAccountType.ServiceProvider
      ? mapDataForInvites(spUserInvites.data?.serviceProviderUserInvitations)
      : mapProfDataForInvites(agentProfy);

  const profAgents =
    user?.accountType === UserAccountType.Professional
      ? profUsers.data?.professionalPortalUsers
      : agentUsers.data?.agentPortalUsers;

  const currentUsers =
    user?.accountType === UserAccountType.ServiceProvider
      ? mapSpUserData(spUsers.data?.serviceProviderPortalUsers)
      : mapDataForDataTable(profAgents);

  return (
    <div className={layoutStyles.accountPage}>
      <Row size={isTablet ? 11 : 6}>
        <TitleWithSubtitle
          title={t(`${Messages.titleSettingsSubItemPrefix}${SettingsSubpages.Users}` as Messages)}
          subtitle={t(`${Messages.subTitleSettingsSubItemPrefix}${SettingsSubpages.Users}` as Messages)}
          userSection
        />
      </Row>
      <div className={styles.container}>
        <div className={styles.addUserBox}>
          <Button
            className={styles.addButton}
            type={ButtonTypes.submit}
            icon={!isTablet ? 'user-add' : undefined}
            onClick={() => addUserHandler(t, dispatch)}
          >
            <Typography msg={t(Messages.buttonAddUser)} tag="span" bold />
          </Button>
        </div>
        <CaseSummaryContainer
          title={`${t(Messages.msgTitleProviderUsers)} (${currentUsers ? currentUsers.length : 0})`}
          data={currentUsers}
        />
        <div className={styles.container}>
          <CaseSummaryContainer
            title={`Pending users (${recentInvites ? recentInvites.length : 0})`}
            data={recentInvites}
            subTitle="These users have been invited but have not yet registered to the platform"
            isPending={true}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
