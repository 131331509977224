import {
  StripeCardNode,
  StripeSubscriptionNode,
  UserNode,
  UserPreferredFlow,
  UserVerificationStatus,
} from '@Graphql/graphqlTypes.generated';

import { RootState } from '../reducers';

export const getUser = (state: RootState): UserNode | null => state.auth.userData;
export const getUserCard = (state: RootState): StripeCardNode | null =>
  state.auth.userData?.paymentCards.length ? state.auth.userData?.paymentCards[0] : null;
export const getUserSubscriptions = (state: RootState): StripeSubscriptionNode[] =>
  state.auth.userData?.mySubscriptions || [];
export const getIsAccountInfoMissing = (state: RootState): boolean =>
  !state.auth.userData?.accountType ||
  !state.auth.userData?.birthDate ||
  !state.auth.userData?.city ||
  !state.auth.userData?.address1;

export const getIsIdValidationMissing = (state: RootState): boolean =>
  state.auth.userData?.verificationStatus !== UserVerificationStatus.Verified;

export const getPreferredFlow = (state: RootState): UserPreferredFlow | null =>
  state.auth.userData?.preferredFlow || null;
