import classNames from 'classnames';
import React from 'react';

import styles from './Paper.scss';

export interface PaperProps {
  depth?: 1 | 2;
  marginBottom?: boolean;
  width?: PaperWidthStyles;
  bordered?: boolean;
  className?: string;
  onClick?: () => void;
  id?: string;
  onMouseOver?: (e: any) => void;
  onMouseLeave?: () => void;
  onFocus?: (e: any) => void;
  onBlur?: () => void;
  tabIndex?: number;
  onKeyDown?: (e: any) => void;
}
export enum PaperWidths {
  s = 'width-s',
  m = 'width-m',
  ml = 'width-ml',
  m672 = 'width-672',
  mm = 'width-mm',
  l = 'width-l',
  full = 'width-full',
  yotiWidth = 'width-yoti',
  banner = 'width-banner',
  transunionWidth = 'width-transunion',
  verification = 'width-verification',
  widthSuccess = 'width-success',
  widthMobileAction = 'width-mobileaction',
  l1016 = 'width-1016',
  m581 = 'width-581',
  l1330 = 'width-1330',
}

type PaperWidthStyles =
  | 'width-s'
  | 'width-l'
  | 'width-m'
  | 'width-ml'
  | 'width-mm'
  | 'width-672'
  | 'width-581'
  | 'width-full'
  | 'width-yoti'
  | 'width-1016'
  | 'width-banner'
  | 'width-success'
  | 'width-transunion'
  | 'width-mobileaction'
  | 'width-verification';
type PaperDepths = 'depth0' | 'depth1';

const Paper: React.FunctionComponent<PaperProps> = (props) => {
  const {
    depth = 0,
    marginBottom,
    width,
    children,
    bordered = false,
    className,
    onClick,
    onMouseLeave,
    onMouseOver,
    onBlur,
    onFocus,
    id,
    tabIndex,
    onKeyDown,
  } = props;
  return (
    <div
      className={classNames(
        styles.paper,
        marginBottom && styles.marginBottom,
        bordered && styles.bordered,
        width && styles[width],
        depth && styles[`depth${depth}` as PaperDepths],
        className
      )}
      onClick={onClick}
      onFocus={(e: any) => onFocus && onFocus(e)}
      onBlur={() => onBlur && onBlur()}
      onMouseOver={(e: any) => onMouseOver && onMouseOver(e)}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      id={id}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
    >
      {children}
    </div>
  );
};
export default Paper;
