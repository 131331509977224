import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { fileUploadSettings } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { notificationError } from '@Utils/notificationUtils';

export interface LogoUploadProps {
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  smallWrapperMargin?: boolean;
  unsetMinHeight?: boolean;
  onRemoveClick?: (id: string, index: number) => void;
  disableRemoval?: boolean;
  onDrop: (file: File[]) => void;
  logoUploadComponent: JSX.Element;
}

interface LogoUploadPrivateProps extends LogoUploadProps {}

const LogoUpload: React.FunctionComponent<LogoUploadPrivateProps> = (props) => {
  const { onDrop, multiple = false } = props;
  const t = useTranslations();
  const dispatch = useDispatch();
  const { maxUploadSize, imageUploadFormats } = fileUploadSettings;

  return (
    <>
      <Dropzone
        maxSize={maxUploadSize}
        disabled={props.disabled}
        onDrop={onDrop}
        multiple={multiple}
        accept={imageUploadFormats}
        onDropRejected={(fileRejected: FileRejection[]) => {
          if (fileRejected[0].errors[0].code === 'file-too-large') {
            dispatch(notificationError(t(Messages.msgFileSizeLarge)));
          } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
            dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section {...getRootProps()}>
            <input {...getInputProps()} />
            {props.logoUploadComponent}
          </section>
        )}
      </Dropzone>
    </>
  );
};

export default LogoUpload;
