import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import List from '@Components/List';
import Typography from '@Components/Typography';
import { Links, PaymentStatus } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  LifeCaseDeleteMutation,
  LifeCaseDeleteMutationVariables,
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  StripeUpdateCheckoutSessionMutation,
  StripeUpdateCheckoutSessionMutationVariables,
  UserLandingPage,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteLC } from '@Graphql/lc/mutations';
import { mutationStripeCreateCheckoutSession, mutationStripeUpdateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import styles from '@Routes/settings/StripePaymentsPage/SuccessPage/SuccessPage.scss';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import headerStyles from '../../../../components/Header/Header.scss';

export interface ErrorPageProps {}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = () => {
  const t = useTranslations();
  const history = useHistory();
  const stripe = useStripe();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id') || '';
  const caseId = searchParams.get('id') || '';

  const [updateCheckoutSession] = useMutation<
    StripeUpdateCheckoutSessionMutation,
    StripeUpdateCheckoutSessionMutationVariables
  >(mutationStripeUpdateCheckoutSession, {});

  const [deleteLifeCase] = useMutation<LifeCaseDeleteMutation, LifeCaseDeleteMutationVariables>(mutationDeleteLC, {
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const priceId =
    window.location.hostname === 'app.lifeledger.com'
      ? 'price_1HldhsLnDfwm8yU9OPTe3VYc'
      : 'price_1IJIY8LnDfwm8yU93m4ONMCP';

  React.useEffect(() => {
    if (sessionId !== '') {
      updateCheckoutSession({ variables: { input: { sessionId, status: PaymentStatus.FAILED } } });
    }
  }, [sessionId, updateCheckoutSession]);

  const possibleFailures = [
    `${t('list1_payment_error' as Messages)}`,
    `${t('list2_payment_error' as Messages)}`,
    `${t('list3_payment_error' as Messages)}`,
  ];

  if (stripeLoading) {
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  return (
    <div className={styles.mainContainer}>
      <Row justifyCenter className={classNames(headerStyles.logoContainer, styles.mt30)}>
        <a href={Links.lifeLedger}>
          <Icon icon="logo-full" size={IconSizes.logoFull} />
        </a>
      </Row>

      <div className={classNames(styles.successModal)}>
        <div className={styles.textCenter}>
          <Icon className={styles.closeIcon} icon="close" size={IconSizes.sm} />
        </div>
        <Typography
          textCenter
          className={styles.mb24}
          size="xl"
          bold
          tag="h4"
          msg={t('title_payment_error' as Messages)}
        />
        <Typography className={styles.mb24} size="l" tag="div" msg={t('subtitle_payment_error' as Messages)} />
        <List fontSize="l" listItems={possibleFailures} containerClassName={styles.mb24} />

        <Typography className={styles.mb24} size="l" tag="div" msg={t('desc_payment_error' as Messages)} />

        <Button
          className={styles.mb24}
          type={ButtonTypes.button}
          label={t('button_want_to_try_again' as Messages)}
          onClick={() => stripeCreateDcSubscriptionMutation({ variables: { input: { priceId } } })}
          isFullWidth
        />
        <span className={styles.textCenter}>
          <LinkButton
            linkTitle={t(Messages.backToDashboardLink)}
            onClick={() => {
              if (
                user?.landingPage === UserLandingPage.LifeCase ||
                user?.landingPage === UserLandingPage.RetargetLifeCase
              ) {
                deleteLifeCase({ variables: { input: { id: caseId } } });
                history.push(Links.home);
              } else {
                history.push(generatePath(`${Links.lcReady}${location.search}`, { id: caseId }));
              }
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default ErrorPage;
