import { ExecutionResult } from 'graphql';

import ApolloClient from '@Graphql/graphqlClient';
import { LifeCaseQuery, LifeCaseQueryVariables } from '@Graphql/graphqlTypes.generated';
import { queryLC } from '@Graphql/lc/queries';

export const fetchLCQuery = async (id: LifeCaseQueryVariables['id']) => {
  const result: ExecutionResult<LifeCaseQuery> = await ApolloClient.query<LifeCaseQuery, LifeCaseQueryVariables>({
    query: queryLC,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  return result.data?.lifeCase;
};
