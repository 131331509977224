import * as dotProp from 'dot-prop-immutable';
import { Action } from 'typescript-fsa';

import {
  CollaboratorNode,
  CreateCollaboratorPayload,
  DeleteCollaboratorPayload,
  DocumentDocumentType,
  UpdateCollaboratorPayload,
} from '@Graphql/graphqlTypes.generated';
import { editCollaborator } from '@Store/collaborator/collaborator.actions';
import { addExecutor, removeDocuments, removeExecutor, uploadDocuments } from '@Store/dc/dc.actions';
import { documentTypeMapper } from '@Store/dc/dc.reducer';
import { clearLC, fetchLC } from '@Store/lc/lc.actions';
import { LCData } from '@Store/lc/lc.types';
import { singleItemReducer, singleItemReducerInitialState } from '@Utils/reducers';
import { createReducer } from '@Utils/redux';

export type LCState = {
  lc: LCData;
};

export const initialState: LCState = {
  lc: singleItemReducerInitialState,
};

const lcReducer = createReducer<LCState>(initialState, {
  [clearLC.type]: (state) => dotProp.set(state, 'lc', { isFetching: false, item: null }),
  [addExecutor.done.type]: (state, action: Action<{ result: CreateCollaboratorPayload }>) => {
    const executors = state.lc.item?.executors || [];
    const item = state.lc.item
      ? { ...state.lc.item, executors: [...executors, action.payload.result.collaborator] }
      : null;
    return dotProp.set(state, 'lc', { isFetching: false, item });
  },
  [removeExecutor.done.type]: (state, action: Action<{ result: DeleteCollaboratorPayload }>) => {
    const executors = state.lc.item?.executors || [];
    const filtered = executors.filter((ex: CollaboratorNode) => ex.id !== action.payload.result?.collaborator?.id);
    const item = state.lc.item ? { ...state.lc.item, executors: filtered } : null;
    return dotProp.set(state, 'lc', { isFetching: false, item });
  },
  [editCollaborator.done.type]: (state, action: Action<{ result: UpdateCollaboratorPayload }>) => {
    const executors = state.lc.item?.executors || [];
    const result = action.payload.result?.collaborator;
    const filteredExecutors = executors.filter((ex: CollaboratorNode) => ex.id !== result.id);
    const item = { ...state.lc.item, executors: [...filteredExecutors, action.payload.result.collaborator] };
    return dotProp.set(state, 'lc', { isFetching: false, item });
  },
  [uploadDocuments.done.type]: (state, { payload: { params, result } }: ReturnType<typeof uploadDocuments.done>) => {
    if (!state.lc.item) {
      return state;
    }
    return dotProp.merge(
      state,
      `lc.item.${documentTypeMapper[params.documentType as DocumentDocumentType]}`,
      result.document
    );
  },
  [removeDocuments.done.type]: (state, { payload: { params } }: ReturnType<typeof removeDocuments.done>) => {
    return dotProp.delete(
      state,
      `lc.item.${documentTypeMapper[params.documentType as DocumentDocumentType]}.${[params.index]}`
    ) as LCState;
  },
  ...singleItemReducer<any, any, any>(fetchLC, 'lc'),
});

export default lcReducer;
