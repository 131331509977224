import classNames from 'classnames';
import * as React from 'react';

import styles from './Icon.scss';

export enum IconSizes {
  xss = 'xxs',
  xs = 'xs',
  s12 = 's12',
  sss = 'sss',
  sxs = 'sxs',
  ss = 'ss',
  s16 = 's16',
  s24 = 's24',
  s = 's',
  s26 = 's26',
  ssm = 'ssm',
  sm = 'sm',
  tick = 'tick',
  tick1 = 'tick1',
  m = 'm',
  mm = 'mm',
  m64 = 'm64',
  m68 = 'm68',
  mms = 'mms',
  mmm = 'mmm',
  l = 'l',
  l78 = 'l78',
  l74 = 'l74',
  xl = 'xl',
  auto = 'auto',
  logoFull = 'logoFull',
  logoBig = 'logoBig',
  logoBigger = 'logoBigger',
  // Use this if styling using css
  none = 'none',
  stripe = 'stripe',
  gocardless = 'gocardless',
  progressBar = 'progressBar',
  pr = 'pr',
  br = 'br',
  brt = 'brt',
  noteIcons = 'noteIcons',
  switchIcon = 'switchIcon',
  welcomeLogo = 'welcomeLogo',
  statsIcon = 'statsIcon',
  anglianIcon = 'anglianIcon',
  britishGasIcon = 'britishGasIcon',
  aaIcon = 'aaIcon',
  homeIcon = 'homeIcon',
  tsbIcon = 'tsbIcon',
  originIcon = 'originIcon',
  swIcon = 'swIcon',
  swwIcon = 'swwIcon',
  sswIcon = 'sswIcon',
  hdIcon = 'hdIcon',
  oneCallicon = 'oneCallIcon',
  uuIcon = 'uuIcon',
  utilitaIcon = 'utilitaIcon',
  doubleQuotesIcon = 'doubleQuotesIcon',
  yellowQuotes = 'yellowQuotes',
  virginMobile = 'virginMobile',
  toggleIcon = 'toggleIcon',
}

export const sizes = {
  [IconSizes.xss]: {
    width: 7,
    height: 7,
  },
  [IconSizes.xs]: {
    width: 10,
    height: 10,
  },
  [IconSizes.s12]: {
    width: 12,
    height: 12,
  },
  [IconSizes.sss]: {
    width: 14,
    height: 14,
  },
  [IconSizes.s16]: {
    width: 16,
    height: 16,
  },
  [IconSizes.sxs]: {
    width: 18,
    height: 18,
  },
  [IconSizes.ss]: {
    width: 20,
    height: 20,
  },
  [IconSizes.tick]: {
    width: 21.39,
    height: 16.06,
  },
  [IconSizes.tick1]: {
    width: 16.04,
    height: 12.04,
  },
  [IconSizes.s24]: {
    width: 24,
    height: 24,
  },
  [IconSizes.s]: {
    width: 26,
    height: 26,
  },
  [IconSizes.s26]: {
    width: 26,
    height: 21,
  },
  [IconSizes.ssm]: {
    width: 31,
    height: 30,
  },
  [IconSizes.mms]: {
    width: 43,
    height: 44,
  },
  [IconSizes.sm]: {
    width: 36,
    height: 36,
  },
  [IconSizes.m]: {
    width: 55,
    height: 55,
  },
  [IconSizes.toggleIcon]: {
    width: 54,
    height: 27.69,
  },
  [IconSizes.mm]: {
    width: 40,
    height: 40,
  },
  [IconSizes.welcomeLogo]: {
    width: 46,
    height: 46,
  },
  [IconSizes.mmm]: {
    width: 50,
    height: 50,
  },
  [IconSizes.noteIcons]: {
    width: 42,
    height: 37,
  },
  [IconSizes.switchIcon]: {
    width: 33,
    height: 31,
  },
  [IconSizes.statsIcon]: {
    width: 62,
    height: 62,
  },
  [IconSizes.m64]: {
    width: 64,
    height: 64,
  },
  [IconSizes.m68]: {
    width: 68,
    height: 66,
  },
  [IconSizes.l]: {
    width: 70,
    height: 70,
  },
  [IconSizes.l74]: {
    width: 74,
    height: 74,
  },
  [IconSizes.l78]: {
    width: 78,
    height: 78,
  },
  [IconSizes.xl]: {
    width: 115,
    height: 152,
  },
  [IconSizes.pr]: {
    width: 157,
    height: 58,
  },
  [IconSizes.auto]: {
    width: '100%',
    height: '100%',
  },
  [IconSizes.logoFull]: {
    width: 202,
    height: 42,
  },
  [IconSizes.logoBig]: {
    width: 252,
    height: 113,
  },
  [IconSizes.br]: {
    width: 263.54,
    height: 87,
  },
  [IconSizes.brt]: {
    width: 172,
    height: 57,
  },
  [IconSizes.logoBigger]: {
    width: 321,
    height: 67,
  },
  [IconSizes.stripe]: {
    width: 190,
    height: 94,
  },
  [IconSizes.gocardless]: {
    width: 298,
    height: 32,
  },
  [IconSizes.progressBar]: {
    width: 19.96,
    height: 19.96,
  },
  [IconSizes.anglianIcon]: {
    width: 144,
    height: 33,
  },
  [IconSizes.britishGasIcon]: {
    width: 125,
    height: 49,
  },
  [IconSizes.aaIcon]: {
    width: 86,
    height: 86,
  },
  [IconSizes.homeIcon]: {
    width: 148,
    height: 57,
  },
  [IconSizes.tsbIcon]: {
    width: 150,
    height: 55,
  },
  [IconSizes.originIcon]: {
    width: 75,
    height: 93,
  },
  [IconSizes.swIcon]: {
    width: 150,
    height: 41,
  },
  [IconSizes.swwIcon]: {
    width: 150,
    height: 53,
  },
  [IconSizes.sswIcon]: {
    width: 150,
    height: 71,
  },
  [IconSizes.hdIcon]: {
    width: 129,
    height: 56,
  },
  [IconSizes.oneCallicon]: {
    width: 118,
    height: 45,
  },
  [IconSizes.uuIcon]: {
    width: 150,
    height: 50,
  },
  [IconSizes.utilitaIcon]: {
    width: 150,
    height: 60,
  },
  [IconSizes.doubleQuotesIcon]: {
    width: 76,
    height: 57,
  },
  [IconSizes.yellowQuotes]: {
    width: 39.6,
    height: 29.7,
  },
  [IconSizes.virginMobile]: {
    width: 126,
    height: 76,
  },
  [IconSizes.none]: {},
};

export interface IconProps {
  icon: string;
  size?: IconSizes;
  className?: string;
  spin?: boolean;
  onClick?: (e?: any) => void;
  onKeyDown?: (e: any) => void;
  id?: string;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  const { icon, size = 'none', className = null, spin = false, onClick, id, onKeyDown } = props;
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
  const IconResource = require(`@Images/icons/${icon}.svg`).default;
  return (
    <IconResource
      {...sizes[size]}
      onClick={onClick}
      className={classNames(styles.icon, className, { [styles.spin]: spin, [`icon-${size}`]: size })}
      tabIndex={onClick ? 0 : undefined}
      id={id}
      onKeyDown={onKeyDown}
    />
  );
};
export default Icon;
