import classNames from 'classnames';
import React from 'react';

import styles from './GrayPaper.scss';

export interface GrayPaperProps {
  depth?: 1 | 2;
  marginBottom?: boolean;
  width?: GrayPaperWidthStyles;
  bordered?: boolean;
  className?: string;
  noInsideSpacing?: boolean;
  onMouseOver?: (e: any) => void;
  onMouseLeave?: () => void;
  onFocus?: (e: any) => void;
  onBlur?: () => void;
  tabIndex?: number;
  onKeyDown?: (e: any) => void;
  onClick?: (e?: any) => void;
}
export enum GrayPaperWidths {
  m = 'width-m',
  l = 'width-l',
  full = 'width-full',
  yotiWidth = 'width-yoti',
  banner = 'width-banner',
  transunionWidth = 'width-transunion',
  verification = 'width-verification',
}

type GrayPaperWidthStyles =
  | 'width-l'
  | 'width-m'
  | 'width-full'
  | 'width-yoti'
  | 'width-banner'
  | 'width-transunion'
  | 'width-verification';
type GrayPaperDepths = 'depth0' | 'depth1';

const GrayPaper: React.FunctionComponent<GrayPaperProps> = (props) => {
  const {
    depth = 0,
    marginBottom,
    width,
    children,
    bordered = false,
    className,
    noInsideSpacing = false,
    onMouseLeave,
    onMouseOver,
    onBlur,
    onFocus,
    tabIndex,
    onKeyDown,
    onClick,
  } = props;
  return (
    <div
      className={classNames(
        styles.paper,
        marginBottom && styles.marginBottom,
        bordered && styles.bordered,
        width && styles[width],
        depth && styles[`depth${depth}` as GrayPaperDepths],
        className,
        { [styles.noInsideSpacingPaper]: noInsideSpacing }
      )}
      onFocus={(e: any) => onFocus && onFocus(e)}
      onBlur={() => onBlur && onBlur()}
      onMouseOver={(e: any) => onMouseOver && onMouseOver(e)}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export default GrayPaper;
