import React from 'react';
import { useHistory } from 'react-router';

import Header from '@Components/application/Header/Header';
import Button from '@Components/Button';
import Footer from '@Components/Footer';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { apiDomain } from '@Utils/helpers';

import styles from './SPLoginPage.scss';

const SPLoginPage: React.FC = () => {
  const history = useHistory();
  const t = useTranslations();
  const searchParams = new URLSearchParams(history.location.search);
  const companyName = searchParams.get('sp') || '';
  const handleLogin = () => {
    window.location.href = `${apiDomain()}home/sso/login/`;
  };

  const sideBarVisibility = () => {
    return false;
  };

  return companyName === 'lvinsurance' ? (
    <>
      <Header
        loginRegisterVisibility={false}
        setMobileNavigation={sideBarVisibility}
        showSideBar={false}
        showNavigation={false}
      />
      <div className={styles.loginContainer}>
        <div className={styles.loginBox}>
          <img
            className={styles.logo}
            alt="LV Insurance"
            src="https://lifeledger-api-dev-files.s3.eu-west-2.amazonaws.com/service_provider/logos/download-40.jpg"
          />

          <h2>Welcome to Life Ledger</h2>
          <p className={styles.mb20}>{t('label_corporate_account_sign_in' as Messages)}</p>
          <Button onClick={handleLogin} label="Login with SSO" isFullWidth />
        </div>
      </div>
      <Footer isRegisterForm={true} />
    </>
  ) : (
    <h1>Access Restricted</h1>
  );
};

export default SPLoginPage;
