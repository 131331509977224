import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import GrayPaper from '@Components/application/GrayPaper';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Paper from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { NotificationDocumentNode, DocumentNode, UserDocumentsNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';

import Tooltip, { TooltipPosition } from '../Tooltip';

import styles from './FileView.scss';

export interface FileViewProps {
  isGray?: boolean;
  fileDetails: NotificationDocumentNode | DocumentNode | UserDocumentsNode;
  containerClassName?: string;
  showDownload?: boolean;
  onRemoveClick?: (id: string, index?: number) => void;
  fileId?: string;
  idx?: number;
  fileNameCenter?: boolean;
  isLifeCase?: boolean;
  isDocumentsPage?: boolean;
  blockDocumentAccess?: boolean;
  deleteIconOutSide?: boolean;
  isNotificationPage?: boolean;
  isSmartModal?: boolean;
}

const FileView: React.FunctionComponent<FileViewProps> = ({
  isGray = false,
  fileDetails,
  containerClassName,
  showDownload = true,
  onRemoveClick = noop,
  fileId,
  idx,
  fileNameCenter,
  blockDocumentAccess = false,
  deleteIconOutSide = false,
  isNotificationPage,
  isSmartModal,
}) => {
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const t = useTranslations();
  const [showEye, setShowEye] = React.useState(false);

  const embedSize = isMobile ? 3 : 2;

  const onMouseOver = () => setShowEye(true);
  const onMouseLeave = () => setShowEye(false);

  React.useEffect(() => {
    const nextElement = document.querySelector(`#deleteDiv`) as HTMLElement;
    if (nextElement) {
      return nextElement.focus();
    }
    return noop;
  });

  // const compressImage = () => {
  //   const imageUrl =
  //     'https://lifeledger-api-dev-files.s3.amazonaws.com/personal_data/Manchesterleeds/CERTIFICATE/119d6f10-4df7-40b8-88b5-b5450d824718.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVEJKXSCJ4GREMWMH%2F20221003%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20221003T144040Z&X-Amz-Expires=600&X-Amz-SignedHeaders=host&X-Amz-Signature=63476ff8d6e308a8dc0f04c9cebba7c0e6d543a071e05b2184398a079c1752c6';
  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((imageBlob) => {
  //       // Then create a local URL for that image and print it
  //       const imageObjectURL = URL.createObjectURL(imageBlob);
  //       console.log(imageObjectURL);
  //     });

  //   return imageUrl;
  // };

  const grayFileViewContent = (
    <>
      <Row className={styles.mb10} constant size={12}>
        <GrayPaper
          onFocus={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onMouseLeave();
          }}
          onMouseOver={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            onMouseLeave();
          }}
          tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              window.open(fileDetails.file, '_blank');
            }
          }}
          width="width-full"
          onClick={() => window.open(fileDetails.file, '_blank')}
          className={classNames(containerClassName, { [styles.blockAccess]: blockDocumentAccess }, styles.cardHover)}
        >
          {/* desktop  */}
          {!isMobile && (
            <Row constant>
              {blockDocumentAccess && <Icon icon="lock" size={IconSizes.mms} className={styles.lockIcon} />}
              {!isSmartModal && (
                <Col constant size={isMobileBetweenTablet || isNotificationPage ? 3 : embedSize} alignCenter>
                  <iframe
                    id="docPreview"
                    className={styles.filePreview}
                    src={fileDetails.file}
                    width="64px"
                    title={idx?.toString()}
                    height="46px"
                    style={{ overflow: 'hidden' }}
                    scrolling="no"
                    //  type="application/pdf"
                    onFocus={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onMouseOver();
                    }}
                    onMouseOver={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onMouseOver();
                    }}
                  />
                </Col>
              )}
              {!isMobile && (
                <div className={showEye ? styles.overlay : styles.hide}>
                  <Icon size={IconSizes.mm} icon="eye" className={styles.eyeIcon} />
                </div>
              )}

              <Col constant alignCenter textCenter={fileNameCenter} size={isMobile ? 7 : 8}>
                <Typography size="m" tag="div" msg={fileDetails.fileName} />
                <Typography
                  size="s"
                  tag="div"
                  msg={t(`${Messages.documentTypePrefix}${fileDetails.documentType}` as Messages)}
                  color="footerColor"
                />
              </Col>

              {(!isMobile || !isSmartModal) && (
                <Col constant alignCenter size={2}>
                  <a href={fileDetails.file} target="_blank" rel="noopener noreferrer" download>
                    <Typography msg="View" color="newGray" tag="div" underline size="m" />
                  </a>
                </Col>
              )}

              {fileId && !deleteIconOutSide && !isMobile && (
                <div className={isMobile ? styles.flexBox : styles.alignCenter}>
                  {isMobile && (
                    <Col className={styles.topSpacing} alignCenter size={1}>
                      <a href={fileDetails.file} target="_blank" rel="noopener noreferrer" download>
                        <Typography msg="View" color="newGray" tag="div" underline size="m" />
                      </a>
                    </Col>
                  )}

                  <Col alignCenter size={1}>
                    <Icon
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveClick(fileId, idx || -1);
                      }}
                      icon="trash"
                      className={classNames(styles.handSymbol, styles.mt12)}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          e.stopPropagation();
                          onRemoveClick(fileId, idx || -1);
                        }
                      }}
                    />
                  </Col>
                </div>
              )}
            </Row>
          )}

          {/* mobile */}
          {isMobile && (
            <div onClick={() => window.open(fileDetails.file, '_blank')}>
              <Row constant alignCenter>
                {!isSmartModal && (
                  <Col constant size={3}>
                    <iframe
                      id="docPreview"
                      className={styles.filePreview}
                      src={fileDetails.file}
                      width="64px"
                      title={idx?.toString()}
                      height="58px"
                      style={{ overflow: 'hidden' }}
                      scrolling="no"
                      //  type="application/pdf"
                      onFocus={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onMouseOver();
                      }}
                      onMouseOver={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onMouseOver();
                      }}
                    />
                  </Col>
                )}

                <Col constant alignCenter textCenter={fileNameCenter} size={isMobile ? 7 : 8}>
                  <Typography size="m" tag="div" msg={fileDetails.fileName} />
                  <Typography
                    size="s"
                    tag="div"
                    msg={t(`${Messages.documentTypePrefix}${fileDetails.documentType}` as Messages)}
                    color="footerColor"
                  />
                </Col>
              </Row>
              <Row alignCenter constant justifyBetween>
                <a
                  className={styles.mobileViewLink}
                  href={fileDetails.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Typography msg="View" color="newGray" tag="div" underline size="m" />
                </a>

                {fileId && (
                  <Icon
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemoveClick(fileId, idx || -1);
                    }}
                    icon="trash"
                    className={styles.handSymbol}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveClick(fileId, idx || -1);
                      }
                    }}
                  />
                )}
              </Row>
            </div>
          )}
        </GrayPaper>

        {fileId && deleteIconOutSide && (
          <Col
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                onRemoveClick(fileId, idx || -1);
              }
            }}
            constant
            className={styles.topSpacing}
            alignCenter
            size={1}
            textCenter
            tabindex={0}
          >
            <div onClick={() => onRemoveClick(fileId, idx || -1)}>
              <Icon icon="trash" className={styles.handSymbol} />
            </div>
          </Col>
        )}
      </Row>
    </>
  );

  const whiteFileViewContent = (
    <Paper
      onFocus={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onMouseLeave();
      }}
      onMouseOver={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onMouseLeave();
      }}
      width="width-full"
      className={classNames(styles.container, containerClassName, styles.cardHover, {
        [styles.blockAccess]: blockDocumentAccess,
      })}
      onKeyDown={(e: any) => {
        if (e.keyCode === EnterKeyCode) {
          window.open(fileDetails.file, '_blank');
        }
      }}
      onClick={() => window.open(fileDetails.file, '_blank')}
      tabIndex={0}
    >
      <Row size={12}>
        {blockDocumentAccess && <Icon icon="lock" size={IconSizes.mms} className={styles.lockIcon} />}
        <Col size={3} alignCenter>
          <embed
            className={styles.filePreview}
            src={fileDetails.file}
            width="64px"
            height="46px"
            type="application/pdf"
            onFocus={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              onMouseOver();
            }}
            onMouseOver={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              onMouseOver();
            }}
          />
        </Col>
        <div className={showEye ? styles.overlay : styles.hide}>
          <Icon size={IconSizes.mm} icon="eye" className={styles.eyeIcon} />
        </div>
        {!showDownload && <Col size={1} />}
        <Col size={showDownload ? 7 : 8} alignCenter>
          <Typography size="s" tag="div" msg={fileDetails.fileName} />
        </Col>
      </Row>
    </Paper>
  );

  const blockedGrayPaper = (isWhite: boolean) => {
    return (
      <Tooltip
        position={TooltipPosition.top}
        message={t('subtitle_document_hidden' as Messages)}
        title={t('title_document_hidden' as Messages)}
      >
        {isWhite ? whiteFileViewContent : grayFileViewContent}
      </Tooltip>
    );
  };

  const grayPaperDisplay = blockDocumentAccess ? blockedGrayPaper(false) : grayFileViewContent;

  const whitePaperDisplay = blockDocumentAccess ? blockedGrayPaper(true) : whiteFileViewContent;

  return !isGray ? whitePaperDisplay : grayPaperDisplay;
};

export default FileView;
