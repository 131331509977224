import { Field, Form, Formik } from 'formik';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import MiniFooter from '@Components/application/MiniFooter';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Tabs, { TabItem } from '@Components/Tabs';
import Typography from '@Components/Typography';
import { DataExchangeFormats } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  ServiceProviderOrganizationType,
  UserAccountType,
  useServiceProviderCompaniesQuery,
  useServiceProviderQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { dataFormatDate, normalizeDate } from '@Utils/dates';
import { validateMaxDate } from '@Utils/validator';

import ProviderDashboardNavbar from '../ProviderDashboardPage/ProviderDashboardNavbar';
import styles from '../ProviderDashboardPage/ProviderDashboardPage.scss';

import ReportsForm from './ReportsForm/ReportsForm';

export interface ReportsPageProps {}

enum StatisticsFormFields {
  fromDate = 'fromDate',
  toDate = 'toDate',
}

const ReportsPage: React.FunctionComponent<ReportsPageProps> = () => {
  const user = useSelector(getUser);
  const serviceProviderId = user?.serviceProvider || '';
  const currentLanguage = useSelector(getCurrentLanguage);
  const t = useTranslations();

  const [fieldStartDate, setFieldStartDate] = React.useState(
    dataFormatDate(new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000), DataExchangeFormats.date, '...')
  );
  const [fieldEndDate, setFieldEndDate] = React.useState(dataFormatDate(new Date(), DataExchangeFormats.date, '...'));
  const [newTab, setNewTab] = React.useState(0);

  const spGroups = useServiceProviderCompaniesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const providerInfo = useServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: user?.accountType !== UserAccountType.ServiceProvider,
    variables: {
      id: serviceProviderId,
    },
  });

  if (spGroups.loading) {
    return <LoadingSpinner />;
  }

  const nodeList = spGroups.data?.serviceProviderCompanies.map((company: any) => {
    let edges = company.serviceProvider.edges.map((edge: any) => {
      const { name, id, organizationType } = edge.node;
      return { name, id, organizationType };
    });

    const holdingCompany = company.serviceProviderCompany;
    delete holdingCompany.__typename;
    edges = edges.concat([holdingCompany]);
    return edges;
  });

  const sisterCompanies = nodeList?.reduce((acc: any, val: any) => acc.concat(val), []);

  const sortedSisterCompanies = sisterCompanies.sort((a: any, b: any) => {
    if (a.organizationType === 'HOLDING_COMPANY' && b.organizationType === 'COMPANY') {
      return -1; // a should come before b
    }
    if (a.organizationType === 'COMPANY' && b.organizationType === 'HOLDING_COMPANY') {
      return 1; // b should come before a
    }
    return 0; // no change in order
  });

  const filteredSisterCompanies =
    providerInfo.data?.serviceProvider.organizationType === ServiceProviderOrganizationType.Company
      ? sortedSisterCompanies.filter((sp: any) => sp.id === providerInfo.data?.serviceProvider.id)
      : sortedSisterCompanies;

  const companyTabs = filteredSisterCompanies.map((company: any) => {
    return {
      title: company.name,
    } as TabItem;
  });

  const getSPId = () => {
    return filteredSisterCompanies.length > 0 ? filteredSisterCompanies[newTab].id : serviceProviderId;
  };

  return (
    <Col className={styles.overallContainer} size={12}>
      <ProviderDashboardNavbar />
      <div className={classNames(styles.cardBackground, styles.mt34)}>
        <Tabs tabs={companyTabs} activeTab={newTab} setActiveTab={setNewTab} />

        <Typography msg={t(Messages.labelDateRange)} tag="div" size="l" bold className={styles.mt34} />
        <Formik
          initialValues={{
            [StatisticsFormFields.fromDate]: fieldStartDate,
            [StatisticsFormFields.toDate]: fieldEndDate,
          }}
          validationSchema={Yup.object({
            [StatisticsFormFields.toDate]: validateMaxDate(
              StatisticsFormFields.fromDate,
              t(Messages.msgErrorFieldFromDate)
            ),
          })}
          onSubmit={(values) => {
            setFieldStartDate(values.fromDate);
            setFieldEndDate(values.toDate);
          }}
        >
          {({ status }) => {
            return (
              <Form>
                <Row alignEnd className={styles.gap24}>
                  <Col>
                    <Field
                      name={StatisticsFormFields.fromDate}
                      type="text"
                      component={DatePickerInput}
                      label={t(Messages.labelFromDate)}
                      parse={normalizeDate(currentLanguage)}
                    />
                  </Col>
                  <Col textCenter className={styles.hyphen} alignCenter />
                  <Col>
                    <Field
                      name={StatisticsFormFields.toDate}
                      type="text"
                      component={DatePickerInput}
                      label={t(Messages.labelToDate)}
                      parse={normalizeDate(currentLanguage)}
                    />
                  </Col>

                  <Col size={2} className={styles.buttonWrapper}>
                    <Button
                      type={ButtonTypes.submit}
                      style={ButtonStyles.transparent}
                      label={t(Messages.buttonApply)}
                    />
                  </Col>
                </Row>
                <FormError formError={status} />
              </Form>
            );
          }}
        </Formik>

        <ReportsForm startDate={fieldStartDate} endDate={fieldEndDate} spId={getSPId()} />
      </div>
      <MiniFooter centerAlign noMaxWidth />
    </Col>
  );
};
export default ReportsPage;
