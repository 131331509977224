import classNames from 'classnames';
import * as React from 'react';

import Icon from '@Components/Icon';
import Typography from '@Components/Typography';

import styles from './ErrorDisplay.scss';

export interface ErrorDisplayProps {
  msg: string | string[] | { message: string };
  showErrorIcon?: boolean;
  removePositionCss?: boolean;
}

const parseMessageToDisplay = (msg: string | string[] | { message: string }, showErrorIcon?: boolean) => {
  if (typeof msg === 'string') {
    return (
      <>
        {showErrorIcon && <Icon icon="warningSign" className={styles.iconPlacing} />}
        <Typography className={styles.message} tag="span" msg={msg} />
      </>
    );
  }

  if (Array.isArray(msg)) {
    return msg.map((m: string, idx: number) => (
      <>
        {showErrorIcon && <Icon icon="warningSign" className={styles.iconPlacing} />}
        <Typography key={idx} className={styles.message} tag="span" msg={m} />
      </>
    ));
  }
  return (
    <>
      {showErrorIcon && <Icon icon="warningSign" className={styles.iconPlacing} />}
      <Typography className={styles.message} tag="span" msg={msg.message} />
    </>
  );
};

const ErrorDisplay: React.FunctionComponent<ErrorDisplayProps> = ({ msg, showErrorIcon, removePositionCss }) => {
  const msgToDisplay = parseMessageToDisplay(msg, showErrorIcon);
  return (
    <div
      className={classNames(removePositionCss ? styles.normalError : styles.error, {
        [styles.withErrorIcon]: showErrorIcon,
      })}
    >
      {msgToDisplay}
    </div>
  );
};

export default ErrorDisplay;
