// import { useMsal } from '@azure/msal-react';
import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationRegisterUser, mutationSocialLogin } from '@Graphql/auth/mutations';
import {
  RegisterUserMutation,
  RegisterUserMutationVariables,
  SocialLoginMedium,
  SocialLoginMutation,
  SocialLoginMutationVariables,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getGqlErrorMessage } from '@Utils/form';
import { chooseAccountType } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';

export interface MicrosoftLandingPageProps {
  accountInfo: IAccountInfo;
  isLoginPage?: boolean;
  collaboratorKey?: any;
  logout: any;
  accountType?: UserAccountType;
  referralCode?: string;
  referralSource?: string;
  referralSourceDetails?: string;
}

const MicrosoftLandingPage: React.FunctionComponent<MicrosoftLandingPageProps> = ({
  accountInfo,
  isLoginPage,
  collaboratorKey,
  accountType,
  referralCode,
  referralSource,
  referralSourceDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslations();

  const [socialLogin] = useMutation<SocialLoginMutation, SocialLoginMutationVariables>(mutationSocialLogin, {
    onCompleted: (data: any) => {
      setToLocalStorage(LocalStorage.userToken, data.socialLogin.token);
      history.push(Links.home);
    },
  });

  const [registerUser] = useMutation<RegisterUserMutation, RegisterUserMutationVariables>(mutationRegisterUser, {
    onCompleted: () => {
      socialLogin({
        variables: {
          input: {
            provider: 'microsoft',
            idToken: accountInfo.jwtIdToken,
          },
        },
      });
    },
    onError: (error: any) => {
      if (getGqlErrorMessage(error.message) === 'msg_error_user_with_this_email_already_exists') {
        dispatch(notificationError(t('msg_user_exists_already_in_app' as Messages)));
      }
    },
  });

  React.useEffect(() => {
    const getAccountType = () => {
      if (accountType) {
        return accountType;
      }
      if (!isLoginPage) {
        return history.location.pathname.split('/')[2].toUpperCase()
          ? chooseAccountType(history.location.pathname.split('/')[2].toUpperCase())
          : undefined;
      }
      return undefined;
    };

    const nameParts = accountInfo.account.name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts[1] : '';
    registerUser({
      variables: {
        input: {
          firstName: firstName || '',
          lastName: lastName || '',
          email: accountInfo.account.userName,
          accountType: getAccountType(),
          accessCode: '',
          collaboratorKey,
          socialLoginType: SocialLoginMedium.Azure,
          referralCode,
          referralSource,
          referralSourceDetails,
        },
      },
    });
  }, [
    accountInfo,
    collaboratorKey,
    registerUser,
    history,
    isLoginPage,
    accountType,
    referralCode,
    referralSource,
    referralSourceDetails,
  ]);

  return <p />;
};

export default MicrosoftLandingPage;
