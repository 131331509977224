import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import TextInput from '@Components/form/inputs/TextInput';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { LifeCaseDocumentType } from '@Components/Modal/FileUploadModalBody/FileUploadModalBody';
import Typography from '@Components/Typography';
import { fileUploadSettings } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderFragment,
  DeathCaseFragment,
  DocumentDocumentType,
  NotificationDocumentNode,
  RestrictedDeathCaseNode,
  UploadDocumentInNotificationMutation,
  UploadDocumentInNotificationMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationUploadDocumentInNotification } from '@Graphql/providers/mutations';
import { queryCaseDocuments } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { deathCaseNotificationHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateRequired } from '@Utils/validator';

import styles from './UploadDocument.scss';

export interface UploadDocumentProps {
  csp: CaseServiceProviderFragment | undefined;
  deathCase: DeathCaseFragment | RestrictedDeathCaseNode;
  uploadedFiles: NotificationDocumentNode[];
  closePopOver: () => void;
  resetDraggedFile?: () => void;
  draggedFiles?: File[];
  isCustomer?: boolean;
}

enum FileUploadModalBodyFields {
  documentType = 'documentType',
  otherDocumentTypeName = 'otherDocumentTypeName',
}

const UploadDocument: React.FunctionComponent<UploadDocumentProps> = ({
  csp,
  deathCase,
  uploadedFiles,
  closePopOver,
  resetDraggedFile = noop,
  draggedFiles = [],
  isCustomer,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const initialValues = {
    [FileUploadModalBodyFields.documentType]: '',
    [FileUploadModalBodyFields.otherDocumentTypeName]: '',
  };

  const [updateDocumentInChat] = useMutation<
    UploadDocumentInNotificationMutation,
    UploadDocumentInNotificationMutationVariables
  >(mutationUploadDocumentInNotification, {
    refetchQueries: [getOperationName(queryCaseDocuments) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.labelChatDocumentUploadSuccess)));
      resetDraggedFile();
      closePopOver();
    },
    onError: (apiError) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
      if (isCustomer) {
        deathCaseNotificationHandler(dispatch, csp ? csp?.id : '', false, deathCase, true);
      }
    },
  });

  const dcDocuments = Object.values(DocumentDocumentType)
    .filter((dcType: string) => dcType !== DocumentDocumentType.SpUploaded)
    .map((documentType) => ({
      label: t(`${Messages.documentTypePrefix}${documentType.toUpperCase()}` as Messages),
      value: documentType,
    }));

  const isOtherSelected = (values: any) => {
    return values.documentType === LifeCaseDocumentType.Other;
  };

  const { maxUploadSize, supportedUploadFormats, maxFiles } = fileUploadSettings;
  const disabled = uploadedFiles && uploadedFiles.length >= maxFiles;

  const AutoToggleForm = () => {
    const { setFieldValue, values } = useFormikContext();

    React.useEffect(() => {
      if (!isOtherSelected(values)) {
        setFieldValue(FileUploadModalBodyFields.otherDocumentTypeName, '');
      }
    }, [setFieldValue, values]);

    return null;
  };

  return (
    <>
      <Typography msg={t(Messages.modalUploadDocumentTitle)} tag="h4" size="xl" bold />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          [FileUploadModalBodyFields.documentType]: validateRequired(t),
        })}
        onSubmit={(values) => {
          updateDocumentInChat({
            variables: {
              input: {
                deathCaseProviderId: csp ? csp?.id : '',
                documentType: values.documentType,
                files: draggedFiles,
              },
            },
          });
        }}
      >
        {({ values }) => {
          return (
            <Form>
              <Row size={12} justifyBetween className={styles.radioButtonContainer}>
                <Col size={12} tabindex={0}>
                  <Field
                    name={FileUploadModalBodyFields.documentType}
                    options={dcDocuments}
                    component={RadioButton}
                    label="Select document type"
                    mSize={true}
                  />
                </Col>
              </Row>
              {isOtherSelected(values) && (
                <Field
                  name={FileUploadModalBodyFields.otherDocumentTypeName}
                  type="text"
                  component={TextInput}
                  label=""
                  placeholder={t('field_placeholder_specify_document_type' as Messages)}
                />
              )}

              {draggedFiles.length === 0 && (
                <Dropzone
                  maxSize={maxUploadSize}
                  disabled={disabled}
                  onDrop={(files: File[]) =>
                    updateDocumentInChat({
                      variables: {
                        input: {
                          deathCaseProviderId: csp ? csp.id : '',
                          documentType: values.documentType,
                          files,
                        },
                      },
                    })
                  }
                  multiple={true}
                  accept={supportedUploadFormats}
                  onDropRejected={(fileRejected: FileRejection[]) => {
                    if (fileRejected[0].errors[0].code === 'file-too-large') {
                      dispatch(notificationError(t(Messages.msgFileSizeLarge)));
                    } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
                      dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className={classNames(styles.actions)} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        onClick={(e: any) => {
                          if (values.documentType === '') {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(notificationError(t('msg_select_doc_type' as Messages)));
                          }
                        }}
                        label="Continue"
                        size={ButtonSizes.fill}
                        type={ButtonTypes.button}
                        isFullWidth
                      />
                    </div>
                  )}
                </Dropzone>
              )}

              {draggedFiles.length > 0 && (
                <Button label="Continue" size={ButtonSizes.fill} type={ButtonTypes.submit} isFullWidth />
              )}

              <AutoToggleForm />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UploadDocument;
