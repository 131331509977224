export { default as mutationCreateDC } from '@Graphql/dc/mutations/createDC.graphql';
export { default as mutationUpdateDC } from '@Graphql/dc/mutations/updateDC.graphql';
export { default as mutationDeleteDC } from '@Graphql/dc/mutations/deleteDC.graphql';
export { default as mutationCreateCollaborator } from '@Graphql/dc/mutations/createCollaborator.graphql';
export { default as mutationUpdateCollaborator } from '@Graphql/dc/mutations/updateCollaborator.graphql';
export { default as mutationDeleteCollaborator } from '@Graphql/dc/mutations/deleteCollaborator.graphql';
export { default as mutationUploadDocuments } from '@Graphql/dc/mutations/uploadDocument.graphql';
export { default as mutationDeleteDocuments } from '@Graphql/dc/mutations/deleteDocument.graphql';
export { default as mutationSendOne } from '@Graphql/dc/mutations/sendOne.graphql';
export { default as mutationSendAll } from '@Graphql/dc/mutations/sendAll.graphql';
export { default as mutationChangeDCStatus } from '@Graphql/dc/mutations/changeDCStatus.graphql';
export { default as mutationDeleteDCSP } from '@Graphql/dc/mutations/deleteDCSP.graphql';
export { default as mutationDownloadCaseInfo } from '@Graphql/dc/mutations/caseInfoDownload.graphql';
export { default as mutationMarkReferredDeathIrrelevant } from '@Graphql/dc/mutations/markReferredDeathIrrelevant.graphql';
