import { connect } from 'react-redux';

import WillForm, { WillFormProps, WillFormStateProps } from '@Routes/deathCase/DeathCasePage/WillForm/WillForm';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
});

export default connect<WillFormStateProps, {}, WillFormProps, RootState>(mapStateToProps)(WillForm);
