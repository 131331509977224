import classNames from 'classnames';
import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import Button, { ButtonStyles } from '@Components/Button';
import { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { fileUploadSettings } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';

import styles from '../FileInput/FileInput.scss';

import logoStyle from './ImageUpload.scss';

export interface ImageUploadProps {
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  smallWrapperMargin?: boolean;
  unsetMinHeight?: boolean;
  onRemoveClick?: (id: string, index: number) => void;
  disableRemoval?: boolean;
  onDrop: (file: File[]) => void;
  imgFile: any;
}

interface ImageUploadPrivateProps extends ImageUploadProps {}

const ImageUpload: React.FunctionComponent<ImageUploadPrivateProps> = (props) => {
  const { onDrop, multiple = false } = props;
  const t = useTranslations();
  const dispatch = useDispatch();
  const { maxUploadSize, imageUploadFormats } = fileUploadSettings;
  return (
    <>
      <Dropzone
        maxSize={maxUploadSize}
        disabled={props.disabled}
        onDrop={onDrop}
        multiple={multiple}
        accept={imageUploadFormats}
        onDropRejected={(fileRejected: FileRejection[]) => {
          if (fileRejected[0].errors[0].code === 'file-too-large') {
            dispatch(notificationError(t(Messages.msgFileSizeLarge)));
          } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
            dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            className={classNames(styles.wrapperBlock, { [styles.disabled]: props.disabled })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Row justifyCenter constant>
              <Button
                style={ButtonStyles.transparent}
                onClick={() => noop}
                icon="upload"
                iconSize={IconSizes.ss}
                className={classNames({ [styles.disabled]: props.disabled })}
                constant
              >
                <Typography msg={t(Messages.buttonUpload)} tag="span" />
              </Button>
            </Row>
            <Typography size="l" tag="div" msg={t(Messages.labelDragFileIntoBox)} className={styles.topSpacing} />
            <Typography
              size="s"
              color="footerColor"
              tag="div"
              msg={t('label_accepted_image_formats' as Messages)}
              className={styles.topSpacing}
            />
          </section>
        )}
      </Dropzone>

      <div className={logoStyle.logoWrapper}>
        <img src={props.imgFile} alt="" />
      </div>
    </>
  );
};

export default ImageUpload;
