import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { GenericModalType } from '@Components/Modal/GenericModalBody/GenericModalBody';
import { PaperWidths } from '@Components/Paper';
import SelectOnly, { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import { ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationChangeOwnership } from '@Graphql/collaborators/mutations';
import {
  ChangeOwnershipMutation,
  ChangeOwnershipMutationVariables,
  CollaboratorNodeEdge,
  CollaboratorPermission,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

export interface CollaboratorPermissionProps {
  data: any;
  permissionOptions: any;
  allCollaborators: CollaboratorNodeEdge[];
}

const CollaboratorPermissionPage: React.FunctionComponent<CollaboratorPermissionProps> = ({
  data,
  permissionOptions,
  allCollaborators,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const selectedOption =
    data.permission && permissionOptions ? permissionOptions.find((perm: any) => perm.value === data.permission) : null;
  const [assignedOption, setAssignedOption] = React.useState(selectedOption);
  const [changedOption, setChangedOption] = React.useState(selectedOption);

  React.useEffect(() => {
    setAssignedOption(selectedOption);
  }, [selectedOption]);

  const [caseTransferOwnership] = useMutation<ChangeOwnershipMutation, ChangeOwnershipMutationVariables>(
    mutationChangeOwnership,
    {
      onCompleted: () => {
        dispatch(notificationSuccess(t('msg_collab_permission_changed' as Messages)));
        setAssignedOption(changedOption);
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const genericPopupHandler = (perm: any) => {
    return dispatch(
      showModal({
        type: ModalTypes.genericModal,
        params: {
          data: {
            icon: 'alert-triangle',
            title: t('title_transfer_owner' as Messages),
            bodyText: t('text_transfer_owner' as Messages),
            firstButtonMsg: t('button_transfer_owner' as Messages),
            secondButtonMsg: t('link_not_transfer' as Messages),
            isSecondButtonLink: true,
            genericType: GenericModalType,
            id: data.id,
            extraParameter: perm.value,
          },
          title: '',
          modalSize: PaperWidths.m581,
        },
      })
    );
  };

  const handlePermissionChange = (item: any) => {
    if (item.value === CollaboratorPermission.Owner) {
      genericPopupHandler(item);
    } else {
      const getOwners = allCollaborators.find(
        (each: CollaboratorNodeEdge) => each.node.permission === CollaboratorPermission.Owner
      );
      const ownershipChange = getOwners?.node.id === data.id && getOwners?.node.permission !== item.value;
      if (ownershipChange) {
        dispatch(notificationError(t('msg_deny_owner_change' as Messages)));
      } else {
        caseTransferOwnership({
          variables: {
            input: {
              id: data.id,
              permission: item.value,
            },
          },
        });
        setChangedOption(item);
      }
    }
  };

  return (
    <SelectOnly
      size="ss"
      selectClass={ClassNamesForOverwrite.SelectCollaborator}
      options={permissionOptions}
      value={assignedOption}
      onChange={handlePermissionChange}
      disabled={data.isInvitePending}
    />
  );
};

export default CollaboratorPermissionPage;
