import * as React from 'react';
import ReactDocumentTitle from 'react-document-title';

import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

export interface DocumentTitleProps {
  title: Messages;
  children: any;
}

const DocumentTitle: React.FunctionComponent<DocumentTitleProps> = ({ children, title }) => {
  React.useEffect(() => window.scrollTo(0, 0), []);
  const t = useTranslations();

  return <ReactDocumentTitle title={`${t(Messages.appName)}: ${t(title)}`}>{children}</ReactDocumentTitle>;
};

export default DocumentTitle;
