export interface MobileMenuProperties {
  keyId: string;
  link: string;
}

export const authMobileMenu: MobileMenuProperties[] = [
  {
    keyId: 'send_death_notifications',
    link: 'https://lifeledger.com/',
  },

  {
    keyId: 'life_planning',
    link: 'https://lifeledger.com/life-planning/',
  },

  {
    keyId: 'resources',
    link: 'https://lifeledger.com/resources/',
  },

  {
    keyId: 'for_service_providers',
    link: 'https://lifeledger.com/service-providers/',
  },

  {
    keyId: 'for_sector_professionals',
    link: 'https://lifeledger.com/sector-professionals/',
  },

  {
    keyId: 'about_us',
    link: 'https://lifeledger.com/about-us/?nab=1',
  },

  {
    keyId: 'faq',
    link: 'https://lifeledger.com/faq/?nab=1',
  },
];
