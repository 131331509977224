import { connect } from 'react-redux';

import LegalForm, {
  WillAdminFormProps,
  WillAdminFormStateProps,
} from '@Routes/deathCase/DeathCasePage/WillAdminForm/WillAdminForm';
import { getUser } from '@Store/auth/auth.selector';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
  user: getUser(state),
});

export default connect<WillAdminFormStateProps, {}, WillAdminFormProps, RootState>(mapStateToProps)(LegalForm);
