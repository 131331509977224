export interface CookieDetails {
  name: string;
  description: string;
  type: string;
}

export const privacyCookie: CookieDetails = {
  name: 'Privacy Overview',
  description:
    'We do not use cookies for targeting or advertising. We only use the necessary cookies to make our site work. We would like to set optional cookies to give us valuable feedback on how many people visit us and how they use the site. These cookies do not identify you personally, they just give us statistics. We will not set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences.',
  type: 'Privacy_Overview',
};
export const essentialCookie: CookieDetails = {
  name: 'Essential cookies',
  description:
    'These cookies are strictly necessary and are required to enable core site functionality. This category cannot be disabled.',
  type: 'Essential_cookies',
};

export const functionalCookie: CookieDetails = {
  name: 'Functional cookies',
  description:
    'Functional cookies are used to provide services or to remember settings that improve your visit and to measure and improve the performance of our website. For example, they allow our live chat function to work.',
  type: 'Functional_cookies',
};
export const analyticCookie: CookieDetails = {
  name: 'Analytics cookies',
  description:
    'Analytics cookies can track the sources of traffic to a website. This information includes whether visitors came from search engines, social media, referral links, or other sources. Understanding traffic sources helps in optimizing marketing strategies.',
  type: 'Analytics_cookies',
};
