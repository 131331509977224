import classNames from 'classnames';
import { useField, useFormikContext, FieldProps } from 'formik';
import * as React from 'react';

import Select from '@Components/Select';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import { Messages } from '@Config/messages';
import { hasFieldError } from '@Utils/form';
import { noop } from '@Utils/helpers';

import InputContainer from '../InputContainer';

import styles from './SelectInput.scss';

export type OptionItem = {
  label: string;
  value: any;
};

export interface SelectFieldProps extends FieldProps {
  options: OptionItem[];
  label: Messages;
  placeholder: Messages;
  onSelectChange: (e: any) => void;
  isMulti: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  size?: any;
  className?: string;
  selectClass?: string;
  formatOptionLabel?: Function;
  customStyles?: any;
  required?: boolean;
  onFocus: () => void;
  isSearchable?: boolean;
  isLoading?: boolean;
  boldLabel?: boolean;
  hasErrorProp?: boolean;
}

const SelectInput: React.FunctionComponent<SelectFieldProps> = ({
  label,
  options,
  form,
  field,
  placeholder,
  isMulti,
  isClearable,
  size,
  disabled,
  selectClass = ClassNamesForOverwrite.SelectContainer,
  onSelectChange = noop,
  className,
  customStyles,
  required,
  onFocus = noop,
  isSearchable,
  isLoading,
  boldLabel,
  hasErrorProp,
}) => {
  const [input, meta] = useField(field);
  const hasError = hasFieldError(meta, form);
  const { setFieldValue } = useFormikContext();
  const handleOnChange = (val: any) => {
    setFieldValue(input.name, val);
    onSelectChange(val);
  };

  return (
    <InputContainer
      form={form}
      label={label}
      meta={meta}
      showErrorIcon={true}
      required={required}
      containerClass={classNames(styles.container)}
      labelBold={boldLabel}
    >
      <Select
        placeholder={placeholder}
        input={input}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        size={size}
        className={className}
        withError={hasErrorProp || hasError}
        onChange={handleOnChange}
        disabled={disabled}
        selectClass={selectClass}
        customStyles={customStyles}
        onFocus={onFocus}
        isSearchable={isSearchable}
        isLoading={isLoading}
      />
    </InputContainer>
  );
};
export default SelectInput;
