import classNames from 'classnames';
import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import FileView from '@Components/application/FileView';
import Button, { ButtonStyles } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { fileUploadSettings } from '@Config/constants';
// import { Messages } from '@Config/messages';
import { Messages } from '@Config/messages';
import { DocumentNode } from '@Graphql/graphqlTypes.generated';
// import { useTranslations } from '@Hooks/useTranslations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { noop } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';

import styles from './FileInput.scss';

export interface FileInputProps {
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  smallWrapperMargin?: boolean;
  unsetMinHeight?: boolean;
  onRemoveClick?: (id: string, index: number) => void;
  disableRemoval?: boolean;
  onDrop: (file: File[]) => void;
  files: Array<DocumentNode>;
  fileNameCenter?: boolean;
  isLifeCase?: boolean;
  isReadOnlyUser?: boolean;
  isLifeEvent?: boolean;
  isSmartModal?: boolean;
  isDeathCertificate?: boolean;
}

interface FileInputPrivateProps extends FileInputProps {}

const FileInput: React.FunctionComponent<FileInputPrivateProps> = (props) => {
  const dispatch = useDispatch();
  const {
    multiple = true,
    onDrop,
    files,
    onRemoveClick = noop,
    fileNameCenter = false,
    isLifeCase,
    isReadOnlyUser,
    isLifeEvent = false,
    isSmartModal,
    isDeathCertificate,
  } = props;
  const t = useTranslations();
  const { maxUploadSize, supportedUploadFormats, imageUploadFormats, maxOtherFileSize } = fileUploadSettings;
  const isFileInputEmpty = files.length === 0;
  const user = useSelector(getUser);

  const isImageFile = (fileType: string) => {
    return imageUploadFormats.some((format) => fileType.endsWith(format));
  };

  const getMaxSizeForFileType = (fileType: string) => {
    return isImageFile(fileType) ? maxUploadSize : maxOtherFileSize;
  };

  const blockDocumentAccess = (fileDetails: any) => {
    if (isLifeCase && fileDetails.collaborator) {
      return isReadOnlyUser || (!fileDetails.collaboratorsView && fileDetails.collaborator.email !== user?.email);
    }
    return false;
  };

  return (
    <>
      <Dropzone
        maxSize={maxUploadSize}
        disabled={props.disabled}
        onDrop={(acceptedFiles) => {
          if (isDeathCertificate) {
            const filteredFiles = acceptedFiles.filter((file) => {
              const maxSizeForFileType = getMaxSizeForFileType(file.name);
              if (file.size > maxSizeForFileType) {
                if (isImageFile(file.name)) {
                  dispatch(notificationError(t('label_image_size' as Messages)));
                } else {
                  dispatch(notificationError(t('label_pdf_file_size' as Messages)));
                }
                return false;
              }
              return true;
            });
            onDrop(filteredFiles);
          } else {
            onDrop(acceptedFiles);
          }
        }}
        multiple={multiple}
        accept={supportedUploadFormats}
        onDropRejected={(fileRejected: FileRejection[]) => {
          if (fileRejected[0].errors[0].code === 'file-too-large') {
            dispatch(notificationError(t(Messages.msgFileSizeLarge)));
          } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
            dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            className={classNames(styles.wrapperBlock, { [styles.disabled]: props.disabled })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {!isLifeEvent && (
              <Row justifyCenter constant>
                <Button
                  style={ButtonStyles.transparent}
                  onClick={() => noop}
                  icon="upload"
                  iconSize={IconSizes.ss}
                  className={classNames({ [styles.disabled]: props.disabled })}
                  constant
                >
                  <Typography msg={t(Messages.buttonUpload)} tag="span" />
                </Button>
              </Row>
            )}
            {isLifeEvent && (
              <>
                <Typography
                  msg="Upload bank statement, tenancy agreement, utility bill or other proof of new address"
                  size="l"
                  tag="div"
                  className={styles.textWidth}
                  bold
                />
                <Row justifyCenter constant className={styles.flex}>
                  <Icon icon="upload" size={IconSizes.s24} />
                  <Typography msg="Upload" tag="span" align="justify" textCenter />
                </Row>
              </>
            )}
            <Typography size="l" tag="div" msg={t(Messages.labelDragFileIntoBox)} className={styles.topSpacing} />
            <Typography
              size="m"
              color="footerColor"
              tag="div"
              msg={t(Messages.labelAcceptedFileFormats)}
              className={styles.topSpacing}
            />
          </section>
        )}
      </Dropzone>
      {!isFileInputEmpty && (
        <>
          {files.map((fileDetails: DocumentNode, idx: number) => (
            <FileView
              key={idx}
              fileDetails={fileDetails}
              isGray
              containerClassName={styles.fileContainer}
              onRemoveClick={() => onRemoveClick(fileDetails.id, idx)}
              fileId={fileDetails.id}
              idx={idx}
              fileNameCenter={fileNameCenter}
              blockDocumentAccess={blockDocumentAccess(fileDetails)}
              isSmartModal={isSmartModal}
            />
          ))}
        </>
      )}
    </>
  );
};

export default FileInput;
