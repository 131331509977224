import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import moment from 'moment';
import { generatePath } from 'react-router';

import config, { NodeEnvEnum } from '@Config/config';
import { Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { logError } from '@Utils/helpers';
import { deleteFromLocalStorage, getFromLocalStorage } from '@Utils/localStorage';

import { refreshTokenMutation } from './auth/auth.mutations';

const authLink = setContext((_, { headers }) => {
  const token = getFromLocalStorage(LocalStorage.userToken);
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (config.NODE_ENV !== NodeEnvEnum.production) {
    if (networkError?.message) {
      logError(networkError.message);
    }

    (graphQLErrors || []).forEach((error) => {
      logError(error.message);
    });
  }
  if (graphQLErrors) {
    if (graphQLErrors.some((error) => error.message === Messages.JWTTokenExpiredOrInvalid)) {
      deleteFromLocalStorage(LocalStorage.userToken);
      deleteFromLocalStorage(LocalStorage.accessTokenExpiry);
      window.location.replace(generatePath(Links.login));
    }
  }
});

const graphqlInterceptor = new ApolloLink((operation: any, forward) => {
  const isStaffUser = getFromLocalStorage(LocalStorage.accessTokenExpiry);
  const operationType = operation.query.definitions[0]?.name.value.toLowerCase();
  if (isStaffUser && operationType !== 'refreshtoken') {
    const dateObject = moment(isStaffUser, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    const currentTime = moment();
    // Calculate the time difference in seconds
    const timeDifferenceInSeconds = dateObject.diff(currentTime, 'seconds');
    if (timeDifferenceInSeconds <= 0) {
      refreshTokenMutation(getFromLocalStorage(LocalStorage.userToken));
    }
  }
  return forward(operation);
});

const graphqlClient = new ApolloClient({
  link: ApolloLink.from([
    authLink.concat(graphqlInterceptor),
    errorLink,
    createUploadLink({ uri: `${config.API_BASE_URL}/graphql/` }),
  ]),
  cache: new InMemoryCache({
    resultCaching: false,
    freezeResults: false,
  }),
});

export default graphqlClient;
