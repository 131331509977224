import classNames from 'classnames';
import * as React from 'react';
import { ActionCreator } from 'redux';

import Typography, { TextSizes } from '@Components/Typography';
import { noop } from '@Utils/helpers';

import styles from './LinkButton.scss';

export interface LinkButtonProps {
  linkTitle: string;
  disabled?: boolean;
  onClick: ActionCreator<void> | (() => void);
  size?: TextSizes;
  className?: string;
  tabIndex?: number;
  textCenter?: boolean;
  onKeyDown?: (e: any) => void;
  isRed?: boolean;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
  linkTitle,
  disabled,
  onClick,
  onKeyDown,
  className,
  textCenter = false,
  isRed = false,
}) => {
  const notDisabled = isRed ? 'red' : 'electricBlue';
  return (
    /* eslint-disable-next-line */
    <div tabIndex={0}
      onKeyDown={onKeyDown}
      className={classNames(styles.linkButton, isRed ? styles.red : styles.blue, className, {
        [styles.textAlignCenter]: textCenter,
      })}
      onClick={disabled ? noop : onClick}
    >
      <Typography color={disabled ? 'gray' : notDisabled} bold size="mb" tag="span" msg={linkTitle} />
    </div>
  );
};

export default LinkButton;
