import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import AuthPageLayout from '@Components/layout/AuthPageLayout';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';

import { AuthSuggestions } from '../shared/SuggestionBlock/SuggestionBlock';

import LoginForm from './LoginForm';

export interface LoginPageProps {}

const LoginPage: React.FunctionComponent<LoginPageProps> = () => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  return (
    <AuthPageLayout
      titleKey={Messages.loginFormTitle}
      isSmallLayout={true}
      leftBottomComponent={<SuggestionBlock authType={AuthSuggestions.login} suggestLogin={false} />}
      isLogin={true}
      showFooter={!(isTablet || isMobile)}
      loginRegisterVisibility={!isMobile}
    >
      <LoginForm />
    </AuthPageLayout>
  );
};

export default LoginPage;
