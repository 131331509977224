import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import RadioButton from '@Components/form/inputs/RadioButton';
import Icon, { IconSizes } from '@Components/Icon';
import { EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './ToggleSwitch.scss';

export interface ToggleSwitchProps {
  value: any;
  setValue: (val: boolean) => void;
  label?: string;
  toggleLabels?: string[];
  disabled?: boolean;
  firstValue?: boolean;
  secondValue?: boolean;
  wrapperClassName?: string;
  infoMessage?: string;
  infoTitle?: string;
  name?: string;
}

interface ToggleSwitchPrivateProps extends ToggleSwitchProps {}

const ToggleSwitch: React.FunctionComponent<ToggleSwitchPrivateProps> = (props) => {
  const { label, toggleLabels, value, setValue, disabled, wrapperClassName, infoMessage, infoTitle, name } = props;
  const t = useTranslations();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const labels = toggleLabels || [t(Messages.labelYes), t(Messages.labelNo)];
  return (
    <div className={classNames(styles.toggleSwitchContainer, wrapperClassName)}>
      {label && (
        <div className={classNames(styles.label, styles.positionRelative)}>
          {label}
          {infoMessage && (
            <Tooltip
              position={isMobile ? TooltipPosition.top : TooltipPosition.right}
              message={infoMessage}
              title={infoTitle}
              className={styles.tooltipMaxWidth}
            >
              <Icon size={IconSizes.sxs} icon="info" className={styles.margin} />
            </Tooltip>
          )}
        </div>
      )}
      <div>
        <RadioButton
          value={value as any}
          checked={value === false}
          name={`${name}_${labels[1]}`}
          onChange={() => setValue(!value)}
          label={labels[1]}
          disabled={disabled}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              setValue(!value);
            }
          }}
        />

        <RadioButton
          value={value as any}
          checked={value === true}
          name={`${name}_${labels[0]}`}
          onChange={() => setValue(!value)}
          label={labels[0]}
          disabled={disabled}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              setValue(!value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ToggleSwitch;
