import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ModalProviderProps } from '@Components/Modal/Modal';
import { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useGetMeQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';
import { deleteFromLocalStorage, getBooleanValueCookie, setToLocalStorage } from '@Utils/localStorage';
import { idVerfiyfileUploadHandler } from '@Utils/modal';

import styles from './IDValidationError.scss';

export interface ValidationErrorProps extends NavigationProps {
  isSettings?: boolean;
  fromNotification?: boolean;
  onClose?: () => void;
  deathCaseServiceProviderId?: string;
}

const IDValidationError: React.FunctionComponent<ModalProviderProps<ValidationErrorProps>> = ({
  closeModal,
  modalData: { isSettings, fromNotification, onClose = noop, deathCaseServiceProviderId },
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const firstFail = getBooleanValueCookie(LocalStorage.idVerificationFirstFail);
  const { data, loading } = useGetMeQuery({
    fetchPolicy: 'network-only',
    skip: !firstFail,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const failureReasons = [t('label_failure_reason_one' as Messages), t('label_failure_reason_two' as Messages)];

  return (
    <>
      <Row constant justifyCenter>
        <Col className={styles.relative} alignCenter>
          <Icon icon="person" size={IconSizes.l} className={styles.personIcon} />
          <Icon
            icon="alert-triangle"
            size={IconSizes.ssm}
            className={isSettings ? styles.alertTriangleSettings : styles.alertTriangle}
          />
        </Col>
      </Row>
      <Typography msg={t('title_id_verification_failed' as Messages)} tag="h4" size="xl" bold textCenter />
      {!firstFail && (
        <>
          <Typography
            msg={t('label_possible_reasons_for_failure' as Messages)}
            tag="div"
            size="m"
            bold
            className={styles.topSpacing}
          />
          <List listItems={failureReasons} fontSize="m" />
          <Typography
            className={styles.subtitleSpacing}
            msg={t('label_please_try_again' as Messages)}
            tag="div"
            size="m"
          />
        </>
      )}

      {firstFail && (
        <Typography
          textCenter
          msg={t('label_manual_id_verification' as Messages)}
          tag="div"
          size="m"
          className={styles.topSpacing}
        />
      )}

      <Row
        className={classNames(styles.subtitleSpacing, { [styles.notificationContinueButton]: fromNotification })}
        justifyCenter
      >
        <Button
          type={ButtonTypes.button}
          onClick={() => {
            if (fromNotification) {
              if (!firstFail) {
                setToLocalStorage(LocalStorage.idVerificationFirstFail, true);
                idVerfiyfileUploadHandler(dispatch, deathCaseServiceProviderId);
              } else {
                closeModal();
                deleteFromLocalStorage(LocalStorage.idVerificationFirstFail);
                //history.push(generatePath(`${Links.settings}`, { subpage: SettingsSubpages.IDValidation }));
              }
            } else {
              closeModal();
              onClose();
            }
          }}
          isFullWidth
          label={firstFail ? t('label_ok' as Messages) : t('button_try_again' as Messages)}
        />
      </Row>

      {data && <div className={styles.hide}>{data.me.info.firstName}</div>}
    </>
  );
};

export default IDValidationError;
