import classNames from 'classnames';
import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './MobileNavigator.scss';

export interface MobileNavigatorProps {
  activeStepIdx: number;
  steps: string[];
  title: string;
  id?: string;
  isLifeCase?: boolean;
  missing?: boolean;
}

const MobileNavigator: React.FunctionComponent<MobileNavigatorProps> = ({
  steps,
  activeStepIdx,
  isLifeCase,
  id,
  missing,
}) => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const link = isLifeCase ? Links.lcEdit : Links.dcEdit;

  const clickHandler = (sidebarItem: string) => {
    return history.push(generatePath(`${link}${queryString}`, { id, subpage: sidebarItem, missing }));
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.lifeLedgerIcon}>
          <div className={styles.clickSection} onClick={() => history.push(`${Links.home}${queryString}`)}>
            <img width="173" height="37" src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg" alt="" />
          </div>
        </div>
        <div className={styles.divider} />

        <div className={classNames(styles.flexDisplay, styles.mt34)}>
          {steps.map((sidebarItem: any, index: number) => {
            return (
              <>
                <button onClick={() => clickHandler(sidebarItem)} className={styles.buttonControl}>
                  <span
                    className={classNames(styles.defaultCircle, {
                      [styles.tickCircle]: activeStepIdx > index,
                      [styles.activeCircle]: activeStepIdx === index,
                    })}
                  >
                    {activeStepIdx > index && <Icon icon="tick" size={IconSizes.sxs} />}
                  </span>
                </button>
                {steps.length - 1 !== index && (
                  <div
                    className={classNames(styles.stepperLine, { [styles.completedStepperLine]: activeStepIdx > index })}
                  />
                )}
              </>
            );
          })}
        </div>

        <div className={classNames(styles.flexDisplay, styles.mt7)}>
          {steps.map((sidebarItem, index) => {
            return (
              <div key={index} className={styles.pageName}>
                <Typography
                  msg={t(
                    `${
                      isLifeCase ? Messages.titleCreateLCSubItemPrefix : Messages.titleCreateDCSubItemPrefix
                    }${sidebarItem}` as Messages
                  )}
                  tag="div"
                  size="l"
                  bolder
                  className={classNames({ [styles.hideUnselectedPageName]: activeStepIdx !== index })}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MobileNavigator;
