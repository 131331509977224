import classNames from 'classnames';
import { FieldProps, useField, useFormikContext } from 'formik';
import * as React from 'react';

import InputContainer from '@Components/form/inputs/InputContainer';
import Row from '@Components/layout/Row';
import { hasFieldError } from '@Utils/form';

import styles from './DatePickerInput.scss';

export interface DatePickerInputProps {
  placeholder?: string;
  className?: string;
  required?: boolean;
  label?: string;
  showError?: boolean;
  disabled?: boolean;
  boldLabel?: string;
  nextFieldName?: string;
  useFieldValue?: boolean;
  noFutureDateValidation?: boolean;
  idSelector?: string;
}

interface DatePickerInputPrivateProps extends DatePickerInputProps, FieldProps {}

const DatePickerInput: React.FunctionComponent<DatePickerInputPrivateProps> = (props) => {
  const {
    label,
    required,
    form,
    className,
    boldLabel,
    nextFieldName,
    disabled,
    useFieldValue,
    noFutureDateValidation,
    idSelector,
  } = props;

  const [field, meta] = useField(props.field);
  const hasError = hasFieldError(meta, form);
  const { setFieldValue } = useFormikContext();
  const splitAndReverse = field.value ? field.value.split('-').reverse() : [];
  const existingDay = splitAndReverse.length > 0 ? splitAndReverse[0] : '';
  const existingMonth = splitAndReverse.length > 0 ? splitAndReverse[1] : '';
  const existingYear = splitAndReverse.length > 0 ? splitAndReverse[2] : '';

  const currentDate = new Date();
  const currYear = currentDate.getFullYear();

  const [stateDay, setStateDay] = React.useState(existingDay);
  const [stateMonth, setStateMonth] = React.useState(existingMonth);
  const [stateYear, setStateYear] = React.useState(existingYear);
  React.useEffect(() => {
    setStateDay(existingDay);
    setStateMonth(existingMonth);
    setStateYear(existingYear);
  }, [existingDay, existingMonth, existingYear]);

  const combineAndSet = (from: string, inputVal: string) => {
    let formattedDate = `${stateYear}-${stateMonth}-${stateDay}`;
    if (from === 'DD') {
      formattedDate = `${stateYear}-${stateMonth}-${inputVal}`;
    } else if (from === 'MM') {
      formattedDate = `${stateYear}-${inputVal}-${stateDay}`;
    } else {
      formattedDate = `${inputVal}-${stateMonth}-${stateDay}`;
    }
    if (formattedDate === '--') {
      setFieldValue(field.name, '');
    } else {
      setFieldValue(field.name, formattedDate);
    }
  };

  const monthAndLeapYear = () => {
    const listofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (stateMonth === 1 || stateMonth > 2) {
      if (stateDay > listofDays[stateMonth - 1]) {
        setStateDay(`${listofDays[stateMonth - 1]}`);
        combineAndSet('DD', `${listofDays[stateMonth - 1]}`);
      }
    }
    if (stateMonth === 2) {
      let lyear = false;
      if ((!(stateYear % 4) && stateYear % 100) || !(stateYear % 400)) {
        lyear = true;
      }
      if (lyear === false && stateDay >= 29) {
        setStateDay(`${listofDays[stateMonth - 1]}`);
        combineAndSet('DD', `${listofDays[stateMonth - 1]}`);
      }
      if (lyear === true && stateDay > 29) {
        setStateDay('29');
        combineAndSet('DD', '29');
      }
    }
  };

  const setFocus = (selector: string) => {
    const nextElement = document.querySelector(`#${selector}`) as HTMLElement;
    return nextElement.focus();
  };

  return (
    <InputContainer
      showErrorIcon={true}
      meta={meta}
      form={form}
      label={label}
      required={required}
      containerClass={className}
      fullWidth
      boldLabel={boldLabel}
    >
      <Row constant className={styles.flexGap}>
        <input
          id={`${idSelector || field.name}_dd`}
          type="number"
          className={classNames(styles.input, styles.ddMMWidth, {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
          })}
          autoComplete="off"
          placeholder="DD"
          value={useFieldValue ? existingDay : stateDay}
          onChange={(e: any) => {
            const currTarget = e.currentTarget.value;
            const currTargetInt = parseInt(currTarget, 10);
            if (currTargetInt > 31 || currTarget.length > 2) {
              e.preventDefault();
            } else {
              if (currTarget.length === 2) {
                if (currTargetInt === 0) {
                  setStateDay('01');
                  combineAndSet('DD', '01');
                }
                setFocus(`${idSelector || field.name}_mm`);
              }
              setStateDay(currTarget);
              combineAndSet('DD', currTarget);
            }
          }}
          onBlur={(e: any) => {
            const currTarget = e.currentTarget.value;
            const currTargetInt = parseInt(currTarget, 10);
            if (currTargetInt === 0) {
              setStateDay('01');
              combineAndSet('DD', '01');
            } else if (currTarget.length === 1) {
              const prefixValue = `0${currTarget}`;
              setStateDay(prefixValue);
              combineAndSet('DD', prefixValue);
            }
            monthAndLeapYear();
          }}
        />
        <input
          type="number"
          id={`${idSelector || field.name}_mm`}
          className={classNames(styles.input, styles.ddMMWidth, {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
          })}
          autoComplete="off"
          placeholder="MM"
          value={useFieldValue ? existingMonth : stateMonth}
          onKeyDown={(e: any) => {
            const currTarget = e.currentTarget.value;
            if (e.keyCode === 8 && currTarget.length === 0) {
              setFocus(`${idSelector || field.name}_dd`);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            const currTarget = e.currentTarget.value;
            const currTargetInt = parseInt(currTarget, 10);
            if (currTargetInt > 12 || currTarget.length > 2) {
              e.preventDefault();
            } else {
              if (currTarget.length === 2) {
                if (currTargetInt === 0) {
                  setStateDay('01');
                  combineAndSet('MM', '01');
                }
                setFocus(`${idSelector || field.name}_yyyy`);
              }
              if (currTarget.length === 0 && stateMonth.length > 0) {
                setFocus(`${idSelector || field.name}_dd`);
              }
              setStateMonth(currTarget);
              combineAndSet('MM', currTarget);
            }
          }}
          onBlur={(e: any) => {
            const currTarget = e.currentTarget.value;
            const currTargetInt = parseInt(currTarget, 10);
            if (currTargetInt === 0) {
              setStateMonth('01');
              combineAndSet('MM', '01');
            } else if (currTarget.length === 1) {
              const prefixValue = `0${currTarget}`;
              setStateMonth(prefixValue);
              combineAndSet('MM', prefixValue);
            }
            monthAndLeapYear();
          }}
        />

        <input
          type="number"
          id={`${idSelector || field.name}_yyyy`}
          className={classNames(styles.input, styles.yyWidth, {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
          })}
          autoComplete="off"
          placeholder="YYYY"
          value={useFieldValue ? existingYear : stateYear}
          onKeyDown={(e: any) => {
            const currTarget = e.currentTarget.value;
            if (e.keyCode === 8 && currTarget.length === 0) {
              setFocus(`${idSelector || field.name}_mm`);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            const currTarget = e.currentTarget.value;
            const currTargetInt = parseInt(currTarget, 10);
            const futureDateValidation = noFutureDateValidation ? false : currTargetInt > currYear;
            if (futureDateValidation || currTarget.length > 4) {
              e.preventDefault();
            } else {
              if (currTarget.length === 4 && nextFieldName && currTargetInt >= 1900) {
                setFocus(`${nextFieldName}_dd`);
              }
              if (currTarget.length === 0 && stateYear.length > 0) {
                setFocus(`${idSelector || field.name}_mm`);
              }
              setStateYear(currTarget);
              combineAndSet('YYYY', currTarget);
            }
          }}
          onBlur={(e: any) => {
            const currTarget = e.currentTarget.value;
            const currTargetInt = parseInt(currTarget, 10);
            if (currTargetInt < 1900) {
              setStateYear('1900');
              combineAndSet('YYYY', '1900');
            }
            monthAndLeapYear();
          }}
        />
      </Row>
    </InputContainer>
  );
};
export default DatePickerInput;
