import * as React from 'react';

import LifeEventPage from '../LifeEventPage';

const CreateLifeEvent: React.FunctionComponent = () => {
  return (
    <>
      <LifeEventPage />
    </>
  );
};

export default CreateLifeEvent;
