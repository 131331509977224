import * as React from 'react';

import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useGetNordBanksQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './NotSupportedBanks.scss';

export interface NotSupportedBanks {}

const NotSupportedBanks: React.FunctionComponent<NotSupportedBanks> = () => {
  const t = useTranslations();

  const nordigenInstitutes = useGetNordBanksQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { country: 'GB' },
  });

  if (nordigenInstitutes.loading) {
    return <LoadingSpinner />;
  }

  const inActiveBanks = nordigenInstitutes.data?.getNordBanks
    .filter((bnks: any) => !bnks.isActive)
    .map((banks: any) => banks.name) as any[];
  const unsupportedBanks = [];

  for (let i = 0; i < inActiveBanks?.length; i += 2) {
    const first = inActiveBanks[i];
    let second = '';
    if (inActiveBanks[i + 1]) {
      second = inActiveBanks[i + 1];
    }
    unsupportedBanks.push(
      <Row constant columnMobile>
        <Col size={6}>
          <li className={styles.bankNameFont}>{first}</li>
        </Col>
        {second && (
          <Col size={6}>
            <li className={styles.bankNameFont}>{second}</li>
          </Col>
        )}
      </Row>
    );
  }

  return (
    <>
      <Typography bold msg={t('link_not_supported_banks' as Messages)} tag="h4" size="xl" className={styles.mb20} />
      {unsupportedBanks}
    </>
  );
};

export default NotSupportedBanks;
