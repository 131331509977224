import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './ConfirmModalBody.scss';

export interface ConfirmModalBodyProps {
  onClose?: () => void;
  buttonText?: string;
  secondButtonText?: string;
  secondButtonAction?: () => void;
  leaveOpen?: boolean;
  paymentMessage?: string;
  content?: string;
  deleteMessage?: string;
  priceId?: string;
  needSecondButtonCallback?: boolean;
}

const ConfirmModalBody: React.FunctionComponent<ModalProviderProps> = ({
  modalData: {
    buttonText,
    onClose = noop,
    leaveOpen = false,
    paymentMessage,
    secondButtonText,
    secondButtonAction,
    content,
    deleteMessage,
    priceId,
    needSecondButtonCallback = false,
  },
  closeModal,
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(t(error.message as Messages))));
    },
  });

  const onButtonClick = () => {
    onClose();
    if (!leaveOpen) {
      closeModal();
    }
  };
  const onSecondButtonClick = () => {
    if (needSecondButtonCallback) {
      secondButtonAction();
    } else {
      closeModal();
    }
  };

  const history = useHistory();
  const queryString = history.location.search;

  const onPreventClick = () => {
    closeModal();
    if (priceId) {
      stripeCreateDcSubscriptionMutation({ variables: { input: { priceId } } });
    } else {
      history.push(`${Links.payments}${queryString}`);
    }
  };

  const t = useTranslations();

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  return paymentMessage ? (
    <div>
      <Row justifyCenter>
        <Icon icon="lock" className={styles.lockIcon} size={IconSizes.m} />
      </Row>
      <Typography textCenter msg={t(Messages.modalPlanUpgradeTitle)} tag="h4" size="xl" bold />
      <Row alignCenter justifyCenter className={styles.bottomSpacing}>
        <Typography textCenter msg={paymentMessage} tag="span" className={styles.title} />
      </Row>
      <Row className={styles.bottomSpacing} alignCenter>
        <Button label={t(Messages.buttonUpgradeNow)} size={ButtonSizes.fill} onClick={onPreventClick} />
      </Row>
      <Row justifyCenter alignCenter>
        <LinkButton linkTitle={t('link_no_thanks' as Messages)} onClick={onButtonClick} />
      </Row>
    </div>
  ) : (
    <>
      {content && <Typography className={styles.bottomSpacing} msg={content} tag="div" size="l" />}
      {deleteMessage && <Typography className={styles.bottomSpacing} msg={deleteMessage} tag="div" size="l" />}
      <div className={classNames(styles.actions)}>
        <Button label={buttonText} size={ButtonSizes.fill} onClick={onButtonClick} />
        {secondButtonText && (
          <Button
            label={secondButtonText}
            size={ButtonSizes.fill}
            style={ButtonStyles.white}
            onClick={onSecondButtonClick}
          />
        )}
      </div>
    </>
  );
};

export default ConfirmModalBody;
