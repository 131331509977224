import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import ProviderDashboardPage from '@Routes/provider/ProviderDashboardPage';

import { RouteInfo } from '../appRoutes';

import NotificationDashboard from './NotificationDashboard';
import { ProviderSubpages } from './providerSubpages';
import ReportsPage from './Reports/ReportsPage';
import StaffDashboard from './StaffDashboardPage/StaffDashboardPage';

const routes: RouteInfo[] = [
  {
    path: Links.providerDashboard,
    component: ProviderDashboardPage,
    title: Messages.pageProviderDashboard,
    isServiceProviderRoute: true,
  },
  {
    path: Links.staffDashboard,
    component: StaffDashboard,
    title: Messages.staffDashboard,
    isServiceProviderRoute: true,
  },
  {
    path: Links.professionalDashboard,
    component: ProviderDashboardPage,
    title: Messages.pageProviderDashboard,
    isServiceProviderRoute: false,
  },
  {
    path: Links.serviceProviderReports,
    component: ReportsPage,
    title: `${Messages.titleProviderSubItemPrefix}${ProviderSubpages.Reports}` as Messages,
    isServiceProviderRoute: true,
  },
  {
    path: Links.notificationDashboard,
    component: NotificationDashboard,
    title: Messages.pageProviderDCInfo,
    isServiceProviderRoute: true,
  },
];

export default routes;
