export interface RelationShipDefinition {
  label: string;
  value: string;
  relationshipType: number;
  selected?: boolean;
  index: number;
  infoMessage?: string;
  infoTitle?: string;
  disabled?: boolean;
}

export enum RelationshipLevel {
  firstLevel,
  secondLevel,
}
