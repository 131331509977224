import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';

import { ModalProviderProps } from '../Modal';

import styles from './LoadingModalBody.scss';

export interface LoadingModalBodyProps {
  logoName: string;
  title: string;
  firstMessage: string;
  secondMessage: string;
  firstMessageType: string;
}

const LoadingModalBody: React.FunctionComponent<ModalProviderProps<LoadingModalBodyProps>> = ({
  modalData: { logoName, title, firstMessage, secondMessage, firstMessageType },
}) => {
  return (
    <>
      <Row justifyCenter>
        <Icon
          size={logoName === 'stripe' ? IconSizes.stripe : IconSizes.gocardless}
          className={styles.mb20}
          icon={logoName}
        />
      </Row>
      <Typography className={styles.mb8} msg={title} tag="div" bold size="xl" textCenter />
      <Typography msg={`${firstMessage} ${firstMessageType}.`} tag="div" size="l" textCenter />
      <Typography className={styles.mb50} msg={secondMessage} tag="div" size="l" textCenter />
      <Row justifyCenter>
        <LoadingSpinner />
      </Row>
    </>
  );
};

export default LoadingModalBody;
