import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { LocalStorage, ModalTypes, NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, LifeCaseNode, UserReferralSource } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import SummaryInfo from '@Routes/deathCase/SummaryInfo';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { updateDC } from '@Store/dc/dc.actions';
import { manipulateTranslations, noop } from '@Utils/helpers';
import { deleteFromLocalStorage } from '@Utils/localStorage';
import { basicPaymentHandler, paymentHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';
import { validateRequiredBoolean } from '@Utils/validator';

import styles from './ConfirmationForm.scss';

export interface ConfirmationFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
}

export interface ConfirmationFormStateProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
}

interface ConfirmationFormPrivateProps extends ConfirmationFormProps, ConfirmationFormStateProps {}

enum ConfirmationFormFields {
  hasAuthority = 'hasAuthority',
  // consent = 'consent',
}

const ConfirmationForm: React.FunctionComponent<ConfirmationFormPrivateProps> = ({
  data,
  back,
  next,
  setSubpage,
  isLifeCase,
  // containerClasses,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const isHospice =
    user?.referralSource === UserReferralSource.LifeHospice &&
    data?.item?.__typename === NodeDefiner.LifeCase &&
    data.item.credits === null &&
    !data.item.isTerminallyIll &&
    isLifeCase;

  const id = data?.item?.id;
  if (!id) {
    return null;
  }

  const getSaveButton = (isSubmitting: boolean, isValid: boolean) => (
    <Button
      type={ButtonTypes.submit}
      size={ButtonSizes.fill}
      disabled={!isValid}
      loading={isSubmitting}
      label={!id ? 'Confirm and create death case' : 'Save'}
      isFullWidth
    />
  );

  const ConfirmLifeCaseButton = () => (
    <Button
      type={ButtonTypes.submit}
      size={ButtonSizes.fill}
      label={!id ? 'Confirm details' : 'Save'}
      isFullWidth
      onClick={lifeCaseSave}
    />
  );

  const dispatchDeathCaseModal = () => {
    dispatch(
      showModal({
        type: ModalTypes.deathRegisterSuccessFormModal,
        params: {
          data: {
            caseId: id,
            title: t('title_life_case_created_successfully' as Messages),
            bodyMsg: t('desc_death_case_success' as Messages),
            // question: 'Would you like to add companies now?',
            firstButtonMsg: t(Messages.buttonYesAddCompanies),
            isLifeCase: true,
            makeCenter: true,
          },
          title: '',
          modalSize: PaperWidths.widthSuccess,
          showCloseIcon: false,
          disableOutsideClick: true,
        },
      })
    );
  };

  const lifeCaseSave = () => {
    if (!isHospice) {
      return dispatchDeathCaseModal();
    }
    return user?.enableDiscovery
      ? paymentHandler(dispatch, data.item?.id)
      : basicPaymentHandler(t, dispatch, id, isHospice, true);
  };

  const deathCaseItems = data?.item?.__typename === NodeDefiner.DeathCase ? data?.item : null;

  const showWarnMessage = () => {
    if (isTablet || isMobile) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Row size={10} className={styles.marginBottom30}>
        <Typography tag="div" msg={t(Messages.subTitleConfirmationForm)} size="l" />
      </Row>

      {!isLifeCase && (
        <CautionBox
          containerClassName={styles.cautionBox}
          info={manipulateTranslations(
            t,
            'label_caution_dc_confirmation',
            'Make sure the information below is correct, you won’t be able to change it once you have sent your first notification'
          )}
          title={manipulateTranslations(t, 'label_caution', 'Caution')}
        />
      )}

      <SummaryInfo data={data} isLifeCase={isLifeCase} sectionsToCol />
      {!isLifeCase && (
        <Formik
          initialValues={{
            [ConfirmationFormFields.hasAuthority]: deathCaseItems?.hasAuthority || false,
            // [ConfirmationFormFields.consent]: deathCaseItems?.consent || false,
          }}
          validationSchema={Yup.object({
            // [ConfirmationFormFields.consent]: validateRequired(t),
            [ConfirmationFormFields.hasAuthority]: validateRequiredBoolean(t),
          })}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            deleteFromLocalStorage(LocalStorage.deathCaseId);
            dispatch(
              updateDC.started({
                setSubmitting,
                setStatus,
                setNextStep: noop,
                id,
                hasAuthority: values.hasAuthority,
                //consent: values.consent,
                navigateAway: true,
              })
            );

            dispatch(
              showModal({
                type: ModalTypes.deathRegisterSuccessFormModal,
                params: {
                  data: {
                    caseId: id,
                    title: t(Messages.titleDeathCaseCreatedSuccess),
                    bodyMsg: t(Messages.infoDeathCaseCreated),
                    //  question: t(Messages.labelQuestionSendNotifications),
                    firstButtonMsg: t(Messages.buttonSendDeathNotifications),
                    makeCenter: true,
                    lifeCaseData: data.item,
                  },
                  title: '',
                  modalSize: PaperWidths.widthSuccess,
                  showCloseIcon: false,
                  disableOutsideClick: true,
                },
              })
            );
          }}
        >
          {({ isSubmitting, status, values, isValid }) => (
            <Form>
              {/* <div>
                <GridRow className={styles.checkBoxContainer}>
                  <GridCol size={12}>
                    <Field
                      name={ConfirmationFormFields.consent}
                      label="I consent to Life Ledger using my personal information and ID document to perform an identity check"
                      component={Checkbox}
                      type={CheckBoxTypes.checkbox}
                    />
                  </GridCol>
                </GridRow>
              </div> */}
              <div>
                <GridRow className={styles.checkBoxContainer}>
                  <GridCol size={12}>
                    <Field
                      name={ConfirmationFormFields.hasAuthority}
                      label={t(Messages.fieldHasAuthority)}
                      component={Checkbox}
                      type={CheckBoxTypes.checkbox}
                    />
                  </GridCol>
                </GridRow>
              </div>
              <FormError formError={status} />
              <NavigationBlock
                isSubmitting={isSubmitting}
                back={back}
                next={next}
                setSubpage={setSubpage}
                SaveButton={getSaveButton(isSubmitting, isValid)}
                nextButtonTooltipMsg={!values.hasAuthority ? t(Messages.tooltipConfirmation) : ''}
              />
              {showWarnMessage() && (
                <Row size={12}>
                  <Col size={5} />
                  <Col size={7}>
                    <Row>
                      <Icon className={styles.bulbIcon} icon="bulb" size={IconSizes.s} />
                      <Typography
                        msg={t(Messages.labelWarnBeforeDeathCaseSave)}
                        size="m"
                        tag="div"
                        color="footerColor"
                      />
                    </Row>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      )}
      {isLifeCase && (
        <NavigationBlock
          isSubmitting={false}
          back={back}
          next={next}
          setSubpage={setSubpage}
          SaveButton={ConfirmLifeCaseButton()}
        />
      )}
    </>
  );
};

export default ConfirmationForm;
