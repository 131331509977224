import * as React from 'react';
import { useSelector } from 'react-redux';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import { LocalStorage, OnboardingCaseSteps } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { noop } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';

import { ModalProviderProps } from '../Modal';

import styles from './WelcomeModalBody.scss';

export interface WelcomeModalBodyProps {
  title: string;
  bodyMsg: string;
  firstButtonMsg: string;
  secondButtonMsg: string;
  onClose?: () => void;
  isUnvitedProfessional?: boolean;
}

const WelcomeModalBody: React.FunctionComponent<ModalProviderProps<WelcomeModalBodyProps>> = ({
  closeModal,
  modalData: { onClose = noop, isUnvitedProfessional = false },
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);

  const noThanksClick = () => {
    setToLocalStorage(`${LocalStorage.welcomeTourProfessional}_${user?.id}`, false);
    if (isUnvitedProfessional) {
      setToLocalStorage(`${LocalStorage.professionalActiveCase}_${user?.id}`, OnboardingCaseSteps.isFirstCase);
    }
    closeModal();
  };

  return (
    <div className={styles.welcomeContainer}>
      <Icon className={styles.bulbColor} icon="bulb" size={IconSizes.m64} />

      <Typography size="xl" tag="h4" bold msg={t('title_prof_portal_welcome' as Messages)} />

      <Typography className={styles.mb28} size="m" tag="div" msg={t('help_text_welcome_guide' as Messages)} />
      <Row justifyCenter size={12}>
        <Col size={10}>
          <Button
            onClick={() => {
              closeModal();
              setToLocalStorage(`${LocalStorage.welcomeTourProfessional}_${user?.id}`, false);
              onClose();
            }}
            label={t('button_show_me_around' as Messages)}
            isFullWidth
            className={styles.mb28}
          />
        </Col>
      </Row>

      <LinkButton linkTitle={t('link_no_thanks' as Messages)} onClick={() => noThanksClick()} />
    </div>
  );
};

export default WelcomeModalBody;
