import * as React from 'react';

import Col from '@Components/layout/Col/Col';
import Row from '@Components/layout/Row/Row';
import Typography from '@Components/Typography/Typography';
import { CaseServiceProviderStatus } from '@Graphql/graphqlTypes.generated';

import AutomatedReportsTable from '../AutomatedReportsTable/AutomatedReportsTable';

import styles from './AutomatedReportsTablePage.scss';

export const NotificationStatuses = [
  CaseServiceProviderStatus.Sent,
  CaseServiceProviderStatus.InProgress,
  CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier,
  CaseServiceProviderStatus.InProgressAwaitingInformationFromSp,
  CaseServiceProviderStatus.InProgressAutoPopulationFailed,
];

export interface AutomatedReportsTablePageProps {
  data: any;
}

const AutomatedReportsTablePage: React.FunctionComponent<AutomatedReportsTablePageProps> = ({ data }) => {
  return (
    <>
      <div className={styles.headerContainer}>
        <Row justifyBetween alignCenter>
          <Col>
            <Row alignCenter className={styles.gap3}>
              <Typography msg="Reports" bold tag="h3" size="xxl" />
            </Row>
          </Col>
        </Row>
      </div>
      <AutomatedReportsTable data={data} />
    </>
  );
};

export default AutomatedReportsTablePage;
