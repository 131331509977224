import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './Navigation.scss';

export interface NavigationProps {}

const Navigation: React.FunctionComponent<NavigationProps> = () => {
  const t = useTranslations();

  const chevronDownIcon = (
    <FontAwesomeIcon className={styles.chevronIconWrapper} icon={faChevronDown} size="xs" color="#2E60E0" />
  );
  const chevronRightIcon = (
    <FontAwesomeIcon className={styles.chevronIconWrapper} icon={faChevronRight} size="xs" color="#2E60E0" />
  );

  return (
    <nav className={styles.layoutHorizontal}>
      <ul className={styles.elementorNavMenu}>
        <li>
          <a href="https://lifeledger.com/about-us/" className={styles.itemMenuLink} target="_blank" rel="noreferrer">
            {t('label_auth_mobile_menu_about_us' as Messages)}
          </a>
        </li>
        <li>
          <a href="/#" className={styles.itemMenuLink}>
            {t(Messages.labelAuthMenuItemPersonal)}
            {chevronDownIcon}
          </a>

          <ul className={styles.sub}>
            <li>
              <a href="https://lifeledger.com/" target="_blank" rel="noreferrer">
                <div className={styles.totalHeader}>
                  <span className={styles.inner}>
                    <span className={styles.mainHead}>
                      {' '}
                      {t('label_auth_mobile_menu_send_death_notifications' as Messages)}
                    </span>
                    <br />
                    <span className={styles.bodyText}>
                      {t('desc_auth_mobile_menu_send_death_notifications' as Messages)}
                    </span>
                  </span>
                  <span className={styles.arrow}>{chevronRightIcon}</span>
                </div>
              </a>
            </li>
            <li>
              <a href="https://lifeledger.com/life-planning/" target="_blank" rel="noreferrer">
                <div className={styles.totalHeader}>
                  <span className={styles.inner}>
                    <span className={styles.mainHead}>{t('label_auth_mobile_menu_life_planning' as Messages)}</span>
                    <br />
                    <span className={styles.bodyText}>{t('desc_auth_mobile_menu_life_planning' as Messages)}</span>
                  </span>
                  <span className={styles.arrow}>{chevronRightIcon}</span>
                </div>
              </a>
            </li>
            <li>
              <a href="https://lifeledger.com/resources/" target="_blank" rel="noreferrer">
                <div className={styles.totalHeader}>
                  <span className={styles.inner}>
                    <span className={styles.mainHead}>{t('label_auth_mobile_menu_resources' as Messages)}</span>
                    <br />
                    <span className={styles.bodyText}>{t('desc_auth_mobile_menu_resources' as Messages)}</span>
                  </span>
                  <span className={styles.arrow}>{chevronRightIcon}</span>
                </div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a className={styles.itemMenuLink} href="/#">
            {t(Messages.labelAuthMenuItemBusiness)}
            {chevronDownIcon}
          </a>
          <ul className={styles.sub}>
            <li>
              <a href="https://lifeledger.com/service-providers/" target="_blank" rel="noreferrer">
                <div className={styles.totalHeader}>
                  <span className={styles.inner}>
                    <span className={styles.mainHead}>
                      {t('label_auth_mobile_menu_for_service_providers' as Messages)}
                    </span>
                    <br />
                    <span className={styles.bodyText}>
                      {t('desc_auth_mobile_menu_for_service_providers' as Messages)}
                    </span>
                  </span>

                  <span className={styles.arrow}>{chevronRightIcon}</span>
                </div>
              </a>
            </li>
            <li>
              <a href="https://lifeledger.com/sector-professionals/" target="_blank" rel="noreferrer">
                <div className={styles.totalHeader}>
                  <span className={styles.inner}>
                    <span className={styles.mainHead}>
                      {t('label_auth_mobile_menu_for_sector_professionals' as Messages)}
                    </span>
                    <br />
                    <span className={styles.bodyText}>
                      {t('desc_auth_mobile_menu_for_sector_professionals' as Messages)}
                    </span>
                  </span>
                  <span className={styles.arrow}>{chevronRightIcon}</span>
                </div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a className={styles.itemMenuLink} href="https://lifeledger.com/faq/" target="_blank" rel="noreferrer">
            {t('label_auth_mobile_menu_faq' as Messages)}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
