import { useMutation } from '@apollo/react-hooks';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { UpdateTransactionMutation, UpdateTransactionMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationUpdateTransaction } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateAny, validateRequired } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './CompanyMissingInfoModalBody.scss';

export interface CompanyMissingInfoModalBodyProps {
  missingInfoType: MissingInfoFields;
  onClose?: () => void;
  transactionId: string;
  serviceProviderId?: string;
  caseAccountIdentifier?: string;
  caseAccountIdentifierKey?: string;
}

export enum MissingInfoFields {
  AccountNumber = 'AccountNumber',
  CompanyName = 'CompanyName',
}

enum CompanyMissingInfoModalBodyFields {
  companyName = 'companyName',
  accountNumberInfo = 'accountNumberInfo',
  customCompanyAccountNumber = 'customCompanyAccountNumber',
}
const CompanyMissingInfoModalBody: React.FunctionComponent<ModalProviderProps<CompanyMissingInfoModalBodyProps>> = ({
  modalData: { missingInfoType, onClose = noop, transactionId, serviceProviderId, caseAccountIdentifier = '' },
  closeModal,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [updateTransaction] = useMutation<UpdateTransactionMutation, UpdateTransactionMutationVariables>(
    mutationUpdateTransaction,
    {
      //refetchQueries: [getOperationName(queryObTransactions) || ''],
      onCompleted: () => {
        const successMsg =
          missingInfoType === MissingInfoFields.CompanyName ? 'msg_sp_name_updated' : 'msg_sp_acc_number_updated';
        dispatch(notificationSuccess(t(successMsg as Messages)));
        onClose();
        closeModal();
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const companyAccountNumberOptions = [
    { label: "Yes, that's my account number", value: "Yes, that's my account number" },
    {
      label: "I'm not sure right now, I'll come back to it later",
      value: "I'm not sure right now, I'll come back to it later",
    },
    { label: `No, that's not my account number`, value: "No, that's not my account number" },
  ];

  return (
    <>
      <Typography msg="Add missing information" bold tag="h4" size="xl" />
      <Formik
        initialValues={{
          [CompanyMissingInfoModalBodyFields.companyName]: '',
          [CompanyMissingInfoModalBodyFields.accountNumberInfo]: '',
          [CompanyMissingInfoModalBodyFields.customCompanyAccountNumber]: '',
        }}
        validationSchema={() =>
          Yup.lazy((values: any) => {
            return Yup.object({
              [CompanyMissingInfoModalBodyFields.accountNumberInfo]:
                missingInfoType === MissingInfoFields.AccountNumber ? validateRequired(t) : validateAny(),
              [CompanyMissingInfoModalBodyFields.companyName]:
                missingInfoType === MissingInfoFields.CompanyName ? validateRequired(t) : validateAny(),
              [CompanyMissingInfoModalBodyFields.customCompanyAccountNumber]:
                values.accountNumberInfo === "No, that's not my account number" ? validateRequired(t) : validateAny(),
            });
          })
        }
        validateOnMount={true}
        onSubmit={(values) => {
          if (missingInfoType === MissingInfoFields.CompanyName) {
            updateTransaction({
              variables: {
                input: {
                  transaction: transactionId,
                  newServiceProvider: values.companyName,
                },
              },
            });
          } else {
            let actualCaseAccountIdentifier = '';
            if (values.accountNumberInfo === "Yes, that's my account number") {
              actualCaseAccountIdentifier = caseAccountIdentifier;
            } else if (values.accountNumberInfo === "I'm not sure right now, I'll come back to it later") {
              actualCaseAccountIdentifier = caseAccountIdentifier;
            } else {
              actualCaseAccountIdentifier = values.customCompanyAccountNumber;
            }
            updateTransaction({
              variables: {
                input: {
                  transaction: transactionId,
                  serviceProvider: serviceProviderId,
                  caseAccountIdentifier: actualCaseAccountIdentifier,
                },
              },
            });
          }
        }}
      >
        {({ status, values, isValid }) => (
          <>
            <Form>
              <div className={styles.bodyContainer}>
                {missingInfoType === MissingInfoFields.CompanyName && (
                  <>
                    <Row className={styles.blueBox}>
                      <Icon className={styles.infoIcon} size={IconSizes.s24} icon="info" />
                      <Typography
                        tag="div"
                        size="s"
                        msg="Our support team to reach out to this company to get them onboarded to the Life Ledger platform. This is a process that can take some time."
                      />
                    </Row>

                    <Field
                      name={CompanyMissingInfoModalBodyFields.companyName}
                      component={TextInput}
                      fullWidth
                      required
                      label="What is the name of this company?"
                      placeholder="Enter company name"
                    />
                  </>
                )}

                {missingInfoType === MissingInfoFields.AccountNumber && (
                  <>
                    <Typography className={styles.mt35} msg="Is this your account number?" tag="div" size="m" />
                    <div className={styles.companyNameContainer}>
                      <Typography msg={caseAccountIdentifier} bold tag="div" size="m" />
                    </div>
                    <div className={styles.ml5}>
                      <Field
                        name={CompanyMissingInfoModalBodyFields.accountNumberInfo}
                        options={companyAccountNumberOptions}
                        component={RadioButton}
                        required
                      />
                      {values.accountNumberInfo === "No, that's not my account number" && (
                        <Row className={styles.knownNameSpacing}>
                          <Field
                            name={CompanyMissingInfoModalBodyFields.customCompanyAccountNumber}
                            component={TextInput}
                            label="Please provide account number, if you know it"
                          />
                        </Row>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className={styles.buttonSpacing}>
                <Button disabled={!isValid} isFullWidth type={ButtonTypes.submit} label="Confirm selection" />
              </div>
              <FormError formError={status} />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CompanyMissingInfoModalBody;
