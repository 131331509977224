import { ActionCreator } from 'redux';
import { Action } from 'typescript-fsa';

import { PayloadWithPromises } from '@Utils/types';

export function createReducer<T>(
  initialState: T,
  handlers: Record<string, <A extends Action<any>>(state: T, action: A) => T>
) {
  return (state = initialState, actionC: Action<any>) => {
    if (Object.prototype.hasOwnProperty.call(handlers, actionC.type)) {
      return handlers[actionC.type](state, actionC);
    }
    return state;
  };
}

export const initSagas = (sagas: any, sagaMiddleware: any): void => {
  Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
};

export function bindActionToPromise<T>(
  dispatch: any,
  actionCreator: ActionCreator<T & PayloadWithPromises>
): (p: T) => any {
  return (payload: T) => {
    return new Promise((resolve, reject) => dispatch(actionCreator({ ...payload, resolve, reject })));
  };
}
