import { connect } from 'react-redux';

import ConfirmationForm, {
  ConfirmationFormProps,
  ConfirmationFormStateProps,
} from '@Routes/deathCase/DeathCasePage/ConfirmationForm/ConfirmationForm';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
});

export default connect<ConfirmationFormStateProps, {}, ConfirmationFormProps, RootState>(mapStateToProps)(
  ConfirmationForm
);
