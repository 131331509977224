import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { ModalTypes } from '@Config/constants';
import { showModal } from '@Store/app/app.actions';

import { ModalProviderProps } from '../Modal';

import styles from './ChangeAddressModalBody.scss';

export interface ChangeAddressModalBodyProps {
  changeNameField: boolean;
}

export enum ChangeAddressModalFields {
  selfAddress = 'self-address',
  someoneAddress = 'someone-address',
  selfName = 'self-name',
  someoneName = 'someone-name',
}

const ChangeAddressModalBody: React.FunctionComponent<ModalProviderProps<ChangeAddressModalBodyProps>> = ({
  modalData: { changeNameField },
  closeModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCLick = () => {
    closeModal();
    // history.push(`${Links.lifeEventSelect}`, {
    //   type: selfAddress
    // })
    dispatch(
      showModal({
        type: ModalTypes.paymentDetailsModal,
        params: {
          title: '',
          modalSize: PaperWidths.l1330,
          showCloseIcon: false,
          isGrayPaper: true,
          noPadding: true,
          data: {
            changeNameField,
          },
        },
      })
    );
  };

  return (
    <>
      {!changeNameField && (
        <>
          <Typography
            msg="Whose address would you like to change?"
            size="xl"
            tag="div"
            bold
            className={styles.headerContainer}
          />
          <div
            className={classNames(styles.bodyContainer, styles.mb10)}
            onClick={() => {
              // setSelfAddress(ChangeAddressModalFields.selfAddress)
              handleCLick();
              history.push(ChangeAddressModalFields.selfAddress);
            }}
          >
            <div className={styles.flex}>
              <Icon icon="person" size={IconSizes.ssm} />
              <Typography msg="Change my address" size="l" tag="div" bolder />
            </div>
          </div>
          <div
            className={classNames(styles.bodyContainer, styles.mb56)}
            onClick={() => {
              handleCLick();
              history.push(ChangeAddressModalFields.someoneAddress);
            }}
          >
            <div className={styles.flex}>
              <Icon icon="people" size={IconSizes.ssm} />
              <Typography msg="Change someone else’s address" size="l" tag="div" bolder />
            </div>
          </div>
        </>
      )}
      {changeNameField && (
        <>
          <Typography
            msg="Whose name would you like to change?"
            size="xl"
            tag="div"
            bold
            className={styles.headerContainer}
          />
          <div
            className={classNames(styles.bodyContainer, styles.mb10)}
            onClick={() => {
              // setSelfAddress(ChangeAddressModalFields.selfAddress)
              handleCLick();
              history.push(ChangeAddressModalFields.selfName);
            }}
          >
            <div className={styles.flex}>
              <Icon icon="person" size={IconSizes.ssm} />
              <Typography msg="Change my name" size="l" tag="div" bolder />
            </div>
          </div>
          <div
            className={classNames(styles.bodyContainer, styles.mb56)}
            onClick={() => {
              handleCLick();
              history.push(ChangeAddressModalFields.someoneName);
            }}
          >
            <div className={styles.flex}>
              <Icon icon="people" size={IconSizes.ssm} />
              <Typography msg="Change someone else’s name" size="l" tag="div" bolder />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChangeAddressModalBody;
