import * as React from 'react';

import ProviderDCRefNumberForm from '@Routes/shared/Notification/ProviderDCRefNumberForm';

export interface ProviderDCRefNumberPageProps {
  accessCode: string;
  referenceCode: string;
}

const ProviderDCRefNumberPage: React.FunctionComponent<ProviderDCRefNumberPageProps> = ({
  accessCode,
  referenceCode,
}) => {
  return <ProviderDCRefNumberForm accessCode={accessCode} referenceCode={referenceCode} />;
};

export default ProviderDCRefNumberPage;
