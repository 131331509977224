import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { Links, LocalStorage, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getBooleanValueCookie } from '@Utils/localStorage';
import { saveChangesHandler } from '@Utils/modal';

import styles from './MobileMenu.scss';

export interface MobileMenuProps {
  showHamBurger?: boolean;
  onHamburgerClick?: Function;
  showBackToSettings?: boolean;
  onBackToSettings?: Function;
  grayBackground?: boolean;
  isValidationPage?: boolean;
  isIndividualDashboard?: boolean;
}

const MobileMenu: React.FunctionComponent<MobileMenuProps> = ({
  showHamBurger = false,
  onHamburgerClick,
  showBackToSettings = false,
  onBackToSettings,
  grayBackground = false,
  isValidationPage = false,
  isIndividualDashboard,
}) => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const dispatch = useDispatch();

  const hamOrSettings = () => {
    if (showBackToSettings) {
      return (
        <div className={classNames(styles.backToSettingsContainer, { [styles.grayBackground]: isValidationPage })}>
          <div
            className={classNames(styles.clickSection, styles.backToMenuContainer)}
            onClick={() => {
              if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
                return dispatch(
                  showModal({
                    type: ModalTypes.saveChangesModal,
                    params: {
                      data: {
                        path: '',
                        onClose: () => onBackToSettings && onBackToSettings(),
                      },
                      title: '',
                      modalSize: PaperWidths.yotiWidth,
                    },
                  })
                );
              }
              return onBackToSettings && onBackToSettings();
            }}
          >
            <Icon size={IconSizes.ssm} icon="chevronLeft" className={styles.iconPosition} />
            <Typography className={styles.backToText} msg={t(Messages.linkBackToSettings)} size="l" tag="div" />
          </div>

          <Icon
            onClick={() => {
              if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
                saveChangesHandler(dispatch, `${Links.home}${queryString}`);
              } else {
                history.push(generatePath(`${Links.home}${queryString}`));
              }
            }}
            icon="close"
            className={styles.settingsCloseIcon}
            size={IconSizes.sxs}
          />
        </div>
      );
    }
    return (
      <div
        className={classNames(styles.lifeLedgerIcon, styles.clickSection, {
          [styles.grayBackground]: grayBackground,
          [styles.pb16]: isIndividualDashboard,
        })}
        onClick={() => onHamburgerClick && onHamburgerClick()}
      >
        <Icon icon="bars" size={IconSizes.ssm} />
      </div>
    );
  };

  return (
    <>
      {showHamBurger ? (
        hamOrSettings()
      ) : (
        <div className={styles.container}>
          <div className={styles.lifeLedgerIcon}>
            <div className={styles.clickSection} onClick={() => history.push(`${Links.home}${queryString}`)}>
              <img width="173" height="37" src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg" alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
