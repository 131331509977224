import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import BillingCard from '@Components/application/BillingCard';
import { PaymentStatus } from '@Components/application/BillingCard/BillingCard';
import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  SubscriptionPaymentPaymentStatus,
  SubscriptionSubscriptionStatus,
  usePackagesQuery,
  useUserSubscriptionPaymentsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { registerLifeCaseModal } from '@Utils/modal';

import LcSubscriptionCard from './LcSubscriptionCard';
import styles from './PaymentsPage.scss';

export interface PaymentsPageProps {}

enum BillingTabs {
  TabLifePlans,
  TabPaymentHistory,
}

const PaymentsPage: React.FunctionComponent<PaymentsPageProps> = () => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState(BillingTabs.TabLifePlans);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [selectedDates, setSelectedDates] = React.useState<string[]>([]);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  React.useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const { data, loading } = useUserSubscriptionPaymentsQuery({
    fetchPolicy: 'network-only',
  });

  const paymentPackages = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  let subscriptionCreatedDate: string[] = [];

  if (data?.userSubscriptionPayments?.length) {
    subscriptionCreatedDate = Array.from(
      new Set(
        data.userSubscriptionPayments.map((item) => {
          const date = new Date(item.createdAt);
          const monthName = date.toLocaleString('default', { month: 'long' });
          const year = date.getFullYear();
          return `${monthName} ${year}`;
        })
      )
    );
  } else {
    subscriptionCreatedDate = [];
  }

  const handleSelectChange = (date: string) => {
    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((d) => d !== date));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const filteredPayments = data?.userSubscriptionPayments
    .filter((us: any) => {
      const date = new Date(us.createdAt);
      const monthName = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      const formattedDate = `${monthName} ${year}`;
      return selectedDates.length === 0 || selectedDates.includes(formattedDate);
    })
    .sort((a: any, b: any) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA; // Descending order
    });

  const basicPackage = paymentPackages?.data?.packages.find((p: any) => p.features[0] === 'BASIC_LIFE_CASE');
  const billingTabs = [{ title: t('label_ral_plans' as Messages) }, { title: t('label_payment_history' as Messages) }];

  const currentSubs = [
    ...new Map(
      data?.userSubscriptionPayments
        .sort((x: any) => x.createdAt)
        .reverse()
        .filter(
          (p: any) =>
            p.paymentStatus === SubscriptionPaymentPaymentStatus.Paid &&
            p.subscription.subscriptionStatus !== SubscriptionSubscriptionStatus.Cancelled
        )
        .map((usp: any) => [usp.subscription.id, usp])
    ).values(),
  ];

  return (
    <>
      <div className={styles.headerContainer}>
        <Typography bold tag="h3" size="xxl" msg={t('msg_title_settings_subitem_billing' as Messages)} />
        <div className={styles.tabPlacement}>
          <Tabs tabs={billingTabs} activeTab={activeTab} setActiveTab={setActiveTab} className={styles.tabs} />
        </div>
      </div>
      <div className={styles.tabBody}>
        {activeTab === BillingTabs.TabLifePlans &&
          (currentSubs.length > 0 ? (
            <div className={styles.ralWidth}>
              <Typography color="footerColor" size="l" msg={t('info_ral_registered' as Messages)} tag="div" />
              {currentSubs.map((usp: any, idx: number) => {
                return <LcSubscriptionCard userSubscription={usp} key={idx} />;
              })}

              <Typography
                className={styles.mt4}
                msg={t('info_payment_renewal' as Messages)}
                color="footerColor"
                tag="div"
                size="s"
              />
            </div>
          ) : (
            <Row size={isMobile ? 8 : 7} constant justifyCenter column className={styles.emptyNotificationContainer}>
              <Col className={styles.messageWidth} textCenter>
                <Icon size={IconSizes.sm} icon="people" className={styles.peopleIcon} />
                <Typography msg={t('msg_empty_end_of_life_plans' as Messages)} tag="div" size="l" color="footerColor" />
                <Button
                  onClick={() => {
                    registerLifeCaseModal(dispatch, basicPackage);
                  }}
                  label={t('cta_register_your_life' as Messages)}
                  isFullWidth
                  className={styles.mt18}
                />
              </Col>
            </Row>
          ))}

        {activeTab === BillingTabs.TabPaymentHistory &&
          (filteredPayments && filteredPayments.length > 0 ? (
            <>
              <div className={styles.historyWidth}>
                <div onClick={() => setShowDropdown(false)} />
                <Row alignCenter={!isMobile} justifyEnd alignStart={isMobile}>
                  <Col>
                    <div className={styles.customDropdown} ref={dropdownRef}>
                      <div className={styles.dropdownHeader} onClick={() => setShowDropdown(!showDropdown)}>
                        <Typography msg="Period" size="l" tag="span" />
                        <Icon
                          icon={showDropdown ? 'chevronUp' : 'chevronDown'}
                          size={IconSizes.s24}
                          className={styles.dropdownIcon}
                        />
                      </div>

                      {showDropdown && (
                        <div className={styles.dropdownMenu}>
                          {subscriptionCreatedDate.map((date) => (
                            <label key={date} className={styles.dropdownItem}>
                              <input
                                type="checkbox"
                                checked={selectedDates.includes(date)}
                                onChange={() => handleSelectChange(date)}
                              />
                              <Typography msg={date} tag="span" size="m" />
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {filteredPayments?.map((us: any, idx: number) => (
                  <BillingCard userSubscription={us} paymentStatus={PaymentStatus.Paid} key={idx} />
                ))}
              </div>
            </>
          ) : (
            <Row size={isMobile ? 8 : 7} constant justifyCenter column className={styles.emptyNotificationContainer}>
              <Col className={styles.messageWidth} textCenter>
                <Icon size={IconSizes.sm} icon="history" className={styles.peopleIcon} />
                <Typography
                  msg={t('msg_empty_history_of_payment' as Messages)}
                  tag="div"
                  size="l"
                  color="footerColor"
                />
              </Col>
            </Row>
          ))}
      </div>
    </>
  );
};

export default PaymentsPage;
