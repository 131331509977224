import classNames from 'classnames';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';

import styles from './CautionBox.scss';

export interface CautionBoxProps {
  title?: string;
  info: string;
  containerClassName: string;
  showMidSection?: boolean;
  id?: string;
}

const CautionBox: React.FunctionComponent<CautionBoxProps> = ({ title, info, containerClassName, id }) => {
  return (
    <Row constant id={id} className={classNames(styles.infoBox, containerClassName)}>
      <Col>
        <Row constant>
          <Col className={styles.infoIcon}>
            <Icon icon="info" />
          </Col>
          {title && (
            <Col size={12}>
              <Typography className={styles.title} msg={title} tag="div" size="s" bolder />
            </Col>
          )}
        </Row>
      </Col>

      <Col>
        <p className={styles.fs14}>{ReactHtmlParser(info)}</p>
      </Col>
    </Row>
  );
};

export default CautionBox;
