import classNames from 'classnames';
import * as React from 'react';

import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';

import styles from './EmptyFileView.scss';

export interface EmptyFileViewProps {
  containerClassName?: string;
}

const EmptyFileView: React.FunctionComponent<EmptyFileViewProps> = ({ containerClassName }) => {
  return (
    <div className={classNames(containerClassName, styles.mobileView)}>
      <Row justifyCenter>
        <Icon icon="file-text" />
      </Row>
      <Row justifyCenter className={styles.topSpacing}>
        <Typography size="m" tag="div" msg="Nothing has been uploaded yet" color="footerColor" />
      </Row>
    </div>
  );
};

export default EmptyFileView;
