import * as React from 'react';

import Typography from '@Components/Typography';
import CaseSummary from '@Routes/provider/AccountManagement/AccountManagementForm/UsersPage/CaseSummary';

export interface CaseSummaryContainerProps {
  data: any;
  title?: string;
  subTitle?: string;
  isPending?: boolean;
}

const CaseSummaryContainer: React.FunctionComponent<CaseSummaryContainerProps> = ({
  data,
  title,
  subTitle,
  isPending,
}) => {
  return (
    <>
      {title && <Typography msg={title} tag="h5" bold size="l" />}
      {subTitle && <Typography msg={subTitle} tag="div" color="footerColor" size="m" />}
      <div style={{ marginTop: '10px' }}>
        {data &&
          data.map((user: any, idx: number) => <CaseSummary data={user} idx={idx} key={idx} isPending={isPending} />)}
      </div>
    </>
  );
};

export default CaseSummaryContainer;
