import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { Links, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationDeleteDC } from '@Graphql/dc/mutations';
import { queryDCList } from '@Graphql/dc/queries';
import {
  DeathCaseDeleteMutation,
  DeathCaseDeleteMutationVariables,
  DeathCaseListCardFragment,
  DeathCaseStatus,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './MobileDCActionsModalBody.scss';

export interface MobileDCActionsModalBodyProps {
  onClose?: () => void;
  caseData: DeathCaseListCardFragment;
}

const MobileDCActionsModalBody: React.FunctionComponent<ModalProviderProps<MobileDCActionsModalBodyProps>> = ({
  modalData: { caseData },
  closeModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslations();
  const queryString = history.location.search;

  const [deleteDCMutation] = useMutation<DeathCaseDeleteMutation, DeathCaseDeleteMutationVariables>(mutationDeleteDC, {
    refetchQueries: [getOperationName(queryDCList) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.notificationDCDeleteSuccess)));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const deleteHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCMutation({ variables: { input: { id: caseData.id } } }),
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
          },
          title: t(Messages.modalRemoveDCTitle),
          subtitle: t(
            caseData.status === DeathCaseStatus.Active
              ? Messages.modalRemoveActiveDCSubtitle
              : Messages.modalRemoveDCSubtitle
          ),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  return (
    <>
      <Row justifyCenter className={styles.bottomSpacing}>
        <div
          onClick={() => {
            closeModal();
            history.push(generatePath(`${Links.dcReady}${queryString}`, { id: caseData.id }));
          }}
        >
          <Icon className={styles.swapIcon} icon="radio-button-on" />
          <Typography msg="View notifications" tag="span" size="mb" className={styles.switchCase} />
        </div>
      </Row>

      <div className={styles.breaker} />

      <Row alignCenter constant justifyCenter onClick={(e: any) => deleteHandler(e)}>
        <Col>
          <Icon icon="trash" className={styles.viewNotificationIcon} />
        </Col>
        <Col>
          <Typography msg="Remove" color="red" bold tag="span" size="mb" />
        </Col>
      </Row>
    </>
  );
};

export default MobileDCActionsModalBody;
