import { Messages } from '@Config/messages';
import configurationMFARoutes from '@Routes/provider/ConfigurationMFA/configurationMFARoutes';

import activityRoutes from './activity/activityRoutes';
import authRoutes from './auth/authRoutes';
import dashboardRoutes from './dashboard/dashboardRoutes';
import deathCaseRoutes from './deathCase/deathCaseRoutes';
import referredDeathCaseRoutes from './deathCase/ReferredDeathCase/referredDeathCaseRoutes';
import lifeCaseRoutes from './lifeCase/lifeCaseRoutes';
import lifeEventRoutes from './lifeEvent/lifeEventRoutes';
import providerPortalRoutes from './provider/providerPortalRoutes';
import providerRoutes from './provider/providerRoutes';
import settingsRoutes from './settings/settingsRoutes';

export type RouteInfo = {
  path: string;
  component: any;
  title: Messages;
  isServiceProviderRoute?: boolean;
  target?: string;
};

export const publicRoutes: RouteInfo[] = [
  ...authRoutes,
  ...providerRoutes,
  ...configurationMFARoutes,
  ...referredDeathCaseRoutes,
];

export const privateRoutes: RouteInfo[] = [
  ...lifeEventRoutes,
  ...dashboardRoutes,
  ...settingsRoutes,
  ...deathCaseRoutes,
  ...lifeCaseRoutes,
  ...providerPortalRoutes,
  ...activityRoutes,
];
