import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Button, { ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { CaseServiceProviderNode, useGetMeQuery, UserVerificationStatus } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { hideModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { sendOne } from '@Store/dc/dc.actions';

import styles from './VerificationStatus.scss';

export interface VerificationStatusProps {
  status?: string;
  fromNotification?: boolean;
  deathCaseServiceProviderId?: string;
  submitAction?: string;
  deathCaseServiceProvider?: CaseServiceProviderNode;
}
const VerificationStatus: React.FunctionComponent<VerificationStatusProps> = ({
  status,
  fromNotification = false,
  deathCaseServiceProviderId,
  submitAction,
  deathCaseServiceProvider,
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const userDetails = useGetMeQuery({
    fetchPolicy: 'network-only',
    skip: !fromNotification,
  });

  const needsReviewStatus = user?.verificationStatus === UserVerificationStatus.NeedsReview;

  if (userDetails.loading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return null;
  }

  const changeToYotiStatus = (verifyStatus: string) => {
    if (verifyStatus === 'Pass') {
      return UserVerificationStatus.Verified;
    }
    if (verifyStatus === 'Fail') {
      return UserVerificationStatus.Error;
    }
    if (verifyStatus === 'Fetch') {
      return UserVerificationStatus.Verifying;
    }
    return user.verificationStatus as UserVerificationStatus;
  };

  const errorWindow = fromNotification ? 12 : 6;

  const statusContent = (icon: string, msg: string) => {
    return (
      <Row
        constant
        column
        size={isTablet ? 11 : errorWindow}
        className={classNames(
          styles.userStatusPanel,
          user.verificationStatus === UserVerificationStatus.Verified ||
            user.verificationStatus === UserVerificationStatus.NotVerified
            ? styles.verified
            : styles.needsReview
        )}
      >
        <Row justifyBetween>
          <Col>
            <Row column>
              <Typography msg={t('label_account_status' as Messages)} tag="div" size="l" />
              <Typography className={styles.topSpacing} msg={msg} tag="div" size="l" bold />
            </Row>
          </Col>
          <Col className={styles.statusIconContainers} alignCenter>
            <Icon icon="person" size={IconSizes.l} className={styles.personIcon} />
            {needsReviewStatus ? (
              <Icon icon={icon} size={IconSizes.ssm} className={styles.errorSymbol} />
            ) : (
              <Icon icon={icon} className={styles.errorSymbol} />
            )}
          </Col>
        </Row>

        {needsReviewStatus && (
          <Typography
            className={styles.topSpacing}
            msg={t('label_status_subtitle_NEEDS_REVIEW' as Messages)}
            tag="div"
            size="l"
          />
        )}
      </Row>
    );
  };
  const errortatus = () => {
    return (
      //  <IDValidationError isSettings={isSettings} fromNotification={fromNotification}/>
      <></>
    );
  };

  const errorFailure = () => {
    return <div className={styles.verificationFailureContainer}>{errortatus()}</div>;
  };

  const renderBasedOnStatus = {
    [UserVerificationStatus.Verified]: statusContent(
      'doneAll',
      t(`${Messages.labelStatusTitlePrefix}${UserVerificationStatus.Verified}` as Messages)
    ),
    [UserVerificationStatus.NotVerified]: statusContent(
      'doneAll',
      t(`${Messages.labelStatusTitlePrefix}${UserVerificationStatus.Verified}` as Messages)
    ),
    [UserVerificationStatus.Verifying]: statusContent(
      'fingerprint-light-blue',
      t(`${Messages.labelStatusTitlePrefix}${UserVerificationStatus.Verifying}` as Messages)
    ),
    [UserVerificationStatus.NeedsReview]: statusContent(
      'alert-triangle',
      t(`${Messages.labelStatusTitlePrefix}${UserVerificationStatus.NeedsReview}` as Messages)
    ),
    [UserVerificationStatus.Error]: errorFailure(),
  };

  const verifiedStatus = () => {
    return (
      <>
        <Row justifyCenter>
          <Col alignCenter>
            <Icon icon="person" size={IconSizes.l} className={styles.personIcon} />
            <Icon icon="doneAll" size={IconSizes.ssm} className={styles.alertTriangle} />
          </Col>
        </Row>
        <Typography
          msg={
            deathCaseServiceProvider?.serviceProvider.isUserValidationRequired
              ? t('label_id_validation_successful' as Messages)
              : t('label_id_doc_upload_success' as Messages)
          }
          tag="h4"
          size="xl"
          bold
          textCenter
        />
        <Typography msg={t('label_send_notifications' as Messages)} tag="div" size="m" textCenter />

        <Row className={styles.bigTopSpacing} justifyCenter>
          <Button
            type={ButtonTypes.button}
            onClick={() => {
              if (submitAction === 'send' && deathCaseServiceProviderId) {
                dispatch(sendOne.started({ deathCaseProviderId: deathCaseServiceProviderId }));
              }
              dispatch(hideModal({}));
            }}
            label={t('label_auth_mobile_menu_send_death_notifications' as Messages)}
          />
        </Row>
      </>
    );
  };

  const renderStatusInPopUp = () => {
    return userDetails.data?.me.info.verificationStatus === UserVerificationStatus.Verified
      ? verifiedStatus()
      : renderBasedOnStatus[changeToYotiStatus(status || '')];
  };

  return fromNotification ? renderStatusInPopUp() : renderBasedOnStatus[changeToYotiStatus(status || '')];
};

export default VerificationStatus;
