import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import PartnerForm, {
  PartnerFormDispatchProps,
  PartnerFormProps,
  PartnersFormStateProps,
} from '@Routes/deathCase/DeathCasePage/PartnerForm/PartnerForm';
import { getUser } from '@Store/auth/auth.selector';
import { updateDC } from '@Store/dc/dc.actions';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  user: getUser(state),
  data: getDCData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateRequest: updateDC.started,
    },
    dispatch
  );

export default connect<PartnersFormStateProps, PartnerFormDispatchProps, PartnerFormProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PartnerForm);
