import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import CreateLifeEvent from '@Routes/lifeEvent/CreateLifeEvent';

import { RouteInfo } from '../appRoutes';

import EditLifeEvent from './EditLifeEvent/EditLifeEvent';

const routes: RouteInfo[] = [
  {
    path: Links.lifeEvent,
    component: CreateLifeEvent,
    title: Messages.pageCreateLifeCase,
    isServiceProviderRoute: false,
  },
  {
    path: Links.lifeEventEdit,
    component: EditLifeEvent,
    title: Messages.pageCreateLifeCase,
    isServiceProviderRoute: false,
  },
];

export default routes;
