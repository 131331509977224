import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalStorage } from '@Config/constants';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import DeathCasePage from '@Routes/deathCase/DeathCasePage';
import { getUser } from '@Store/auth/auth.selector';
import { clearDC } from '@Store/dc/dc.actions';
import { getFromLocalStorage } from '@Utils/localStorage';

export interface CreateDeathCaseProps {}

export interface CreateDeathCaseDispatchProps {}

interface CreateDeathCasePrivateProps extends CreateDeathCaseProps, CreateDeathCaseDispatchProps {}

const CreateDeathCase: React.FunctionComponent<CreateDeathCasePrivateProps> = () => {
  const id = getFromLocalStorage(LocalStorage.deathCaseId) || '';

  const user = useSelector(getUser);
  const userAccountType = user?.accountType;
  const isUserProfessional = userAccountType === UserAccountType.Professional;

  const dispatch = useDispatch();
  if (id === '') {
    dispatch(clearDC());
  }
  return <DeathCasePage isUserProfessional={isUserProfessional} />;
};

export default CreateDeathCase;
