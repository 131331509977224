import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import {
  AddressPortal,
  emptySelectorValue,
  EnterKeyCode,
  LocalStorage,
  ReactResponsiveQueries,
  validationLimits,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserAccountType,
  UserNode,
  UserTitle,
  UserVerificationStatus,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { updateAccount } from '@Store/settings/settings.actions';
import { formatDateForExchange, normalizeDate } from '@Utils/dates';
import { normaliseGqlError, titleOptions } from '@Utils/form';
import { addressHelper, noop, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { deleteFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';
import {
  validateMaxLen,
  validatePhone,
  validateRequired,
  validateSelector,
  validateAny,
  validateName,
  validateDate,
  validatePostalCodeCharacters,
  validateMinLength,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../../model/Address';

import styles from './AccountForm.scss';

export interface AccountFormProps {
  user: UserNode | null;
  isTransunionStep?: boolean;
  setNextPage?: () => void;
  fromNotification?: boolean;
}

enum AccountFormFields {
  accountType = 'accountType',
  birthDate = 'birthDate',
  birthDay = 'birthDay',
  birthYear = 'birthYear',
  phone = 'phone',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  postalCode = 'postalCode',
  title = 'title',
  buildingNumber = 'buildingNumber',
  buildingName = 'buildingName',
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  email = 'email',
  autoAddress = 'autoAddress',
  chooseAddress = 'chooseAddress',
  titleOther = 'titleOther',
}

const AccountForm: React.FunctionComponent<AccountFormProps> = ({
  user,
  setNextPage = noop,
  isTransunionStep = false,
  fromNotification = false,
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const dispatch = useDispatch();

  const [manualAddress, setManualAddress] = React.useState(!!user?.address1);

  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);

  const [currentPostalCode, setCurrentPostalCode] = React.useState('');

  const [searching, setSearching] = React.useState(false);

  const [assignedAddress, setAssignedAddress] = React.useState(-1);

  const [updateAction, setUpdateAction] = React.useState(false);

  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);

  const [updateUser, { loading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: () => {
      deleteFromLocalStorage(LocalStorage.settingFormChanged);
      setNextPage();
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const getAddress = () => {
    if (currentPostalCode !== '') {
      setSearching(true);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', currentPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apidata) => {
          if (apidata.Message) {
            dispatch(notificationError(apidata.Message));

            setAddressResult([]);
            setSearching(false);
          } else {
            const outputData = new Address(apidata);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError('Address not found for this postal code'));
            }
            setAddressResult(outputData.addresses);
            setSearching(false);
          }
        });
    }
  };

  const titleInitialValue = user?.title ? titleOptions.find((item) => item.value === user?.title) : emptySelectorValue;

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
    setManualAddress(true);
  };

  const isServiceProviderUser = user?.accountType === UserAccountType.ServiceProvider;

  const saveButtonText = () => {
    if (!isTransunionStep) {
      return !updateAction ? 'Saved' : t(Messages.buttonUpdateAccount);
    }
    return 'Verify and continue';
  };

  const buttonIcon = () => {
    if (!isTransunionStep) {
      return !updateAction ? 'checkmark' : '';
    }
    return 'lock';
  };

  const AutoToggleForm = () => {
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
      const searchedAddress = addressResult.find((addr: FullAddress) => addr.id === assignedAddress);
      if (searchedAddress) {
        setFieldValue(AccountFormFields.buildingName, addressHelper(searchedAddress.buildingName));
        setFieldValue(AccountFormFields.buildingNumber, addressHelper(searchedAddress.buildingNumber));
        setFieldValue(AccountFormFields.address1, addressHelper(searchedAddress.address1));
        setFieldValue(AccountFormFields.address2, addressHelper(searchedAddress.address2));
        setFieldValue(AccountFormFields.city, addressHelper(searchedAddress.city));
        setFieldValue(AccountFormFields.postalCode, addressHelper(searchedAddress.postalCode));
      }
    }, [setFieldValue]);

    return null;
  };

  const disableVerifyAndContinue = (values: any) => {
    const isBirthDateNeeded = user?.accountType === UserAccountType.Agent ? false : !values.birthDate;
    if (
      isBirthDateNeeded ||
      values.phone === '' ||
      values.phone === '+44' ||
      values.address1 === '' ||
      values.city === '' ||
      values.postalCode === '' ||
      values.firstName === '' ||
      values.lastName === '' ||
      values.title?.value === '' ||
      (values.buildingName === '' && values.buildingNumber === '')
    ) {
      return true;
    }
    return false;
  };

  const inputControlChange = (e: any, currentValue: any, initialValue: any, controlName: string) => {
    if (e.target) {
      if (e.target.name === controlName) {
        return e.target.value !== initialValue;
      }
      if (initialValue === null) {
        return currentValue !== '';
      }
      return currentValue !== initialValue;
    }
    return e.value !== initialValue;
  };

  const formChanges = (e: any, values: any) => {
    const titleChanged = inputControlChange(e, values.title.value, user?.title, AccountFormFields.title);
    const firstNameChanged = inputControlChange(e, values.firstName, user?.firstName, AccountFormFields.firstName);
    const middleNameChanged = inputControlChange(e, values.middleName, user?.middleName, AccountFormFields.middleName);
    const lastNameChanged = inputControlChange(e, values.lastName, user?.lastName, AccountFormFields.lastName);
    const birthDateChanged = inputControlChange(e, values.birthDate, user?.birthDate, AccountFormFields.birthDate);
    const phoneChanged = inputControlChange(e, values.phone, user?.phone, AccountFormFields.phone);
    const currentPostalCodeChanged = currentPostalCode !== '';
    const buildingNameChanged = inputControlChange(
      e,
      values.buildingName,
      user?.buildingName,
      AccountFormFields.buildingName
    );
    const buildingNumberChanged = inputControlChange(
      e,
      values.buildingNumber,
      user?.buildingNumber,
      AccountFormFields.buildingNumber
    );
    const address1Changed = inputControlChange(e, values.address1, user?.address1, AccountFormFields.address1);
    const address2Changed = inputControlChange(e, values.address2, user?.address2, AccountFormFields.address2);
    const cityChanged = inputControlChange(e, values.city, user?.city, AccountFormFields.city);
    const postalCodeChanged = inputControlChange(e, values.postalCode, user?.postalCode, AccountFormFields.postalCode);

    if (
      firstNameChanged ||
      middleNameChanged ||
      lastNameChanged ||
      birthDateChanged ||
      phoneChanged ||
      currentPostalCodeChanged ||
      buildingNameChanged ||
      buildingNumberChanged ||
      address1Changed ||
      address2Changed ||
      cityChanged ||
      postalCodeChanged ||
      titleChanged
    ) {
      setToLocalStorage(LocalStorage.settingFormChanged, true);
    } else {
      deleteFromLocalStorage(LocalStorage.settingFormChanged);
    }
  };

  const isUserVerified = user?.verificationStatus === UserVerificationStatus.Verified;

  const showDob = () => {
    return user?.accountType === UserAccountType.Individual;
  };

  return (
    <Formik
      initialValues={{
        [AccountFormFields.address1]: user?.address1 || '',
        [AccountFormFields.address2]: user?.address2 || '',
        [AccountFormFields.city]: user?.city || '',
        [AccountFormFields.postalCode]: user?.postalCode || '',
        [AccountFormFields.phone]: user?.phone || '+44',
        [AccountFormFields.accountType]: user?.accountType || UserAccountType.Individual,
        birthDate: user?.birthDate || '',
        [AccountFormFields.title]: titleInitialValue,
        [AccountFormFields.buildingName]: user?.buildingName || '',
        [AccountFormFields.buildingNumber]: user?.buildingNumber || '',
        [AccountFormFields.firstName]: user?.firstName || '',
        [AccountFormFields.lastName]: user?.lastName || '',
        [AccountFormFields.email]: user?.email || '',
        [AccountFormFields.middleName]: user?.middleName || '',
        [AccountFormFields.titleOther]: user?.titleOther || '',
      }}
      validationSchema={() => {
        return Yup.lazy((values: any) => {
          return Yup.object({
            [AccountFormFields.birthDate]: showDob() ? validateDate(t) : validateAny(),
            [AccountFormFields.phone]: isTransunionStep ? validateAny() : validatePhone(t),
            [AccountFormFields.address1]: manualAddress ? validateRequired(t) : validateAny(),
            [AccountFormFields.city]: manualAddress ? validateRequired(t) : validateAny(),
            [AccountFormFields.postalCode]: validateMaxLen(t, validationLimits.postalCode, manualAddress),
            [AccountFormFields.title]: validateSelector(t),
            [AccountFormFields.firstName]: validateName(t),
            [AccountFormFields.lastName]: validateName(t),
            [AccountFormFields.buildingName]:
              !values.buildingName && !values.buildingNumber
                ? validateRequired(t, 'label_mandatory_building_details' as Messages)
                : validateAny(),
            [AccountFormFields.buildingNumber]:
              !values.buildingName && !values.buildingNumber
                ? validateRequired(t, 'label_mandatory_building_details' as Messages)
                : validateAny(),
            [AccountFormFields.titleOther]:
              values.title.value === UserTitle.Other ? validateRequired(t) : validateAny(),
            [AccountFormFields.chooseAddress]: resultAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
          });
        });
      }}
      validateOnMount={true}
      initialTouched={{
        [AccountFormFields.buildingName]: isTransunionStep,
        [AccountFormFields.buildingNumber]: isTransunionStep,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const {
          accountType,
          phone,
          address1,
          address2,
          city,
          postalCode,
          title,
          buildingName,
          buildingNumber,
          firstName,
          lastName,
          middleName,
          titleOther,
        } = values;

        if (isTransunionStep && fromNotification) {
          updateUser({
            variables: {
              input: {
                id: user ? user?.id : '',
                accountType,
                phone: removeLeadingZeroAndSpaceInContact(phone),
                address1,
                address2,
                city,
                postalCode,
                birthDate: formatDateForExchange(values.birthDate),
                title: Object.values(UserTitle).find((respect: any) => respect === title?.value),
                buildingName,
                buildingNumber,
                firstName,
                lastName,
                middleName,
                titleOther: titleOther === '' ? undefined : titleOther,
              },
            },
          });
        } else {
          dispatch(
            updateAccount.started({
              setSubmitting,
              setStatus,
              id: user ? user?.id : '',
              accountType,
              phone: removeLeadingZeroAndSpaceInContact(phone),
              address1,
              address2,
              city,
              postalCode,
              birthDate: formatDateForExchange(values.birthDate),
              title: Object.values(UserTitle).find((respect: any) => respect === title?.value),
              buildingName,
              buildingNumber,
              firstName,
              lastName,
              middleName,
              isTransunionStep,
              titleOther: titleOther === '' ? undefined : titleOther,
            })
          );
          if (isTransunionStep) {
            setNextPage();
          }
        }
      }}
    >
      {({ isSubmitting, status, values, setFieldValue }) => (
        <Form onChange={(e: any) => formChanges(e, values)} onFocus={() => setUpdateAction(true)}>
          <div className={styles.formContainer}>
            <div className={classNames(styles.formSection, { [styles.accountDetailsPopUp]: isTransunionStep })}>
              <Row>
                <Col size={5}>
                  <Typography tag="div" size="m" msg={t(Messages.personalSectionHeader)} bold />
                </Col>
              </Row>
              {!isServiceProviderUser && (
                <Row className={styles.spacing}>
                  <Col size={2}>
                    <Field
                      name={AccountFormFields.title}
                      component={SelectInput}
                      disabled={isUserVerified}
                      label={t(Messages.fieldTitle)}
                      options={titleOptions}
                      onSelectChange={(e: any) => {
                        formChanges(e, values);
                        if (e.value !== UserTitle.Other) {
                          setFieldValue(AccountFormFields.titleOther, '');
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
              {values.title?.value === UserTitle.Other && (
                <Row>
                  <Col size={isTablet ? 12 : 5}>
                    <Field
                      name={AccountFormFields.titleOther}
                      type="text"
                      component={TextInput}
                      label={t('field_title_other' as Messages)}
                      disabled={isUserVerified}
                    />
                  </Col>
                </Row>
              )}
              <Row columnTablet className={styles.spacing}>
                <Col size={isTablet ? 12 : 5}>
                  <Field
                    name={AccountFormFields.firstName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldFirstName)}
                    disabled={isUserVerified}
                    required
                  />
                </Col>
              </Row>
              <Row columnTablet className={styles.spacing}>
                <Col size={isTablet ? 12 : 5}>
                  <Field
                    name={AccountFormFields.middleName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldMiddleName)}
                    disabled={isUserVerified}
                  />
                </Col>
              </Row>
              <Row className={styles.spacing}>
                <Col size={isTablet ? 12 : 5}>
                  <Field
                    name={AccountFormFields.lastName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldLastName)}
                    disabled={isUserVerified}
                    required
                  />
                </Col>
              </Row>
              {showDob() && (
                <>
                  <Row className={styles.dobHeader}>
                    <Col size={5}>
                      <label>
                        Date of &nbsp;
                        <b>birth</b>
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Field
                      name={AccountFormFields.birthDate}
                      type="text"
                      component={DatePickerInput}
                      parse={normalizeDate(currentLanguage)}
                      label=""
                      disabled={user?.birthDate !== null && isUserVerified}
                      required
                    />
                  </Row>
                </>
              )}
            </div>
            {!isTransunionStep && (
              <div className={classNames(styles.formSection, { [styles.accountDetailsPopUp]: isTransunionStep })}>
                <Row>
                  <Col size={5}>
                    <Typography tag="div" size="m" bold msg={t(Messages.contactSectionHeader)} />
                  </Col>
                </Row>
                <Row>
                  <Col size={isTablet ? 12 : 8}>
                    <Field
                      name={AccountFormFields.email}
                      type="text"
                      component={TextInput}
                      disabled={true}
                      label={t(Messages.fieldEmail)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col size={isTablet ? 12 : 8}>
                    <Typography tag="div" size="m" msg={t(Messages.emailSubText)} className={styles.subText} />
                  </Col>
                </Row>
                {!isServiceProviderUser && (
                  <Row className={styles.spacing}>
                    <Col size={isTablet ? 12 : 8}>
                      <Field
                        name={AccountFormFields.phone}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldContactNumber)}
                        // disabled={!isEdit}
                        required
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}
            {!isServiceProviderUser && (
              <div className={classNames(styles.formSection, { [styles.accountDetailsPopUp]: isTransunionStep })}>
                <Row>
                  <Col size={8}>
                    <Typography tag="div" size="m" msg={t(Messages.addressSectionHeader)} bold />
                  </Col>
                </Row>

                <Typography tag="div" size="l" msg={t(Messages.fieldAccountEnterPostcode)} />
                <Row columnTablet className={styles.addressContainer}>
                  <Col size={isTablet ? 12 : 5}>
                    <input
                      name={AccountFormFields.autoAddress}
                      type="text"
                      value={currentPostalCode}
                      className={styles.input}
                      autoComplete="off"
                      onChange={(e: any) => {
                        setCurrentPostalCode(e.currentTarget.value);
                        setShowClearAddress(true);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter' && validatePostalCodeCharacters(currentPostalCode)) {
                          e.preventDefault();
                          getAddress();
                        }
                      }}
                      onBlur={() => {
                        if (validateMinLength(currentPostalCode) && resultAddressOptions.length === 0) {
                          setPostCodeTabOut(true);
                        } else {
                          setPostCodeTabOut(false);
                        }
                      }}
                    />
                    {showClearAddress && (
                      <Icon
                        icon="close-outline"
                        className={styles.visibilityToggleIcon}
                        onClick={() => {
                          setShowClearAddress(false);
                          setCurrentPostalCode('');
                          setAddressResult([]);
                          setAssignedAddress(-1);
                          setPostCodeTabOut(false);
                        }}
                        size={IconSizes.s}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            setShowClearAddress(false);
                            setCurrentPostalCode('');
                            setAddressResult([]);
                            setAssignedAddress(-1);
                            setPostCodeTabOut(false);
                          }
                        }}
                      />
                    )}
                  </Col>
                  <Col size={isTablet ? 12 : 3} className={classNames(styles.searchButtonContainer)}>
                    <Popover
                      showContent={postCodeTabOut}
                      position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                      title={t('title_postcode_warning' as Messages)}
                      message={t('desc_postcode_warning' as Messages)}
                    >
                      <Button
                        className={styles.fullWidth}
                        type={ButtonTypes.button}
                        style={ButtonStyles.transparent}
                        loading={searching}
                        label={t(Messages.buttonSearch)}
                        icon="search"
                        iconSize={IconSizes.sxs}
                        isFullWidth={true}
                        onClick={() => {
                          setPostCodeTabOut(false);
                          getAddress();
                        }}
                        constant
                        disabled={!validatePostalCodeCharacters(currentPostalCode)}
                      />
                    </Popover>
                  </Col>
                </Row>
                {resultAddressOptions.length > 0 && (
                  <Row className={styles.topAddressSpacing}>
                    <Col size={isTablet ? 12 : 8}>
                      <Field
                        name={AccountFormFields.chooseAddress}
                        component={SelectInput}
                        label=""
                        placeholder="---- Select your address ----"
                        options={resultAddressOptions}
                        disabled={!resultAddressOptions.length}
                        onSelectChange={handleAddressChange}
                        selectClass={ClassNamesForOverwrite.SelectAddress}
                        onFocus={() => setPostCodeTabOut(false)}
                        hasErrorProp={assignedAddress === -1}
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col className={styles.adresslink} size={9}>
                    <div
                      //eslint-disable-next-line
                      tabIndex={0}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setManualAddress(!manualAddress);
                        }
                      }}
                      onClick={() => setManualAddress(!manualAddress)}
                    >
                      <Typography msg="Or enter address manually" tag="span" />
                    </div>
                  </Col>
                </Row>
                {manualAddress && (
                  <>
                    <Row>
                      <Col size={isTablet ? 5 : 3}>
                        <Field
                          name={AccountFormFields.buildingNumber}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldBuildingNumber)}
                          validateOnMount={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col size={isTablet ? 12 : 8}>
                        <Field
                          name={AccountFormFields.buildingName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldBuildingName)}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.spacing}>
                      <Col size={isTablet ? 12 : 8}>
                        <Field
                          name={AccountFormFields.address1}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldAddress1)}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className={styles.spacing}>
                      <Col size={isTablet ? 12 : 8}>
                        <Field
                          name={AccountFormFields.address2}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldAddress2)}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.spacing}>
                      <Col size={isTablet ? 12 : 8}>
                        <Field
                          name={AccountFormFields.city}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldCity)}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col size={isTablet ? 5 : 4}>
                        <Field
                          name={AccountFormFields.postalCode}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldPostalCode)}
                          required
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            )}
            <AutoToggleForm />
            <FormError formError={status} />
          </div>

          <div className={styles.buttonSpacing}>
            <Button
              disabled={isTransunionStep ? disableVerifyAndContinue(values) : !updateAction}
              iconSize={IconSizes.sxs}
              icon={buttonIcon()}
              type={ButtonTypes.submit}
              loading={isSubmitting || loading}
              label={saveButtonText()}
              isFullWidth={isTablet}
              constant
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccountForm;
