import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, Link, useHistory } from 'react-router-dom';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useGetMeLazyQuery, UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { ProviderSubpages } from '@Routes/provider/providerSubpages';
import { AccountSettingsSubpages, NonAdminSettingsSubpages, SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { logout } from '@Store/auth/auth.actions';
import { getUser } from '@Store/auth/auth.selector';

import { MenuItems } from '../SideMenu/SideMenu';

import styles from './SpSideMenu.scss';

export interface SpSideMenuProps {
  page?: MenuItems;
  caseId?: string;
  closeClick?: () => void;
}

const SpSideMenu: React.FunctionComponent<SpSideMenuProps> = ({ page, closeClick }) => {
  const [menuSelected] = React.useState(page || MenuItems.Dashboard);
  const [showSettingsMenu, setShowSettingsMenu] = React.useState(false);
  const user = useSelector(getUser);
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const [getUserDetails, userDetails] = useGetMeLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  React.useEffect(() => {
    if (user === null) {
      getUserDetails();
    }
  }, [user, getUserDetails]);

  const dispatch = useDispatch();

  //const isStaffUser = user?.accountType === UserAccountType.Individual && user.isStaff;

  return (
    <div className={styles.container}>
      {!showSettingsMenu ? (
        <>
          <div className={styles.lifeLedgerIcon}>
            <div
              className={styles.clickSection}
              onClick={() => history.push(`${Links.providerDashboard}${queryString}`)}
            >
              <img
                width={isTablet ? '128' : '173'}
                height="37"
                src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
                alt=""
              />
            </div>
            {isMobile && <Icon onClick={closeClick} icon="close" className={styles.closeIcon} size={IconSizes.sxs} />}
          </div>
          <Row
            constant
            className={classNames(
              menuSelected === MenuItems.Dashboard ? styles.dashboardContainer : styles.settingsContainer
            )}
          >
            <Col className={styles.icon}>
              <Icon icon="dashboard" />
            </Col>
            <Col className={styles.linkContainer}>
              <Link to={`${Links.providerDashboard}${queryString}`}>
                <Typography msg="Dashboard" size="l" tag="div" color="newGray" />
              </Link>
            </Col>
          </Row>

          <Row
            constant
            className={classNames(
              menuSelected === MenuItems.Reports ? styles.dashboardContainer : styles.settingsContainer,
              styles.mb24
            )}
          >
            <Col className={styles.icon}>
              <Icon icon="trendingUp" />
            </Col>
            <Col className={styles.linkContainer}>
              <Link to={`${Links.serviceProviderReports}${queryString}`}>
                <Typography
                  msg={t(`${Messages.titleProviderSubItemPrefix}${ProviderSubpages.Reports}` as Messages)}
                  size="l"
                  tag="div"
                  color="newGray"
                />
              </Link>
            </Col>
          </Row>
          <Row
            column
            className={classNames(
              menuSelected === MenuItems.Settings ? styles.dashboardContainer : styles.settingsContainer,
              styles.divSpacing
            )}
          >
            <Row constant>
              <Col className={styles.icon}>
                <Icon icon="settings" />
              </Col>
              <Col>
                {isMobile ? (
                  <span onClick={() => setShowSettingsMenu(true)}>
                    <Typography
                      className={styles.nameSpacing}
                      msg={`${user?.firstName} ${user?.lastName}`}
                      size="l"
                      tag="div"
                      color="newGray"
                    />
                  </span>
                ) : (
                  <Link
                    to={generatePath(`${Links.settings}?${queryString}`, {
                      subpage:
                        user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
                          ? NonAdminSettingsSubpages.IDValidation
                          : SettingsSubpages.Account,
                    })}
                  >
                    <Typography
                      className={styles.nameSpacing}
                      msg={
                        user
                          ? `${user?.firstName} ${user?.lastName}`
                          : `${userDetails.data?.me.info.firstName} ${userDetails.data?.me.info.lastName}`
                      }
                      size="l"
                      tag="div"
                      color="newGray"
                    />
                  </Link>
                )}
              </Col>
            </Row>
          </Row>
          {/* )} */}

          <Row
            constant
            className={classNames(styles.settingsContainer, styles.clickSection, styles.logoutSpacing)}
            onClick={() => dispatch(logout())}
          >
            <Col className={styles.icon}>
              <Icon icon="logout" />
            </Col>
            <Col className={styles.linkContainer}>
              <Typography msg="Log out" size="l" tag="div" color="newGray" />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div className={styles.lifeLedgerIcon}>
            <div
              className={classNames(styles.clickSection, styles.backToMenuContainer)}
              onClick={() => setShowSettingsMenu(false)}
            >
              <Icon size={IconSizes.ssm} icon="chevronLeft" className={styles.iconPosition} />
              <Typography className={styles.backToText} msg="Back to menu" size="l" tag="div" />
            </div>
            {isMobile && (
              <Icon onClick={closeClick} icon="close" className={styles.settingsCloseIcon} size={IconSizes.sxs} />
            )}
          </div>
          <div className={styles.leftSpacing}>
            <Row constant className={styles.title} alignCenter wrap>
              <span className={styles.iconSpacing}>
                <Icon icon="settings" size={IconSizes.sss} className={styles.settingsIcon} />
              </span>
              <Typography msg={t(Messages.titleSettings)} tag="span" />
            </Row>
            <Typography msg="Account management" tag="div" size="s" className={styles.accountMgmt} />

            {Object.values(AccountSettingsSubpages).map((settingHeader: any, idx: number) => {
              return (
                <Link
                  key={idx}
                  onClick={closeClick}
                  to={generatePath(`${Links.settings}?${queryString}`, { subpage: settingHeader })}
                  className={styles.settingsLink}
                >
                  <div className={styles.textContainer}>
                    <Typography
                      msg={t(`${Messages.titleSettingsSubItemPrefix}${settingHeader.toLowerCase()}` as Messages)}
                      tag="div"
                      size="lbx"
                    />
                  </div>
                  <div className={styles.chevronRightContainer}>
                    <Icon size={IconSizes.ssm} icon="chevronRight" />
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default SpSideMenu;
