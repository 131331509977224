import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { LocalStorage } from '@Config/constants';
import { DeathCaseInformation } from '@Graphql/graphqlTypes.generated';
import {
  createDCProviderMutation,
  sendReferenceCodeMutation,
  sendNewProviderMutation,
  caseProviderUpdateMutation,
  removeUserInProviderMutation,
  removeDataDefinitionMutation,
  updateDCProviderMutation,
} from '@Graphql/providers/providers.mutations';
import { deathCaseInformationQuery } from '@Graphql/providers/providers.queries';
import { hideModal } from '@Store/app/app.actions';
import {
  sendAccessCode,
  sendReferenceCode,
  professionalAccessCode,
  caseDocAccessRequest,
  createCaseProvider,
  sendNewProvName,
  removeUserInProvider,
  removeDataDefinition,
  updateCaseProvider,
} from '@Store/provider/provider.actions';
import {
  CaseProviderCreateMutationPayload,
  SendAccessCodeActionPayload,
  SendReferenceCodeActionPayload,
  ProfessionalAccessCodeActionPayload,
  SendNewRequestPayLoad,
  CaseDocumentAccessRequestMutationPayload,
  PortalUserDeleteMutationPayload,
  DeleteDataDefinitionMutationPayload,
  CaseProviderUpdateMutationPayload,
} from '@Store/provider/provider.types';
import { normaliseGqlError } from '@Utils/form';
import { setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

export function* sendAccessCodeSaga(action: Action<SendAccessCodeActionPayload>) {
  const { accessCode, setDcInfo, setStatus, setSubmitting } = action.payload;
  try {
    const result: DeathCaseInformation = yield call(deathCaseInformationQuery, { accessCode });
    yield setDcInfo({ ...result, accessCode });
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* professionalAccessCodeSaga(action: Action<ProfessionalAccessCodeActionPayload>) {
  const { accessCode, setDcInfo, isStaff, setIsLoading } = action.payload;
  try {
    const result: DeathCaseInformation = yield call(deathCaseInformationQuery, { accessCode, isStaff });
    yield setDcInfo && setDcInfo({ ...result, accessCode });
    yield setIsLoading && setIsLoading(false);
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* removeUserInProviderSaga(action: Action<PortalUserDeleteMutationPayload>) {
  const { id, successMessage, userAccountType } = action.payload;
  try {
    yield call(removeUserInProviderMutation, { id }, userAccountType);
    yield put(notificationSuccess(successMessage));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* removeDataDefinitionSaga(action: Action<DeleteDataDefinitionMutationPayload>) {
  const { id, successMessage } = action.payload;
  try {
    yield call(removeDataDefinitionMutation, { id });
    yield put(notificationSuccess(successMessage));
  } catch (error) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* sendReferenceCodeSaga(action: Action<SendReferenceCodeActionPayload>) {
  const {
    setHasReferenceCode,
    setActualRefCode,
    setShowTextBox,
    accessCode,
    referenceCode,
    finalBalance,
  } = action.payload;
  try {
    yield call(sendReferenceCodeMutation, { accessCode, referenceCode, finalBalance });
    yield setHasReferenceCode && setHasReferenceCode(true);
    yield setShowTextBox && setShowTextBox(false);
    yield setActualRefCode && setActualRefCode(referenceCode);
    yield setToLocalStorage(LocalStorage.referenceCode, referenceCode);
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* createDcProviderSaga(action: Action<CaseProviderCreateMutationPayload>) {
  try {
    const result: CaseProviderCreateMutationPayload = yield call(createDCProviderMutation, action.payload);
    yield put(createCaseProvider.done({ result, params: action.payload }));
    yield put(hideModal({}));
  } catch (error: any) {
    yield put(hideModal({}));
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* updateProviderSaga(action: Action<CaseProviderUpdateMutationPayload>) {
  try {
    const result: CaseProviderUpdateMutationPayload = yield call(updateDCProviderMutation, action.payload);
    yield put(updateCaseProvider.done({ result, params: action.payload }));
    yield put(hideModal({}));
  } catch (error: any) {
    yield put(hideModal({}));
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* caseDocumentAccessRequestSaga(action: Action<CaseDocumentAccessRequestMutationPayload>) {
  try {
    yield call(caseProviderUpdateMutation, action.payload);
  } catch (error) {
    yield put(hideModal({}));
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* watchProviderSaga() {
  yield all([
    yield takeLatest(sendAccessCode.started, sendAccessCodeSaga),
    yield takeLatest(professionalAccessCode.started, professionalAccessCodeSaga),
    yield takeLatest(sendReferenceCode.started, sendReferenceCodeSaga),
    yield takeLatest(createCaseProvider.started, createDcProviderSaga),
    yield takeLatest(updateCaseProvider.started, updateProviderSaga),
    yield takeLatest(sendNewProvName.started, sendNewProv),
    yield takeLatest(caseDocAccessRequest.started, caseDocumentAccessRequestSaga),
    yield takeLatest(removeUserInProvider.started, removeUserInProviderSaga),
    yield takeLatest(removeDataDefinition.started, removeDataDefinitionSaga),
  ]);
}

export function* sendNewProv(action: Action<SendNewRequestPayLoad>) {
  const { newServiceProviderName, comments, setIsSending } = action.payload;
  try {
    yield setIsSending(true);
    yield call(sendNewProviderMutation, { newServiceProviderName, comments });
    yield setIsSending(false);
    yield put(notificationSuccess('New Provider request sent successfully'));
    yield put(hideModal({}));
  } catch (error) {
    yield put(notificationError(normaliseGqlError(error.message)));
    yield setIsSending(false);
  }
}
