import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { changePassword } from '@Store/auth/auth.actions';
import { getUser } from '@Store/auth/auth.selector';
import { validateConfirmPasswordOld, validatePassword, validateRequired } from '@Utils/validator';

import styles from './PasswordChangeForm.scss';

export interface PasswordChangeFormProps {}

enum PasswordChangeFormFields {
  oldPassword = 'oldPassword',
  password = 'password',
  confirmPassword = 'confirm_password',
}

const PasswordChangeForm: React.FunctionComponent<PasswordChangeFormProps> = () => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const id = user?.id;
  if (!id) {
    return null;
  }

  const successMessage = t(Messages.notificationPassswordChangeSuccess);

  return (
    <Formik
      initialValues={{
        [PasswordChangeFormFields.oldPassword]: '',
        [PasswordChangeFormFields.password]: '',
        [PasswordChangeFormFields.confirmPassword]: '',
      }}
      validationSchema={Yup.object({
        [PasswordChangeFormFields.oldPassword]: validateRequired(t),
        [PasswordChangeFormFields.password]: validatePassword(t),
        [PasswordChangeFormFields.confirmPassword]: validateConfirmPasswordOld(t),
      })}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        dispatch(changePassword.started({ setSubmitting, setStatus, resetForm, successMessage, ...values }));
      }}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <div className={styles.formContainer}>
            <GridRow>
              <GridCol size={8}>
                <Field
                  name={PasswordChangeFormFields.oldPassword}
                  type="password"
                  component={TextInput}
                  label={t(Messages.fieldOldPassword)}
                  placeholder={t(Messages.fieldOldPasswordPlaceholder)}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol size={8}>
                <Field
                  name={PasswordChangeFormFields.password}
                  type="password"
                  component={TextInput}
                  label="Now, type what you would like to change your password to"
                  placeholder={t(Messages.fieldPasswordPlaceholder)}
                />
              </GridCol>
              <GridCol size={8}>
                <Field
                  name={PasswordChangeFormFields.confirmPassword}
                  type="password"
                  component={TextInput}
                  label={t(Messages.fieldConfirmPassword)}
                  placeholder={t(Messages.fieldConfirmPasswordPlaceholder)}
                />
              </GridCol>
            </GridRow>
            <FormError formError={status} />
          </div>
          <Row>
            <Col size={isTablet ? 10 : 4}>
              <Button
                type={ButtonTypes.submit}
                disabled={isSubmitting}
                loading={isSubmitting}
                label={t(Messages.buttonUpdate)}
                isFullWidth
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordChangeForm;
