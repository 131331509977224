import classNames from 'classnames';
import * as React from 'react';

import Typography from '@Components/Typography';

export interface CardTitleProps {
  heading: string;
  description?: string;
  containerClassName?: string;
}

const CardTitle: React.FunctionComponent<CardTitleProps> = ({ heading, description, containerClassName }) => {
  return (
    <div className={classNames(containerClassName)}>
      <Typography tag="div" msg={heading} size="l" bold />
      {description && <Typography tag="div" msg={description} size="m" color="footerColor" />}
    </div>
  );
};

export default CardTitle;
