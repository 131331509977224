import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { CollaboratorRelationshipFe, UserTitle, useGetMeQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { titleOptions } from '@Utils/form';

import { RelationshipLevel, RelationShipDefinition } from '../../../../model/Relationship';
import { LifeEventSubPagesForSomeone } from '../LifeEventSubpages';

import styles from './PersonalDetailsForm.scss';

export interface PersonalDetailsFormProps extends NavigationProps {
  missing: boolean;
}

export enum PersonalDetailsFormFields {
  title = 'title',
  titleOther = 'titleOther',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  relationship = 'relationship',
  termsAndConditions = 'termsAndConditions',
}

const PersonalDetailsForm: React.FunctionComponent<PersonalDetailsFormProps> = ({
  back,
  next,
  setSubpage,
  missing,
}) => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;

  const user = useGetMeQuery({
    fetchPolicy: 'network-only',
  });

  const relationships: RelationShipDefinition[] = [
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Partner.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Partner,
      relationshipType: RelationshipLevel.secondLevel,
      index: 0,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Child.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Child,
      relationshipType: RelationshipLevel.secondLevel,
      index: 1,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Parent.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Parent,
      relationshipType: RelationshipLevel.secondLevel,
      index: 2,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Sibling.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Sibling,
      relationshipType: RelationshipLevel.secondLevel,
      index: 3,
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.FamilyMember.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.FamilyMember,
      relationshipType: RelationshipLevel.secondLevel,
      index: 4,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Friend.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Friend,
      relationshipType: RelationshipLevel.secondLevel,
      index: 5,
    },
  ];

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  return (
    <>
      <Typography msg="Add the details of the person whose address you are changing" tag="div" size="l" />
      <div className={styles.bodyContainer}>
        <Typography msg="Add the details of the person whose address you are changing" tag="div" size="m" />
        <Formik
          initialValues={{
            [PersonalDetailsFormFields.title]: user.data?.me.info.title || '',
            [PersonalDetailsFormFields.titleOther]: user.data?.me.info.titleOther || '',
            [PersonalDetailsFormFields.firstName]: user.data?.me.info.firstName || '',
            [PersonalDetailsFormFields.lastName]: user.data?.me.info.lastName || '',
            [PersonalDetailsFormFields.email]: user.data?.me.info.email || '',
            [PersonalDetailsFormFields.relationship]: relationshipInitialValue,
            [PersonalDetailsFormFields.phoneNumber]: user.data?.me.info.phone || '+44',
            [PersonalDetailsFormFields.termsAndConditions]: false,
          }}
          onSubmit={() => {
            history.push(
              generatePath(`${Links.lifeEventEdit}${queryString}`, {
                subpage: LifeEventSubPagesForSomeone.Address,
                missing,
              })
            );
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <GridRow className={styles.spacing}>
                <GridCol size={6}>
                  <Field
                    name={PersonalDetailsFormFields.title}
                    component={SelectInput}
                    label={t(Messages.fieldTitle)}
                    options={titleOptions}
                    required
                  />
                </GridCol>
              </GridRow>
              {values.title === UserTitle.Other && (
                <GridRow className={styles.spacing}>
                  <GridCol size={6}>
                    <Field
                      name={PersonalDetailsFormFields.titleOther}
                      type="text"
                      component={TextInput}
                      label={t('field_title_other' as Messages)}
                      required
                    />
                  </GridCol>
                </GridRow>
              )}
              <GridRow className={styles.spacing}>
                <GridCol size={6}>
                  <Field
                    name={PersonalDetailsFormFields.firstName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldFirstName)}
                    // helperText={t('label_name_govt_id' as Messages)}
                    required
                  />
                </GridCol>
                <GridCol size={6}>
                  <Field
                    name={PersonalDetailsFormFields.lastName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldSurname)}
                    required
                  />
                </GridCol>
              </GridRow>
              <GridRow className={styles.spacing}>
                <GridCol size={12}>
                  <Field
                    name={PersonalDetailsFormFields.email}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldEmail)}
                    required
                  />
                </GridCol>
              </GridRow>
              <GridRow className={styles.spacing}>
                <GridCol size={12}>
                  <Field
                    name={PersonalDetailsFormFields.phoneNumber}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldContactNumber)}
                    required
                  />
                </GridCol>
              </GridRow>
              <GridRow className={styles.spacing}>
                <GridCol size={12}>
                  <Field
                    name={PersonalDetailsFormFields.relationship}
                    options={relationships}
                    type="text"
                    component={MultiSelect}
                    label="State relationship to this person*"
                    controlDescription="You can choose more than one"
                  />
                </GridCol>
              </GridRow>
              <GridRow className={styles.spacing}>
                <GridCol size={12}>
                  <Field
                    name={PersonalDetailsFormFields.termsAndConditions}
                    label="I confirm I have the authority to act for the individual whose address I am changing."
                    component={Checkbox}
                    type={CheckBoxTypes.checkbox}
                  />
                </GridCol>
              </GridRow>
              <NavigationBlock next={next} back={back} setSubpage={setSubpage} isSubmitting={isSubmitting} hideSkip />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PersonalDetailsForm;
