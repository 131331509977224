export class Address {
  addresses: FullAddress[];

  postcode: string;

  formAddress2 = (x: any) => {
    if (x.line_2 !== '') {
      if (x.line_3 !== '') {
        if (x.line_4 !== '') {
          return `${x.line_2},${x.line_3},${x.line_4}`;
        }
        return `${x.line_2},${x.line_3}`;
      }
      return x.line_2;
    }
    return '';
  };

  constructor(addressResponse: any) {
    this.addresses = addressResponse.addresses.map((x: any, idx: number) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return <FullAddress>{
        buildingName: x.building_name !== '' ? x.building_name : x.sub_building_name,
        buildingNumber: x.building_number !== '' ? x.building_number : x.sub_building_number,
        address1: x.line_1,
        address2: this.formAddress2(x),
        locality: x.locality,
        city: x.town_or_city,
        postalCode: addressResponse.postcode,
        formattedAddress: x.formatted_address.filter((n: string) => n).join(', '),
        id: idx,
      };
    });

    this.postcode = addressResponse.postcode;
  }
}

export interface FullAddress {
  id: number;
  buildingName: string;
  buildingNumber: string;
  address1: string;
  address2: string;
  locality: string;
  city: string;
  postalCode: string;
  formattedAddress: string;
}
