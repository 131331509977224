import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import GoogleSignIn from '@Components/application/GoogleSignIn';
import Button, { ButtonTypes } from '@Components/Button';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { login } from '@Store/auth/auth.actions';
import { deleteFromLocalStorage } from '@Utils/localStorage';
import { validateAny, validateEmail, validateRequired, validateSortCode } from '@Utils/validator';

import styles from './LoginForm.scss';

export interface LoginFormProps {}

enum LoginFormFields {
  email = 'email',
  password = 'password',
  otp = 'otp',
  keepOtpAlive = 'keepOtpAlive',
}

const LoginForm: React.FunctionComponent<LoginFormProps> = () => {
  const t = useTranslations();
  const { collaboratorKey } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEmailVerified: boolean = searchParams.get('email_verified') === 'true' || false;
  const accessCode = searchParams.get('access_code') || undefined;
  const emailParam = searchParams.get('email') || '';
  const dispatch = useDispatch();
  const history = useHistory();
  const isLogout = history.location.pathname === Links.logout;

  const [showOtpBox, setShowOtpBox] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const queryString = history.location.search;
  deleteFromLocalStorage(LocalStorage.caseTypeState);
  deleteFromLocalStorage(LocalStorage.filteredValues);
  deleteFromLocalStorage(LocalStorage.filters);
  deleteFromLocalStorage(LocalStorage.spFilteredValues);
  deleteFromLocalStorage(LocalStorage.spFilters);

  return (
    <Formik
      initialValues={{
        [LoginFormFields.email]: emailParam,
        [LoginFormFields.password]: '',
        [LoginFormFields.otp]: '',
        // [LoginFormFields.keepOtpAlive]: false,
      }}
      validationSchema={Yup.object({
        [LoginFormFields.email]: validateEmail(t),
        [LoginFormFields.password]: validateRequired(t),
        [LoginFormFields.otp]: showOtpBox ? validateSortCode(t) : validateAny(),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const { password } = values;
        if (values.otp !== '') {
          dispatch(
            login.started({
              setSubmitting,
              setStatus,
              setShowOtpBox,
              setQrCodeUrl,
              ...values,
              email: values.email.trim(),
              //  queryString,
              collaboratorKey,
              accessCode,
            })
          );
        } else {
          dispatch(
            login.started({
              setSubmitting,
              setStatus,
              setShowOtpBox,
              setQrCodeUrl,
              email: values.email.trim(),
              password,
              //  queryString,
              collaboratorKey,
              accessCode,
            })
          );
        }
      }}
    >
      {({ isSubmitting, status }) => {
        return (
          <Form>
            {(isLogout || isEmailVerified) && (
              <Row size={12} className={classNames(styles.spacing, styles.signedOutContainer)}>
                <Typography
                  msg={isEmailVerified ? 'Email verified successfully!' : t(Messages.msgSignedOut)}
                  color="newGray"
                  size="s"
                  tag="div"
                />
              </Row>
            )}
            <Row size={12} className={styles.spacing}>
              <Field
                name={LoginFormFields.email}
                type="text"
                component={TextInput}
                label={t(Messages.fieldEmail)}
                fullWidth
                placeholder={t(Messages.fieldEmailAddressPlaceholder)}
              />
            </Row>
            <Row size={12} className={styles.spacing}>
              <Field
                name={LoginFormFields.password}
                type="password"
                component={TextInput}
                label={t(Messages.fieldPassword)}
                fullWidth
                placeholder={t(Messages.fieldLoginPasswordPlaceholder)}
              />
            </Row>
            {showOtpBox && (
              <>
                <Row size={12} className={styles.spacing}>
                  <Field
                    name={LoginFormFields.otp}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldOtp)}
                    fullWidth
                  />
                </Row>
                {/* <Row>
                  <Field
                    name={LoginFormFields.keepOtpAlive}
                    label={t(Messages.fieldSkipPeriod)}
                    component={Checkbox}
                    type={CheckBoxTypes.checkbox}
                  />
                </Row> */}
              </>
            )}
            {qrCodeUrl && (
              <Row size={12} className={styles.spacing}>
                <QRCode value={qrCodeUrl} />
              </Row>
            )}
            <FormError formError={status} />
            <Row className={styles.buttonPlacing} size={12}>
              <Col size={12}>
                <Button
                  type={ButtonTypes.submit}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  label={t(Messages.buttonLogin)}
                  isFullWidth
                />
              </Col>
            </Row>
            <Row size={12} justifyCenter>
              <Link to={`${Links.forgotPassword}${queryString}`} className={styles.links}>
                <Typography color="electricBlue" size="mb" bold tag="span" msg={t(Messages.questionForgot)} />
              </Link>
            </Row>
            {/* <AzureAD provider={msalInstance}> */}
            <GoogleSignIn collaboratorKey={collaboratorKey} isLoginPage />
            {/* </AzureAD> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
