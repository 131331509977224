import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import {
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables,
  DowngradeStripeSubscriptionMutation,
  DowngradeStripeSubscriptionMutationVariables,
  SubscriptionPaymentNode,
  usePackagesQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationCancelSubscription, mutationDowngradeStripeSubscription } from '@Graphql/lc/mutations';
import { queryLCList } from '@Graphql/lc/queries';
import { queryUserSubscriptionPayments } from '@Graphql/payments/queries';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './CancelSubscriptionConfirmationModalBody.scss';

export interface CancelSubscriptionConfirmationModalBodyProps {
  userSubscription: SubscriptionPaymentNode;
}

const CancelSubscriptionConfirmationModalBody: React.FunctionComponent<ModalProviderProps<
  CancelSubscriptionConfirmationModalBodyProps
>> = ({ closeModal, modalData: { userSubscription } }) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);

  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  const [cancelSubscription, { loading }] = useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(mutationCancelSubscription, {
    refetchQueries: [getOperationName(queryUserSubscriptionPayments) || '', getOperationName(queryLCList) || ''],
    onCompleted: () => {
      closeModal();
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [downgradeSubscription, { loading: downgradeLoading }] = useMutation<
    DowngradeStripeSubscriptionMutation,
    DowngradeStripeSubscriptionMutationVariables
  >(mutationDowngradeStripeSubscription, {
    refetchQueries: [getOperationName(queryUserSubscriptionPayments) || ''],
    onCompleted: () => {
      closeModal();
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const basicPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'BASIC_LIFE_CASE');

  return (
    <>
      <Typography
        className={styles.headerTitle}
        msg="Would you like to downgrade to the basic package?"
        tag="h4"
        size="xl"
        bold
      />
      <Typography
        className={styles.mb28}
        msg="You are currently subscribed to the Life Ledger Discovery package, if you cancel, all companies and information will be removed from your dashboard."
        tag="div"
        size="l"
      />

      <Typography msg="By downgrading to the basic package, you will:" tag="div" size="l" />
      <Row className={styles.gap16} alignCenter constant>
        <Icon className={styles.icon} icon="tick" size={IconSizes.ss} />
        <Typography
          msg="Keep all of the companies and information that you have added on the Life Ledger platform"
          tag="div"
          size="l"
        />
      </Row>

      <Row className={classNames(styles.gap16, styles.mb28)} alignCenter constant>
        <Icon className={styles.icon} icon="tick" size={IconSizes.ss} />
        <Typography
          msg="Pay £12 per year (£8 less annually than your Life Ledger Discovery subscription"
          tag="div"
          size="l"
        />
      </Row>

      <Typography
        className={styles.mb28}
        msg={`Your Life Ledger Discovery annual subscription is paid until ${languageBasedDate(
          userSubscription.subscription.subscriptionRenewal,
          currentLanguage
        )}, until this date, even if you cancel you will have all the benefits of your current subscription.`}
        tag="div"
        size="l"
      />
      <Typography
        msg="If you would still like to cancel you can do so by clicking ‘Cancel subscription’ below"
        tag="div"
        size="l"
      />

      <Button
        onClick={() =>
          downgradeSubscription({
            variables: {
              input: {
                toPackage: basicPackage?.id || '',
                baseCase: userSubscription.baseCaseId,
              },
            },
          })
        }
        constant
        label="Downgrade to Life Ledger basic package"
        isFullWidth
        className={classNames(styles.mt35, styles.mb28)}
        loading={downgradeLoading}
      />

      {!loading && (
        <LinkButton
          textCenter
          className={classNames(styles.mb28)}
          isRed
          linkTitle="Cancel subscription"
          onClick={() =>
            cancelSubscription({
              variables: {
                input: {
                  subscription: userSubscription.subscription.id,
                },
              },
            })
          }
        />
      )}

      <LinkButton
        textCenter
        className={styles.mb28}
        linkTitle="Do not cancel subscription"
        onClick={() => closeModal()}
      />
    </>
  );
};

export default CancelSubscriptionConfirmationModalBody;
