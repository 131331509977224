import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UpdateObAuthIdMutation, UpdateObAuthIdMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationUpdateObAuthId } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import styles from '@Routes/settings/StripePaymentsPage/SuccessPage/SuccessPage.scss';
import { normaliseGqlError } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';

import headerStyles from '../../../../components/Header/Header.scss';

export interface SuccessPageProps {}

const SuccessPage: React.FunctionComponent<SuccessPageProps> = () => {
  const t = useTranslations();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const accountVerificationrReportId = searchParams.get('code') || undefined;
  const state = decodeURI(searchParams.get('state') || '');

  const [updateObAuthId] = useMutation<UpdateObAuthIdMutation, UpdateObAuthIdMutationVariables>(
    mutationUpdateObAuthId,
    {
      onError: (mutError: any) => {
        dispatch(notificationError(normaliseGqlError(mutError.message)));
      },
    }
  );

  React.useEffect(() => {
    updateObAuthId({
      variables: {
        input: {
          authCode: accountVerificationrReportId,
          sessionId: state,
        },
      },
    });
  }, [updateObAuthId, accountVerificationrReportId, state]);

  return (
    <div className={styles.mainContainer}>
      <Row justifyCenter className={classNames(headerStyles.logoContainer, styles.mt30)}>
        <a href={Links.lifeLedger}>
          <Icon icon="logo-full" size={IconSizes.logoFull} />
        </a>
      </Row>

      <div className={classNames(styles.successModal, styles.textCenter)}>
        <div className={styles.relPosition}>
          <Icon icon="lock-tick" />
        </div>
        <Typography
          className={styles.mb10}
          msg={t('page_title_bank_not_configured' as Messages)}
          tag="h4"
          bold
          size="xl"
        />
        <Typography
          html
          className={classNames(styles.mb24, styles.lsp)}
          msg={t('msg_bank_not_configured' as Messages)}
          tag="div"
          textCenter
        />
        <Button
          type={ButtonTypes.button}
          label={t('button_back_to_dashboard' as Messages)}
          onClick={() => history.push(generatePath(Links.lcReady, { id }))}
          isFullWidth
        />
      </div>
    </div>
  );
};

export default SuccessPage;
