import { ExecutionResult } from 'graphql';

import { PreArchivedDCStatuses } from '@Config/constants';
import { queryDC, queryDCList } from '@Graphql/dc/queries';
import ApolloClient from '@Graphql/graphqlClient';
import {
  DeathCaseQuery,
  DeathCaseQueryVariables,
  DeathCasesQuery,
  DeathCasesQueryVariables,
} from '@Graphql/graphqlTypes.generated';

export const fetchDCQuery = async (id: DeathCaseQueryVariables['id']) => {
  const result: ExecutionResult<DeathCaseQuery> = await ApolloClient.query<DeathCaseQuery, DeathCaseQueryVariables>({
    query: queryDC,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  return result.data?.deathCase;
};

export const fetchDeathCasesQuery = async () => {
  const result: ExecutionResult<DeathCasesQuery> = await ApolloClient.query<DeathCasesQuery, DeathCasesQueryVariables>({
    query: queryDCList,
    variables: {
      status: PreArchivedDCStatuses,
    },
    fetchPolicy: 'network-only',
  });

  return result.data?.deathCases.edges[0].node;
};
