import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import Paper, { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { CaseTags, Links, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UpdateUserMutation, UpdateUserMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';

import styles from './CaseOnboard.scss';

export interface CaseOnboardProps {}

enum CaseTypes {
  LifeCase = 'LifeCase',
  DeathCase = 'DeathCase',
  ChangeAddress = 'ChangeAddress',
  ChangeName = 'ChangeName',
}
const CaseOnboard: React.FunctionComponent<CaseOnboardProps> = () => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const [dcShowMore, setDcShowMore] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isIPadMini = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });

  const [lcShowMore, setLcShowMore] = React.useState(false);

  const deathCaseNotes = [
    'Date of Birth',
    'Date of Death',
    'Full name and usual address',
    'Death certificate, interim death certificate or certificate of death to upload (scan or photograph)',
  ];
  const lifeCaseNotes = [
    'Date of Birth',
    'Name and address',
    'Partner’s details',
    'Will if created',
    'Executor/s detail',
  ];

  const additionalLifeCaseNotes = ['Provider/company name', 'Product name or type', 'Account number'];

  const [caseType, setCaseType] = React.useState('');

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: (resp: any) => {
      if (resp.updateUser.user.isFirstLogin) {
        history.push(`${Links.home}${queryString}`);
      }
      history.push(
        caseType === CaseTypes.DeathCase
          ? generatePath(`${Links.dcCreate}${queryString}`, { tag: `tag=${CaseTags.deathCaseCreated}` })
          : generatePath(`${Links.lcCreate}${queryString}`, { tag: `tag=${CaseTags.lifeCaseCreated}` })
      );
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const showIcons = () => {
    if (isTablet || isMobile) {
      return false;
    }
    return true;
  };

  const putFlexStart = () => {
    if (dcShowMore && lcShowMore) {
      return false;
    }
    if (dcShowMore || lcShowMore) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Typography msg="What would you like to do?" tag="h3" size="xxl" />
      <Typography
        msg="Select one of the options below"
        tag="div"
        size="l"
        className={styles.bottomSpacing}
        color="footerColor"
      />
      <div className={classNames(styles.colContainer, { [styles.flexStart]: putFlexStart() })}>
        <Paper
          tabIndex={0}
          width="width-full"
          className={caseType === CaseTypes.DeathCase ? styles.selectedPaper : styles.normalGrayPaper}
        >
          <Row className={styles.gap24} onClick={() => setCaseType(CaseTypes.DeathCase)} size={12}>
            {showIcons() && (
              <Col className={styles.iconPlacement}>
                <Icon icon="paper-plane" size={IconSizes.mm} />
              </Col>
            )}
            <Col className={styles.flexColumnDisplay}>
              <Row constant justifyBetween>
                <Typography msg="Send death notifications" size="l" tag="div" bold />

                <Icon
                  className={caseType === CaseTypes.DeathCase ? '' : styles.handSymbol}
                  onClick={() => setCaseType(CaseTypes.DeathCase)}
                  icon={caseType === CaseTypes.DeathCase ? 'circle-checked' : 'circle-unchecked'}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      setCaseType(CaseTypes.DeathCase);
                    }
                  }}
                />
              </Row>
              <Typography
                msg="Notify all the companies connected to the deceased from one place"
                size="l"
                tag="div"
                className={styles.mb24}
              />

              {!dcShowMore && (
                <Row
                  constant
                  size={12}
                  className={styles.question}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setDcShowMore(!dcShowMore);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      e.stopPropagation();
                      setDcShowMore(!dcShowMore);
                    }
                  }}
                >
                  <Col>
                    <Typography
                      className={styles.textDecorationColor}
                      underline
                      msg="What do I need?"
                      size="l"
                      tag="div"
                      color="footerColor"
                    />
                  </Col>
                  <Col tabindex={0}>
                    <Icon icon="chevronDown" size={IconSizes.ssm} />
                  </Col>
                </Row>
              )}

              {dcShowMore && (
                <>
                  <div className={styles.moveShowLess}>
                    <Typography
                      msg="To help make this process as quick and easy as possible we suggest you have the following to hand."
                      tag="p"
                      size="m"
                      className={styles.evenBottomSpacing}
                    />

                    <Typography msg="The deceased’s" tag="p" size="m" bold />

                    <List containerClassName={styles.evenBottomSpacing} listItems={deathCaseNotes} fontSize="m" />

                    <Typography msg={t(Messages.labelFromYou)} tag="p" size="m" bold />
                    <Typography msg={t('desc_expected_from_user' as Messages)} tag="p" size="m" />
                  </div>
                  <Row
                    tabIndex={0}
                    size={12}
                    constant
                    className={styles.question}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setDcShowMore(!dcShowMore);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 13) {
                        e.stopPropagation();
                        setDcShowMore(!dcShowMore);
                      }
                    }}
                  >
                    <Col>
                      <Typography
                        className={styles.textDecorationColor}
                        underline
                        msg="Show less"
                        size="l"
                        tag="div"
                        color="footerColor"
                      />
                    </Col>
                    <Col>
                      <Icon icon="chevronUp" size={IconSizes.ssm} />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Paper>

        <Paper
          width="width-full"
          tabIndex={0}
          className={caseType === CaseTypes.LifeCase ? styles.selectedPaper : styles.normalGrayPaper}
        >
          <Row className={styles.gap24} onClick={() => setCaseType(CaseTypes.LifeCase)} size={12}>
            {showIcons() && (
              <Col className={styles.iconPlacement}>
                <Icon icon="people" size={IconSizes.sm} />
              </Col>
            )}
            <Col className={styles.flexColumnDisplay}>
              <Row constant justifyBetween>
                <Typography msg="Life planning" size="l" tag="div" bold />

                <Icon
                  className={caseType === CaseTypes.DeathCase ? '' : styles.handSymbol}
                  onClick={() => setCaseType(CaseTypes.LifeCase)}
                  icon={caseType === CaseTypes.LifeCase ? 'circle-checked' : 'circle-unchecked'}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      setCaseType(CaseTypes.LifeCase);
                    }
                  }}
                />
              </Row>
              <Typography
                msg="Organise for end of life and ensure everything is in place and easy to access"
                size="l"
                tag="div"
                className={styles.mb24}
              />

              {!lcShowMore && (
                <Row
                  constant
                  size={12}
                  className={styles.question}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setLcShowMore(!lcShowMore);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      e.stopPropagation();
                      setLcShowMore(!lcShowMore);
                    }
                  }}
                >
                  <Col>
                    <Typography
                      className={styles.textDecorationColor}
                      underline
                      msg="What do I need?"
                      size="l"
                      tag="div"
                      color="footerColor"
                    />
                  </Col>
                  <Col tabindex={0}>
                    <Icon icon="chevronDown" size={IconSizes.ssm} />
                  </Col>
                </Row>
              )}

              {lcShowMore && (
                <>
                  <div>
                    <Typography
                      msg="You can stop and start this process at any time, the sort of information you will need to hand is."
                      tag="p"
                      size="m"
                      className={styles.evenBottomSpacing}
                    />

                    <Typography msg="The individual’s" tag="p" size="m" bold />

                    <List containerClassName={styles.lcEvenBottomSpacing} listItems={lifeCaseNotes} fontSize="m" />

                    <Typography msg="Account details to be stored including:" tag="p" size="m" bold />

                    <List listItems={additionalLifeCaseNotes} fontSize="m" />
                  </div>
                  <Row
                    constant
                    tabIndex={0}
                    size={12}
                    className={styles.question}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setLcShowMore(!lcShowMore);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 13) {
                        e.stopPropagation();
                        setLcShowMore(!lcShowMore);
                      }
                    }}
                  >
                    <Col>
                      <Typography
                        className={styles.textDecorationColor}
                        underline
                        msg="Show less"
                        size="l"
                        tag="div"
                        color="footerColor"
                      />
                    </Col>
                    <Col>
                      <Icon icon="chevronUp" size={IconSizes.ssm} />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Paper>
      </div>
      <Row className={styles.topSpacing}>
        <Col size={3}>
          {isIPadMini ? (
            <Button
              type={ButtonTypes.button}
              size={ButtonSizes.fill}
              onClick={() => {
                if (caseType === CaseTypes.ChangeAddress) {
                  dispatch(
                    showModal({
                      type: ModalTypes.changeAddressModal,
                      params: {
                        title: '',
                        modalSize: PaperWidths.ml,
                        disableOutsideClick: true,
                      },
                    })
                  );
                } else {
                  updateUser({
                    variables: {
                      input: {
                        id: user ? user?.id : '',
                        // isFirstLogin: true,
                      },
                    },
                  });
                }
              }}
              label="Next"
              disabled={caseType === ''}
              isFullWidth
              // loading={loading}
            />
          ) : (
            <Button
              size={ButtonSizes.fill}
              type={ButtonTypes.button}
              style={ButtonStyles.white}
              label="Go back"
              icon="chevronLeft"
              //  loading={loading}
              iconSize={IconSizes.ss}
              isFullWidth
              constant
              onClick={() => {
                updateUser({
                  variables: {
                    input: {
                      id: user ? user?.id : '',
                      isFirstLogin: true,
                    },
                  },
                });
              }}
            />
          )}
        </Col>

        <Col size={5}>
          {isIPadMini ? (
            <Button
              size={ButtonSizes.fill}
              type={ButtonTypes.button}
              style={ButtonStyles.white}
              label="Go back"
              icon="chevronLeft"
              // loading={loading}
              iconSize={IconSizes.ss}
              isFullWidth
              constant
              onClick={() => {
                updateUser({
                  variables: {
                    input: {
                      id: user ? user?.id : '',
                      isFirstLogin: true,
                    },
                  },
                });
              }}
            />
          ) : (
            <Button
              type={ButtonTypes.button}
              size={ButtonSizes.fill}
              onClick={() => {
                if (caseType === CaseTypes.ChangeAddress) {
                  dispatch(
                    showModal({
                      type: ModalTypes.changeAddressModal,
                      params: {
                        title: '',
                        modalSize: PaperWidths.ml,
                        disableOutsideClick: true,
                        data: {
                          changeNameField: false,
                        },
                      },
                    })
                  );
                } else if (caseType === CaseTypes.ChangeName) {
                  dispatch(
                    showModal({
                      type: ModalTypes.changeAddressModal,
                      params: {
                        title: '',
                        modalSize: PaperWidths.ml,
                        disableOutsideClick: true,
                        data: {
                          changeNameField: true,
                        },
                      },
                    })
                  );
                } else {
                  updateUser({
                    variables: {
                      input: {
                        id: user ? user?.id : '',
                        // isFirstLogin: true,
                      },
                    },
                  });
                }
              }}
              label="Next"
              disabled={caseType === ''}
              isFullWidth
              // loading={loading}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CaseOnboard;
