import React from 'react';

import CertificateForm from './CertificateForm';
import ConfirmationForm from './ConfirmationForm';
import GeneralFormAddress from './GeneralFormAddress';
import GeneralForm from './GeneralFormNew';
import LegalDocumentsForm from './LegalDocumentsForm';
import LegalForm from './LegalForm';
import PartnerForm from './PartnerForm';
import ProbateForm from './ProbateForm';
import WillForm from './WillForm';

export enum DeathCaseSubpages {
  General = 'general',
  Certificate = 'certificate',
  Partner = 'partner',
  Will = 'will',
  Legal = 'legal',
  Confirmation = 'confirmation',
}

export enum DeathCaseSubpages2 {
  General = 'general',
  Certificate = 'certificate',
}

export enum DeathCaseSubpagesNew {
  General = 'general',
  GeneralStep2 = 'general-address',
  Certificate = 'certificate',
  Partner = 'partner',
  Will = 'will',
  Probate = 'probate',
  Legal = 'legal',
  Confirmation = 'confirmation',
}

export enum DeathCaseSubpagesGeneral {
  General = 'general',
  GeneralStep2 = 'general-address',
  Certificate = 'certificate',
}

export enum ProfDeathCaseSubpages {
  General = 'general',
  GeneralStep2 = 'general-address',
  Certificate = 'certificate',
  Partner = 'partner',
  Will = 'will',
  Probate = 'probate',
  Legal = 'legal',
  Legal2 = 'legal2',
  Confirmation = 'confirmation',
}

export enum DeathCaseSubpagesNewMenu {
  General = 'Deceased’s Info',
  Certificate = 'Death certificate',
  Partner = 'Partner’s Info',
  Will = 'Will and Probate',
  Legal = 'Legal representation',
  Confirmation = 'Confirm details',
}

export const deathCaseSubpagesConfig: {
  [key in DeathCaseSubpagesGeneral]: {
    title: string;
    stepIndex?: number;
    totalSteps?: number;
    step: DeathCaseSubpagesNewMenu;
    component: React.ReactNode;
    back: DeathCaseSubpagesGeneral | null;
    next: DeathCaseSubpagesGeneral | null;
    current: DeathCaseSubpagesGeneral;
  };
} = {
  [DeathCaseSubpagesNew.General]: {
    title: "Deceased's Information",
    step: DeathCaseSubpagesNewMenu.General,
    stepIndex: 0,
    totalSteps: 2,
    component: GeneralForm,
    back: null,
    next: DeathCaseSubpagesGeneral.GeneralStep2,
    current: DeathCaseSubpagesGeneral.General,
  },
  [DeathCaseSubpagesNew.GeneralStep2]: {
    title: "Deceased's Information",
    stepIndex: 1,
    totalSteps: 2,
    step: DeathCaseSubpagesNewMenu.General,
    component: GeneralFormAddress,
    back: DeathCaseSubpagesGeneral.General,
    next: DeathCaseSubpagesGeneral.Certificate,
    current: DeathCaseSubpagesGeneral.GeneralStep2,
  },
  [DeathCaseSubpagesNew.Certificate]: {
    title: 'Death certificate',
    step: DeathCaseSubpagesNewMenu.Certificate,
    component: CertificateForm,
    back: DeathCaseSubpagesGeneral.GeneralStep2,
    next: null,
    current: DeathCaseSubpagesGeneral.Certificate,
  },
  // [DeathCaseSubpagesNew.Partner]: {
  //   title: "Partner's Info",
  //   step: DeathCaseSubpagesNewMenu.Partner,
  //   component: PartnerForm,
  //   back: DeathCaseSubpagesNew.Certificate,
  //   next: DeathCaseSubpagesNew.Will,
  //   current: DeathCaseSubpagesNew.Partner,
  // },
  // [DeathCaseSubpagesNew.Will]: {
  //   title: 'Will and Probate',
  //   step: DeathCaseSubpagesNewMenu.Will,
  //   component: WillForm,
  //   stepIndex: 0,
  //   totalSteps: 2,
  //   back: DeathCaseSubpagesNew.Partner,
  //   next: DeathCaseSubpagesNew.Probate,
  //   current: DeathCaseSubpagesNew.Will,
  // },
  // [DeathCaseSubpagesNew.Probate]: {
  //   title: 'Will and Probate',
  //   step: DeathCaseSubpagesNewMenu.Will,
  //   stepIndex: 1,
  //   totalSteps: 2,
  //   component: ProbateForm,
  //   back: DeathCaseSubpagesNew.Will,
  //   next: DeathCaseSubpagesNew.Legal,
  //   current: DeathCaseSubpagesNew.Probate,
  // },
  // [DeathCaseSubpagesNew.Legal]: {
  //   title: 'Legal Representation',
  //   step: DeathCaseSubpagesNewMenu.Legal,
  //   component: LegalForm,
  //   back: DeathCaseSubpagesNew.Probate,
  //   next: DeathCaseSubpagesNew.Confirmation,
  //   current: DeathCaseSubpagesNew.Legal,
  // },
  // [DeathCaseSubpagesNew.Confirmation]: {
  //   title: 'Confirm details',
  //   step: DeathCaseSubpagesNewMenu.Confirmation,
  //   component: ConfirmationForm,
  //   back: DeathCaseSubpagesNew.Legal,
  //   next: null,
  //   current: DeathCaseSubpagesNew.Confirmation,
  // },
};

export const profDeathCaseSubpagesConfig: {
  [key in ProfDeathCaseSubpages]: {
    title: string;
    stepIndex?: number;
    totalSteps?: number;
    step: DeathCaseSubpagesNewMenu;
    component: React.ReactNode;
    back: ProfDeathCaseSubpages | null;
    next: ProfDeathCaseSubpages | null;
    current: ProfDeathCaseSubpages;
  };
} = {
  [ProfDeathCaseSubpages.General]: {
    title: "Deceased's Information",
    step: DeathCaseSubpagesNewMenu.General,
    stepIndex: 0,
    totalSteps: 2,
    component: GeneralForm,
    back: null,
    next: ProfDeathCaseSubpages.GeneralStep2,
    current: ProfDeathCaseSubpages.General,
  },
  [ProfDeathCaseSubpages.GeneralStep2]: {
    title: "Deceased's Information",
    stepIndex: 1,
    totalSteps: 2,
    step: DeathCaseSubpagesNewMenu.General,
    component: GeneralFormAddress,
    back: ProfDeathCaseSubpages.General,
    next: ProfDeathCaseSubpages.Certificate,
    current: ProfDeathCaseSubpages.GeneralStep2,
  },
  [ProfDeathCaseSubpages.Certificate]: {
    title: 'Death certificate',
    step: DeathCaseSubpagesNewMenu.Certificate,
    component: CertificateForm,
    back: ProfDeathCaseSubpages.GeneralStep2,
    next: ProfDeathCaseSubpages.Partner,
    current: ProfDeathCaseSubpages.Certificate,
  },
  [ProfDeathCaseSubpages.Partner]: {
    title: "Partner's Info",
    step: DeathCaseSubpagesNewMenu.Partner,
    component: PartnerForm,
    back: ProfDeathCaseSubpages.Certificate,
    next: ProfDeathCaseSubpages.Will,
    current: ProfDeathCaseSubpages.Partner,
  },
  [ProfDeathCaseSubpages.Will]: {
    title: 'Will and Probate',
    step: DeathCaseSubpagesNewMenu.Will,
    component: WillForm,
    stepIndex: 0,
    totalSteps: 2,
    back: ProfDeathCaseSubpages.Partner,
    next: ProfDeathCaseSubpages.Probate,
    current: ProfDeathCaseSubpages.Will,
  },
  [ProfDeathCaseSubpages.Probate]: {
    title: 'Will and Probate',
    step: DeathCaseSubpagesNewMenu.Will,
    stepIndex: 1,
    totalSteps: 2,
    component: ProbateForm,
    back: ProfDeathCaseSubpages.Will,
    next: ProfDeathCaseSubpages.Legal,
    current: ProfDeathCaseSubpages.Probate,
  },
  [ProfDeathCaseSubpages.Legal]: {
    title: 'Legal Representation',
    step: DeathCaseSubpagesNewMenu.Legal,
    stepIndex: 0,
    totalSteps: 2,
    component: LegalForm,
    back: ProfDeathCaseSubpages.Probate,
    next: ProfDeathCaseSubpages.Legal2,
    current: ProfDeathCaseSubpages.Legal,
  },
  [ProfDeathCaseSubpages.Legal2]: {
    title: 'Legal Representation',
    step: DeathCaseSubpagesNewMenu.Legal,
    stepIndex: 1,
    totalSteps: 2,
    component: LegalDocumentsForm,
    back: ProfDeathCaseSubpages.Legal,
    next: ProfDeathCaseSubpages.Confirmation,
    current: ProfDeathCaseSubpages.Legal2,
  },
  [ProfDeathCaseSubpages.Confirmation]: {
    title: 'Confirm details',
    step: DeathCaseSubpagesNewMenu.Confirmation,
    component: ConfirmationForm,
    back: ProfDeathCaseSubpages.Legal2,
    next: null,
    current: ProfDeathCaseSubpages.Confirmation,
  },
};
