import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import ProviderDCInfoPage from '@Routes/provider/ProviderDCInfoPage';

import { RouteInfo } from '../appRoutes';

import CompanyProfile from './CRA/CompanyProfile/CompanyProfile';
import BankNotConfigured from './NordigenBanks/BankNotConfigured';
import Institutions from './NordigenBanks/Institutions/Institutions';
import AutomatedReportsPage from './Reports/AutomatedReportsPage';

const routes: RouteInfo[] = [
  {
    path: Links.providerDCInfo,
    component: ProviderDCInfoPage,
    title: Messages.pageProviderDCInfo,
    isServiceProviderRoute: false,
  },
  {
    path: Links.userNotification,
    component: ProviderDCInfoPage,
    title: Messages.pageProviderDCInfo,
    isServiceProviderRoute: false,
  },
  {
    path: Links.craCompanies,
    component: CompanyProfile,
    title: 'page_title_cra' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.institutions,
    component: Institutions,
    title: 'page_title_nordigen_institutes' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.bankNotConfigured,
    component: BankNotConfigured,
    title: 'page_title_bank_not_configured' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.automatedReportForm,
    component: AutomatedReportsPage,
    title: Messages.pageProviderDCInfo,
    isServiceProviderRoute: false,
  },
];

export default routes;
