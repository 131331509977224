import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import EmptyFileView from '@Components/application/EmptyFileView';
import FileView from '@Components/application/FileView';
import Paper from '@Components/application/GrayPaper';
import Button, { ButtonStyles } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes, NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationUploadDocuments } from '@Graphql/dc/mutations';
import { queryDC } from '@Graphql/dc/queries';
import {
  DeathCaseFragment,
  LifeCaseFragment,
  DocumentNode,
  UploadDocumentMutation,
  DocumentDocumentType,
  DeathCaseStatus,
  useCaseDocumentsFromSpsQuery,
  NotificationDocumentNode,
  Permissions,
  useCollaboratorsQuery,
} from '@Graphql/graphqlTypes.generated';
import { queryLC } from '@Graphql/lc/queries';
import { useTranslations } from '@Hooks/useTranslations';
import paperStyles from '@Routes/shared/SummaryInfoDeceased/SummaryInfoDeceased.scss';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { deleteConfirmationHandler, infoModalHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import layoutStyles from '../../deathCase/ReadyDCPage/ReadyDCPage.scss';

import styles from './DocumentsPage.scss';

export interface DocumentsPageProps {
  caseData: DeathCaseFragment | LifeCaseFragment;
  isLifeCase?: boolean;
}

const DocumentsPage: React.FunctionComponent<DocumentsPageProps> = ({ caseData, isLifeCase = false }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const user = useSelector(getUser);
  const funeralPlansFiles = caseData.__typename === NodeDefiner.LifeCase ? caseData.funeralPlans : [];

  const insurancePolicyFiles = caseData.__typename === NodeDefiner.LifeCase ? caseData.insurancePolicies : [];

  const caseDocumentsUploaded = [
    ...(caseData.certificates as DocumentNode[]),
    ...(caseData.wills as DocumentNode[]),
    ...(caseData.otherDocuments as DocumentNode[]),
    ...(caseData.representationLetters as DocumentNode[]),
    ...(caseData.powerOfAttorneys as DocumentNode[]),
    ...(caseData.instructionLetters as DocumentNode[]),
    ...(caseData.letterToCompanys as DocumentNode[]),
    ...(funeralPlansFiles as DocumentNode[]),
    ...(insurancePolicyFiles as DocumentNode[]),
  ];

  const [uploadedFiles, setUploadedFiles] = React.useState(caseDocumentsUploaded);

  // const recentUploaded = uploadedFiles.reverse().slice(0, 3);

  const spCaseDocuments = useCaseDocumentsFromSpsQuery({
    variables: {
      baseCase: caseData.id,
    },
    fetchPolicy: 'network-only',
  });

  const collaboratorsData = useCollaboratorsQuery({
    variables: {
      baseCase: caseData.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [updateDocumentsMutation] = useMutation<UploadDocumentMutation, any>(mutationUploadDocuments, {
    refetchQueries: [getOperationName(isLifeCase ? queryLC : queryDC) || ''],
    onCompleted: (output: any) => {
      dispatch(notificationSuccess(t(Messages.labelChatDocumentUploadSuccess)));
      const newDocument = uploadedFiles.concat(output.uploadDocument.document);
      setUploadedFiles(newDocument);
    },
    onError: (apiError: any) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
    },
  });

  if (spCaseDocuments.loading) {
    return <LoadingSpinner />;
  }

  const getCollaboratorDetails = () => {
    const collab = collaboratorsData.data?.collaborators.edges.find((cb: any) => cb.node.email === user?.email);
    return collab ? collab.node.id : undefined;
  };

  const fileUploadHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.fileUploadModal,
        params: {
          data: {
            buttonText: 'Continue',
            isLifeCase,
            uploadedFiles,
            onClose: (documentType: DocumentDocumentType | undefined, files: File[], otherDocumentTypeName: string) => {
              updateDocumentsMutation({
                variables: {
                  input: {
                    baseCaseId: caseData.id,
                    documentType,
                    files,
                    otherDocumentType: otherDocumentTypeName !== '' ? otherDocumentTypeName : undefined,
                    collaboratorId: getCollaboratorDetails(),
                  },
                },
              });
            },
          },
          // title: t(Messages.modalUploadDocumentTitle),
          // subtitle: t(Messages.modalUploadDocumentSubtitle),
          modalSize: PaperWidths.m,
          isNotificationModal: true,
        },
      })
    );
  };

  const cannotRemoveDocumentHandler = () =>
    infoModalHandler(
      t,
      Messages.modalCannotRemoveCertificateTitle,
      Messages.modalCannotRemoveCertificateSubtitle,
      dispatch
    );

  const isCertificateOnActiveCase = (value: any) => {
    return caseData.status === DeathCaseStatus.Active && value.documentType === DocumentDocumentType.Certificate;
  };

  const removeDocumentHandler = (id: any, index: number, documentType: DocumentDocumentType) => {
    if (isCertificateOnActiveCase(documentType)) {
      return cannotRemoveDocumentHandler();
    }
    return deleteConfirmationHandler(
      t,
      dispatch,
      documentType,
      caseData.__typename === NodeDefiner.LifeCase
    )(id, index);
  };

  const spDocuments = spCaseDocuments.data?.caseDocumentsFromSps as NotificationDocumentNode[];

  const isReadOnlyUser = caseData.myPermission === Permissions.ReadOnly;

  const blockDocumentAccess = (fileDetails: any) => {
    if (isLifeCase && fileDetails.collaborator) {
      return isReadOnlyUser || (!fileDetails.collaboratorsView && fileDetails.collaborator.email !== user?.email);
    }
    return false;
  };

  const hideDelete = (fileDetails: any) => {
    if (isLifeCase && fileDetails.collaborator) {
      return isReadOnlyUser || (!fileDetails.collaboratorsView && fileDetails.collaborator.email !== user?.email);
    }
    if (isReadOnlyUser) {
      return true;
    }
    return isMobile;
  };

  const getFileId = (fileDetails: any) => {
    if (isReadOnlyUser) {
      return '';
    }
    return isMobile ? fileDetails.id : '';
  };

  return (
    <section className={classNames(styles.pageWidth, { [layoutStyles.rightSideGap]: !isLifeCase })}>
      <Typography
        msg={t(Messages.infoDocumentsTab)}
        className={styles.margintop32}
        tag="div"
        size="m"
        color="footerColor"
      />
      {!isReadOnlyUser && (
        <Row columnTablet size={12}>
          <Col size={isTablet ? 12 : 9} className={styles.cardRightSpacing}>
            <Paper width="width-full" className={paperStyles.deathCaseContainer}>
              <CardTitle heading="Upload file to case" />
              {/* eslint-disable-next-line */}
              <div onClick={() => fileUploadHandler()} className={styles.margintop32} tabIndex={0}>
                <section className={styles.wrapperBlock}>
                  <Row justifyCenter constant>
                    <Button
                      style={ButtonStyles.transparent}
                      onClick={() => fileUploadHandler()}
                      icon="upload"
                      iconSize={IconSizes.ss}
                      constant
                    >
                      <Typography msg="Upload document" tag="span" />
                    </Button>
                  </Row>
                  <Typography
                    size="m"
                    color="footerColor"
                    tag="div"
                    msg={t(Messages.labelAcceptedFileFormats)}
                    className={styles.topSpacing}
                  />
                </section>
              </div>
            </Paper>
          </Col>
        </Row>
      )}
      <Row className={styles.margintop32}>
        <Typography msg={t(Messages.labelUploadedByNotifier)} tag="h4" size="xl" />
      </Row>
      {uploadedFiles.length === 0 ? (
        <Paper width="width-full">
          <EmptyFileView />
        </Paper>
      ) : (
        uploadedFiles.map((fileDetails: DocumentNode, idx: number) => (
          <Row className={styles.mb8} constant key={idx} size={12}>
            <Col size={11}>
              <FileView
                blockDocumentAccess={blockDocumentAccess(fileDetails)}
                isLifeCase={isLifeCase}
                isDocumentsPage={true}
                key={idx}
                fileDetails={fileDetails}
                isGray
                deleteIconOutSide={!isMobile}
                fileId={getFileId(fileDetails)}
                onRemoveClick={() => removeDocumentHandler(fileDetails.id, idx, fileDetails.documentType)}
              />
            </Col>
            {!hideDelete(fileDetails) && (
              <Col
                tabindex={0}
                constant
                textRight
                size={1}
                alignCenter
                onClick={() => removeDocumentHandler(fileDetails.id, idx, fileDetails.documentType)}
                onKeyDown={(e: any) => {
                  if (e.keyCode === EnterKeyCode) {
                    removeDocumentHandler(fileDetails.id, idx, fileDetails.documentType);
                  }
                }}
              >
                <Icon icon="trash" className={styles.handSymbol} />
              </Col>
            )}
          </Row>
        ))
      )}

      {!isLifeCase && (
        <>
          <Row className={styles.margintop32}>
            <Typography msg={t(Messages.labelUploadedByNotifiedCompanies)} tag="h4" size="xl" />
          </Row>
          {spDocuments.length === 0 ? (
            <Paper className={styles.emptyContainerWidth}>
              <EmptyFileView />
            </Paper>
          ) : (
            spDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
              <Row className={styles.mb8} key={idx} size={11}>
                <FileView isLifeCase={isLifeCase} isDocumentsPage={true} key={idx} fileDetails={fileDetails} isGray />
              </Row>
            ))
          )}
        </>
      )}
    </section>
  );
};

export default DocumentsPage;
