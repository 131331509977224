import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@Components/Button';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { CreateObSessionMutation, CreateObSessionMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationCreateObSession } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import NotSupportedBanks from '@Routes/shared/NotSupportedBanks/NotSupportedBanks';
import { hideModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './ObBanksNoSupport.scss';

export interface ObBanksNoSupport {
  selectedInstitute?: any;
  baseCase?: string;
}

const ObBanksNoSupport: React.FunctionComponent<ModalProviderProps<ObBanksNoSupport>> = ({
  modalData: { selectedInstitute, baseCase },
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [createObSession, { loading }] = useMutation<CreateObSessionMutation, CreateObSessionMutationVariables>(
    mutationCreateObSession,
    {
      onCompleted: (resp: any) => {
        window.location.href = resp.createObSession.sessionUrl;
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
        dispatch(hideModal({}));
      },
    }
  );

  const firstPoint =
    selectedInstitute &&
    t('bank_restrict_1stpoint' as Messages).replace('[X]', selectedInstitute.transactionalHistoryDays);
  const secondPoint =
    selectedInstitute && t('bank_restrict_2ndpoint' as Messages).replace('[X data]', selectedInstitute.infoField);

  const listItems = selectedInstitute && selectedInstitute.infoField ? [firstPoint, secondPoint] : [firstPoint];

  return selectedInstitute ? (
    <div className={styles.layout}>
      <Typography msg={t('label_before_continue' as Messages)} bold tag="h4" size="xl" />
      <Typography
        msg={t('desc_bank_restriction' as Messages).replace('[X]', selectedInstitute.infoField)}
        tag="div"
        size="m"
      />
      {selectedInstitute.logo ? (
        <Row justifyCenter>
          <img src={selectedInstitute.logo} alt="" className={styles.profLogo} />
        </Row>
      ) : (
        <Row justifyCenter>
          <div className={styles.noLogoContainer}>{selectedInstitute.name.charAt(0).toUpperCase()}</div>
        </Row>
      )}

      <Typography msg={selectedInstitute.name} tag="div" bolder size="m" />
      {selectedInstitute.restrictedInfo === null || selectedInstitute.restrictedInfo === '' ? (
        <List listItems={listItems} />
      ) : (
        <List listItems={[selectedInstitute.restrictedInfo]} />
      )}

      <Button
        label={t('btn_connect_bank' as Messages)}
        isFullWidth
        loading={loading}
        onClick={() =>
          createObSession({
            variables: {
              input: {
                baseCase: baseCase || '',
                institutionId: selectedInstitute.institutionId,
              },
            },
          })
        }
      />
      <Typography
        msg={t('label_redirect_online_banking' as Messages)}
        textCenter
        color="footerColor"
        tag="div"
        size="m"
      />
    </div>
  ) : (
    <div className={styles.layout}>
      <NotSupportedBanks />
    </div>
  );
};

export default ObBanksNoSupport;
