import classNames from 'classnames';
import * as React from 'react';

import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './NewsLetters.scss';

export interface NewsLettersProps {
  containerClassName?: string;
  isRetargetedPage?: boolean;
}

const NewsLetters: React.FunctionComponent<NewsLettersProps> = ({ containerClassName, isRetargetedPage }) => {
  const t = useTranslations();

  return (
    <div className={classNames({ [styles.opaque]: !isRetargetedPage }, containerClassName)}>
      <Row justifyCenter className={classNames(styles.textContainer, { [styles.opaque]: isRetargetedPage })}>
        <Typography msg={t(Messages.labelAsSeenIn)} size="l" tag="div" />
      </Row>
      <Row
        constant
        alignEnd
        justifyCenter
        size={12}
        className={classNames(styles.logosContainer, { [styles.opaque]: isRetargetedPage })}
      >
        <Icon className={styles.newsIcons} icon="guardian" />
        <Icon className={styles.newsIcons} icon="which" />
        <Icon className={styles.newsIcons} icon="goodhousekeeping" />
        <Icon className={styles.newsIcons} icon="bbc" />
        <Icon className={styles.newsIcons} icon="gazette" />
        <Icon className={styles.newsIcons} icon="dailymail" />
      </Row>
    </div>
  );
};

export default NewsLetters;
