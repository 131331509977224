import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { validationLimits, LocalStorage, AddressPortal, ReactResponsiveQueries, EnterKeyCode } from '@Config/constants';
import { Messages } from '@Config/messages';
import { LifeCaseNode, BaseCasePropertyType, MissingInfoNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { createLC, updateLC } from '@Store/lc/lc.actions';
import { addressHelper, disableNextButton } from '@Utils/helpers';
import { deleteFromLocalStorage, getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import {
  validateAny,
  validateMaxLen,
  validateRequired,
  validatePostalCodeCharacters,
  validateMinLength,
  validateSelector,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../../model/Address';

import styles from './GeneralFormAddress.scss';

export interface GeneralFormAddressProps extends NavigationProps {
  containerClasses?: string;
}

export interface GeneralFormAddressStateProps {
  data: LoadableItem<LifeCaseNode>;
}

interface GeneralFormAddressPrivateProps extends GeneralFormAddressProps, GeneralFormAddressStateProps {}

export enum GeneralFormAddressFields {
  isSelf = 'isSelf',
  buildingName = 'buildingName',
  buildingNumber = 'buildingNumber',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  postalCode = 'postalCode',
  propertyType = 'propertyType',
  autoAddress = 'autoAddress',
  isAddressOverThree = 'isAddressOverThree',
  autoPrevAddress = 'autoPrevAddress',

  chooseAddress = 'chooseAddress',

  previousAddress1 = 'previousAddress1',
  previousAddress2 = 'previousAddress2',
  previousPostalCode = 'previousPostalCode',
  previousCity = 'previousCity',
  previousAutoAddress = 'previousAutoAddress',

  prevBuildingName = 'prevBuildingName',
  prevBuildingNumber = 'prevBuildingNumber',

  choosePrevAddress = 'choosePrevAddress',
}

export interface GeneralFormAddressValues {
  [GeneralFormAddressFields.isSelf]: boolean;
  [GeneralFormAddressFields.isAddressOverThree]: boolean;
}

const GeneralFormAddress: React.FunctionComponent<GeneralFormAddressPrivateProps> = ({
  back,
  next,
  setSubpage,
  containerClasses,
  data,
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = history.location.search;
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isSelfUser = data.item?.isSelf;
  const sessionId = searchParams.get('session_id') || undefined;
  const [manualAddress, setManualAddress] = React.useState(isSelfUser ? true : !!data.item?.address1);
  const [previousManualAddress, setPreviousManualAddress] = React.useState(!!data.item?.previousAddress1);

  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);
  const [prevAddressResult, setPrevAddressResult] = React.useState<FullAddress[]>([]);

  const [currentPostalCode, setCurrentPostalCode] = React.useState('');
  const [prevPostalCode, setPrevPostalCode] = React.useState('');

  const [searching, setSearching] = React.useState(false);
  const [prevSearching, setPrevSearching] = React.useState(false);

  const [assignedAddress, setAssignedAddress] = React.useState(-1);
  const [prevAssignedAddress, setPrevAssignedAddress] = React.useState(-2);

  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [showPrevClearAddress, setShowPrevClearAddress] = React.useState(false);

  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);
  const [prevPostCodeTabOut, setPrevPostCodeTabOut] = React.useState(false);

  React.useEffect(() => {
    deleteFromLocalStorage(LocalStorage.lifeCaseTag);
    if (isSelfUser) {
      setManualAddress(true);
    }
  }, [isSelfUser, sessionId]);

  if (data.isFetching) {
    return <LoadingSpinner />;
  }

  const matchCreateTag = history.location.pathname.split('/').includes('tag=ralc');

  const missingItems = data.item?.missingInfo.filter(
    (m: MissingInfoNode) =>
      !m.value && (m.field.includes('address') || m.field.includes('city') || m.field.includes('post'))
  );

  const isEdit = !!data.item?.id;
  let isEditable = isEdit;
  const isSameLifeCase = getFromLocalStorage(LocalStorage.lifeCaseId) || '';
  if (!isEditable) {
    isEditable = isSameLifeCase !== '' ? true : isEditable;
  }
  const setNextStep = () => (next ? setSubpage(next) : {});

  const baseCaseProperties = Object.values(BaseCasePropertyType).map((baseCaseProps) => ({
    label: t(`${Messages.labelBaseCasePropertyPrefix}${baseCaseProps.toLowerCase()}` as Messages),
    value: baseCaseProps,
  }));

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const resultPrevAddressOptions =
    prevAddressResult &&
    prevAddressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const getAddress = (previous: boolean) => {
    const paramPostalCode = previous ? prevPostalCode : currentPostalCode;
    if (paramPostalCode !== '') {
      if (previous) {
        setPrevSearching(true);
      } else {
        setSearching(true);
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', paramPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apidata) => {
          if (apidata.Message) {
            dispatch(notificationError(apidata.Message));
            if (previous) {
              setPrevAddressResult([]);
              setPrevSearching(false);
            } else {
              setAddressResult([]);
              setSearching(false);
            }
          } else {
            const outputData = new Address(apidata);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError('Address not found for this postal code'));
            }
            if (previous) {
              setPrevAddressResult(outputData.addresses);
              setPrevSearching(false);
            } else {
              setAddressResult(outputData.addresses);
              setSearching(false);
            }
          }
        });
    }
  };

  const AutoToggleForm = () => {
    const { setFieldValue, values } = useFormikContext();
    const { isAddressOverThree } = values as GeneralFormAddressValues;

    React.useEffect(() => {
      const searchedAddress = addressResult.find((addr: FullAddress) => addr.id === assignedAddress);
      if (searchedAddress) {
        setFieldValue(GeneralFormAddressFields.buildingName, addressHelper(searchedAddress.buildingName));
        setFieldValue(GeneralFormAddressFields.buildingNumber, addressHelper(searchedAddress.buildingNumber));
        setFieldValue(GeneralFormAddressFields.address1, addressHelper(searchedAddress.address1));
        setFieldValue(GeneralFormAddressFields.address2, addressHelper(searchedAddress.address2));
        setFieldValue(GeneralFormAddressFields.city, addressHelper(searchedAddress.city));
        setFieldValue(GeneralFormAddressFields.postalCode, addressHelper(searchedAddress.postalCode));
      }

      const prevSearchedAddress = prevAddressResult.find((addr: FullAddress) => addr.id === prevAssignedAddress);
      if (prevSearchedAddress) {
        setFieldValue(GeneralFormAddressFields.prevBuildingName, addressHelper(prevSearchedAddress.buildingName));
        setFieldValue(GeneralFormAddressFields.prevBuildingNumber, addressHelper(prevSearchedAddress.buildingNumber));
        setFieldValue(GeneralFormAddressFields.previousAddress1, addressHelper(prevSearchedAddress.address1));
        setFieldValue(GeneralFormAddressFields.previousAddress2, addressHelper(prevSearchedAddress.address2));
        setFieldValue(GeneralFormAddressFields.previousCity, addressHelper(prevSearchedAddress.city));
        setFieldValue(GeneralFormAddressFields.previousPostalCode, addressHelper(prevSearchedAddress.postalCode));
      }

      if (isAddressOverThree) {
        setFieldValue(GeneralFormAddressFields.prevBuildingName, '');
        setFieldValue(GeneralFormAddressFields.prevBuildingNumber, '');
        setFieldValue(GeneralFormAddressFields.previousAddress1, '');
        setFieldValue(GeneralFormAddressFields.previousAddress2, '');
        setFieldValue(GeneralFormAddressFields.previousCity, '');
        setFieldValue(GeneralFormAddressFields.previousPostalCode, '');
        setPreviousManualAddress(false);
      }
    }, [setFieldValue, isAddressOverThree]);

    return null;
  };

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
    setManualAddress(true);
  };

  const handlePrevAddressChange = (item: any) => {
    setPrevAssignedAddress(item.value);
    setPreviousManualAddress(true);
  };

  const isMissingItem = missingItems && missingItems?.length > 0 && !matchCreateTag;

  return (
    <Formik
      initialValues={{
        [GeneralFormAddressFields.isSelf]: data.item?.isSelf || false,

        [GeneralFormAddressFields.buildingName]: (isSelfUser ? user?.buildingName : data.item?.buildingName) || '',
        [GeneralFormAddressFields.buildingNumber]:
          (isSelfUser ? user?.buildingNumber : data.item?.buildingNumber) || '',

        [GeneralFormAddressFields.address1]: (isSelfUser ? user?.address1 : data.item?.address1) || '',
        [GeneralFormAddressFields.address2]: (isSelfUser ? user?.address2 : data.item?.address2) || '',
        [GeneralFormAddressFields.city]: (isSelfUser ? user?.city : data.item?.city) || '',
        [GeneralFormAddressFields.postalCode]: (isSelfUser ? user?.postalCode : data.item?.postalCode) || '',
        [GeneralFormAddressFields.propertyType]: data.item?.propertyType || '',
        [GeneralFormAddressFields.isAddressOverThree]: data.item?.isAddressOverThree || false,

        [GeneralFormAddressFields.prevBuildingName]: data.item?.prevBuildingName || '',
        [GeneralFormAddressFields.prevBuildingNumber]: data.item?.prevBuildingNumber || '',

        [GeneralFormAddressFields.previousPostalCode]: data.item?.previousPostalCode || '',
        [GeneralFormAddressFields.previousCity]: data.item?.previousCity || '',
        [GeneralFormAddressFields.previousAddress1]: data.item?.previousAddress1 || '',
        [GeneralFormAddressFields.previousAddress2]: data.item?.previousAddress2 || '',
      }}
      validationSchema={() => {
        return Yup.lazy(() => {
          return Yup.object({
            [GeneralFormAddressFields.isAddressOverThree]: validateRequired(t),
            [GeneralFormAddressFields.propertyType]: validateRequired(t),

            [GeneralFormAddressFields.postalCode]: validateMaxLen(t, validationLimits.postalCode, true),

            [GeneralFormAddressFields.previousPostalCode]: validateMaxLen(t, validationLimits.postalCode, false),
            [GeneralFormAddressFields.chooseAddress]:
              resultAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
            [GeneralFormAddressFields.choosePrevAddress]:
              resultPrevAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
            [GeneralFormAddressFields.address1]: isMissingItem
              ? validateRequired(t, 'label_compulsory_field' as Messages)
              : validateAny(),
            [GeneralFormAddressFields.city]: isMissingItem ? validateRequired(t) : validateAny(),
          });
        });
      }}
      validateOnMount={true}
      initialTouched={{
        [GeneralFormAddressFields.address1]: isMissingItem,
        [GeneralFormAddressFields.city]: isMissingItem,
        [GeneralFormAddressFields.postalCode]: isMissingItem,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const submitValues = {
          isSelf: values.isSelf,
          isAddressOverThree: values.isAddressOverThree,

          buildingName: values.buildingName,
          buildingNumber: values.buildingNumber,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          postalCode: values.postalCode,

          prevBuildingName: values.prevBuildingName,
          prevBuildingNumber: values.prevBuildingNumber,
          previousAddress1: values.previousAddress1,
          previousAddress2: values.previousAddress2,
          previousCity: values.previousCity,
          previousPostalCode: values.previousPostalCode,

          propertyType: Object.values(BaseCasePropertyType).find((bcp: any) => values.propertyType === bcp),
        };
        if (isEditable) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const itemId = data.item === null ? isSameLifeCase : data!.item!.id!;
          dispatch(updateLC.started({ setSubmitting, setStatus, setNextStep, id: itemId, ...submitValues }));
        } else {
          dispatch(
            createLC.started({
              setSubmitting,
              setStatus,
              ...submitValues,
              queryString,
            })
          );
        }
        setToLocalStorage(LocalStorage.noLcContent, 'true');
      }}
    >
      {({ isSubmitting, status, values, isValid }) => (
        <Form>
          <div className={containerClasses}>
            <Typography tag="div" size="l" msg={t(Messages.fieldLifeCaseAddPostcode)} />
            <Row className={styles.addressContainer} size={12}>
              <Col size={isTablet ? 12 : 7}>
                <input
                  name={GeneralFormAddressFields.autoAddress}
                  type="text"
                  value={currentPostalCode}
                  className={styles.input}
                  autoComplete="off"
                  onChange={(e: any) => {
                    setCurrentPostalCode(e.currentTarget.value);
                    setShowClearAddress(true);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode && validatePostalCodeCharacters(currentPostalCode)) {
                      e.preventDefault();
                      getAddress(false);
                    }
                  }}
                  onBlur={() => {
                    if (validateMinLength(currentPostalCode) && resultAddressOptions.length === 0) {
                      setPostCodeTabOut(true);
                    } else {
                      setPostCodeTabOut(false);
                    }
                  }}
                />
                {showClearAddress && (
                  <Icon
                    icon="close-outline"
                    className={styles.visibilityToggleIcon}
                    onClick={() => {
                      setShowClearAddress(false);
                      setCurrentPostalCode('');
                      setAddressResult([]);
                      setAssignedAddress(-1);
                      setPostCodeTabOut(false);
                    }}
                    size={IconSizes.s}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        setShowClearAddress(false);
                        setCurrentPostalCode('');
                        setAddressResult([]);
                        setAssignedAddress(-1);
                        setPostCodeTabOut(false);
                      }
                    }}
                  />
                )}
              </Col>
              <Col size={isTablet ? 12 : 5} className={classNames(styles.searchButtonContainer)}>
                <Popover
                  showContent={postCodeTabOut}
                  position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                  title={t('title_postcode_warning' as Messages)}
                  message={t('desc_postcode_warning' as Messages)}
                >
                  <Button
                    type={ButtonTypes.button}
                    style={ButtonStyles.transparent}
                    loading={searching}
                    label={t(Messages.buttonSearch)}
                    icon="search"
                    iconSize={IconSizes.sxs}
                    onClick={() => {
                      setPostCodeTabOut(false);
                      getAddress(false);
                    }}
                    isFullWidth={true}
                    constant
                    disabled={!validatePostalCodeCharacters(currentPostalCode)}
                  />
                </Popover>
              </Col>
            </Row>

            {resultAddressOptions.length > 0 && (
              <Row className={styles.topSpacing} size={12}>
                <Col size={12}>
                  <Field
                    name={GeneralFormAddressFields.chooseAddress}
                    component={SelectInput}
                    label=""
                    placeholder={t('label_selected' as Messages)}
                    options={resultAddressOptions}
                    disabled={!resultAddressOptions.length}
                    onSelectChange={handleAddressChange}
                    selectClass={ClassNamesForOverwrite.SelectAddress}
                    onFocus={() => setPostCodeTabOut(false)}
                    hasErrorProp={assignedAddress === -1}
                  />
                </Col>
              </Row>
            )}

            <GridRow>
              <GridCol className={styles.adresslink} size={9}>
                {/* eslint-disable-next-line */}
                <div 
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      setManualAddress(!manualAddress);
                    }
                  }}
                  onClick={() => setManualAddress(!manualAddress)}
                  //eslint-disable-next-line
                  tabIndex={0}
                >
                  <Typography msg="Or enter address manually" tag="span" />
                </div>
              </GridCol>
            </GridRow>
            {(manualAddress || isMissingItem) && (
              <>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.buildingName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingName)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.buildingNumber}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingNumber)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormAddressFields.address1}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldAddress1)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormAddressFields.address2}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldAddress2)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.city}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldCity)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.postalCode}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldPostalCode)}
                    />
                  </GridCol>
                </GridRow>
              </>
            )}
            <GridRow>
              <GridCol size={5}>
                <Field
                  name={GeneralFormAddressFields.isAddressOverThree}
                  label="Have they lived here for over 3 years?"
                  component={ToggleSwitchInput}
                  required
                />
              </GridCol>
            </GridRow>
            {!values.isAddressOverThree && (
              <>
                <Typography tag="div" size="l" msg={t(Messages.fieldAddPreviousAddress)} />
                <Row className={styles.addressContainer} size={12}>
                  <Col size={isTablet ? 12 : 7}>
                    <input
                      name={GeneralFormAddressFields.previousAutoAddress}
                      type="text"
                      value={prevPostalCode}
                      autoComplete="off"
                      className={styles.input}
                      onChange={(e: any) => {
                        setPrevPostalCode(e.currentTarget.value);
                        setShowPrevClearAddress(true);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter' && validatePostalCodeCharacters(prevPostalCode)) {
                          e.preventDefault();
                          getAddress(true);
                        }
                      }}
                      onBlur={() => {
                        if (validateMinLength(prevPostalCode) && resultPrevAddressOptions.length === 0) {
                          setPrevPostCodeTabOut(true);
                        } else {
                          setPrevPostCodeTabOut(false);
                        }
                      }}
                    />
                    {showPrevClearAddress && (
                      <Icon
                        icon="close-outline"
                        className={styles.visibilityToggleIcon}
                        size={IconSizes.s}
                        onClick={() => {
                          setShowPrevClearAddress(false);
                          setPrevPostalCode('');
                          setPrevAddressResult([]);
                          setPrevAssignedAddress(-2);
                          setPrevPostCodeTabOut(false);
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            setShowPrevClearAddress(false);
                            setPrevPostalCode('');
                            setPrevAddressResult([]);
                            setPrevAssignedAddress(-2);
                            setPrevPostCodeTabOut(false);
                          }
                        }}
                      />
                    )}
                  </Col>
                  <Col size={isTablet ? 12 : 5} className={classNames(styles.searchButtonContainer)}>
                    <Popover
                      showContent={prevPostCodeTabOut}
                      position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                      title={t('title_postcode_warning' as Messages)}
                      message={t('desc_postcode_warning' as Messages)}
                    >
                      <Button
                        type={ButtonTypes.button}
                        style={ButtonStyles.transparent}
                        loading={prevSearching}
                        label={t(Messages.buttonSearch)}
                        icon="search"
                        iconSize={IconSizes.sxs}
                        onClick={() => {
                          setPrevPostCodeTabOut(false);
                          getAddress(true);
                        }}
                        isFullWidth={true}
                        constant
                        disabled={!validatePostalCodeCharacters(prevPostalCode)}
                      />
                    </Popover>
                  </Col>
                </Row>
                {resultPrevAddressOptions.length > 0 && (
                  <Row className={styles.topSpacing} size={12}>
                    <Col size={12}>
                      <Field
                        name={GeneralFormAddressFields.choosePrevAddress}
                        component={SelectInput}
                        label=""
                        placeholder="---- Select your previous address ----"
                        options={resultPrevAddressOptions}
                        disabled={!resultPrevAddressOptions.length}
                        onSelectChange={handlePrevAddressChange}
                        isFullWidth={isMobile || isTablet}
                        constant
                        onFocus={() => setPrevPostCodeTabOut(false)}
                        hasErrorProp={prevAssignedAddress === -1}
                      />
                    </Col>
                  </Row>
                )}

                <GridRow>
                  <GridCol className={styles.adresslink} size={9}>
                    <div
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setPreviousManualAddress(!previousManualAddress);
                        }
                      }}
                      onClick={() => setPreviousManualAddress(!previousManualAddress)}
                      //eslint-disable-next-line
                      tabIndex={0}
                    >
                      <Typography msg="Or enter address manually" tag="span" />
                    </div>
                  </GridCol>
                </GridRow>
              </>
            )}
            {previousManualAddress && (
              <>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.prevBuildingName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingName)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.prevBuildingNumber}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingNumber)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormAddressFields.previousAddress1}
                      type="text"
                      component={TextInput}
                      label="Address line #1"
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormAddressFields.previousAddress2}
                      type="text"
                      component={TextInput}
                      label="Address line #2"
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.previousCity}
                      type="text"
                      component={TextInput}
                      label="City"
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormAddressFields.previousPostalCode}
                      type="text"
                      component={TextInput}
                      label="Postal code"
                    />
                  </GridCol>
                </GridRow>
              </>
            )}
            <GridRow>
              <GridCol size={12}>
                <Field
                  name={GeneralFormAddressFields.propertyType}
                  options={baseCaseProperties}
                  component={RadioButton}
                  label="What is the ownership status of their property?*"
                  required
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol size={5}>
                <Typography
                  msg="This information is often requested by utilities and insurers when processing notifications"
                  tag="div"
                  color="footerColor"
                  size="s"
                />
              </GridCol>
            </GridRow>
            <AutoToggleForm />
            <FormError formError={status} />
          </div>
          <NavigationBlock
            isSubmitting={isSubmitting}
            back={back}
            next={next}
            setSubpage={setSubpage}
            hideSkip={true}
            isDisabled={disableNextButton(values, isValid, assignedAddress)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default GeneralFormAddress;
