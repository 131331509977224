import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import NotificationCard from '@Components/application/NotificationCard';
import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import {
  AppVersion,
  colorArray,
  EnterKeyCode,
  freeServiceProvidersForLC,
  Links,
  ModalTypes,
  ReactResponsiveQueries,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  LifeCaseFragment,
  Permissions,
  SubscriptionPaymentPaymentStatus,
  useCaseServiceProvidersQuery,
  useObTransactionsQuery,
  useUserSubscriptionPaymentsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { compareDates, languageBasedDate } from '@Utils/dates';
import {
  addProviderHandler,
  basicPaymentHandler,
  discoveryDescHandler,
  paymentHandler,
  paymentUpgradeHandler,
} from '@Utils/modal';

import layoutStyles from '../../../deathCase/ReadyDCPage/ReadyDCPage.scss';

import styles from './LCServicesPage.scss';

export interface LCServicesPageProps {
  caseData: LifeCaseFragment;
  Button?: React.ReactNode;
  containerClassName?: string;
  notDiscovered?: boolean;
  discoveryPackage: any;
}

const LCServicesPage: React.FunctionComponent<LCServicesPageProps> = ({
  caseData,
  notDiscovered,
  containerClassName,
  discoveryPackage,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });
  const isRes935 = useMediaQuery({ query: ReactResponsiveQueries.Res935 });
  const isRes986 = useMediaQuery({ query: ReactResponsiveQueries.Res986 });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const currentLanguage = useSelector(getCurrentLanguage);
  const [hideNotifications, setHideNotifications] = React.useState(false);
  const queryString = history.location.search;
  //const [showDiscoveryWorks, setShowDiscoveryWorks] = React.useState(false);
  //const [showMoreInfo, setShowMoreInfo] = React.useState(true);

  const { data, loading } = useCaseServiceProvidersQuery({
    variables: {
      baseCase: caseData.id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const subscriptionsData = useUserSubscriptionPaymentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const bankDetails = useObTransactionsQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    variables: { baseCase: caseData.id, status: ['requested'] },
  });

  const cancelledSubscription = subscriptionsData.data?.userSubscriptionPayments.find(
    (subs: any) =>
      compareDates(subs.subscription.subscriptionRenewal) &&
      subs.paymentStatus === SubscriptionPaymentPaymentStatus.Failed &&
      subs.baseCaseId === caseData.id
  );
  React.useEffect(() => {
    const renewSubscriptionHandler = () => {
      return dispatch(
        showModal({
          type: ModalTypes.renewSubscription,
          params: {
            data: {
              cancelledSubscription,
            },
            title: '',
            modalSize: PaperWidths.m672,
            noPadding: true,
            disableOutsideClick: true,
            isRenewModal: true,
            onClose: () => {
              history.push(generatePath(`${Links.home}?${queryString}`));
            },
          },
        })
      );
    };
    if (cancelledSubscription) {
      renewSubscriptionHandler();
    }
  }, [dispatch, cancelledSubscription, history, queryString]);

  if (loading || bankDetails.loading || subscriptionsData.loading) {
    return <LoadingSpinner />;
  }

  const draftCsps = data?.caseServiceProviders.edges.filter((cspd: any) => cspd.node.isMissingInfo);
  const completedCsps = data?.caseServiceProviders.edges.filter((cspd: any) => !cspd.node.isMissingInfo);

  const EmptyNotificationComponent = (msg: string) => {
    return (
      <Row size={11} constant justifyCenter column className={styles.emptyNotificationContainer}>
        <Col className={styles.messageWidth} textCenter>
          <Typography msg={msg} tag="div" size="m" color="footerColor" />
        </Col>
      </Row>
    );
  };

  const notificationComponent = (items: any, emptyMesg: string, isDraft?: boolean) => {
    if (items.length > 0) {
      let i = -1;
      return items.map((sp: any, idx: number) => {
        i += 1;
        const j = i;
        i = i === 4 ? -1 : i;
        return (
          <div key={idx} className={styles.readyToSendBoxes}>
            <NotificationCard
              initialBackground={colorArray[j]}
              caseData={caseData}
              key={idx}
              service={sp.node}
              isLifeCase
              isMissingInfo={isDraft}
            />
          </div>
        );
      });
    }
    return EmptyNotificationComponent(emptyMesg);
  };

  const remObCredits = caseData.credits && caseData.credits.remainingObCredits;
  const discoveryPackageCredits = discoveryPackage && discoveryPackage.obCredits;

  const findOrAddCompany = remObCredits === discoveryPackageCredits;

  const obButtonText = findOrAddCompany ? t('button_find_companies' as Messages) : 'Continue adding companies';

  const obButtonIcons = () => {
    if (caseData.configuringOb) {
      return 'sync';
    }
    if (notDiscovered) {
      return 'lock';
    }
    if (findOrAddCompany) {
      return 'plus';
    }
    return 'cornerDownRight';
  };

  const obTransferLinks = findOrAddCompany ? Links.institutions : Links.craCompanies;

  const caseSubscription = subscriptionsData.data?.userSubscriptionPayments
    .filter((s) => !s.subscription.isCancelled)
    .map((s) => s.baseCaseId)
    .find((sId) => sId === caseData.id);

  const checkProviderHandler = () => {
    if (!user) {
      return;
    }

    const subscribedTo = !!caseData.credits || caseSubscription;

    if (!subscribedTo) {
      if (data && data.caseServiceProviders.totalCount >= freeServiceProvidersForLC && !caseData.isTerminallyIll) {
        if (user.enableDiscovery) {
          paymentHandler(dispatch, caseData.id);
        } else {
          basicPaymentHandler(t, dispatch, caseData.id, false, true, t('title_to_subscribe_more_sp' as Messages));
        }
      } else {
        addProviderHandler(t, caseData.id, dispatch, true, AppVersion.first);
      }
    } else {
      addProviderHandler(t, caseData.id, dispatch, true, AppVersion.first);
    }
  };

  const isReadyOnlyUser = caseData.myPermission === Permissions.ReadOnly;
  const handlerOfPayments = () => {
    if (caseData.credits) {
      return paymentUpgradeHandler(t, dispatch, caseData);
    }
    return paymentHandler(dispatch, caseData.id, undefined, caseSubscription === undefined && caseData.isTerminallyIll);
  };

  const addCompanyManualSize = () => {
    if (user?.enableDiscovery) {
      return isRes986 ? 5 : 4;
    }
    return 6;
  };

  const discoveryBoxButtonText = () => {
    if (caseData.configuringOb) {
      return t('button_configuring_bank' as Messages);
    }
    return notDiscovered ? t('button_unlock_ll_discovery' as Messages) : obButtonText;
  };

  return (
    <div className={containerClassName}>
      {!isReadyOnlyUser && (
        <Row size={isRes986 ? 11 : 10} className={styles.profAddCompanyBlock}>
          {user?.enableDiscovery && (
            <Col
              size={isRes935 ? 7 : 6}
              className={classNames(styles.normalCompanyAddContainer, {
                [styles.notDiscovered]: notDiscovered,
                [styles.shadowCard]: !notDiscovered,
              })}
            >
              {notDiscovered && <Icon icon="lock" className={styles.afterImage} size={IconSizes.xl} />}
              <Row alignCenter justifyBetween={caseData.configuringOb} justifyEnd={!caseData.configuringOb}>
                {caseData.configuringOb && (
                  <Col>
                    <Row alignCenter className={styles.gap2}>
                      <Col>
                        <Icon className={styles.footColor} size={IconSizes.s12} icon="star" />
                      </Col>

                      <Col className={styles.accountInfo}>
                        <span className={styles.footColor}>Discovery:</span>
                        &nbsp;
                        <span className={styles.greenColor}>Account synced</span>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col>
                  <Row
                    constant
                    alignCenter
                    className={classNames(styles.llDiscoveryStrip, { [styles.stripBg]: notDiscovered })}
                  >
                    <Icon size={IconSizes.s12} icon="star" />

                    <Typography color="white" msg={t('label_ll_discovery' as Messages)} tag="div" size="xss" bold />
                  </Row>
                </Col>
              </Row>
              <div className={classNames({ [styles.opaque]: notDiscovered })}>
                <Row constant justifyCenter>
                  <Icon className={styles.flashIcon} icon="flash" />
                  <Typography
                    className={styles.addCompany}
                    msg={t('title_find_cra_company' as Messages)}
                    tag="div"
                    size="l"
                    bold
                  />
                </Row>

                <Row alignCenter constant className={classNames(styles.mb9, styles.ml5)}>
                  <Icon className={classNames(styles.tickIcon, styles.tickIconGray)} icon="tick" />
                  <div className={styles.pointOneDiscovery}>
                    <Typography
                      className={styles.pointOneDiscovery}
                      msg={t(Messages.labelDiscoveryDefnDesc)}
                      tag="div"
                      size="s"
                    />
                  </div>
                </Row>
              </div>
              <Button
                onClick={() =>
                  notDiscovered ? handlerOfPayments() : history.push(generatePath(obTransferLinks, { id: caseData.id }))
                }
                icon={obButtonIcons()}
                iconSize={IconSizes.ss}
                constant
                label={discoveryBoxButtonText()}
                isFullWidth
                disabled={caseData.configuringOb}
              />
              {!caseData.configuringOb && (
                <div
                  className={styles.handSymbol}
                  onClick={() => {
                    discoveryDescHandler(dispatch, caseData);
                  }}
                  /* eslint-disable-next-line */
                tabIndex={0}
                >
                  <Typography
                    textCenter
                    className={classNames(styles.mt10, styles.lh24)}
                    msg="More info"
                    color="electricBlue"
                    tag="div"
                    size="l"
                    underline
                    bold
                  />
                </div>
              )}
            </Col>
          )}

          <Col
            size={addCompanyManualSize()}
            textCenter
            constant={!isMobile}
            //Height to be removed
            className={classNames(styles.normalCompanyAddContainer, styles.extendspacing, styles.shadowCard, {
              [styles.heightCard]: !user?.enableDiscovery,
            })}
          >
            <Typography msg={t('label_add_company_manually' as Messages)} tag="div" size="l" bolder />
            <Typography
              className={styles.mb14}
              color="footerColor"
              msg={t('info_add_company_manually' as Messages)}
              tag="div"
              size="s"
            />

            <div
              className={classNames(
                user?.enableDiscovery ? styles.btnDisAddCompanyManual : styles.btnAddCompanyManual,
                { [styles.obConfigureImpact]: caseData.configuringOb }
              )}
            >
              <Button
                onClick={() => checkProviderHandler()}
                icon="plus"
                iconSize={IconSizes.ss}
                constant
                label={t('button_add_company' as Messages)}
                isFullWidth
              />
            </div>
          </Col>
        </Row>
      )}

      {draftCsps && draftCsps.length > 0 && (
        <div className={styles.readyToSendContainer}>
          <Col size={isTablet ? 12 : 11}>
            <Typography msg={`Draft (${draftCsps?.length})`} tag="h5" size="l" />
            <Typography
              className={styles.bottomSpacing}
              msg="These companies have missing information"
              tag="div"
              size="m"
              color="footerColor"
            />
            {notificationComponent(draftCsps, t('msg_empty_lc_draft_notifications' as Messages), true)}
          </Col>
        </div>
      )}

      <div className={classNames(styles.spacingTop, layoutStyles.rightSideGap)}>
        <Col size={isTablet ? 12 : 11}>
          <Row constant alignCenter>
            <Typography msg={`${t('label_lc_companies' as Messages)} (${completedCsps?.length})`} tag="h5" size="l" />
            <Icon
              className={styles.handSymbol}
              onClick={() => setHideNotifications(!hideNotifications)}
              size={IconSizes.s}
              icon={hideNotifications ? 'chevronDown' : 'chevronUp'}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  setHideNotifications(!hideNotifications);
                }
              }}
            />
          </Row>
          {!hideNotifications && (
            <Typography
              className={styles.bottomSpacing}
              msg="Here is a total list of all companies linked to this end of life plan"
              tag="div"
              size="m"
              color="footerColor"
            />
          )}

          {!hideNotifications &&
            notificationComponent(completedCsps, t('msg_empty_lc_completed_notifications' as Messages))}
        </Col>
      </div>
      {bankDetails.data && bankDetails.data?.obTransactions.length > 0 && (
        <div className={classNames(styles.spacingTop, layoutStyles.rightSideGap, styles.pb25)}>
          <Col size={isTablet ? 12 : 11}>
            <Typography
              msg={`${t('label_requested' as Messages)} (${bankDetails.data?.obTransactions.length})`}
              tag="h5"
              size="l"
            />

            <Typography
              className={styles.bottomSpacing}
              msg={t('info_requested_companies' as Messages)}
              tag="div"
              size="m"
              color="footerColor"
            />

            {bankDetails.data?.obTransactions.map((bd: any, idx: number) => (
              <Row
                column={isRes986}
                constant
                size={isMobile ? 12 : 11}
                key={idx}
                className={classNames(isRes986 ? styles.requestedCard : '', styles.newSpCompanies)}
              >
                <Row key={idx} size={12} constant className={!isRes986 ? styles.requestedCard : ''}>
                  <Col size={isRes986 ? 3 : 2}>
                    <Typography msg={bd.newServiceProvider} size="m" tag="div" bolder />
                  </Col>

                  <Col alignCenter textCenter size={isRes986 ? 8 : 5}>
                    <div className={styles.statusPanel}>
                      <Icon icon="doneAll" size={IconSizes.ss} />
                      {!isIpadMini && <div className={styles.statusTitle}>Requested to add to Life Ledger</div>}
                    </div>
                  </Col>

                  {!isRes986 && (
                    <Col alignCenter size={5}>
                      <Row constant alignCenter size={12} justifyEnd={!isIpadMini}>
                        <Icon className={styles.completedDate} icon="tick" size={IconSizes.sss} />
                        <Typography
                          msg="Requested"
                          tag="span"
                          size="s"
                          color="footerColor"
                          className={styles.rightSpacing}
                        />

                        <Typography
                          msg={languageBasedDate(bd.createdAt, currentLanguage)}
                          tag="span"
                          size="s"
                          color="footerColor"
                          className={styles.rightSpacing}
                        />
                      </Row>
                    </Col>
                  )}
                </Row>
                {isRes986 && (
                  <Col alignCenter size={5}>
                    <Row constant alignCenter size={12}>
                      <Icon className={styles.completedDate} icon="tick" size={IconSizes.sss} />
                      <Typography
                        msg="Requested"
                        tag="span"
                        size="s"
                        color="footerColor"
                        className={styles.rightSpacing}
                      />

                      <Typography
                        msg={languageBasedDate(bd.createdAt, currentLanguage)}
                        tag="span"
                        size="s"
                        color="footerColor"
                        className={styles.rightSpacing}
                      />
                    </Row>
                  </Col>
                )}
              </Row>
            ))}
          </Col>
        </div>
      )}
    </div>
  );
};

export default LCServicesPage;
