import { Form, Formik } from 'formik';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import FileInput from '@Components/form/inputs/FileInput';
import FormError from '@Components/FormError';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DocumentDocumentType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { deleteConfirmationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './AddressProofForm.scss';

export interface AddressProofProps extends NavigationProps {
  data: LoadableItem<DeathCaseNode>;
}

enum AddressProofFormFields {
  hasCertificate = 'hasCertificate',
}

const AddressProofForm: React.FunctionComponent<AddressProofProps> = ({ data, back, next, setSubpage }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const removeCertificateHandler = deleteConfirmationHandler(t, dispatch, DocumentDocumentType.Certificate, false);

  return (
    <>
      <Typography tag="div" msg={t('msg_address_proof_certificate' as Messages)} size="l" />
      <Formik
        initialValues={{
          [AddressProofFormFields.hasCertificate]: '',
        }}
        validationSchema={() => {
          return Yup.lazy(() => {
            return Yup.object({
              // [CertificateFormFields.deathRegisteredDate]: hasCertificate ? validateDate(t) : validateAny(),
            });
          });
        }}
        onSubmit={() => {}}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className={styles.mt135}>
              <>
                <div className={classNames(styles.mt54, styles.width)}>
                  <FileInput
                    onDrop={() => {}}
                    onRemoveClick={removeCertificateHandler}
                    files={[]}
                    disabled={false}
                    isLifeEvent
                  />
                </div>
              </>
              <FormError formError="label_compulsory_field" />
            </div>
            <div className={styles.width}>
              <NavigationBlock
                isSubmitting={isSubmitting}
                back={back}
                next={next}
                setSubpage={setSubpage}
                nextButtonTooltipMsg={
                  values.hasCertificate && !data.item?.certificates.length ? t(Messages.tooltipMissingDocument) : ''
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddressProofForm;
