import classNames from 'classnames';
import * as React from 'react';

import styles from './Typography.scss';

export type HeaderTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
export type TextTags = 'div' | 'p' | 'span';
export type TextAlignment = 'left' | 'center' | 'right' | 'justify';
export type TextColors =
  | 'black'
  | 'lightBlack'
  | 'pineGreen'
  | 'green'
  | 'gray'
  | 'gray2'
  | 'gray3'
  | 'white'
  | 'red'
  | 'orange'
  | 'darkOrange'
  | 'mobileDescColor'
  | 'newGray'
  | 'yellowishOrange'
  | 'footerColor'
  | 'profRegisterBackground'
  | 'successColor'
  | 'dividerColor'
  | 'electricBlue'
  | 'mixedGray';

export type TextSizes = 'xs' | 'xss' | 's' | 'm' | 'l' | 'xl' | 'mb' | 'lb' | 'lbx' | 'lcx' | 'xxl' | 'xxxl' | 'h1l'; // TODO Conditionally disable this prop if tag type is HeaderTag
export type TextFamily = 'helvetica' | 'palatino' | 'Montserrat';

export interface TypographyProps {
  html?: boolean;
  params?: {
    key: string;
    value: string;
  };
  msg?: string;
  align?: TextAlignment;
  color?: TextColors;
  tag: TextTags | HeaderTags;
  size?: TextSizes;
  bold?: boolean;
  bolder?: boolean;
  className?: string;
  withNormalLineHeight?: boolean;
  family?: TextFamily;
  normalFontWeight?: boolean;
  underline?: boolean;
  textCenter?: boolean;
  onClick?: (e?: any) => void;
  tabIndex?: number;
  onKeyDown?: (e: any) => void;
}

const Typography: React.FunctionComponent<TypographyProps> = ({
  tag: Tag,
  align,
  color,
  size,
  bold,
  bolder,
  msg = '',
  html,
  className,
  withNormalLineHeight,
  normalFontWeight,
  family,
  underline,
  textCenter,
  tabIndex,
  onClick,
  onKeyDown,
}) => {
  const computedClass =
    classNames(className, {
      [styles[`${align}` as TextAlignment]]: align,
      [styles[`${color}` as TextColors]]: color,
      [styles[`${size}` as TextSizes]]: size,
      [styles.bold]: bold,
      [styles.bolder]: bolder,
      [styles.underline]: underline,
      [styles.normalFontWeight]: normalFontWeight,
      [styles.withNormalLineHeight]: withNormalLineHeight,
      [styles[`${family}` as TextFamily]]: family,
      [styles.textCenter]: textCenter,
    }) || undefined;

  if (html) {
    return <Tag dangerouslySetInnerHTML={{ __html: msg }} className={classNames(computedClass, styles.htmlContent)} />;
  }

  return (
    <Tag onKeyDown={onKeyDown} onClick={onClick} className={computedClass} tabIndex={tabIndex}>
      {msg}
    </Tag>
  );
};

export default Typography;
