import * as React from 'react';
import NotificationsRedux from 'react-notification-system-redux';
import { useSelector } from 'react-redux';

import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getNotifications } from '@Store/app/app.selector';

export interface SnackbarProps {}

const translateNotifications = (t: (messageKey: Messages) => string, notifications: any[]) =>
  notifications &&
  notifications.map(({ message, title, translationMessageKey, ...notify }) => ({
    ...notify,
    title: t(message) || title,
    message: !translationMessageKey ? '' : t(translationMessageKey),
    dismissible: true,
  }));

const styles = {
  Containers: {
    DefaultStyle: {
      top: 'auto',
      bottom: 0,
      left: '50%',
      width: 500,
      margin: '0 auto 0 -250px',
    },
  },
  Dismiss: {
    DefaultStyle: {
      display: 'none',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: 500,
      height: 'auto',
      border: 0,
      borderRadius: '6px',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    },
    success: {
      backgroundColor: '#519E8A',
    },
    error: {
      backgroundColor: '#E03616',
    },
  },
  Title: {
    DefaultStyle: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 18,
      textAlign: 'center',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: 1.67,
      textAlign: 'left',
    },
  },
} as any;

const Snackbar: React.FunctionComponent<SnackbarProps> = () => {
  const t = useTranslations();
  const notifications = useSelector(getNotifications);

  return <NotificationsRedux notifications={translateNotifications(t, notifications)} style={styles} />;
};

export default Snackbar;
