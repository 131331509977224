import * as React from 'react';

import styles from './Title.scss';

export interface TitleProps {
  text: string;
}

const Title: React.FunctionComponent<TitleProps> = ({ text }) => {
  return <h3 className={styles.title}>{text}</h3>;
};

export default Title;
