import classNames from 'classnames';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon from '@Components/Icon';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { PasswordGuide } from '@Routes/auth/RegisterPage/IndividualRegisterPage/IndividualRegisterForm/IndividualRegisterForm';
import { resetPassword } from '@Store/auth/auth.actions';
import { validateAny, validateConfirmPassword, validateRequired } from '@Utils/validator';

import styles from './ResetPasswordForm.scss';

export interface ResetPasswordFormProps {
  passwordChanged: boolean;
  setPasswordChanged: Dispatch<SetStateAction<boolean>>;
}

enum ResetPasswordFormFields {
  password = 'password',
  confirmPassword = 'confirm_password',
}

export interface ResetPasswordFormValues {
  password: string;
  confirm_password: string;
}

const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = ({
  passwordChanged,
  setPasswordChanged,
}) => {
  const t = useTranslations();
  const { passwordKey } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;
  const [passwordValue, setPasswordValue] = React.useState('');
  const [guidanceMessages, setGuidanceMessages] = React.useState<PasswordGuide[]>([]);
  const [showGuidanceMessages, setShowGuidanceMessages] = React.useState(false);

  const ResetSuccessPage = () => (
    <Form>
      <Row className={styles.resendContainerSpacing} wrap>
        <Typography color="black" size="m" tag="span" msg={t(Messages.labelResetSuccess)} />
        <Link to={generatePath(`${Links.login}${queryString}`)} className={styles.links}>
          <Typography color="electricBlue" size="m" tag="span" bold msg={t(Messages.linkLogin)} />
        </Link>
      </Row>
    </Form>
  );

  const onPasswordChange = (e: any) => {
    const passwordEntry = e.target.value;
    const alphabetRegex = /[a-zA-Z]/g;
    const numberRegex = /\d/;
    const msgs: PasswordGuide[] = [];
    setPasswordValue(passwordEntry);
    if (passwordEntry.length >= 8) {
      msgs.push({ message: 'Atleast 8 characters.', state: true });
    } else {
      msgs.push({ message: 'Atleast 8 characters.', state: false });
    }
    if (alphabetRegex.test(passwordEntry) && numberRegex.test(passwordEntry)) {
      msgs.push({ message: 'A mixture of letters and numbers.', state: true });
    } else {
      msgs.push({ message: 'A mixture of letters and numbers.', state: false });
    }
    if (/[A-Z]/.test(passwordEntry) && /[a-z]/.test(passwordEntry)) {
      msgs.push({ message: 'A mixture of both uppercase and lowercase letters.', state: true });
    } else {
      msgs.push({ message: 'A mixture of both uppercase and lowercase letters.', state: false });
    }
    setShowGuidanceMessages(true);
    setGuidanceMessages(msgs);
  };

  const onFocus = () => setShowGuidanceMessages(true);
  const onBlur = () => {
    if (guidanceMessages.filter((gm: any) => gm.state).length === 3) {
      setShowGuidanceMessages(false);
    } else {
      setShowGuidanceMessages(true);
    }
  };

  const PasswordInputForm = ({ isSubmitting, status }: FormikProps<ResetPasswordFormValues>) => (
    <Form>
      <Row size={12}>
        <Field
          name={ResetPasswordFormFields.password}
          type="password"
          component={TextInput}
          label={t(Messages.fieldPassword)}
          fullWidth
          onChange={onPasswordChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Row>
      <GridRow className={styles.guidance}>
        <GridCol size={12}>
          {showGuidanceMessages &&
            guidanceMessages.map((m: PasswordGuide, idx: number) => {
              return (
                <div
                  key={idx}
                  className={classNames(m.state ? styles.passwordCorrect : styles.error, styles.withErrorIcon)}
                >
                  <Icon icon={m.state ? 'tick' : 'warningSign'} className={styles.iconPlacing} />
                  <Typography className={styles.message} tag="span" msg={m.message} />
                </div>
              );
            })}
        </GridCol>
      </GridRow>
      <Row size={12}>
        <Field
          name={ResetPasswordFormFields.confirmPassword}
          type="password"
          component={TextInput}
          label={t(Messages.fieldConfirmPassword)}
          fullWidth
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Row>
      <FormError formError={status} />
      <Button
        type={ButtonTypes.submit}
        disabled={isSubmitting}
        loading={isSubmitting}
        label={t(Messages.buttonResetPassword)}
        isFullWidth={true}
      />
    </Form>
  );

  return (
    <Formik
      initialValues={{
        [ResetPasswordFormFields.password]: '',
        [ResetPasswordFormFields.confirmPassword]: '',
      }}
      validationSchema={() => {
        return Yup.lazy((values: any) => {
          return Yup.object({
            [ResetPasswordFormFields.password]: passwordValue !== '' ? validateAny() : validateRequired(t),
            [ResetPasswordFormFields.confirmPassword]: validateConfirmPassword(
              t,
              passwordValue,
              values.confirmPassword
            ),
          });
        });
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const guidanceLength = guidanceMessages.filter((gm: PasswordGuide) => !gm.state);
        dispatch(
          resetPassword.started({
            setSubmitting,
            setStatus,
            setPasswordChanged,
            password: passwordValue,
            passwordKey,
            isGuidanceRight: guidanceLength.length === 0,
          })
        );
      }}
    >
      {passwordChanged ? ResetSuccessPage : PasswordInputForm}
    </Formik>
  );
};

export default ResetPasswordForm;
