import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import styles from '@Components/Modal/PaymentModalBody/PaymentModalBody.scss';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { Links, CaseTags, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  UserReferralSource,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { loadingHandler, registerLifeCaseModal } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

export interface LifeCasesContentProps {
  hasUnusedLifeSubscription?: boolean;
  packagesData: any;
  hideBasic?: boolean;
  caseId?: string;
  showBanksCallback?: () => void;
}
const DiscoveryComponent: React.FunctionComponent<LifeCasesContentProps> = ({
  hasUnusedLifeSubscription,
  packagesData,
  hideBasic,
  caseId,
  showBanksCallback = noop,
}) => {
  const t = useTranslations();
  const stripe = useStripe();
  const user = useSelector(getUser);
  const history = useHistory();
  const dispatch = useDispatch();

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (stripeLoading) {
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  React.useEffect(() => {
    // Function to be executed on click
    const handleClick = () => {
      if (caseId) {
        showBanksCallback();
      } else {
        dispatch(
          showModal({
            type: ModalTypes.obBanksNoSupport,
            params: {
              title: '',
              modalSize: PaperWidths.m672,
              noPadding: true,
              data: {
                selectedInstitute: undefined,
                baseCase: undefined,
              },
            },
          })
        );
      }
    };

    // Get the HTML element by its ID or any other method
    const element = document.getElementById('unsupported_banks');

    // Check if the element exists before adding the event listener
    if (element) {
      // Attach the click event listener
      element.addEventListener('click', handleClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (element) {
        element.removeEventListener('click', handleClick);
      }
    };
  }, [caseId, dispatch, showBanksCallback]); // Empty dependency array means this effect runs once after the initial render

  const discoveryPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'DISCOVERY');
  const basicPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'BASIC_LIFE_CASE');
  const isHospice = user?.referralSource === UserReferralSource.LifeHospice;

  return (
    <div className={styles.flexBox}>
      {user?.enableDiscovery && (
        <div className={classNames(styles.packageCard, styles.recommendedCard)}>
          <div className={styles.recommendedStrip}>
            <Typography color="white" bold size="xs" tag="div" msg={t('label_recommended' as Messages)} />
          </div>
          <Row constant justifyCenter className={styles.iconTile} alignCenter>
            <Icon className={styles.starIcon} size={IconSizes.s24} icon="star" />
            <Typography textCenter size="l" tag="h5" msg={discoveryPackage?.name} />
          </Row>

          {discoveryPackage?.currencySymbol && (
            <Row constant justifyCenter>
              <Typography className={styles.fromLabel} msg="From" tag="div" size="l" />
              &nbsp;
              <Typography
                bold
                msg={`${discoveryPackage?.currencySymbol}${discoveryPackage?.unitAmount}`}
                tag="div"
                size="xxxl"
              />
              <Typography
                className={styles.tenure}
                msg={`/${discoveryPackage.recurringInterval.toLowerCase()}`}
                tag="div"
                size="l"
              />
            </Row>
          )}

          <Typography className={styles.discoveryPackDesc} msg={t('desc_discovery_pack' as Messages)} html tag="span" />
          <Row constant alignCenter className={classNames(styles.f16, styles.listItemGap, styles.mt24)}>
            <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
            <Typography
              className={classNames(styles.f16, styles.footerColor)}
              msg="Add unlimited companies"
              tag="div"
            />
          </Row>

          <Row constant alignCenter className={classNames(styles.listItemGap, styles.mb21)}>
            <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
            <span className={classNames(styles.f16, styles.footerColor, styles.lineHeight)}>
              Link bank account to &nbsp;automatically sync all companies
            </span>
          </Row>

          {discoveryPackage?.unitAmount && discoveryPackage?.priceId && (
            <Button
              onClick={() => {
                stripeCreateDcSubscriptionMutation({
                  variables: { input: { priceId: discoveryPackage?.priceId || '', baseCase: caseId } },
                });
              }}
              label={t('button_subscribe_now' as Messages)}
              isFullWidth
              className={classNames(styles.f18, styles.subscribeButton)}
            />
          )}
        </div>
      )}

      {!hideBasic && (
        <div className={styles.packageCard}>
          <Typography textCenter size="l" tag="h5" msg={basicPackage?.name} />

          {basicPackage?.currencySymbol && (
            <Row justifyCenter constant>
              <Typography className={styles.fromLabel} msg="From" tag="div" size="l" />
              &nbsp;
              <Typography
                bold
                msg={`${basicPackage?.currencySymbol}${basicPackage?.unitAmount}`}
                tag="div"
                size="xxxl"
              />
              <Typography
                msg={`/${basicPackage.recurringInterval.toLowerCase()}`}
                tag="div"
                size="l"
                className={styles.tenure}
              />
            </Row>
          )}

          <Row constant alignCenter className={classNames(styles.listItemGap)}>
            <span className={classNames(styles.f18, styles.footerColor, styles.lineHeight)}>
              The basic package allows you to add&nbsp; companies and company &nbsp;information (such as account&nbsp;
              numbers) manually
            </span>
          </Row>

          <Row constant alignCenter className={classNames(styles.listItemGap, styles.lineHeight, styles.mt24)}>
            <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
            <Typography
              className={classNames(styles.f16, styles.footerColor)}
              msg="Add unlimited companies"
              tag="div"
            />
          </Row>

          <Row constant alignCenter className={classNames(styles.listItemGap, styles.lineHeight, styles.mb21)}>
            <Icon className={styles.unavailable} icon="close" size={IconSizes.tick1} />
            <Typography className={styles.f16} msg="Bank account linking not included" tag="div" color="dividerColor" />
          </Row>

          {basicPackage?.unitAmount && (
            <Button
              onClick={() => {
                if (hasUnusedLifeSubscription) {
                  history.push(
                    generatePath(`${Links.lcCreate}?ill=undefined`, { tag: `tag=${CaseTags.lifeCaseCreated}` })
                  );
                } else if (caseId && isHospice) {
                  stripeCreateDcSubscriptionMutation({
                    variables: { input: { priceId: basicPackage?.priceId || '', baseCase: caseId } },
                  });
                } else {
                  registerLifeCaseModal(dispatch, basicPackage);
                }
              }}
              label={t('button_subscribe_now' as Messages)}
              isFullWidth
              className={classNames(styles.f18, styles.subscribeButton)}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default DiscoveryComponent;
