import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import SelectOnly, { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import { Messages } from '@Config/messages';
import { UpdateAssigneePortalMutation, UpdateAssigneePortalMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationUpdateAssigneePortal } from '@Graphql/staff/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { StaffNotifications } from '../StaffPage';

export interface StaffAssigneeProps {
  data: any;
  staffUsersList: any;
  staffAssigneeCallback?: (notifcationRef: string, assigneeId: string) => void;
  assignedList?: StaffNotifications[];
}

const StaffAssignee: React.FunctionComponent<StaffAssigneeProps> = ({
  data,
  staffUsersList,
  staffAssigneeCallback,
  assignedList,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const currentStaff = assignedList?.find((asl: StaffNotifications) => asl.notificationRef === data.notificationId);
  const getStaffAssigned = () => {
    if (currentStaff) {
      return staffUsersList.find((userDetail: any) => userDetail.value === currentStaff.assigneeId);
    }
    if (data.assignee && staffUsersList) {
      return staffUsersList.find((userDetail: any) => userDetail.value === data.assignee.id);
    }
    return staffUsersList[0];
  };
  const selectedOption = getStaffAssigned();

  const [assignedOption, setAssignedOption] = React.useState(selectedOption);

  React.useEffect(() => {
    setAssignedOption(selectedOption);
  }, [selectedOption]);

  const [assignStaffUser] = useMutation<UpdateAssigneePortalMutation, UpdateAssigneePortalMutationVariables>(
    mutationUpdateAssigneePortal,
    {
      onCompleted: () => {
        dispatch(notificationSuccess(t('msg_staff_user_assigned' as Messages)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: '1px solid #C4C4C4 !important',
      borderRadius: '4px',
      paddingLeft: '8px !important',
      paddingRight: '6px !important',
    }),
    option: (provided: any) => ({
      ...provided,
      whiteSpace: 'pre-wrap',
      padding: '2px 16px 2px 16px !important',
    }),
    menu: (provided: any) => ({
      ...provided,
      height: '500px !important',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '500px !important',
    }),
  };

  const handleUserChange = (item: any) => {
    setAssignedOption(item);
    //eslint-disable-next-line
    staffAssigneeCallback && staffAssigneeCallback(data.notificationId, item.value);
    assignStaffUser({ variables: { input: { assignUserId: item.value, caseId: data.notificationId } } });
  };

  return (
    <SelectOnly
      selectClass={ClassNamesForOverwrite.SelectContainment}
      options={staffUsersList}
      value={assignedOption}
      onChange={handleUserChange}
      //placeholder={Messages.fieldUnassignedPlaceholder}
      customStyles={customStyles}
    />
  );
};

export default StaffAssignee;
