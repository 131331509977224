import { connect } from 'react-redux';

import CertificateForm, {
  CertificateFormProps,
  CertificateFormStateProps,
} from '@Routes/deathCase/DeathCasePage/CertificateForm/CertificateForm';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
});

export default connect<CertificateFormStateProps, {}, CertificateFormProps, RootState>(mapStateToProps)(
  CertificateForm
);
