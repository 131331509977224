import { useSelector } from 'react-redux';

import { getMessageKeyTranslation } from '@Config/appConfig';
import { Messages } from '@Config/messages';
import { getCurrentTranslations } from '@Store/app/app.selector';

export const useTranslations = () => {
  const translations = useSelector(getCurrentTranslations);
  return (messageKey: Messages, params?: any): string => {
    let translatedMessage = getMessageKeyTranslation(messageKey, translations);
    if (params) {
      Object.keys(params).forEach((key) => {
        translatedMessage = translatedMessage.replace(`{{${key}}}`, `${params[key]}`);
      });
    }
    return translatedMessage;
  };
};
