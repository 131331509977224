export function setToLocalStorage(name: string, token: any) {
  localStorage.setItem(name, token);
}

export function deleteFromLocalStorage(name: string) {
  return localStorage.removeItem(name);
}

export function getFromLocalStorage(name: string) {
  return localStorage.getItem(name) || null;
}

export function getArrayFromLocalStorage(name: string) {
  const localItem = localStorage.getItem(name);
  if (localItem) {
    return JSON.parse(localItem);
  }
  return [];
}

export function getBooleanValueCookie(name: string) {
  return getFromLocalStorage(name) === 'true';
}

export function getFromLocalStorageString(name: string) {
  return localStorage.getItem(name) || '';
}
