import { generatePath } from 'react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { Links, CaseTags, LocalStorage } from '@Config/constants';
import {
  createCollaboratorMutation,
  createDCMutation,
  deleteDocumentsMutation,
  removeCollaboratorMutation,
  sendAllMutation,
  sendOneMutation,
  updateCollaboratorMutation,
  updateDCMutation,
  updatePermissionCollaboratorMutation,
  uploadDocumentsMutation,
} from '@Graphql/dc/dc.mutations';
import { fetchDCQuery } from '@Graphql/dc/dc.queries';
import {
  CollaboratorRelationshipFe,
  CreateCollaboratorPayload,
  DeathCaseCreateMutationPayload,
  DeathCaseNode,
  DeathCaseUpdateMutationPayload,
  DeleteCollaboratorPayload,
  DocumentDeleteMutationPayload,
  UpdateCollaboratorPayload,
  UploadDocumentMutationPayload,
} from '@Graphql/graphqlTypes.generated';
import { DeathCaseSubpagesNew } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { hideModal } from '@Store/app/app.actions';
import { EditCollaboratorPayload } from '@Store/collaborator/collaborator.types';
import {
  AddAdminPayload,
  AddExecutorPayload,
  AddLegalPayload,
  CreateDCPayload,
  FetchDCPayload,
  RemoveCollaboratorPayload,
  SendAllPayload,
  SendOnePayload,
  UpdateDCPayload,
} from '@Store/dc/dc.types';
import { locationChange } from '@Store/navigation/navigation.actions';
import { normaliseGqlError } from '@Utils/form';
import { setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import {
  addAdministrator,
  addExecutor,
  addLegal,
  createDC,
  editCollaborator,
  fetchDC,
  removeDocuments,
  removeExecutor,
  sendAll,
  sendOne,
  updateDC,
  uploadDocuments,
} from './dc.actions';

export function* fetchDCSaga(action: Action<FetchDCPayload>) {
  const { id } = action.payload;
  try {
    const result: DeathCaseNode = yield call(fetchDCQuery, id);
    yield put(fetchDC.done({ result, params: action.payload }));
  } catch (error: any) {
    yield put(fetchDC.failed({ error, params: action.payload }));
  }
}

export function* createDCSaga(action: Action<CreateDCPayload>) {
  const { setStatus, setSubmitting, queryString, ...restProps } = action.payload;
  try {
    const result: DeathCaseCreateMutationPayload = yield call(createDCMutation, restProps);
    yield setToLocalStorage(LocalStorage.deathCaseTag, CaseTags.deathCaseCreated);
    yield put(
      locationChange({
        path: generatePath(`${Links.dcEdit}${queryString}`, {
          id: result.deathCase.id,
          subpage: DeathCaseSubpagesNew.GeneralStep2,
          tag: `tag=${CaseTags.deathCaseCreated}`,
        }),
      })
    );
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* uploadDocumentSaga(action: ReturnType<typeof uploadDocuments.started>) {
  const filesWithId = action.payload;
  try {
    const result: UploadDocumentMutationPayload = yield call(uploadDocumentsMutation, filesWithId);
    yield put(uploadDocuments.done({ result, params: action.payload }));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* deleteDocumentSaga(action: ReturnType<typeof removeDocuments.started>) {
  const { id, index, documentType, isLifeCase } = action.payload;
  try {
    const result: DocumentDeleteMutationPayload = yield call(deleteDocumentsMutation, { id }, isLifeCase || false);
    yield put(removeDocuments.done({ result, params: { id, index, documentType, isLifeCase } }));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* updateDCSaga(action: Action<UpdateDCPayload>) {
  const { setStatus, setSubmitting, setNextStep, navigateAway, withModal, ...restProps } = action.payload;
  try {
    const result: DeathCaseUpdateMutationPayload = yield call(updateDCMutation, restProps);
    yield put(fetchDC.done({ result: result.deathCase, params: action.payload }));

    if (setNextStep) {
      yield setNextStep();
    } else {
      //TODO validate if it is correct
      yield setSubmitting && setSubmitting(false);
    }
    if (withModal) {
      yield put(hideModal({}));
    }

    if (navigateAway) {
      yield setSubmitting && setSubmitting(false);
    }
  } catch (error: any) {
    const errorMsg = normaliseGqlError(error.message);
    yield put(notificationError(errorMsg));
    yield setStatus && setStatus(errorMsg);
    yield setSubmitting && setSubmitting(false);
  }
}

export function* addExecutorSaga(action: Action<AddExecutorPayload>) {
  const { setStatus, setSubmitting, resetForm, ...restProps } = action.payload;
  try {
    const result: CreateCollaboratorPayload = yield call(createCollaboratorMutation, {
      ...restProps,
      relationship: CollaboratorRelationshipFe.Executor,
    });
    yield put(addExecutor.done({ result, params: action.payload }));
    yield resetForm();
    yield setSubmitting(false);
  } catch (error: any) {
    const errorMsg = normaliseGqlError(error.message);
    yield put(notificationError(errorMsg));
    yield setStatus(errorMsg);
    yield setSubmitting(false);
  }
}

export function* addLegalSaga(action: Action<AddLegalPayload>) {
  const { setStatus, setFormVisible, setSubmitting, resetForm, ...restProps } = action.payload;
  try {
    const result: CreateCollaboratorPayload = yield call(createCollaboratorMutation, {
      ...restProps,
    });
    yield put(addLegal.done({ result, params: action.payload }));
    yield resetForm();
    yield setSubmitting(false);
    yield setFormVisible && setFormVisible(false);
  } catch (error: any) {
    const errorMsg = normaliseGqlError(error.message);
    yield put(notificationError(errorMsg));
    yield setStatus(errorMsg);
    yield setSubmitting(false);
  }
}

export function* addAdministratorSaga(action: Action<AddAdminPayload>) {
  const { setStatus, setSubmitting, setNextStep, ...restProps } = action.payload;
  try {
    const result: CreateCollaboratorPayload = yield call(createCollaboratorMutation, {
      ...restProps,
      relationship: CollaboratorRelationshipFe.WillAdministrator,
    });
    yield put(addAdministrator.done({ result, params: action.payload }));
    yield setSubmitting(false);
    if (setNextStep) {
      yield setNextStep();
    }
  } catch (error: any) {
    const errorMsg = normaliseGqlError(error.message);
    yield put(notificationError(errorMsg));
    yield setStatus(errorMsg);
    yield setSubmitting(false);
  }
}

export function* removeExecutorSaga(action: Action<RemoveCollaboratorPayload>) {
  const { id } = action.payload;
  try {
    const result: DeleteCollaboratorPayload = yield call(removeCollaboratorMutation, { id });
    yield put(removeExecutor.done({ result, params: action.payload }));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* sendOneSaga(action: Action<SendOnePayload>) {
  const { deathCaseProviderId } = action.payload;
  try {
    yield call(sendOneMutation, { deathCaseProviderId });
    yield put(notificationSuccess('Notification sent'));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* sendAllSaga(action: Action<SendAllPayload>) {
  const { deathCaseId, setIsSending } = action.payload;
  try {
    yield setIsSending(true);
    yield call(sendAllMutation, { deathCaseId });
    yield setIsSending(false);
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
    yield setIsSending(false);
  }
}

export function* editCollaboratorSaga(action: Action<EditCollaboratorPayload>) {
  const { skipRefetchQuery, setSubmitting, ...restProps } = action.payload;
  try {
    const result: UpdateCollaboratorPayload = yield call(
      skipRefetchQuery ? updatePermissionCollaboratorMutation : updateCollaboratorMutation,
      restProps
    );
    yield put(editCollaborator.done({ result, params: action.payload }));
    yield put(hideModal({}));
    yield put(notificationSuccess('Collaborator details saved'));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
    yield setSubmitting && setSubmitting(false);
  }
}

export function* watchDCSaga() {
  yield all([
    yield takeLatest(fetchDC.started, fetchDCSaga),
    yield takeLatest(createDC.started, createDCSaga),
    yield takeLatest(updateDC.started, updateDCSaga),
    yield takeLatest(addExecutor.started, addExecutorSaga),
    yield takeLatest(removeExecutor.started, removeExecutorSaga),
    yield takeLatest(uploadDocuments.started, uploadDocumentSaga),
    yield takeLatest(removeDocuments.started, deleteDocumentSaga),
    yield takeLatest(sendOne.started, sendOneSaga),
    yield takeLatest(sendAll.started, sendAllSaga),
    yield takeLatest(addLegal.started, addLegalSaga),
    yield takeLatest(addAdministrator.started, addAdministratorSaga),
    yield takeLatest(editCollaborator.started, editCollaboratorSaga),
  ]);
}
