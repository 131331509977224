import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import SelectOnly, { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { EnterKeyCode, Links, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryDCList } from '@Graphql/dc/queries';
import {
  CollaboratorPermission,
  CollaboratorRelationshipFe,
  DeathCaseDeleteMutation,
  DeathCaseDeleteMutationVariables,
  LifeCaseListCardFragment,
  LifeCaseStatus,
  LifeCaseTransformMutation,
  LifeCaseTransformMutationVariables,
  MissingInfoNode,
  Permissions,
  ProfessionalPortalUserNode,
  useProfessionalPortalUsersQuery,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteLC, mutationTransform } from '@Graphql/lc/mutations';
import { queryLCList } from '@Graphql/lc/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { LifeCaseSubpages } from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { addCollaborator } from '@Store/collaborator/collaborator.actions';
import { normaliseGqlError } from '@Utils/form';
import { chooseIcon, modifyMissingItems } from '@Utils/helpers';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './LifeCaseInfo.scss';

export interface LifeCaseInfoProps {
  data: LifeCaseListCardFragment;
  initialBackground: string;
}

const LifeCaseInfo: React.FunctionComponent<LifeCaseInfoProps> = ({ data, initialBackground }) => {
  const isDraft = data.status === LifeCaseStatus.Draft;
  const dispatch = useDispatch();
  const t = useTranslations();
  const user = useSelector(getUser);

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const isRes1400Min = useMediaQuery({ query: ReactResponsiveQueries.Res1400Min });
  const isTabletMin = useMediaQuery({ query: ReactResponsiveQueries.TabletMin });
  const isProfessionalUser = user?.accountType === UserAccountType.Professional;
  const history = useHistory();
  const queryString = history.location.search;
  const isReadOnlyUser = data.myPermission === Permissions.ReadOnly;

  const [deleteDCMutation] = useMutation<DeathCaseDeleteMutation, DeathCaseDeleteMutationVariables>(mutationDeleteLC, {
    refetchQueries: [getOperationName(queryLCList) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.notificationLCDeleteSuccess)));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [transformMutation] = useMutation<LifeCaseTransformMutation, LifeCaseTransformMutationVariables>(
    mutationTransform,
    {
      refetchQueries: [getOperationName(queryDCList) || '', getOperationName(queryLCList) || ''],
      onCompleted: (result) => {
        history.push(
          generatePath(`${Links.dcEdit}${queryString}`, {
            id: result.lifeCaseTransform.deathCaseId,
            subpage: DeathCaseSubpages.General,
            missing: true,
          })
        );
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const profUsers = useProfessionalPortalUsersQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isProfessionalUser,
  });

  if (profUsers.loading) {
    return <LoadingSpinner />;
  }

  const dataForSelect = (queryData: any) =>
    queryData?.map((item: ProfessionalPortalUserNode) => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
    });

  const colloboratorDetail = (queryData: any) =>
    queryData?.map((item: ProfessionalPortalUserNode) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        id: item.id,
      };
    });

  const colloborators = colloboratorDetail(profUsers.data?.professionalPortalUsers);

  const usersList = dataForSelect(profUsers.data?.professionalPortalUsers);

  let selectedOption =
    data.portalUser && usersList ? usersList.find((userDetail: any) => userDetail.value === data.portalUser.id) : null;

  const handleUserChange = (item: any) => {
    selectedOption = item;
    const colloborator = colloborators.find((c: any) => c.id === item.value);
    dispatch(
      addCollaborator.started({
        baseCase: data.id,
        email: colloborator.email,
        firstName: colloborator.firstName,
        lastName: colloborator.lastName,
        relationship: CollaboratorRelationshipFe.Professional,
        permission: CollaboratorPermission.FullAccess,
        shouldNotify: true,
        isProfessionalPortal: true,
      })
    );
  };

  const columnSize = () => {
    if (isProfessionalUser) {
      const portalAdminSize = isTablet ? 10 : 8;
      const nonPortalAdminSize = isTablet ? 10 : 9;
      return user?.isPortalAdmin ? portalAdminSize : nonPortalAdminSize;
    }
    return 11;
  };

  const editLinkClickHandler = () =>
    history.push(generatePath(`${Links.lcEdit}${queryString}`, { id: data.id, subpage: LifeCaseSubpages.General }));

  const deleteHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCMutation({ variables: { input: { id: data.id } } }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t('modal_remove_lc_title' as Messages),
          subtitle: '',
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const transformHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: secondTransformHandler,
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
            leaveOpen: true,
          },
          title: t(Messages.modalConvertToDCTitle, { name: [data.firstName, data.lastName].join(' ') }),
          subtitle: t(Messages.modalConvertToDCSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const secondTransformHandler = () => {
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => transformMutation({ variables: { input: { lifeCaseId: data.id } } }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.modalConvertToDCSecondTitle),
          subtitle: t(Messages.modalConvertToDCSecondSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const moreHandler = () => {
    dispatch(
      showModal({
        type: ModalTypes.lifeCaseActionsModal,
        params: {
          data: {
            name: [data.firstName, data.lastName].join(' '),
            caseId: data.id,
          },
          title: '',
          subtitle: '',
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const pickActionItems = () => {
    if (!isDraft) {
      if (!isTablet) {
        return (
          <Row size={12} justifyEnd>
            <Col
              tabindex={0}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!isReadOnlyUser) {
                    history.push(
                      generatePath(`${Links.lcEdit}${queryString}`, {
                        id: data.id,
                        subpage: LifeCaseSubpages.General,
                        missing: true,
                      })
                    );
                  } else {
                    history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));
                  }
                }
              }}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                if (!isReadOnlyUser) {
                  history.push(
                    generatePath(`${Links.lcEdit}${queryString}`, {
                      id: data.id,
                      subpage: LifeCaseSubpages.General,
                      missing: true,
                    })
                  );
                } else {
                  history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));
                }
              }}
              textLeft
              size={2}
            >
              <Typography underline msg={isReadOnlyUser ? 'View' : 'Edit'} tag="div" size="l" />
            </Col>
            {!isReadOnlyUser && (
              <Col size={9}>
                <div
                  //eslint-disable-next-line
                  tabIndex={0}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      transformHandler(e);
                    }
                  }}
                  onClick={(e: any) => transformHandler(e)}
                >
                  <Icon className={styles.swapIcon} icon="swap" />
                  <Typography
                    msg="This person has passed away"
                    tag="span"
                    size="mb"
                    bold
                    className={styles.switchCase}
                  />
                </div>
              </Col>
            )}
          </Row>
        );
      }
      return (
        <Row
          constant
          size={12}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            e.preventDefault();
            moreHandler();
          }}
          justifyEnd={!isMobile}
          justifyBetween={isMobile && isProfessionalUser}
        >
          {isProfessionalUser && isMobile && (
            <Col
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <SelectOnly
                size="l"
                selectClass={ClassNamesForOverwrite.SelectContainment}
                options={usersList}
                value={selectedOption}
                onChange={handleUserChange}
                placeholder={Messages.fieldUnassignedPlaceholder}
              />
            </Col>
          )}
          {!isReadOnlyUser ? (
            <div className={styles.flexDisplay}>
              <Icon icon="more-horizontal" />
              <Typography msg="More" tag="span" size="mb" bold className={styles.moreEllipsis} />
            </div>
          ) : (
            <Typography
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));
                }
              }}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));
              }}
              tabIndex={0}
              underline
              msg={isReadOnlyUser ? 'View' : 'Edit'}
              tag="div"
              size="l"
            />
          )}
          {isMobile && !isProfessionalUser && !isReadOnlyUser && (
            <div className={styles.moreDelete} onClick={(e: any) => deleteHandler(e)} role="presentation">
              <Icon icon="trash" className={styles.handSymbol} />
            </div>
          )}
        </Row>
      );
    }
    if (isRes1400Min) {
      return !isReadOnlyUser ? (
        <Row
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            editLinkClickHandler();
          }}
          tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              editLinkClickHandler();
            }
          }}
          className={styles.gap4}
          justifyEnd
          alignCenter
        >
          <Icon icon="edit" />
          <Typography msg="Complete missing info" tag="div" size="mb" bold />
        </Row>
      ) : (
        <Typography
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              e.preventDefault();
              e.stopPropagation();
              history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));
            }
          }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));
          }}
          tabIndex={0}
          underline
          msg="View"
          tag="div"
          size="l"
        />
      );
    }
    return isMobile ? (
      <Row
        constant={isMobile}
        size={12}
        className={styles.handSymbol}
        justifyEnd={!isMobile}
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          editLinkClickHandler();
        }}
      >
        <Col size={isMobile ? 4 : 12} tabindex={0}>
          <Icon className={styles.swapIcon} icon="edit" />
          <Typography msg="Complete missing info" tag="span" size="mb" bold className={styles.switchCase} />
        </Col>

        {!isReadOnlyUser && (
          <div className={styles.mobileDelete} onClick={(e: any) => deleteHandler(e)}>
            <Icon icon="trash" className={styles.handSymbol} />
          </div>
        )}
      </Row>
    ) : (
      <></>
    );
  };

  const viewLinkClickHandler = () => history.push(generatePath(`${Links.lcReady}${queryString}`, { id: data.id }));

  const missingItems = modifyMissingItems(
    data.missingInfo.filter((x: MissingInfoNode) => x.value === false && x.field !== 'user_verification')
  );

  const accountWord = data.caseServiceProviderCount > 1 ? 'accounts' : 'account';

  const leftSideDesktopSize = () => {
    return isDraft ? 9 : 7;
  };

  const leftSideTabletSize = isDraft ? 11 : 5;
  const rightSideTabletSize = isDraft ? 1 : 7;

  const mainLeftSize = isTablet ? leftSideTabletSize : leftSideDesktopSize();

  const mainContent = (
    <Col size={mainLeftSize} className={styles.nameDetails}>
      <Row column>
        <Col className={styles.splitNameAndInfoPanel}>
          <Typography msg={`${data.firstName} ${data.lastName}`} tag="span" size="m" bold />

          {isDraft && !isMobileBetweenTablet && (
            <span className={styles.missingInfoPanel}>
              <Icon icon="alert-triangle" className={styles.alertTriangle} size={IconSizes.ss} />

              <Typography msg="Information missing" tag="span" size="s" bold color="red" className={styles.sent} />
            </span>
          )}
        </Col>

        {isTabletMin && data.status === LifeCaseStatus.Draft && missingItems.length > 0 && (
          <>
            <Col className={styles.notificationTitle}>
              <Typography msg="To do" tag="div" size="s" color="footerColor" />
            </Col>
            <Col>
              <Row size={12}>
                {missingItems.map((item: any, idx: number) => (
                  <Col key={idx} textCenter className={styles.missingInfoItemsSpacing}>
                    <Icon className={styles.missingItemsIcon} icon={chooseIcon(item)} size={IconSizes.ss} />
                    <Typography
                      className={styles.sent}
                      msg={t(`${Messages.missingItemPrefix}${item.toLowerCase()}` as Messages)}
                      tag="span"
                      bold
                      size="s"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </>
        )}
        <Col>
          {!isDraft && (
            <>
              <Typography
                msg="Service providers"
                className={styles.notificationTitle}
                tag="div"
                size="s"
                color="footerColor"
              />

              <Row size={12}>
                <Col>
                  <Icon className={styles.moreIcon} icon="layers-outline" size={IconSizes.ss} />
                </Col>
                <Col size={isTablet ? 1 : 6}>
                  <Typography
                    msg={
                      isTablet
                        ? `${data.caseServiceProviderCount}`
                        : `${data.caseServiceProviderCount} ${accountWord} added`
                    }
                    tag="div"
                    size="s"
                    color="footerColor"
                    bold
                    className={styles.readyToSend}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );

  return (
    <Row constant={!isMobile} size={12}>
      <Col
        constant={!isMobile}
        size={columnSize()}
        tabindex={0}
        className={isDraft ? styles.missingContainer : styles.activeContainer}
        onClick={viewLinkClickHandler}
        onKeyDown={(e: any) => {
          if (e.keyCode === EnterKeyCode) {
            viewLinkClickHandler();
          }
        }}
      >
        <Row constant={!isMobile} size={12}>
          <div className={styles.combined}>
            <div style={{ backgroundColor: initialBackground }} className={styles.personContainer}>
              <Typography
                msg={`${data.firstName.charAt(0).toUpperCase()}${data.lastName.charAt(0).toUpperCase()}`}
                tag="span"
                color="white"
                bold
                size="lcx"
              />
            </div>
            {isMobile ? mainContent : null}
          </div>
          {!isMobile ? mainContent : null}

          <Col
            size={isTablet ? rightSideTabletSize : 5}
            textRight={!isMobile}
            className={isDraft ? styles.lifeCaseEdit : styles.actionItems}
          >
            {pickActionItems()}
          </Col>
        </Row>
      </Col>
      {!isMobile && isDraft && !isRes1400Min && (
        <Row
          constant={isMobile}
          className={classNames(
            styles.handSymbol,
            styles.completeMissingInfonHover,
            isProfessionalUser ? styles.profViewRight : styles.completeMissingInfoRight
          )}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isReadOnlyUser) {
              editLinkClickHandler();
            } else {
              viewLinkClickHandler();
            }
          }}
          tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              if (!isReadOnlyUser) {
                editLinkClickHandler();
              } else {
                viewLinkClickHandler();
              }
            }
          }}
        >
          <Col>
            {!isReadOnlyUser ? (
              <>
                <Icon className={styles.swapIcon} icon="edit" />
                <Typography msg="Complete missing info" tag="span" size="mb" bold className={styles.switchCase} />
              </>
            ) : (
              <Typography underline msg="View" tag="div" size="l" />
            )}
          </Col>
        </Row>
      )}
      {!isMobile && !isReadOnlyUser && (
        <Col constant={!isMobile} size={1} alignCenter textCenter>
          <div
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                deleteHandler(e);
              }
            }}
            //eslint-disable-next-line
            tabIndex={0}
            onClick={(e: any) => deleteHandler(e)}
          >
            <Icon icon="trash" className={styles.handSymbol} />
          </div>
        </Col>
      )}
      {isProfessionalUser && user?.isPortalAdmin && data.status !== LifeCaseStatus.Draft && !isMobile && (
        <Col id="assignUser" textCenter alignCenter size={isTablet ? 3 : 2} tabindex={0}>
          <SelectOnly
            size="ss"
            selectClass={ClassNamesForOverwrite.SelectContainment}
            options={usersList}
            value={selectedOption}
            onChange={handleUserChange}
            placeholder={Messages.fieldUnassignedPlaceholder}
          />
        </Col>
      )}
    </Row>
  );
};

export default LifeCaseInfo;
