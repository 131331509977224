import React from 'react';

import AddCompaniesForm from './AddCompaniesForm';
import AddressProofForm from './AddressProofForm';
import GeneralAddressForm from './ChangeAddressForm';
import PersonalDetailsForm from './PersonalDetailsForm';

export enum LifeEventSubPages {
  Address = 'address',
  ProofOfAddress = 'proof_of_address',
  AddCompanies = 'add_companies',
}

export enum LifeEventSubPagesMenu {
  Address = 'Address',
  ProofOfAddress = 'Proof Of Address',
  AddCompanies = 'Add Companies',
}

export enum LifeEventSubPagesForSomeone {
  PersonDetails = 'person-detail',
  Address = 'address',
  ProofOfAddress = 'proof_of_address',
  AddCompanies = 'add_companies',
}

export const lifeEventSubPageConfig: {
  [key in LifeEventSubPages]: {
    title: string;
    stepIndex?: number;
    totalSteps?: number;
    step: LifeEventSubPagesMenu;
    component: React.ReactNode;
    back: LifeEventSubPages | null;
    next: LifeEventSubPages | null;
    current: LifeEventSubPages;
  };
} = {
  [LifeEventSubPages.Address]: {
    title: 'Please provide the relevant addresses',
    step: LifeEventSubPagesMenu.Address,
    component: GeneralAddressForm,
    back: null,
    next: LifeEventSubPages.ProofOfAddress,
    current: LifeEventSubPages.Address,
  },
  [LifeEventSubPages.ProofOfAddress]: {
    title: 'Please provide proof of the new address',
    step: LifeEventSubPagesMenu.ProofOfAddress,
    component: AddressProofForm,
    back: LifeEventSubPages.Address,
    next: LifeEventSubPages.AddCompanies,
    current: LifeEventSubPages.ProofOfAddress,
  },
  [LifeEventSubPages.AddCompanies]: {
    title: 'How would you like to add companies to inform?',
    step: LifeEventSubPagesMenu.AddCompanies,
    component: AddCompaniesForm,
    back: LifeEventSubPages.ProofOfAddress,
    next: null,
    current: LifeEventSubPages.AddCompanies,
  },
};

export const LifeEventSubPagesSomeoneConfig: {
  [key in LifeEventSubPagesForSomeone]: {
    title: string;
    stepIndex?: number;
    totalSteps?: number;
    step: LifeEventSubPagesMenu;
    component: React.ReactNode;
    back: LifeEventSubPagesForSomeone | null;
    next: LifeEventSubPagesForSomeone | null;
    current: LifeEventSubPagesForSomeone;
  };
} = {
  [LifeEventSubPagesForSomeone.PersonDetails]: {
    title: 'Whose address do you want to change?',
    step: LifeEventSubPagesMenu.Address,
    totalSteps: 2,
    stepIndex: 0,
    component: PersonalDetailsForm,
    back: null,
    next: LifeEventSubPagesForSomeone.Address,
    current: LifeEventSubPagesForSomeone.PersonDetails,
  },
  [LifeEventSubPagesForSomeone.Address]: {
    title: 'Please provide the relevant addresses',
    step: LifeEventSubPagesMenu.Address,
    component: GeneralAddressForm,
    totalSteps: 2,
    stepIndex: 1,
    back: LifeEventSubPagesForSomeone.PersonDetails,
    next: LifeEventSubPagesForSomeone.ProofOfAddress,
    current: LifeEventSubPagesForSomeone.Address,
  },
  [LifeEventSubPagesForSomeone.ProofOfAddress]: {
    title: 'Please provide proof of the new address',
    step: LifeEventSubPagesMenu.ProofOfAddress,
    component: AddressProofForm,
    back: LifeEventSubPagesForSomeone.Address,
    next: LifeEventSubPagesForSomeone.AddCompanies,
    current: LifeEventSubPagesForSomeone.ProofOfAddress,
  },
  [LifeEventSubPagesForSomeone.AddCompanies]: {
    title: 'How would you like to add companies to inform?',
    step: LifeEventSubPagesMenu.AddCompanies,
    component: AddCompaniesForm,
    back: LifeEventSubPagesForSomeone.ProofOfAddress,
    next: null,
    current: LifeEventSubPagesForSomeone.AddCompanies,
  },
};
