import * as Yup from 'yup';
import { Schema } from 'yup';

import { Messages } from '@Config/messages';
import { Translator } from '@Utils/types';

// /^((\+44(\s\(0\))?))7\d{3}(\s)?\d{6}$/
export const REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PHONE: /^\+?[0-9 ]+[0-9 ]{6,}$/,
  FLOAT_NUMBER: /^[0-9]*[.]?[0-9]*$/,
  HUMAN_NAME: /^[a-zA-Z\s.\-']*(?<!\s)$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[0-9]).{8,}$/,
  LITHUANIAN_VAT_CODE: /^LT\d{11}$/,
  ONLY_WHOLE_NUMBERS: /^[0-9]*$/,
  NUMBERS: /^([0-9]*[.])?[0-9]*$/,
  USERNAME: /^[\w.@+-]{4,}$/,
  SORTCODE: /^\d\d-\d\d-\d\d$|^\d{6}$/,
  ACCOUNT_NUMBER: /^\d{8}$/,
  DATEFORMAT: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
};

export const validateAny = (): Schema<string> => Yup.string();

export const validateRequired = (t: Translator, msg?: Messages): Schema<string> =>
  Yup.string()
    .trim()
    .required(msg ? t(msg) : t(Messages.errorFieldRequired));

export const validateRequiredBoolean = (t: Translator): Schema<boolean> =>
  Yup.boolean().oneOf([true], t(Messages.errorFieldRequired));

export const validateRequiredBoolean2 = (t: Translator): Schema<boolean> =>
  Yup.boolean().required(t(Messages.errorFieldRequired)).oneOf([true], t(Messages.errorFieldRequired));

export const validateBankAccount = (t: Translator): Schema<string> =>
  Yup.string()
    .matches(REGEX.ACCOUNT_NUMBER, t(Messages.errorInvalidAccountNumber))
    .required(t(Messages.errorFieldRequired));

export const validateSortCode = (t: Translator): Schema<string> =>
  Yup.string().matches(REGEX.SORTCODE, t(Messages.errorInvalidSortCode)).required(t(Messages.errorFieldRequired));

export const validateSelector = (t: Translator): Schema<any> =>
  Yup.object().required(t(Messages.errorSelector)).nullable();

export const validatePhone = (t: Translator, required = true): Schema<string> => {
  const val = Yup.string().matches(REGEX.PHONE, t(Messages.errorInvalidPhone));
  return !required ? val : val.required(t(Messages.errorFieldRequired));
};

export const validateName = (t: Translator): Schema<string> =>
  Yup.string().matches(REGEX.HUMAN_NAME, t(Messages.errorNotAllowedChars)).required(t(Messages.errorFieldRequired));

export const validateEmail = (t: Translator): Schema<string> =>
  Yup.string().trim().email(t(Messages.errorInvalidEmail)).required(t(Messages.errorFieldRequired));

// Password must contain at least one number, special symbol, uppercase letter and be 8 character length.
export const validatePassword = (t: Translator): Schema<string> =>
  Yup.string()
    .min(8, t(Messages.errorMinLength, { minLen: 8 }))
    .matches(REGEX.PASSWORD, t(Messages.errorPasswordFormat))
    .required(t(Messages.errorFieldRequired));

export const validateConfirmPassword = (t: Translator, password: string, confirmPassword: string): Schema<string> =>
  Yup.string()
    .oneOf([password, confirmPassword], t(Messages.errorPasswordsNotMatch))
    .required(t(Messages.errorFieldRequired));

export const validateConfirmPasswordOld = (t: Translator): Schema<string> =>
  Yup.string()
    .oneOf([Yup.ref('password')], t(Messages.errorPasswordsNotMatch))
    .required(t(Messages.errorFieldRequired));

export const validateNumber = (t: Translator): Schema<string> =>
  Yup.string().matches(REGEX.NUMBERS, t(Messages.errorOnlyNumbersAllowed)).required(t(Messages.errorFieldRequired));

export const validateMaxLen = (t: Translator, maxLen: number, required = true): Schema<string | null> => {
  const val = Yup.string().max(maxLen, t(Messages.errorOverMaxLength, { maxLen }));
  return !required ? val : val.required(t(Messages.errorFieldRequired));
};

export const validateDate = (t: Translator, required = true): Schema<string | null> => {
  const val = Yup.string().nullable().matches(REGEX.DATEFORMAT, 'Invalid date');
  return !required ? val : val.required(t(Messages.errorFieldRequired));
};

export const validateMaxDate = (dateField: string, message: string): Yup.DateSchema<Date> => {
  return Yup.date().when(
    dateField,
    (eventStartDate: any, schema: any) => eventStartDate && schema.min(eventStartDate, message)
  );
};

export const validateMinDate = (
  t: Translator,
  dateField: string,
  message: string,
  isRequired?: boolean
): Yup.DateSchema<Date> => {
  if (isRequired) {
    return Yup.date()
      .max(new Date(), t('msg_greater_than_today' as Messages))
      .when(dateField, (eventStartDate: any, schema: any) => eventStartDate && schema.min(eventStartDate, message))
      .required(t(Messages.errorFieldRequired));
  }
  return Yup.date()
    .max(new Date(), t('msg_greater_than_today' as Messages))
    .when(dateField, (eventStartDate: any, schema: any) => eventStartDate && schema.min(eventStartDate, message));
};

export const validateFuneralDate = (dateField: string, message: string): Yup.DateSchema<Date> => {
  return Yup.date().when(
    dateField,
    (eventStartDate: any, schema: any) => eventStartDate && schema.min(eventStartDate, message)
  );
};

export const validateNotCurrentDay = (t: Translator): Yup.DateSchema<Date | null> => {
  return Yup.date()
    .nullable()
    .max(new Date(), t('msg_greater_than_today' as Messages));
};

export const validatePostalCodeCharacters = (postCode: string) => {
  return postCode.length > 4;
};

export const validateMinLength = (postCode: string) => {
  return postCode.length > 0;
};
