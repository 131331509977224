import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import Tour from 'reactour';

import MobileMenu from '@Components/application/MobileMenu';
import SideMenu from '@Components/application/SideMenu';
import { MenuItems } from '@Components/application/SideMenu/SideMenu';
import TourContent from '@Components/application/TourContent';
import Button from '@Components/Button';
import Col from '@Components/layout/Col';
import LinkButton from '@Components/LinkButton';
import LoadingSpinner from '@Components/LoadingSpinner';
import AgentCompanyUpdateModal from '@Components/Modal/AgentCompanyUpdateModal';
import { PaperWidths } from '@Components/Paper';
import {
  AllLCStatuses,
  LocalStorage,
  ModalTypes,
  OnboardingCaseSteps,
  PreArchivedDCStatuses,
  ReactResponsiveQueries,
} from '@Config/constants';
import {
  DeathCaseStatus,
  useDeathCasesQuery,
  useLifeCasesQuery,
  UserAccountType,
  useCollaboratorDeathsByUserQuery,
  useUserSubscriptionPaymentsQuery,
  SubscriptionPaymentPaymentStatus,
  UserLandingPage,
  SubscriptionV2SubscriptionStatus,
  useAgentCompanyDetailsQuery,
} from '@Graphql/graphqlTypes.generated';
import DashboardIndividual from '@Routes/dashboard/DashboardPage/DashboardIndividual';
import { hideModal, showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { compareDates } from '@Utils/dates';
import {
  deleteFromLocalStorage,
  getBooleanValueCookie,
  getFromLocalStorage,
  setToLocalStorage,
} from '@Utils/localStorage';
import { newWelcomeModal, renewSubscriptionHandler, termsAndConidtionsModalHandler } from '@Utils/modal';

import styles from './DashboardPage.scss';
import DashboardProfessional from './DashboardProfessional';
import CaseOnboard from './OnboardingPage/CaseOnboard';
import UserInfo from './OnboardingPage/UserInfo';
import UserTitleForm from './OnboardingPage/UserTitleForm';
import ReferredDeathCaseInfo from './ReferredDeathCaseInfo';

export interface DashboardPageProps {}

const DashboardPage: React.FunctionComponent<DashboardPageProps> = () => {
  const user = useSelector(getUser);
  deleteFromLocalStorage(LocalStorage.deathCaseId);
  deleteFromLocalStorage(LocalStorage.lifeCaseId);

  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isIndividual = user?.accountType === UserAccountType.Individual;

  const [mobileShowSideMenu, setMobileShowSideMenu] = React.useState(false);
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const [isActiveTourOpen, setIsActiveTourOpen] = React.useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const needLlData = searchParams.get('user_info') || undefined;
  const lifeCaseId = searchParams.get('id') || '';
  const isAgentProfessional =
    user?.accountType === UserAccountType.Professional || user?.accountType === UserAccountType.Agent;

  const caseForUser = useCollaboratorDeathsByUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  const dcData = useDeathCasesQuery({
    variables: {
      status: PreArchivedDCStatuses,
    },
    fetchPolicy: 'cache-and-network',
    skip: user?.isFirstLogin,
  });

  const lcData = useLifeCasesQuery({
    variables: {
      status: AllLCStatuses,
    },
    fetchPolicy: 'cache-and-network',
    skip: user?.isFirstLogin && user.landingPage === UserLandingPage.RetargetDeathCase,
  });

  const subscriptionsData = useUserSubscriptionPaymentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const cancelledSubscription = subscriptionsData.data?.userSubscriptionPayments.find(
    (subs: any) =>
      compareDates(subs.subscription.subscriptionRenewal) &&
      subs.paymentStatus === SubscriptionPaymentPaymentStatus.Failed
  );

  const unusedLifeSubscription = subscriptionsData.data?.userSubscriptionPayments.filter(
    (us: any) =>
      us.baseCaseId === null &&
      us.subscription.isCancelled === false &&
      us.subscription.subscriptionStatus === SubscriptionV2SubscriptionStatus.Active
  );

  const agentCompanyDetails = useAgentCompanyDetailsQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    variables: { id: user ? user?.agentCompany : '' },
    skip: user?.accountType !== UserAccountType.Agent,
  });

  const isAgent = user?.accountType === UserAccountType.Agent;

  const companyNumberNeeded = !agentCompanyDetails.data?.agentCompanyDetails?.companyNumber;

  const dcCount = dcData.data ? dcData.data.deathCases.totalCount : 0;

  const lcCount = lcData.data ? lcData.data.lifeCases.totalCount : 0;

  const casesCount = dcCount + lcCount;

  const hasReferredDeathcase =
    (caseForUser.data && caseForUser.data.collaboratorDeathsByUser.length > 0) || user?.hasRefDeathCase;

  const agentId = agentCompanyDetails.data?.agentCompanyDetails
    ? agentCompanyDetails.data?.agentCompanyDetails.id
    : undefined;

  React.useEffect(() => {
    const welcomeGuideHandler = () => {
      return dispatch(
        showModal({
          type: ModalTypes.welcomeGuideModal,
          params: {
            data: {
              onClose: () => setIsTourOpen(true),
              isUnvitedProfessional: dcCount === 0,
            },
            title: '',
            modalSize: PaperWidths.m672,
          },
        })
      );
    };

    if (user?.showTerms) {
      termsAndConidtionsModalHandler(dispatch, user.showTerms);
    }
    if (!user?.showTerms) {
      dispatch(hideModal({}));
    }

    const showWelcomeOnboard = getBooleanValueCookie(`${LocalStorage.welcomeTourProfessional}_${user?.id}`);
    const showActiveTour = getFromLocalStorage(`${LocalStorage.professionalActiveCase}_${user?.id}`);

    if (showWelcomeOnboard && isAgentProfessional && !user.isFirstLogin && user?.title && !hasReferredDeathcase) {
      if (!isTourOpen) welcomeGuideHandler();
    }
    if (isAgentProfessional && showActiveTour === DeathCaseStatus.Approved) {
      setIsActiveTourOpen(true);
    }

    const isRenewModalShown = getBooleanValueCookie(`${LocalStorage.renewModal}_${user?.id}`);
    if (cancelledSubscription && !isRenewModalShown) {
      renewSubscriptionHandler(dispatch, cancelledSubscription);
      setToLocalStorage(`${LocalStorage.renewModal}_${user?.id}`, 'true');
    }

    if (needLlData) {
      newWelcomeModal(dispatch);
    }
  }, [
    user,
    isTourOpen,
    setIsActiveTourOpen,
    hasReferredDeathcase,
    dcCount,
    dispatch,
    cancelledSubscription,
    lifeCaseId,
    needLlData,
    isAgentProfessional,
  ]);

  if (dcData.loading || lcData.loading || caseForUser.loading) {
    return (
      <Col size={8} textCenter alignCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const dektopInvitedSteps = [
    {
      selector: '#sideMainMenu',
      content: (
        <TourContent content="This is your main navigation, where you can navigate between your overviews and death notifications" />
      ),
    },
    {
      selector: '#btnAddDeathCase',
      content: <TourContent content="Click this button to add a new death case, try it now" />,
    },
    {
      selector: '#deathCasesPreview',
      content: (
        <TourContent content="This is where you can get an overview of the case that you have been invited to collaborate on" />
      ),
    },
    {
      selector: '#caseCard_0',
      content: <TourContent content="Take a look at your open case now by clicking on it" />,
    },
  ];

  const mobileInvitedSteps = [
    {
      selector: '#btnAddDeathCase',
      content: <TourContent content="Click this button to add a new death case, try it now" />,
    },
    {
      selector: '#deathCasesPreview',
      content: (
        <TourContent content="This is where you can get an overview of the case that you have been invited to collaborate on" />
      ),
    },
    {
      selector: '#caseCard_0',
      content: <TourContent content="Take a look at your open case now by clicking on it" />,
    },
  ];

  const invitedSteps = isMobile ? mobileInvitedSteps : dektopInvitedSteps;

  const desktopUnInvitedSteps = [
    {
      selector: '#sideMainMenu',
      content: (
        <TourContent content="This is your main navigation, where you can navigate between your overviews and death notifications" />
      ),
    },
    {
      selector: '#noDeathNotification',
      content: <TourContent content="This is where you can get an overview of the cases that you are managing" />,
    },
    {
      selector: '#btnAddDeathCase',
      content: <TourContent content="Click this button to add a new death case, try it now" />,
    },
  ];

  const mobileUnInvitedSteps = [
    {
      selector: '#noDeathNotification',
      content: <TourContent content="This is where you can get an overview of the cases that you are managing" />,
    },
    {
      selector: '#btnAddDeathCase',
      content: <TourContent content="Click this button to add a new death case, try it now" />,
    },
  ];

  const unInvitedActiveSettingsSteps = [
    {
      selector: '#assignUser',
      content: (
        <TourContent content="If you wish, you can assign the management of this case to yourself or a team member" />
      ),
    },
    {
      selector: '#settingsLink',
      content: (
        <TourContent content="You can invite new team members and manage your team through the settings menu under ‘User permissions’" />
      ),
    },
  ];

  const unInvitedSteps = isMobile ? mobileUnInvitedSteps : desktopUnInvitedSteps;

  const steps = dcCount > 0 ? invitedSteps : unInvitedSteps;

  const onHamburgerClick = () => {
    setMobileShowSideMenu(!mobileShowSideMenu);
  };
  const caseOnBoardScreen = casesCount === 0 && isIndividual && !user.isFirstLogin;

  const closeTour = () => {
    setToLocalStorage(`${LocalStorage.welcomeTourProfessional}_${user?.id}`, false);
    setIsTourOpen(false);
    if (isAgentProfessional && dcCount > 0) {
      setToLocalStorage(`${LocalStorage.professionalActiveCase}_${user?.id}`, OnboardingCaseSteps.isFirstCase);
    }
  };

  const closeActiveTour = () => {
    setToLocalStorage(`${LocalStorage.professionalActiveCase}_${user?.id}`, OnboardingCaseSteps.lastStepCompleted);
    setIsActiveTourOpen(false);
  };

  const isRalLandingPage =
    user?.landingPage === UserLandingPage.LifeCase || user?.landingPage === UserLandingPage.RetargetLifeCase;

  const isIndividualDashboard =
    (!user?.isFirstLogin && !caseOnBoardScreen && isIndividual && user?.title && !hasReferredDeathcase) ||
    isRalLandingPage;

  const nonDashboard = isTablet ? 11 : 10;

  const individualCaseboxSize = isIndividualDashboard ? 12 : nonDashboard;

  const isCompanyNumberNeeded = isAgent ? companyNumberNeeded : false;

  const renderUserTitleForm = () => {
    if (user?.isFirstLogin && !hasReferredDeathcase && !user.accountType) {
      return true;
    }
    return !user?.title && !hasReferredDeathcase && !user?.isFirstLogin;
  };

  const companyNumberForm = () => {
    if (isAgent && companyNumberNeeded && !user.isFirstLogin && user.isPortalAdmin) {
      return true;
    }
    return false;
  };

  const dashboardProfessionalCheck =
    isAgentProfessional && !companyNumberForm() && !user.isFirstLogin && user?.title && !hasReferredDeathcase;

  return (
    <main className={isIndividualDashboard ? styles.individualLayoutContainer : styles.layoutContainer}>
      <Tour
        steps={steps}
        showNumber={false}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        showNavigationNumber={false}
        lastStepNextButton={
          dcCount > 0 ? (
            <Button onClick={() => closeTour()} label="Finish" isFullWidth />
          ) : (
            <LinkButton linkTitle="Not right now" onClick={() => closeTour()} />
          )
        }
      />

      <Tour
        steps={unInvitedActiveSettingsSteps}
        showNumber={false}
        isOpen={isActiveTourOpen}
        onRequestClose={closeActiveTour}
        showNavigationNumber={false}
        lastStepNextButton={<Button onClick={() => closeTour()} label="Finish" isFullWidth />}
      />
      <div>
        {isMobile && !mobileShowSideMenu ? (
          <MobileMenu
            isIndividualDashboard={isIndividualDashboard}
            grayBackground={isIndividualDashboard}
            onHamburgerClick={onHamburgerClick}
            showHamBurger={!user?.isFirstLogin}
          />
        ) : (
          <SideMenu
            hasCollaboratorCase={hasReferredDeathcase}
            closeClick={() => setMobileShowSideMenu(!mobileShowSideMenu)}
            page={MenuItems.Dashboard}
            showSideMenuItems={isRalLandingPage}
            companyNumberNeeded={isCompanyNumberNeeded}
          />
        )}
      </div>
      {!mobileShowSideMenu && (
        <div className={styles.rightSideContainer}>
          <Col constant={isMobileBetweenTablet} size={isIndividual ? individualCaseboxSize : 12}>
            {renderUserTitleForm() && <UserTitleForm />}
            {user?.isFirstLogin && !isRalLandingPage && user.accountType && <UserInfo />}
            {companyNumberForm() && <AgentCompanyUpdateModal id={agentId} />}
            {caseOnBoardScreen && !hasReferredDeathcase && !isRalLandingPage && <CaseOnboard />}
            {isIndividualDashboard && (
              <DashboardIndividual
                dcCount={dcCount}
                lcCount={lcCount}
                hasUnusedLifeSubscription={unusedLifeSubscription && unusedLifeSubscription?.length > 0}
              />
            )}
            {dashboardProfessionalCheck && (
              <DashboardProfessional
                isUnvitedProfessional={isAgentProfessional && dcCount === 0}
                dcCount={dcCount}
                lcCount={lcCount}
              />
            )}
            {hasReferredDeathcase && !user?.isFirstLogin && (
              <ReferredDeathCaseInfo
                caseId={
                  caseForUser.data && caseForUser.data.collaboratorDeathsByUser.length > 0
                    ? caseForUser.data?.collaboratorDeathsByUser[0].baseCaseId
                    : undefined
                }
                collaboratorId={
                  caseForUser.data && caseForUser.data.collaboratorDeathsByUser.length > 0
                    ? caseForUser.data?.collaboratorDeathsByUser[0].id
                    : undefined
                }
              />
            )}
          </Col>
        </div>
      )}
    </main>
  );
};

export default DashboardPage;
