import * as React from 'react';
import { generatePath, useHistory, useParams } from 'react-router';

import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import AuthPageLayout from '@Components/layout/AuthPageLayout';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './InvitePage.scss';

export interface InvitePageProps {}

const InvitePage: React.FunctionComponent<InvitePageProps> = () => {
  const { collaboratorKey } = useParams();
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;

  return (
    <AuthPageLayout titleKey={Messages.invitePageTitle}>
      {!collaboratorKey && <Typography size="m" tag="span" msg={t(Messages.labelBrokenInviteLink)} />}
      {collaboratorKey && (
        <>
          <Typography size="m" tag="span" msg={t(Messages.labelInviteExplain)} className={styles.paragraphSpacing} />
          <Row alignCenter className={styles.paragraphSpacing}>
            <Button
              type={ButtonTypes.button}
              label={t(Messages.buttonLogin)}
              onClick={() => history.push(generatePath(`${Links.login}${queryString}`, { collaboratorKey }))}
              isFullWidth
            />
          </Row>
          <Row justifyCenter className={styles.topSpacing}>
            <Typography size="m" tag="span" msg={t(Messages.labelOr)} />
          </Row>
          <Row className={styles.topSpacing}>
            <Button
              type={ButtonTypes.button}
              label={t(Messages.buttonSignUp)}
              onClick={() => history.push(`${Links.register}?collaborator_key=${collaboratorKey}${queryString}`)}
              style={ButtonStyles.white}
              isFullWidth
            />
          </Row>
        </>
      )}
    </AuthPageLayout>
  );
};

export default InvitePage;
