import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import Footer from '@Components/Footer';
import Header from '@Components/Header';
import Col from '@Components/layout/Col';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import PaymentCard from '@Components/PaymentCard';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import { Links, PaymentStatus } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeUpdateCheckoutSessionMutation,
  StripeUpdateCheckoutSessionMutationVariables,
  useProductsLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeUpdateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import styles from '@Routes/lifeCase/ReadyLCPage/ReadyLCPage.scss';
import { getUser } from '@Store/auth/auth.selector';

export interface StripePaymentsPageProps {}

const StripePaymentsPage: React.FunctionComponent<StripePaymentsPageProps> = () => {
  const t = useTranslations();

  const backLink = { title: t(Messages.backToDashboardLink), link: Links.home };

  const user = useSelector(getUser);

  const [getProductsQuery, productValues] = useProductsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const currentProductId =
    user?.userSubscriptionDetails && user.userSubscriptionDetails.length > 0
      ? user.userSubscriptionDetails[0].currentProductId
      : '';

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id') || '';

  const [updateCheckoutSession] = useMutation<
    StripeUpdateCheckoutSessionMutation,
    StripeUpdateCheckoutSessionMutationVariables
  >(mutationStripeUpdateCheckoutSession, {});

  React.useEffect(() => {
    if (sessionId !== '') {
      updateCheckoutSession({ variables: { input: { sessionId, status: PaymentStatus.FAILED } } });
    }

    const caseType =
      user?.userSubscriptionDetails && user.userSubscriptionDetails.length > 0
        ? user.userSubscriptionDetails[0].subscriptionFor
        : '';

    if (caseType !== '') {
      getProductsQuery({
        variables: {
          productType: caseType,
        },
      });
    }
  }, [sessionId, updateCheckoutSession, user, getProductsQuery]);

  // if (loading) {
  //   return <LoadingSpinner />;
  // }

  // if (error) {
  //   return <ErrorDisplay msg={error.message} />;
  // }

  return (
    <>
      <Header backLink={backLink} hideUpgrade={true} isSettingsActive titleOnMobile={t(Messages.titleNewPayments)} />
      <Container style={{ marginTop: '30px' }}>
        <div style={{ marginTop: '30px' }}>
          <TitleWithSubtitle
            title={t(Messages.titleNewPayments)}
            subtitle={t(Messages.pageSubtitleNewPayment)}
            userSection
          />
        </div>
        {productValues.loading && <LoadingSpinner />}
        <Row>
          {productValues.data?.products
            .sort((a, b) => {
              if (a.maxLimit > b.maxLimit) return 1;
              if (a.maxLimit < b.maxLimit) return -1;
              return 0;
            })
            .map((prd: any, idx: number) => (
              <Col className={styles.tabSpacing} key={idx} size={4}>
                <PaymentCard data={prd} currentProductId={currentProductId} />
              </Col>
            ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default StripePaymentsPage;
