import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Benefits from '@Components/application/Benefits';
import Header from '@Components/application/Header/Header';
import NewsLetters from '@Components/application/NewsLetters';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import Footer from '@Components/Footer';
import Icon from '@Components/Icon';
import Title from '@Components/Title';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import { MobileMenuProperties, authMobileMenu } from '../../../model/AuthMobileMenu';
import Container from '../Container';

import styles from './AuthPageLayout.scss';

export interface AuthPageLayoutProps {
  titleKey: Messages;
  className?: string;
  leftBottomComponent?: React.ReactNode;
  isRegisterForm?: boolean;
  isLogin?: boolean;
  emailSentTo?: string;
  accountType?: UserAccountType;
  isSmallLayout?: boolean;
  mobileRegisterFormHeight?: string;
  showFooter?: boolean;
  loginRegisterVisibility?: boolean;
}

const AuthPageLayout: React.FunctionComponent<AuthPageLayoutProps> = ({
  className,
  children,
  leftBottomComponent,
  isRegisterForm = false,
  titleKey,
  isLogin = false,
  emailSentTo,
  accountType,
  isSmallLayout,
  mobileRegisterFormHeight,
  loginRegisterVisibility = false,
}) => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;
  const [showSideBar, setShowSideBar] = React.useState(false);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const chevronRightIcon = <FontAwesomeIcon icon={faChevronRight} size="1x" color="#E97258" />;

  const registrationHeight = isTablet ? mobileRegisterFormHeight : '100%';

  const sideBarVisibility = (isSideBar: boolean) => {
    setShowSideBar(isSideBar);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      {!showSideBar ? (
        <div id="authentication">
          <Header
            loginRegisterVisibility={loginRegisterVisibility}
            setMobileNavigation={sideBarVisibility}
            showSideBar={showSideBar}
            showNavigation={!isRegisterForm}
          />
          <div style={{ height: isSmallLayout ? '63rem' : registrationHeight }} className={styles.bodyFooterContainer}>
            {!isMobile && isLogin && <div className={styles.yellowEllipsis} />}
            {!isRegisterForm && (
              <div className={styles.loginFormContainer}>
                <div
                  className={classNames(
                    emailSentTo !== undefined && emailSentTo !== '' ? styles.emailSentLayout : styles.layout,
                    className
                  )}
                >
                  <Title text={t(titleKey)} />
                  <div>{children}</div>
                  {!!leftBottomComponent && <div className={styles.signUpContainer}>{leftBottomComponent}</div>}
                </div>
              </div>
            )}

            {isRegisterForm && (
              <Container>
                {accountType ? (
                  <div className={styles.registrationContiner}>
                    {emailSentTo !== undefined && emailSentTo !== '' ? null : <Benefits accountType={accountType} />}
                    <div className={styles.registerFormlayout}>
                      <div>{children}</div>
                      {!!leftBottomComponent && <div className={styles.signUpContainer}>{leftBottomComponent}</div>}
                    </div>
                  </div>
                ) : (
                  <div className={styles.accountTypeRegisterlayout}>
                    <div>{children}</div>
                    {!!leftBottomComponent && <div className={styles.signUpContainer}>{leftBottomComponent}</div>}
                  </div>
                )}
                <NewsLetters />
              </Container>
            )}
          </div>
          <Footer isRegisterForm={isRegisterForm} />
        </div>
      ) : (
        <div className={styles.sideBarContainer}>
          <div className={styles.widgetHeading}>
            <img width="160" height="40" src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg" alt="" />
            <a href="/#" className={styles.closeWidget}>
              <Typography color="black" msg="Close" tag="span" size="s" family="helvetica" bolder />
            </a>
            <span className={styles.closeIcon} onClick={() => setShowSideBar(false)}>
              <Icon icon="closewindow" />
            </span>
          </div>
          <div className={styles.widgetBody}>
            {authMobileMenu.map((menuLinks: MobileMenuProperties) => (
              <section key={menuLinks.keyId} className={styles.itemContainer}>
                <div className={styles.menuItem}>
                  <a href={menuLinks.keyId} className={styles.link}>
                    <Typography
                      msg={t(`${Messages.labelAuthMobileMenuPrefix}${menuLinks.keyId}` as Messages)}
                      size="lbx"
                      tag="div"
                    />
                  </a>
                  <Typography
                    color="mobileDescColor"
                    msg={t(`${Messages.descAuthMobileMenuPrefix}${menuLinks.keyId}` as Messages)}
                    size="m"
                    tag="p"
                  />
                </div>
                <div className={styles.iconContainer}>{chevronRightIcon}</div>
              </section>
            ))}

            <section className={styles.loginRegisterContainer}>
              <div className={styles.buttonContainers}>
                <Button
                  type={ButtonTypes.button}
                  label={t(Messages.buttonRegister)}
                  onClick={() => history.push(`${Links.register}${queryString}`)}
                  isFullWidth
                />
              </div>

              <div className={styles.buttonContainers}>
                <Button
                  style={ButtonStyles.white}
                  type={ButtonTypes.button}
                  label={t(Messages.buttonLogin)}
                  onClick={() => history.push(generatePath(`${Links.login}${queryString}`))}
                  isFullWidth
                />
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthPageLayout;
