import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  GocardlessCreateCheckoutSessionMutation,
  GocardlessCreateCheckoutSessionMutationVariables,
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import {
  mutationGoCardlessCreateCheckoutSession,
  mutationStripeCreateCheckoutSession,
} from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './PaymentModeModalBody.scss';

export interface PaymentModeModalBodyProps {
  onClose?: () => void;
  amount: number;
  priceId: string;
  baseCase: string;
  packageId: string;
}

const PaymentModeModalBody: React.FunctionComponent<ModalProviderProps<PaymentModeModalBodyProps>> = ({
  closeModal,
  modalData: { priceId, baseCase, amount, packageId },
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const t = useTranslations();

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      closeModal();
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [goCardlessSubscriptionMutation, { loading: gocardlessLoading }] = useMutation<
    GocardlessCreateCheckoutSessionMutation,
    GocardlessCreateCheckoutSessionMutationVariables
  >(mutationGoCardlessCreateCheckoutSession, {
    onCompleted: (apiResult: any) => {
      window.location.href = apiResult.gocardlessCreateCheckoutSession.authorisationUrl;
      closeModal();
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (gocardlessLoading) {
    loadingHandler(
      dispatch,
      'gocardless',
      t('title_gocardless' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_gocardless' as Messages)
    );
  }

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  return (
    <div className={styles.bodyContainer}>
      <Typography
        className={styles.headersSpacing}
        msg={t('title_payment_mode_question' as Messages)}
        tag="h4"
        bold
        size="xl"
      />

      <Typography
        className={styles.headersSpacing}
        msg={t('label_payment_mode_redirect' as Messages)}
        tag="div"
        size="l"
      />

      <div
        onClick={() =>
          goCardlessSubscriptionMutation({
            variables: {
              input: {
                baseCase,
                isSubscription: true,
                package: packageId,
              },
            },
          })
        }
        className={styles.modeCard}
      >
        <Row constant justifyBetween>
          <Col>
            <Typography
              className={styles.annually}
              tag="div"
              size="l"
              color="dividerColor"
              msg={t('label_annually' as Messages)}
            />
          </Col>
          <Col tabindex={0}>
            <Icon icon="gocardless" />
          </Col>
        </Row>
        <Typography tag="div" size="l" bold msg={t('label_pay_direct_debit' as Messages)} />
        <Row>
          <Typography msg={`£${amount}`} tag="div" size="xxxl" />
          <Typography msg="/year" tag="div" size="l" className={styles.tenure} />
        </Row>
        {/* eslint-disable-next-line */}
        <div className={classNames(styles.offerContainer, styles.marginBotto25)} tabIndex={0}>
          <Typography msg="Save 25%" bold color="white" tag="div" size="m" />
        </div>

        <Row>
          <Icon icon="tick" size={IconSizes.ss} className={styles.tick} />
          <Typography
            msg={t('label_auto_recurring_direct_debit' as Messages)}
            className={styles.advantageMessage}
            tag="div"
            color="footerColor"
            size="l"
          />
        </Row>
      </div>

      <div
        onClick={() => {
          stripeCreateDcSubscriptionMutation({ variables: { input: { priceId, baseCase } } });
        }}
        className={styles.modeCard}
      >
        <Row constant justifyBetween>
          <Col>
            <Typography
              className={styles.annually}
              tag="div"
              size="l"
              color="dividerColor"
              msg={t('label_annually' as Messages)}
            />
          </Col>
          <Col tabindex={0}>
            <Icon icon="stripe" />
          </Col>
        </Row>
        <Typography tag="div" size="l" bold msg={t('label_pay_card' as Messages)} />
        <Row>
          <Typography msg={`£${amount}`} tag="div" size="xxxl" />
          <Typography msg="/year" tag="div" size="l" className={styles.tenure} />
        </Row>

        <Row className={styles.marginBotto25}>
          <Icon icon="tick" size={IconSizes.ss} className={styles.tick} />
          <Typography
            msg={t('label_subscription_via_card' as Messages)}
            className={styles.advantageMessage}
            tag="div"
            color="footerColor"
            size="l"
          />
        </Row>

        <Row>
          <Col size={5}>
            <Typography msg={t('label_accepted_payment_methods' as Messages)} tag="div" color="footerColor" size="s" />
          </Col>

          <Col>
            <Row constant alignCenter className={styles.paymentModeIcons}>
              <Col tabindex={0}>
                <Icon icon="mastercard" />
              </Col>
              <Col tabindex={0}>
                <Icon icon="visa" />
              </Col>
              <Col tabindex={0}>
                {' '}
                <Icon icon="gpay" />
              </Col>
              <Col tabindex={0}>
                <Icon icon="applepay" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentModeModalBody;
