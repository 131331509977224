import { generatePath } from 'react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { Links, LocalStorage, CaseTags } from '@Config/constants';
import {
  LifeCaseCreateMutationPayload,
  LifeCaseNode,
  LifeCaseUpdateMutationPayload,
} from '@Graphql/graphqlTypes.generated';
import { createLCMutation, updateLCMutation } from '@Graphql/lc/lc.mutations';
import { fetchLCQuery } from '@Graphql/lc/lc.queries';
import { LifeCaseSubpagesNew } from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';
import { hideModal } from '@Store/app/app.actions';
import { CreateLCPayload, FetchLCPayload, UpdateLCPayload } from '@Store/lc/lc.types';
import { locationChange } from '@Store/navigation/navigation.actions';
import { normaliseGqlError } from '@Utils/form';
import { setToLocalStorage } from '@Utils/localStorage';

import { createLC, fetchLC, updateLC } from './lc.actions';

export function* fetchLCSaga(action: Action<FetchLCPayload>) {
  const { id } = action.payload;
  try {
    const result: LifeCaseNode = yield call(fetchLCQuery, id);
    yield put(fetchLC.done({ result, params: action.payload }));
  } catch (error: any) {
    yield put(fetchLC.failed({ error, params: action.payload }));
  }
}

export function* createLCSaga(action: Action<CreateLCPayload>) {
  const { setStatus, setSubmitting, queryString, ...restProps } = action.payload;
  try {
    const result: LifeCaseCreateMutationPayload = yield call(createLCMutation, restProps);
    yield setToLocalStorage(LocalStorage.lifeCaseTag, CaseTags.lifeCaseCreated);
    yield put(
      locationChange({
        path: generatePath(`${Links.lcEdit}${queryString}`, {
          id: result.lifeCase.id,
          subpage: LifeCaseSubpagesNew.GeneralStep2,
          tag: `tag=${CaseTags.lifeCaseCreated}`,
        }),
      })
    );
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* updateLCSaga(action: Action<UpdateLCPayload>) {
  const { setStatus, setSubmitting, setNextStep, navigateAway, withModal, ...restProps } = action.payload;
  try {
    const result: LifeCaseUpdateMutationPayload = yield call(updateLCMutation, restProps);
    yield put(fetchLC.done({ result: result.lifeCase, params: action.payload }));
    yield setSubmitting(false);
    if (setNextStep) {
      yield setNextStep();
    }
    if (withModal) {
      yield put(hideModal({}));
    }
    if (navigateAway) {
      yield put(
        locationChange({
          path: Links.home,
        })
      );
    }
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* watchLCSaga() {
  yield all([
    yield takeLatest(fetchLC.started, fetchLCSaga),
    yield takeLatest(createLC.started, createLCSaga),
    yield takeLatest(updateLC.started, updateLCSaga),
  ]);
}
