import typescriptFsa from 'typescript-fsa';

import { UploadUserDocumentPayload } from '@Graphql/graphqlTypes.generated';

import {
  UpdateAccountPayload,
  VerifyIdentityPayload,
  UploadIdDocumentsPayload,
  DeleteIdDocumentsPayload,
} from './settings.types';

const actionCreator = typescriptFsa('@SETTINGS');

export const updateAccount = actionCreator.async<UpdateAccountPayload, {}>('UPDATE_ACCOUNT');
export const verifyIdentity = actionCreator.async<VerifyIdentityPayload, {}>('UPDATE_ID_VALIDATION');
export const uploadIdDocument = actionCreator.async<UploadIdDocumentsPayload, UploadUserDocumentPayload>(
  'UPLOAD_ID_DOCUMENT'
);

export const deleteIdDcoument = actionCreator.async<DeleteIdDocumentsPayload, {}>('DELETE_ID_DOCUMENT');
