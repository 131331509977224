import * as React from 'react';

import FileView from '@Components/application/FileView';
import Typography from '@Components/Typography';
// import { Messages } from '@Config/messages';
// import { useTranslations } from '@Hooks/useTranslations';
import { DocumentNode } from '@Graphql/graphqlTypes.generated';

import styles from './FileIconWithLabel.scss';

export interface FileIconWithLabelProps {
  file: DocumentNode | null;
  label?: string;
  fileType?: string;
  blockDocumentAccess?: boolean;
}

const FileIconWithLabel: React.FunctionComponent<FileIconWithLabelProps> = ({
  file,
  label,
  fileType,
  blockDocumentAccess,
}) => {
  // const t = useTranslations();

  return (
    <div>
      {label && <Typography tag="div" msg={label} size="m" color="footerColor" />}
      {fileType && <Typography tag="div" msg={fileType} size="m" />}
      {file && (
        <FileView
          blockDocumentAccess={blockDocumentAccess}
          containerClassName={styles.fileContainer}
          fileDetails={file}
          showDownload={false}
        />
      )}
    </div>
  );
};

export default FileIconWithLabel;
