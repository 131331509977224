import * as React from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import Row from '@Components/layout/Row/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './SuggestionBlock.scss';

export interface SuggestCreateAccountProps {
  suggestLogin: boolean;
  authType: AuthSuggestions;
}

export enum AuthSuggestions {
  login = 'login',
  register = 'register',
  forgotPassword = 'forgotPassword',
}

const SuggestionBlock: React.FunctionComponent<SuggestCreateAccountProps> = ({ suggestLogin, authType }) => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;
  const link = suggestLogin ? generatePath(Links.login) : generatePath(Links.register);

  const chooseQuestion = (screenType: string): string => {
    if (screenType === AuthSuggestions.login) {
      return t(Messages.labelNoAccount);
    }
    if (screenType === AuthSuggestions.register) {
      return t(Messages.labelHaveAccount);
    }
    return t(Messages.labelNotRegistered);
  };

  const chooseLinkTitle = (screenType: string): string => {
    if (screenType === AuthSuggestions.login) {
      return t(Messages.linkSignUpHere);
    }
    if (screenType === AuthSuggestions.register) {
      return t(Messages.linkSignInHere);
    }
    return t(Messages.linkRegisterHere);
  };

  return (
    <div className={styles.spacing}>
      <Typography className={styles.labelSpacing} align="center" size="l" tag="div" msg={chooseQuestion(authType)} />
      <Row justifyCenter>
        <Link to={`${link}${queryString}`} className={styles.links}>
          <Typography align="center" color="electricBlue" bold size="mb" tag="span" msg={chooseLinkTitle(authType)} />
        </Link>
      </Row>
    </div>
  );
};

export default SuggestionBlock;
