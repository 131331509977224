import * as React from 'react';
import { useSelector } from 'react-redux';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useServiceProviderDataFieldsQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';

import DataDefinitionsForm from './DataDefinitionsForm/DataDefinitionsForm';
import styles from './DataDefinitionsPage.scss';

export interface DataDefinitionsPageProps {}

const DataDefinitionsPage: React.FunctionComponent<DataDefinitionsPageProps> = () => {
  const user = useSelector(getUser);
  const t = useTranslations();

  const { data, loading } = useServiceProviderDataFieldsQuery({
    fetchPolicy: 'network-only',
    variables: { serviceProvider: user?.serviceProvider },
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
  });

  if (loading) {
    return (
      <Col size={8}>
        <LoadingSpinner />
      </Col>
    );
  }

  return (
    <div className={styles.formSection}>
      <div className={styles.titleContainer}>
        <TitleWithSubtitle
          title={t('msg_title_settings_subitem_requiredfields' as Messages)}
          subtitle={t('msg_subtitle_settings_requiredfields' as Messages)}
          userSection
        />
      </div>

      <Row className={styles.tipContainer}>
        <Col className={styles.leftSide}>
          <Row>
            <Icon className={styles.iconSpacing} icon="info" />
            <Typography tag="div" size="xs" bold msg={t(Messages.labelTip)} />
          </Row>
        </Col>
        <Col className={styles.rightSide}>
          <Typography
            size="s"
            tag="span"
            msg="Basic information will be collected as standard and therefore you do not need to create a required field below for"
          />
          &nbsp;
          <Typography
            size="xs"
            tag="span"
            bold
            msg="‘Date of birth’; ‘Date of death’; ‘Deceased’s name’; ‘Deceased’s address’; ‘Death certificate’; ‘Notifier’s ID’"
          />
        </Col>
      </Row>

      <DataDefinitionsForm
        data={data?.serviceProviderDataFields.sort((a: any, b: any) => {
          if (a.displayOrder > b.displayOrder) return 1;
          if (a.displayOrder < b.displayOrder) return -1;
          return 0;
        })}
      />
    </div>
  );
};

export default DataDefinitionsPage;
