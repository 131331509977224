import moment from 'moment';

import {
  ApplicationLanguages,
  DataExchangeFormats,
  DateFormats,
  Defaults,
  languageBasedFormats,
} from '@Config/constants';

export function getFormattedDateTime(date = new Date()) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${month}/${day} ${hour}:${padLeadingZero(minutes)}:${padLeadingZero(seconds)}`;
}

export function padLeadingZero(value: number) {
  return value > 9 ? value : `0${value}`;
}

export const getDateFormat = (currentLanguage: ApplicationLanguages, dateFormatted?: DateFormats) => {
  const language = currentLanguage || Defaults.language;
  const format = dateFormatted || DateFormats.shortDate;
  return languageBasedFormats[format][language];
};

export const normalizeDate = (currentLanguage: ApplicationLanguages) => (date: any) => {
  const dateFormat = currentLanguage ? getDateFormat(currentLanguage) : Defaults.dateFormat;
  return moment.isMoment(date) ? date.format(dateFormat) : date;
};

export const dataFormatDate = (
  date: Date | string,
  dateFormat = DataExchangeFormats.date,
  noDateReturn: string | null = null
) => {
  if (!date) {
    return noDateReturn;
  }

  return moment(date).format(dateFormat);
};

export const languageBasedDate = (date: Date, language: ApplicationLanguages, dateFormatted?: DateFormats) => {
  if (!date) {
    return '';
  }
  const format = getDateFormat(language, dateFormatted);
  return moment(date).format(format || DateFormats.shortDate);
};

export const formatDateForExchange = (date: Date | string) => {
  return date ? moment(date).format(DataExchangeFormats.date) : null;
};

export const checkIsDate = (date: string) => {
  return moment(date, DataExchangeFormats.date, true).isValid();
};

export const stringToDate = (date: string | null) => {
  return date ? new Date(date) : null;
};

export const stringToDateNotNull = (date: string) => {
  return new Date(date);
};

export const normalizeDateTime = (date: any) => {
  return moment(date).format(languageBasedFormats[DateFormats.dateWithMonthAsText][ApplicationLanguages.EN]);
};

export const currentDateTime = () => {
  return moment();
};

export const maxBirthDate = () => {
  return moment().subtract(1, 'years');
};

export const getDayMonth = (date: any) => {
  return moment(date).format(languageBasedFormats[DateFormats.dayMonth][ApplicationLanguages.EN]);
};

export const djangoDateFormat = (date: string) => {
  return moment(date).format('MMMM Do, YYYY, h:mm a');
};

export const DateFormat = (date: string) => {
  return moment(date).format('MMMM Do, YYYY');
};

export const compareDates = (renewal: string) => {
  const date = moment(renewal);
  const now = moment();
  return now >= date;
};
