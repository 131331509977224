import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@Components/application/GrayPaper';
import Button, { ButtonSizes, ButtonStyles } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import DataWithLabel from '@Components/DataWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import { Messages } from '@Config/messages';
import {
  DeathCaseNode,
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
  UserTitle,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { capitalize } from '@Utils/helpers';
import { somethingWrongHandler } from '@Utils/modal';

import styles from './SummaryInfoDeceased.scss';

export interface SummaryInfoDeceasedProps {
  deathCase: DeathCaseNode | RestrictedDeathCaseNode;
  userAccountType?: string;
  deathCaseServiceProvider?: RestrictedCaseServiceProviderNode;
  accessCode?: string;
}

const SummaryInfoDeceased: React.FunctionComponent<SummaryInfoDeceasedProps> = ({
  deathCase,
  userAccountType,
  deathCaseServiceProvider,
  accessCode = '',
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();

  const deceasedTitle = deathCase.title?.toUpperCase() === UserTitle.Other ? deathCase.titleOther : deathCase.title;

  return (
    <Paper
      width="width-full"
      className={classNames(userAccountType ? styles.professionalPaper : styles.normalGrayPaper, styles.sameHeight)}
    >
      <div className={styles.deceasedContainer}>
        {deathCaseServiceProvider && (
          <Row justifyEnd constant>
            <LinkButton
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode);
                }
              }}
              linkTitle="Something wrong?"
              onClick={() => somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode)}
            />
          </Row>
        )}
        <CardTitle heading="Deceased’s details" description="The personal identification details of the deceased" />
      </div>
      <Row constant columnMobile>
        <Col size={5}>
          <DataWithLabel data={`${capitalize(deceasedTitle || '-')}`} label={t(Messages.fieldTitle)} dashForEmpty />
        </Col>
        <Col size={1} constant />
        <Col size={5}>
          <DataWithLabel
            data={languageBasedDate(deathCase.birthDate, currentLanguage)}
            label={t(Messages.labelBirthDate)}
            dashForEmpty
          />
        </Col>
      </Row>
      <Row constant columnMobile>
        {deathCase.firstName && (
          <Col size={5}>
            <DataWithLabel
              data={[deathCase.firstName, deathCase.middleName, deathCase.lastName].join(' ')}
              label="Name"
              dashForEmpty
            />
          </Col>
        )}
        <Col size={1} constant />
        <Col size={5}>
          <DataWithLabel
            data={languageBasedDate(deathCase.funeralDate, currentLanguage)}
            label={t(Messages.fieldFuneralDate)}
            dashForEmpty
          />
        </Col>
      </Row>

      <Row constant columnMobile>
        <Col size={5}>
          <DataWithLabel
            data={languageBasedDate(deathCase.deathDate, currentLanguage)}
            label={t(Messages.fieldDeathDate)}
            dashForEmpty
          />
        </Col>
      </Row>

      <Row constant columnMobile>
        <Col size={5}>
          <DataWithLabel dashForEmpty data={deathCase.buildingName} label={t(Messages.fieldBuildingName)} />
        </Col>
        <Col size={1} constant />
        <Col size={5}>
          <DataWithLabel dashForEmpty data={deathCase.buildingNumber} label={t(Messages.fieldBuildingNumber)} />
        </Col>
      </Row>

      <Row constant columnMobile>
        <Col size={5}>
          <DataWithLabel
            data={[deathCase.address1, deathCase.address2].filter(Boolean).join(', ')}
            label={t(Messages.labelAddress)}
            dashForEmpty
          />
        </Col>
      </Row>
      <Row constant columnMobile>
        <Col size={5}>
          <DataWithLabel data={deathCase.city} label={t(Messages.labelCity)} dashForEmpty />
        </Col>
        <Col size={1} constant />
        <Col size={5}>
          <DataWithLabel data={deathCase.postalCode} label={t(Messages.labelPostalCode)} dashForEmpty />
        </Col>
      </Row>

      {deathCase.previousAddress1 && deathCase.previousAddress1 !== '' && (
        <>
          <Row constant columnMobile>
            <Col size={5}>
              <DataWithLabel dashForEmpty data={deathCase.prevBuildingName} label={t(Messages.fieldBuildingName)} />
            </Col>
            <Col size={1} constant />
            <Col size={5}>
              <DataWithLabel dashForEmpty data={deathCase.prevBuildingNumber} label={t(Messages.fieldBuildingNumber)} />
            </Col>
          </Row>

          <Row constant columnMobile>
            <Col size={5}>
              <DataWithLabel
                data={[deathCase.previousAddress1, deathCase.previousAddress2].filter(Boolean).join(', ')}
                label="Previous address"
                dashForEmpty
              />
            </Col>
          </Row>
          <Row constant columnMobile>
            <Col size={5}>
              <DataWithLabel data={deathCase.previousCity} label="Previous city" dashForEmpty />
            </Col>
            <Col size={1} constant />
            <Col size={5}>
              <DataWithLabel data={deathCase.previousPostalCode} label="Previous postal code" dashForEmpty />
            </Col>
          </Row>
        </>
      )}

      <Row constant columnMobile>
        <Col size={5}>
          <DataWithLabel
            data={
              deathCase.propertyType
                ? t(`${Messages.labelBaseCasePropertyPrefix}${deathCase.propertyType?.toLowerCase()}` as Messages)
                : ''
            }
            label={t(Messages.fieldBaseCaseProperty)}
            conditional
            dashForEmpty
          />
        </Col>
        <Col size={1} constant />
        <Col size={5}>
          <DataWithLabel
            data={
              deathCase.occupancyStatus
                ? t(`${Messages.labelOccupancyStatusPrefix}${deathCase.occupancyStatus?.toLowerCase()}` as Messages)
                : ''
            }
            label={t(Messages.fieldOccupancyStatus)}
            conditional
            dashForEmpty
          />
        </Col>
      </Row>
      <Row size={12}>
        {deathCase.certificates &&
          deathCase.certificates.map((document, i) => (
            <Col size={12} constant key={i}>
              <Button
                label="Download death certificate"
                size={ButtonSizes.fill}
                style={ButtonStyles.transparent}
                onClick={() => window.open(document.file)}
                isFullWidth
              />
            </Col>
          ))}
      </Row>
    </Paper>
  );
};

export default SummaryInfoDeceased;
