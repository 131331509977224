import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationDeleteDCSP } from '@Graphql/dc/mutations';
import {
  CaseProviderDeleteMutation,
  CaseProviderDeleteMutationVariables,
  CaseServiceProviderFragment,
  DeathCaseFragment,
  LifeCaseFragment,
  Permissions,
} from '@Graphql/graphqlTypes.generated';
import { queryCaseServiceProviders } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { deathCaseNotificationHandler, editProviderHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './MobileNotificationActionsModalBody.scss';

export interface MobileNotificationActionsModalBodyProps {
  onClose?: () => void;
  serviceId: string;
  caseData?: DeathCaseFragment | LifeCaseFragment | undefined;
  isLifeCase?: boolean;
  service: CaseServiceProviderFragment;
  isMissingInfo?: boolean;
}

const MobileNotificationActionsModalBody: React.FunctionComponent<ModalProviderProps<
  MobileNotificationActionsModalBodyProps
>> = ({ modalData: { serviceId, caseData, isLifeCase, service, isMissingInfo }, closeModal }) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [deleteDCSPMutation] = useMutation<CaseProviderDeleteMutation, CaseProviderDeleteMutationVariables>(
    mutationDeleteDCSP,
    {
      refetchQueries: [getOperationName(queryCaseServiceProviders) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess(t(Messages.notificationProviderDeleteSuccess)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const deleteHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCSPMutation({ variables: { input: { id: serviceId } } }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.modalRemoveDCSPTitle),
          subtitle: t(Messages.modalRemoveDCSPSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  return (
    <div className={styles.topSpacing}>
      <Row justifyCenter className={styles.bottomSpacing}>
        <div
          onClick={() => {
            closeModal();
            deathCaseNotificationHandler(dispatch, service.id, isLifeCase, caseData);
          }}
        >
          <Icon className={styles.swapIcon} icon="grid" />
          <Typography msg="View details" tag="span" size="mb" className={styles.switchCase} />
        </div>
      </Row>
      {caseData?.myPermission !== Permissions.ReadOnly && (
        <>
          {' '}
          <div className={styles.breaker} />
          <Row constant justifyCenter className={styles.bottomSpacing}>
            <div
              onClick={() => {
                closeModal();
                editProviderHandler(t, caseData ? caseData.id : '', dispatch, isLifeCase === true, service);
              }}
            >
              <Icon className={styles.swapIcon} icon="edit" />
              <Typography
                msg={isLifeCase && isMissingInfo ? 'Complete missing info' : 'Edit'}
                tag="span"
                size="mb"
                className={styles.switchCase}
              />
            </div>
          </Row>
        </>
      )}
      {caseData?.myPermission !== Permissions.ReadOnly && (
        <>
          <div className={styles.breaker} />
          <Row alignCenter constant justifyCenter onClick={() => deleteHandler()}>
            <Col>
              <Icon icon="trash" className={styles.viewNotificationIcon} />
            </Col>
            <Col>
              <Typography msg="Remove" color="red" bold tag="span" size="mb" />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default MobileNotificationActionsModalBody;
