import classNames from 'classnames';
import * as React from 'react';

import styles from './LoadingSpinner.scss';

export interface LoadingSpinnerProps {
  fullscreen?: boolean;
  circumference?: string;
  isGraySpinner?: boolean;
}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({
  fullscreen,
  circumference = '65px',
  isGraySpinner = false,
}) => {
  return (
    <div className={classNames(styles.spinner, { [styles.fullscreen]: fullscreen })}>
      <svg width={circumference} height={circumference} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={classNames(styles.spinnerPath, isGraySpinner ? styles.grayColor : styles.spinnerColor)}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  );
};
export default LoadingSpinner;
