import { NotificationsState } from 'react-notification-system-redux';

import { RootState } from '@Store/reducers';

import { ModalState } from './app.reducer';

export const isAppReady = (state: RootState): boolean => state.app.isReady;
export const getCurrentTranslations = (state: RootState): Record<string, string> => {
  return state.app.isReady && state.app.config ? state.app.config.translations[state.localization.currentLanguage] : {};
};
export const getModalState = (state: RootState): ModalState => state.app.modal;
export const getStripeApiPublicKey = (state: RootState): string => state.app.config.stripeApiPublicKey;
export const getEnableTuCheck = (state: RootState): boolean => state.app.config.enableTuCheck;
export const getGoogleTagManagerId = (state: RootState): string => state.app.config.googleTagManagerId;

export const getNotifications = (state: RootState): NotificationsState => state.notifications;
