import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonStyles } from '@Components/Button';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  StripeProductNode,
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import styles from './PaymentCard.scss';

export interface PaymentCardProps {
  data: StripeProductNode;
  currentProductId: string;
}

const PaymentCard: React.FunctionComponent<PaymentCardProps> = ({ data, currentProductId }) => {
  const t = useTranslations();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    variables: {
      input: { priceId: data.priceId },
    },
    onCompleted: (apiResult) => {
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (stripeLoading) {
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  return (
    <div className={styles.cardContainer}>
      <Row justifyCenter>
        <Typography msg={data.name} tag="h3" bold />
      </Row>
      <Row justifyCenter className={styles.planTitle}>
        <div className={styles.space}>
          <Typography msg={data.description} tag="span" size="l" />
        </div>
      </Row>
      {/* <Row justifyCenter className={styles.space}>
        <Typography msg={data.minLimit.toString()} tag="span" size="l" bold />
        &nbsp;
        <Typography msg={t(Messages.labelToForCaseLimit)} tag="span" size="l" />
        &nbsp;
        <Typography msg={data.maxLimit.toString()} tag="span" size="l" bold />
        &nbsp;
        <Typography
          msg={t(`${Messages.labelRegisteredPrefix}${caseType.toLowerCase()}` as Messages)}
          tag="span"
          size="l"
        />
      </Row> */}
      <Row justifyCenter className={styles.space}>
        <Typography msg={t(Messages.labelDuration)} tag="span" size="l" />
        &nbsp;
        <Typography
          msg={t(`${data.recurringIntervalCount}_${data.recurringInterval}` as Messages)}
          tag="span"
          size="l"
          bold
        />
      </Row>
      <Row justifyCenter className={styles.space}>
        <Typography msg={`${data.currency.toUpperCase()} ${data.unitAmount.toString()}`} tag="h2" bold />
      </Row>

      <Row justifyCenter className={styles.space}>
        {data.unitAmount !== 0 && (
          <Button
            label={t(Messages.buttonSubscribe)}
            style={ButtonStyles.outlined}
            disabled={data.productId === currentProductId}
            onClick={() => stripeCreateDcSubscriptionMutation()}
            loading={stripeLoading}
          />
        )}
        {data.unitAmount === 0 && <div className={styles.subscribeReplace} />}
      </Row>
    </div>
  );
};

export default PaymentCard;
