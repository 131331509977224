import { ExecutionResult } from 'graphql';

import { queryAppConfig } from '@Graphql/config/queries';
import ApolloClient from '@Graphql/graphqlClient';
import { AppConfigQuery, AppConfigQueryVariables } from '@Graphql/graphqlTypes.generated';

export const getAppConfigQuery = async () => {
  const result: ExecutionResult<AppConfigQuery> = await ApolloClient.query<AppConfigQuery, AppConfigQueryVariables>({
    query: queryAppConfig,
    fetchPolicy: 'network-only',
  });

  return result.data?.appConfig;
};
