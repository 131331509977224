import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import Compressor from 'compressorjs';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import ImageUpload from '@Components/form/inputs/ImageUpload';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import Typography from '@Components/Typography';
import { AddressPortal, LocalStorage, validationLimits } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationAgentCompanyUpdate } from '@Graphql/agent/mutations';
import { queryAgentCompanyDetails } from '@Graphql/agent/queries';
import { queryProfessionalCompanyDetails } from '@Graphql/auth/queries';
import {
  useServiceProviderQuery,
  useProfessionalCompanyDetailsQuery,
  ProfessionalCompanyUpdateMutation,
  ProfessionalCompanyUpdateMutationVariables,
  UserAccountType,
  PortalUpdateSettingsMutation,
  PortalUpdateSettingsMutationVariables,
  ProfessionalCompanyProfessionalType,
  useAgentCompanyDetailsQuery,
  AgentCompanyUpdateMutation,
  AgentCompanyUpdateMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { queryServiceProvider } from '@Graphql/providers/queries';
import { mutationPortalUpdateSettings } from '@Graphql/serviceProvidersPortal/mutations';
import { mutationProfessionalCompanyUpdate } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { deleteFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import {
  validateEmail,
  validatePhone,
  validateMaxLen,
  validateAny,
  validatePostalCodeCharacters,
  validateMinLength,
  validateSelector,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../model/Address';
import layoutStyles from '../AccountPage/AccountForm/AccountForm.scss';

import styles from './CompanyPage.scss';

export interface CompanyPageProps {
  noInvoices?: boolean;
}

enum CompanyPageFields {
  companyName = 'companyName',
  vatNumber = 'vatNumber',
  companyNumber = 'companyNumber',
  buildingNumber = 'buildingNumber',
  buildingName = 'buildingName',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  postalCode = 'postalCode',
  isMfaEnabled = 'isMfaEnabled',
  primaryBereavementEmail = 'primaryBereavementEmail',
  primaryBereavementPhone = 'primaryBereavementPhone',
  autoAddress = 'autoAddress',
  chooseAddress = 'chooseAddress',
}

type CompanyPageTypeFields = Extract<
  CompanyPageFields,
  | CompanyPageFields.buildingNumber
  | CompanyPageFields.buildingName
  | CompanyPageFields.address1
  | CompanyPageFields.address2
  | CompanyPageFields.city
  | CompanyPageFields.postalCode
>;

const CompanyPage: React.FunctionComponent<CompanyPageProps> = () => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const serviceProviderId = user?.serviceProvider || '';
  const dispatch = useDispatch();

  const isProfessionalUser = user?.accountType === UserAccountType.Professional;
  const isAgent = user?.accountType === UserAccountType.Agent;
  const isServiceProviderUser = user?.accountType === UserAccountType.ServiceProvider;

  // const [manualAddress, setManualAddress] = React.useState(!!user?.address1);

  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);

  const [currentPostalCode, setCurrentPostalCode] = React.useState('');

  const [searching, setSearching] = React.useState(false);

  const [assignedAddress, setAssignedAddress] = React.useState(-1);

  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);

  const providerInfo = useServiceProviderQuery({
    variables: {
      id: serviceProviderId,
    },
    fetchPolicy: 'network-only',
    skip: isProfessionalUser || isAgent,
  });

  const companyDetails = useProfessionalCompanyDetailsQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: isServiceProviderUser || isAgent,
    variables: { id: user ? user?.professionalCompany : '' },
  });

  const agentCompanyDetails = useAgentCompanyDetailsQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    skip: isServiceProviderUser || isProfessionalUser,
    variables: { id: user ? user?.agentCompany : '' },
  });

  // const [updateSettings] = useMutation<PortalUpdateSettingsMutation, PortalUpdateSettingsMutationVariables>(
  //   mutationPortalUpdateSettings,
  //   {
  //     refetchQueries: [getOperationName(queryServiceProvider) || ''],
  //     onCompleted: (response: any) => {
  //       dispatch(notificationSuccess(t(response.portalUpdateSettings.message)));
  //     },
  //     onError: (error: any) => {
  //       dispatch(notificationError(normaliseGqlError(error.message)));
  //     },
  //   }
  // );

  const getAddress = () => {
    if (currentPostalCode !== '') {
      setSearching(true);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', currentPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apidata) => {
          if (apidata.Message) {
            dispatch(notificationError(apidata.Message));
            setAddressResult([]);
            setSearching(false);
          } else {
            const outputData = new Address(apidata);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError('Address not found for this postal code'));
            }
            setAddressResult(outputData.addresses);
            setSearching(false);
          }
        });
    }
  };

  const [professionalCompanyUpdate, { loading }] = useMutation<
    ProfessionalCompanyUpdateMutation,
    ProfessionalCompanyUpdateMutationVariables
  >(mutationProfessionalCompanyUpdate, {
    refetchQueries: [getOperationName(queryProfessionalCompanyDetails) || ''],
    onCompleted: () => {
      deleteFromLocalStorage(LocalStorage.settingFormChanged);
      dispatch(notificationSuccess('Company details updated successfully'));
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [agentCompanyUpdate, { loading: agentCompanyUpdateLoading }] = useMutation<
    AgentCompanyUpdateMutation,
    AgentCompanyUpdateMutationVariables
  >(mutationAgentCompanyUpdate, {
    refetchQueries: [getOperationName(queryAgentCompanyDetails) || ''],
    onCompleted: () => {
      deleteFromLocalStorage(LocalStorage.settingFormChanged);
      dispatch(notificationSuccess('Company details updated successfully'));
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [spUpdateSettings] = useMutation<PortalUpdateSettingsMutation, PortalUpdateSettingsMutationVariables>(
    mutationPortalUpdateSettings,
    {
      refetchQueries: [getOperationName(queryServiceProvider) || ''],
      onCompleted: (response: any) => {
        deleteFromLocalStorage(LocalStorage.settingFormChanged);
        dispatch(notificationSuccess(t(response.portalUpdateSettings.message)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  if (companyDetails.loading || providerInfo.loading || agentCompanyDetails.loading) {
    return (
      <Col size={8} alignCenter textCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
  };

  const userDataValues = {
    [CompanyPageFields.buildingName]: addressResult.find((addr: FullAddress) => addr.id === assignedAddress)
      ?.buildingName,
    [CompanyPageFields.buildingNumber]: addressResult.find((addr: FullAddress) => addr.id === assignedAddress)
      ?.buildingNumber,
    [CompanyPageFields.address1]: addressResult.find((addr: FullAddress) => addr.id === assignedAddress)?.address1,
    [CompanyPageFields.address2]: addressResult.find((addr: FullAddress) => addr.id === assignedAddress)?.address2,
    [CompanyPageFields.city]: addressResult.find((addr: FullAddress) => addr.id === assignedAddress)?.city,
    [CompanyPageFields.postalCode]: addressResult.find((addr: FullAddress) => addr.id === assignedAddress)?.postalCode,
  };

  const professionalData = isAgent
    ? agentCompanyDetails.data?.agentCompanyDetails
    : companyDetails.data?.professionalCompanyDetails;
  const serviceProviderData = providerInfo.data?.serviceProvider;

  const emptyFormValues = {
    [CompanyPageFields.buildingNumber]:
      isProfessionalUser || isAgent ? professionalData?.buildingNumber : serviceProviderData?.buildingNumber || '',
    [CompanyPageFields.buildingName]:
      isProfessionalUser || isAgent ? professionalData?.buildingName : serviceProviderData?.buildingName || '',
    [CompanyPageFields.address1]:
      isProfessionalUser || isAgent ? professionalData?.address1 : serviceProviderData?.address1 || '',
    [CompanyPageFields.address2]:
      isProfessionalUser || isAgent ? professionalData?.address2 : serviceProviderData?.address2 || '',
    [CompanyPageFields.city]: isProfessionalUser || isAgent ? professionalData?.city : serviceProviderData?.city || '',
    [CompanyPageFields.postalCode]:
      isProfessionalUser || isAgent ? professionalData?.postalCode : serviceProviderData?.postalCode || '',
  };

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm } = useFormikContext();

    React.useEffect(() => {
      const getFieldValue = (fieldName: CompanyPageTypeFields) => {
        return assignedAddress > -1 ? userDataValues[fieldName] : emptyFormValues[fieldName];
      };
      const toggleExecutorFormField = (fieldName: CompanyPageTypeFields) => {
        setFieldValue(fieldName, getFieldValue(fieldName), false);
        setFieldTouched(fieldName, false, false);
      };
      toggleExecutorFormField(CompanyPageFields.buildingName);
      toggleExecutorFormField(CompanyPageFields.buildingNumber);
      toggleExecutorFormField(CompanyPageFields.address1);
      toggleExecutorFormField(CompanyPageFields.address2);
      toggleExecutorFormField(CompanyPageFields.city);
      toggleExecutorFormField(CompanyPageFields.postalCode);
    }, [setFieldValue, setFieldTouched, resetForm]);

    return null;
  };

  const compressAndUpload = (file: File) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: (compressedResult: File) => {
        dispatch(
          spUpdateSettings({
            variables: {
              input: {
                logo: compressedResult,
                id: serviceProviderId,
              },
            },
          })
        );
      },
    });
  };

  const inputControlChange = (e: any, currentValue: any, initialValue: any, controlName: string) => {
    if (e.target) {
      if (e.target.name === controlName) {
        return e.target.value !== initialValue;
      }
      if (initialValue === null) {
        return currentValue !== '';
      }
      return currentValue !== initialValue;
    }
    return e.value !== initialValue;
  };

  const formChanges = (e: any, values: any) => {
    const sraNumberChanged = inputControlChange(
      e,
      values.vatNumber,
      isProfessionalUser ? companyDetails.data?.professionalCompanyDetails.sraNumber : undefined,
      CompanyPageFields.vatNumber
    );
    const companyNumberChanged = inputControlChange(
      e,
      values.companyNumber,
      professionalData?.companyNumber,
      CompanyPageFields.companyNumber
    );
    const currentPostalCodeChanged = currentPostalCode !== '';
    const buildingNameChanged = inputControlChange(
      e,
      values.buildingName,
      user?.buildingName,
      CompanyPageFields.buildingName
    );
    const buildingNumberChanged = inputControlChange(
      e,
      values.buildingNumber,
      user?.buildingNumber,
      CompanyPageFields.buildingNumber
    );
    const address1Changed = inputControlChange(e, values.address1, user?.address1, CompanyPageFields.address1);
    const address2Changed = inputControlChange(e, values.address2, user?.address2, CompanyPageFields.address2);
    const cityChanged = inputControlChange(e, values.city, user?.city, CompanyPageFields.city);
    const postalCodeChanged = inputControlChange(e, values.postalCode, user?.postalCode, CompanyPageFields.postalCode);
    const isMfaEnabledChanged = inputControlChange(
      e,
      values.isMfaEnabled,
      professionalData?.isMfaEnabled,
      CompanyPageFields.isMfaEnabled
    );

    if (
      sraNumberChanged ||
      currentPostalCodeChanged ||
      buildingNameChanged ||
      isMfaEnabledChanged ||
      buildingNumberChanged ||
      address1Changed ||
      address2Changed ||
      cityChanged ||
      postalCodeChanged ||
      companyNumberChanged
    ) {
      setToLocalStorage(LocalStorage.settingFormChanged, true);
    } else {
      deleteFromLocalStorage(LocalStorage.settingFormChanged);
    }
  };

  const isCSW =
    companyDetails.data?.professionalCompanyDetails &&
    companyDetails.data?.professionalCompanyDetails.professionalType ===
      ProfessionalCompanyProfessionalType.CommunityOrganisation;

  return (
    <div className={layoutStyles.accountPage}>
      <TitleWithSubtitle title={t(Messages.titleSettingsCompanySection)} subtitle="" userSection />
      <Formik
        initialValues={{
          [CompanyPageFields.primaryBereavementEmail]: serviceProviderData?.email || '',
          [CompanyPageFields.primaryBereavementPhone]: serviceProviderData?.bereavementContactPhone || '',
          [CompanyPageFields.companyName]:
            isProfessionalUser || isAgent ? professionalData?.companyName : serviceProviderData?.name || '',
          [CompanyPageFields.vatNumber]: isProfessionalUser
            ? companyDetails.data?.professionalCompanyDetails.sraNumber
            : serviceProviderData?.vatNumber || '',
          [CompanyPageFields.companyNumber]:
            isProfessionalUser || isAgent ? professionalData?.companyNumber : serviceProviderData?.companyNumber || '',

          [CompanyPageFields.buildingNumber]:
            isProfessionalUser || isAgent
              ? professionalData?.buildingNumber
              : serviceProviderData?.buildingNumber || '',
          [CompanyPageFields.buildingName]:
            isProfessionalUser || isAgent ? professionalData?.buildingName : serviceProviderData?.buildingName || '',
          [CompanyPageFields.address1]:
            isProfessionalUser || isAgent ? professionalData?.address1 : serviceProviderData?.address2 || '',
          [CompanyPageFields.address2]:
            isProfessionalUser || isAgent ? professionalData?.address2 : serviceProviderData?.address2 || '',
          [CompanyPageFields.city]:
            isProfessionalUser || isAgent ? professionalData?.city : serviceProviderData?.city || '',
          [CompanyPageFields.postalCode]: isProfessionalUser
            ? professionalData?.postalCode
            : serviceProviderData?.postalCode || '',

          [CompanyPageFields.isMfaEnabled]:
            isProfessionalUser || isAgent ? professionalData?.isMfaEnabled : serviceProviderData?.isMfaEnabled || false,
          [CompanyPageFields.chooseAddress]: -1,
        }}
        validationSchema={Yup.object({
          [CompanyPageFields.primaryBereavementEmail]: isProfessionalUser || isAgent ? validateAny() : validateEmail(t),
          [CompanyPageFields.primaryBereavementPhone]: isProfessionalUser || isAgent ? validateAny() : validatePhone(t),
          [CompanyPageFields.postalCode]: validateMaxLen(t, validationLimits.postalCode, false),
          [CompanyPageFields.chooseAddress]: resultAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
        })}
        onSubmit={(values) => {
          const { address1, address2, city, postalCode, buildingName, buildingNumber, companyName } = values;
          if (isProfessionalUser) {
            professionalCompanyUpdate({
              variables: {
                input: {
                  companyNumber: !isCSW ? values.companyNumber : '',
                  sraNumber: !isCSW ? values.vatNumber : '',
                  buildingName,
                  buildingNumber,
                  address1,
                  address2,
                  city,
                  postalCode,
                  id: companyDetails.data?.professionalCompanyDetails.id || '',
                  isMfaEnabled: values.isMfaEnabled,
                },
              },
            });
          } else if (isAgent) {
            agentCompanyUpdate({
              variables: {
                input: {
                  companyNumber: !isCSW ? values.companyNumber : '',
                  // sraNumber: !isCSW ? values.vatNumber : '',
                  buildingName,
                  buildingNumber,
                  address1,
                  address2,
                  city,
                  postalCode,
                  id: agentCompanyDetails.data?.agentCompanyDetails.id || '',
                  isMfaEnabled: values.isMfaEnabled,
                },
              },
            });
          } else {
            spUpdateSettings({
              variables: {
                input: {
                  email: values.primaryBereavementEmail.trim(),
                  bereavementContactPhone: removeLeadingZeroAndSpaceInContact(values.primaryBereavementPhone),
                  id: serviceProviderId,
                  companyNumber: values.companyNumber,
                  vatNumber: values.vatNumber,
                  buildingName,
                  buildingNumber,
                  address1,
                  address2,
                  city,
                  postalCode,
                  isMfaEnabled: values.isMfaEnabled,
                  name:
                    serviceProviderData?.name.toLowerCase() !== companyName?.toLowerCase() ? companyName : undefined,
                },
              },
            });
          }
        }}
      >
        {({ status, values }) => (
          <Form onChange={(e) => formChanges(e, values)} className={styles.companyAccntForm}>
            <div className={styles.formSection}>
              <Row>
                <Col size={8}>
                  <Typography tag="h4" msg={t(Messages.titleSettingsCompanySection)} className={styles.sectionHeader} />
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <Field
                    name={CompanyPageFields.companyName}
                    type="text"
                    component={TextInput}
                    label={isCSW ? t('field_community_organisation' as Messages) : t(Messages.fieldcompanyName)}
                    disabled={!!user?.professionalCompany}
                  />
                </Col>
              </Row>
              {!isCSW && (
                <>
                  {!isAgent && (
                    <Row>
                      <Col size={6}>
                        <Field
                          name={CompanyPageFields.vatNumber}
                          type="text"
                          disabled={true}
                          component={TextInput}
                          label={
                            isProfessionalUser || isAgent
                              ? t('field_sra_number' as Messages)
                              : t(Messages.fieldcompanyVat)
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col size={4}>
                      <Field
                        name={CompanyPageFields.companyNumber}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldcompanyNumber)}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>

            {!isProfessionalUser && !isAgent && (
              <div className={styles.formSection}>
                <Row>
                  <Col size={8}>
                    <Typography
                      tag="h4"
                      msg={t('label_primary_contact_information' as Messages)}
                      className={styles.sectionHeader}
                    />
                  </Col>
                </Row>
                <Row size={7}>
                  <Typography tag="div" size="l" msg={t('label_primary_contact_information_help_text' as Messages)} />
                </Row>
                <Row>
                  <Col size={8}>
                    <Field
                      name={CompanyPageFields.primaryBereavementEmail}
                      type="text"
                      component={TextInput}
                      label={t('field_sp_contact_email' as Messages)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col size={8}>
                    <Field
                      name={CompanyPageFields.primaryBereavementPhone}
                      type="text"
                      component={TextInput}
                      label={t('field_sp_contact_number' as Messages)}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {!isProfessionalUser && !isAgent && (
              <div className={styles.formSection}>
                <Row>
                  <Col size={8}>
                    <Typography tag="h4" msg={t('field_company_logo' as Messages)} className={styles.sectionHeader} />
                  </Col>
                </Row>
                <Row className={styles.helpTextSpacing} size={7}>
                  <Typography tag="div" size="l" msg={t('field_company_logo_help_text' as Messages)} />
                </Row>

                <ImageUpload
                  onDrop={
                    (files: File[]) => {
                      compressAndUpload(files[0]);
                    }
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  imgFile={serviceProviderData ? serviceProviderData?.logo : ''}
                />
              </div>
            )}

            <div className={styles.formSection}>
              <Row>
                <Col size={8}>
                  <Typography tag="h4" msg={t(Messages.companyAddressSectionHeader)} className={styles.sectionHeader} />
                </Col>
              </Row>
              <Typography tag="div" size="l" msg={t(Messages.fieldCompanyPostCodeInput)} />
              <Row className={styles.addressContainer} columnTablet>
                <Col size={5}>
                  <input
                    name={CompanyPageFields.autoAddress}
                    type="text"
                    value={currentPostalCode}
                    className={styles.input}
                    autoComplete="off"
                    onChange={(e: any) => {
                      setCurrentPostalCode(e.currentTarget.value);
                      setShowClearAddress(true);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter' && validatePostalCodeCharacters(currentPostalCode)) {
                        e.preventDefault();
                        getAddress();
                      }
                    }}
                    onBlur={() => {
                      if (validateMinLength(currentPostalCode) && resultAddressOptions.length === 0) {
                        setPostCodeTabOut(true);
                      } else {
                        setPostCodeTabOut(false);
                      }
                    }}
                  />
                  {showClearAddress && (
                    <Icon
                      icon="close-outline"
                      className={styles.visibilityToggleIcon}
                      onClick={() => {
                        setShowClearAddress(false);
                        setCurrentPostalCode('');
                        setAddressResult([]);
                        setAssignedAddress(-1);
                        setPostCodeTabOut(false);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          setShowClearAddress(false);
                          setCurrentPostalCode('');
                          setAddressResult([]);
                          setAssignedAddress(-1);
                          setPostCodeTabOut(false);
                        }
                      }}
                      size={IconSizes.s}
                    />
                  )}
                </Col>
                <Col size={3} className={styles.searchButtonContainer}>
                  <Popover
                    showContent={postCodeTabOut}
                    position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                    title={t('title_postcode_warning' as Messages)}
                    message={t('desc_postcode_warning' as Messages)}
                  >
                    <Button
                      className={styles.fullWidth}
                      type={ButtonTypes.button}
                      style={ButtonStyles.transparent}
                      loading={searching}
                      label={t(Messages.buttonSearch)}
                      icon="search"
                      iconSize={IconSizes.sxs}
                      onClick={() => getAddress()}
                      constant
                      disabled={!validatePostalCodeCharacters(currentPostalCode)}
                    />
                  </Popover>
                </Col>
              </Row>

              {resultAddressOptions.length > 0 && (
                <Row className={styles.topAddressSpacing}>
                  <Col size={8}>
                    <Field
                      name={CompanyPageFields.chooseAddress}
                      component={SelectInput}
                      label=""
                      placeholder={t('label_select_your_address' as Messages)}
                      options={resultAddressOptions}
                      disabled={!resultAddressOptions.length}
                      onSelectChange={handleAddressChange}
                      selectClass={ClassNamesForOverwrite.SelectAddress}
                      onFocus={() => setPostCodeTabOut(false)}
                      hasErrorProp={assignedAddress === -1}
                    />
                  </Col>
                </Row>
              )}

              {/* <Row>
                <Col className={styles.adresslink} size={9}>
                  <div onClick={() => setManualAddress(!manualAddress)}>
                    <Typography msg="Or enter address manually" tag="span" />
                  </div>
                </Col>
              </Row> */}

              {/* {manualAddress && (
                <> */}
              <Row>
                <Col size={3}>
                  <Field
                    name={CompanyPageFields.buildingNumber}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldBuildingNumber)}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <Field
                    name={CompanyPageFields.buildingName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldBuildingName)}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <Field
                    name={CompanyPageFields.address1}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldAddress1)}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <Field
                    name={CompanyPageFields.address2}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldAddress2)}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={8}>
                  <Field
                    name={CompanyPageFields.city}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldCity)}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={4}>
                  <Field
                    name={CompanyPageFields.postalCode}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldPostalCode)}
                  />
                </Col>
              </Row>
              {/* </>
              )} */}
            </div>
            <div className={styles.formSection}>
              <Row>
                <Col size={8}>
                  <Typography tag="h4" msg={t(Messages.multiFactorAuthHeader)} className={styles.sectionHeader} />
                </Col>
              </Row>
              <Row className={styles.info}>
                <Col size={8}>
                  <Typography tag="span" size="m" bold msg={t(Messages.multiFactorAuthQuestion)} />
                  &nbsp;
                  <Typography tag="span" size="m" msg={t(Messages.multiFactorAuthDesc)} />
                </Col>
              </Row>
              <Row className={styles.mfaRadioOptions}>
                <Col size={12}>
                  <Field
                    name={CompanyPageFields.isMfaEnabled}
                    component={ToggleSwitchInput}
                    label=""
                    toggleLabels={[t(Messages.labelMfaOptionOn), t(Messages.labelMfaOptionOff)]}
                  />
                </Col>
              </Row>
            </div>
            <Row size={4}>
              <Button
                label={t(Messages.buttonUpdate)}
                type={ButtonTypes.submit}
                loading={loading || agentCompanyUpdateLoading}
              />
            </Row>
            <AutoToggleForm />
            <FormError formError={status} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyPage;
