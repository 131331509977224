import ReviewCompanies from './ReviewCompanies';
import SelectCompanies from './SelectCompanies/SelectCompanies';

export enum CRAStepsSubpages {
  DeceasedDetails = 'deceasedDetails',
  LettersUpload = 'lettersUpload',
  UploadConfirmation = 'uploadConfirmation',
}

export enum CompanyProfileSubpages {
  SelectCompany = 'SelectCompanies',
  Review = 'review',
}

export enum CompanyProfileSubpagesMenu {
  Select_Companies = 'Select Companies',
  Review = 'Review',
}

export const CompanyProfileSubpagesConfig: {
  [key in CompanyProfileSubpages]: {
    title: string;
    stepIndex?: number;
    totalSteps?: number;
    step: CompanyProfileSubpagesMenu;
    component: React.ReactNode;
    back: CompanyProfileSubpages | null;
    next: CompanyProfileSubpages | null;
    current: CompanyProfileSubpages;
  };
} = {
  [CompanyProfileSubpages.SelectCompany]: {
    title: 'Select Companies',
    step: CompanyProfileSubpagesMenu.Select_Companies,
    component: SelectCompanies,
    back: null,
    next: CompanyProfileSubpages.Review,
    current: CompanyProfileSubpages.SelectCompany,
  },
  [CompanyProfileSubpages.Review]: {
    title: 'Review',
    step: CompanyProfileSubpagesMenu.Review,
    component: ReviewCompanies,
    back: CompanyProfileSubpages.SelectCompany,
    next: null,
    current: CompanyProfileSubpages.Review,
  },
};
