import { ExecutionResult } from 'apollo-link';
import moment from 'moment';

import { LocalStorage } from '@Config/constants';
import {
  mutationChangePassword,
  mutationForgotPassword,
  mutationLogin,
  mutationRegisterUser,
  mutationResendEmail,
  mutationResendVerification,
  mutationResetPassword,
  mutationVerifyEmail,
  mutationGenerateOtp,
  mutationRefreshToken,
} from '@Graphql/auth/mutations';
import ApolloClient from '@Graphql/graphqlClient';
import {
  ChangePasswordInput,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  ForgotPasswordInput,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  LoginMutation,
  LoginMutationVariables,
  RegisterUserInput,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  ResendEmailMutation,
  ResendEmailMutationVariables,
  ResendVerificationMutation,
  ResendVerificationMutationVariables,
  ResetPasswordInput,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
  VerifyUserKeyMutationInput,
  GenerateOtpMutation,
  GenerateOtpMutationVariables,
  GenerateOtpInput,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { setToLocalStorage } from '@Utils/localStorage';

export const loginMutation = async (variables: LoginMutationVariables) => {
  const result: ExecutionResult<LoginMutation> = await ApolloClient.mutate<LoginMutation, LoginMutationVariables>({
    mutation: mutationLogin,
    variables,
  });
  return result.data?.tokenAuth;
};

export const registerUserMutation = async (input: RegisterUserInput) => {
  const result: ExecutionResult<RegisterUserMutation> = await ApolloClient.mutate<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >({
    mutation: mutationRegisterUser,
    variables: {
      input,
    },
  });

  return result.data?.registerUser;
};

export const verifyEmailMutation = async (input: VerifyUserKeyMutationInput) => {
  const result: ExecutionResult<VerifyEmailMutation> = await ApolloClient.mutate<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >({
    mutation: mutationVerifyEmail,
    variables: {
      input,
    },
  });

  return result.data?.verifyUserKey;
};

export const forgotPasswordMutation = async (input: ForgotPasswordInput) => {
  const result: ExecutionResult<ForgotPasswordMutation> = await ApolloClient.mutate<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >({
    mutation: mutationForgotPassword,
    variables: {
      input,
    },
  });

  return result.data?.forgotPassword;
};

export const resendEmailMutation = async (input: ForgotPasswordInput) => {
  const result: ExecutionResult<ResendEmailMutation> = await ApolloClient.mutate<
    ResendEmailMutation,
    ResendEmailMutationVariables
  >({
    mutation: mutationResendEmail,
    variables: {
      input,
    },
  });

  return result.data?.forgotPassword;
};

export const resendVerificationMutation = async (input: ForgotPasswordInput) => {
  const result: ExecutionResult<ResendVerificationMutation> = await ApolloClient.mutate<
    ResendVerificationMutation,
    ResendVerificationMutationVariables
  >({
    mutation: mutationResendVerification,
    variables: {
      input,
    },
  });

  return result.data?.resendVerification;
};

export const resetPasswordMutation = async (input: ResetPasswordInput) => {
  const result: ExecutionResult<ResetPasswordMutation> = await ApolloClient.mutate<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >({
    mutation: mutationResetPassword,
    variables: {
      input,
    },
  });

  return result.data?.resetPassword;
};

export const changePasswordMutation = async (input: ChangePasswordInput) => {
  const result: ExecutionResult<ChangePasswordMutation> = await ApolloClient.mutate<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >({
    mutation: mutationChangePassword,
    variables: {
      input,
    },
  });

  return result.data?.changePassword;
};

export const generateOtpMutation = async (input: GenerateOtpInput) => {
  const result: ExecutionResult<GenerateOtpMutation> = await ApolloClient.mutate<
    GenerateOtpMutation,
    GenerateOtpMutationVariables
  >({
    mutation: mutationGenerateOtp,
    variables: {
      input,
    },
  });

  return result.data;
};

export const refreshTokenMutation = async (expiredToken: any) => {
  const result: ExecutionResult<RefreshTokenMutation> = await ApolloClient.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: mutationRefreshToken,
    variables: {
      token: expiredToken,
    },
  });

  setToLocalStorage(LocalStorage.userToken, result.data?.refreshToken.token);
  const timeAfter55Minutes = moment().add(50, 'minutes');
  setToLocalStorage(LocalStorage.accessTokenExpiry, timeAfter55Minutes);
};
