import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';

import { RouteInfo } from '../appRoutes';

import ActivityPage from './ActivityPage';

const routes: RouteInfo[] = [
  {
    path: Links.activity,
    component: ActivityPage,
    title: Messages.pageActivity,
    isServiceProviderRoute: false,
  },
];

export default routes;
