import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon/Icon';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row/Row';
import LinkButton from '@Components/LinkButton/LinkButton';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  CollaboratorNode,
  CollaboratorPermission,
  CollaboratorProRelationFe,
  CollaboratorRelationshipFe,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { editCollaborator } from '@Store/collaborator/collaborator.actions';
import { getPermissionSelectOption } from '@Utils/form';
import { manipulateTranslations, noop, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { validatePhone, validateRequired, validateSelector } from '@Utils/validator';

import { RelationshipLevel, RelationShipDefinition } from '../../../model/Relationship';
import { ModalProviderProps } from '../Modal';

import styles from './EditCollaboratorModalBody.scss';

export interface EditCollaboratorModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
  collaborator: CollaboratorNode;
  isLegalForm?: boolean;
  isLifeCase?: boolean;
}

enum EditCollaboratorFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  relationship = 'relationship',
  permission = 'permission',
}

const EditCollaboratorModalBody: React.FunctionComponent<ModalProviderProps<EditCollaboratorModalBodyProps>> = ({
  modalData: { buttonText, onClose = noop, secondButtonText, collaborator, isLegalForm = false, isLifeCase },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const onButtonClick = () => {
    onClose();
    closeModal();
  };

  const relationships: RelationShipDefinition[] = [
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Executor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: collaborator.isAlsoExecutor,
      index: 0,
      infoTitle: manipulateTranslations(t, 'tooltip_title_executor', 'What is an executor?'),
      infoMessage: isLifeCase ? t('tooltip_desc_lc_executor' as Messages) : t('tooltip_desc_executor' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.WillAdministrator,
      relationshipType: RelationshipLevel.firstLevel,
      selected: collaborator.isAlsoAdministrator,
      index: 1,
      infoTitle: manipulateTranslations(t, 'tooltip_title_administrator', 'What is an administrator?'),
      infoMessage: isLifeCase
        ? t('tooltip_desc_lc_administrator' as Messages)
        : t('tooltip_desc_administrator' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.LegalRepresentative,
      relationshipType: RelationshipLevel.firstLevel,
      selected: collaborator.isAlsoLegal,
      index: 2,
      infoTitle: manipulateTranslations(t, 'tooltip_title_legal_representative', 'What is a legal representative?'),
      infoMessage: isLifeCase
        ? t('tooltip_desc_lc_legal_representative' as Messages)
        : t('tooltip_desc_legal_representative' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.OtherAdvisor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: collaborator.isAlsoOtherAdvisor,
      index: 3,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Partner.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Partner,
      relationshipType: RelationshipLevel.secondLevel,
      selected: collaborator.relationship === CollaboratorRelationshipFe.Partner,
      index: 4,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Child.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Child,
      relationshipType: RelationshipLevel.secondLevel,
      selected: collaborator.relationship === CollaboratorRelationshipFe.Child,
      index: 5,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Parent.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Parent,
      relationshipType: RelationshipLevel.secondLevel,
      selected: collaborator.relationship === CollaboratorRelationshipFe.Parent,
      index: 6,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Sibling.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Sibling,
      relationshipType: RelationshipLevel.secondLevel,
      selected: collaborator.relationship === CollaboratorRelationshipFe.Sibling,
      index: 7,
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.FamilyMember.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.FamilyMember,
      relationshipType: RelationshipLevel.secondLevel,
      selected: collaborator.relationship === CollaboratorRelationshipFe.FamilyMember,
      index: 8,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Friend.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Friend,
      relationshipType: RelationshipLevel.secondLevel,
      selected: collaborator.relationship === CollaboratorRelationshipFe.Friend,
      index: 9,
    },
  ];

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  const noOwner = Object.values(CollaboratorPermission)
    .filter((item: CollaboratorPermission) => item !== CollaboratorPermission.Owner)
    .map(getPermissionSelectOption(t));

  const permissionSelectOptions =
    user?.accountType === UserAccountType.Professional
      ? Object.values(CollaboratorPermission).map(getPermissionSelectOption(t))
      : noOwner;

  const isCollaboratorDefined = (values: any, relationshipName: any) => {
    return values.relationship.find((x: RelationShipDefinition) => x.value === relationshipName)?.selected;
  };

  const initialValues = {
    // [EditCollaboratorFormFields.relationship]: getRelationshipSelectOption(t)(collaborator.relationship),
    [EditCollaboratorFormFields.permission]: getPermissionSelectOption(t)(collaborator.permission),
    [EditCollaboratorFormFields.firstName]: collaborator.firstName,
    [EditCollaboratorFormFields.lastName]: collaborator.lastName,
    [EditCollaboratorFormFields.phone]: collaborator.phone || '+44',
    [EditCollaboratorFormFields.relationship]: relationshipInitialValue,
  };

  const legalFormRelations = relationships.filter(
    (rd: RelationShipDefinition) => rd.relationshipType === RelationshipLevel.firstLevel
  );

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      width: '581px',
      padding: '14px 24px',
    },
    overlay: {
      border: 'unset',
      borderRadius: '6px',
      background: 'rgba(0, 0, 0, 0.2)',
      zIndex: 1001,
    },
  };

  const handlePermissionChange = (perm: any) => {
    if (perm.value === CollaboratorPermission.Owner && user?.accountType === UserAccountType.Professional) {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.formContainer}>
      <Typography msg={t(Messages.modalEditCollaboratorTitle)} tag="h4" size="xl" bold />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          [EditCollaboratorFormFields.permission]: validateSelector(t),
          [EditCollaboratorFormFields.firstName]: validateRequired(t),
          [EditCollaboratorFormFields.lastName]: validateRequired(t),
          [EditCollaboratorFormFields.phone]: validatePhone(t),
        })}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          dispatch(
            editCollaborator.started({
              setSubmitting,
              setStatus,
              id: collaborator.id,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: removeLeadingZeroAndSpaceInContact(values.phone),
              permission: Object.values(CollaboratorPermission).find((perms: any) => perms === values.permission.value),
              isAlsoAdministrator: isCollaboratorDefined(values, CollaboratorRelationshipFe.WillAdministrator),
              isAlsoOtherAdvisor: isCollaboratorDefined(values, CollaboratorProRelationFe.OtherAdvisor),
              isAlsoExecutor: isCollaboratorDefined(values, CollaboratorRelationshipFe.Executor),
              isAlsoLegal: isCollaboratorDefined(values, CollaboratorProRelationFe.LegalRepresentative),
              relationship:
                values.relationship
                  .filter((rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.secondLevel)
                  .find((x: RelationShipDefinition) => x.selected === true)?.value || collaborator.relationship,
            })
          );
        }}
      >
        {({ status, isSubmitting, handleSubmit, setFieldValue }) => {
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <GridRow>
                  <GridCol size={5}>
                    <Field
                      name={EditCollaboratorFormFields.firstName}
                      type="text"
                      component={TextInput}
                      label="First name"
                      required
                    />
                  </GridCol>
                  <GridCol size={5}>
                    <Field
                      name={EditCollaboratorFormFields.lastName}
                      type="text"
                      component={TextInput}
                      label="Surname"
                      required
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={EditCollaboratorFormFields.phone}
                      type="text"
                      component={TextInput}
                      label="Phone number"
                      required
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={EditCollaboratorFormFields.permission}
                      label={t(Messages.labelCollaboratorPermission)}
                      component={SelectInput}
                      options={permissionSelectOptions}
                      onSelectChange={handlePermissionChange}
                      selectClass={isLegalForm ? undefined : ClassNamesForOverwrite.SelectPermissions}
                    />
                  </GridCol>
                </GridRow>

                {isLegalForm && (
                  <GridRow>
                    <GridCol size={10}>
                      <Field
                        name={EditCollaboratorFormFields.relationship}
                        options={!isLifeCase ? legalFormRelations : relationships}
                        type="text"
                        component={MultiSelect}
                        label={!isLifeCase ? t(Messages.fieldRelationship) : t('field_life_relationship' as Messages)}
                        controlDescription="You can choose more than one"
                      />
                    </GridCol>
                  </GridRow>
                )}

                <FormError formError={status} />
                <div className={classNames(styles.actions)}>
                  <GridRow fullWidth withRowGap>
                    <GridCol size={6}>
                      <Button
                        label={buttonText}
                        size={ButtonSizes.fill}
                        style={ButtonStyles.white}
                        onClick={onButtonClick}
                      />
                    </GridCol>
                    <GridCol size={6}>
                      <Button
                        label={secondButtonText}
                        size={ButtonSizes.fill}
                        type={ButtonTypes.submit}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      />
                    </GridCol>
                  </GridRow>
                </div>
              </Form>
              <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                shouldFocusAfterRender
                shouldCloseOnEsc={false}
                onRequestClose={() => setIsOpen(false)}
              >
                <Row justifyCenter>
                  <Icon icon="alert-triangle" size={IconSizes.m} />
                </Row>

                <Typography
                  textCenter
                  msg={t('title_transfer_owner' as Messages)}
                  tag="h4"
                  size="xl"
                  bold
                  className={styles.bottomSpacing}
                />

                <Typography
                  textCenter
                  msg={t('text_transfer_owner' as Messages)}
                  tag="div"
                  size="l"
                  className={styles.bottomSpacing}
                />
                <Button onClick={() => setIsOpen(false)} label={t('button_transfer_owner' as Messages)} isFullWidth />

                <LinkButton
                  textCenter
                  className={styles.mt18}
                  linkTitle={t('link_not_transfer' as Messages)}
                  onClick={() => {
                    setFieldValue(
                      EditCollaboratorFormFields.permission,
                      getPermissionSelectOption(t)(collaborator.permission)
                    );
                    setIsOpen(false);
                  }}
                />
              </Modal>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditCollaboratorModalBody;
