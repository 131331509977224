import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import { Links } from '@Config/constants';
import LifeCasePage from '@Routes/lifeCase/LifeCasePage';
import { clearLC, fetchLC } from '@Store/lc/lc.actions';

export interface EditLifeCaseProps {}

export interface EditLifeCaseDispatchProps {}

interface EditLifeCasePrivateProps extends EditLifeCaseProps, EditLifeCaseDispatchProps {}

const EditLifeCase: React.FunctionComponent<EditLifeCasePrivateProps> = () => {
  const { id, subpage, missing } = useParams();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchLC.started({ id }));
    return () => {
      dispatch(clearLC());
    };
  }, [id, dispatch]);

  if (!id) {
    return <Redirect to={Links.home} />;
  }

  return <LifeCasePage preferred={subpage} id={id} missing={missing} />;
};

export default EditLifeCase;
