import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Header from '@Components/application/Header';
import NewsLetters from '@Components/application/NewsLetters';
import Button, { ButtonTypes } from '@Components/Button';
import Footer from '@Components/Footer';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Title from '@Components/Title';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useDeathCasesReportLazyQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';
import { AuthSuggestions } from '@Routes/auth/shared/SuggestionBlock/SuggestionBlock';
import { normaliseGqlError } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';
import { validateRequired } from '@Utils/validator';

import AutomatedReportsTablePage from '../AutomatedReportsTablePage';

import styles from './AutomatedReportsForm.scss';

export interface AutomatedReportsFormProps {}

enum AutomatedReportsFormFields {
  accessCode = 'accessCode',
}

const AutomatedReportsForm: React.FunctionComponent<AutomatedReportsFormProps> = () => {
  const t = useTranslations();
  const [dcInfo, setDcInfo] = useState(false);
  const dispatch = useDispatch();

  const [getReport, reportValues] = useDeathCasesReportLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      setDcInfo(true);
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const CodeInputForm = () => (
    <>
      <Header showHamBurger={false} showSideBar={false} />
      <main>
        <Container>
          <div className={styles.registerFormlayout}>
            <Title text={t(Messages.automatedReports)} />

            <Typography
              color="black"
              size="l"
              tag="div"
              msg="Please copy the access code from the notification email"
              family="helvetica"
              className={styles.subtitleSpacing}
            />

            <Formik
              initialValues={{
                [AutomatedReportsFormFields.accessCode]: '',
              }}
              validationSchema={Yup.object({
                [AutomatedReportsFormFields.accessCode]: validateRequired(t),
              })}
              onSubmit={(values) => {
                getReport({
                  variables: {
                    accessCode: values.accessCode.trim(),
                  },
                });
              }}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <Row size={12}>
                    <Field
                      name={AutomatedReportsFormFields.accessCode}
                      type="text"
                      component={TextInput}
                      label={t(Messages.labelAccessCode)}
                      placeholder="281739-18747292-93278"
                      fullWidth
                      required
                    />
                  </Row>
                  <Row alignCenter justifyCenter className={styles.buttonWrapper}>
                    <Button
                      type={ButtonTypes.submit}
                      disabled={isSubmitting}
                      loading={reportValues.loading}
                      label={t(Messages.automatedReports)}
                    />
                  </Row>
                  <FormError formError={status} />
                </Form>
              )}
            </Formik>
            <div className={styles.signUpContainer}>
              <SuggestionBlock authType={AuthSuggestions.register} suggestLogin={true} />
            </div>
          </div>
          <NewsLetters />
        </Container>
      </main>
      <Footer />
    </>
  );

  if (!dcInfo) {
    return <CodeInputForm />;
  }
  if (reportValues.loading) {
    return <LoadingSpinner />;
  }
  return <AutomatedReportsTablePage data={reportValues.data?.deathCasesReport.cases} />;
};

export default AutomatedReportsForm;
