import { useMutation } from '@apollo/react-hooks';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';
import * as Yup from 'yup';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Tooltip, { TooltipPosition } from '@Components/application/Tooltip/Tooltip';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import {
  validationLimits,
  AddressPortal,
  ReactResponsiveQueries,
  LocalStorage,
  Links,
  EnterKeyCode,
  CaseTags,
  emptySelectorValue,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserAccountType,
  ProfessionalCompanyAddMutationVariables,
  ProfessionalCompanyAddMutation,
  useReferredUserQuery,
  useProfessionalCompanyDetailsQuery,
  ProfessionalCompanyProfessionalType,
  UserReferralSource,
  UserLandingPage,
  UserTitle,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser, mutationProfessionalCompanyAdd } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { ReferralSource } from '@Routes/auth/RegisterPage/IndividualRegisterPage/IndividualRegisterForm/IndividualRegisterForm';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { currentDateTime, formatDateForExchange, normalizeDate } from '@Utils/dates';
import { normaliseGqlError, titleOptions } from '@Utils/form';
import { addressHelper, manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import {
  validateMaxLen,
  validateRequired,
  validateAny,
  validatePhone,
  validateDate,
  validatePostalCodeCharacters,
  validateMinLength,
  validateSelector,
  validateName,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../../../model/Address';

import styles from './UserInfo.scss';

export interface UserInfoProps {}

enum UserInfoFields {
  birthDate = 'birthDate',
  phone = 'phone',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  postalCode = 'postalCode',
  autoAddress = 'autoAddress',
  chooseAddress = 'chooseAddress',
  companyName = 'companyName',
  companyNumber = 'companyNumber',
  sraNumber = 'sraNumber',
  buildingName = 'buildingName',
  buildingNumber = 'buildingNumber',
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  referralSource = 'referralSource',
  referralSourceDetails = 'referralSourceDetails',
  terms = 'terms',
  optIn = 'optIn',
  titleOther = 'titleOther',
}

const UserInfo: React.FunctionComponent<UserInfoProps> = () => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const user = useSelector(getUser);

  const referredUserData = useReferredUserQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: !user?.hasRefDeathCase,
  });

  const companyDetails = useProfessionalCompanyDetailsQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: user?.accountType !== UserAccountType.Professional,
    variables: { id: user ? user?.professionalCompany : '' },
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const [manualAddress, setManualAddress] = React.useState(user?.address1 !== '');
  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);
  const [currentPostalCode, setCurrentPostalCode] = React.useState('');
  const [searching, setSearching] = React.useState(false);
  const [assignedAddress, setAssignedAddress] = React.useState(-1);
  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);

  const isProfessionalUser = user?.accountType === UserAccountType.Professional;
  const isProfessionalCollaborator = user?.accountType === UserAccountType.Professional && !user.isProfessionalCompany;
  const isHospice = user?.referralSource === UserReferralSource.LifeHospice;

  const [showRegisterCompany, setShowRegisterCompany] = React.useState(isProfessionalCollaborator);

  const [updateUser, { loading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: () => {
      //  dispatch(notificationSuccess(t(Messages.msgUpdatedSuccessfully)));
      if (user?.landingPage === UserLandingPage.RetargetDeathCase) {
        history.push(generatePath(`${Links.dcCreate}?retarget=death`, { tag: `tag=${CaseTags.deathCaseCreated}` }));
      } else if (isHospice) {
        history.push(generatePath(`${Links.lcCreate}${queryString}`, { tag: `tag=${CaseTags.lifeCaseCreated}` }));
      } else {
        history.push(generatePath(`${Links.home}${queryString}`));
      }
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [addCompany] = useMutation<ProfessionalCompanyAddMutation, ProfessionalCompanyAddMutationVariables>(
    mutationProfessionalCompanyAdd,
    {
      onCompleted: () => {
        history.push(generatePath(`${Links.home}${queryString}`));
        dispatch(notificationSuccess(t(Messages.msgCompanyRegisteredSuccessfully)));
        setAssignedAddress(-1);
        setAddressResult([]);
        setCurrentPostalCode('');
        setShowClearAddress(false);
        setShowRegisterCompany(false);
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  React.useEffect(() => {
    if (user?.hasRefDeathCase) {
      setManualAddress(true);
    }
  }, [user]);

  if (referredUserData.loading || companyDetails.loading) {
    return <LoadingSpinner />;
  }

  const getAddress = () => {
    if (currentPostalCode !== '') {
      setSearching(true);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', currentPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apidata) => {
          if (apidata.Message) {
            dispatch(notificationError(apidata.Message));

            setAddressResult([]);
            setSearching(false);
          } else {
            const outputData = new Address(apidata);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError(t(Messages.msgAddressNotFound)));
            }
            setAddressResult(outputData.addresses);
            setSearching(false);
          }
        });
    }
  };

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
    setManualAddress(true);
  };

  const chooseSubtitle = () => {
    if (user?.accountType === UserAccountType.Professional) {
      if (!user.isProfessionalCompany) {
        return showRegisterCompany ? t(Messages.labelPersonalDetailsCheck) : t(Messages.labelProvideBasicDetails);
      }
      return t(Messages.labelPersonalDetailsCheck);
    }
    return user?.socialLoginType
      ? t('label_confirm_personal_info' as Messages)
      : t(Messages.labelProvideAccountDetails);
  };

  const chooseTitle = () => {
    if (user?.accountType === UserAccountType.Professional) {
      if (!user.isProfessionalCompany) {
        return showRegisterCompany ? t(Messages.pageInvite) : t('page_title_personal_information' as Messages);
      }
      return t(Messages.pageInvite);
    }
    return t(Messages.pageInvite);
  };

  const chooseAddressLabel = () => {
    if (user?.accountType === UserAccountType.Professional) {
      if (!user.isProfessionalCompany) {
        return showRegisterCompany ? `${t(Messages.labelWorkAddress)}*` : `${t('field_home_address' as Messages)}*`;
      }
      return `${t('field_personal_address' as Messages)}*`;
    }
    return t(Messages.labelEnterYourPostcode);
  };

  const isHomeAddress = () => {
    if (user?.accountType === UserAccountType.Professional) {
      if (!user.isProfessionalCompany) {
        return !showRegisterCompany;
      }
      return false;
    }
    return false;
  };

  const sraNumberVisibility = () => {
    if (user?.accountType === UserAccountType.Professional) {
      if (!user.isProfessionalCompany) {
        return showRegisterCompany;
      }
      if (
        companyDetails.data?.professionalCompanyDetails.professionalType ===
        ProfessionalCompanyProfessionalType.CommunityOrganisation
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const validateAddressField = (values: any) => {
    if (assignedAddress === -1 && values.address1 === '') {
      return true;
    }
    return false;
  };

  const disableNextButton = (values: any) => {
    if (user?.accountType === UserAccountType.Individual) {
      return !!(
        values.birthDate === '' ||
        values.phone === '' ||
        values.phone === '+44' ||
        validateAddressField(values)
      );
    }
    if (showRegisterCompany) {
      return !!(values.companyName === '' || validateAddressField(values));
    }
    return !!(validateAddressField(values) || values.phone === '' || values.phone === '+44');
  };
  if (isProfessionalUser) {
    setToLocalStorage(`${LocalStorage.welcomeTourProfessional}_${user?.id}`, true);
  }

  const AutoToggleForm = () => {
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
      const searchedAddress = addressResult.find((addr: FullAddress) => addr.id === assignedAddress);
      if (searchedAddress) {
        setFieldValue(UserInfoFields.buildingName, addressHelper(searchedAddress.buildingName));
        setFieldValue(UserInfoFields.buildingNumber, addressHelper(searchedAddress.buildingNumber));
        setFieldValue(UserInfoFields.address1, addressHelper(searchedAddress.address1));
        setFieldValue(UserInfoFields.address2, addressHelper(searchedAddress.address2));
        setFieldValue(UserInfoFields.city, addressHelper(searchedAddress.city));
        setFieldValue(UserInfoFields.postalCode, addressHelper(searchedAddress.postalCode));
      }
    }, [setFieldValue]);

    return null;
  };

  const referredUserInfo = referredUserData.data?.referredUser;
  const titleInitialValue = user?.title ? titleOptions.find((item) => item.value === user?.title) : emptySelectorValue;

  const refOptionsHospice = Object.values(ReferralSource)
    .filter((rs: string) => rs !== ReferralSource.LifeHospice)
    .map((refSource) => ({
      label: t(`${Messages.fieldReferralSourcePrefix}${refSource.toLowerCase()}` as Messages),
      value: refSource,
    }));

  const getReferralSourceLabel = (referralSource: string): string => {
    if (referralSource === ReferralSource.Other) {
      return t(Messages.labelReferralSourceOther);
    }
    if (referralSource === ReferralSource.Company) {
      return t(Messages.labelReferralSourceCompany);
    }
    return t(Messages.labelReferralSourceDetails);
  };

  const refSourceInitialValue =
    user?.referralSource !== undefined
      ? refOptionsHospice.find((item) => item.value === user?.referralSource)
      : emptySelectorValue;

  const isAgent = user?.accountType === UserAccountType.Agent;

  return (
    <Formik
      initialValues={{
        [UserInfoFields.address1]: user?.address1 || referredUserInfo?.address1 || '',
        [UserInfoFields.address2]: user?.address2 || referredUserInfo?.address2 || '',
        [UserInfoFields.city]: user?.city || referredUserInfo?.city || '',
        [UserInfoFields.postalCode]: user?.postalCode || referredUserInfo?.postalCode || '',
        [UserInfoFields.phone]: user?.phone || '+44',
        [UserInfoFields.birthDate]: user?.birthDate || '',
        [UserInfoFields.companyName]: '',
        [UserInfoFields.companyNumber]: '',
        [UserInfoFields.sraNumber]: '',
        [UserInfoFields.buildingName]: user?.buildingName || referredUserInfo?.buildingName || '',
        [UserInfoFields.buildingNumber]: user?.buildingNumber || referredUserInfo?.buildingNumber || '',
        [UserInfoFields.title]: titleInitialValue,
        [UserInfoFields.firstName]: user?.firstName || '',
        [UserInfoFields.lastName]: user?.lastName || '',
        [UserInfoFields.referralSource]: refSourceInitialValue,
        [UserInfoFields.referralSourceDetails]: user?.referralSourceDetails || '',
        [UserInfoFields.titleOther]: '',
        [UserInfoFields.optIn]: false,
        [UserInfoFields.terms]: false,
      }}
      validationSchema={() => {
        return Yup.lazy((values: any) => {
          return Yup.object({
            [UserInfoFields.birthDate]:
              user?.accountType === UserAccountType.Individual ? validateDate(t) : validateAny(),
            [UserInfoFields.postalCode]: validateMaxLen(t, validationLimits.postalCode, false),
            [UserInfoFields.phone]: validatePhone(t),
            [UserInfoFields.companyName]: showRegisterCompany ? validateRequired(t) : validateAny(),
            [UserInfoFields.chooseAddress]: resultAddressOptions.length > 0 ? validateSelector(t) : validateAny(),

            [UserInfoFields.title]: user?.socialLoginType ? validateSelector(t) : validateAny(),
            [UserInfoFields.firstName]: user?.socialLoginType ? validateName(t) : validateAny(),
            [UserInfoFields.lastName]: user?.socialLoginType ? validateName(t) : validateAny(),
            [UserInfoFields.referralSource]: user?.socialLoginType ? validateSelector(t) : Yup.mixed().notRequired(),
            [UserInfoFields.referralSourceDetails]:
              values.referralSource &&
              user?.socialLoginType &&
              values.referralSource.value === ReferralSource.Other &&
              !values.referralSourceDetails
                ? validateRequired(t)
                : validateAny(),
            [UserInfoFields.titleOther]:
              values.title && values.title.value === UserTitle.Other ? validateRequired(t) : validateAny(),
          });
        });
      }}
      onSubmit={(values, { resetForm }) => {
        const {
          companyName,
          companyNumber,
          sraNumber,
          address1,
          address2,
          city,
          postalCode,
          phone,
          buildingName,
          buildingNumber,
          title,
          firstName,
          lastName,
          titleOther,
          referralSource,
          referralSourceDetails,
        } = values;

        const validatedPhone = removeLeadingZeroAndSpaceInContact(phone);

        if (showRegisterCompany) {
          addCompany({
            variables: {
              input: {
                companyName,
                companyNumber,
                sraNumber,
                address1,
                address2,
                phone: validatedPhone,
                city,
                postalCode,
                buildingName,
                buildingNumber,
              },
            },
          });
          setAddressResult([]);
          setAssignedAddress(-1);
          setManualAddress(false);
          updateUser({
            variables: {
              input: {
                id: user ? user?.id : '',
                isFirstLogin: false,
              },
            },
          });
          resetForm();
        } else {
          updateUser({
            variables: {
              input: {
                id: user ? user?.id : '',
                phone: validatedPhone,
                address1,
                address2,
                city,
                postalCode,
                birthDate: formatDateForExchange(values.birthDate),
                buildingName,
                buildingNumber,
                sraNumber,
                isFirstLogin: false,
                title: Object.values(UserTitle).find((respect: any) => respect === title?.value) || user?.title,
                firstName,
                lastName,
                titleOther,
                referralSource: referralSource?.value
                  ? Object.values(UserReferralSource).find((respect: any) => respect === referralSource.value)
                  : UserReferralSource.SearchEngine,
                referralSourceDetails,
              },
            },
          });
        }
      }}
    >
      {({ status, values, setFieldValue }) => (
        <Form>
          <div className={styles.container}>
            <Typography msg={chooseTitle()} tag="h3" size="xxl" />
            <Row size={11} className={styles.bottomSpacing}>
              <Typography msg={chooseSubtitle()} tag="div" size="l" />
            </Row>

            {/* {isProfessionalCollaborator && (
              <Stepper onClick={() => noop} disableClick={true} currentIndex={showRegisterCompany ? 0 : 1} total={2} />
            )} */}

            {showRegisterCompany && (
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={UserInfoFields.companyName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldcompanyName)}
                    placeholder={t(Messages.labelTypeCompanyName)}
                    required
                  />
                </GridCol>
              </GridRow>
            )}

            {user?.title === null && (
              <GridRow>
                <GridCol size={6}>
                  <Field
                    name={UserInfoFields.title}
                    component={SelectInput}
                    label={t(Messages.fieldTitle)}
                    options={titleOptions}
                    required
                  />
                </GridCol>
              </GridRow>
            )}
            {values.title?.value === UserTitle.Other && (
              <GridRow>
                <GridCol size={6}>
                  <Field
                    name={UserInfoFields.titleOther}
                    type="text"
                    component={TextInput}
                    label={t('field_title_other' as Messages)}
                    required
                  />
                </GridCol>
              </GridRow>
            )}
            {(user?.socialLoginType || (isAgent && user.firstName === '')) && (
              <>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={UserInfoFields.firstName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldFirstName)}
                      helperText={t('label_name_govt_id' as Messages)}
                      required
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={UserInfoFields.lastName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldSurname)}
                      required
                    />
                  </GridCol>
                </GridRow>
              </>
            )}

            <Row constant className={styles.topSpacing}>
              <Typography tag="div" size="l" msg={chooseAddressLabel()} />
              {isHomeAddress() && (
                <Tooltip
                  title={manipulateTranslations(t, 'tooltip_title_home_address', 'why do we ask for this?')}
                  message={manipulateTranslations(
                    t,
                    'tooltip_desc_home_address',
                    'To help protect against fraud, we verify the identity of everyone sending death notifications. This is two steps process. First we will check your details with a credit reference agency, then we will ask you to upload copy of a Government-issued identity document.<br/> As CRA records based on name, home address and date of birth, to complete this step we do need some limited personal data in addition to the professional details already provided.'
                  )}
                  position={TooltipPosition.right}
                >
                  <Icon icon="info" size={IconSizes.sxs} className={styles.margin} />
                </Tooltip>
              )}
            </Row>

            <Row className={styles.addressContainer} size={12}>
              <Col size={isTablet ? 12 : 7}>
                <input
                  name={UserInfoFields.autoAddress}
                  type="text"
                  value={currentPostalCode}
                  className={styles.input}
                  autoComplete="off"
                  onChange={(e: any) => {
                    setCurrentPostalCode(e.currentTarget.value);
                    setShowClearAddress(true);
                  }}
                  placeholder={t(Messages.labelSearchByPostcode)}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter' && validatePostalCodeCharacters(currentPostalCode)) {
                      e.preventDefault();
                      getAddress();
                    }
                  }}
                  onBlur={() => {
                    if (validateMinLength(currentPostalCode) && resultAddressOptions.length === 0) {
                      setPostCodeTabOut(true);
                    } else {
                      setPostCodeTabOut(false);
                    }
                  }}
                />
                {showClearAddress && (
                  <Icon
                    icon="close-outline"
                    className={styles.visibilityToggleIcon}
                    onClick={() => {
                      setShowClearAddress(false);
                      setCurrentPostalCode('');
                      setAddressResult([]);
                      setAssignedAddress(-1);
                      setPostCodeTabOut(false);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        setShowClearAddress(false);
                        setCurrentPostalCode('');
                        setAddressResult([]);
                        setAssignedAddress(-1);
                        setPostCodeTabOut(false);
                      }
                    }}
                    size={IconSizes.s}
                  />
                )}
              </Col>
              <Col size={isTablet ? 12 : 5} className={styles.searchButtonContainer}>
                <Popover
                  showContent={postCodeTabOut}
                  position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                  title={t('title_postcode_warning' as Messages)}
                  message={t('desc_postcode_warning' as Messages)}
                >
                  <Button
                    type={ButtonTypes.button}
                    style={ButtonStyles.transparent}
                    loading={searching}
                    label={t(Messages.buttonSearch)}
                    icon="search"
                    iconSize={IconSizes.sxs}
                    isFullWidth={true}
                    onClick={() => {
                      setPostCodeTabOut(false);
                      getAddress();
                    }}
                    constant
                    disabled={!validatePostalCodeCharacters(currentPostalCode)}
                  />
                </Popover>
              </Col>
            </Row>

            {resultAddressOptions.length > 0 && (
              <Row className={styles.topAddressSpacing} size={12}>
                <Col size={12}>
                  <Field
                    name={UserInfoFields.chooseAddress}
                    component={SelectInput}
                    label=""
                    placeholder={t(Messages.labelSelectYourAddress)}
                    options={resultAddressOptions}
                    disabled={!resultAddressOptions.length}
                    selectClass={ClassNamesForOverwrite.SelectAddress}
                    onSelectChange={handleAddressChange}
                    onFocus={() => setPostCodeTabOut(false)}
                    hasErrorProp={assignedAddress === -1}
                  />
                </Col>
              </Row>
            )}

            <GridRow>
              <GridCol className={styles.adresslink} size={9}>
                <div
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      setManualAddress(!manualAddress);
                    }
                  }}
                  onClick={() => setManualAddress(!manualAddress)}
                >
                  <Typography msg={t(Messages.linkEnterAddressManually)} tag="span" tabIndex={0} />
                </div>
              </GridCol>
            </GridRow>

            {manualAddress && (
              <>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={UserInfoFields.buildingNumber}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingNumber)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={UserInfoFields.buildingName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingName)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={UserInfoFields.address1}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldAddress1)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={UserInfoFields.address2}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldAddress2)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={4}>
                    <Field name={UserInfoFields.city} type="text" component={TextInput} label={t(Messages.fieldCity)} />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={UserInfoFields.postalCode}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldPostalCode)}
                    />
                  </GridCol>
                </GridRow>
              </>
            )}
            <GridRow className={styles.topSpacing}>
              <GridCol size={6}>
                <Field
                  name={UserInfoFields.phone}
                  type="text"
                  component={TextInput}
                  label={!showRegisterCompany ? t(Messages.fieldContactNumber) : t('field_company_phone' as Messages)}
                  required
                />
              </GridCol>
            </GridRow>

            {user?.accountType === UserAccountType.Individual && (
              <GridRow>
                <GridCol size={10}>
                  <Field
                    name={UserInfoFields.birthDate}
                    type="text"
                    component={DatePickerInput}
                    parse={normalizeDate(currentLanguage)}
                    label={t(Messages.fieldBirthDate)}
                    maxDate={currentDateTime().toDate()}
                    required
                  />
                </GridCol>
              </GridRow>
            )}

            {user?.socialLoginType && (
              <>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={UserInfoFields.referralSource}
                      component={SelectInput}
                      selectClass={ClassNamesForOverwrite.SelectReferralField}
                      label={t(Messages.labelReferralSource)}
                      options={refOptionsHospice}
                      onSelectChange={() => {
                        setShowOther(true);
                        setFieldValue(UserInfoFields.referralSourceDetails, '');
                      }}
                    />
                  </GridCol>
                </GridRow>

                {showOther && (
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={UserInfoFields.referralSourceDetails}
                        type="text"
                        component={TextInput}
                        label={getReferralSourceLabel(values.referralSource ? values.referralSource.value : '')}
                      />
                    </GridCol>
                  </GridRow>
                )}
              </>
            )}
            {showRegisterCompany && (
              <GridRow className={styles.topSpacing}>
                <GridCol size={6}>
                  <Field
                    name={UserInfoFields.companyNumber}
                    type="text"
                    component={TextInput}
                    label={t('field_registered_company_number' as Messages)}
                    placeholder="Enter company number"
                  />
                </GridCol>
              </GridRow>
            )}

            {sraNumberVisibility() && (
              <GridRow className={styles.topSpacing}>
                <GridCol size={6}>
                  <Field
                    name={UserInfoFields.sraNumber}
                    type="text"
                    component={TextInput}
                    label="Solicitors Regulation Authority (SRA) number"
                    boldLabel="Personal"
                    toggleBold={true}
                    infoMessage={manipulateTranslations(
                      t,
                      'tooltip_desc_sra',
                      'Only solicitors need to provide the SRA number'
                    )}
                    infoTitle={manipulateTranslations(
                      t,
                      'tooltip_title_sra',
                      'Solicitors Regulation Authority (SRA) number'
                    )}
                  />
                </GridCol>
              </GridRow>
            )}

            <AutoToggleForm />

            <FormError formError={status} />
            <Row className={styles.bigTopSpacing}>
              <Col size={isTablet ? 12 : 7}>
                <Button
                  disabled={disableNextButton(values)}
                  type={ButtonTypes.submit}
                  size={ButtonSizes.fill}
                  label="Next"
                  isFullWidth
                  loading={loading}
                />
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserInfo;
