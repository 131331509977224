import classNames from 'classnames';
import * as React from 'react';

import { noop } from '@Utils/helpers';

import styles from './Col.scss';

export interface ColProps {
  size?: number;
  className?: string;
  textCenter?: boolean;
  textRight?: boolean;
  textCenterTablet?: boolean;
  constant?: boolean;
  alignCenter?: boolean;
  onClick?: (e?: any) => void;
  textLeft?: boolean;
  id?: string;
  alignBottom?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  tabindex?: number;
  onKeyDown?: (e: any) => void;
  isDraggable?: boolean;
  onDragStart?: (idx: number) => void;
  onDragEnter?: (idx: number) => void;
  onDragEnd?: () => void;
  idx?: number;
}

type ColSizes =
  | 'size1'
  | 'size2'
  | 'size3'
  | 'size4'
  | 'size5'
  | 'size6'
  | 'size7'
  | 'size8'
  | 'size9'
  | 'size10'
  | 'size11'
  | 'size12'
  | 'size13'
  | 'auto';

const Col: React.FunctionComponent<ColProps> = ({
  size,
  children,
  className,
  textCenter,
  textCenterTablet,
  constant,
  alignCenter,
  onClick,
  textRight,
  textLeft,
  id = '',
  alignBottom,
  onMouseLeave,
  onMouseOver,
  onBlur,
  onFocus,
  tabindex,
  onKeyDown,
  isDraggable,
  onDragEnd,
  onDragEnter = noop,
  onDragStart = noop,
  idx,
}) => {
  return (
    <div
      className={classNames(className, {
        [styles[`size${size}` as ColSizes]]: size,
        [styles.auto]: !size,
        [styles.textCenter]: textCenter,
        [styles.textCenterTablet]: textCenterTablet,
        [styles.constant]: constant,
        [styles.alignCenter]: alignCenter,
        [styles.textRight]: textRight,
        [styles.textLeft]: textLeft,
        [styles.alignBottom]: alignBottom,
      })}
      onClick={onClick}
      id={id}
      onFocus={() => onFocus && onFocus()}
      onBlur={() => onBlur && onBlur()}
      onMouseOver={() => onMouseOver && onMouseOver()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      tabIndex={tabindex}
      onKeyDown={onKeyDown}
      draggable={isDraggable}
      onDragStart={() => onDragStart(idx || 0)}
      onDragEnd={onDragEnd}
      onDragEnter={() => onDragEnter(idx || 0)}
    >
      {children}
    </div>
  );
};

export default Col;
