import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button/Button';
import SelectInput from '@Components/form/inputs/SelectInput/SelectInput';
import TextInput from '@Components/form/inputs/TextInput/TextInput';
import Col from '@Components/layout/Col/Col';
import GridCol from '@Components/layout/Grid/GridCol/GridCol';
import GridRow from '@Components/layout/Grid/GridRow/GridRow';
import Row from '@Components/layout/Row/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Typography from '@Components/Typography/Typography';
import { colorArray } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  AddServiceProviderNotesMutation,
  AddServiceProviderNotesMutationVariables,
  ServiceProviderNode,
  ServiceProviderNotificationChannel,
  UpdateServiceProviderMutation,
  UpdateServiceProviderMutationVariables,
  UserNode,
  useServiceProviderNotesQuery,
} from '@Graphql/graphqlTypes.generated';
import { queryServiceProvider } from '@Graphql/providers/queries';
import { mutationAddServiceProviderNotes, mutationUpdateServiceProvider } from '@Graphql/staff/mutations';
import { queryServiceProviderNotes } from '@Graphql/staff/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { djangoDateFormat } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateEmail } from '@Utils/validator';

import 'react-quill/dist/quill.snow.css';
import styles from './SPInfoNotificationDetails.scss';

export interface SPInfoNotificationDetailsProps {
  user?: UserNode | null;
  setSpNotesCallback: (comm: string) => void;
  spNotesParent: string;
  serviceProvider?: ServiceProviderNode;
}

export enum NotificationFields {
  channel = 'channel',
  number = 'number',
  email = 'email',
  formLink = 'formLink',
  processingTime = 'processingTime',
}

const SPInfoNotificationDetails: React.FunctionComponent<SPInfoNotificationDetailsProps> = ({
  user,
  setSpNotesCallback,
  spNotesParent,
  serviceProvider,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [sla, setSla] = React.useState(serviceProvider ? serviceProvider.slaInDays : 0);
  const [textValue, setTextValue] = React.useState(spNotesParent);
  const [msgValue, setMsgValue] = React.useState(serviceProvider?.onlineFormUserMessage);
  const quillRef = React.useRef<ReactQuill>(null);
  const [updateAction, setUpdateAction] = React.useState(false);

  const { data, loading } = useServiceProviderNotesQuery({
    fetchPolicy: 'network-only',
    variables: {
      serviceProvider: serviceProvider?.id || '',
    },
  });

  const [addSPNotesMutation] = useMutation<AddServiceProviderNotesMutation, AddServiceProviderNotesMutationVariables>(
    mutationAddServiceProviderNotes,
    {
      refetchQueries: [getOperationName(queryServiceProviderNotes) || ''],
      onCompleted: () => {
        setTextValue('');
        setSpNotesCallback('');
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const handleChange = (value: any) => {
    setTextValue(value);
    setSpNotesCallback(value);
  };

  const [spUpdateSettings] = useMutation<UpdateServiceProviderMutation, UpdateServiceProviderMutationVariables>(
    mutationUpdateServiceProvider,
    {
      refetchQueries: [getOperationName(queryServiceProvider) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess(t('msg_sp_updated' as Messages)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const channelOptions = Object.values(ServiceProviderNotificationChannel).map((chans: string) => ({
    label: t(`label_channels_${chans.toLowerCase()}` as Messages),
    value: chans,
  }));

  const channelInitialValue = serviceProvider?.notificationChannel
    ? channelOptions.find((item: any) => item.value === serviceProvider.notificationChannel.toUpperCase())
    : channelOptions.find((item: any) => item.value.toLowerCase() === 'other');

  const getNotificationChannelEnum = (channel: string): ServiceProviderNotificationChannel => {
    let channelSelected: ServiceProviderNotificationChannel = ServiceProviderNotificationChannel.Automated;
    switch (channel) {
      case ServiceProviderNotificationChannel.Automated.toString():
        channelSelected = ServiceProviderNotificationChannel.Automated;
        break;
      case ServiceProviderNotificationChannel.Telephone.toString():
        channelSelected = ServiceProviderNotificationChannel.Telephone;
        break;
      case ServiceProviderNotificationChannel.ManualEmail.toString():
        channelSelected = ServiceProviderNotificationChannel.ManualEmail;
        break;
      case ServiceProviderNotificationChannel.OnlineForm.toString():
        channelSelected = ServiceProviderNotificationChannel.OnlineForm;
        break;
      case ServiceProviderNotificationChannel.Other.toString():
        channelSelected = ServiceProviderNotificationChannel.Other;
        break;
      case ServiceProviderNotificationChannel.AutoOnlineForm.toString():
        channelSelected = ServiceProviderNotificationChannel.AutoOnlineForm;
        break;
      default:
        break;
    }
    return channelSelected;
  };

  const getOneChar = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  const submitComment = () => {
    const quillValue = quillRef.current && quillRef.current.getEditor().getContents();
    if (
      textValue.replace(/(<([^>]+)>)/gi, '').trim() !== '' &&
      quillValue?.ops &&
      quillValue.ops.length !== 0 &&
      quillValue.ops[0].insert !== '\n'
    ) {
      addSPNotesMutation({
        variables: {
          input: {
            serviceProviderId: serviceProvider?.id || '',
            notes: textValue,
          },
        },
      });
    } else {
      dispatch(notificationError('Please enter notes to save'));
    }
  };

  const commentedByUsers: string[] = [
    ...new Set(data?.serviceProviderNotes.map((nc: any) => nc && nc.commentedBy && nc.commentedBy.id)),
  ];

  const getColor = (userId: string) => {
    let index = 0;
    index = userId === '' ? 9 : commentedByUsers.indexOf(userId);
    return index === 0 ? colorArray[10] : colorArray[index];
  };

  return (
    <>
      <div className={styles.notifyContainer}>
        <Formik
          initialValues={{
            [NotificationFields.channel]: channelInitialValue,
            [NotificationFields.number]: serviceProvider?.bereavementContactPhone || '+44',
            [NotificationFields.email]: serviceProvider?.email || '',
            [NotificationFields.formLink]: serviceProvider?.linkToOnlineForm || '',
          }}
          validationSchema={() => {
            return Yup.object({
              [NotificationFields.email]: validateEmail(t),
            });
          }}
          onSubmit={(values) => {
            const channelValue = getNotificationChannelEnum(values.channel?.value || '');
            spUpdateSettings({
              variables: {
                input: {
                  email: values.email.trim(),
                  bereavementContactPhone: removeLeadingZeroAndSpaceInContact(values.number),
                  id: serviceProvider?.id || '',
                  linkToOnlineForm: values.formLink,
                  slaInDays: sla ? parseInt(sla.toString(), 10) : undefined,
                  notificationChannel: channelValue,
                  onlineFormUserMessage: msgValue,
                },
              },
            });
          }}
        >
          {({ isValid, values }) => (
            <Form onChange={() => setUpdateAction(true)}>
              <GridRow>
                <GridCol constant size={12}>
                  <Field
                    name={NotificationFields.channel}
                    component={SelectInput}
                    label="Primary notification channel"
                    options={channelOptions}
                    boldLabel
                    onSelectChange={() => {
                      setUpdateAction(true);
                      if (values.channel?.value !== 'AUTO_ONLINE_FORM') {
                        setMsgValue('');
                      }
                    }}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={12}>
                  <Field name={NotificationFields.number} labelBold type="text" component={TextInput} label="Number" />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={NotificationFields.email}
                    labelBold
                    type="copy"
                    component={TextInput}
                    label="Email"
                    required
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={NotificationFields.formLink}
                    labelBold
                    type="link"
                    component={TextInput}
                    label="Form link"
                  />
                </GridCol>
              </GridRow>

              <Typography bolder tag="div" size="l" msg="Processing time" />
              <Row size={4} className={styles.gap16}>
                <input
                  name={NotificationFields.processingTime}
                  type="number"
                  className={styles.input}
                  autoComplete="off"
                  value={sla}
                  onChange={(e: any) => setSla(e.currentTarget.value)}
                />
                <Typography tag="div" size="l" msg="days" />
              </Row>
              {values.channel?.value === 'AUTO_ONLINE_FORM' && (
                <GridRow>
                  <GridCol size={12}>
                    <div className={styles.labelFont}>{t('label_enter_custom_message' as Messages)}</div>
                    <ReactQuill
                      ref={quillRef}
                      value={msgValue}
                      onChange={(val: any) => {
                        setMsgValue(val);
                        setUpdateAction(true);
                      }}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                          [{ list: 'ordered' }, { list: 'bullet' }],
                        ],
                      }}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                      placeholder="Write your comment here"
                      className={styles.quillArea}
                    />
                  </GridCol>
                </GridRow>
              )}
              <Row className={styles.mt28}>
                <Button
                  disabled={!isValid || !updateAction}
                  type={ButtonTypes.submit}
                  label="Save changes"
                  isFullWidth
                />
              </Row>
            </Form>
          )}
        </Formik>
      </div>

      <Typography msg="Service provider notes" bold size="xl" tag="div" />

      <Row className={styles.mt40}>
        <div style={{ backgroundColor: colorArray[0] }} className={styles.personContainer}>
          {user && (
            <Typography
              msg={`${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`}
              tag="span"
              color="white"
              bold
              size="lcx"
            />
          )}
        </div>
        <ReactQuill
          ref={quillRef}
          value={textValue}
          onChange={handleChange}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ],
          }}
          formats={['bold', 'italic', 'underline', 'list', 'bullet']}
          placeholder="Write your comment here"
          className={classNames(styles.quillArea, styles.quillHeight)}
        />
      </Row>

      <Row className={styles.mt60}>
        <Button label="Save comment" isFullWidth className={styles.buttonWidth} onClick={submitComment} />
      </Row>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {data?.serviceProviderNotes
            .sort((a: any, b: any) => {
              const aDate = new Date(a.commentedAt);
              const bDate = new Date(b.commentedAt);
              if (aDate > bDate) return 1;
              if (aDate < bDate) return -1;
              return 0;
            })
            .reverse()
            .map((nc: any, idx: number) => {
              return (
                <>
                  <Row key={idx} className={styles.commentsLayout}>
                    <div
                      style={{
                        backgroundColor:
                          user?.id === nc.commentedBy?.id
                            ? colorArray[0]
                            : getColor(nc.commentedBy ? nc.commentedBy.id : ''),
                      }}
                      className={styles.personContainer}
                    >
                      <Typography
                        msg={
                          nc.commentedBy
                            ? `${getOneChar(nc.commentedBy.firstName)}${getOneChar(nc.commentedBy.lastName)}`
                            : ''
                        }
                        tag="span"
                        color="white"
                        bold
                        size="lcx"
                      />
                    </div>
                    <Col>
                      <Row className={styles.nameDate}>
                        <Typography
                          msg={nc.commentedBy ? `${nc.commentedBy.firstName} ${nc.commentedBy.lastName}` : ''}
                          tag="div"
                          size="l"
                          bolder
                        />
                        <Typography msg={djangoDateFormat(nc.commentedAt)} tag="div" color="footerColor" size="m" />
                      </Row>
                      <div
                        className={styles.fs18}
                        //eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: nc.notes,
                        }}
                      />
                    </Col>
                  </Row>
                  {idx < data?.serviceProviderNotes.length - 1 && <div className={styles.separator} />}
                </>
              );
            })}
        </>
      )}
    </>
  );
};

export default SPInfoNotificationDetails;
