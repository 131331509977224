import classNames from 'classnames';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Typography from '@Components/Typography/Typography';

import styles from './Tooltip.scss';

export enum TooltipPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export interface TooltipProps {
  title?: string;
  message: string;
  position: TooltipPosition;
  className?: string;
  noMargin?: boolean;
  messageClassName?: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  className,
  title,
  message,
  position,
  noMargin = true,
  messageClassName,
}) => {
  return (
    <span className={classNames(noMargin ? styles.tooltipContainer : styles.tooltipContainerSpacing, className)}>
      {children}
      {message && (
        <div className={classNames(styles.tooltip, className, styles[position])}>
          <div className={styles.text}>
            {title && <Typography tag="div" size="s" bold msg={title} color="white" />}
            <p className={classNames(styles.tooltipContent, messageClassName)}>{ReactHtmlParser(message)}</p>
          </div>
        </div>
      )}
    </span>
  );
};

export default Tooltip;
