import * as React from 'react';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { EnterKeyCode } from '@Config/constants';
import { noop } from '@Utils/helpers';

import styles from './ConfirmationHeader.scss';

export interface ConfirmationHeaderProps {
  title: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showEdit?: boolean;
}

const ConfirmationHeader: React.FunctionComponent<ConfirmationHeaderProps> = ({ title, onClick = noop, showEdit }) => {
  return (
    <Row constant alignCenter justifyBetween size={12} className={styles.marginBottom20}>
      <Col>
        <Typography msg={title} tag="div" size="l" bolder />
      </Col>

      {showEdit && (
        <Col>
          <Row
            className={styles.flexGap}
            alignCenter
            constant
            onClick={onClick}
            tabIndex={0}
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                onClick(e);
              }
            }}
          >
            <Icon icon="button-edit" />
            <Typography msg="Edit" tag="div" size="mb" bold />
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default ConfirmationHeader;
