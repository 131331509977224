import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import Icon from '@Components/Icon';
import Typography from '@Components/Typography';
import { sendReferenceCode } from '@Store/provider/provider.actions';

import styles from './ProviderDCRefNumberForm.scss';

export interface ProviderDCRefNumberFormProps {
  accessCode: string;
  referenceCode: string;
}

enum ProviderDCRefNumberFormFields {
  referenceCode = 'referenceCode',
  finalBalance = 'finalBalance',
}

const ProviderDCRefNumberForm: React.FunctionComponent<ProviderDCRefNumberFormProps> = ({
  accessCode,
  referenceCode,
}) => {
  const dispatch = useDispatch();

  const [showTextBox, setShowTextBox] = React.useState(false);
  const [textRefCode, setTextRefCode] = React.useState('');
  const [actualRefCode, setActualRefCode] = React.useState(referenceCode);
  const [hasReferenceCode, setHasReferenceCode] = React.useState(referenceCode !== '');

  const showClickHere = () => {
    if (hasReferenceCode) {
      return false;
    }
    if (!showTextBox) {
      return true;
    }
    return false;
  };
  return (
    <div>
      <div className={styles.dCRefNumberFormWrapper}>
        <Typography
          msg={
            hasReferenceCode ? `Unique reference - ${actualRefCode}` : 'Do you want to add a unique reference number?'
          }
          tag="span"
          size="l"
          className={!hasReferenceCode ? styles.rightSpacing : styles.editSpacing}
        />
        {showClickHere() && (
          <div
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                setShowTextBox(!showTextBox);
              }
            }}
            onClick={() => setShowTextBox(!showTextBox)}
          >
            <Typography msg="Click here" tag="span" size="l" underline className={styles.handSymbol} tabIndex={0} />
          </div>
        )}
        {hasReferenceCode && (
          <span
            //eslint-disable-next-line
            tabIndex={0}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                setTextRefCode(actualRefCode);
                setShowTextBox(!showTextBox);
              }
            }}
            onClick={() => {
              setTextRefCode(actualRefCode);
              setShowTextBox(!showTextBox);
            }}
          >
            <Icon icon="edit" className={styles.iconSpacing} />
          </span>
        )}
        {showTextBox && (
          <>
            <input
              type="text"
              name={ProviderDCRefNumberFormFields.referenceCode}
              className={styles.textBoxStyle}
              value={textRefCode}
              onChange={(e: any) => setTextRefCode(e.currentTarget.value)}
            />
            {/* <span
              onClick={() =>
                dispatch(
                  sendReferenceCode.started({
                    accessCode,
                    setHasReferenceCode,
                    setShowTextBox,
                    setActualRefCode,
                    referenceCode: textRefCode,
                  })
                )
              }
              className={classNames(styles.saveButton, hasReferenceCode ? styles.updateText : styles.addtext)}
            >

              <Typography tabIndex={0} msg="Save" bold tag="span" size="m" />
            </span> */}
            <Button
              type={ButtonTypes.button}
              label="Save"
              className={styles.saveButton}
              size={ButtonSizes.s}
              onClick={() =>
                dispatch(
                  sendReferenceCode.started({
                    accessCode,
                    setHasReferenceCode,
                    setShowTextBox,
                    setActualRefCode,
                    referenceCode: textRefCode,
                  })
                )
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProviderDCRefNumberForm;
