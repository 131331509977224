import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@Components/Button';
import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { EnterKeyCode } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  usePackagesQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import DiscoveryComponent from '@Routes/dashboard/LifeCasesPage/LifeCasesContent/DiscoveryComponent';
import DiscoveryInfo from '@Routes/lifeCase/ReadyLCPage/DiscoveryInfo';
import NotSupportedBanks from '@Routes/shared/NotSupportedBanks/NotSupportedBanks';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './PaymentModalBody.scss';

export interface PaymentModalBodyProps {
  title: string;
  bodyMsg: string;
  baseCase: string;
  onClose?: () => void;
  hideBasic?: boolean;
}

const PaymentModalBody: React.FunctionComponent<ModalProviderProps<PaymentModalBodyProps>> = ({
  closeModal,
  modalData: { title, bodyMsg, baseCase, hideBasic },
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const stripe = useStripe();
  const [showDiscoveryWorks, setShowDiscoveryWorks] = React.useState(false);
  const [showMoreInfo, setShowMoreInfo] = React.useState(true);
  const [showBanksList, setShowBanksList] = React.useState(false);

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      closeModal();
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  if (packagesData.loading) {
    return <LoadingSpinner />;
  }

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  const discoveryPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'DISCOVERY');
  return (
    <>
      {!showDiscoveryWorks && !showBanksList && (
        <div className={styles.paddingBody}>
          <Typography msg={title} tag="h4" bold size="xl" />

          <Typography msg={bodyMsg} tag="div" className={classNames(styles.bottomSpacing, styles.lh164)} size="l" />
          <div className={styles.flexBox}>
            <DiscoveryComponent
              packagesData={packagesData}
              hideBasic={hideBasic}
              caseId={baseCase}
              showBanksCallback={() => setShowBanksList(!showBanksList)}
            />
          </div>

          <Typography
            textCenter
            className={styles.mt16}
            msg="Cancel at any time, effective at the end of the payment period"
            color="footerColor"
            tag="div"
            size="m"
          />

          {showMoreInfo && (
            <div
              className={styles.pb25}
              onClick={() => {
                setShowDiscoveryWorks(true);
                setShowMoreInfo(false);
              }}
              /* eslint-disable-next-line */
          tabIndex={0}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  setShowDiscoveryWorks(true);
                  setShowMoreInfo(false);
                }
              }}
            >
              <Typography
                textCenter
                className={classNames(styles.mt10, styles.lh24)}
                msg="More info about Life Ledger Discovery"
                color="electricBlue"
                tag="div"
                size="l"
                underline
                bold
              />
            </div>
          )}
        </div>
      )}
      {showDiscoveryWorks && !showBanksList && (
        <div className={styles.paddingBody}>
          <Row
            constant
            onClick={() => {
              setShowDiscoveryWorks(false);
              setShowMoreInfo(true);
            }}
            alignCenter
            className={styles.backButton}
            tabIndex={0}
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                setShowDiscoveryWorks(false);
                setShowMoreInfo(true);
              }
            }}
          >
            <Icon icon="chevronLeft" />
            <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
          </Row>
          <DiscoveryInfo />
          <Row constant className={styles.pb25}>
            {discoveryPackage?.unitAmount && (
              <Button
                onClick={() => {
                  stripeCreateDcSubscriptionMutation({
                    variables: { input: { priceId: discoveryPackage?.priceId, baseCase } },
                  });
                }}
                label="Subscribe now to Life Ledger Discovery"
                className={styles.mt40}
                isFullWidth
              />
            )}
          </Row>
        </div>
      )}
      {showBanksList && !showDiscoveryWorks && (
        <div className={styles.paddingBody}>
          <Row
            constant
            onClick={() => {
              setShowBanksList(false);
            }}
            alignCenter
            className={styles.backButton}
            tabIndex={0}
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                setShowBanksList(false);
              }
            }}
          >
            <Icon icon="chevronLeft" />
            <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
          </Row>
          <NotSupportedBanks />
        </div>
      )}
    </>
  );
};

export default PaymentModalBody;
