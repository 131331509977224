import { connect } from 'react-redux';

import GeneralFormAddress, {
  GeneralFormAddressProps,
  GeneralFormAddressStateProps,
} from '@Routes/lifeCase/LifeCasePage/GeneralFormAddress/GeneralFormAddress';
import { getLCData } from '@Store/lc/lc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getLCData(state),
});

export default connect<GeneralFormAddressStateProps, {}, GeneralFormAddressProps, RootState>(mapStateToProps)(
  GeneralFormAddress
);
