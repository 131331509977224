import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { NotificationsState, reducer as notificationsReducer } from 'react-notification-system-redux';
import { Action, combineReducers } from 'redux';

import appReducer from './app/app.reducer';
import authReducer from './auth/auth.reducer';
import dcReducer from './dc/dc.reducer';
import lcReducer from './lc/lc.reducer';
import localizationReducer from './localization/localization.reducer';

// We need to rewrite notification lib types, because it has wrong type definitions. Reducer function state var should accept NotificationsState or undefined, not only NotificationsState
type NotificationsReducer<A extends Action> = (state: NotificationsState | undefined, action: A) => NotificationsState;

const notifications: NotificationsReducer<any> = notificationsReducer as NotificationsReducer<any>;

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    localization: localizationReducer,
    auth: authReducer,
    app: appReducer,
    dc: dcReducer,
    lc: lcReducer,
    notifications,
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
