import classNames from 'classnames';
import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon/Icon';
import Row from '@Components/layout/Row';
import Typography, { TextSizes } from '@Components/Typography';

import styles from './Button.scss';

export interface ButtonProps {
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: ButtonTypes;
  style?: ButtonStyles;
  size?: ButtonSizes;
  label?: string;
  labelSize?: TextSizes;
  className?: string;
  icon?: string;
  iconSize?: IconSizes;
  constant?: boolean;
  id?: string;
  isFullWidth?: boolean;
  autoFocus?: boolean;
}

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum ButtonSizes {
  xs = 'xsmall',
  ss = 'ss',
  s = 'small',
  m = 'medium',
  l = 'large',
  xl = 'xlarge',
  fill = 'fill',
  send = 'send',
}

export enum ButtonStyles {
  outlined = 'outlined',
  plain = 'plain',
  success = 'success',
  danger = 'danger',
  white = 'white',
  black = 'black',
  neutral = 'neutral',
  transparent = 'transparent',
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    onClick,
    disabled = false,
    loading = false,
    children = null,
    label = null,
    type = ButtonTypes.button,
    size = ButtonSizes.m,
    iconSize = IconSizes.sss,
    style,
    className,
    icon,
    constant,
    id,
    isFullWidth,
    autoFocus = false,
    labelSize,
  } = props;
  // ToDo: disabled has no effect
  return (
    <button
      autoFocus={autoFocus}
      onClick={onClick}
      type={type}
      disabled={disabled}
      name={label?.toLowerCase().replace(' ', '_')}
      id={id}
      className={classNames(styles.button, className, {
        [styles[`${style}` as ButtonStyles]]: style,
        [styles.disabled]: disabled,
        [styles[`${size}` as ButtonSizes]]: size,
        [styles.isFullWidth]: isFullWidth,
      })}
    >
      {loading && <Icon icon="spinner" spin={true} size={IconSizes.ss} className={styles.spinner} />}
      <Row constant={constant} alignCenter>
        {icon && !loading && <Icon icon={icon} className={styles.iconStyling} size={iconSize} />}
        <span>
          {label && !loading && <Typography msg={label} tag="span" html size={labelSize} />}
          {children}
        </span>
      </Row>
    </button>
  );
};

export default Button;
