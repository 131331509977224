import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import EmailSentPage from '@Routes/auth/EmailSentPage';
import { forgotPassword } from '@Store/auth/auth.actions';
import { validateEmail } from '@Utils/validator';

import styles from './ForgotPassword.scss';

export interface ForgotPasswordFormProps {
  emailSentTo: string;
  setEmailSentTo: Dispatch<SetStateAction<string>>;
}

export interface ForgotPasswordFormValues {
  email: string;
}

enum ForgotPasswordFormFields {
  email = 'email',
}

const ForgotPasswordForm: React.FunctionComponent<ForgotPasswordFormProps> = ({ emailSentTo, setEmailSentTo }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const EmailInputForm = ({ isSubmitting, status }: FormikProps<ForgotPasswordFormValues>) => (
    <Form>
      <Typography msg={t(Messages.subtitlePasswordReset)} size="l" tag="div" />
      <Row className={styles.topSpacing} size={12}>
        <Field
          name={ForgotPasswordFormFields.email}
          type="text"
          component={TextInput}
          label={t(Messages.fieldEmail)}
          fullWidth
          placeholder={t('field_forgot_password_email' as Messages)}
        />
      </Row>
      <FormError formError={status} />
      <Row alignCenter justifyCenter>
        <Button
          type={ButtonTypes.submit}
          disabled={isSubmitting}
          loading={isSubmitting}
          label={t(Messages.buttonRecoveryEmail)}
          className={styles.buttonTopSpacing}
          isFullWidth
        />
      </Row>
    </Form>
  );

  const EmailSentComponent = ({ setStatus }: FormikProps<ForgotPasswordFormValues>) => (
    <EmailSentPage email={emailSentTo} setStatus={setStatus} />
  );

  return (
    <Formik
      initialValues={{
        [ForgotPasswordFormFields.email]: '',
      }}
      validationSchema={Yup.object({
        [ForgotPasswordFormFields.email]: validateEmail(t),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        dispatch(forgotPassword.started({ setSubmitting, setStatus, setEmailSentTo, email: values.email.trim() }));
      }}
    >
      {emailSentTo ? EmailSentComponent : EmailInputForm}
    </Formik>
  );
};

export default ForgotPasswordForm;
