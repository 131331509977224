import classNames from 'classnames';
import * as React from 'react';

import { noop } from '@Utils/helpers';

import styles from './Stepper.scss';

export interface StepperProps {
  currentIndex: number;
  className?: string;
  color?: string;
  total: number;
  onClick?: (selectedNumber: number) => void;
  disableClick?: boolean;
  onKeyDown?: (e: any, selectedNumber: number) => void;
}

const Stepper: React.FunctionComponent<StepperProps> = ({
  currentIndex,
  total,
  className = '',
  onClick = noop,
  disableClick = false,
  onKeyDown = noop,
}) => {
  const arr = Array.from(Array(total).keys());
  return (
    <div className={classNames(styles.stepperWrapper)}>
      <div className={classNames(styles.stepsContainer, className)}>
        {arr.map((item) => (
          <span
            /* eslint-disable-next-line */
            tabIndex={0}
            onClick={() => onClick(item)}
            onKeyDown={(e: any) => onKeyDown(e, item)}
            key={item}
            className={classNames(
              styles.step,
              { [styles.handSymbol]: onClick !== noop },
              item <= currentIndex ? styles.done : styles.pending,
              disableClick ? '' : styles.handSymbol
            )}
          />
        ))}
      </div>
      <div className={classNames(styles.stepperDescription)}>{`Steps ${currentIndex + 1} of ${total}`}</div>
    </div>
  );
};

export default Stepper;
