import classNames from 'classnames';
import React from 'react';
import NumberFormat from 'react-number-format';

import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';

import styles from './ReportKpiBox.scss';

export interface ReportKpiBoxProps {
  icon?: string;
  kpiHeader: string;
  kpiValue: number;
  kpiMetric?: string;
}

const ReportKpiBox: React.FunctionComponent<ReportKpiBoxProps> = ({ icon, kpiHeader, kpiValue, kpiMetric }) => {
  return (
    <div className={styles.kpiContainer}>
      <Row alignCenter justifyCenter className={styles.statusContainer}>
        {icon && <Icon className={styles.rightSpacing} size={IconSizes.s24} icon={icon} />}
        <Typography textCenter={icon === undefined} size="l" tag="h5" msg={kpiHeader} bold />
      </Row>
      <Row justifyCenter>
        <NumberFormat
          value={kpiValue}
          className={classNames(styles.kpiValueClass, { [styles.boxBottomSpacing]: kpiMetric === undefined })}
          thousandSeparator={true}
          displayType="text"
        />
      </Row>
      {kpiMetric && <Typography color="dividerColor" textCenter={true} size="l" tag="h5" msg={kpiMetric} bold />}
    </div>
  );
};

export default ReportKpiBox;
