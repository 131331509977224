import { ExecutionResult } from 'apollo-link';

import { queryGetUser, queryYotiInfo } from '@Graphql/auth/queries';
import ApolloClient from '@Graphql/graphqlClient';
import { GetMeQuery, GetMeQueryVariables, GetYotiQuery, GetYotiQueryVariables } from '@Graphql/graphqlTypes.generated';

export const getUserQuery = async () => {
  const result: ExecutionResult<GetMeQuery> = await ApolloClient.query<GetMeQuery, GetMeQueryVariables>({
    query: queryGetUser,
    variables: {},
    fetchPolicy: 'network-only',
  });
  return result.data?.me;
};

export const getYotiQuery = async () => {
  const result: ExecutionResult<GetYotiQuery> = await ApolloClient.query<GetYotiQuery, GetYotiQueryVariables>({
    query: queryYotiInfo,
    variables: {},
    fetchPolicy: 'network-only',
  });
  return result.data?.me;
};
