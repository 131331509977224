import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import CreateLifeCase from '@Routes/lifeCase/CreateLifeCase';
import EditLifeCase from '@Routes/lifeCase/EditLifeCase';
import ReadyLCPage from '@Routes/lifeCase/ReadyLCPage';

import { RouteInfo } from '../appRoutes';

const routes: RouteInfo[] = [
  {
    path: Links.lcCreate,
    component: CreateLifeCase,
    title: Messages.pageCreateLifeCase,
    isServiceProviderRoute: false,
  },
  {
    path: Links.lcEdit,
    component: EditLifeCase,
    title: Messages.pageCreateLifeCase,
    isServiceProviderRoute: false,
  },
  {
    path: Links.lcReady,
    component: ReadyLCPage,
    title: Messages.pageLCServices,
    isServiceProviderRoute: false,
  },
];

export default routes;
