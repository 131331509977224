import classNames from 'classnames';
import React from 'react';

import styles from './PaperBody.scss';

export enum PaperBodySpacing {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  none = 'none',
}

export enum PaperBodyOverflow {
  auto = 'auto',
  hidden = 'hidden',
}

type PaperBodySpacingStyles = 'spacing-s' | 'spacing-m' | 'spacing-l' | 'spacing-none';
type PaperBodyOverflowStyles = 'overflow-auto' | 'overflow-hidden';

interface PaperBodyProps {
  spacing?: keyof typeof PaperBodySpacing;
  overflow?: keyof typeof PaperBodyOverflow;
  className?: string;
}
const PaperBody: React.FunctionComponent<PaperBodyProps> = ({ children, spacing, overflow, className }) => {
  React.useEffect(() => {
    const nextElement = document.querySelector(`#emptyDiv`) as HTMLElement;
    const currentElement = document.querySelector(`#modalDiv`) as HTMLElement;
    currentElement.scrollTop = 0;
    return nextElement.focus();
  });

  return (
    <div
      id="modalDiv"
      className={classNames(
        styles.paperBody,
        spacing && styles[`spacing-${spacing}` as PaperBodySpacingStyles],
        overflow && styles[`overflow-${overflow}` as PaperBodyOverflowStyles],
        className
      )}
    >
      {children}
    </div>
  );
};
export default PaperBody;
