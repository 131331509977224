import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import Button from '@Components/Button';
import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './AddCompaniesForm.scss';

const AddCompaniesForm: React.FunctionComponent = () => {
  const history = useHistory();
  const t = useTranslations();

  return (
    <div className={styles.bodyContainer}>
      <Typography msg={t('msg_life_event_open_bank' as Messages)} size="l" tag="div" />
      <div className={styles.containers}>
        <Row constant justifyCenter className={styles.titleIconGap} alignCenter>
          <Icon icon="flash" />
          <Typography tag="div" size="l" bold msg={t('title_connect_your_bank_account' as Messages)} />
        </Row>

        <Row justifyCenter>
          <Col size={10}>
            <Typography
              color="footerColor"
              tag="div"
              size="l"
              msg={t('subtitle_connect_your_bank_account' as Messages)}
            />
          </Col>
        </Row>

        <Button
          onClick={() => {
            history.push(generatePath(Links.institutions, { id: '' }));
          }}
          className={styles.buttonSpacing}
          label={t('button_connect_bank_account' as Messages)}
          isFullWidth
        />
        <Row justifyCenter>
          <Typography tag="div" color="footerColor" size="m" msg="You will be redirected to  connect your account" />
        </Row>
      </div>
      <div className={styles.manualButton} onClick={() => {}}>
        <Typography
          msg="I want to continue manually"
          tag="div"
          size="l"
          align="center"
          className={styles.textHeight}
          bold
        />
      </div>
      <div className={styles.link}>
        <a href="/">
          <Typography msg="More info about connecting your bank" tag="div" size="l" align="center" />
        </a>
      </div>
    </div>
  );
};

export default AddCompaniesForm;
