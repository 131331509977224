import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import FileInput from '@Components/form/inputs/FileInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { NodeDefiner } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DocumentDocumentType, LifeCaseNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { updateDC, uploadDocuments } from '@Store/dc/dc.actions';
// import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { formatDateForExchange, normalizeDate } from '@Utils/dates';
import { manipulateTranslations } from '@Utils/helpers';
import { deleteConfirmationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';
import { validateNotCurrentDay } from '@Utils/validator';

import styles from './ProbateForm.scss';

export interface ProbateFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
  isUserProfessional?: boolean;
}

export interface ProbateFormStateProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
}

interface ProbateFormPrivateProps extends ProbateFormProps, ProbateFormStateProps {}

enum ProbateFormFields {
  hasWill = 'hasWill',

  hasAdministrator = 'hasAdministrator',
  meAdministrator = 'meAdministrator',
  adminFirstName = 'adminFirstName',
  adminLastName = 'adminLastName',
  adminEmail = 'adminEmail',
  adminTelephone = 'adminTelephone',

  hasRepresentationLetter = 'hasRepresentationLetter',
  representationLetterDate = 'representationLetterDate',
  isProfessional = 'isProfessional',
}

type AdminCollaboratorFields = Extract<
  ProbateFormFields,
  | ProbateFormFields.adminFirstName
  | ProbateFormFields.adminLastName
  | ProbateFormFields.adminEmail
  | ProbateFormFields.adminTelephone
>;

export interface AdminFormValues {
  [ProbateFormFields.adminFirstName]: string;
  [ProbateFormFields.adminLastName]: string;
  [ProbateFormFields.adminEmail]: string;
  [ProbateFormFields.adminTelephone]: string;
  [ProbateFormFields.hasAdministrator]: boolean;
  [ProbateFormFields.meAdministrator]: boolean;
  [ProbateFormFields.hasWill]: boolean;
}

const emptyFormValues = {
  [ProbateFormFields.adminFirstName]: '',
  [ProbateFormFields.adminLastName]: '',
  [ProbateFormFields.adminEmail]: '',
  [ProbateFormFields.adminTelephone]: '',
};

const ProbateForm: React.FunctionComponent<ProbateFormPrivateProps> = ({
  data,
  back,
  next,
  setSubpage,
  containerClasses,
  isUserProfessional,
}) => {
  // const currentLanguage = useSelector(getCurrentLanguage);
  const t = useTranslations();
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const userDataValues = {
    [ProbateFormFields.adminFirstName]: user?.firstName,
    [ProbateFormFields.adminLastName]: user?.lastName,
    [ProbateFormFields.adminEmail]: user?.email,
    [ProbateFormFields.adminTelephone]: user?.phone,
  };

  const representationLettersFiles =
    data?.item?.__typename === NodeDefiner.DeathCase ? data?.item?.representationLetters : [];

  //const powerOfAttorneyFiles = data?.item?.__typename === NodeDefiner.DeathCase ? data?.item?.powerOfAttorneys : [];

  const currentLanguage = useSelector(getCurrentLanguage);

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const removeLetterHandler = deleteConfirmationHandler(t, dispatch, DocumentDocumentType.RepresentationLetter);

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm, values } = useFormikContext();
    const { hasAdministrator, meAdministrator, hasWill } = values as AdminFormValues;

    React.useEffect(() => {
      if (hasWill) {
        setFieldValue(ProbateFormFields.hasAdministrator, false);
        setFieldValue(ProbateFormFields.meAdministrator, false);
      }
      const getFieldValue = (fieldName: AdminCollaboratorFields) => {
        if (!hasAdministrator) {
          return emptyFormValues[fieldName];
        }
        return meAdministrator ? userDataValues[fieldName] : emptyFormValues[fieldName];
      };
      const toggleExecutorFormField = (fieldName: AdminCollaboratorFields) => {
        setFieldValue(fieldName, getFieldValue(fieldName), false);
        setFieldTouched(fieldName, false, false);
      };
      toggleExecutorFormField(ProbateFormFields.adminFirstName);
      toggleExecutorFormField(ProbateFormFields.adminLastName);
      toggleExecutorFormField(ProbateFormFields.adminEmail);
      toggleExecutorFormField(ProbateFormFields.adminTelephone);
    }, [setFieldValue, setFieldTouched, resetForm, hasAdministrator, meAdministrator, hasWill]);

    return null;
  };

  // const legalApplicable = !(isUserProfessional && user?.professionalType === UserProfessionalType.Solicitor);

  const setNextStep = () => (next ? setSubpage(next) : {});

  const field_will_yes_has_probate_question = manipulateTranslations(
    t,
    'field_will_yes_has_probate_question',
    'Has a grant of probate been issued?'
  );

  const field_will_no_has_probate_question = manipulateTranslations(
    t,
    'field_will_no_has_probate_question',
    'Has a grant of letters of administration been issued?'
  );

  const tooltip_desc_will_yes_grant_of_probate = manipulateTranslations(
    t,
    'tooltip_desc_will_yes_grant_of_probate',
    ' A grant of probate is issued when a person has left a will naming an executor (executrix if female) who proves the will through the probate court.<br/><br/>This gives legal authority to prove who can administer the estate, undertaking tasks such as signing sale contracts or transferring documents for property, closing bank accounts and selling shares.'
  );

  const tooltip_desc_will_no_grant_of_probate = manipulateTranslations(
    t,
    'tooltip_desc_will_no_grant_of_probate',
    'A grant of letters of administration is issued when a person has not left a will and the person entitled under the rules of intestacy seeks authority to administer the estate.<br/><br/>This gives legal authority to prove who can administer the estate, undertaking tasks such as signing sale contracts or transferring documents for property, closing bank accounts and selling shares.'
  );

  const tooltip_title_will_yes_grant_of_probate = manipulateTranslations(
    t,
    'tooltip_title_will_yes_grant_of_probate',
    'What is a grant of probate?'
  );

  const tooltip_title_will_no_grant_of_probate = manipulateTranslations(
    t,
    'tooltip_title_will_no_grant_of_probate',
    'What is a grant of letters of representation?'
  );

  const field_will_yes_probate_date = manipulateTranslations(
    t,
    'field_will_yes_probate_date',
    'If you do not yet have a grant of probate, but have applied for one, please  provide the date it was applied for'
  );

  const field_will_no_probate_date = manipulateTranslations(
    t,
    'field_will_no_probate_date',
    'If you do not yet have a grant of letters of administration, but have applied for one, please  provide the date it was applied for'
  );

  return (
    <>
      <Row className={classNames(styles.notesBottomSpacing, styles.pageWidth)}>
        <CautionBox
          containerClassName={styles.cautionBox}
          info={manipulateTranslations(
            t,
            'label_caution_dc_probate',
            'Completing this section is not essential to send notifications. However, we recommend finishing it as banks use this information to distribute funds accordingly'
          )}
          title={t(Messages.labelTip)}
        />
      </Row>
      <Formik
        initialValues={{
          [ProbateFormFields.hasWill]: data.item?.hasWill || false,

          [ProbateFormFields.hasAdministrator]: false,
          [ProbateFormFields.meAdministrator]: false,
          [ProbateFormFields.adminFirstName]: '',
          [ProbateFormFields.adminLastName]: '',
          [ProbateFormFields.adminEmail]: '',
          [ProbateFormFields.adminTelephone]: '',

          [ProbateFormFields.hasRepresentationLetter]: !!representationLettersFiles.length,
          [ProbateFormFields.representationLetterDate]:
            data?.item?.__typename === NodeDefiner.DeathCase ? data.item?.representationLetterDate : undefined,
          [ProbateFormFields.isProfessional]: isUserProfessional,
        }}
        validationSchema={() =>
          Yup.lazy(() => {
            return Yup.object().shape({
              [ProbateFormFields.representationLetterDate]: validateNotCurrentDay(t),
            });
          })
        }
        onSubmit={(values, { setSubmitting, setStatus }) => {
          if (data?.item?.__typename === NodeDefiner.DeathCase) {
            dispatch(
              updateDC.started({
                setSubmitting,
                setStatus,
                setNextStep,
                id,
                hasWill: values.hasWill,
                representationLetterDate: formatDateForExchange(values.representationLetterDate),
              })
            );
          } else {
            setNextStep();
          }
        }}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={styles.pageWidth}>
            <div className={containerClasses}>
              <>
                <Row justifyBetween>
                  <Col size={12}>
                    <Field
                      name={ProbateFormFields.hasRepresentationLetter}
                      label={
                        data.item?.hasWill ? field_will_yes_has_probate_question : field_will_no_has_probate_question
                      }
                      component={ToggleSwitchInput}
                      disabled={!!representationLettersFiles.length}
                      wrapWhiteSpace
                      infoMessage={
                        data.item?.hasWill
                          ? tooltip_desc_will_yes_grant_of_probate
                          : tooltip_desc_will_no_grant_of_probate
                      }
                      infoTitle={
                        data.item?.hasWill
                          ? tooltip_title_will_yes_grant_of_probate
                          : tooltip_title_will_no_grant_of_probate
                      }
                    />
                  </Col>
                </Row>

                {values.hasRepresentationLetter && (
                  <>
                    <Row justifyBetween>
                      <Col size={12}>
                        <Typography tag="div" msg="If yes, please upload the document" size="l" />
                      </Col>
                    </Row>
                    <div>
                      <Col size={12} tabindex={0}>
                        <FileInput
                          onDrop={
                            (files: File[]) =>
                              dispatch(
                                uploadDocuments.started({
                                  baseCaseId: id,
                                  documentType: DocumentDocumentType.RepresentationLetter,
                                  files,
                                })
                              )
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          onRemoveClick={removeLetterHandler}
                          files={representationLettersFiles}
                        />
                      </Col>
                    </div>
                  </>
                )}

                <Row justifyBetween>
                  <Col size={11}>
                    <Field
                      name={ProbateFormFields.representationLetterDate}
                      type="text"
                      component={DatePickerInput}
                      parse={normalizeDate(currentLanguage)}
                      label={data.item?.hasWill ? field_will_yes_probate_date : field_will_no_probate_date}
                    />
                  </Col>
                </Row>
                {/* {values.isProfessional && (
                  <>
                    <Row justifyBetween>
                      <Col size={12}>
                        <Typography tag="span" msg={t(Messages.labelPowerOfAttorney)} />
                      </Col>
                    </Row>
                    <Row justifyBetween>
                      <Col size={12}>
                        <FileInput
                          onDrop={
                            (files: File[]) =>
                              dispatch(
                                uploadDocuments.started({
                                  baseCaseId: id,
                                  documentType: DocumentDocumentType.PowerOfAttorney,
                                  files,
                                })
                              )
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          onRemoveClick={removePowerOfAttorneyHandler}
                          files={powerOfAttorneyFiles}
                        />
                      </Col>
                    </Row>
                  </>
                )} */}
              </>
            </div>
            <AutoToggleForm />
            <FormError formError={status} />
            <NavigationBlock isSubmitting={isSubmitting} back={back} next={next} setSubpage={setSubpage} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProbateForm;
