import { connect } from 'react-redux';

import AdditionalInfoForm, {
  AdditionalInfoFormProps,
  AdditionalInfoFormStateProps,
} from '@Routes/lifeCase/LifeCasePage/AdditionalInfoForm/AdditionalInfoForm';
import { getLCData } from '@Store/lc/lc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getLCData(state),
});

export default connect<AdditionalInfoFormStateProps, {}, AdditionalInfoFormProps, RootState>(mapStateToProps)(
  AdditionalInfoForm
);
