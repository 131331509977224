//import { useMutation } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import EditRequiredFields from '@Components/application/EditRequiredFields';
import Button, { ButtonSizes, ButtonStyles } from '@Components/Button';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import FileInput from '@Components/form/inputs/FileInput';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import DataDefinitionModalBody from '@Components/Modal/DataDefinitionModalBody';
import {
  ProviderBankFieldLabels,
  ProviderFormFields,
} from '@Components/Modal/ProviderFormModalBody/ProviderFormModalBody';
import providerFormStyles from '@Components/Modal/ProviderFormModalBody/ProviderFormModalBody.scss';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { emptySelectorValue, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  AccountDataDefinitionInputType,
  AccountDataDefinitionNode,
  ServiceProviderIdentifierType,
  UpdateDataDefinitionMutation,
  UpdateDataDefinitionMutationVariables,
  useServiceProviderDataFieldsLazyQuery,
  useServiceProviderLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpdateDataDefinition } from '@Graphql/serviceProvidersPortal/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { removeDataDefinition } from '@Store/provider/provider.actions';
// import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
// import { notificationError } from '@Utils/notificationUtils';
import {
  validateAny,
  validateBankAccount,
  validateEmail,
  validatePhone,
  validateRequired,
  validateSortCode,
} from '@Utils/validator';

import styles from '../DataDefinitionsPage.scss';

export interface DataDefinitionsFormProps {
  data: any;
}

const DataDefinitionsForm: React.FunctionComponent<DataDefinitionsFormProps> = ({ data }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const serviceProviderId = user?.serviceProvider || '';
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const [isFormVisible, setFormVisible] = React.useState(false);
  const [isAddField, setIsAddField] = React.useState(false);
  const dragItem = React.useRef();
  const dragOverItem = React.useRef();

  const mapDataForDataTable = (dataForTable: any) => {
    if (dataForTable) {
      return dataForTable.map((item: AccountDataDefinitionNode) => {
        return {
          inputLabel: item.inputLabel,
          inputType: item.inputType,
          inputValues: item.inputValues.join(', '),
          isPrimaryIdentifier: item.isPrimaryIdentifier ? t(Messages.labelYes) : t(Messages.labelNo),
          isMandatory: item.isMandatory ? t(Messages.labelYes) : t(Messages.labelNo),
          id: item.id,
          order: item.displayOrder,
        };
      });
    }
    return [];
  };

  const [list, setList] = React.useState(mapDataForDataTable(data));
  const [dragEnterIdx, setDragEnterIdx] = React.useState(1000);

  const [updateDataDefinition] = useMutation<UpdateDataDefinitionMutation, UpdateDataDefinitionMutationVariables>(
    mutationUpdateDataDefinition
  );

  const [getServiceProvider, serviceProviderValues] = useServiceProviderLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getAccountDataDefinitions, accountDataDefinitionsValues] = useServiceProviderDataFieldsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  React.useEffect(() => {
    if (modalIsOpen) {
      getServiceProvider({ variables: { id: serviceProviderId } });
      getAccountDataDefinitions({ variables: { serviceProvider: serviceProviderId } });
    }
  }, [serviceProviderId, modalIsOpen, getAccountDataDefinitions, getServiceProvider]);

  const lastDataSettingsOrder = Math.max(...list.map((item: any) => item.order));

  const deleteHandler = (id: any) => {
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () =>
              dispatch(
                removeDataDefinition.started({
                  id,
                  successMessage: t(Messages.msgDeleteDataDefinition),
                })
              ),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.modalRemoveDataDefinition),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '750px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#F3F3F3',
      width: '33%',
    },
    overlay: {
      border: 'unset',
      borderRadius: '6px',
      background: 'rgba(0, 0, 0, 0.2)',
    },
  };

  interface LooseObject {
    [key: string]: any;
  }

  const serviceProviderInputType = serviceProviderValues.data?.serviceProvider?.identifierType;

  const validatePrimaryAccountIdentifier = (): Yup.Schema<string> => {
    if (serviceProviderInputType === ServiceProviderIdentifierType.Email) {
      return validateEmail(t);
    }
    if (serviceProviderInputType === ServiceProviderIdentifierType.Phone) {
      return validatePhone(t);
    }
    if (serviceProviderInputType === ServiceProviderIdentifierType.Bank) {
      return validateBankAccount(t);
    }
    return validateRequired(t);
  };

  let initialValues: LooseObject = {};
  initialValues = {
    [ProviderFormFields.category]: emptySelectorValue,
    [ProviderFormFields.subCategory]: emptySelectorValue,
    [ProviderFormFields.provider]: emptySelectorValue,
    [ProviderFormFields.allProvider]: '',
    [ProviderFormFields.accountIdentifierPrimary]: '',
    [ProviderFormFields.accountIdentifierSecondary]: '',
  };

  let schemaObj: LooseObject = {};
  schemaObj = {
    [ProviderFormFields.accountIdentifierPrimary]:
      serviceProviderInputType && serviceProviderInputType !== ServiceProviderIdentifierType.Custom
        ? validatePrimaryAccountIdentifier()
        : validateAny(),
    [ProviderFormFields.accountIdentifierSecondary]:
      serviceProviderInputType === ServiceProviderIdentifierType.Bank ? validateSortCode(t) : validateAny(),
  };

  const newSchema = Yup.object(schemaObj);

  const getExtraControls = () => {
    return accountDataDefinitionsValues.data?.serviceProviderDataFields
      ? accountDataDefinitionsValues.data?.serviceProviderDataFields.sort(
          (a: any, b: any) =>
            a.displayOrder - b.displayOrder ||
            b.isPrimaryIdentifier - a.isPrimaryIdentifier ||
            b.isMandatory - a.isMandatory
        )
      : [];
  };

  const sortedProviderControls = getExtraControls();

  function getControl(fieldType: string) {
    let controlName = null;

    switch (fieldType) {
      case AccountDataDefinitionInputType.Textbox:
      case AccountDataDefinitionInputType.Textarea:
        controlName = TextInput;
        break;
      case AccountDataDefinitionInputType.Dropdown:
        controlName = SelectInput;
        break;
      case AccountDataDefinitionInputType.Date:
        controlName = DatePickerInput;
        break;
      case AccountDataDefinitionInputType.Checkbox:
      case AccountDataDefinitionInputType.Radiobutton:
        controlName = Checkbox;
        break;
      default:
        break;
    }
    return controlName;
  }

  function getControlType(fieldType: string) {
    let controlType = null;

    switch (fieldType) {
      case AccountDataDefinitionInputType.Textbox:
        controlType = 'text';
        break;
      case AccountDataDefinitionInputType.Checkbox:
        controlType = CheckBoxTypes.checkbox;
        break;
      case AccountDataDefinitionInputType.Textarea:
        controlType = 'textarea';
        break;
      case AccountDataDefinitionInputType.Radiobutton:
        controlType = CheckBoxTypes.radio;
        break;
      default:
        break;
    }
    return controlType;
  }

  const dataForDynamicSelect = (queryData: any) =>
    queryData?.map((item: any) => {
      return {
        value: item,
        label: item,
      };
    });

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    setDragEnterIdx(position);
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...list];
    const dragItemContent = dragItem.current !== undefined ? copyListItems[dragItem.current] : null;
    if (dragItem.current !== undefined) {
      copyListItems.splice(dragItem.current, 1);
    }
    if (dragOverItem.current !== undefined) {
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    }
    dragItem.current = undefined;
    dragOverItem.current = undefined;
    setDragEnterIdx(1000);
    setList(copyListItems);
    copyListItems.forEach((cli: any, idx: number) => {
      updateDataDefinition({
        variables: {
          input: {
            id: cli.id,
            displayOrder: idx + 1,
            serviceProvider: user?.serviceProvider,
          },
        },
      });
    });
  };

  return (
    <div style={{ marginBottom: '15px', marginTop: '20px' }}>
      {!isFormVisible && (
        <Row size={isTablet ? 10 : 8} justifyCenter className={styles.addCompanyBlock}>
          <Button
            onClick={() => {
              setIsAddField(true);
              setFormVisible(true);
            }}
            icon={!isTablet ? 'plus' : undefined}
            className={styles.buttonDataField}
            iconSize={IconSizes.ss}
            isFullWidth
            constant
          >
            <Typography msg={t(Messages.buttonAddDataDefinition)} tag="span" />
          </Button>
        </Row>
      )}

      <Typography tag="div" size="l" bolder msg={t('msg_title_settings_subitem_requiredfields' as Messages)} />

      {!isFormVisible && (
        <>
          <Typography
            tag="div"
            size="l"
            msg={
              list.length > 0
                ? t('label_required_fields_help_text' as Messages)
                : t('label_add_new_field_helper' as Messages)
            }
          />

          {list.map((x: any, idx: number) => (
            <Row key={idx} size={12}>
              <Col size={11}>
                <Row column>
                  {dragEnterIdx === idx && <Col className={styles.blueStrip} />}
                  <Col
                    isDraggable
                    onDragStart={dragStart}
                    onDragEnter={dragEnter}
                    onDragEnd={drop}
                    idx={idx}
                    className={styles.requiredFieldContainer}
                  >
                    <Row justifyBetween>
                      <Col>
                        <Typography
                          size="m"
                          bold
                          tag="div"
                          msg={`${t('title_required_field_card' as Messages)} ${idx + 1}`}
                        />
                        <Typography size="l" tag="div" msg={x.inputLabel} />
                      </Col>
                      <Col alignCenter>
                        <Icon icon="more-vertical" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col size={1} alignCenter textCenter>
                <div onClick={() => deleteHandler(x.id)}>
                  <Icon icon="trash" className={styles.handSymbol} />
                </div>
              </Col>
            </Row>
          ))}

          {list.length > 0 && (
            <>
              <Button
                className={styles.topSpacing}
                style={ButtonStyles.transparent}
                onClick={() => setFormVisible(true)}
                iconSize={IconSizes.ss}
                icon="edit"
                constant
                label={t(Messages.buttonEdit)}
              />

              <Typography
                className={styles.previousSpacing}
                tag="div"
                size="l"
                bold
                msg={t('button_preview' as Messages)}
              />
              <Row size={9}>
                <Typography tag="div" size="l" msg={t('label_preview_help_text' as Messages)} />
              </Row>

              <Button
                className={styles.topSpacing}
                style={ButtonStyles.transparent}
                onClick={() => setIsOpen(true)}
                constant
                label={t('button_preview' as Messages)}
              />
            </>
          )}
        </>
      )}

      {isFormVisible && !isAddField && (
        <div className={styles.containersWidth}>
          <EditRequiredFields dataDefinitions={data} closeEdit={() => setFormVisible(false)} />
        </div>
      )}

      {isAddField && (
        <div className={styles.containersWidth}>
          <DataDefinitionModalBody
            serviceProviderId={serviceProviderId}
            closeForm={() => {
              setFormVisible(false);
              setIsAddField(false);
            }}
            newOrder={lastDataSettingsOrder + 1}
          />
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
      >
        <Row justifyBetween>
          <Typography tag="div" size="m" bold msg={t('button_preview' as Messages)} />
          <Icon className={styles.closeIcon} icon="close" onClick={() => setIsOpen(false)} size={IconSizes.sss} />
        </Row>

        <Typography tag="div" size="m" msg={t('label_preview_pop_up_helper' as Messages)} />

        <div className={styles.previewContainer}>
          <Typography tag="h4" size="xl" bold msg={t('title_account_details' as Messages)} />

          <div className={styles.logoWrapper}>
            <img width="62px" height="62px" src={serviceProviderValues.data?.serviceProvider.logo} alt="" />
            <Typography
              bold
              tag="h5"
              size="l"
              msg={serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''}
              className={classNames(styles.leftSpacing)}
            />
          </div>
          <Formik initialValues={initialValues} validationSchema={newSchema} onSubmit={() => {}}>
            {({ status }) => {
              return (
                <Form className={providerFormStyles.formContainer}>
                  {serviceProviderInputType && (
                    <>
                      {serviceProviderInputType !== ServiceProviderIdentifierType.Custom && (
                        <>
                          <GridRow>
                            <GridCol size={12}>
                              <Field
                                name={ProviderFormFields.accountIdentifierPrimary}
                                type="text"
                                component={TextInput}
                                label={t(
                                  `${Messages.fieldProviderPrefix}${(serviceProviderInputType !==
                                  ServiceProviderIdentifierType.Bank
                                    ? serviceProviderInputType
                                    : ProviderBankFieldLabels.accountNumber
                                  ).toLowerCase()}` as Messages
                                )}
                                //required={}
                              />
                            </GridCol>
                          </GridRow>

                          {serviceProviderInputType === ServiceProviderIdentifierType.Bank && (
                            <GridRow>
                              <GridCol size={12}>
                                <Field
                                  name={ProviderFormFields.accountIdentifierSecondary}
                                  type="text"
                                  component={TextInput}
                                  label={t(
                                    `${Messages.fieldProviderPrefix}${(serviceProviderInputType !==
                                    ServiceProviderIdentifierType.Bank
                                      ? serviceProviderInputType
                                      : ProviderBankFieldLabels.sortCode
                                    ).toLowerCase()}` as Messages
                                  )}
                                />
                              </GridCol>
                            </GridRow>
                          )}
                        </>
                      )}

                      {serviceProviderInputType === ServiceProviderIdentifierType.Custom &&
                        sortedProviderControls.length > 0 &&
                        sortedProviderControls.map((item: any) => {
                          if (item.inputType === AccountDataDefinitionInputType.Dropdown) {
                            return (
                              <GridRow>
                                <GridCol size={12}>
                                  <Field
                                    name={`PrimaryField_${item.id}`}
                                    type={getControlType(item.inputType)}
                                    component={getControl(item.inputType)}
                                    label={item.inputLabel}
                                    options={dataForDynamicSelect(item.inputValues)}
                                    required={item.isPrimaryIdentifier || item.isMandatory}
                                  />
                                </GridCol>
                              </GridRow>
                            );
                          }

                          if (item.inputType === AccountDataDefinitionInputType.Radiobutton) {
                            return (
                              <>
                                <GridRow className={styles.topSpacing}>
                                  <GridCol size={12}>
                                    <Typography
                                      msg={`${item.inputLabel}${
                                        item.isMandatory || item.isPrimaryIdentifier ? '*' : ''
                                      }`}
                                      tag="div"
                                      size="l"
                                    />
                                  </GridCol>
                                </GridRow>
                                {item.inputValues.map((inpVal: any, idx: number) => (
                                  <GridRow key={`div_${idx}`}>
                                    <GridCol size={12}>
                                      <Field
                                        name={`PrimaryField_${item.id}`}
                                        type={getControlType(item.inputType)}
                                        component={getControl(item.inputType)}
                                        value={inpVal}
                                        label={inpVal}
                                      />
                                    </GridCol>
                                  </GridRow>
                                ))}
                              </>
                            );
                          }

                          if (item.inputType === AccountDataDefinitionInputType.Checkbox) {
                            return (
                              <>
                                <GridRow className={styles.topSpacing}>
                                  <GridCol size={12}>
                                    <Typography
                                      msg={`${item.inputLabel}${
                                        item.isMandatory || item.isPrimaryIdentifier ? '*' : ''
                                      }`}
                                      tag="div"
                                      size="l"
                                    />
                                  </GridCol>
                                </GridRow>
                                {item.inputValues.map((inpVal: any, idx: number) => (
                                  <GridRow key={`div_${idx}`}>
                                    <GridCol size={12}>
                                      <Field
                                        // name={`PrimaryField_${item.id}`}
                                        name={`checkbox_${idx}_${inpVal.replace(' ', '$')}`}
                                        type={getControlType(item.inputType)}
                                        component={getControl(item.inputType)}
                                        label={inpVal}
                                      />
                                    </GridCol>
                                  </GridRow>
                                ))}
                              </>
                            );
                          }

                          if (item.inputType === AccountDataDefinitionInputType.Date) {
                            return (
                              <>
                                <GridRow className={styles.topSpacing}>
                                  <GridCol size={12}>
                                    <Field
                                      name="date"
                                      //type={getControlType(item.inputType)}
                                      component={DatePickerInput}
                                      label={item.inputLabel}
                                      required={item.isPrimaryIdentifier || item.isMandatory}
                                    />
                                  </GridCol>
                                </GridRow>
                              </>
                            );
                          }

                          if (item.inputType === AccountDataDefinitionInputType.FileUpload) {
                            return (
                              <>
                                <Typography
                                  msg={`${item.inputLabel}${item.isMandatory || item.isPrimaryIdentifier ? '*' : ''}`}
                                  tag="span"
                                  size="l"
                                />
                                <GridRow>
                                  <GridCol size={12}>
                                    <FileInput onDrop={() => noop} files={[]} fileNameCenter={true} />
                                  </GridCol>
                                </GridRow>
                              </>
                            );
                          }

                          return (
                            <GridRow key="idx">
                              <GridCol size={12}>
                                <Field
                                  name={`PrimaryField_${item.id}`}
                                  type={getControlType(item.inputType)}
                                  component={getControl(item.inputType)}
                                  label={item.inputLabel}
                                  required={item.isPrimaryIdentifier || item.isMandatory}
                                />
                              </GridCol>
                            </GridRow>
                          );
                        })}
                    </>
                  )}
                  <FormError formError={status} />
                  <div className={classNames(providerFormStyles.actions)}>
                    <GridRow fullWidth withRowGap>
                      <GridCol size={12}>
                        <Button
                          label={t(Messages.modalProviderFormTitle)}
                          size={ButtonSizes.fill}
                          onClick={() => noop}
                          disabled={true}
                        />
                      </GridCol>
                    </GridRow>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default DataDefinitionsForm;
