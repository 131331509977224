import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getEnableTuCheck } from '@Store/app/app.selector';

import IdDocumentSteps from './IdDocumentSteps';
import TransUnionSteps from './TransUnionSteps';

export interface IdValidationPageProps {}

const IdValidationPage: React.FunctionComponent<IdValidationPageProps> = () => {
  const { transpage }: any = useParams();
  const enableTuCheck = useSelector(getEnableTuCheck);

  return enableTuCheck ? <TransUnionSteps preferred={transpage} /> : <IdDocumentSteps preferred={transpage} />;
};

export default IdValidationPage;
