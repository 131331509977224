import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonStyles } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Paper from '@Components/Paper';
import Typography from '@Components/Typography';
import { CaseTags, Links, LocalStorage, OnboardingCaseSteps } from '@Config/constants';
import {
  DeathCaseStatus,
  // StripeCreateCheckoutSessionMutation,
  // StripeCreateCheckoutSessionMutationVariables,
  useDeathCaseQuery,
  useGetMeQuery,
  // usePackagesQuery,
  UserVerificationStatus,
} from '@Graphql/graphqlTypes.generated';
import { TransUnionSubpages } from '@Routes/settings/IdValidationPage/TransUnionSteps/transUnionSubPages';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { getUser } from '@Store/auth/auth.selector';
import { sendOne } from '@Store/dc/dc.actions';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';

import { ModalProviderProps } from '../Modal';

import styles from './DeathCaseSuccessModalBody.scss';

export interface DeathCaseSuccessModalBodyProps {
  caseId?: string;
  isLifeCase?: boolean;
  title: string;
  bodyMsg: string;
  question?: string;
  firstButtonMsg?: string;
  secondButtonMsg?: string;
  makeCenter?: boolean;
  deathCaseServiceProviderId?: string;
  isVerificationSuccess?: boolean;
  fromNotification?: boolean;
}

const DeathCaseSuccessModalBody: React.FunctionComponent<ModalProviderProps<DeathCaseSuccessModalBodyProps>> = ({
  closeModal,
  modalData: {
    caseId,
    isLifeCase = false,
    title,
    bodyMsg,
    question,
    firstButtonMsg,
    secondButtonMsg,
    makeCenter = false,
    deathCaseServiceProviderId,
    isVerificationSuccess,
    fromNotification,
  },
}) => {
  const history = useHistory();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const queryString = history.location.search;
  const isCreateTag = history.location.pathname.includes(CaseTags.deathCaseCreated);
  const tagValue = `tag=${isCreateTag ? CaseTags.deathCaseCreated : CaseTags.deathCaseEdit}`;

  const notificationUrl = isLifeCase ? `${Links.lcReady}${queryString}` : `${Links.dcReady}${queryString}`;

  const viewLinkClickHandler = () =>
    firstButtonMsg
      ? history.push(generatePath(notificationUrl, { id: caseId, dest: `dest=notification` }))
      : window.location.reload();

  const isFirstCase = getFromLocalStorage(`${LocalStorage.professionalActiveCase}_${user?.id}`);

  const deathCase = useDeathCaseQuery({
    variables: {
      id: caseId || '',
    },
    skip: isLifeCase || isFirstCase !== OnboardingCaseSteps.isFirstCase,
    fetchPolicy: 'network-only',
  });

  const userDetails = useGetMeQuery({
    fetchPolicy: 'network-only',
    skip: !isVerificationSuccess,
  });

  React.useEffect(() => {
    if (
      !fromNotification &&
      isVerificationSuccess &&
      userDetails.data?.me.info.verificationStatus === UserVerificationStatus.Verified
    ) {
      history.push(
        generatePath(Links.settings, {
          subpage: SettingsSubpages.IDValidation,
          transpage: TransUnionSubpages.VerficationStatus,
        })
      );
    }
    window.onpopstate = null;
  }, [userDetails, history, isVerificationSuccess, fromNotification]);

  if (userDetails.loading) {
    return <LoadingSpinner />;
  }

  if (
    !isLifeCase &&
    deathCase.data?.deathCase.status === DeathCaseStatus.Approved &&
    isFirstCase === OnboardingCaseSteps.isFirstCase
  ) {
    setToLocalStorage(`${LocalStorage.professionalActiveCase}_${user?.id}`, DeathCaseStatus.Approved);
  }

  return (
    <Paper id="divDeathCaseSuccess" width="width-full" className={styles.container}>
      <Icon icon="tick" size={IconSizes.m} />

      <Typography msg={title} tag="h4" size="xl" bold className={styles.bottomSpacing} />

      <Typography msg={bodyMsg} tag="div" size="l" className={styles.bottomSpacing} />

      {question && <Typography msg={question} tag="div" size="l" className={styles.marginBottom50} />}

      <Row justifyCenter={makeCenter} size={12}>
        {firstButtonMsg && (
          <Col size={secondButtonMsg ? 0 : 7} className={secondButtonMsg ? styles.rightSpacing : ''}>
            <Button
              onClick={() => {
                closeModal();
                viewLinkClickHandler();
                if (deathCaseServiceProviderId) {
                  dispatch(sendOne.started({ deathCaseProviderId: deathCaseServiceProviderId }));
                }
              }}
              label={firstButtonMsg}
              isFullWidth
              id="btnSendNotification"
            />
          </Col>
        )}
        {secondButtonMsg && (
          <Col>
            <Button
              style={ButtonStyles.transparent}
              onClick={() => {
                closeModal();
                history.push(generatePath(`${Links.home}${queryString}`, { dest: `dest=dashboard&${tagValue}` }));
              }}
              label={secondButtonMsg}
              isFullWidth={true}
              id="btnGoToDashboard"
            />
          </Col>
        )}
      </Row>
    </Paper>
  );
};

export default DeathCaseSuccessModalBody;
