import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import DataWithLabel from '@Components/DataWithLabel';
import FileIconWithLabel from '@Components/FileIconWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { CaseTags, Links, NodeDefiner } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorNode,
  DeathCaseNode,
  DeathCaseStatus,
  LifeCaseNode,
  Permissions,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpagesNew } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { showEmptyModalHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './WillSummary.scss';

export interface WillSummaryProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  isLifeCase?: boolean;
}

const WillSummary: React.FunctionComponent<WillSummaryProps> = ({ data, isLifeCase = false }) => {
  const history = useHistory();
  const queryString = history.location.search;
  const currentLanguage = useSelector(getCurrentLanguage);
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const showEdit =
    data.item?.status === DeathCaseStatus.Draft || data.item?.status === DeathCaseStatus.Approved || isLifeCase;

  const editForm = () => {
    if (showEdit) {
      history.push(
        generatePath(isLifeCase ? `${Links.lcEdit}${queryString}` : Links.dcEdit, {
          id: data.item?.id,
          subpage: DeathCaseSubpagesNew.Will,
          tag: `tag=${CaseTags.deathCaseEdit}`,
        })
      );
    } else {
      showEmptyModalHandler(t(Messages.titleCannotEditCase), t(Messages.descCannotEditCase), dispatch);
    }
  };

  const administrator = data?.item?.__typename === NodeDefiner.DeathCase ? data?.item?.willAdministrator : [];

  const execPresent = data.item && data.item?.executors.length > 0;

  const deathCaseItems = data?.item?.__typename === NodeDefiner.DeathCase ? data?.item : null;

  const isReadOnlyUser = data.item?.myPermission === Permissions.ReadOnly;

  const willDocs = data.item && data.item?.wills.length > 0 ? data.item?.wills[0] : null;

  const repLetterDocs =
    data.item && data.item?.representationLetters.length > 0 ? data.item?.representationLetters[0] : null;

  const docsVisibility = (docs: any) => {
    if (isLifeCase) {
      return (
        isReadOnlyUser || (!docs?.collaboratorsView && docs?.collaborator && docs.collaborator.email !== user?.email)
      );
    }
    return false;
  };

  return (
    <>
      <ConfirmationHeader showEdit={!isReadOnlyUser && showEdit} title="Will and probate" onClick={editForm} />

      <Paper width="width-full" className={styles.deathCaseContainer}>
        {execPresent &&
          data.item?.executors.map((ex: CollaboratorNode) => (
            <>
              <Typography className={styles.marginBottom20} msg="Executor named in will" tag="div" bold size="m" />
              <div className={styles.addressContainer}>
                <Row constant columnMobile>
                  <Col size={12}>
                    <DataWithLabel
                      data={`${ex.firstName} ${ex.lastName}`}
                      label="Name"
                      isConfirmationForm
                      dashForEmpty
                    />
                  </Col>
                </Row>
                {data.item && (
                  <>
                    <Row constant columnMobile>
                      <Col size={12}>
                        <DataWithLabel data={ex.email} label="Email" isConfirmationForm dashForEmpty />
                      </Col>
                    </Row>
                    <Row constant columnMobile>
                      <Col size={12}>
                        <DataWithLabel data={ex.phone} label="Phone number" isConfirmationForm dashForEmpty />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </>
          ))}
        {data.item && data.item?.wills.length > 0 && (
          <>
            <Typography className={styles.marginBottom20} msg="Copy of will" tag="div" bold size="m" />
            <Row constant columnMobile>
              <Col size={5}>
                <FileIconWithLabel
                  file={willDocs}
                  fileType=""
                  label=""
                  blockDocumentAccess={docsVisibility(willDocs)}
                />
              </Col>
            </Row>
          </>
        )}
        {data.item && data.item.representationLetters.length > 0 && (
          <>
            <Typography
              className={styles.marginBottom20}
              msg="Copy of grant of representation"
              tag="div"
              bold
              size="s"
            />
            <Row constant columnMobile>
              <Col size={5}>
                <FileIconWithLabel
                  file={repLetterDocs}
                  fileType=""
                  label=""
                  blockDocumentAccess={docsVisibility(repLetterDocs)}
                />
              </Col>
            </Row>
          </>
        )}

        {administrator && administrator.length > 0 && (
          <>
            <Typography msg="Administrator" tag="div" bold size="m" />
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={`${administrator[0].firstName} ${administrator[0].lastName}`}
                  label="Name"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel data={administrator[0].email} label="Email" isConfirmationForm dashForEmpty />
              </Col>
            </Row>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel data={administrator[0].phone} label="Phone number" isConfirmationForm dashForEmpty />
              </Col>
            </Row>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={administrator[0].relationship}
                  label="Collaborator"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>
          </>
        )}

        {!isLifeCase && deathCaseItems?.representationLetterDate && (
          <Row constant columnMobile>
            <Col size={5}>
              <DataWithLabel
                data={languageBasedDate(deathCaseItems?.representationLetterDate, currentLanguage)}
                label="Grant of representation application date"
                isConfirmationForm
                dashForEmpty
              />
            </Col>
          </Row>
        )}
      </Paper>
    </>
  );
};

export default WillSummary;
