import * as React from 'react';

import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { alphabets } from '@Config/constants';
import { useServiceProvidersQuery } from '@Graphql/graphqlTypes.generated';
import { noop } from '@Utils/helpers';

import styles from './SpList.scss';

export interface SpListProps {
  options?: any;
  spCallback?: (item: { label: string; value: string }, companySelected: boolean) => void;
  isCompanyForm: boolean;
  categorySelected?: any;
}

const SpList: React.FunctionComponent<SpListProps> = ({
  options,
  spCallback = noop,
  categorySelected,
  isCompanyForm,
}) => {
  const providersValues = useServiceProvidersQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { subCategory: categorySelected ? categorySelected.value : '' },
    skip: !isCompanyForm,
  });

  if (providersValues.loading) {
    return <LoadingSpinner />;
  }

  const companies = () => {
    return alphabets.map((albets: any) => {
      return {
        value: '',
        label: albets,
        options: providersValues.data?.serviceProviders
          .filter((provs: any) => Array.from(provs.name.toUpperCase())[0] === albets)
          .map((sbc: any) => {
            return {
              value: sbc.id,
              label: sbc.name,
            };
          }),
      };
    });
  };

  const actualOptions = isCompanyForm ? companies() : options;

  return (
    <div className={styles.mt10}>
      {actualOptions.map((spc: any) => {
        return (
          <>
            <Typography className={styles.category} msg={spc.label} tag="div" bold size="l" />

            {spc.options.map((subCategory: any, idx: number) => (
              <div key={idx}>
                <Typography
                  onClick={() => spCallback({ label: subCategory.label, value: subCategory.value }, isCompanyForm)}
                  className={styles.subCategory}
                  msg={subCategory.label}
                  tag="div"
                  size="l"
                />
              </div>
            ))}
          </>
        );
      })}
    </div>
  );
};

export default SpList;
