import * as React from 'react';
import ReactPaginate from 'react-paginate';

import ActivityCard from '@Components/application/ActivityCard';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
// import { Steps } from '@Components/TourSelect';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ActivityStreamNode, useActivityStreamQuery } from '@Graphql/graphqlTypes.generated';

import styles from './ActivityForm.scss';

export interface ActivityFormProps {
  deathCaseId?: string;
}

const ActivityForm: React.FunctionComponent<ActivityFormProps> = ({ deathCaseId }) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  const activitySteam = useActivityStreamQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      baseCaseId: deathCaseId,
    },
  });

  if (activitySteam.loading) {
    return (
      <Col size={8} textCenter alignCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  if (activitySteam.data?.activityStream === undefined) {
    return null;
  }

  const activities = activitySteam.data?.activityStream.filter((x: any) => !!x) as [ActivityStreamNode];

  const activityData = activities.map((activity: ActivityStreamNode, idx: number) => (
    <ActivityCard key={idx} data={activity} />
  ));

  const PER_PAGE = 14;
  const offset = currentPage * PER_PAGE;
  const currentPageData = activityData.slice(offset, offset + PER_PAGE).map((item: any) => item);
  const pageCount = Math.ceil(activityData.length / PER_PAGE);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const backButton = <Icon icon="chevronLeft" size={IconSizes.ss} />;
  const nextButton = <Icon icon="chevronRight" size={IconSizes.ss} />;

  return (
    <>
      {currentPageData}

      {activityData.length > PER_PAGE && (
        <Row className={styles.cardsPagination} justifyCenter>
          <Col>
            <ReactPaginate
              previousLabel={backButton}
              nextLabel={nextButton}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              disabledClassName={styles.pagination__link__disabled}
              activeClassName={styles.pagination__link__active}
              pageRangeDisplayed={3}
              marginPagesDisplayed={0}
              previousLinkClassName={styles.previousButton}
              previousClassName={styles.navigationIconsPlacement}
              nextClassName={styles.navigationIconsPlacement}
              nextLinkClassName={styles.previousButton}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ActivityForm;
