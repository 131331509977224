import { useMutation } from '@apollo/react-hooks';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import {
  validationLimits,
  AddressPortal,
  ReactResponsiveQueries,
  EnterKeyCode,
  Links,
  PaymentStatus,
  CaseTags,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeUpdateCheckoutSessionMutation,
  StripeUpdateCheckoutSessionMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeUpdateCheckoutSession } from '@Graphql/payments/mutations';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { currentDateTime, formatDateForExchange, normalizeDate } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { addressHelper, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';
import {
  validateMaxLen,
  validatePhone,
  validateDate,
  validatePostalCodeCharacters,
  validateMinLength,
  validateAny,
  validateSelector,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../model/Address';
import { ModalProviderProps } from '../Modal';

import styles from './NewWelcomeModal.scss';

export interface NewWelcomeModalProps {
  isIllProp?: boolean;
}

enum NewWelcomeModalFields {
  birthDate = 'birthDate',
  phone = 'phone',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  postalCode = 'postalCode',
  autoAddress = 'autoAddress',
  chooseAddress = 'chooseAddress',
  buildingName = 'buildingName',
  buildingNumber = 'buildingNumber',
}

const NewWelcomeModal: React.FunctionComponent<ModalProviderProps<NewWelcomeModalProps>> = ({
  closeModal,
  modalData: { isIllProp },
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const [manualAddress, setManualAddress] = React.useState(false);
  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);
  const [currentPostalCode, setCurrentPostalCode] = React.useState('');
  const [searching, setSearching] = React.useState(false);
  const [assignedAddress, setAssignedAddress] = React.useState(-1);
  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id') || undefined;
  const isTerminallyIll = isIllProp || searchParams.get('ill') === 'true' || undefined;

  const [updateUser, { loading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: () => {
      closeModal();
      history.push(
        generatePath(`${Links.lcCreate}?ill=${isTerminallyIll}`, { tag: `tag=${CaseTags.lifeCaseCreated}` })
      );
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [updateCheckoutSession] = useMutation<
    StripeUpdateCheckoutSessionMutation,
    StripeUpdateCheckoutSessionMutationVariables
  >(mutationStripeUpdateCheckoutSession, {
    onError: (mutCheckError: any) => {
      dispatch(notificationError(normaliseGqlError(mutCheckError.message)));
    },
  });

  const getAddress = () => {
    if (currentPostalCode !== '') {
      setSearching(true);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', currentPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apidata) => {
          if (apidata.Message) {
            dispatch(notificationError(apidata.Message));

            setAddressResult([]);
            setSearching(false);
          } else {
            const outputData = new Address(apidata);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError(t(Messages.msgAddressNotFound)));
            }
            setAddressResult(outputData.addresses);
            setSearching(false);
          }
        });
    }
  };

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
    setManualAddress(true);
  };

  const AutoToggleForm = () => {
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
      const searchedAddress = addressResult.find((addr: FullAddress) => addr.id === assignedAddress);
      if (searchedAddress) {
        setFieldValue(NewWelcomeModalFields.buildingName, addressHelper(searchedAddress.buildingName));
        setFieldValue(NewWelcomeModalFields.buildingNumber, addressHelper(searchedAddress.buildingNumber));
        setFieldValue(NewWelcomeModalFields.address1, addressHelper(searchedAddress.address1));
        setFieldValue(NewWelcomeModalFields.address2, addressHelper(searchedAddress.address2));
        setFieldValue(NewWelcomeModalFields.city, addressHelper(searchedAddress.city));
        setFieldValue(NewWelcomeModalFields.postalCode, addressHelper(searchedAddress.postalCode));
      }
    }, [setFieldValue]);

    return null;
  };

  return (
    <div className={styles.containerPadding}>
      <Formik
        // enableReinitialize={true}
        initialValues={{
          [NewWelcomeModalFields.address1]: '',
          [NewWelcomeModalFields.address2]: '',
          [NewWelcomeModalFields.city]: '',
          [NewWelcomeModalFields.postalCode]: '',
          [NewWelcomeModalFields.phone]: '+44',
          [NewWelcomeModalFields.birthDate]: user?.birthDate || '',
          [NewWelcomeModalFields.buildingName]: '',
          [NewWelcomeModalFields.buildingNumber]: '',
          [NewWelcomeModalFields.chooseAddress]: -1,
        }}
        validationSchema={() => {
          return Yup.lazy(() => {
            return Yup.object({
              [NewWelcomeModalFields.birthDate]: user?.birthDate ? validateAny() : validateDate(t),
              [NewWelcomeModalFields.postalCode]: validateMaxLen(t, validationLimits.postalCode, false),
              [NewWelcomeModalFields.phone]: validatePhone(t),
              [NewWelcomeModalFields.chooseAddress]:
                resultAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
            });
          });
        }}
        onSubmit={(values) => {
          const { address1, address2, city, postalCode, phone, buildingName, buildingNumber } = values;

          const validatedPhone = removeLeadingZeroAndSpaceInContact(phone);
          updateUser({
            variables: {
              input: {
                id: user ? user?.id : '',
                phone: validatedPhone,
                address1,
                address2,
                city,
                postalCode,
                birthDate: formatDateForExchange(values.birthDate ? values.birthDate : user?.birthDate),
                buildingName,
                buildingNumber,
                isFirstLogin: false,
                title: user?.title,
              },
            },
          });
          if (sessionId) {
            updateCheckoutSession({ variables: { input: { sessionId, status: PaymentStatus.SUCCESS } } });
          }
        }}
      >
        {({ status }) => (
          <Form>
            <div className={styles.container}>
              <Row className={styles.mt10} constant justifyCenter>
                <Icon icon="logo" size={IconSizes.welcomeLogo} />
              </Row>
              <Row constant justifyCenter>
                <Typography msg={t(Messages.pageInvite)} tag="h3" size="xl" bold />
              </Row>
              <Row size={12} className={styles.bottomSpacing}>
                <Typography msg={t(Messages.pageInviteDesc)} tag="div" size="l" />
              </Row>

              <Row>
                <Typography tag="div" size="l" msg={t(Messages.labelEnterYourPostcode)} />
                <span className={styles.required}>*</span>
              </Row>
              <Row className={styles.addressContainer} size={12}>
                <Col size={isTablet ? 12 : 7}>
                  <input
                    name={NewWelcomeModalFields.autoAddress}
                    type="text"
                    value={currentPostalCode}
                    className={styles.input}
                    autoComplete="off"
                    onChange={(e: any) => {
                      setCurrentPostalCode(e.currentTarget.value);
                      setShowClearAddress(true);
                    }}
                    placeholder={t(Messages.labelSearchByPostcode)}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter' && validatePostalCodeCharacters(currentPostalCode)) {
                        e.preventDefault();
                        getAddress();
                      }
                    }}
                    onBlur={() => {
                      if (validateMinLength(currentPostalCode) && resultAddressOptions.length === 0) {
                        setPostCodeTabOut(true);
                      } else {
                        setPostCodeTabOut(false);
                      }
                    }}
                  />
                  {showClearAddress && (
                    <Icon
                      icon="close-outline"
                      className={styles.visibilityToggleIcon}
                      onClick={() => {
                        setShowClearAddress(false);
                        setCurrentPostalCode('');
                        setAddressResult([]);
                        setAssignedAddress(-1);
                        setPostCodeTabOut(false);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          setShowClearAddress(false);
                          setCurrentPostalCode('');
                          setAddressResult([]);
                          setAssignedAddress(-1);
                          setPostCodeTabOut(false);
                        }
                      }}
                      size={IconSizes.s}
                    />
                  )}
                </Col>
                <Col size={isTablet ? 12 : 5} className={styles.searchButtonContainer}>
                  <Popover
                    showContent={postCodeTabOut}
                    position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                    title={t('title_postcode_warning' as Messages)}
                    message={t('desc_postcode_warning' as Messages)}
                  >
                    <Button
                      type={ButtonTypes.button}
                      style={ButtonStyles.transparent}
                      loading={searching}
                      label={t(Messages.buttonSearch)}
                      icon="search"
                      iconSize={IconSizes.sxs}
                      isFullWidth={true}
                      onClick={() => {
                        setPostCodeTabOut(false);
                        getAddress();
                      }}
                      constant
                      disabled={!validatePostalCodeCharacters(currentPostalCode)}
                    />
                  </Popover>
                </Col>
              </Row>

              {resultAddressOptions.length > 0 && (
                <Row className={styles.topAddressSpacing} size={12}>
                  <Col size={12}>
                    <Field
                      name={NewWelcomeModalFields.chooseAddress}
                      component={SelectInput}
                      label=""
                      placeholder={t(Messages.labelSelectYourAddress)}
                      options={resultAddressOptions}
                      disabled={!resultAddressOptions.length}
                      selectClass={ClassNamesForOverwrite.SelectAddress}
                      onSelectChange={handleAddressChange}
                      onFocus={() => setPostCodeTabOut(false)}
                      hasErrorProp={assignedAddress === -1}
                    />
                  </Col>
                </Row>
              )}

              <GridRow>
                <GridCol className={styles.adresslink} size={9}>
                  <div
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        setManualAddress(!manualAddress);
                      }
                    }}
                    onClick={() => setManualAddress(!manualAddress)}
                  >
                    <Typography msg={t(Messages.linkEnterAddressManually)} tag="span" tabIndex={0} />
                  </div>
                </GridCol>
              </GridRow>

              {manualAddress && (
                <>
                  <GridRow>
                    <GridCol size={4}>
                      <Field
                        name={NewWelcomeModalFields.buildingNumber}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldBuildingNumber)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={4}>
                      <Field
                        name={NewWelcomeModalFields.buildingName}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldBuildingName)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={NewWelcomeModalFields.address1}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldAddress1)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={NewWelcomeModalFields.address2}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldAddress2)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={4}>
                      <Field
                        name={NewWelcomeModalFields.city}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldCity)}
                      />
                    </GridCol>
                    <GridCol size={4}>
                      <Field
                        name={NewWelcomeModalFields.postalCode}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldPostalCode)}
                      />
                    </GridCol>
                  </GridRow>
                </>
              )}

              <GridRow>
                <GridCol size={6}>
                  <Field
                    name={NewWelcomeModalFields.phone}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldContactNumber)}
                    required
                  />
                </GridCol>
              </GridRow>

              <GridRow className={styles.mt11}>
                <GridCol size={6}>
                  <Field
                    name={NewWelcomeModalFields.birthDate}
                    type="text"
                    component={DatePickerInput}
                    parse={normalizeDate(currentLanguage)}
                    label={t(Messages.fieldBirthDate)}
                    maxDate={currentDateTime().toDate()}
                    required
                  />
                </GridCol>
              </GridRow>

              <AutoToggleForm />

              <FormError formError={status} />
              <Row className={styles.bigTopSpacing}>
                <Col size={12}>
                  <Button
                    type={ButtonTypes.submit}
                    size={ButtonSizes.fill}
                    label={t('button_continue' as Messages)}
                    isFullWidth
                    loading={loading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewWelcomeModal;
