import classNames from 'classnames';
import React from 'react';

import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';

export interface PaperHeaderProps {
  title?: string;
  subtitle?: Messages | string;
  className?: string;
}

const PaperHeader: React.FunctionComponent<PaperHeaderProps> = ({ title, className }) => {
  return <div className={classNames(className)}>{title && <Typography msg={title} tag="h4" size="xl" bold />}</div>;
};

export default PaperHeader;
