import { Messages } from '@Config/messages';
import {
  CollaboratorPermission,
  UserAccountType,
  UserProfessionalType,
  UserTitle,
} from '@Graphql/graphqlTypes.generated';
import { Translator } from '@Utils/types';

const gqlErrorPrefix = 'GraphQL error: ';

export const hasFieldError = (meta: any, form: any) => (meta.touched || form?.submitCount > 0) && !!meta.error;

export const normaliseGqlError = (errorMessage: string) => {
  const normalised = errorMessage.startsWith(gqlErrorPrefix)
    ? errorMessage.substr(gqlErrorPrefix.length)
    : errorMessage;
  return normalised as Messages;
};

export const getGqlErrorMessage = (errorMessage: string) => {
  const normalised = errorMessage.startsWith(gqlErrorPrefix)
    ? errorMessage.substr(gqlErrorPrefix.length)
    : errorMessage;
  return normalised;
};

export const hideTrueEmail = (email: string) =>
  email.replace(/(.)(.+)(@)/g, (_match, start, middle, end) => start + '*'.repeat(Math.min(middle.length, 4)) + end);

export const getPermissionTranslated = (t: Translator, permission: CollaboratorPermission) =>
  t(`${Messages.labelPermissionTypePrefix}${permission.toLowerCase()}` as Messages);

export const getRelationshipTranslated = (t: Translator, relationship: string) =>
  t(`${Messages.labelRelationshipTypePrefix}${relationship.toLowerCase()}` as Messages);

export const getPermissionSelectOption = (t: Translator) => (permission: CollaboratorPermission) => ({
  value: permission,
  label: getPermissionTranslated(t, permission),
});

export const getRelationshipSelectOption = (t: Translator) => (relationship: string) => ({
  value: relationship,
  label: getRelationshipTranslated(t, relationship),
});

export const getUserAccountTypeTranslated = (t: Translator, accountType: UserAccountType) =>
  t(`${Messages.accountTypePrefix}${accountType.toUpperCase()}` as Messages);

export const getUserAccountTypeOptions = (t: Translator) => (accountType: UserAccountType) => ({
  value: accountType,
  label: getUserAccountTypeTranslated(t, accountType),
});

export const getProfessionalTypeTranslated = (t: Translator, professionalType: UserProfessionalType) =>
  t(`${Messages.professionalTypePrefix}${professionalType.toUpperCase()}` as Messages);

export const getProfessionalTypeOptions = (t: Translator) => (professionalType: UserProfessionalType) => ({
  value: professionalType,
  label: getProfessionalTypeTranslated(t, professionalType),
});

export const titleOptions = Object.values(UserTitle)
  .filter((titles: string) => titles !== UserTitle.NotSpecified)
  .map((desTitle: string) => ({
    label: desTitle.charAt(0).toUpperCase() + desTitle.slice(1).toLowerCase(),
    value: desTitle,
  }));
