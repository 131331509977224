import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import Compressor from 'compressorjs';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import LogoUpload from '@Components/application/LogoUpload';
import MiniFooter from '@Components/application/MiniFooter';
import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { Links, LocalStorage, ModalTypes, OnboardingCaseSteps, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationAgentCompanyUpdate } from '@Graphql/agent/mutations';
import {
  AgentCompanyUpdateMutation,
  AgentCompanyUpdateMutationVariables,
  ProfessionalCompanyUpdateMutation,
  ProfessionalCompanyUpdateMutationVariables,
  useAgentCompanyDetailsQuery,
  useIsPaymentRequiredQuery,
  useProfessionalCompanyDetailsQuery,
  UserAccountType,
  UserVerificationStatus,
} from '@Graphql/graphqlTypes.generated';
import { mutationProfessionalCompanyUpdate } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import DeathCasesContent from '@Routes/dashboard/DeathCasesPage/DeathCasesContent';
import LifeCasesContent from '@Routes/dashboard/LifeCasesPage/LifeCasesContent';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { showModal } from '@Store/app/app.actions';
import { getEnableTuCheck } from '@Store/app/app.selector';
import { getUser } from '@Store/auth/auth.selector';
import { setToLocalStorage } from '@Utils/localStorage';
import { logoUploadConfirmationHandler, preventHandler } from '@Utils/modal';

import styles from './DashboardProfessional.scss';

export interface DashboardProfessionalProps {
  isUnvitedProfessional?: boolean;
  dcCount: number;
  lcCount: number;
}

enum ProfessionalDashboardTabs {
  TabDc,
  TabLc,
}

enum ProfessionalDashboardReversedTabs {
  TabLc,
  TabDc,
}

const DashboardProfessional: React.FunctionComponent<DashboardProfessionalProps> = ({
  isUnvitedProfessional = false,
  dcCount,
  lcCount,
}) => {
  const user = useSelector(getUser);
  const enableTuCheck = useSelector(getEnableTuCheck);
  const history = useHistory();
  const t = useTranslations();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState(
    dcCount > 0 || (dcCount === 0 && lcCount === 0)
      ? ProfessionalDashboardTabs.TabDc
      : ProfessionalDashboardReversedTabs.TabLc
  );

  const queryString = history.location.search;

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const isPaymentRequiredData = useIsPaymentRequiredQuery({
    fetchPolicy: 'cache-and-network',
  });

  const authorityActionHandler = (link: string, query: string, agentUser: boolean) => {
    return dispatch(
      showModal({
        type: ModalTypes.authorityActionModal,
        params: {
          data: {
            link,
            query,
            agentUser,
          },
          title: '',
          noPadding: true,
          modalSize: PaperWidths.m672,
        },
      })
    );
  };

  const profTabs = [{ title: t('tab_prof_dc' as Messages) }, { title: t('tab_prof_lc' as Messages) }];
  const profTabsReverse = [{ title: t('tab_prof_lc' as Messages) }, { title: t('tab_prof_dc' as Messages) }];
  const isAgent = user?.accountType === UserAccountType.Agent;

  const companyDetails = useProfessionalCompanyDetailsQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { id: user ? user?.professionalCompany : '' },
    skip: isAgent,
  });

  const agentCompanyDetails = useAgentCompanyDetailsQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { id: user ? user?.agentCompany : '' },
    skip: user?.accountType === UserAccountType.Professional,
  });

  const [professionalCompanyUpdate] = useMutation<
    ProfessionalCompanyUpdateMutation,
    ProfessionalCompanyUpdateMutationVariables
  >(mutationProfessionalCompanyUpdate, {
    onCompleted: () => {
      logoUploadConfirmationHandler(dispatch);
    },
  });

  const [agentCompanyUpdate] = useMutation<AgentCompanyUpdateMutation, AgentCompanyUpdateMutationVariables>(
    mutationAgentCompanyUpdate,
    {
      onCompleted: () => {
        logoUploadConfirmationHandler(dispatch);
      },
    }
  );

  if (isPaymentRequiredData.loading) {
    return <LoadingSpinner />;
  }

  const isPaymentRequired = isPaymentRequiredData.data?.isPaymentRequired.priceId;

  const compressAndUpload = (file: File) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: (compressedResult: File) => {
        if (isAgent) {
          dispatch(
            agentCompanyUpdate({
              variables: {
                input: {
                  files: compressedResult,
                  id: agentCompany?.agentCompanyDetails.id || '',
                },
              },
            })
          );
        } else {
          dispatch(
            professionalCompanyUpdate({
              variables: {
                input: {
                  files: compressedResult,
                  id: companyDetails.data?.professionalCompanyDetails.id || '',
                },
              },
            })
          );
        }
      },
    });
  };

  const logoUploadComponent = (
    <Col className={styles.handSymbol}>
      <div className={styles.uploadOuterCircle}>
        <div className={styles.uploadInnerCircle}>
          <Icon size={IconSizes.s24} className={styles.uploadIcon} icon="download" />
        </div>
      </div>
    </Col>
  );

  const isDCPresent = () => {
    return dcCount > 0 || (dcCount === 0 && lcCount === 0);
  };

  const profCompany = companyDetails.data;
  const agentCompany = agentCompanyDetails.data;

  return (
    <>
      {profCompany?.professionalCompanyDetails.logo || agentCompany?.agentCompanyDetails.logo ? (
        <Row constant size={12}>
          <Col>
            <div className={styles.outerCircle}>
              <div className={styles.innerCircle}>
                <img className={styles.profLogo} src={profCompany?.professionalCompanyDetails.logo} alt="" />
              </div>
            </div>
          </Col>
          <Col className={styles.companyNamePlacement}>
            <Typography
              msg={
                companyDetails.data
                  ? profCompany?.professionalCompanyDetails.companyName
                  : agentCompany?.agentCompanyDetails.companyName
              }
              tag="h4"
              size="xl"
              bold
            />
          </Col>
        </Row>
      ) : (
        <Row constant size={12}>
          <LogoUpload
            logoUploadComponent={logoUploadComponent}
            onDrop={
              (files: File[]) => {
                compressAndUpload(files[0]);
              }
              // eslint-disable-next-line react/jsx-curly-newline
            }
          />
          <Col className={styles.companyNamePlacement}>
            <Typography
              msg={
                companyDetails.data
                  ? profCompany?.professionalCompanyDetails.companyName
                  : agentCompany?.agentCompanyDetails.companyName
              }
              tag="h4"
              size="xl"
              bold
            />
          </Col>
        </Row>
      )}
      <Typography msg="Your dashboard" tag="h3" size="xxl" />
      <Row size={isTablet ? 10 : 6}>
        <Typography
          msg="From your dashboard you can see an overview of your death and life cases, as well as track and manage your ongoing cases."
          tag="div"
          size="l"
          color="footerColor"
          className={styles.bottomSpacing}
        />
      </Row>
      {user?.verificationStatus !== UserVerificationStatus.Verified && dcCount > 0 && (
        <Row size={isTablet ? 11 : 7} className={styles.missingInfoBox}>
          <Col size={5}>
            <Row constant>
              <Col className={styles.infoIcon}>
                <Icon icon="info" />
              </Col>

              <Col size={6} className={styles.topSpacing}>
                <Typography msg="To do before sending notifications" tag="div" size="s" bolder />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row constant>
              <Icon className={classNames(styles.missingItems)} icon="lock" size={IconSizes.ss} />
              <span
                className={styles.handSymbol}
                onClick={() =>
                  history.push(
                    generatePath(`${Links.settings}?${queryString}`, { subpage: SettingsSubpages.IDValidation })
                  )
                }
              >
                <Typography
                  className={styles.sent}
                  msg={enableTuCheck ? 'Verify identity' : t('missing_item_prefix_user_doc' as Messages)}
                  tag="span"
                  bold
                  color="red"
                  size="s"
                  underline
                />
              </span>
            </Row>
          </Col>
        </Row>
      )}

      <Tabs
        removeTabWidth
        className={styles.noWhiteSpace}
        tabs={isDCPresent() ? profTabs : profTabsReverse}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === (isDCPresent() ? ProfessionalDashboardTabs.TabDc : ProfessionalDashboardReversedTabs.TabDc) && (
        <>
          <Row size={isTablet ? 10 : 6} justifyCenter className={styles.addCompanyBlock}>
            <Button
              onClick={() => {
                if (isUnvitedProfessional) {
                  setToLocalStorage(
                    `${LocalStorage.professionalActiveCase}_${user?.id}`,
                    OnboardingCaseSteps.isFirstCase
                  );
                }
                if (isPaymentRequired) {
                  preventHandler(t('msg_dc_free_limit_reached' as Messages), dispatch, isPaymentRequired);
                } else {
                  authorityActionHandler(Links.dcCreate, queryString, isAgent);
                }
              }}
              icon="plus"
              constant
              label={t(Messages.buttonNewDC)}
              className={styles.buttonSpacing}
              id="btnAddDeathCase"
              isFullWidth
            />
          </Row>
          <DeathCasesContent />
        </>
      )}

      {activeTab === (isDCPresent() ? ProfessionalDashboardTabs.TabLc : ProfessionalDashboardReversedTabs.TabLc) && (
        <>
          <Row size={isTablet ? 10 : 6} justifyCenter className={styles.addCompanyBlock}>
            <Button
              onClick={() => authorityActionHandler(Links.lcCreate, queryString, isAgent)}
              icon="plus"
              constant
              label="Register a Life"
              className={styles.buttonSpacing}
              isFullWidth
              iconSize={IconSizes.ss}
            />
          </Row>
          <LifeCasesContent />
        </>
      )}

      {!isTablet && <MiniFooter />}
    </>
  );
};

export default DashboardProfessional;
