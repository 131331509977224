import * as React from 'react';
import { useDispatch } from 'react-redux';

import { LocalStorage } from '@Config/constants';
import LifeCasePage from '@Routes/lifeCase/LifeCasePage';
import { clearLC } from '@Store/lc/lc.actions';
import { getFromLocalStorage } from '@Utils/localStorage';

export interface CreateLifeCaseProps {}

const CreateLifeCase: React.FunctionComponent<CreateLifeCaseProps> = () => {
  const id = getFromLocalStorage(LocalStorage.lifeCaseId) || '';
  const dispatch = useDispatch();
  if (id === '') {
    dispatch(clearLC());
  }
  return <LifeCasePage />;
};

export default CreateLifeCase;
