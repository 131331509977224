import { generatePath } from 'react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { Links, LocalStorage } from '@Config/constants';
import { getAppConfigQuery } from '@Graphql/config/config.queries';
import { AppConfigViewNode } from '@Graphql/graphqlTypes.generated';
import { getAppConfig } from '@Store/app/app.actions';
import { locationChange } from '@Store/navigation/navigation.actions';
import { logError } from '@Utils/helpers';
import { deleteFromLocalStorage } from '@Utils/localStorage';

export function* getAppConfigSaga(action: Action<{}>) {
  try {
    const result: AppConfigViewNode = yield call(getAppConfigQuery);
    yield put(getAppConfig.done({ result, params: action.payload }));
  } catch (error) {
    yield deleteFromLocalStorage(LocalStorage.userToken);
    yield deleteFromLocalStorage(LocalStorage.accessTokenExpiry);
    yield put(locationChange({ path: generatePath(Links.login) }));
    logError(error);
  }
}

export function* watchAppSaga() {
  yield all([yield takeLatest(getAppConfig.started, getAppConfigSaga)]);
}
