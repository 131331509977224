import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/RadioButton';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseProviderUpdateStatusMutation,
  CaseProviderUpdateStatusMutationVariables,
  CaseServiceProviderStatus,
  RestrictedCaseServiceProviderNode,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { mutationCaseProviderUpdateStatus } from '@Graphql/providers/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { getFromLocalStorageString, setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './NotificationStatusUpdateModalBody.scss';

export interface NotificationStatusUpdateModalBodyProps {
  onClose?: (completedAt: string) => void;
  buttonText: string;
  leaveOpen?: boolean;
  slug: string;
  accessCode: string;
  status: CaseServiceProviderStatus;
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
  providerDash?: boolean;
}

const NotificationStatusUpdateModalBody: React.FunctionComponent<ModalProviderProps<
  NotificationStatusUpdateModalBodyProps
>> = ({ modalData: { accessCode, status, onClose = noop, deathCaseServiceProvider, providerDash }, closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;
  const user = useSelector(getUser);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramAccessCode = searchParams.get('accesscode') || '';
  const refCode = getFromLocalStorageString(LocalStorage.referenceCode);
  const [statusType, setStatusType] = React.useState(status);
  const [showRefForm, setShowRefForm] = React.useState(status === CaseServiceProviderStatus.CompletedClosed);
  const [reference, setReference] = React.useState(refCode);
  const [finalBalance, setFinalBalance] = React.useState(deathCaseServiceProvider.finalBalance);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const t = useTranslations();

  const onStatusChange = (e: any, dataAttribute?: string | null) => {
    const selectedStatusType = e.target.value ? e.target.value : dataAttribute;
    setStatusType(selectedStatusType);
    if (selectedStatusType === CaseServiceProviderStatus.CompletedClosed) {
      setShowRefForm(true);
    } else {
      setShowRefForm(false);
    }
  };

  const onReferenceChange = (e: any) => {
    setReference(e.target.value);
  };

  const onFinalBalanceChange = (e: any) => {
    setFinalBalance(e.target.value);
  };

  const [updateStatus] = useMutation<CaseProviderUpdateStatusMutation, CaseProviderUpdateStatusMutationVariables>(
    mutationCaseProviderUpdateStatus,
    {
      variables: {
        input: {
          accessCode,
          status: statusType,
          referenceCode: reference,
          finalBalance,
        },
      },
      onCompleted: (resp: any) => {
        setToLocalStorage(LocalStorage.notificationStatus, statusType);
        dispatch(notificationSuccess('Notification status updated'));
        setIsSubmitting(false);
        if (paramAccessCode !== '') {
          window.location.reload();
        } else if (
          user?.accountType === UserAccountType.ServiceProvider ||
          (user?.accountType === UserAccountType.Individual && user.isStaff)
        ) {
          history.push(generatePath(`${Links.notificationDashboard}${queryString}`, { accessCode }));
        } else {
          history.push(`${Links.notificationDCInfo}?accesscode=${accessCode}${queryString}`);
        }
        if (providerDash) {
          setToLocalStorage(LocalStorage.refreshSpDashboard, statusType);
        }
        onClose(resp.caseProviderUpdateStatus.completedAt);
        closeModal();
      },
      onError: (error: any) => {
        setIsSubmitting(false);
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const updateStatusFunction = () => {
    setIsSubmitting(true);
    updateStatus();
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.mt20} msg="Update status of death notification" tag="h4" size="xl" bold />
      <Row>
        <RadioButton
          value={CaseServiceProviderStatus.InProgress}
          checked={statusType === CaseServiceProviderStatus.InProgress}
          name={CaseServiceProviderStatus.InProgress}
          onChange={onStatusChange}
          label="In progress"
          isLabelBold
          subLabel="You are managing the request"
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.InProgress)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
        />
      </Row>
      {user?.isStaff && (
        <Row>
          <RadioButton
            value={CaseServiceProviderStatus.InProgressAwaitingInformationFromSp}
            checked={statusType === CaseServiceProviderStatus.InProgressAwaitingInformationFromSp}
            name={CaseServiceProviderStatus.InProgressAwaitingInformationFromSp}
            onChange={onStatusChange}
            isLabelBold
            label="In Progress: Awaiting information from the SP"
            subLabel={t('label_sub_awaiting_sp' as Messages)}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                const buttonValue = document
                  .getElementById(CaseServiceProviderStatus.InProgressAwaitingInformationFromSp)
                  ?.getAttribute('data-radio-value');
                onStatusChange(e, buttonValue);
              }
            }}
          />
        </Row>
      )}
      {user?.isStaff && (
        <Row>
          <RadioButton
            value={CaseServiceProviderStatus.InProgressAutoPopulationFailed}
            checked={statusType === CaseServiceProviderStatus.InProgressAutoPopulationFailed}
            name={CaseServiceProviderStatus.InProgressAutoPopulationFailed}
            onChange={onStatusChange}
            isLabelBold
            label="In Progress: Auto population failed"
            subLabel={t('msg_auto_population_failed' as Messages)}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                const buttonValue = document
                  .getElementById(CaseServiceProviderStatus.InProgressAutoPopulationFailed)
                  ?.getAttribute('data-radio-value');
                onStatusChange(e, buttonValue);
              }
            }}
          />
        </Row>
      )}
      <Row>
        <RadioButton
          value={CaseServiceProviderStatus.NoActionRequired}
          checked={statusType === CaseServiceProviderStatus.NoActionRequired}
          name={CaseServiceProviderStatus.NoActionRequired}
          onChange={onStatusChange}
          isLabelBold
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.NoActionRequired)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
          label="No further Action Required: In progress outside Life Ledger"
          subLabel="You are managing the request and are in contact with the notifier outside of Life Ledger"
        />
      </Row>
      <Row>
        <RadioButton
          value={CaseServiceProviderStatus.CompletedClosed}
          checked={statusType === CaseServiceProviderStatus.CompletedClosed}
          name={CaseServiceProviderStatus.CompletedClosed}
          onChange={onStatusChange}
          isLabelBold
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.CompletedClosed)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
          label="Complete: The account has been closed"
          subLabel="You have completed the request made by the notifier, and the account has been closed"
        />
      </Row>

      {showRefForm && (
        <>
          <Row className={styles.leftSpacing}>
            <Col size={1} />
            <Col size={3}>
              <Typography msg="Reference number" tag="div" size="s" bold />
            </Col>
          </Row>
          <Row className={classNames(styles.textBoxSpacing, styles.leftSpacing)}>
            <Col size={1} />
            <Col size={5}>
              <input
                placeholder="Optional"
                type="text"
                className={classNames(styles.input)}
                onChange={onReferenceChange}
                value={reference}
              />
            </Col>
          </Row>
          <Row className={classNames(styles.leftSpacing, styles.textBoxSpacing)}>
            <Col size={1} />
            <Col size={3}>
              <Typography msg="Final balance" tag="div" size="s" bold />
            </Col>
          </Row>
          <Row className={classNames(styles.textBoxSpacing, styles.leftSpacing)}>
            <Col size={1} />
            <Col size={5}>
              <input
                placeholder="Optional"
                type="text"
                className={classNames(styles.input)}
                onChange={onFinalBalanceChange}
                value={finalBalance}
              />
            </Col>
          </Row>
        </>
      )}

      <Row className={styles.textBoxSpacing}>
        <RadioButton
          value={CaseServiceProviderStatus.CompletedSwitched}
          checked={statusType === CaseServiceProviderStatus.CompletedSwitched}
          name={CaseServiceProviderStatus.CompletedSwitched}
          onChange={onStatusChange}
          isLabelBold
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.CompletedSwitched)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
          label="Complete: The account has been switched"
          subLabel="You have completed the request made by the notifier, and the account has been switched"
        />
      </Row>

      <Row>
        <RadioButton
          value={CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier}
          checked={statusType === CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier}
          name={CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier}
          onChange={onStatusChange}
          isLabelBold
          label="More information required"
          subLabel={t('label_sub_awaiting_notifier' as Messages)}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
        />
      </Row>

      <Row constant size={12} className={styles.orSpacing}>
        <Col size={4} alignCenter className={styles.orBorder} />
        <Col textCenter>
          <Typography msg="Or" tag="div" size="m" color="footerColor" bold />
        </Col>
        <Col size={4} alignCenter className={styles.orBorder} />
      </Row>
      <Row>
        <RadioButton
          value={CaseServiceProviderStatus.AlreadyRegistered}
          checked={statusType === CaseServiceProviderStatus.AlreadyRegistered}
          name={CaseServiceProviderStatus.AlreadyRegistered}
          onChange={onStatusChange}
          isLabelBold
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.AlreadyRegistered)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
          label="This death has already been registered"
          subLabel="This case was dealt with outside of Life Ledger"
        />
      </Row>
      <Row>
        <RadioButton
          value={CaseServiceProviderStatus.NotFound}
          checked={statusType === CaseServiceProviderStatus.NotFound}
          name={CaseServiceProviderStatus.NotFound}
          onChange={onStatusChange}
          isLabelBold
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document
                .getElementById(CaseServiceProviderStatus.NotFound)
                ?.getAttribute('data-radio-value');
              onStatusChange(e, buttonValue);
            }
          }}
          label="No account found"
          subLabel="You could not match the details given with an account"
        />
      </Row>

      <Row>
        <Button
          loading={isSubmitting}
          type={ButtonTypes.submit}
          label="Change status"
          isFullWidth
          onClick={() => updateStatusFunction()}
        />
      </Row>
    </div>
  );
};

export default NotificationStatusUpdateModalBody;
