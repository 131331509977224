import classNames from 'classnames';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
//import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/RadioButton';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { RestrictedCaseServiceProviderNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { caseDocAccessRequest } from '@Store/provider/provider.actions';
import { noop } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
// import { validateAny, validateEmail } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './UserInputModalBody.scss';

export interface UserInputModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
}

enum UserInputFormFields {
  radioBereavementEmail = 'radioBereavementEmail',
  radioUserInputEmail = 'radioUserInputEmail',
  inputEmail = 'inputEmail',
}

export enum EmailType {
  bereavement = 'bereavement',
  contact = 'contact',
}

const UserInputModalBody: React.FunctionComponent<ModalProviderProps<UserInputModalBodyProps>> = ({
  modalData: { deathCaseServiceProvider, onClose = noop, secondButtonText },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [emailType, setEmailType] = React.useState('');

  const [contactEmail, setContactEmail] = React.useState('');

  const initialValues = {
    [UserInputFormFields.inputEmail]: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting, setStatus }) => {
        const emailValue =
          emailType === EmailType.bereavement ? deathCaseServiceProvider.serviceProvider.email : contactEmail;
        setToLocalStorage(LocalStorage.providerEmail, emailValue);
        dispatch(
          caseDocAccessRequest.started({
            setSubmitting,
            setStatus,
            id: deathCaseServiceProvider.id,
            notificationEmail: emailValue,
          })
        );
        onClose();
        closeModal();
      }}
    >
      {({ status, isSubmitting }) => {
        return (
          <Form className={styles.formContainer}>
            <>
              <Row>
                <Typography className={styles.bottomSpacing} msg={t(Messages.labelChooseEmail)} tag="span" />
              </Row>

              <Row>
                <Col size={5}>
                  <RadioButton
                    value={EmailType.bereavement}
                    label={deathCaseServiceProvider.serviceProvider.email}
                    subLabel={t(Messages.labelBereavementEmail)}
                    checked={emailType === EmailType.bereavement}
                    name={UserInputFormFields.radioBereavementEmail}
                    onChange={(e: any) => setEmailType(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 13) {
                        const buttonValue = document
                          .getElementById(EmailType.bereavement)
                          ?.getAttribute('data-radio-value');
                        setEmailType(buttonValue || EmailType.bereavement);
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={12}>
                  <Typography
                    className={styles.providerSubtitle}
                    tag="div"
                    size="l"
                    msg={t(Messages.labelServiceProviderOr)}
                  />
                </Col>
              </Row>
              <Row constant>
                <Col className={styles.shiftTextBox}>
                  <RadioButton
                    value={EmailType.contact}
                    checked={emailType === EmailType.contact}
                    name={UserInputFormFields.radioUserInputEmail}
                    onChange={(e: any) => setEmailType(e.target.value)}
                    onlyButton={true}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 13) {
                        const buttonValue = document
                          .getElementById(EmailType.contact)
                          ?.getAttribute('data-radio-value');
                        setEmailType(buttonValue || EmailType.contact);
                      }
                    }}
                  />
                </Col>
                <Col size={6} className={styles.providerSubtitle}>
                  <input
                    placeholder={t(Messages.labelContactEmailPlaceholder)}
                    type="text"
                    name={UserInputFormFields.inputEmail}
                    className={classNames(styles.input)}
                    onChange={(e: any) => setContactEmail(e.target.value)}
                  />
                </Col>
              </Row>
            </>
            <FormError formError={status} />
            <div className={classNames(styles.actions)}>
              <GridRow fullWidth withRowGap>
                <GridCol size={12}>
                  <Button
                    label={secondButtonText}
                    size={ButtonSizes.fill}
                    type={ButtonTypes.submit}
                    loading={isSubmitting}
                    disabled={emailType === EmailType.contact && contactEmail === ''}
                  />
                </GridCol>
              </GridRow>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserInputModalBody;
