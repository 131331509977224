import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import TextInput, { InputTypes } from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import { useTranslations } from '@Hooks/useTranslations';
import { sendNewProvName } from '@Store/provider/provider.actions';
import { noop } from '@Utils/helpers';
import { validateRequired } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './NewProviderFormModalBody.scss';

export interface NewProviderFormModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
  id: string;
}

enum ProviderFormFields {
  category = 'category',
  subCategory = 'subCategory',
  provider = 'provider',
  accountIdentifierPrimary = 'accountIdentifierPrimary',
  accountIdentifierSecondary = 'accountIdentifierSecondary',
  newProviderName = 'newProviderName',
  comments = 'comments',
}

const NewProviderFormModalBody: React.FunctionComponent<ModalProviderProps<NewProviderFormModalBodyProps>> = ({
  modalData: { buttonText, onClose = noop, secondButtonText },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);

  const onButtonClick = () => {
    onClose();
    closeModal();
  };

  const initialValues = {
    [ProviderFormFields.newProviderName]: '',
    [ProviderFormFields.comments]: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        [ProviderFormFields.newProviderName]: validateRequired(t),
      })}
      onSubmit={(values) => {
        dispatch(
          sendNewProvName.started({
            newServiceProviderName: values.newProviderName,
            comments: values.comments,
            setIsSending,
          })
        );
      }}
    >
      {({ status, handleSubmit }) => {
        return (
          <Form className={styles.formContainer} onSubmit={handleSubmit}>
            <>
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={ProviderFormFields.newProviderName}
                    type={InputTypes.text}
                    component={TextInput}
                    label="New Provider Name"
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={ProviderFormFields.comments}
                    type={InputTypes.textArea}
                    component={TextInput}
                    label="Comments"
                  />
                </GridCol>
              </GridRow>
            </>
            <FormError formError={status} />
            <div className={classNames(styles.actions)}>
              <GridRow fullWidth withRowGap>
                <GridCol size={6}>
                  <Button
                    label={buttonText}
                    size={ButtonSizes.fill}
                    style={ButtonStyles.white}
                    onClick={onButtonClick}
                  />
                </GridCol>
                <GridCol size={6}>
                  <Button
                    label={secondButtonText}
                    size={ButtonSizes.fill}
                    style={ButtonStyles.black}
                    type={ButtonTypes.submit}
                    disabled={isSending}
                    loading={isSending}
                  />
                </GridCol>
              </GridRow>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewProviderFormModalBody;
