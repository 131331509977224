import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox/CautionBox';
import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { GenericModalType } from '@Components/Modal/GenericModalBody/GenericModalBody';
import { PaperWidths } from '@Components/Paper';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue, ModalTypes, NodeDefiner } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorNode,
  CollaboratorPermission,
  CollaboratorRelationshipFe,
  DeathCaseNode,
  LifeCaseNode,
  UserAccountType,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import KnownExecutors from '@Routes/deathCase/DeathCasePage/ExecutorsForm/KnownExecutors';
import { showModal } from '@Store/app/app.actions';
import { updateDC, addExecutor } from '@Store/dc/dc.actions';
import { updateLC } from '@Store/lc/lc.actions';
import { getPermissionSelectOption } from '@Utils/form';
import { manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { LoadableItem } from '@Utils/types';
import { validateEmail, validateName, validatePhone } from '@Utils/validator';

import styles from './ExecutorsForm.scss';

export interface ExecutorsFormProps {
  containerClasses?: string;
  isLifeCase?: boolean;
  isUserProfessional?: boolean;
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  user: UserNode | null;
}

export interface ExecutorsFormStateProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  user: UserNode | null;
}

export enum ExecutorFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  hasExecutors = 'hasExecutors',
  meExecutor = 'meExecutor',
  permission = 'permission',
}

type ExecutiveCollaboratorFields = Extract<
  ExecutorFormFields,
  | ExecutorFormFields.firstName
  | ExecutorFormFields.lastName
  | ExecutorFormFields.email
  | ExecutorFormFields.phone
  | ExecutorFormFields.permission
>;

export interface ExecutorsFormValues {
  [ExecutorFormFields.firstName]: string;
  [ExecutorFormFields.lastName]: string;
  [ExecutorFormFields.email]: string;
  [ExecutorFormFields.phone]: string;
  [ExecutorFormFields.hasExecutors]: boolean;
  [ExecutorFormFields.meExecutor]: boolean;
  [ExecutorFormFields.permission]: { value: string; label: string };
}

const emptyFormValues = {
  [ExecutorFormFields.firstName]: '',
  [ExecutorFormFields.lastName]: '',
  [ExecutorFormFields.email]: '',
  [ExecutorFormFields.phone]: '+44',
  [ExecutorFormFields.permission]: '',
};

interface ExecutorsFormPrivateProps extends ExecutorsFormProps, ExecutorsFormStateProps {}

const ExecutorsForm: React.FunctionComponent<ExecutorsFormPrivateProps> = ({
  user,
  data,
  isLifeCase,
  containerClasses,
}) => {
  const t = useTranslations();
  const isUserProfessional = user?.accountType === UserAccountType.Professional;
  const executors = data?.item?.executors.filter((ex: CollaboratorNode) => ex.showCollaborator) || [];

  const dispatch = useDispatch();
  const [isFormVisible, setFormVisible] = useState(false);

  const isUserExecutor = () => {
    if (data?.item?.__typename === NodeDefiner.DeathCase) {
      const isAlreadyUserExec = executors.filter((e: CollaboratorNode) => e.email === user?.email);
      if (isAlreadyUserExec.length > 0) {
        return false;
      }
    }
    return false;
  };

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const permissionSelectOptions = Object.values(CollaboratorPermission).map(getPermissionSelectOption(t));

  const userDataValues = {
    [ExecutorFormFields.firstName]: user?.firstName,
    [ExecutorFormFields.lastName]: user?.lastName,
    [ExecutorFormFields.email]: user?.email,
    [ExecutorFormFields.phone]: user?.phone,
    [ExecutorFormFields.permission]: !isLifeCase
      ? permissionSelectOptions.find((ps: any) => ps.value === CollaboratorPermission.Owner)
      : permissionSelectOptions.find((ps: any) => ps.value === CollaboratorPermission.FullAccess),
  };

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm, values } = useFormikContext();
    const { hasExecutors, meExecutor } = values as ExecutorsFormValues;

    React.useEffect(() => {
      if (!executors.length) {
        setFormVisible(hasExecutors);
      }
      const getFieldValue = (fieldName: ExecutiveCollaboratorFields) => {
        if (!hasExecutors) {
          return emptyFormValues[fieldName];
        }
        return meExecutor ? userDataValues[fieldName] : emptyFormValues[fieldName];
      };
      const toggleExecutorFormField = (fieldName: ExecutiveCollaboratorFields) => {
        setFieldValue(fieldName, getFieldValue(fieldName), false);
        setFieldTouched(fieldName, false, false);
      };
      toggleExecutorFormField(ExecutorFormFields.firstName);
      toggleExecutorFormField(ExecutorFormFields.lastName);
      toggleExecutorFormField(ExecutorFormFields.email);
      toggleExecutorFormField(ExecutorFormFields.phone);
      toggleExecutorFormField(ExecutorFormFields.permission);
    }, [setFieldValue, setFieldTouched, resetForm, hasExecutors, meExecutor]);

    return null;
  };

  const handlePermissionChange = (perm: any, setFieldValue: any) => {
    if (perm.value === CollaboratorPermission.Owner && user?.accountType === UserAccountType.Professional) {
      dispatch(
        showModal({
          type: ModalTypes.genericModal,
          params: {
            data: {
              icon: 'alert-triangle',
              title: t('title_transfer_owner' as Messages),
              bodyText: t('text_transfer_owner' as Messages),
              firstButtonMsg: t('button_transfer_owner' as Messages),
              secondButtonMsg: t('link_not_transfer' as Messages),
              isSecondButtonLink: true,
              genericType: GenericModalType,
              id: '',
              extraParameter: perm,
              onClose: () => setFieldValue(ExecutorFormFields.permission, emptySelectorValue),
            },
            title: '',
            modalSize: PaperWidths.m581,
          },
        })
      );
    }
  };

  const tooltip_desc_will_executor = manipulateTranslations(
    t,
    'tooltip_desc_will_executor',
    "The executor is appointed under a Will.<br/><br/>An executor is legally responsible for carrying out the instructions in the person's will and handling their estate (their money, property, and possessions).<br/><br/>This differs from the appointment of an administrator, who is appointed by the courts in the absence of a Will."
  );

  const tooltip_title_will_executor = manipulateTranslations(
    t,
    'tooltip_title_will_executor',
    'How would I know who the executor is?'
  );

  const showMeExecField = (values: any) => {
    if (values.hasExecutors) {
      if (isFormVisible) {
        return !data.item?.isAlsoExecutor;
      }
      if (data.item?.isAlsoExecutor) {
        return false;
      }
      return true;
    }
    return false;
  };

  const onFocus = () => {
    const emailInput = document.getElementById('emailInput');
    const cautionBox = document.getElementById('cautionBox');
    if (emailInput && cautionBox)
      emailInput.addEventListener('focus', () => {
        cautionBox.style.display = 'flex';
      });
    if (emailInput && cautionBox)
      emailInput.addEventListener('blur', () => {
        cautionBox.style.display = 'none';
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [ExecutorFormFields.firstName]: '',
        [ExecutorFormFields.lastName]: '',
        [ExecutorFormFields.email]: '',
        [ExecutorFormFields.phone]: '+44',
        [ExecutorFormFields.permission]: emptySelectorValue,
        [ExecutorFormFields.hasExecutors]: executors.length > 0,
        [ExecutorFormFields.meExecutor]: isUserExecutor(),
      }}
      validationSchema={Yup.object({
        [ExecutorFormFields.firstName]: validateName(t),
        [ExecutorFormFields.lastName]: validateName(t),
        [ExecutorFormFields.email]: validateEmail(t),
        [ExecutorFormFields.phone]: validatePhone(t),
      })}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { meExecutor, firstName, lastName, email, phone, permission } = values;
        const caseId = data.item ? data.item.id : '';
        if (!meExecutor)
          dispatch(
            addExecutor.started({
              setSubmitting,
              setStatus,
              resetForm,
              baseCase: id,
              relationship: CollaboratorRelationshipFe.Executor,
              firstName,
              lastName,
              email: email.trim(),
              phone: removeLeadingZeroAndSpaceInContact(phone),
              permission: Object.values(CollaboratorPermission).find((perms: any) => perms === permission.value),
              isAlsoExecutor: !meExecutor,
            })
          );
        else if (isLifeCase) {
          dispatch(
            updateLC.started({
              setSubmitting,
              setStatus,
              id: caseId,
              isAlsoExecutor: meExecutor,
              relationship: data.item?.relationship,
            })
          );
        } else {
          dispatch(
            updateDC.started({
              setSubmitting,
              setStatus,
              id: caseId,
              isAlsoExecutor: meExecutor,
              relationship: data.item?.relationship,
            })
          );
        }
      }}
    >
      {({ isSubmitting, status, setStatus, values, setFieldValue }) => (
        <>
          <Form>
            <div className={classNames(containerClasses, styles.executorsForm)}>
              <Row justifyBetween>
                <Col className={styles.bottomSpacing}>
                  <Field
                    name={ExecutorFormFields.hasExecutors}
                    component={ToggleSwitchInput}
                    label={t(Messages.labelHasExecutors)}
                    disabled={executors.length > 0}
                    infoMessage={!isLifeCase ? tooltip_desc_will_executor : undefined}
                    infoTitle={!isLifeCase ? tooltip_title_will_executor : undefined}
                  />
                </Col>
              </Row>
              {showMeExecField(values) && (
                <Row justifyBetween>
                  <Col className={styles.bottomSpacing}>
                    <Field
                      name={ExecutorFormFields.meExecutor}
                      component={ToggleSwitchInput}
                      label={isLifeCase ? t('label_life_case_me_executor' as Messages) : t(Messages.labelMeExecutor)}
                      disabled={executors.length > 0}
                      infoMessage={!isLifeCase ? tooltip_desc_will_executor : undefined}
                      infoTitle={!isLifeCase ? tooltip_title_will_executor : undefined}
                    />
                  </Col>
                </Row>
              )}
              <KnownExecutors
                setStatus={setStatus}
                executors={executors}
                user={user}
                caseId={id}
                isLifeCase={isLifeCase}
                isUserProfessional={isUserProfessional}
              />
              {isFormVisible && (
                <GrayPaper width="width-full">
                  <Row className={styles.bottomSpacing}>
                    <Typography msg={`Executor ${executors.length + 1}`} tag="div" size="m" bold />
                  </Row>
                  <GridRow>
                    <GridCol size={5}>
                      <Field
                        name={ExecutorFormFields.firstName}
                        type="text"
                        component={TextInput}
                        label="First name"
                        disabled={values.meExecutor}
                        required
                      />
                    </GridCol>
                    <GridCol size={5}>
                      <Field
                        name={ExecutorFormFields.lastName}
                        type="text"
                        component={TextInput}
                        label="Last name"
                        disabled={values.meExecutor}
                        required
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={8} className={classNames(styles.executorsFormEmailFieldWrapper)}>
                      <Field
                        name={ExecutorFormFields.email}
                        type="text"
                        id="emailInput"
                        component={TextInput}
                        label="Email address"
                        disabled={values.meExecutor}
                        required
                        onFocus={onFocus}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={8}>
                      <CautionBox
                        containerClassName={styles.cautionBox}
                        info={manipulateTranslations(
                          t,
                          'label_email_caution',
                          "We'll be sending an email to this individual, inviting them to make an account so they can see the information you've given."
                        )}
                        id="cautionBox"
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={8} className={styles.executorsFormFieldWrapper}>
                      <Field
                        name={ExecutorFormFields.phone}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldExecutivePhone)}
                        disabled={values.meExecutor}
                        required
                      />
                    </GridCol>
                  </GridRow>

                  <GridRow>
                    <GridCol size={9} className={styles.executorsFormFieldWrapper}>
                      <Field
                        name={ExecutorFormFields.permission}
                        label="Level of access"
                        component={SelectInput}
                        onSelectChange={(e: any) => handlePermissionChange(e, setFieldValue)}
                        options={
                          values.meExecutor || (isUserProfessional && !isLifeCase)
                            ? permissionSelectOptions
                            : permissionSelectOptions.filter((pso: any) => pso.value !== CollaboratorPermission.Owner)
                        }
                        selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                        disabled={values.meExecutor}
                        required
                      />
                    </GridCol>
                  </GridRow>

                  <GridRow>
                    <GridCol size={4}>
                      <Button
                        type={ButtonTypes.submit}
                        label="Add"
                        loading={isSubmitting}
                        icon="plus"
                        iconSize={IconSizes.ss}
                        constant
                      />
                    </GridCol>
                  </GridRow>
                </GrayPaper>
              )}
              {!isFormVisible && values.hasExecutors && (
                <Row justifyCenter>
                  <Button
                    label="Add another"
                    loading={isSubmitting}
                    icon="person-add"
                    style={ButtonStyles.transparent}
                    onClick={() => setFormVisible(true)}
                    constant
                  />
                </Row>
              )}
            </div>
            <AutoToggleForm />
            <FormError formError={status} />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ExecutorsForm;
