import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { Links, LocalStorage } from '@Config/constants';

import { authActions } from '../store/actions';

import { getFromLocalStorage, setToLocalStorage } from './localStorage';

export interface AuthenticatedComponentProps {
  actions: {
    getUserData: typeof authActions.getUserData.started;
  };
  dispatch: Dispatch;
}

export const authenticatedOnlyComponent = (WrappedComponent: any) => {
  const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch,
    actions: {
      getUserData: bindActionCreators(authActions.getUserData.started, dispatch),
    },
  });

  class AuthenticatedComponent extends React.PureComponent<AuthenticatedComponentProps, {}> {
    componentDidMount() {
      if (this.isLoggedIn()) {
        this.props.actions.getUserData({});
      }
    }

    // eslint-disable-next-line class-methods-use-this
    isLoggedIn() {
      return getFromLocalStorage(LocalStorage.userToken) !== null;
    }

    render() {
      if (this.isLoggedIn()) {
        return <WrappedComponent {...this.props} />;
      }
      const urlSearchParams = new URLSearchParams(window.location.search);
      const paramAccessCode = urlSearchParams.get('caseproviderid') || '';
      const spNotificationUrl = window.location.pathname.split('/');
      if (paramAccessCode !== '') {
        const deathCaseId = window.location.pathname.split('/')[3];
        setToLocalStorage(LocalStorage.isNotificationSummary, `${deathCaseId}/${paramAccessCode}`);
      }
      if (spNotificationUrl.length > 1) {
        if (spNotificationUrl[1].toLowerCase() === 'notification') {
          setToLocalStorage(LocalStorage.spNotification, spNotificationUrl[2]);
        }
      }
      return <Redirect to={{ pathname: generatePath(Links.login) }} />;
    }
  }

  return connect(null, mapDispatchToProps)(AuthenticatedComponent);
};
