import classNames from 'classnames';
import * as React from 'react';

import Typography, { TextSizes } from '@Components/Typography';

import styles from './List.scss';

export interface ListProps {
  listItems: string[];
  containerClassName?: string;
  isOrderedList?: boolean;
  fontSize?: TextSizes;
  isHelvetica?: boolean;
  listSpacingClass?: string;
}

const List: React.FunctionComponent<ListProps> = ({
  listItems,
  containerClassName,
  isOrderedList,
  fontSize = 'm',
  isHelvetica,
  listSpacingClass,
}) => {
  return (
    <ul className={classNames(containerClassName, isOrderedList ? styles.orderedList : styles.unorderedList)}>
      {listItems.map((headerItem, idx) => {
        return (
          <li className={listSpacingClass} key={idx}>
            {isHelvetica ? (
              <Typography tag="span" msg={headerItem} size={fontSize} family="helvetica" />
            ) : (
              <Typography tag="span" msg={headerItem} size={fontSize} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default List;
