import classNames from 'classnames';
import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import Button from '@Components/Button';
import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import DiscoveryInfo from '@Routes/lifeCase/ReadyLCPage/DiscoveryInfo';

import { ModalProviderProps } from '../Modal';

import styles from './DiscoveryModalBody.scss';

export interface DiscoveryModalBodyProps {
  isFirstTime?: boolean;
  baseCaseId: string;
}

const DiscoveryModalBody: React.FunctionComponent<ModalProviderProps<DiscoveryModalBodyProps>> = ({
  modalData: { isFirstTime, baseCaseId },
  closeModal,
}) => {
  const t = useTranslations();
  const history = useHistory();

  const [showDiscoveryWorks, setShowDiscoveryWorks] = React.useState(false);
  const [showMoreInfo, setShowMoreInfo] = React.useState(true);

  React.useEffect(() => {
    const paymentParam = history.location.state ? history.location.state : '';
    if (paymentParam === 'payment_success') {
      history.replace({
        state: null,
      });
    }
  });

  return !showDiscoveryWorks ? (
    <div className={styles.p24}>
      {isFirstTime && (
        <>
          <Row constant justifyCenter>
            <Icon icon="star" />
          </Row>
          <Typography size="xl" textCenter bold tag="div" msg={t('title_life_ledger_discovery' as Messages)} />
          <Row className={styles.mb16} justifyCenter>
            <Col size={9}>
              <Typography size="m" textCenter tag="div" msg={t('subtitle_life_ledger_discovery' as Messages)} />
            </Col>
          </Row>
        </>
      )}

      <div className={styles.containers}>
        <Row constant justifyCenter className={styles.titleIconGap} alignCenter>
          <Icon icon="flash" />
          <Typography tag="div" size="l" bold msg={t('title_connect_your_bank_account' as Messages)} />
        </Row>

        <Row justifyCenter>
          <Col size={10}>
            <Typography
              color="footerColor"
              tag="div"
              size="l"
              msg={t('subtitle_connect_your_bank_account' as Messages)}
            />
          </Col>
        </Row>

        <Button
          onClick={() => {
            closeModal();
            history.push(generatePath(Links.institutions, { id: baseCaseId }));
          }}
          className={styles.buttonSpacing}
          label={t('button_connect_bank_account' as Messages)}
          isFullWidth
        />

        {isFirstTime && (
          <Row justifyCenter>
            <Typography tag="div" color="footerColor" size="m" msg="You will be redirected to  connect your account" />
          </Row>
        )}
      </div>

      {showMoreInfo && isFirstTime && (
        <Typography
          onClick={() => {
            setShowDiscoveryWorks(true);
            setShowMoreInfo(false);
          }}
          textCenter
          className={classNames(styles.mt10, styles.lh24, styles.pb25)}
          msg="More info about Life Ledger Discovery"
          color="electricBlue"
          tag="div"
          size="l"
          underline
          bold
        />
      )}
    </div>
  ) : (
    <div className={styles.p24}>
      <Row
        constant
        onClick={() => {
          setShowDiscoveryWorks(false);
          setShowMoreInfo(true);
        }}
        alignCenter
        className={styles.backButton}
      >
        <Icon icon="chevronLeft" />
        <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
      </Row>
      <DiscoveryInfo />
    </div>
  );
};

export default DiscoveryModalBody;
