import * as dotProp from 'dot-prop-immutable';
import { Action, Success } from 'typescript-fsa';

import { ModalParams } from '@Components/Modal/Modal';
import { Defaults, ModalTypes } from '@Config/constants';
import { ApplicationConfigFragment } from '@Graphql/graphqlTypes.generated';
import { createReducer } from '@Utils/redux';

import { getAppConfig, hideModal, showModal } from './app.actions';
import { ShowModalPayload } from './app.types';

export type ModalState = {
  isVisible: boolean;
  modalType: null | ModalTypes;
  modalParams: ModalParams<any>;
};

const modalInitialState = {
  isVisible: false,
  modalType: null,
  modalParams: {},
};

export type AppState = {
  config: ApplicationConfigFragment;
  modal: ModalState;
  isReady: boolean;
};

export const initialState: AppState = {
  config: {
    version: '',
    enabledLanguages: [],
    defaultLanguage: Defaults.language,
    translations: {
      [Defaults.language]: {},
    },
    stripeApiPublicKey: '',
    googleTagManagerId: '',
    enableTuCheck: false,
  },
  isReady: false,
  modal: modalInitialState,
};

const appReducer = createReducer<AppState>(initialState, {
  [getAppConfig.done.type]: (state, action: Action<Success<{}, ApplicationConfigFragment>>) => {
    const newState = dotProp.set(state, 'config', action.payload.result);
    return dotProp.set(newState, 'isReady', true);
  },
  [showModal.type]: (state, action: Action<ShowModalPayload<any>>) => {
    return dotProp.set(state, 'modal', {
      isVisible: true,
      modalType: action.payload.type,
      modalParams: action.payload.params,
    });
  },
  [hideModal.type]: (state) => {
    return dotProp.set(state, 'modal', modalInitialState);
  },
});

export default appReducer;
