import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useField, useFormikContext, FieldProps } from 'formik';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { noop } from '@Utils/helpers';

import { RelationshipLevel, RelationShipDefinition } from '../../../../model/Relationship';
import InputContainer from '../InputContainer';

import styles from './MultiSelect.scss';

interface MultiSelectProps extends FieldProps {
  options: RelationShipDefinition[];
  label: Messages;
  placeholder: Messages;
  onSelectChange: (e: any) => void;
  isMulti: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  size?: any;
  controlDescription?: string;
  position?: TooltipPosition;
}

const MultiSelect: React.FunctionComponent<MultiSelectProps> = (props) => {
  const {
    field,
    onSelectChange = noop,
    form,
    label,
    options,
    controlDescription,
    position = TooltipPosition.top,
  } = props;
  const [input, meta] = useField(field);
  const [selected, setSelected] = React.useState(options);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    setFieldValue(input.name, selected);
  }, [selected, input.name, setFieldValue]);

  const preventSecondLevelSelection = (val: any) => {
    if (val.relationshipType === RelationshipLevel.secondLevel) {
      const secondLevelItems = selected.filter(
        (x: RelationShipDefinition) => x.relationshipType === RelationshipLevel.secondLevel && x.selected === true
      );

      if (secondLevelItems.length === 0) {
        return true;
      }
      return false;
    }
    return true;
  };

  const handleOnChange = (val: any, idx: number) => {
    const { value = [] } = input;
    const optionSelected = selected;
    const index = (selected || []).findIndex(
      (item: RelationShipDefinition) => item && item.value === val.value && item.selected === true
    );
    if (index >= 0 && !val.disabled) {
      value[idx].selected = false;
      optionSelected[idx].selected = false;
    } else if (preventSecondLevelSelection(val)) {
      value[idx].selected = true;
      optionSelected[idx].selected = true;
    }
    setFieldValue(input.name, value);
    setSelected(optionSelected);
    onSelectChange(val);
  };

  const firstLevelRelations = selected.filter(
    (rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.firstLevel
  );
  const secondLevelRelations = selected.filter(
    (rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.secondLevel
  );

  const isOpaqueObject = () => {
    const selectedItems = selected.filter(
      (x: RelationShipDefinition) => x.selected === true && x.relationshipType === RelationshipLevel.secondLevel
    );
    if (selectedItems.length > 0) {
      return styles.opaqueCheckBox;
    }
    return styles.handSymbol;
  };

  const disableRelationship = (isDisabled: boolean | undefined) => {
    return isDisabled ? styles.opaqueCheckBox : styles.handSymbol;
  };

  const firstLevelRelationshipDeskSize = secondLevelRelations.length > 0 ? 6 : 12;
  const firstLevelRelationshipTabletSize = secondLevelRelations.length > 0 ? 7 : 12;

  return (
    <InputContainer
      form={form}
      label={label}
      meta={meta}
      showErrorIcon={true}
      containerClass={classNames(styles.container)}
      controlDescription={controlDescription}
    >
      <Row size={12}>
        {firstLevelRelations.length > 0 && (
          <>
            <Col
              size={isTablet ? firstLevelRelationshipTabletSize : firstLevelRelationshipDeskSize}
              className={classNames(styles.listContainer)}
            >
              {firstLevelRelations.map((item: RelationShipDefinition) => {
                return (
                  <>
                    <div
                      key={item.value} //eslint-disable-next-line
                      tabIndex={0} 
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          handleOnChange(item, item.index);
                        }
                      }}
                      onClick={() => handleOnChange(item, item.index)}
                      className={classNames(styles.listItem, styles.handSymbol, {
                        [styles.selectedLayout]: item.selected,
                      })}
                    >
                      <span
                        className={classNames(
                          styles.flexDisplay,
                          styles.label,
                          item.selected ? styles.active : '',
                          disableRelationship(item.disabled)
                        )}
                      >
                        {item.label}
                        {item.infoTitle && (
                          <Tooltip
                            position={position}
                            message={item.infoMessage ? item.infoMessage : ''}
                            title={item.infoTitle}
                            // className={styles.tooltipMaxWidth}
                            messageClassName={styles.noBold}
                          >
                            <Icon className={styles.infoIcon} size={IconSizes.ss} icon="info" />
                          </Tooltip>
                        )}
                      </span>
                      {item.selected ? (
                        <span className={classNames(styles.checkBox)}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="#4FB286"
                            size="2x"
                            className={classNames(styles.checkIcon, disableRelationship(item.disabled))}
                          />
                        </span>
                      ) : (
                        <span className={classNames(styles.checkBox)} />
                      )}
                    </div>
                  </>
                );
              })}
            </Col>

            {secondLevelRelations.length > 0 && <Col size={1} />}
          </>
        )}
        {secondLevelRelations.length > 0 && (
          <Col size={6} className={classNames(styles.listContainer)}>
            {secondLevelRelations.map((item: RelationShipDefinition) => {
              return (
                <>
                  <div
                    key={item.value} //eslint-disable-next-line
                    tabIndex={0} 
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        handleOnChange(item, item.index);
                      }
                    }}
                    onClick={() => handleOnChange(item, item.index)}
                    className={classNames(styles.listItem, { [styles.selectedLayout]: item.selected })}
                  >
                    <span
                      className={classNames(
                        styles.label,
                        { [styles.handSymbol]: item.selected },
                        item.selected ? styles.active : isOpaqueObject()
                      )}
                    >
                      {item.label}
                    </span>
                    {item.selected ? (
                      <span className={classNames(styles.checkBox, styles.handSymbol)}>
                        <FontAwesomeIcon className={styles.checkIcon} size="2x" icon={faCheck} color="#4FB286" />
                      </span>
                    ) : (
                      <span className={classNames(isOpaqueObject(), styles.checkBox)} />
                    )}
                  </div>
                </>
              );
            })}
          </Col>
        )}
      </Row>
    </InputContainer>
  );
};

export default MultiSelect;
