import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@Components/application/GrayPaper';
import Button, { ButtonStyles } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import DataWithLabel from '@Components/DataWithLabel';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { RestrictedCaseServiceProviderNode, RestrictedDeathCaseNode, UserTitle } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { capitalize } from '@Utils/helpers';
import { somethingWrongHandler } from '@Utils/modal';

import styles from './SummaryInfoContact.scss';

export interface SummaryInfoContactProps {
  label: string;
  name?: string;
  email: string;
  phone: string;
  extraNote?: string;
  verifiedAt?: Date | null;
  relationshipTitle?: string;
  notifierNote?: string;
  professionalType?: string;
  companyName?: string;
  position?: string;
  address1?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
  header?: string;
  description?: string;
  userAccountType?: string;
  isFromNotifier?: boolean;
  deathCase?: RestrictedDeathCaseNode;
  deathCaseServiceProvider?: RestrictedCaseServiceProviderNode;
  accessCode?: string;
  isCollaborator?: boolean;
  firstName?: string;
  lastName?: string;
}

const SummaryInfoContact: React.FunctionComponent<SummaryInfoContactProps> = ({
  label,
  email,
  phone,
  relationshipTitle,
  address1,
  address2,
  companyName,
  position,
  city = '',
  postalCode = '',
  header = '',
  description = '',
  userAccountType,
  deathCase,
  deathCaseServiceProvider,
  accessCode = '',
  isCollaborator = false,
  firstName,
  lastName,
}) => {
  const t = useTranslations();
  const relationshipLabel = relationshipTitle || '';
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);

  const notifierTitle =
    deathCase?.notifier.title && deathCase?.notifier.title.toUpperCase() === UserTitle.Other
      ? deathCase?.notifier.titleOther
      : deathCase?.notifier.title;
  const getRelationship = (): string[] | undefined => {
    return deathCase?.notifier.allRelationship?.map((rel: string) =>
      t(`${Messages.labelRelationshipPrefix}${rel.toLowerCase()}` as Messages)
    );
  };
  return (
    <Paper
      width="width-full"
      className={classNames(userAccountType ? styles.professionalPaper : styles.normalGrayPaper, styles.sameHeight)}
    >
      <div className={styles.deceasedContainer}>
        {deathCaseServiceProvider && (
          <Row justifyEnd constant>
            <LinkButton
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode);
                }
              }}
              linkTitle="Something wrong?"
              onClick={() => somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode)}
            />
          </Row>
        )}
        <CardTitle heading={header} description={description} />
      </div>
      {!isCollaborator && (
        <Row size={12} columnMobile constant>
          <Col size={6} constant>
            <DataWithLabel data={`${capitalize(notifierTitle || '-')}`} label={t(Messages.fieldTitle)} dashForEmpty />
          </Col>
          <Col size={1} constant />
          <Col size={5}>
            <DataWithLabel
              data={
                deathCase?.notifier.birthDate !== 'None'
                  ? languageBasedDate(deathCase?.notifier.birthDate, currentLanguage)
                  : '-'
              }
              label={t(Messages.labelBirthDate)}
            />
          </Col>
        </Row>
      )}

      {!isCollaborator && (
        <Row constant columnMobile>
          {deathCase?.notifier.firstName && (
            <Col size={6} constant>
              <DataWithLabel
                data={[deathCase.notifier.firstName, deathCase.notifier.lastName].join(' ')}
                label={label}
                dashForEmpty
              />
            </Col>
          )}
          <Col size={1} constant />
          {deathCase && (
            <Col size={5}>
              <DataWithLabel dataArray={getRelationship()} label={relationshipLabel} dashForEmpty />
            </Col>
          )}
        </Row>
      )}

      {isCollaborator && (
        <Row constant columnMobile>
          <Col size={6} constant>
            <DataWithLabel data={firstName} label="Name" dashForEmpty />
          </Col>
        </Row>
      )}

      {isCollaborator && (
        <Row constant columnMobile>
          <Col size={6} constant>
            <DataWithLabel data={lastName} label="Surname" dashForEmpty />
          </Col>
        </Row>
      )}

      <Row constant columnMobile>
        <Col size={6}>
          <DataWithLabel dashForEmpty label="Email" data={email} />
        </Col>
        <Col size={1} constant />
        <Col size={5}>
          <DataWithLabel dashForEmpty label="Contact number" data={phone} />
        </Col>
      </Row>

      {companyName && (
        <DataWithLabel data={companyName} label={t(Messages.labelCompanyName)} conditional dashForEmpty />
      )}

      {!isCollaborator && (
        <Row constant columnMobile>
          <>
            <Col size={6}>
              <DataWithLabel
                dashForEmpty
                label={t(Messages.fieldBuildingName)}
                data={deathCase?.notifier.buildingName}
              />
            </Col>
            <Col size={1} constant />
          </>
          <Col size={5}>
            <DataWithLabel
              dashForEmpty
              label={t(Messages.fieldBuildingNumber)}
              data={deathCase?.notifier.buildingNumber === 'None' ? '' : deathCase?.notifier.buildingNumber}
            />
          </Col>
        </Row>
      )}

      <Row constant columnMobile>
        <>
          <Col size={6}>
            <DataWithLabel dashForEmpty label="Address1" data={address1 || ''} />
          </Col>
          <Col size={1} constant />
        </>
        <Col size={5}>
          <DataWithLabel dashForEmpty label="Address2" data={address2 || ''} />
        </Col>
      </Row>

      <Row constant columnMobile>
        <>
          <Col size={6}>
            <DataWithLabel dashForEmpty label="City" data={city} />
          </Col>
          <Col size={1} constant />
        </>
        <Col size={5}>
          <DataWithLabel dashForEmpty label="Postcode" data={postalCode} />
        </Col>
      </Row>

      {position && (
        <DataWithLabel data={position} label={t(Messages.labelPositionInCompany)} conditional dashForEmpty />
      )}
      {/* </Col>
      </Row> */}
      {deathCase?.letterToCompanys && deathCase.letterToCompanys.length > 0 && (
        <Row>
          <Button
            style={ButtonStyles.transparent}
            onClick={() => window.open(deathCase?.letterToCompanys[0].file)}
            icon="download"
            iconSize={IconSizes.ss}
            constant
            isFullWidth
          >
            <Typography msg="Download letter of autorisation" tag="span" />
          </Button>
        </Row>
      )}
    </Paper>
  );
};

export default SummaryInfoContact;
