import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Button from '@Components/Button';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { noop } from '@Utils/helpers';

import { ModalProviderProps } from '../Modal';

import styles from './DeathCaseWelcomeModalBody.scss';

export interface DeathCaseWelcomeModalBodyProps {
  title: string;
  buttonText: string;
  secondButtonText: string;
  id: string;
  isLifeCase?: boolean;
  onClose?: () => void;
}

const DeathCaseWelcomeModalBody: React.FunctionComponent<ModalProviderProps<DeathCaseWelcomeModalBodyProps>> = ({
  modalData: { onClose = noop },
  closeModal,
}) => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const t = useTranslations();
  const user = useSelector(getUser);

  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.lifeLedgerIcon}>
        <img
          width={isTablet ? '128' : '173'}
          height="37"
          src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
          alt="" // eslint-disable-next-line
        />
      </div>

      <Typography size="xl" tag="h4" bold msg="Welcome to Life Ledger" />

      <Typography
        className={styles.mb40}
        size="m"
        tag="div"
        msg="Our service allows you to quickly and simply notify all of the companies connected to the deceased from one centralised place"
      />
      <Row justifyCenter size={12} className={styles.mb24}>
        <Col size={12}>
          <Button
            onClick={() => {
              onClose();
              closeModal();
            }}
            label={user?.addMore ? t(Messages.buttonAddCompanyToNotify) : t('button_continue' as Messages)}
            isFullWidth
          />
        </Col>
      </Row>
    </div>
  );
};

export default DeathCaseWelcomeModalBody;
