import * as React from 'react';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import AuthPageLayout from '@Components/layout/AuthPageLayout';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';

import { AuthSuggestions } from '../shared/SuggestionBlock/SuggestionBlock';

import ResetPasswordForm from './ForgotPasswordForm';

export interface ResetPasswordPageProps {}

const ForgotPasswordPage: React.FunctionComponent<ResetPasswordPageProps> = () => {
  const [emailSentTo, setEmailSentTo] = useState('');

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  return (
    <AuthPageLayout
      emailSentTo={emailSentTo}
      isSmallLayout={true}
      titleKey={Messages.resetPasswordFormTitle}
      leftBottomComponent={<SuggestionBlock authType={AuthSuggestions.forgotPassword} suggestLogin={false} />}
      showFooter={!(isTablet || isMobile)}
    >
      <ResetPasswordForm emailSentTo={emailSentTo} setEmailSentTo={setEmailSentTo} />
    </AuthPageLayout>
  );
};

export default ForgotPasswordPage;
