import classNames from 'classnames';
import * as React from 'react';

import styles from './GridRow.scss';

export interface RowProps {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  centerVertically?: boolean;
  withRowGap?: boolean;
  withTopPadding?: string;
  alignSelfBotton?: boolean;
}

const GridRow: React.FunctionComponent<RowProps> = ({
  children,
  className,
  fullWidth,
  withRowGap,
  centerVertically,
  withTopPadding,
}) => {
  return (
    <div
      className={classNames(styles.gridRow, className, {
        [styles.fullWidth]: fullWidth,
        [styles.centerVertically]: centerVertically,
        [styles.withRowGap]: withRowGap,
        [styles.withTopPadding]: withTopPadding,
      })}
    >
      {children}
    </div>
  );
};

export default GridRow;
