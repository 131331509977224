import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { LifeCaseNode, Permissions, useCollaboratorsQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { LoadableItem } from '@Utils/types';

import styles from './AdditionalContactSummary.scss';

export interface AdditionalContactSummaryProps {
  data: LoadableItem<LifeCaseNode>;
}

const AdditionalContactSummary: React.FunctionComponent<AdditionalContactSummaryProps> = ({ data }) => {
  const history = useHistory();
  const t = useTranslations();

  const collaboratorsData = useCollaboratorsQuery({
    variables: {
      baseCase: data.item?.id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (collaboratorsData.loading) {
    return (
      <Col size={8} textCenter alignCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const editForm = () => {
    history.push(
      generatePath(Links.lcEdit, {
        id: data.item?.id,
        subpage: 'contacts',
      })
    );
  };

  const additionalContacts = collaboratorsData.data?.collaborators.edges.map((x: any) => x.node) || [];

  const isReadOnlyUser = data.item?.myPermission === Permissions.ReadOnly;

  return (
    <>
      <ConfirmationHeader showEdit={!isReadOnlyUser} title="Additional contacts" onClick={editForm} />
      <Paper width="width-full">
        {additionalContacts.map((legal: any, idx: number) => (
          <div key={idx} className={styles.eachContactSpacing}>
            <Row className={styles.titleBottom}>
              <Typography
                msg={t(`${Messages.labelRelationshipTypePrefix}${legal.relationship.toLowerCase()}` as Messages)}
                tag="div"
                size="m"
                bold
              />
            </Row>
            <Row className={styles.addressContainer} size={12}>
              <Col size={9}>
                <Row column>
                  <Col className={styles.infoBottom}>
                    <Typography msg={`${legal.firstName} ${legal.lastName}`} tag="div" size="m" />
                  </Col>

                  <Col className={styles.infoBottom}>
                    <Typography msg={legal.email} tag="div" size="m" />
                  </Col>

                  <Col className={styles.infoBottom}>
                    <Typography msg={legal.phone} tag="div" size="m" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </Paper>
    </>
  );
};

export default AdditionalContactSummary;
