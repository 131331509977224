import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import SelectInput from '@Components/form/inputs/SelectInput';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { MissingInfoFields } from '@Components/Modal/CompanyMissingInfoModalBody/CompanyMissingInfoModalBody';
import { PaperWidths } from '@Components/Paper';
import { OptionItem } from '@Components/Select';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  ObTransactionNode,
  UpdateTransactionMutation,
  UpdateTransactionMutationVariables,
  useServiceProviderSearchLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpdateTransaction } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { noop } from '@Utils/helpers';

import styles from './CompanyCard.scss';

export interface CompanyCardProps {
  company?: ObTransactionNode;
  onSelection?: (checked: boolean, id: string, isMissingTrans?: boolean) => void;
  initialBackground: string;
  missingInfoCompanies?: ObTransactionNode;
  isCardChecked?: boolean;
}

const CompanyCard: React.FunctionComponent<CompanyCardProps> = ({
  company,
  initialBackground,
  onSelection = noop,
  missingInfoCompanies,
  isCardChecked = false,
}) => {
  const [isSelected, setIsSelected] = React.useState(false);
  const t = useTranslations();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isRes904 = useMediaQuery({ query: ReactResponsiveQueries.Res904 });
  const prevIsCardChecked = React.useRef({ isCardChecked }).current;
  const [companyOptions, setCompanyOptions] = React.useState<OptionItem[]>([]);
  const [spLogo, setSpLogo] = React.useState('');
  const [newSpName, setNewSpName] = React.useState('');
  const [caseAccountIdentifierTextInput, setCaseAccountIdentifierTextInput] = React.useState(
    company ? company.caseAccountIdentifier : ''
  );
  const [updateTransaction] = useMutation<UpdateTransactionMutation, UpdateTransactionMutationVariables>(
    mutationUpdateTransaction
  );

  React.useEffect(() => {
    if (prevIsCardChecked.isCardChecked !== isCardChecked) {
      setIsSelected(isCardChecked);
    }

    return () => {
      prevIsCardChecked.isCardChecked = isCardChecked;
    };
  }, [prevIsCardChecked, isCardChecked]);

  const [getProvidersData, providersValues] = useServiceProviderSearchLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      const suggestions = providersValues.data?.serviceProviderSearch;

      const briefcaseOption = (
        <Row constant className={styles.gapFlex} alignCenter>
          <Col className={styles.briefCaseContainer}>
            <Icon icon="briefcase" />
          </Col>
          <Col>The company is not in this list</Col>
        </Row>
      );

      const notListOption = [
        { value: '1000', labelDisplay: briefcaseOption, label: 'The company is not in this list' },
      ];

      if (suggestions && suggestions.length > 0) {
        const companySelectOptions = suggestions.map((spCompany: any) => {
          const withLogo = (
            <Row constant className={styles.gapFlex} alignCenter>
              <Col>
                <img className={styles.logoWrapper} src={spCompany.logo} alt="" />
              </Col>
              <Col>
                <Col>{spCompany.name}</Col>
              </Col>
            </Row>
          );
          const nameAsLogo = (
            <Row constant className={styles.gapFlex} alignCenter>
              <Col>
                <Col>
                  <div className={styles.initialLogoContainer}>
                    <span>{spCompany.name.charAt(0).toUpperCase()}</span>
                  </div>
                </Col>
              </Col>
              <Col>
                <Col>{spCompany.name}</Col>
              </Col>
            </Row>
          );
          return {
            value: spCompany.id,
            labelDisplay: spCompany.logo ? withLogo : nameAsLogo,
            label: spCompany.name,
            extraFlag: spCompany.logo,
          };
        });
        const newOptions = notListOption.concat(companySelectOptions);
        setCompanyOptions(newOptions);
      } else {
        setCompanyOptions(notListOption);
      }
    },
  });

  const missingInfoCompanyId = missingInfoCompanies ? missingInfoCompanies.id : '';

  const companyMissingInfoHandler = (
    missingInfoType: MissingInfoFields,
    transactionId: string,
    serviceProviderId?: string,
    caseAccountIdentifier?: string,
    caseAccountIdentifierKey?: string
  ) => {
    return dispatch(
      showModal({
        type: ModalTypes.companyMissingInfoModal,
        params: {
          data: {
            missingInfoType,
            transactionId,
            serviceProviderId,
            caseAccountIdentifier,
            caseAccountIdentifierKey,
            onClose: () => {
              if (serviceProviderId) {
                const selectedSpLogo = companyOptions.find((co: OptionItem) => co.value === serviceProviderId);
                setSpLogo(selectedSpLogo?.extraFlag || '');
                setNewSpName(selectedSpLogo?.label || '');
              }
            },
          },
          title: '',
          modalSize: PaperWidths.m672,
        },
      })
    );
  };

  const handleAutoProviderChange = (item: any) => {
    if (item.value === '1000') {
      companyMissingInfoHandler(MissingInfoFields.CompanyName, missingInfoCompanies ? missingInfoCompanies.id : '');
    } else {
      updateTransaction({
        variables: {
          input: {
            transaction: missingInfoCompanies?.id,
            serviceProvider: item.value,
          },
        },
      });
      companyMissingInfoHandler(
        MissingInfoFields.AccountNumber,
        missingInfoCompanyId,
        item.value,
        missingInfoCompanies?.additionalInformation
          ? missingInfoCompanies.additionalInformation
          : missingInfoCompanies?.unstructuredInfo
      );
    }
  };

  const missingInfoDisplayName = (missingInfoCompaniesParam: ObTransactionNode | undefined) => {
    if (missingInfoCompaniesParam?.displayName) {
      return `${missingInfoCompaniesParam.displayName.charAt(0).toUpperCase()}${
        missingInfoCompaniesParam.displayName.split(' ').length > 1
          ? missingInfoCompaniesParam.displayName.split(' ')[1].charAt(0).toUpperCase()
          : ''
      }`;
    }
    if (newSpName) {
      return `${newSpName.charAt(0).toUpperCase()}${
        newSpName.split(' ').length > 1 ? newSpName.split(' ')[1].charAt(0).toUpperCase() : ''
      }`;
    }
    return '';
  };

  const companyLogoLetter = () => {
    if (company?.displayName) {
      return company.displayName.charAt(0).toUpperCase();
    }
    if (company?.newServiceProvider) {
      return company.newServiceProvider.charAt(0).toUpperCase();
    }
    return company?.serviceProvider.name.charAt(0).toUpperCase();
  };

  const companyInfoName = () => {
    if (company?.displayName) {
      return company.displayName;
    }
    if (company?.newServiceProvider) {
      return company.newServiceProvider;
    }
    return company?.serviceProvider.name;
  };

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      paddingBottom: '8px !important',
      paddingTop: '8px !important',
      borderTop: 'unset !important',
      borderBottom: 'unset !important',
      fontSize: '14px !important',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '23rem !important',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: '14px !important',
      paddingLeft: '0px !important',
    }),
    loadingIndicator: (provided: any) => ({
      ...provided,
      display: 'none !important',
    }),
  };

  const actualSpName = missingInfoCompanies?.newServiceProvider ? missingInfoCompanies.newServiceProvider : newSpName;

  return (
    <div
      onClick={() => {
        setIsSelected(!isSelected);
        onSelection(!isSelected, company ? company.id : missingInfoCompanyId, !company);
        if (missingInfoCompanies) {
          const inputVal = missingInfoCompanies.displayName
            ? missingInfoCompanies.displayName.substring(0, 2)
            : missingInfoCompanies.unstructuredInfo.substring(0, 2);
          getProvidersData({
            variables: { searchString: inputVal },
          });
        }
      }}
      //eslint-disable-next-line
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.keyCode === EnterKeyCode) {
          setIsSelected(!isSelected);
          onSelection(!isSelected, company ? company.id : missingInfoCompanyId, !company);
          if (missingInfoCompanies) {
            const inputVal = missingInfoCompanies.displayName
              ? missingInfoCompanies.displayName.substring(0, 2)
              : missingInfoCompanies.unstructuredInfo.substring(0, 2);
            getProvidersData({
              variables: { searchString: inputVal },
            });
          }
        }
      }}
      className={classNames(styles.container, isSelected ? styles.selectedContainer : styles.notSelectedContainer)}
    >
      <Row size={12} constant>
        {company && (
          <>
            <Col alignCenter className={styles.logoContainer}>
              {company?.serviceProvider.logo ? (
                <div className={styles.logoCircle}>
                  <img className={styles.companyLogoImg} src={company.serviceProvider.logo} alt="" />
                </div>
              ) : (
                <div style={{ backgroundColor: initialBackground }} className={styles.personContainer}>
                  <Typography msg={companyLogoLetter()} tag="span" color="white" bold size="lcx" />
                </div>
              )}
            </Col>
            <Col size={8}>
              <Row column={isRes904} className={styles.wrapWords}>
                <Col size={6}>
                  <Typography className={styles.companyNameSpacing} msg={companyInfoName()} tag="div" size="m" bold />
                </Col>
                <Col size={isRes904 ? 12 : 5}>
                  <Row column>
                    <Typography msg={t('label_account_info' as Messages)} tag="div" size="s" color="footerColor" />
                    <input
                      placeholder={t('placeholder_account_info' as Messages)}
                      type="text"
                      name="caseAccountIdentifier"
                      className={styles.input}
                      value={caseAccountIdentifierTextInput}
                      onChange={(e: any) => setCaseAccountIdentifierTextInput(e.target.value)}
                      onBlur={(e: any) =>
                        updateTransaction({
                          variables: {
                            input: {
                              transaction: company.id,
                              serviceProvider: company.serviceProvider.id,
                              caseAccountIdentifier: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </>
        )}
        {missingInfoCompanies && (
          <>
            {spLogo ? (
              <div className={styles.logoCircle}>
                <img className={styles.companyLogoImg} src={spLogo} alt="" />
              </div>
            ) : (
              <div style={{ backgroundColor: initialBackground }} className={styles.personContainer}>
                <Typography
                  msg={missingInfoDisplayName(missingInfoCompanies)}
                  tag="span"
                  color="white"
                  bold
                  size="lcx"
                />
              </div>
            )}
            <Col className={styles.ml20} size={8}>
              <Row column={isRes904} className={styles.wrapWords}>
                <Col size={6}>
                  <Typography msg="Name" tag="div" size="s" color="footerColor" />
                  <Typography
                    msg={missingInfoCompanies.displayName ? missingInfoCompanies.displayName : actualSpName}
                    tag="div"
                    size="s"
                    bold
                  />
                </Col>
                <Col size={isRes904 ? 12 : 4}>
                  <Row column>
                    <Typography msg="Information" tag="div" size="s" color="footerColor" />
                    <Typography
                      msg={
                        missingInfoCompanies.additionalInformation
                          ? missingInfoCompanies.additionalInformation
                          : missingInfoCompanies.unstructuredInfo
                      }
                      tag="div"
                      size="s"
                      bold
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </>
        )}
        <Col size={6}>
          <Row alignEnd column>
            <Col>
              <div className={styles.selectionContainer}>
                {isSelected && !isMobile && (
                  <Typography msg={t('label_added' as Messages)} tag="div" bold color="successColor" size="m" />
                )}
                {!isSelected && !isMobile && (
                  <Typography msg={t('label_check_to_add' as Messages)} tag="div" color="footerColor" size="m" />
                )}
                <div className={styles.selectionCircle}>{isSelected && <Icon icon="tick" size={IconSizes.s} />}</div>
                {isSelected && isMobile && (
                  <Typography msg={t('label_added' as Messages)} tag="div" bold color="successColor" size="m" />
                )}
              </div>
            </Col>
            {!isMobile && (
              <Col size={12}>
                <div className={styles.mt24}>
                  {missingInfoCompanies && isSelected && (
                    <Formik
                      initialValues={{
                        allProviders: '',
                      }}
                      onSubmit={(values) => {
                        //eslint-disable-next-line
                    console.log(values);
                      }}
                    >
                      <div
                        style={{}}
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Field
                          label=""
                          name="allProviders"
                          component={SelectInput}
                          onSelectChange={handleAutoProviderChange}
                          placeholder="Identify company"
                          options={companyOptions}
                          customStyles={customStyles}
                          isLoading={providersValues.loading}
                        />
                      </div>
                    </Formik>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {isMobile && (
        <div className={styles.mt24}>
          {missingInfoCompanies && isSelected && (
            <Formik
              initialValues={{
                allProviders: '',
              }}
              onSubmit={(values) => {
                //eslint-disable-next-line
                    console.log(values);
              }}
            >
              <div
                style={{}}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Field
                  label=""
                  name="allProviders"
                  component={SelectInput}
                  onSelectChange={handleAutoProviderChange}
                  placeholder="Identify company"
                  options={companyOptions}
                  customStyles={customStyles}
                  isLoading={providersValues.loading}
                />
              </div>
            </Formik>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyCard;
