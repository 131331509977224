import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { EnterKeyCode, Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useLifeCaseQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getFromLocalStorage } from '@Utils/localStorage';
import { obNeverLeaveHandler } from '@Utils/modal';

import styles from './CompanyStepper.scss';

interface CompanyStepperProps {
  activeStepIdx: number;
  steps: string[];
  id?: string;
}

const CompanyStepper: React.FunctionComponent<CompanyStepperProps> = ({ activeStepIdx, id }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;

  const lifeCase = useLifeCaseQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { id: id || '' },
  });

  if (lifeCase.loading) {
    return <LoadingSpinner />;
  }

  const emptySteps: string[] = [];

  return (
    <div className={styles.stepperNew}>
      {emptySteps.map((sidebarItem, index) => {
        const itemStyle = {
          [styles.before]: index < activeStepIdx,
          [styles.active]: index === activeStepIdx,
          [styles.after]: index > activeStepIdx,
        };

        return (
          <Row className={classNames(styles.step, itemStyle)} key={sidebarItem}>
            <Col size={12} constant>
              <div>
                {index < activeStepIdx ? (
                  <Icon size={IconSizes.ss} className={styles.tickIcon} icon="checkmark" />
                ) : null}
                {index === activeStepIdx ? <Icon className={styles.arrowsIcon} icon="arrowhead-right" /> : null}
                <Typography
                  className={classNames(styles.title, itemStyle)}
                  size="l"
                  msg={t(`${'title_company_profile_'}${sidebarItem.toLowerCase()}` as Messages)}
                  tag="div"
                />
              </div>
            </Col>

            {/* } */}
          </Row>
        );
      })}

      <Row
        onClick={() => {
          if (getFromLocalStorage(LocalStorage.obCompanySelected) !== null) {
            obNeverLeaveHandler(dispatch, id || '');
          } else {
            history.push(generatePath(`${Links.lcReady}${queryString}`, { id }));
          }
        }}
        className={styles.exitButtonPosition}
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (e.keyCode === EnterKeyCode) {
            if (getFromLocalStorage(LocalStorage.obCompanySelected) !== null) {
              obNeverLeaveHandler(dispatch, id || '');
            } else {
              history.push(generatePath(`${Links.lcReady}${queryString}`, { id }));
            }
          }
        }}
      >
        <Icon className={styles.exitIcon} icon="chevronLeft" />
        <Typography
          className={styles.exitButton}
          msg={`Register a life: ${lifeCase.data?.lifeCase.firstName} ${lifeCase.data?.lifeCase.lastName}`}
          tag="div"
        />
      </Row>
    </div>
  );
};

export default CompanyStepper;
