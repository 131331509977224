import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/RadioButton';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput, { InputTypes } from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { EmailType } from '@Components/Modal/UserInputModalBody/UserInputModalBody';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue, Links, LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseChatCreateMutation,
  CaseChatCreateMutationVariables,
  IssueSubjectNode,
  NotificationChatChatType,
  RestrictedCaseServiceProviderNode,
  useIssueSubjectsQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationCaseChatCreate } from '@Graphql/providers/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateRequired, validateSelector } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './SomethingWrongModalBody.scss';

export interface SomethingWrongModalBodyProps {
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
  accessCode: string;
}

enum SomethingWrongModalFields {
  issueMessage = 'issueMessage',
  extraInfo = 'extraInfo',
  radioBereavementEmail = 'radioBereavementEmail',
  radioUserInputEmail = 'radioUserInputEmail',
  inputEmail = 'inputEmail',
}

const SomethingWrongModalBody: React.FunctionComponent<ModalProviderProps<SomethingWrongModalBodyProps>> = ({
  modalData: { deathCaseServiceProvider, accessCode },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const history = useHistory();
  const queryString = history.location.search;
  const [emailType, setEmailType] = React.useState('');

  const [contactEmail, setContactEmail] = React.useState('');
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const emailId = getFromLocalStorage(LocalStorage.providerEmail);

  const [createCaseChat] = useMutation<CaseChatCreateMutation, CaseChatCreateMutationVariables>(
    mutationCaseChatCreate,
    {
      onCompleted: () => {
        dispatch(notificationSuccess('Message sent'));
        closeModal();
        history.push(generatePath(`${Links.providerDCInfo}${queryString}`, { msgAccessCode: accessCode }));
      },
      onError: (apiError) => {
        dispatch(notificationError(normaliseGqlError(apiError.message)));
      },
    }
  );

  const { data, loading } = useIssueSubjectsQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <Col size={8} alignCenter textCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const issueMessagesOptions = data?.issueSubjects.map((add: IssueSubjectNode) => ({
    label: t(add.issueText as Messages),
    value: add.id,
  }));

  const getEmailId = () => {
    let spEmail = '';
    if (emailId === null) {
      spEmail = emailType === EmailType.bereavement ? deathCaseServiceProvider.serviceProvider.email : contactEmail;
    } else {
      spEmail = emailId;
    }

    setToLocalStorage(LocalStorage.providerEmail, spEmail);
    return spEmail;
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          [SomethingWrongModalFields.issueMessage]: emptySelectorValue,
          [SomethingWrongModalFields.extraInfo]: '',
        }}
        validationSchema={Yup.object({
          [SomethingWrongModalFields.issueMessage]: validateSelector(t),
          [SomethingWrongModalFields.extraInfo]: validateRequired(t),
        })}
        onSubmit={(values) => {
          createCaseChat({
            variables: {
              input: {
                subject: values.issueMessage.label,
                comment: values.extraInfo,
                deathCaseProviderId: deathCaseServiceProvider.id,
                commentedBy: getEmailId(),
                commentedByName: deathCaseServiceProvider.serviceProvider.name,
                chatType: NotificationChatChatType.ServiceProvider,
              },
            },
          });
        }}
      >
        {({ status, values }) => (
          <>
            <Typography
              className={styles.topSpacing}
              msg="Is there something wrong with the notification?"
              tag="h4"
              size="xl"
              bold
            />
            <Form>
              <Row size={12}>
                <Field
                  name={SomethingWrongModalFields.issueMessage}
                  component={SelectInput}
                  placeholder="Select issue from list"
                  label="Choose issue"
                  options={issueMessagesOptions}
                  size={isMobile ? 'm' : 'xl'}
                  selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                />
              </Row>
              {values.issueMessage.value !== '' && (
                <>
                  <Row size={12}>
                    <Field
                      name={SomethingWrongModalFields.extraInfo}
                      type={InputTypes.textArea}
                      component={TextInput}
                      className={styles.extraInfoBox}
                      label="Please provide more relevant information"
                    />
                  </Row>

                  {emailId === null && (
                    <>
                      <Row size={7}>
                        <Typography className={styles.bottomSpacing} msg={t(Messages.labelChooseEmail)} tag="span" />
                      </Row>

                      <Row>
                        <Col size={5}>
                          <RadioButton
                            value={EmailType.bereavement}
                            label={deathCaseServiceProvider.serviceProvider.bereavementContactEmail}
                            subLabel={t(Messages.labelBereavementEmail)}
                            checked={emailType === EmailType.bereavement}
                            name={SomethingWrongModalFields.radioBereavementEmail}
                            onChange={(e: any) => setEmailType(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col size={12}>
                          <Typography
                            className={styles.providerSubtitle}
                            tag="div"
                            size="l"
                            msg={t(Messages.labelServiceProviderOr)}
                          />
                        </Col>
                      </Row>
                      <Row constant className={styles.bigBottomSpacing}>
                        <Col className={styles.shiftTextBox}>
                          <RadioButton
                            value={EmailType.contact}
                            checked={emailType === EmailType.contact}
                            name={SomethingWrongModalFields.radioUserInputEmail}
                            onChange={(e: any) => setEmailType(e.target.value)}
                            onlyButton={true}
                          />
                        </Col>
                        <Col size={6} className={styles.providerSubtitle}>
                          <input
                            placeholder="Enter contact email"
                            type="text"
                            name={SomethingWrongModalFields.inputEmail}
                            className={classNames(styles.input)}
                            onChange={(e: any) => setContactEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row constant size={isMobile ? 12 : 7}>
                    <Icon icon="info" className={styles.messageInfo} />
                    <Typography
                      msg="This message will be shared directly with the sender of this death notification"
                      tag="div"
                      size="m"
                      family="helvetica"
                      color="footerColor"
                    />
                  </Row>
                </>
              )}
              <Row size={12} className={styles.topSpacing}>
                <Button
                  type={ButtonTypes.submit}
                  label="Send message to notifier"
                  disabled={values.issueMessage.value === ''}
                  isFullWidth
                />
              </Row>

              <FormError formError={status} />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SomethingWrongModalBody;
