
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProfessionalCompany"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProfessionalCompanyNode"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"companyName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"vatNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"companyNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"logo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address1"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address2"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"city"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postalCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buildingName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buildingNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isMfaEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"phone"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"website"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sraNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"professionalType"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":327}};
    doc.loc.source = {"body":"fragment ProfessionalCompany on ProfessionalCompanyNode {\n      id\n      companyName\n      vatNumber\n      companyNumber\n      logo\n      address1\n      address2\n      city\n      postalCode\n      buildingName\n      buildingNumber\n      isMfaEnabled\n      phone\n      email\n      website\n      sraNumber\n      professionalType\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
