import * as React from 'react';

import Col from '@Components/layout/Col';
import LoadingSpinner from '@Components/LoadingSpinner';
import {
  NotificationChatChatType,
  CaseServiceProviderFragment,
  useCaseChatsQuery,
  NotificationDocumentNode,
  DeathCaseFragment,
  RestrictedDeathCaseNode,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { noop } from '@Utils/helpers';

import ChatMessagesForm from './ChatMessagesForm';

export interface ChatMessagesProps {
  deathCaseServiceProvider: CaseServiceProviderFragment | undefined;
  chatType: NotificationChatChatType;
  uploadedFiles: NotificationDocumentNode[];
  deathCase: DeathCaseFragment | RestrictedDeathCaseNode;
  isCustomer?: boolean;
  user?: UserNode | null;
  msgParent?: string;
  setMsgCallback?: (comm: string) => void;
}

const ChatMessages: React.FunctionComponent<ChatMessagesProps> = ({
  deathCaseServiceProvider,
  chatType,
  uploadedFiles,
  deathCase,
  isCustomer,
  user,
  msgParent,
  setMsgCallback = noop,
}) => {
  const { data, loading } = useCaseChatsQuery({
    variables: {
      caseServiceProvider: deathCaseServiceProvider ? deathCaseServiceProvider?.id : '',
    },
    fetchPolicy: 'network-only',
    // skip: chatType === NotificationChatChatType.Lifeledger,
    notifyOnNetworkStatusChange: true,
  });

  // const caseChatsLL = useCaseChatsLlQuery({
  //   variables: {
  //     caseServiceProvider: deathCaseServiceProvider ? deathCaseServiceProvider?.id : '',
  //   },
  //   fetchPolicy: 'network-only',
  //   skip: chatType === NotificationChatChatType.ServiceProvider,
  // });

  if (loading) {
    return (
      <Col size={8} alignCenter textCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const chats = data?.caseChats as any[];

  return (
    <ChatMessagesForm
      uploadedFiles={uploadedFiles}
      caseChats={chats}
      csp={deathCaseServiceProvider}
      chatType={chatType}
      deathCase={deathCase}
      isCustomer={isCustomer}
      user={user}
      msgParent={msgParent}
      setMsgCallback={setMsgCallback}
    />
  );
};

export default ChatMessages;
