import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import Compressor from 'compressorjs';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogoUpload from '@Components/application/LogoUpload';
import Button, { ButtonSizes } from '@Components/Button';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { queryProfessionalCompanyDetails } from '@Graphql/auth/queries';
import {
  PortalUpdateSettingsMutation,
  PortalUpdateSettingsMutationVariables,
  ProfessionalCompanyUpdateMutation,
  ProfessionalCompanyUpdateMutationVariables,
  useProfessionalCompanyDetailsQuery,
  UserAccountType,
  useServiceProviderQuery,
} from '@Graphql/graphqlTypes.generated';
import { queryServiceProvider } from '@Graphql/providers/queries';
import { mutationPortalUpdateSettings } from '@Graphql/serviceProvidersPortal/mutations';
import { mutationProfessionalCompanyUpdate } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './LogoPreviewModalBody.scss';

export interface LogoPreviewModalBodyProps {
  isServiceProvider?: boolean;
  serviceProviderId?: string;
}

const LogoPreviewModalBody: React.FunctionComponent<ModalProviderProps> = ({
  closeModal,
  modalData: { isServiceProvider, serviceProviderId },
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const companyDetails = useProfessionalCompanyDetailsQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { id: user ? user?.professionalCompany : '' },
    skip: isServiceProvider,
  });

  const providerInfo = useServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: user?.accountType !== UserAccountType.ServiceProvider,
    variables: {
      id: serviceProviderId,
    },
  });

  const [professionalCompanyUpdate] = useMutation<
    ProfessionalCompanyUpdateMutation,
    ProfessionalCompanyUpdateMutationVariables
  >(mutationProfessionalCompanyUpdate, {
    refetchQueries: [getOperationName(queryProfessionalCompanyDetails) || ''],
  });

  const [spUpdateSettings] = useMutation<PortalUpdateSettingsMutation, PortalUpdateSettingsMutationVariables>(
    mutationPortalUpdateSettings,
    {
      refetchQueries: [getOperationName(queryServiceProvider) || ''],
      onCompleted: (response: any) => {
        dispatch(notificationSuccess(t(response.portalUpdateSettings.message)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  if (companyDetails.loading || providerInfo.loading) {
    return <LoadingSpinner />;
  }

  const compressAndUpload = (file: File) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: (compressedResult: File) => {
        if (isServiceProvider) {
          dispatch(
            spUpdateSettings({
              variables: {
                input: {
                  logo: compressedResult,
                  id: providerInfo.data?.serviceProvider.id || '',
                },
              },
            })
          );
        } else {
          dispatch(
            professionalCompanyUpdate({
              variables: {
                input: {
                  files: compressedResult,
                  id: companyDetails.data?.professionalCompanyDetails.id || '',
                },
              },
            })
          );
        }
      },
    });
  };

  return (
    <>
      <Typography textCenter msg={t('label_confirm_selection' as Messages)} tag="h4" bold size="xl" />

      <Typography textCenter msg={t('info_logo_preview' as Messages)} tag="div" size="m" />

      <div className={styles.alignCenter}>
        <div className={styles.outerCircle}>
          <div className={styles.innerCircle}>
            <img
              className={styles.profLogo}
              src={
                isServiceProvider
                  ? providerInfo.data?.serviceProvider.logo
                  : companyDetails.data?.professionalCompanyDetails.logo
              }
              alt=""
            />
          </div>
        </div>
      </div>

      <Row className={styles.bottomSpacing} alignCenter>
        <Button
          label={t('button_confirm_company_photo' as Messages)}
          size={ButtonSizes.fill}
          onClick={() => closeModal()}
        />
      </Row>
      <Row justifyCenter alignCenter>
        <LogoUpload
          logoUploadComponent={
            <Typography
              className={styles.handSymbol}
              underline
              color="electricBlue"
              bold
              size="mb"
              tag="span"
              msg={t('link_choose_different_photo' as Messages)}
            />
          }
          onDrop={
            (files: File[]) => {
              compressAndUpload(files[0]);
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      </Row>
    </>
  );
};

export default LogoPreviewModalBody;
