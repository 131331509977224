import { getCookie } from '@Utils/cookies';

import { Cookies, Defaults } from './constants';

export function getDefaultLanguage() {
  const cookieLanguage = getCookie(Cookies.defaultLang);
  return cookieLanguage || Defaults.language;
}

export function getMessageKeyTranslation(messageKey: string, language: any) {
  return language[messageKey] || messageKey;
}
