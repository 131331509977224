import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import Root from '@Components/Root';
import config from '@Config/config';
import ApolloClient from '@Graphql/graphqlClient';

import configureStore, { history } from './js/store/configureStore';

import './styles/index.scss';

if (config.SENTRY_DSN) {
  Sentry.init({ dsn: config.SENTRY_DSN, environment: config.SENTRY_ENVIRONMENT });
}

const store = configureStore();

render(
  <AppContainer>
    <ApolloProvider client={ApolloClient}>
      <Root store={store} history={history} />
    </ApolloProvider>
  </AppContainer>,
  document.getElementById('app')
);
