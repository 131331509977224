import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import ConfigurationMFA from '@Routes/provider/ConfigurationMFA';

import { RouteInfo } from '../../appRoutes';

const routes: RouteInfo[] = [
  {
    path: Links.configureMFA,
    component: ConfigurationMFA,
    title: Messages.pageConfigureMfa,
    isServiceProviderRoute: true,
  },
];

export default routes;
