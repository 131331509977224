import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useLocation } from 'react-router';

import MiniFooter from '@Components/application/MiniFooter';
import Tooltip, { TooltipPosition } from '@Components/application/Tooltip/Tooltip';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import {
  CaseTags,
  EnterKeyCode,
  Links,
  LocalStorage,
  NodeDefiner,
  PaymentStatus,
  ReactResponsiveQueries,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  UserLandingPage,
  UserReferralSource,
  UserVerificationStatus,
  StripeUpdateCheckoutSessionMutation,
  StripeUpdateCheckoutSessionMutationVariables,
  LifeCaseDeleteMutation,
  LifeCaseDeleteMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteLC } from '@Graphql/lc/mutations';
import { mutationStripeUpdateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import DeathCasesContent from '@Routes/dashboard/DeathCasesPage/DeathCasesContent';
import LifeCasesContent from '@Routes/dashboard/LifeCasesPage/LifeCasesContent';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { basicPaymentHandler, paymentHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import styles from './DashboardIndividual.scss';

export interface DashboardIndividualProps {
  dcCount: number;
  lcCount: number;
  hasUnusedLifeSubscription?: boolean;
}

const DashboardIndividual: React.FunctionComponent<DashboardIndividualProps> = ({
  dcCount,
  lcCount,
  hasUnusedLifeSubscription,
}) => {
  const user = useSelector(getUser);
  const history = useHistory();
  const queryString = history.location.search;
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const t = useTranslations();
  const smallDesktop = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });
  const isRes1306Max = useMediaQuery({ query: ReactResponsiveQueries.Res1306Max });
  const sessionId = searchParams.get('session_id') || '';
  const lcPaymentCancel = searchParams.get('lc_cancel') || undefined;
  const lifeCaseId = searchParams.get('id') || '';

  const [deleteLifeCase] = useMutation<LifeCaseDeleteMutation, LifeCaseDeleteMutationVariables>(mutationDeleteLC, {
    // refetchQueries: [getOperationName(queryLCList) || ''],
    onCompleted: () => {
      window.location.href = window.location.origin;
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [updateCheckoutSession] = useMutation<
    StripeUpdateCheckoutSessionMutation,
    StripeUpdateCheckoutSessionMutationVariables
  >(mutationStripeUpdateCheckoutSession, {
    onCompleted: () => {
      deleteLifeCase({ variables: { input: { id: lifeCaseId } } });
    },
  });

  const showActiveTab = () => {
    const storedCaseType = getFromLocalStorage(LocalStorage.caseTypeState);
    if (storedCaseType) {
      return storedCaseType as NodeDefiner;
    }
    if (user?.landingPage === UserLandingPage.LifeCase || user?.landingPage === UserLandingPage.RetargetLifeCase) {
      return NodeDefiner.LifeCase;
    }
    if (!isTablet) {
      if (dcCount > 0) {
        return NodeDefiner.DeathCase;
      }
      if (lcCount > 0) {
        return NodeDefiner.LifeCase;
      }
      return NodeDefiner.DeathCase;
    }
    return NodeDefiner.DocumentNode;
  };
  const [activeCaseType, setActiveCaseType] = React.useState(showActiveTab());

  const showAddAnotherButton = () => {
    if (activeCaseType === NodeDefiner.DeathCase) {
      return dcCount > 0;
    }
    return lcCount > 0;
  };

  React.useEffect(() => {
    // Save the active case type state to localStorage whenever it changes
    if (!isTablet) {
      setToLocalStorage(LocalStorage.caseTypeState, activeCaseType);
    }
    if (lcPaymentCancel) {
      updateCheckoutSession({ variables: { input: { sessionId, status: PaymentStatus.FAILED } } });
    }
  }, [activeCaseType, isTablet, lcPaymentCancel, updateCheckoutSession, sessionId]);

  const isHospice = user?.referralSource === UserReferralSource.LifeHospice;

  const mainBodyContainer = (
    <div
      className={classNames(
        styles.newCaseContainer,
        !isTablet && activeCaseType === NodeDefiner.DeathCase ? styles.dcArrowPointer : styles.lcArrowPointer,
        { [styles.lifeCaseBackground]: lcCount === 0 && activeCaseType === NodeDefiner.LifeCase }
      )}
    >
      {activeCaseType === NodeDefiner.DeathCase && <DeathCasesContent />}
      {activeCaseType === NodeDefiner.LifeCase && (
        <LifeCasesContent hasUnusedLifeSubscription={hasUnusedLifeSubscription} />
      )}
      {showAddAnotherButton() && (
        <Col
          onClick={() => {
            if (!isHospice && activeCaseType === NodeDefiner.LifeCase && !hasUnusedLifeSubscription) {
              if (user?.enableDiscovery) {
                paymentHandler(dispatch, '', t('label_choose_package' as Messages));
              } else {
                basicPaymentHandler(t, dispatch, '', false, false, t('title_life_case_subscription' as Messages));
              }
            } else if (hasUnusedLifeSubscription && activeCaseType === NodeDefiner.LifeCase) {
              history.push(generatePath(`${Links.lcCreate}?ill=undefined`, { tag: `tag=${CaseTags.lifeCaseCreated}` }));
            } else {
              history.push(
                `${activeCaseType === NodeDefiner.DeathCase ? Links.dcCreate : Links.lcCreate}${queryString}`
              );
            }
          }}
          className={styles.addAnother}
          size={11}
        >
          <Icon icon="plus" />
          <Typography msg={t('label_add_person' as Messages)} tag="div" size="m" color="footerColor" />
        </Col>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.dashboardContainer}>
        <Typography msg={t('title_your_dashboard' as Messages)} tag="h4" bolder color="footerColor" size="xl" />

        {user?.verificationStatus !== UserVerificationStatus.Verified && dcCount > 0 && (
          <Row constant={!isTablet} size={smallDesktop ? 10 : 7} className={styles.missingInfoBox}>
            <Col size={4}>
              <Row constant>
                <Col className={styles.infoIcon}>
                  <Icon icon="info" />
                </Col>

                <Col size={12}>
                  <Row>
                    <Col tabindex={0}>
                      <Typography msg={t('info_todo_notification' as Messages)} tag="div" size="s" bolder />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col size={2} />
            <Col size={5} className={styles.missingInfoContainer}>
              <Row constant className={styles.sent}>
                <Icon className={classNames(styles.missingItems)} icon="lock" size={IconSizes.ss} />
                <span
                  className={styles.handSymbol}
                  onClick={() =>
                    history.push(
                      generatePath(`${Links.settings}?${queryString}`, { subpage: SettingsSubpages.IDValidation })
                    )
                  }
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      history.push(
                        generatePath(`${Links.settings}?${queryString}`, { subpage: SettingsSubpages.IDValidation })
                      );
                    }
                  }}
                >
                  <Typography
                    msg={t('btn_verify_id' as Messages)}
                    tag="span"
                    bold
                    color="red"
                    size="s"
                    underline
                    tabIndex={0}
                  />
                </span>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      <Col size={smallDesktop ? 11 : 10} className={styles.mainContainer}>
        <Typography className={styles.pb8} msg={t('title_choose_service' as Messages)} tag="h3" size="xxl" />

        <Row className={styles.gp8}>
          <Col
            onClick={() => {
              const selectedType =
                isTablet && activeCaseType === NodeDefiner.DeathCase ? NodeDefiner.DocumentNode : NodeDefiner.DeathCase;
              setActiveCaseType(selectedType);
            }}
            className={classNames(styles.newCaseBox, {
              [styles.selectedCard]: activeCaseType === NodeDefiner.DeathCase,
            })}
          >
            <Row className={styles.pb8} constant alignCenter={!isRes1306Max}>
              <Col className={styles.icon}>
                <Icon size={IconSizes.ss} icon="paper-plane" />
              </Col>
              <Col>
                <Typography msg={t('title_death_notifications' as Messages)} bold tag="h4" size="xl" />
              </Col>
            </Row>
            <Typography msg={t('desc_death_notifications' as Messages)} tag="div" size="m" />
          </Col>
          {isTablet && activeCaseType === NodeDefiner.DeathCase && mainBodyContainer}
          <Col
            onClick={() => {
              const selectedType =
                isTablet && activeCaseType === NodeDefiner.LifeCase ? NodeDefiner.DocumentNode : NodeDefiner.LifeCase;
              setActiveCaseType(selectedType);
            }}
            className={classNames(styles.newCaseBox, {
              [styles.selectedCard]: activeCaseType === NodeDefiner.LifeCase,
            })}
          >
            <Row className={styles.pb8} constant alignCenter={!isRes1306Max}>
              <Col className={styles.icon}>
                <Icon size={IconSizes.ss} icon="people" />
              </Col>
              <Col>
                <Typography msg={t('title_register_life' as Messages)} bold tag="h4" size="xl" />
              </Col>
            </Row>
            <Typography msg={t('desc_register_life' as Messages)} tag="div" size="m" />
          </Col>

          {isTablet && activeCaseType === NodeDefiner.LifeCase && mainBodyContainer}

          <Col className={styles.referBox}>
            <Tooltip
              className={styles.tooltipFlex}
              position={TooltipPosition.top}
              message={t('tooltip_desc_refer' as Messages)}
              title={t('tooltip_head_refer' as Messages)}
            >
              <div className={classNames(styles.newCaseBox, styles.op8)}>
                <Row className={styles.pb8} constant alignCenter={!isRes1306Max}>
                  <Col className={styles.icon}>
                    <Icon size={IconSizes.ss} icon="people" />
                  </Col>
                  <Col>
                    <Typography msg={t('title_refer_friend' as Messages)} bold tag="h4" size="xl" />
                  </Col>
                </Row>
                <Typography msg={t('desc_refer_friend' as Messages)} tag="div" size="m" />
              </div>
            </Tooltip>
          </Col>
        </Row>
        {!isTablet && mainBodyContainer}
      </Col>

      {!smallDesktop && <MiniFooter />}
    </>
  );
};

export default DashboardIndividual;
