import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox/CautionBox';
import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { GenericModalType } from '@Components/Modal/GenericModalBody/GenericModalBody';
import { PaperWidths } from '@Components/Paper';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue, ModalTypes, NodeDefiner } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorNode,
  CollaboratorPermission,
  CollaboratorRelationshipFe,
  DeathCaseNode,
  LifeCaseNode,
  UserAccountType,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import KnownLegals from '@Routes/deathCase/DeathCasePage/LegalForm/KnownLegals/KnownLegals';
import { showModal } from '@Store/app/app.actions';
import { updateDC, addAdministrator } from '@Store/dc/dc.actions';
import { updateLC } from '@Store/lc/lc.actions';
import { getPermissionSelectOption } from '@Utils/form';
import { manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { LoadableItem } from '@Utils/types';
import { validateEmail, validateName, validatePhone } from '@Utils/validator';

import styles from './WillAdminForm.scss';

export interface WillAdminFormProps {
  containerClasses?: string;
  isLifeCase?: boolean;
  isUserProfessional?: boolean;
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  user: UserNode | null;
}

export interface WillAdminFormStateProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  user: UserNode | null;
}

export enum WillAdminFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  hasAdministrators = 'hasAdministrators',
  meAdministrator = 'meAdministrator',
  permission = 'permission',
}

type WillAdminCollaboratorFields = Extract<
  WillAdminFormFields,
  | WillAdminFormFields.firstName
  | WillAdminFormFields.lastName
  | WillAdminFormFields.email
  | WillAdminFormFields.phone
  | WillAdminFormFields.permission
>;

export interface WillAdminFormValues {
  [WillAdminFormFields.firstName]: string;
  [WillAdminFormFields.lastName]: string;
  [WillAdminFormFields.email]: string;
  [WillAdminFormFields.phone]: string;
  [WillAdminFormFields.hasAdministrators]: boolean;
  [WillAdminFormFields.meAdministrator]: boolean;
  [WillAdminFormFields.permission]: { value: string; label: string };
}

const emptyFormValues = {
  [WillAdminFormFields.firstName]: '',
  [WillAdminFormFields.lastName]: '',
  [WillAdminFormFields.email]: '',
  [WillAdminFormFields.phone]: '+44',
  [WillAdminFormFields.permission]: '',
};

interface WillAdminFormPrivateProps extends WillAdminFormProps, WillAdminFormStateProps {}

const WillAdminForm: React.FunctionComponent<WillAdminFormPrivateProps> = ({
  user,
  data,
  isLifeCase,
  containerClasses,
}) => {
  const t = useTranslations();

  const dispatch = useDispatch();
  // const history = useHistory();
  const [isFormVisible, setFormVisible] = useState(false);
  const willAdministrators =
    data?.item?.__typename === NodeDefiner.DeathCase && data.item.willAdministrator
      ? data?.item?.willAdministrator.filter((wa: CollaboratorNode) => wa.showCollaborator) || []
      : [];

  const isUserAdmin = () => {
    if (data?.item?.__typename === NodeDefiner.DeathCase) {
      const isUserAdminCreated = willAdministrators.filter((e: CollaboratorNode) => e.email === user?.email);
      if (isUserAdminCreated.length > 0) {
        return false;
      }
      if (data.item.isAlsoAdministrator && isUserAdminCreated.length === 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const permissionSelectOptions = Object.values(CollaboratorPermission).map(getPermissionSelectOption(t));

  const userDataValues = {
    [WillAdminFormFields.firstName]: user?.firstName,
    [WillAdminFormFields.lastName]: user?.lastName,
    [WillAdminFormFields.email]: user?.email,
    [WillAdminFormFields.phone]: user?.phone,
    [WillAdminFormFields.permission]: permissionSelectOptions.find(
      (ps: any) => ps.value === CollaboratorPermission.Owner
    ),
  };

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm, values } = useFormikContext();
    const { hasAdministrators, meAdministrator } = values as WillAdminFormValues;

    React.useEffect(() => {
      if (!willAdministrators.length) {
        setFormVisible(hasAdministrators);
      }
      const getFieldValue = (fieldName: WillAdminCollaboratorFields) => {
        if (!hasAdministrators) {
          return emptyFormValues[fieldName];
        }
        return meAdministrator ? userDataValues[fieldName] : emptyFormValues[fieldName];
      };
      const toggleAdministratorFormField = (fieldName: WillAdminCollaboratorFields) => {
        setFieldValue(fieldName, getFieldValue(fieldName), false);
        setFieldTouched(fieldName, false, false);
      };
      toggleAdministratorFormField(WillAdminFormFields.firstName);
      toggleAdministratorFormField(WillAdminFormFields.lastName);
      toggleAdministratorFormField(WillAdminFormFields.email);
      toggleAdministratorFormField(WillAdminFormFields.phone);
      toggleAdministratorFormField(WillAdminFormFields.permission);
    }, [setFieldValue, setFieldTouched, resetForm, hasAdministrators, meAdministrator]);

    return null;
  };

  const tooltip_desc_will_administrator = manipulateTranslations(
    t,
    'tooltip_desc_will_administrator',
    'Relatives of the deceased will be placed in order of priority by the courts, starting with their spouse or civil partner. The person at the top of the list is entitled to take on the role of Administrator.</br></br>An administrator is legally responsible for handling the estate of the deceased (their money, property, and possessions).</br></br>This differs to the appointment of an Executor, who is appointed under a Will.'
  );

  const tooltip_title_will_administrator = manipulateTranslations(
    t,
    'tooltip_title_will_administrator',
    'How would I know who the administrator is?'
  );

  const handlePermissionChange = (perm: any, setFieldValue: any) => {
    if (perm.value === CollaboratorPermission.Owner && user?.accountType === UserAccountType.Professional) {
      dispatch(
        showModal({
          type: ModalTypes.genericModal,
          params: {
            data: {
              icon: 'alert-triangle',
              title: t('title_transfer_owner' as Messages),
              bodyText: t('text_transfer_owner' as Messages),
              firstButtonMsg: t('button_transfer_owner' as Messages),
              secondButtonMsg: t('link_not_transfer' as Messages),
              isSecondButtonLink: true,
              genericType: GenericModalType,
              id: '',
              extraParameter: perm,
              onClose: () => setFieldValue(WillAdminFormFields.permission, emptySelectorValue),
            },
            title: '',
            modalSize: PaperWidths.m581,
          },
        })
      );
    }
  };

  const onFocus = () => {
    const emailInput = document.getElementById('emailInput');
    const cautionBox = document.getElementById('cautionBox');
    if (emailInput && cautionBox)
      emailInput.addEventListener('focus', () => {
        cautionBox.style.display = 'flex';
      });
    if (emailInput && cautionBox)
      emailInput.addEventListener('blur', () => {
        cautionBox.style.display = 'none';
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [WillAdminFormFields.firstName]: '',
        [WillAdminFormFields.lastName]: '',
        [WillAdminFormFields.email]: '',
        [WillAdminFormFields.phone]: '+44',
        [WillAdminFormFields.permission]: emptySelectorValue,
        [WillAdminFormFields.hasAdministrators]: willAdministrators.length > 0,
        [WillAdminFormFields.meAdministrator]: isUserAdmin(),
      }}
      validationSchema={Yup.object({
        [WillAdminFormFields.firstName]: validateName(t),
        [WillAdminFormFields.lastName]: validateName(t),
        [WillAdminFormFields.email]: validateEmail(t),
        [WillAdminFormFields.phone]: validatePhone(t),
      })}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { meAdministrator, firstName, lastName, email, phone, permission } = values;
        const caseId = data.item ? data.item.id : '';
        if (!meAdministrator)
          dispatch(
            addAdministrator.started({
              setSubmitting,
              setStatus,
              resetForm,
              baseCase: id,
              relationship: CollaboratorRelationshipFe.WillAdministrator,
              firstName,
              lastName,
              email: email.trim(),
              phone: removeLeadingZeroAndSpaceInContact(phone),
              permission: Object.values(CollaboratorPermission).find((perms: any) => perms === permission.value),
            })
          );
        else if (isLifeCase) {
          dispatch(
            updateLC.started({
              setSubmitting,
              setStatus,
              id: caseId,
              isAlsoAdministrator: meAdministrator,
              relationship: data.item?.relationship,
            })
          );
        } else {
          dispatch(
            updateDC.started({
              setSubmitting,
              setStatus,
              id: caseId,
              isAlsoAdministrator: meAdministrator,
              relationship: data.item?.relationship,
            })
          );
        }
      }}
    >
      {({ isSubmitting, status, values, setFieldValue }) => (
        <>
          <Form>
            <div className={classNames(containerClasses, styles.WillAdminForm)}>
              <Row justifyBetween>
                <Col className={styles.bottomSpacing}>
                  <Field
                    name={WillAdminFormFields.hasAdministrators}
                    component={ToggleSwitchInput}
                    label="Do you know who the administrators are?"
                    disabled={willAdministrators.length > 0}
                    infoMessage={!isLifeCase ? tooltip_desc_will_administrator : undefined}
                    infoTitle={!isLifeCase ? tooltip_title_will_administrator : undefined}
                  />
                </Col>
              </Row>
              {isFormVisible && (
                <Row justifyBetween>
                  <Col className={styles.bottomSpacing}>
                    <Field
                      name={WillAdminFormFields.meAdministrator}
                      component={ToggleSwitchInput}
                      label="Are you the administrator?"
                      disabled={willAdministrators.length > 0}
                      infoMessage={!isLifeCase ? tooltip_desc_will_administrator : undefined}
                      infoTitle={!isLifeCase ? tooltip_title_will_administrator : undefined}
                    />
                  </Col>
                </Row>
              )}
              <KnownLegals legals={willAdministrators} isWillAdmin={true} caseId={data.item?.id} />
              {isFormVisible && (
                <GrayPaper width="width-full">
                  <Row className={styles.bottomSpacing}>
                    <Typography msg={`Administrator ${willAdministrators.length + 1}`} tag="div" size="m" bold />
                  </Row>
                  <GridRow>
                    <GridCol size={5}>
                      <Field
                        name={WillAdminFormFields.firstName}
                        type="text"
                        component={TextInput}
                        label="First name"
                        disabled={values.meAdministrator}
                        required
                      />
                    </GridCol>
                    <GridCol size={5}>
                      <Field
                        name={WillAdminFormFields.lastName}
                        type="text"
                        component={TextInput}
                        label="Last name"
                        disabled={values.meAdministrator}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={8} className={styles.WillAdminFormEmailFieldWrapper}>
                      <Field
                        name={WillAdminFormFields.email}
                        type="text"
                        id="emailInput"
                        component={TextInput}
                        label="Email address"
                        disabled={values.meAdministrator}
                        required
                        onFocus={onFocus}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={8}>
                      <CautionBox
                        containerClassName={styles.cautionBox}
                        info={manipulateTranslations(
                          t,
                          'label_email_caution',
                          "We'll be sending an email to this individual, inviting them to make an account so they can see the information you've given."
                        )}
                        id="cautionBox"
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={8} className={styles.WillAdminFormFieldWrapper}>
                      <Field
                        name={WillAdminFormFields.phone}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldExecutivePhone)}
                        disabled={values.meAdministrator}
                        required
                      />
                    </GridCol>
                  </GridRow>

                  <GridRow>
                    <GridCol size={9} className={styles.WillAdminFormFieldWrapper}>
                      <Field
                        name={WillAdminFormFields.permission}
                        label="Level of access"
                        component={SelectInput}
                        onSelectChange={(e: any) => handlePermissionChange(e, setFieldValue)}
                        options={
                          values.meAdministrator || user?.accountType === UserAccountType.Professional
                            ? permissionSelectOptions
                            : permissionSelectOptions.filter((pso: any) => pso.value !== CollaboratorPermission.Owner)
                        }
                        selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                        disabled={values.meAdministrator}
                        required
                      />
                    </GridCol>
                  </GridRow>

                  <GridRow>
                    <GridCol size={4}>
                      <Button
                        type={ButtonTypes.submit}
                        label="Add"
                        loading={isSubmitting}
                        icon="plus"
                        iconSize={IconSizes.ss}
                        constant
                      />
                    </GridCol>
                  </GridRow>
                </GrayPaper>
              )}
              {!isFormVisible && values.hasAdministrators && (
                <Row justifyCenter>
                  <Button
                    label="Add another"
                    loading={isSubmitting}
                    icon="person-add"
                    style={ButtonStyles.transparent}
                    onClick={() => setFormVisible(true)}
                    constant
                  />
                </Row>
              )}
            </div>
            <AutoToggleForm />
            <FormError formError={status} />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default WillAdminForm;
