import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import SettingsPage from '@Routes/settings';
import StripePaymentsPage from '@Routes/settings/StripePaymentsPage';
import ErrorPage from '@Routes/settings/StripePaymentsPage/ErrorPage';
import SuccessPage from '@Routes/settings/StripePaymentsPage/SuccessPage';

import { RouteInfo } from '../appRoutes';

const routes: RouteInfo[] = [
  {
    path: Links.settings,
    component: SettingsPage,
    title: Messages.pageSettings,
    isServiceProviderRoute: true,
  },
  {
    path: Links.payments,
    component: StripePaymentsPage,
    title: Messages.pagePayments,
    isServiceProviderRoute: false,
  },
  {
    path: Links.paymentSuccess,
    component: SuccessPage,
    title: Messages.pagePaymentSuccess,
    isServiceProviderRoute: false,
  },
  {
    path: Links.paymentError,
    component: ErrorPage,
    title: Messages.pageTitleError,
    isServiceProviderRoute: false,
  },
];

export default routes;
