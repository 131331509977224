import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { CaseTags, Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryGetUser } from '@Graphql/auth/queries';
import { mutationConfirmCollaborator } from '@Graphql/collaborators/mutations';
import { queryCollaboratorDeathsByUser } from '@Graphql/collaborators/queries';
import { mutationMarkReferredDeathIrrelevant } from '@Graphql/dc/mutations';
import { queryDCList } from '@Graphql/dc/queries';
import {
  ConfirmCollaboratorMutation,
  ConfirmCollaboratorMutationVariables,
  useDeathCaseQuery,
  useLifeCaseQuery,
  useRefDeathsByUserQuery,
  MarkReferredDeathIrrelevantMutation,
  MarkReferredDeathIrrelevantMutationVariables,
  useRefLivesByUserQuery,
} from '@Graphql/graphqlTypes.generated';
import { queryLCList } from '@Graphql/lc/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { capitalize } from '@Utils/helpers';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './ReferredDeathCaseInfo.scss';

export interface ReferredDeathCaseInfoProps {
  caseId: string | undefined;
  collaboratorId: string | undefined;
}

const ReferredDeathCaseInfo: React.FunctionComponent<ReferredDeathCaseInfoProps> = ({ caseId, collaboratorId }) => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const currentLanguage = useSelector(getCurrentLanguage);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const deathCaseData = useDeathCaseQuery({
    variables: {
      id: caseId || '',
    },
    fetchPolicy: 'network-only',
    skip: caseId === undefined,
  });

  const lifeCaseData = useLifeCaseQuery({
    variables: {
      id: caseId || '',
    },
    fetchPolicy: 'network-only',
    skip: caseId === undefined,
  });

  const refDeathsByUserData = useRefDeathsByUserQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: !user?.hasRefDeathCase,
  });

  const refLifeCaseData = useRefLivesByUserQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: !user?.hasRefLifeCase,
  });

  const [confirmCollaborator] = useMutation<ConfirmCollaboratorMutation, ConfirmCollaboratorMutationVariables>(
    mutationConfirmCollaborator,
    {
      refetchQueries: [
        getOperationName(queryCollaboratorDeathsByUser) || '',
        getOperationName(queryDCList) || '',
        getOperationName(queryLCList) || '',
      ],
      onCompleted: (resp: any) => {
        dispatch(notificationSuccess(t('label_thanks_confirmation_msg' as Messages)));
        if (!resp.confirmCollaborator.isRelevant) {
          history.push(generatePath(`${Links.home}${queryString}`));
        } else {
          history.push(
            generatePath(`${deathCaseData.data !== undefined ? Links.dcReady : Links.lcReady}${queryString}`, {
              id: caseId,
            })
          );
        }
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [markReferredDeathIrrelevantMutation] = useMutation<
    MarkReferredDeathIrrelevantMutation,
    MarkReferredDeathIrrelevantMutationVariables
  >(mutationMarkReferredDeathIrrelevant, {
    refetchQueries: [getOperationName(queryGetUser) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t('label_thanks_confirmation_msg' as Messages)));
      history.push(generatePath(`${Links.home}${queryString}`));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (deathCaseData.loading || lifeCaseData.loading || refDeathsByUserData.loading || refLifeCaseData.loading) {
    return <LoadingSpinner />;
  }

  const isDeathCase = deathCaseData.data !== undefined || user?.hasRefDeathCase;

  const deathCaseShort = deathCaseData.data?.deathCase;
  const lifeCaseShort = lifeCaseData.data?.lifeCase;
  const refDeaths = refDeathsByUserData.data?.refDeathsByUser;
  const refLives = refLifeCaseData.data?.refLivesByUser;

  const title = isDeathCase ? deathCaseShort?.title : lifeCaseShort?.title;

  const refDeathTitle = refDeaths && refDeaths.length > 0 ? refDeaths[0].deceasedTitle : '';

  const refLifeTitle = refLives && refLives.length > 0 ? refLives[0].title : '';

  const finalTitle = user?.hasRefDeathCase ? refDeathTitle : title || refLifeTitle;

  const firstName = isDeathCase ? deathCaseShort?.firstName || '' : lifeCaseShort?.firstName || '';

  const finalFirstName = user?.hasRefDeathCase
    ? refDeaths && refDeaths[0].deceasedFirstName
    : firstName || (refLives && refLives[0].firstName);

  const lastName = isDeathCase ? deathCaseShort?.lastName || '' : lifeCaseShort?.lastName || '';

  const finalLastName = user?.hasRefDeathCase
    ? refDeaths && refDeaths[0].deceasedLastName
    : lastName || (refLives && refLives[0].lastName);

  const name = finalTitle
    ? `${capitalize(finalTitle)} ${finalFirstName} ${finalLastName}`
    : `${finalFirstName} ${finalLastName}`;

  const birthDate = isDeathCase ? deathCaseShort?.birthDate : lifeCaseShort?.birthDate;

  const finalBirthDate = user?.hasRefDeathCase
    ? refDeaths && refDeaths[0].birthDate
    : birthDate || (refLives && refLives[0].birthDate);

  const confirmClick = () => {
    history.push({
      pathname: generatePath(`${user?.hasRefDeathCase ? Links.dcCreate : Links.lcCreate}${queryString}`, {
        tag: `tag=${user?.hasRefDeathCase ? CaseTags.registeredDeathCase : CaseTags.registeredLifeCase}`,
      }),
      state: refDeaths ? refDeaths[0] : refLives && refLives[0],
    });
  };

  const callDeathCaseIrrelevant = () => {
    markReferredDeathIrrelevantMutation({
      variables: { input: { id: refDeaths ? refDeaths[0].id : '' } },
    });
  };

  return (
    <div className={styles.referCaseLayout}>
      <Typography
        className={styles.titleRefCase}
        bold
        msg={isDeathCase ? t('title_refer_death_case' as Messages) : t('title_refer_life_case' as Messages)}
        tag="h3"
        size="xxl"
      />
      <Typography
        msg={
          isDeathCase
            ? t('label_refer_death_case_help_text' as Messages)
            : t('label_refer_life_case_help_text' as Messages)
        }
        tag="div"
        size="l"
      />

      <Row constant className={styles.referCaseContainer} size={isMobile ? 12 : 9}>
        <Col className={styles.personContainer}>
          <Typography
            msg={`${finalFirstName?.charAt(0).toUpperCase()}${finalLastName?.charAt(0).toUpperCase()}`}
            tag="span"
            color="white"
            bold
            size="lcx"
          />
        </Col>

        <Col className={styles.titleRefCase} size={8}>
          <Typography msg={name} tag="div" bold size="l" />
          {isDeathCase && (
            <Row constant size={12}>
              <Col size={5}>
                <Typography msg={t(Messages.fieldDeathDate)} tag="div" size="m" color="footerColor" />
              </Col>
              <Col size={7}>
                <Typography
                  msg={languageBasedDate(
                    user?.hasRefDeathCase ? refDeaths && refDeaths[0].deathDate : deathCaseShort?.deathDate,
                    currentLanguage
                  )}
                  tag="div"
                  bold
                  size="l"
                />
              </Col>
            </Row>
          )}

          <Row constant size={12}>
            <Col size={5}>
              <Typography msg={t(Messages.fieldBirthDate)} tag="div" size="m" color="footerColor" />
            </Col>
            <Col size={7}>
              <Typography msg={languageBasedDate(finalBirthDate, currentLanguage)} tag="div" bold size="l" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row column size={8}>
        <Button
          size={ButtonSizes.fill}
          type={ButtonTypes.button}
          onClick={() => {
            if (user?.hasRefDeathCase || user?.hasRefLifeCase) {
              confirmClick();
            } else {
              confirmCollaborator({
                variables: { input: { id: collaboratorId || '', isRelevant: true } },
              });
            }
          }}
          className={styles.confirmButton}
          label={t(Messages.buttonConfirm)}
          isFullWidth
        />

        <Button
          size={ButtonSizes.fill}
          type={ButtonTypes.button}
          style={ButtonStyles.transparent}
          label={t('button_not_recognise' as Messages)}
          isFullWidth
          onClick={() => {
            if (user?.hasRefDeathCase || user?.hasRefLifeCase) {
              callDeathCaseIrrelevant();
            } else {
              confirmCollaborator({
                variables: { input: { id: collaboratorId || '', isRelevant: false } },
              });
            }
          }}
        />
      </Row>
    </div>
  );
};

export default ReferredDeathCaseInfo;
