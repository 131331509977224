import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// import UserLayout from '@Components/layout/UserLayout';
import SettingsLayout from '@Components/layout/SettingsLayout';
import { LocalStorage } from '@Config/constants';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import DataDefinitionsPage from '@Routes/provider/DataDefinitions/DataDefinitionsPage';
import AccountPage from '@Routes/settings/AccountPage';
import CompanyPage from '@Routes/settings/CompanyPage';
import IdValidationPage from '@Routes/settings/IdValidationPage';
import PasswordChangePage from '@Routes/settings/PasswordChangePage';
import PaymentsPage from '@Routes/settings/PaymentsPage';
import SettingsMenuComponent from '@Routes/settings/SettingsMenuComponent';
import { NonAdminSettingsSubpages, SettingsSubpages } from '@Routes/settings/settingsSubpages';
import UsersPage from '@Routes/settings/UsersPage';
import { getUser } from '@Store/auth/auth.selector';
import { deleteFromLocalStorage, getBooleanValueCookie } from '@Utils/localStorage';

export interface SettingsPageProps {}

export interface SettingsPageDispatchProps {}

interface SettingsPagePrivateProps extends SettingsPageProps, SettingsPageDispatchProps {}

type SubpageConfig = { [key in SettingsSubpages]: React.ReactNode };

type NonAdminSubpageConfig = { [key in NonAdminSettingsSubpages]: React.ReactNode };

const RightPanelComponents: SubpageConfig = {
  [SettingsSubpages.Account]: AccountPage,
  [SettingsSubpages.IDValidation]: IdValidationPage,
  [SettingsSubpages.PasswordChange]: PasswordChangePage,
  [SettingsSubpages.Billing]: PaymentsPage,
  [SettingsSubpages.Users]: UsersPage,
  [SettingsSubpages.Company]: CompanyPage,
  [SettingsSubpages.RequiredFields]: DataDefinitionsPage,
};

const NonAdminRightPanelComponents: NonAdminSubpageConfig = {
  [SettingsSubpages.Account]: AccountPage,
  [SettingsSubpages.IDValidation]: IdValidationPage,
  [SettingsSubpages.PasswordChange]: PasswordChangePage,
};

const SettingsPage: React.FunctionComponent<SettingsPagePrivateProps> = () => {
  const user = useSelector(getUser);
  const { subpage }: any = useParams();

  window.onbeforeunload = popup;

  function popup() {
    if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
      deleteFromLocalStorage(LocalStorage.settingFormChanged);
      return 'You have not saved the changes that you have made on this page, woud you like the save them before leaving?';
    }
    deleteFromLocalStorage(LocalStorage.settingFormChanged);
    return window.close();
  }

  const RightPanel =
    user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
      ? (NonAdminRightPanelComponents[subpage as keyof NonAdminSubpageConfig] as React.ComponentClass<any>)
      : (RightPanelComponents[subpage as keyof SubpageConfig] as React.ComponentClass<any>);

  return (
    <SettingsLayout
      rightSideComponent={<RightPanel />}
      settingsMenuComponent={<SettingsMenuComponent active={subpage} />}
      isValidationPage={subpage === SettingsSubpages.IDValidation}
    />
  );
};

export default SettingsPage;
