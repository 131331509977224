import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import Button from '@Components/Button';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { validateRequiredBoolean } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './AuthorityActionModalBody.scss';

export interface AuthorityActionModalBodyProps {
  title: string;
  bodyMsg: string;
  link: string;
  query: string;
  isUnvitedProfessional?: boolean;
  agentUser?: boolean;
}

enum AuthorityFormFields {
  hasAuthority = 'hasAuthority',
}

const AuthorityActionModalBody: React.FunctionComponent<ModalProviderProps<AuthorityActionModalBodyProps>> = ({
  closeModal,
  modalData: { link, query, agentUser },
}) => {
  const t = useTranslations();
  const history = useHistory();

  return (
    <div className={styles.authorityContainer}>
      <Typography
        className={classNames(styles.lh, styles.mb8)}
        size="xl"
        tag="h4"
        bold
        msg="Confirm before continuing"
      />
      <Typography className={styles.mb24} size="m" tag="div" msg="Please read and confirm the following" />
      <Formik
        initialValues={{
          [AuthorityFormFields.hasAuthority]: false,
        }}
        validationSchema={Yup.object({
          [AuthorityFormFields.hasAuthority]: validateRequiredBoolean(t),
        })}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div
              className={styles.gridContainer}
              onClick={() => setFieldValue(AuthorityFormFields.hasAuthority, !values.hasAuthority)}
            >
              <GridRow>
                <GridCol size={12}>
                  <span className={styles.label}>
                    <Field className={styles.checkbox} type="checkbox" name={AuthorityFormFields.hasAuthority} />
                    <span className={styles.checkboxContent}>
                      {agentUser ? (
                        <Typography
                          msg={t('label_agent_authority_consent' as Messages)}
                          tag="span"
                          color="footerColor"
                          size="l"
                          bolder
                        />
                      ) : (
                        <>
                          <Typography
                            msg={t('label_prof_authority_consent' as Messages)}
                            tag="span"
                            color="footerColor"
                            size="l"
                            bolder
                          />
                          &nbsp;
                          <a
                            href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/921428/Mental-capacity-act-code-of-practice.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Typography
                              msg={t('label_code_of_practice' as Messages)}
                              tag="span"
                              color="footerColor"
                              size="l"
                              bolder
                            />
                          </a>
                        </>
                      )}
                    </span>
                  </span>
                </GridCol>
              </GridRow>
            </div>
            <Row justifyCenter size={12} className={styles.mt32}>
              <Button
                onClick={() => {
                  history.push(`${link}${query}`);
                  closeModal();
                }}
                label="Continue"
                isFullWidth
                className={styles.mb4}
                disabled={!values.hasAuthority}
              />
            </Row>
            <Typography
              className={styles.confirmContainer}
              align="center"
              size="m"
              tag="div"
              msg="We need you to confirm this information before you can continue providing any further details "
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthorityActionModalBody;
