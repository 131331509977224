import * as React from 'react';
import { CSVLink } from 'react-csv';

import ReportKpiBox from '@Components/application/ReportKpiBox/ReportKpiBox';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  useServiceProviderPortalStatisticsQuery,
  useServiceProviderEmployeeStatisticsQuery,
  useServiceProviderPerformanceStatisticsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './ReportsForm.scss';

export interface ReportsFormProps {
  startDate: string | null;
  endDate: string | null;
  spId: string;
}

const ReportsForm: React.FunctionComponent<ReportsFormProps> = ({ startDate, endDate, spId }) => {
  const t = useTranslations();

  const statisticValues = useServiceProviderPortalStatisticsQuery({
    fetchPolicy: 'network-only',
    variables: {
      startDate,
      endDate,
      serviceProvider: spId,
    },
  });

  const empStatisticValues = useServiceProviderEmployeeStatisticsQuery({
    fetchPolicy: 'network-only',
    variables: {
      startDate,
      endDate,
      serviceProvider: spId,
    },
  });

  const performStatisticValues = useServiceProviderPerformanceStatisticsQuery({
    fetchPolicy: 'network-only',
    variables: {
      startDate,
      endDate,
      serviceProvider: spId,
    },
  });

  if (statisticValues.loading || empStatisticValues.loading || performStatisticValues.loading) {
    return <LoadingSpinner />;
  }

  const employeeSampleData = () => {
    if (empStatisticValues.data) {
      return empStatisticValues.data?.serviceProviderEmployeeStatistics.map((x: any) => {
        return {
          name: x.serviceProviderUser
            ? `${x.serviceProviderUser.firstName} ${x.serviceProviderUser.lastName}`
            : t('label_unassigned' as Messages),
          active: x.statistics.ACTIVE,
          completed: x.statistics.COMPLETED,
          time: x.timeTaken,
        };
      });
    }
    return [];
  };

  const headers = [
    { label: t(Messages.labelEmployeeName), key: 'name' },
    { label: t(Messages.labelActiveNotifications), key: 'active' },
    { label: t(Messages.labelCompletedNotifications), key: 'completed' },
    { label: t(Messages.labelTimePerNotification), key: 'time' },
  ];

  const csvData = {
    filename: 'EmployeePerformance.csv',
    headers,
    data: employeeSampleData(),
  };

  const averageFromSentValue = (sent: boolean) => {
    if (performStatisticValues.data && performStatisticValues.data?.serviceProviderPerformanceStatistics.length > 0) {
      const averageFromSentTime = sent
        ? performStatisticValues.data.serviceProviderPerformanceStatistics[0].averageFromSent
        : performStatisticValues.data.serviceProviderPerformanceStatistics[0].averageFromOpen;

      if (averageFromSentTime < 1) {
        return `${Math.round(averageFromSentTime * 60)}@minutes`;
      }
      if (averageFromSentTime > 24) {
        return `${Math.round(averageFromSentTime / 24)}@days`;
      }
      return `${Math.round(averageFromSentTime)}@hours`;
    }
    return `0@minutes`;
  };

  return (
    <div style={{ marginBottom: '30px', marginTop: '20px' }}>
      <Typography className={styles.topSpacing} msg={t(Messages.labelOverview)} tag="div" size="l" bold />
      <div className={styles.reportKpiContainer}>
        <ReportKpiBox
          icon="more-horizontal"
          kpiHeader="Unopened"
          kpiValue={statisticValues.data ? statisticValues.data?.serviceProviderPortalStatistics.SENT : 0}
        />
        <ReportKpiBox
          icon="clock"
          kpiHeader={t(Messages.labelInProgress)}
          kpiValue={statisticValues.data ? statisticValues.data?.serviceProviderPortalStatistics.INProgress : 0}
        />
        <ReportKpiBox
          icon="doneAll"
          kpiHeader="Complete"
          kpiValue={statisticValues.data ? statisticValues.data?.serviceProviderPortalStatistics.COMPLETED : 0}
        />
        <ReportKpiBox
          icon="person-delete"
          kpiHeader="No account found"
          kpiValue={statisticValues.data ? statisticValues.data?.serviceProviderPortalStatistics.NOTFound : 0}
        />
      </div>

      <Typography className={styles.topSpacing} msg={t(Messages.labelNotificationTimeReport)} tag="div" size="l" bold />
      <div className={styles.reportKpiContainer}>
        <ReportKpiBox
          kpiHeader={t(Messages.labelSentToComplete)}
          kpiValue={parseFloat(averageFromSentValue(true).split('@')[0])}
          kpiMetric={averageFromSentValue(true).split('@')[1]}
        />
        <ReportKpiBox
          kpiHeader={t(Messages.labelOpenedToComplete)}
          kpiValue={parseFloat(averageFromSentValue(false).split('@')[0])}
          kpiMetric={averageFromSentValue(false).split('@')[1]}
        />
      </div>

      <Typography className={styles.topSpacing} msg={t(Messages.labelEmployeePerformance)} tag="div" size="l" bold />
      <div className={styles.employeePerformanceContainer}>
        <Row size={12}>
          <Col size={3}>
            <Typography className={styles.topSpacing} msg={t(Messages.labelEmployeeName)} tag="div" size="m" bold />
          </Col>

          <Col size={3}>
            <Typography
              className={styles.topSpacing}
              msg={t(Messages.labelActiveNotifications)}
              tag="div"
              size="m"
              bold
            />
          </Col>

          <Col size={3}>
            <Typography
              className={styles.topSpacing}
              msg={t(Messages.labelCompletedNotifications)}
              tag="div"
              size="m"
              bold
            />
          </Col>

          <Col size={3}>
            <Typography
              className={styles.topSpacing}
              msg={t(Messages.labelTimePerNotification)}
              tag="div"
              size="m"
              bold
            />
          </Col>
        </Row>
        <div className={styles.employeesContainer}>
          {empStatisticValues.data?.serviceProviderEmployeeStatistics.map((x: any) => (
            <Row className={styles.employeeRecord} key={x} size={12}>
              <Col size={3}>
                <Typography
                  msg={
                    x.serviceProviderUser
                      ? `${x.serviceProviderUser.firstName} ${x.serviceProviderUser.lastName}`
                      : t('label_unassigned' as Messages)
                  }
                  tag="div"
                  size="m"
                />
              </Col>

              <Col size={3}>
                <Typography msg={x.statistics.ACTIVE} tag="div" size="m" />
              </Col>

              <Col size={3}>
                <Typography msg={x.statistics.COMPLETED} tag="div" size="m" />
              </Col>

              <Col size={3}>
                <Typography msg={x.timeTaken} tag="div" size="m" />
              </Col>
            </Row>
          ))}
        </div>

        <Row className={styles.svgContainer}>
          {/* <Button
            label={t(Messages.buttonDownloadSvgFile)}
            onClick={() => noop}
            style={ButtonStyles.transparent}
            icon="download"
            iconSize={IconSizes.ss}
          >  </Button> */}

          <CSVLink className={styles.csvButton} {...csvData}>
            <Icon icon="download" size={IconSizes.ss} />
            {t(Messages.buttonDownloadSvgFile)}
          </CSVLink>
        </Row>
      </div>
    </div>
  );
};

export default ReportsForm;
