import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

import CaseInfo from '@Components/application/CaseInfo';
import Button from '@Components/Button/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Links, PreArchivedDCStatuses, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseStatus, useDeathCasesQuery, UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import DeathCasesColumn from '@Routes/dashboard/DeathCasesPage/DeathCasesContent/DeathCasesColumn';
import styles from '@Routes/deathCase/ReadyDCPage/ServicesPage/DCServicesColumn/DCServicesColumn.scss';
import { getUser } from '@Store/auth/auth.selector';

export interface DeathCasesContentProps {}

const DeathCasesContent: React.FunctionComponent<DeathCasesContentProps> = () => {
  const user = useSelector(getUser);
  const t = useTranslations();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const { data, loading } = useDeathCasesQuery({
    variables: {
      status: PreArchivedDCStatuses,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <Col alignCenter textCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const draftStatusCases = data?.deathCases.edges.filter((dc: any) => dc.node.status === DeathCaseStatus.Draft);
  const restStatusCases = data?.deathCases.edges.filter((lc: any) => lc.node.status !== DeathCaseStatus.Draft);

  const draftCount = draftStatusCases ? draftStatusCases.length : 0;
  const restCasesCount = restStatusCases ? restStatusCases.length : 0;

  const totalCount = draftCount + restCasesCount;

  const emptyCaseComponent = () => {
    return user?.accountType === UserAccountType.Individual ? (
      <Row id="noDeathNotification" size={12} justifyCenter column className={styles.emptyContainer}>
        <Col textCenter>
          <Icon className={styles.emptyIcon} icon="attach" size={IconSizes.sm} />
        </Col>
        <Col className={styles.bottomSpacing} textCenter>
          <Typography msg={t('hint_no_deaths_added' as Messages)} tag="div" size="m" color="dividerColor" />
        </Col>
        <Col>
          <Row size={12} justifyCenter>
            <Col size={5}>
              <Button onClick={() => history.push(Links.dcCreate)} label="Add deceased's details" isFullWidth />
            </Col>
          </Row>
        </Col>
      </Row>
    ) : (
      <Row id="noDeathNotification" size={10} justifyCenter column className={styles.emptyContainer}>
        <Col textCenter>
          <Icon className={styles.emptyIcon} icon="moon" size={IconSizes.sm} />
        </Col>
        <Col textCenter>
          <Typography msg={t(Messages.labelZeroDeathCase)} tag="div" size="m" color="gray3" />
        </Col>
      </Row>
    );
  };

  return totalCount > 0 ? (
    <div id="deathCasesPreview">
      {draftStatusCases && draftStatusCases.length > 0 && (
        <div style={{ marginBottom: '30px' }}>
          <DeathCasesColumn
            CardComponent={CaseInfo}
            title="Information missing"
            dcCases={draftStatusCases}
            subTitle={
              user?.accountType === UserAccountType.Professional
                ? 'You can not send notifications for these death cases as there is still missing information'
                : ''
            }
          />
        </div>
      )}

      {restStatusCases && restStatusCases.length > 0 && (
        <DeathCasesColumn
          CardComponent={CaseInfo}
          title="Active"
          dcCases={restStatusCases}
          subTitle={
            user?.accountType === UserAccountType.Professional
              ? 'These are open death cases with all necessary information to send notifications to companies'
              : ''
          }
          showAssignedTo={!isMobile}
          isActiveStatus={true}
        />
      )}
    </div>
  ) : (
    emptyCaseComponent()
  );
};

export default DeathCasesContent;
