import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactResponsiveQueries } from '@Config/constants';
import layoutStyles from '@Routes/dashboard/DashboardPage/DashboardPage.scss';

import Col from '../Col';

import styles from './UserLayoutNew.scss';

export interface UserLayoutProps {
  className?: string;
  rightSideComponent?: React.ReactNode;
  leftSideComponent?: React.ReactNode;
  headerComponent?: React.ReactNode;
  hasStepper?: boolean;
  hasSeparator?: boolean;
  isCra?: boolean;
  companiesPage?: boolean;
}

const UserLayoutNew: React.FunctionComponent<UserLayoutProps> = ({
  rightSideComponent,
  leftSideComponent,
  isCra = false,
  companiesPage,
}) => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  const desktopSize = isCra ? 12 : 9;
  const tabletSize = 10;

  return companiesPage ? (
    <main className={styles.layoutContainer}>
      <div>{leftSideComponent}</div>
      <div className={styles.rightSideContainer}>
        <Col constant={isMobileBetweenTablet} size={12}>
          {rightSideComponent}
        </Col>
      </div>
    </main>
  ) : (
    <main className={layoutStyles.layoutContainer}>
      <div>{leftSideComponent}</div>
      <div className={layoutStyles.rightSideContainer}>
        <Col constant={isMobileBetweenTablet} size={isTablet ? tabletSize : desktopSize}>
          {rightSideComponent}
        </Col>
      </div>
    </main>
  );
};

export default UserLayoutNew;
