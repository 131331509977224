import * as React from 'react';

import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import { Messages } from '@Config/messages';
import { useGetMeQuery, UserNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import AccountForm from '@Routes/settings/AccountPage/AccountForm';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';

import styles from './AccountForm/AccountForm.scss';

export interface AccountPageProps {}

export interface AccountPageDispatchProps {}

interface AccountPagePrivateProps extends AccountPageProps, AccountPageDispatchProps {}

const AccountPage: React.FunctionComponent<AccountPagePrivateProps> = () => {
  const t = useTranslations();

  const { data, loading } = useGetMeQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <Row justifyCenter>
        <Col alignCenter>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  }

  return (
    <div className={styles.accountPage}>
      <TitleWithSubtitle
        title={t(`${Messages.titleSettingsSubItemPrefix}${SettingsSubpages.Account}` as Messages)}
        subtitle=""
        userSection
      />
      <AccountForm user={data?.me.info as UserNode} />
    </div>
  );
};

export default AccountPage;
