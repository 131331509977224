import typescriptFsa from 'typescript-fsa';

import {
  ForgotPasswordMutation,
  RegisterUserMutation,
  ResetPasswordMutation,
  UserNodeDetails,
  VerifyEmailMutation,
} from '@Graphql/graphqlTypes.generated';

import {
  ChangePasswordActionPayload,
  ForgotPasswordActionPayload,
  LoginActionPayload,
  RegisterActionPayload,
  ResendEmailActionPayload,
  ResendVerificationActionPayload,
  ResetPasswordActionPayload,
  UserAuth,
  VerifyEmailActionPayload,
} from './auth.types';

const actionCreator = typescriptFsa('@AUTH');

export const login = actionCreator.async<LoginActionPayload, UserAuth>('LOGIN');
export const logout = actionCreator('LOGOUT');
export const register = actionCreator.async<RegisterActionPayload, RegisterUserMutation, {}>('REGISTER');
export const verifyEmail = actionCreator.async<VerifyEmailActionPayload, VerifyEmailMutation, {}>('VERIFY_EMAIL');
export const forgotPassword = actionCreator.async<ForgotPasswordActionPayload, ForgotPasswordMutation, {}>(
  'FORGOT_PASSWORD'
);
export const resendEmail = actionCreator.async<ResendEmailActionPayload, ResetPasswordMutation, {}>('RESEND_EMAIL');
export const resendVerification = actionCreator.async<ResendVerificationActionPayload, ResetPasswordMutation, {}>(
  'RESEND_VERIFICATION'
);
export const resetPassword = actionCreator.async<ResetPasswordActionPayload, ResetPasswordMutation, {}>(
  'RESET_PASSSWORD'
);
export const changePassword = actionCreator.async<ChangePasswordActionPayload, {}>('CHANGE_PASSWORD');

export const getUserData = actionCreator.async<{}, UserNodeDetails>('GET_USER_DATA');

export const yotiPolling = actionCreator.async<{}, UserNodeDetails>('YOTI_POLLING');
export const startYotiPolling = actionCreator('START_YOTI_POLLING');
export const stopYotiPolling = actionCreator('STOP_YOTI_POLLING');
