import * as React from 'react';

import {
  CaseServiceProviderNode,
  DeathCaseFragment,
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { noop } from '@Utils/helpers';

import ChatDeskForm from './ChatDeskForm';

export interface ChatDeskPageProps {
  deathCaseServiceProvider: CaseServiceProviderNode | RestrictedCaseServiceProviderNode;
  accessCode: string;
  isCustomer?: boolean;
  deathCase: DeathCaseFragment | RestrictedDeathCaseNode;
  user?: UserNode | null;
  msgParent?: string;
  setMsgCallback?: (comm: string) => void;
}

const ChatDeskPage: React.FunctionComponent<ChatDeskPageProps> = ({
  deathCaseServiceProvider,
  accessCode,
  isCustomer,
  deathCase,
  user,
  msgParent,
  setMsgCallback = noop,
}) => {
  return (
    <ChatDeskForm
      deathCase={deathCase}
      isCustomer={isCustomer}
      accessCode={accessCode}
      caseServiceProvider={deathCaseServiceProvider as CaseServiceProviderNode}
      user={user}
      msgParent={msgParent}
      setMsgCallback={setMsgCallback}
    />
  );
};

export default ChatDeskPage;
