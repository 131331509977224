import classNames from 'classnames';
import * as React from 'react';

import styles from './Row.scss';

type sizes =
  | 'size1'
  | 'size2'
  | 'size3'
  | 'size4'
  | 'size5'
  | 'size6'
  | 'size7'
  | 'size8'
  | 'size9'
  | 'size10'
  | 'size11'
  | 'size12'
  | 'size13';

export interface RowProps {
  children: React.ReactNode;
  inline?: boolean;
  column?: boolean;
  columnMobile?: boolean;
  columnTablet?: boolean;
  wrap?: boolean;
  justifyEnd?: boolean;
  justifyAround?: boolean;
  justifyBetween?: boolean;
  justifyCenter?: boolean;
  alignStart?: boolean;
  alignCenter?: boolean;
  alignEnd?: boolean;
  constant?: boolean;
  className?: string;
  size?: number;
  lineHeightNormal?: boolean;
  id?: string;
  onClick?: (e?: any) => void;
  onKeyDown?: (e: any) => void;
  tabIndex?: number;
}

const Row: React.FunctionComponent<RowProps> = ({
  children,
  inline = false,
  column = false,
  columnMobile = false,
  columnTablet = false,
  wrap = false,
  justifyEnd = false,
  justifyAround = false,
  justifyBetween = false,
  justifyCenter = false,
  alignStart = false,
  alignCenter = false,
  alignEnd = false,
  constant = false,
  size = undefined,
  className,
  lineHeightNormal = false,
  id,
  tabIndex,
  onClick,
  onKeyDown,
}) => {
  return (
    <div
      id={id}
      className={classNames(styles.row, className, {
        [styles[`size${size}` as sizes]]: size,
        [styles.justifyEnd]: justifyEnd,
        [styles.justifyAround]: justifyAround,
        [styles.justifyBetween]: justifyBetween,
        [styles.justifyCenter]: justifyCenter,
        [styles.alignStart]: alignStart,
        [styles.alignCenter]: alignCenter,
        [styles.alignEnd]: alignEnd,
        [styles.inline]: inline,
        [styles.column]: column,
        [styles.columnMobile]: columnMobile,
        [styles.columnTablet]: columnTablet,
        [styles.wrap]: wrap,
        [styles.constant]: constant,
        [styles.lineHeightNormal]: lineHeightNormal,
      })}
      onClick={onClick}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
    >
      {children}
    </div>
  );
};

export default Row;
