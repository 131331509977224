import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { LocalStorage, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderStatus,
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { deleteFromLocalStorage, getFromLocalStorageString } from '@Utils/localStorage';

import styles from './NotificationStatusPanel.scss';

export interface NotificationStatusPanelProps {
  status: CaseServiceProviderStatus;
  accessCode: string;
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
  deathCase: RestrictedDeathCaseNode;
  providerDash?: boolean;
  isStaff?: boolean;
}

export interface StatusIndicators {
  icon: string;
  statusHeader: string;
  statusTitle: string;
  statusDescription: string;
  backgroundColor: string;
  statusTitleColor: string;
  iconColor?: string;
  borderColor?: string;
}

export type StatusColor =
  | 'inProgessBackgroundColor'
  | 'statusSuccessColor'
  | 'statusErrorColor'
  | 'sentStatusColor'
  | 'adminBgColor';

export type StatusTitleColors = 'yellowishOrange' | 'profRegisterBackground' | 'red' | 'black' | 'electricBlue';

export type StatusBorderColor =
  | 'inProgessBorderColor'
  | 'statusSuccessBorderColor'
  | 'statusErrorBorderColor'
  | 'sentStatusBorderColor'
  | 'adminBorderColor';

const NotificationStatusPanel: React.FunctionComponent<NotificationStatusPanelProps> = ({
  status,
  accessCode,
  deathCaseServiceProvider,
  deathCase,
  providerDash = false,
  isStaff,
}) => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const mobileStatusHeader = 'Current status:';
  const user = useSelector(getUser);
  const isStaffUser = user?.isStaff || isStaff;
  const [completedAt, setCompletedAt] = React.useState(deathCaseServiceProvider.completedAt);

  const setTabTitle = (notifyNumber: string) => {
    document.title = notifyNumber;
  };

  if (deathCase.notificationNumber) {
    setTabTitle(deathCase.notificationNumber);
  }
  const decideStatus = (statusType: CaseServiceProviderStatus): StatusIndicators => {
    let icon = '';
    let statusHeader = '';
    let statusTitle = '';
    let statusDescription = '';
    let backgroundColor = '';
    let statusTitleColor = '';
    let iconColor = '';
    let borderColor = '';

    const shortStatus = isTablet;
    const isMobileHeader = isMobile || shortStatus;

    switch (statusType) {
      case CaseServiceProviderStatus.InProgress:
        icon = 'clock';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'The status of this death notification is ';
        statusTitle = 'In progress';
        statusDescription =
          'Please change the status of this notification once you have solved the request or if this death has already been processed';
        backgroundColor = 'inProgessBackgroundColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        borderColor = 'inProgessBorderColor';
        break;
      case CaseServiceProviderStatus.NoActionRequired:
        icon = 'checkmark';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'No further Action Required: ';
        statusTitle = 'In progress outside Life Ledger';
        statusDescription = 'You are managing the request and are in contact with the notifier outside of Life Ledger';
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.CompletedClosed:
        icon = 'doneAll';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'Complete:';
        statusTitle = 'The account has been closed ';
        statusDescription = 'You have completed the request made by the notifier, and the account has been closed';
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.CompletedSwitched:
        icon = 'shuffle';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'Complete:';
        statusTitle = 'The account has been switched ';
        statusDescription = 'You have completed the request made by the notifier, and the account has been switched';
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.AlreadyRegistered:
        icon = 'doneAll';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'No further Action Required: ';
        statusTitle = 'This death has already been registered';
        statusDescription = 'This case was dealt with outside of Life Ledger';
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.NotFound:
        icon = 'person-delete';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'No further Action Required: ';
        statusTitle = 'No account found';
        statusDescription = 'Your given details could not be matched with with an account';
        backgroundColor = 'adminBgColor';
        statusTitleColor = 'electricBlue';
        iconColor = 'noAccountFoundColor';
        borderColor = 'adminBorderColor';
        break;
      case CaseServiceProviderStatus.Sent:
        icon = 'more-horizontal';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'The status of this death notification is ';
        statusTitle = 'Not started';
        statusDescription = 'The service provider has not yet started managing your request yet.';
        backgroundColor = 'adminBgColor';
        statusTitleColor = 'electricBlue';
        iconColor = 'adminBgColor';
        borderColor = 'sentStatusBorderColor';
        break;
      case CaseServiceProviderStatus.CompletedByLl:
        icon = 'doneAll';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'Complete:';
        statusTitle = t(Messages.statusCompletedByLl);
        statusDescription = t(Messages.statusDescCompletedByLl);
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.Closed:
        icon = 'doneAll';
        statusHeader = 'Closed:';
        statusTitle = t('title_notification_closed' as Messages);
        statusDescription = t('desc_notification_closed' as Messages);
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.Confirmed:
        icon = 'doneAll';
        statusHeader = 'Confirmed:';
        statusTitle = 'Confirmed';
        statusDescription = 'You have completed the request made by the notifier, and the account has been closed';
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        borderColor = 'statusSuccessBorderColor';
        break;
      case CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier:
        icon = 'clock';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'The status of this death notification is ';
        statusTitle = 'In progress: waiting for notifier';
        statusDescription = t('dec_sp_notification_awating_from_notifier' as Messages);
        backgroundColor = 'inProgessBackgroundColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        borderColor = 'inProgessBorderColor';
        break;
      case CaseServiceProviderStatus.InProgressAwaitingInformationFromSp:
        icon = 'clock';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'The status of this death notification is ';
        statusTitle = isStaffUser ? 'In progress: waiting for service provider' : 'In progress';
        statusDescription = t('dec_sp_notification_awating_from_sp' as Messages);
        backgroundColor = 'inProgessBackgroundColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        borderColor = 'inProgessBorderColor';
        break;
      case CaseServiceProviderStatus.InProgressAutoPopulationFailed:
        icon = 'clock';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'The status of this death notification is ';
        statusTitle = isStaffUser ? 'In progress: auto population failed' : 'In progress';
        statusDescription = isStaffUser
          ? t('dec_auto_population_failed' as Messages)
          : 'Please change the status of this notification once you have solved the request or if this death has already been processed';
        backgroundColor = 'inProgessBackgroundColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        borderColor = 'inProgessBorderColor';
        break;
      default:
        icon = 'clock';
        statusHeader = isMobileHeader ? mobileStatusHeader : 'The status of this death notification is ';
        statusTitle = 'In progress';
        statusDescription =
          'Please change the status of this notification once you have solved the request or if this death has already been processed';
        backgroundColor = 'inProgessBackgroundColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        borderColor = 'inProgessBorderColor';
        break;
    }
    const statusDetails: StatusIndicators = {
      icon,
      statusHeader,
      statusTitle,
      statusDescription,
      backgroundColor,
      statusTitleColor,
      iconColor,
      borderColor,
    };
    return statusDetails;
  };

  const convertStatus = (statusInString: string): CaseServiceProviderStatus => {
    let originalStatus = CaseServiceProviderStatus.InProgress;
    switch (statusInString) {
      case 'IN_PROGRESS':
        originalStatus = CaseServiceProviderStatus.InProgress;
        break;
      case 'IN_PROGRESS_AUTO_POPULATION_FAILED':
        originalStatus = CaseServiceProviderStatus.InProgressAutoPopulationFailed;
        break;
      case 'NO_ACTION_REQUIRED':
        originalStatus = CaseServiceProviderStatus.NoActionRequired;
        break;
      case 'COMPLETED_CLOSED':
        originalStatus = CaseServiceProviderStatus.CompletedClosed;
        break;
      case 'COMPLETED_SWITCHED':
        originalStatus = CaseServiceProviderStatus.CompletedSwitched;
        break;
      case 'ALREADY_REGISTERED':
        originalStatus = CaseServiceProviderStatus.AlreadyRegistered;
        break;
      case 'NOT_FOUND':
        originalStatus = CaseServiceProviderStatus.NotFound;
        break;
      case 'COMPLETED_BY_LL':
        originalStatus = CaseServiceProviderStatus.CompletedByLl;
        break;
      case 'IN_PROGRESS_AWAITING_INFORMATION_FROM_NOTIFIER':
        originalStatus = CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier;
        break;
      case 'IN_PROGRESS_AWAITING_INFORMATION_FROM_SP':
        originalStatus = CaseServiceProviderStatus.InProgressAwaitingInformationFromSp;
        break;
      default:
        break;
    }
    return originalStatus;
  };

  const [notificationStatus, setNotificationStatus] = React.useState(status);

  const currentStatus = decideStatus(notificationStatus);

  const dispatch = useDispatch();

  const notificationStatusChangeHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.notificationStatusUpdateFormModal,
        params: {
          data: {
            buttonText: 'Change status',
            accessCode,
            status: notificationStatus,
            deathCaseServiceProvider,
            providerDash,
            onClose: (newCompletedAt: string) => {
              const newStatus = convertStatus(getFromLocalStorageString(LocalStorage.notificationStatus));
              setNotificationStatus(newStatus);
              setCompletedAt(newCompletedAt);
              deleteFromLocalStorage(LocalStorage.notificationStatus);
            },
          },
          //  title: 'Update status of death notification',
          modalSize: PaperWidths.l,
          isNotificationModal: true,
        },
      })
    );
  };

  const completedStatus = [
    CaseServiceProviderStatus.NoActionRequired,
    CaseServiceProviderStatus.CompletedClosed,
    CaseServiceProviderStatus.CompletedSwitched,
    CaseServiceProviderStatus.AlreadyRegistered,
    CaseServiceProviderStatus.CompletedByLl,
  ].includes(notificationStatus);

  const notFoundStatus = notificationStatus === CaseServiceProviderStatus.NotFound;

  const getStripeStyle = () => {
    if (completedStatus) {
      return styles.progressActiveContainer;
    }
    if (notFoundStatus) {
      return styles.notFoundContainer;
    }
    return styles.progressContainer;
  };

  return (
    <section
      //style={{ width: providerDash ? '81%' : '100%' }}
      className={classNames(styles.menuLayout, !isTablet ? styles.panelInsideSpacing : styles.panelInsideSpacing, {
        [styles[`${currentStatus.backgroundColor}` as StatusColor]]: currentStatus.backgroundColor,
        [styles[`${currentStatus.borderColor}` as StatusBorderColor]]: currentStatus.borderColor,
      })}
    >
      <div className={styles.menuContentLayout}>
        <Row>
          <div className={styles.container}>
            <div className={getStripeStyle()}>
              <div className={styles.progress} id="progress">
                {' '}
              </div>
              <div
                className={classNames(styles.circle, {
                  [styles.active]: !notFoundStatus,
                  [styles.notFound]: notFoundStatus,
                })}
                data-tooltip={deathCase && `${deathCase.firstName} ${deathCase.lastName} passed away`}
                data-tooltip1={deathCase && languageBasedDate(deathCase.deathDate, currentLanguage)}
              >
                <Icon icon="moon" className={styles.iconColor} size={IconSizes.progressBar} />
              </div>
              <div
                className={classNames(styles.circle, {
                  [styles.active]: !notFoundStatus,
                  [styles.notFound]: notFoundStatus,
                })}
                data-tooltip="Death notification sent"
                data-tooltip1={languageBasedDate(deathCaseServiceProvider.sentAt, currentLanguage)}
              >
                <Icon icon="paper-plane" className={styles.iconColor} size={IconSizes.progressBar} />
              </div>
              <div
                className={classNames(styles.circle, {
                  [styles.active]: !notFoundStatus,
                  [styles.notFound]: notFoundStatus,
                })}
                data-tooltip="You opened death notification"
                data-tooltip1={languageBasedDate(deathCaseServiceProvider.seenAt, currentLanguage)}
              >
                <Icon icon="clipboard" className={styles.iconColor} size={IconSizes.progressBar} />
              </div>
              <div
                className={classNames({
                  [styles.finalCircle]: !completedStatus,
                  [styles.circle]: completedStatus,
                  [styles.active]: completedStatus,
                  [styles.notFoundFinalCircle]: notFoundStatus,
                  [styles.inProgess]: !completedStatus && !notFoundStatus,
                })}
                data-tooltip="Process notification and update status"
                data-tooltip1={languageBasedDate(completedAt, currentLanguage)}
              >
                <Icon
                  icon={notFoundStatus ? 'person-delete' : 'doneAll'}
                  className={completedStatus || notFoundStatus ? styles.iconColor : styles.iconInactiveColor}
                  size={IconSizes.progressBar}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row size={12} className={styles.flexDisplay}>
          <Col size={6} className={styles.mt7}>
            <Button
              type={ButtonTypes.button}
              label="Update Status"
              onClick={() => notificationStatusChangeHandler()}
              size={ButtonSizes.m}
              isFullWidth
            />
          </Col>
          <Col size={6}>
            <Row alignCenter className={styles.gap8}>
              <Icon
                size={IconSizes.ss}
                icon={currentStatus.icon}
                className={classNames({
                  [styles[`${currentStatus.statusTitleColor}` as StatusTitleColors]]: currentStatus.statusTitleColor,
                })}
              />
              <Typography
                tag="span"
                msg={currentStatus.statusTitle}
                bolder
                size="l"
                className={classNames({
                  [styles[`${currentStatus.statusTitleColor}` as StatusTitleColors]]: currentStatus.statusTitleColor,
                })}
              />
            </Row>
            {!isTablet && (
              <Row className={styles.lh}>
                <Typography color="footerColor" tag="div" msg={currentStatus.statusDescription} size="m" />
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default NotificationStatusPanel;
