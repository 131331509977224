import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import DataWithLabel from '@Components/DataWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { CaseTags, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DeathCaseStatus, LifeCaseNode, Permissions } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpagesNew } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { showEmptyModalHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './AddressPanel.scss';

export interface AddressPanelProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  isLifeCase?: boolean;
  hideEdit?: boolean;
}

const AddressPanel: React.FunctionComponent<AddressPanelProps> = ({ data, isLifeCase = false, hideEdit }) => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const dispatch = useDispatch();

  const showEdit =
    data.item?.status === DeathCaseStatus.Draft || data.item?.status === DeathCaseStatus.Approved || isLifeCase;

  const editForm = () => {
    if (showEdit) {
      history.push(
        generatePath(isLifeCase ? `${Links.lcEdit}${queryString}` : `${Links.dcEdit}${queryString}`, {
          id: data.item?.id,
          subpage: DeathCaseSubpagesNew.GeneralStep2,
          tag: `tag=${CaseTags.deathCaseEdit}`,
        })
      );
    } else {
      showEmptyModalHandler(t(Messages.titleCannotEditCase), t(Messages.descCannotEditCase), dispatch);
    }
  };

  const showEditOption = data.item?.myPermission !== Permissions.ReadOnly && showEdit && !hideEdit;

  return (
    <>
      <ConfirmationHeader showEdit={showEditOption} title="Address" onClick={editForm} />

      <Paper width="width-full" className={styles.deathCaseContainer}>
        {data.item && (
          <>
            <Typography msg="Current address" tag="div" bold size="m" />
            <div className={styles.addressContainer}>
              <Row constant columnMobile>
                <Col size={5}>
                  <DataWithLabel dashForEmpty data={data.item?.address1} label="Address line 1" isConfirmationForm />
                </Col>
                <Col size={1} constant />
                <Col size={5}>
                  <DataWithLabel dashForEmpty data={data.item?.address2} label="Address line 2" isConfirmationForm />
                </Col>
              </Row>
              <Row constant columnMobile>
                <Col size={5}>
                  <DataWithLabel dashForEmpty data={data.item?.city} label="Town/City" isConfirmationForm />
                </Col>
                <Col size={1} constant />
                <Col size={5}>
                  <DataWithLabel dashForEmpty data={data.item?.postalCode} label="Postcode" isConfirmationForm />
                </Col>
              </Row>
              <Row constant columnMobile>
                {data.item.propertyType && (
                  <Col size={5}>
                    <DataWithLabel
                      dashForEmpty
                      data={t(
                        `${Messages.labelBaseCasePropertyPrefix}${data.item.propertyType.toLowerCase()}` as Messages
                      )}
                      label="Ownership"
                      isConfirmationForm
                    />
                  </Col>
                )}
                <Col size={1} constant />
                {data.item.occupancyStatus && (
                  <Col size={5}>
                    <DataWithLabel
                      data={t(
                        `${Messages.labelOccupancyStatusPrefix}${data.item?.occupancyStatus.toLowerCase()}` as Messages
                      )}
                      label="Occupancy status"
                      isConfirmationForm
                      dashForEmpty
                    />
                  </Col>
                )}
              </Row>
            </div>

            {data.item.previousAddress1 && data.item.previousAddress1 !== '' && (
              <>
                <Typography msg="Previous address" tag="div" bold size="m" />
                <div className={styles.addressContainer}>
                  <Row constant columnMobile>
                    <Col size={5}>
                      <DataWithLabel
                        dashForEmpty
                        data={data.item?.previousAddress1}
                        label="Address line 1"
                        isConfirmationForm
                      />
                    </Col>
                    <Col size={1} constant />
                    <Col size={5}>
                      <DataWithLabel
                        dashForEmpty
                        data={data.item?.previousAddress2}
                        label="Address line 2"
                        isConfirmationForm
                      />
                    </Col>
                  </Row>
                  <Row constant columnMobile>
                    <Col size={5}>
                      <DataWithLabel dashForEmpty data={data.item?.previousCity} label="Town/City" isConfirmationForm />
                    </Col>
                    <Col size={1} constant />
                    <Col size={5}>
                      <DataWithLabel
                        dashForEmpty
                        data={data.item?.previousPostalCode}
                        label="Postcode"
                        isConfirmationForm
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default AddressPanel;
