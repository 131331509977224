import classNames from 'classnames';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FileView from '@Components/application/FileView';
import Paper from '@Components/application/GrayPaper/';
import CardTitle from '@Components/CardTitle';
import DataWithLabel from '@Components/DataWithLabel';
import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import List from '@Components/List';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
  useNotifierIdDocumentsQuery,
  UserDocumentsNode,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getEnableTuCheck } from '@Store/app/app.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { vulnerabilityVisibility } from '@Utils/helpers';
import { somethingWrongHandler } from '@Utils/modal';

import styles from '../SummaryInfoDeceased/SummaryInfoDeceased.scss';

export interface SummaryInfoDeceasedProps {
  deathCase: RestrictedDeathCaseNode;
  userAccountType?: string;
  deathCaseServiceProvider?: RestrictedCaseServiceProviderNode;
  accessCode?: string;
  user?: UserNode | null;
  setActiveTabCallback?: () => void;
}

const SummaryInfoNotifier: React.FunctionComponent<SummaryInfoDeceasedProps> = ({
  deathCase,
  userAccountType,
  deathCaseServiceProvider,
  accessCode = '',
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();
  const enableTuCheck = useSelector(getEnableTuCheck);

  const notifierIdDocuments = useNotifierIdDocumentsQuery({
    variables: {
      caseServiceProvider: deathCaseServiceProvider ? deathCaseServiceProvider?.id : '',
    },
    fetchPolicy: 'network-only',
    skip: !deathCaseServiceProvider?.serviceProvider.showNotifierIdDocuments,
  });

  const natureItems = () => {
    const vulnerabilityNature = deathCase.notifier.vulnerabilityNature as string | string[] | null | undefined;

    if (Array.isArray(vulnerabilityNature)) {
      // If vulnerabilityNature is already an array, map through it
      const mappedNature = vulnerabilityNature.map((vn: string) =>
        t(`label_vulnerable_nature_${vn.toLowerCase()}` as Messages)
      );
      return mappedNature;
    }

    if (typeof vulnerabilityNature === 'string') {
      try {
        // Replace single quotes with double quotes and parse it into an array
        const parsedArray = JSON.parse(vulnerabilityNature.replace(/'/g, '"')) as string[];

        if (Array.isArray(parsedArray)) {
          const mappedNature = parsedArray.map((vn: string) =>
            t(`label_vulnerable_nature_${vn.toLowerCase()}` as Messages)
          );
          return mappedNature;
        }
      } catch (error) {
        //eslint-disable-next-line
        console.error('Invalid JSON format for vulnerabilityNature:', error);
      }
    }

    return []; // Return an empty array if it's neither a string nor an array
  };

  const checkNatureIsDefined = (input: any) => {
    // Check if the input is null
    if (input === null) {
      //eslint-disable-next-line
      console.log('Input is null');
      return false;
    }

    // Check if the input is a string
    if (typeof input === 'string') {
      return input !== '';
    }

    // Check if the input is already an array
    if (Array.isArray(input)) {
      return input.length > 0;
    }
    return input;
  };

  const convertDeclaredVulnerable = (consent: any) => {
    if (typeof consent === 'string') {
      return consent.toLowerCase() === 'true' ? 'Yes' : 'No';
    }
    return consent ? 'Yes' : 'No';
  };

  return (
    <Row column className={classNames(styles.width, styles.sameHeight)}>
      {(deathCaseServiceProvider?.serviceProvider.isUserValidationRequired ||
        enableTuCheck ||
        deathCaseServiceProvider?.serviceProvider.showNotifierIdDocuments) && (
        <Paper
          width="width-full"
          className={classNames(userAccountType ? styles.professionalPaper : styles.normalGrayPaper, styles.sameHeight)}
        >
          <div className={styles.deceasedContainer}>
            {deathCaseServiceProvider && (
              <Row justifyEnd constant>
                <LinkButton
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode);
                    }
                  }}
                  linkTitle="Something wrong?"
                  onClick={() => somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode)}
                />
              </Row>
            )}
          </div>
          {(deathCaseServiceProvider?.serviceProvider.isUserValidationRequired || enableTuCheck) && (
            <>
              <CardTitle
                heading="Notifier ID Verification"
                description="This notifier has had their identification verified"
              />

              <Row constant columnMobile>
                <Col>
                  <DataWithLabel data="TransUnion " label={t(Messages.labelVerifiedBy)} dashForEmpty />
                </Col>
                <Col className={styles.tickContainer}>
                  <Icon icon="doneAll" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataWithLabel
                    data={
                      deathCase.notifier.verifiedAt !== 'None'
                        ? languageBasedDate(deathCase.notifier.verifiedAt, currentLanguage)
                        : '-'
                    }
                    label={t(Messages.labelVerifiedAt)}
                    dashForEmpty
                  />
                </Col>
              </Row>
            </>
          )}

          {notifierIdDocuments.data?.notifierIdDocuments.map((fileDetails: UserDocumentsNode, idx: number) => (
            <>
              <CardTitle heading={t('title_notifier_id_document' as Messages)} />
              <div key={idx} className={styles.mb8}>
                <FileView
                  key={idx}
                  fileDetails={fileDetails}
                  isGray
                  isNotificationPage
                  containerClassName={styles.p18}
                />
              </div>
            </>
          ))}
        </Paper>
      )}
      {vulnerabilityVisibility(deathCase) && (
        <Paper
          width="width-full"
          className={classNames(userAccountType ? styles.professionalPaper : styles.normalGrayPaper, styles.sameHeight)}
        >
          <CardTitle heading={t('title_sp_vulnerability_section' as Messages)} />
          {deathCase.notifier.declaredVulnerability && (
            <Row constant columnMobile>
              <Col>
                <DataWithLabel
                  data={convertDeclaredVulnerable(deathCase.notifier.declaredVulnerability)}
                  label={t('label_sp_has_vulnerability' as Messages)}
                  dashForEmpty
                />
              </Col>
            </Row>
          )}
          {checkNatureIsDefined(deathCase.notifier.vulnerabilityNature) && (
            <Row column>
              <Typography
                color="footerColor"
                tag="div"
                msg={t('label_sp_vulnerability_nature' as Messages)}
                size="s"
                bold
              />

              <List listItems={natureItems()} containerClassName={styles.noPadding} />
            </Row>
          )}
          {deathCase.notifier.vulnerabilityOtherDetails && (
            <Row>
              <Col>
                <DataWithLabel
                  data={deathCase.notifier.vulnerabilityOtherDetails}
                  dashForEmpty
                  label={t('label_sp_vulnerability_other_details' as Messages)}
                />
              </Col>
            </Row>
          )}
        </Paper>
      )}
    </Row>
  );
};

export default SummaryInfoNotifier;
