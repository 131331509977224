import { connect } from 'react-redux';

import LegalForm, { LegalFormProps, LegalFormStateProps } from '@Routes/deathCase/DeathCasePage/LegalForm/LegalForm';
import { getUser } from '@Store/auth/auth.selector';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
  user: getUser(state),
});

export default connect<LegalFormStateProps, {}, LegalFormProps, RootState>(mapStateToProps)(LegalForm);
