import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './RenewSubscriptionModalBody.scss';

export interface RenewSubscriptionModalBodyProps {
  cancelledSubscription: any;
}

const RenewSubscriptionModalBody: React.FunctionComponent<ModalProviderProps<RenewSubscriptionModalBodyProps>> = ({
  closeModal,
  modalData: { cancelledSubscription },
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const stripe = useStripe();
  const productDetails = cancelledSubscription.product;

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      closeModal();
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  return (
    <div className={styles.paddingBody}>
      <Typography msg={t('title_subscription_held' as Messages)} tag="h4" bold size="xl" />

      <Typography
        msg={t('text_subscription_held' as Messages)}
        tag="div"
        className={classNames(styles.bottomSpacing)}
        size="l"
      />
      <Typography
        msg={t('text_subscription_account_held' as Messages)}
        tag="div"
        className={classNames(styles.bottomSpacing)}
        size="l"
      />

      <Row justifyCenter constant>
        <Typography className={styles.fromLabel} msg="From" tag="div" size="l" />
        &nbsp;
        <Typography
          bold
          msg={`${cancelledSubscription.product?.currencySymbol}${productDetails?.unitAmount}`}
          tag="div"
          size="xxxl"
        />
        <Typography
          msg={`/${productDetails.recurringInterval.toLowerCase()}`}
          tag="div"
          size="l"
          className={styles.tenure}
        />
      </Row>

      <Row justifyCenter constant alignCenter className={classNames(styles.f16, styles.listItemGap, styles.mt24)}>
        <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
        <Typography
          className={classNames(styles.f16, styles.footerColor)}
          msg="Add unlimited service providers"
          tag="div"
        />
      </Row>

      <Button
        onClick={() => {
          stripeCreateDcSubscriptionMutation({
            variables: { input: { priceId: productDetails?.priceId, baseCase: cancelledSubscription.baseCaseId } },
          });
        }}
        label={t('button_subscribe_now' as Messages)}
        isFullWidth
        className={styles.subscribeButton}
      />
      <Typography
        textCenter
        className={styles.mt16}
        msg="Cancel at any time, effective at the end of the payment period"
        color="footerColor"
        tag="div"
        size="m"
      />
    </div>
  );
};

export default RenewSubscriptionModalBody;
