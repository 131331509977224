import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput, { InputTypes } from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { CustomAccountDataDefinitionInputType } from '@Components/Modal/DataDefinitionModalBody/DataDefinitionModalBody';
import Typography from '@Components/Typography';
import { emptySelectorValue } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  AccountDataDefinitionInputType,
  AccountDataDefinitionNode,
  UpdateDataDefinitionMutation,
  UpdateDataDefinitionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { queryServiceProviderDataFields } from '@Graphql/providers/queries';
import { mutationUpdateDataDefinition } from '@Graphql/serviceProvidersPortal/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { LooseObject } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';
import { validateRequired, validateSelector } from '@Utils/validator';

import styles from './EditRequiredFields.scss';

export interface EditRequiredFieldsProps {
  dataDefinitions: AccountDataDefinitionNode[] | undefined;
  closeEdit: () => void;
}

enum DataDefinitionFormFields {
  inputLabel = 'inputLabel',
  inputType = 'inputType',
  inputValues = 'inputValues',
  isPrimaryIdentifier = 'isPrimaryIdentifier',
  isMandatory = 'isMandatory',
  isMultipleOptions = 'isMultipleOptions',
}

const EditRequiredFields: React.FunctionComponent<EditRequiredFieldsProps> = ({ dataDefinitions, closeEdit }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const inputTypeOptions = Object.values(CustomAccountDataDefinitionInputType).map((inputType: any) => ({
    label: t(`${Messages.labelDataDefnInputTypePrefix}${inputType.toLowerCase()}` as Messages),
    value: inputType,
  }));

  const initialValues: LooseObject = {};

  const inputTypeInitialValue = (dataDefinition: any) => {
    const moddataDefnType =
      dataDefinition.inputType === AccountDataDefinitionInputType.Checkbox ||
      dataDefinition.inputType === AccountDataDefinitionInputType.Radiobutton
        ? CustomAccountDataDefinitionInputType.MultipleChoice
        : dataDefinition.inputType;
    return inputTypeOptions.find((item: any) => item.value === moddataDefnType);
  };

  const checkOrRadioInitialValue = (dataDefinition: any) => {
    return dataDefinition.inputType === AccountDataDefinitionInputType.Checkbox;
  };

  //eslint-disable-next-line
  dataDefinitions?.forEach((dataDefinition: AccountDataDefinitionNode, idx: number) => {
    initialValues[`${DataDefinitionFormFields.inputLabel}_${idx}`] = dataDefinition?.inputLabel || '';
    initialValues[`${DataDefinitionFormFields.inputType}_${idx}`] =
      inputTypeInitialValue(dataDefinition) || emptySelectorValue;
    initialValues[`${DataDefinitionFormFields.inputValues}_${idx}`] = dataDefinition?.inputValues.join(',') || '';
    initialValues[`${DataDefinitionFormFields.isPrimaryIdentifier}_${idx}`] =
      dataDefinition?.isPrimaryIdentifier || false;
    initialValues[`${DataDefinitionFormFields.isMandatory}_${idx}`] = dataDefinition?.isMandatory || false;
    initialValues[`${DataDefinitionFormFields.isMultipleOptions}_${idx}`] = checkOrRadioInitialValue(dataDefinition);
  });

  const ddLength = dataDefinitions ? dataDefinitions.length : 0;

  const getDdSize = () => {
    const foo = [];
    for (let i = 0; i < ddLength; i += 1) {
      foo.push(i);
    }
    return foo;
  };

  const schemaObj: LooseObject = {};

  getDdSize().forEach((idx: number) => {
    schemaObj[`${DataDefinitionFormFields.inputLabel}_${idx}`] = validateRequired(t);
    schemaObj[`${DataDefinitionFormFields.inputType}_${idx}`] = validateSelector(t);
  });

  const inputTypeChange = (values: any, idx: number) => {
    const selectedValue = values[`inputType_${idx}`].value;
    if (
      selectedValue === AccountDataDefinitionInputType.Dropdown ||
      selectedValue === CustomAccountDataDefinitionInputType.MultipleChoice
    ) {
      return true;
    }
    return false;
  };

  const [updateDataDefinition] = useMutation<UpdateDataDefinitionMutation, UpdateDataDefinitionMutationVariables>(
    mutationUpdateDataDefinition,
    {
      refetchQueries: [getOperationName(queryServiceProviderDataFields) || ''],
      onCompleted: () => {
        closeEdit();
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const newSchema = Yup.object(schemaObj);

  const inputTypeOnSubmit = (inputType: any, isMultipleOpted: boolean) => {
    if (inputType === CustomAccountDataDefinitionInputType.MultipleChoice) {
      return isMultipleOpted ? AccountDataDefinitionInputType.Checkbox : AccountDataDefinitionInputType.Radiobutton;
    }
    return inputType;
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={newSchema}
        onSubmit={(values) => {
          //eslint-disable-next-line
          dataDefinitions?.forEach((dds: AccountDataDefinitionNode, idx: number) => {
            const options = values[`${DataDefinitionFormFields.inputValues}_${idx}`];
            updateDataDefinition({
              variables: {
                input: {
                  id: dds.id,
                  inputLabel: values[`${DataDefinitionFormFields.inputLabel}_${idx}`],
                  inputType: inputTypeOnSubmit(
                    values[`${DataDefinitionFormFields.inputType}_${idx}`].value,
                    values[`${DataDefinitionFormFields.isMultipleOptions}_${idx}`]
                  ),
                  inputValues: options ? options.split(',') : undefined,
                  isMandatory: values[`${DataDefinitionFormFields.isMandatory}_${idx}`],
                  isPrimaryIdentifier: values[`${DataDefinitionFormFields.isPrimaryIdentifier}_${idx}`],
                  serviceProvider: user?.serviceProvider,
                },
              },
            });
          });
        }}
      >
        {({ status, handleSubmit, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {getDdSize().map((idx: number) => (
                <GrayPaper key={idx} width="width-full">
                  <Typography msg={`Required field ${idx + 1}`} tag="div" size="m" bolder />
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={`${DataDefinitionFormFields.inputLabel}_${idx}`}
                        type={InputTypes.text}
                        component={TextInput}
                        label={t(Messages.titleDataDefnInputLabel)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={`${DataDefinitionFormFields.inputType}_${idx}`}
                        label={t(Messages.titleDataDefnInputType)}
                        component={SelectInput}
                        options={inputTypeOptions}
                      />
                    </GridCol>
                  </GridRow>
                  {inputTypeChange(values, idx) && (
                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={`${DataDefinitionFormFields.inputValues}_${idx}`}
                          type={InputTypes.textArea}
                          component={TextInput}
                          placeholder={t(Messages.msgCommaSeparationPlaceHolder)}
                          label={t('label_input_control_values' as Messages)}
                          controlDescription={t('label_input_values_help_text' as Messages)}
                        />
                      </GridCol>
                    </GridRow>
                  )}
                  {values[`inputType_${idx}`].value === CustomAccountDataDefinitionInputType.MultipleChoice && (
                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={`${DataDefinitionFormFields.isMultipleOptions}_${idx}`}
                          component={ToggleSwitchInput}
                          label={t('field_multiple_option_question' as Messages)}
                        />
                      </GridCol>
                    </GridRow>
                  )}

                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={`${DataDefinitionFormFields.isPrimaryIdentifier}_${idx}`}
                        component={ToggleSwitchInput}
                        label={t(Messages.titleDataDefnIsPrimaryIdentifier)}
                        infoMessage={t('tooltip_desc_primary_identifier' as Messages)}
                        infoTitle={t('tooltip_title_primary_identifier' as Messages)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={`${DataDefinitionFormFields.isMandatory}_${idx}`}
                        component={ToggleSwitchInput}
                        label={t(Messages.titleDataDefnIsMandatory)}
                        infoMessage={t('tooltip_desc_mandatory_field' as Messages)}
                        infoTitle={t('tooltip_title_mandatory_field' as Messages)}
                      />
                    </GridCol>
                  </GridRow>

                  <FormError formError={status} />
                </GrayPaper>
              ))}
              <Row alignCenter className={styles.gap24}>
                <Col size={7}>
                  <Button isFullWidth constant label={t(Messages.buttonSave)} type={ButtonTypes.submit} />
                </Col>
                <Col size={5}>
                  <Button
                    style={ButtonStyles.transparent}
                    onClick={closeEdit}
                    constant
                    isFullWidth
                    label={t('button_discard_changes' as Messages)}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditRequiredFields;
