import classNames from 'classnames';
import * as React from 'react';

import styles from './GridCol.scss';

export interface ColProps {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
  constant?: boolean;
  alignSelfBottom?: boolean;
}
type ColSizes =
  | 'grid-size1'
  | 'grid-size2'
  | 'grid-size3'
  | 'grid-size4'
  | 'grid-size5'
  | 'grid-size6'
  | 'grid-size7'
  | 'grid-size8'
  | 'grid-size9'
  | 'grid-size10'
  | 'grid-size11'
  | 'grid-size12';

const GridCol: React.FunctionComponent<ColProps> = ({ size, children, className, constant, alignSelfBottom }) => {
  return (
    <div
      className={classNames(styles[`grid-size${size}` as ColSizes], className, {
        [styles.constant]: constant,
        [styles.alignSelfBottom]: alignSelfBottom,
      })}
    >
      {children}
    </div>
  );
};

export default GridCol;
