import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import Icon from '@Components/Icon';
import Navigation from '@Components/Navigation';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './Header.scss';

export interface HeaderProps {
  showNavigation?: boolean;
  setMobileNavigation?: Function;
  showSideBar: boolean;
  loginRegisterVisibility?: boolean;
  showHamBurger?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  showNavigation = false,
  setMobileNavigation,
  showSideBar,
  loginRegisterVisibility = false,
  showHamBurger = true,
}) => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const history = useHistory();
  const t = useTranslations();

  // const queryString = history.location.search;

  const showLoginRegister = () => {
    if (isTablet) {
      if (loginRegisterVisibility) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <section className={styles.menuLayout}>
      <div className={styles.menuContentLayout}>
        <a href="https://lifeledger.com/">
          <img
            width={!isTablet ? '228' : '160'}
            height={!isTablet ? '49' : '40'}
            src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
            alt=""
          />
        </a>

        {showNavigation && (
          <>
            {!isTablet && (
              <div className={styles.elementorCol20}>
                <Navigation />
              </div>
            )}
            {showLoginRegister() && (
              <div className={styles.buttonContainer}>
                <a href={generatePath(`${Links.login}`)} className={styles.itemMenuLink}>
                  {t(Messages.buttonLogin)}
                </a>
                <span>
                  <Button
                    type={ButtonTypes.button}
                    label={t(Messages.buttonCreateAccount)}
                    onClick={() => history.push(`${Links.register}`)}
                    className={styles.mb4}
                  />
                </span>
              </div>
            )}
          </>
        )}

        {isTablet && showHamBurger && (
          <div
            className={styles.burgerIconSpacing}
            onClick={() => setMobileNavigation && setMobileNavigation(!showSideBar)}
          >
            <Icon icon="bars" />
          </div>
        )}
      </div>
    </section>
  );
};

export default Header;
