import * as React from 'react';

import { getDefaultLanguage } from './appConfig';

export interface LocaleContextType {
  localeContext: string;
}
const localeContext = React.createContext(getDefaultLanguage());

export const AppContextProvider = localeContext.Provider;
export const AppContextConsumer = localeContext.Consumer;
