// SSOLogin.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@Components/Typography';
import { Links, LocalStorage } from '@Config/constants';
import { setToLocalStorage } from '@Utils/localStorage';

import styles from './SSOLogin.scss'; // Import your CSS file

const SSOLogin = () => {
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const token = searchParams.get('token');
    const medium = searchParams.get('medium');
    setToLocalStorage(LocalStorage.userToken, token);
    setToLocalStorage(LocalStorage.socialLoginType, medium);
    // Redirect to the dashboard or home page
    history.push(Links.providerDashboard);
  }, [history]);

  return (
    <div className={styles.ssoLoginContainer}>
      <img
        className={styles.ssoLogo}
        alt="LV Insurance"
        src="https://lifeledger-api-dev-files.s3.eu-west-2.amazonaws.com/service_provider/logos/download-40.jpg"
      />
      <Typography msg="Logging you in..." tag="h1" size="xl" color="footerColor" />
    </div>
  );
};

export default SSOLogin;
