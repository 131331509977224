import classNames from 'classnames';
import * as React from 'react';

import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './DiscoveryInfo.scss';

export interface DiscoveryInfoProps {}

const DiscoveryInfo: React.FunctionComponent<DiscoveryInfoProps> = () => {
  const t = useTranslations();

  const openBankingAdvantages = [
    'This option allows you to securely log into your bank account(s) and pull certain data relating to the companies you have accounts with.',
    'The login uses an encrypted end-to-end connection.',
    'We identify companies that you will likely want to be notified of your death.',
    'We do not save or record your login details.',
  ];

  const bankInformation = [
    'Bank being accessed: bank name; number; sort code; name on account',
    'Transaction information from the last 12 months: date; company name; account or payment reference number; amount paid',
    'Balance information: we are provided this information automatically but as we do not want or need it, we immediately delete it, and do not use or store it.',
  ];

  return (
    <>
      <Typography className={styles.mt12} bold tag="div" size="xl" msg="How Life Ledger Discovery works" />

      <Typography
        className={classNames(styles.mt8, styles.letterSpacing)}
        tag="p"
        size="m"
        msg={t('ll_discovery_works_first_para' as Messages)}
      />
      <Typography
        className={classNames(styles.mt16, styles.letterSpacing)}
        tag="p"
        size="m"
        msg={t('ll_discovery_works_second_para' as Messages)}
      />
      <Row constant justifyCenter className={styles.mt16}>
        <Icon icon="globeWithLL" />
      </Row>
      <Typography
        className={classNames(styles.mt32, styles.letterSpacing)}
        bold
        tag="div"
        size="m"
        msg="Open banking"
      />
      <List listItems={openBankingAdvantages} />

      <div className={styles.graySection}>
        <Typography
          className={classNames(styles.letterSpacing)}
          tag="div"
          size="m"
          msg="The data pulled back into your Life Ledger account varies from bank to bank, but typically includes the following:"
        />
        <List listItems={bankInformation} />
        <Typography
          className={classNames(styles.letterSpacing)}
          tag="div"
          size="m"
          msg="You will need to give Nordigen permission to access your bank account and to share the data with your Life Ledger account, as well as accept Nordigen’s terms and conditions. Under data protection law they are independently responsible for telling you about their data collection and use, and for looking after your data in line with the law. We recommend you read their privacy notice which will be linked to from the permission screen. The permission screen will say that we have access to your bank account for 90 days. We are unable to change this and do not routinely access your bank account. The only way to access your bank account and pull through the data is if you start the open banking process from your Life Ledger dashboard. "
        />
      </div>
    </>
  );
};

export default DiscoveryInfo;
