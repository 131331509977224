import typescriptFsa from 'typescript-fsa';

import { ApplicationConfigFragment } from '@Graphql/graphqlTypes.generated';
import { ShowModalPayload } from '@Store/app/app.types';

const actionCreator = typescriptFsa('@APP');

export const getAppConfig = actionCreator.async<{}, ApplicationConfigFragment>('GET_APP_CONFIG');
export const showModal = actionCreator<ShowModalPayload<any>>('SHOW_MODAL');
export const hideModal = actionCreator<{}>('HIDE_MODAL');
