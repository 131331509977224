import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import LifeCaseInfo from '@Components/application/LifeCaseInfo';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { colorArray, ReactResponsiveQueries } from '@Config/constants';
import { LifeCaseStatus } from '@Graphql/graphqlTypes.generated';
import { getUser } from '@Store/auth/auth.selector';

import styles from './LifeCasesColumn.scss';

export interface LifeCasesColumnProps {
  CardComponent: typeof LifeCaseInfo;
  statuses: LifeCaseStatus[];
  title: string;
  lcCases: any[];
  subTitle?: string;
  showAssignedTo?: boolean;
}

const LifeCasesColumn: React.FunctionComponent<LifeCasesColumnProps> = ({
  CardComponent,
  title,
  lcCases,
  subTitle,
  showAssignedTo,
}) => {
  let i = -1;
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  return (
    <>
      <Typography msg={`${title} (${lcCases.length})`} tag="h5" size="l" />

      {subTitle && (
        <Row size={12} className={styles.bottomSpacing}>
          <Col size={isTablet ? 11 : 5}>
            <Typography msg={subTitle} tag="div" size="m" />
          </Col>
          {user?.isPortalAdmin && showAssignedTo && (
            <>
              {!isTablet && <Col size={4} />}

              <Col size={isTablet ? 3 : 2} textCenter alignCenter>
                <Typography msg="Assigned to" tag="div" size="l" />
              </Col>
            </>
          )}
        </Row>
      )}

      {lcCases.map((item: any, idx: number) => {
        i += 1;
        const j = i;
        i = i === 4 ? -1 : i;
        return <CardComponent initialBackground={colorArray[j]} key={idx} data={item.node} />;
      })}
    </>
  );
};

export default LifeCasesColumn;
