import * as React from 'react';
import { Redirect } from 'react-router';

import LoadingSpinner from '@Components/LoadingSpinner';
import { Links, LocalStorage } from '@Config/constants';
import {
  useCaseAccountDetailsQuery,
  useCaseServiceProviderQuery,
  CaseServiceProviderNode,
  DeathCaseFragment,
  LifeCaseFragment,
  RestrictedDeathCaseNode,
} from '@Graphql/graphqlTypes.generated';
import { deleteFromLocalStorage } from '@Utils/localStorage';

import DeathCaseNotification from './DeathCaseNotification';

export interface DeathCaseNotificationPageProps {
  id: string;
  isLifeCase?: boolean;
  caseData?: DeathCaseFragment | LifeCaseFragment | RestrictedDeathCaseNode;
  isDocUploaded?: boolean;
  backgroundColor?: string;
  closeModal: () => void;
  goToTab?: () => void;
}

const DeathCaseNotificationPage: React.FunctionComponent<DeathCaseNotificationPageProps> = ({
  id,
  caseData,
  isLifeCase = false,
  isDocUploaded,
  backgroundColor,
  closeModal,
  goToTab,
}) => {
  const caseAccountDetails = useCaseAccountDetailsQuery({
    variables: {
      caseServiceProvider: id,
    },
    fetchPolicy: 'network-only',
  });

  const caseServiceProvider = useCaseServiceProviderQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  if (caseAccountDetails.loading || caseServiceProvider.loading) {
    return <LoadingSpinner />;
  }

  if (!caseAccountDetails.data || !caseServiceProvider.data) {
    return <Redirect to={Links.home} />;
  }

  const firstName = caseData?.firstName;
  const lastName = caseData?.lastName;

  deleteFromLocalStorage(LocalStorage.isNotificationSummary);

  return (
    <DeathCaseNotification
      caseAccountDetails={caseAccountDetails.data.caseAccountDetails}
      fullName={`${firstName} ${lastName}`}
      deathCaseServiceProvider={caseServiceProvider.data?.caseServiceProvider as CaseServiceProviderNode}
      caseData={caseData as DeathCaseFragment}
      isLifeCase={isLifeCase}
      isDocUploaded={isDocUploaded}
      nameBackgroundColor={backgroundColor}
      closeModal={closeModal}
      goToTab={goToTab}
    />
  );
};

export default DeathCaseNotificationPage;
