import * as React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import DataWithLabel from '@Components/DataWithLabel';
import FileIconWithLabel from '@Components/FileIconWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { LifeCaseNode, Permissions } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { LoadableItem } from '@Utils/types';

import styles from './AdditionalInfoSummary.scss';

export interface AdditionalInfoSummaryProps {
  data: LoadableItem<LifeCaseNode>;
}

const AdditionalInfoSummary: React.FunctionComponent<AdditionalInfoSummaryProps> = ({ data }) => {
  const history = useHistory();
  const t = useTranslations();
  const user = useSelector(getUser);

  const editForm = () => {
    history.push(
      generatePath(Links.lcEdit, {
        id: data.item?.id,
        subpage: 'docs',
      })
    );
  };

  const isReadOnlyUser = data.item?.myPermission === Permissions.ReadOnly;

  const funeralPlanDocs = data.item && data.item?.funeralPlans.length > 0 ? data.item?.funeralPlans[0] : null;
  const insurancePolicyDocs =
    data.item && data.item?.insurancePolicies.length > 0 ? data.item?.insurancePolicies[0] : null;
  const otherDocs = data.item && data.item?.otherDocuments.length > 0 ? data.item?.otherDocuments[0] : null;

  return (
    <>
      <ConfirmationHeader showEdit={!isReadOnlyUser} title="Additional information" onClick={editForm} />
      <Paper width="width-full" className={styles.deathCaseContainer}>
        {data.item && (
          <>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={data.item.funeralPlans.length > 0 ? t(Messages.labelYes) : t(Messages.labelNo)}
                  label="Funeral plan"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>

            {funeralPlanDocs && (
              <Row constant columnMobile>
                <Col size={5}>
                  <FileIconWithLabel
                    file={funeralPlanDocs}
                    fileType=""
                    label=""
                    blockDocumentAccess={
                      isReadOnlyUser ||
                      (!funeralPlanDocs?.collaboratorsView &&
                        funeralPlanDocs.collaborator &&
                        funeralPlanDocs.collaborator.email !== user?.email)
                    }
                  />
                </Col>
              </Row>
            )}

            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={data.item.insurancePolicies.length > 0 ? t(Messages.labelYes) : t(Messages.labelNo)}
                  label="Insurance policy"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>

            {insurancePolicyDocs && (
              <Row constant columnMobile>
                <Col size={5}>
                  <FileIconWithLabel
                    file={insurancePolicyDocs}
                    fileType=""
                    label=""
                    blockDocumentAccess={
                      isReadOnlyUser ||
                      (!insurancePolicyDocs?.collaboratorsView &&
                        insurancePolicyDocs.collaborator &&
                        insurancePolicyDocs.collaborator.email !== user?.email)
                    }
                  />
                </Col>
              </Row>
            )}

            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={data.item.otherDocuments.length > 0 ? t(Messages.labelYes) : t(Messages.labelNo)}
                  label="Other documents uploaded"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>

            {otherDocs && (
              <Row constant columnMobile>
                <Col size={5}>
                  <FileIconWithLabel
                    file={otherDocs}
                    fileType=""
                    label=""
                    blockDocumentAccess={
                      isReadOnlyUser ||
                      (!otherDocs?.collaboratorsView &&
                        otherDocs.collaborator &&
                        otherDocs.collaborator.email !== user?.email)
                    }
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default AdditionalInfoSummary;
