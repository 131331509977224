import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import {
  createCollaboratorMutation,
  updateCollaboratorMutation,
  updatePermissionCollaboratorMutation,
} from '@Graphql/dc/dc.mutations';
import { CreateCollaboratorPayload, UpdateCollaboratorPayload } from '@Graphql/graphqlTypes.generated';
import { hideModal } from '@Store/app/app.actions';
import { EditCollaboratorPayload } from '@Store/dc/dc.types';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { addCollaborator, editCollaborator } from './collaborator.actions';
import { AddCollaboratorPayload } from './collaborator.types';

export function* addCollaboratorSaga(action: Action<AddCollaboratorPayload>) {
  const { isProfessionalPortal, setIsCollaboratorAdded, setSubmitting, ...restProps } = action.payload;
  try {
    const result: CreateCollaboratorPayload = yield call(
      createCollaboratorMutation,
      { ...restProps },
      isProfessionalPortal
    );
    yield setIsCollaboratorAdded && setIsCollaboratorAdded('true');
    yield put(addCollaborator.done({ result, params: action.payload }));
    yield put(notificationSuccess('Collaborator added'));
    yield put(hideModal({}));
  } catch (error: any) {
    yield setSubmitting && setSubmitting(false);
    yield setIsCollaboratorAdded && setIsCollaboratorAdded('false');
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* editCollaboratorSaga(action: Action<EditCollaboratorPayload>) {
  const { skipRefetchQuery, setSubmitting, ...restProps } = action.payload;
  try {
    const result: UpdateCollaboratorPayload = yield call(
      skipRefetchQuery ? updatePermissionCollaboratorMutation : updateCollaboratorMutation,
      restProps
    );
    yield put(editCollaborator.done({ result, params: action.payload }));
    yield put(hideModal({}));
    yield put(notificationSuccess('Collaborator details saved'));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
    yield setSubmitting && setSubmitting(false);
  }
}

export function* watchCollaboratorSaga() {
  yield all([
    yield takeLatest(addCollaborator.started, addCollaboratorSaga),
    yield takeLatest(editCollaborator.started, editCollaboratorSaga),
  ]);
}
