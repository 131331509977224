import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';

import CautionBox from '@Components/application/CautionBox/CautionBox';
import Button from '@Components/Button/Button';
import Col from '@Components/layout/Col/Col';
import Row from '@Components/layout/Row/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Typography from '@Components/Typography/Typography';
import { colorArray } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  AddNotificationCommentsMutation,
  AddNotificationCommentsMutationVariables,
  useNotificationCommentsQuery,
  UserNode,
  ResendNotificationsMutation,
  ResendNotificationsMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationAddNotificationComments, mutationResendNotifications } from '@Graphql/staff/mutations';
import { queryNotificationComments } from '@Graphql/staff/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { djangoDateFormat } from '@Utils/dates';
import 'react-quill/dist/quill.snow.css';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './LLComments.scss';

export interface LLCommentsProps {
  caseServiceProviderId: string;
  user?: UserNode | null;
  setLLCommentCallback: (comm: string) => void;
  llcommentParent: string;
  accessCode: string;
}

const LLComments: React.FunctionComponent<LLCommentsProps> = ({
  caseServiceProviderId,
  user,
  setLLCommentCallback,
  llcommentParent,
  accessCode,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [textValue, setTextValue] = React.useState(llcommentParent);
  const quillRef = React.useRef<ReactQuill>(null);

  const [addCommentMutation] = useMutation<AddNotificationCommentsMutation, AddNotificationCommentsMutationVariables>(
    mutationAddNotificationComments,
    {
      refetchQueries: [getOperationName(queryNotificationComments) || ''],
      onCompleted: () => {
        setTextValue('');
        setLLCommentCallback('');
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [resentNotificationsMutation, { loading }] = useMutation<
    ResendNotificationsMutation,
    ResendNotificationsMutationVariables
  >(mutationResendNotifications, {
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.messageSuccessResendNotification)));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const handleChange = (value: any) => {
    setTextValue(value);
    setLLCommentCallback(value);
  };

  const { data, loading: loadingQuery } = useNotificationCommentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      caseServiceProvider: caseServiceProviderId,
    },
  });

  const resendNotification = () => {
    resentNotificationsMutation({
      variables: {
        input: {
          accessCode,
        },
      },
    });
  };

  const submitComment = () => {
    const quillValue = quillRef.current && quillRef.current.getEditor().getContents();
    if (
      textValue.replace(/(<([^>]+)>)/gi, '').trim() !== '' &&
      quillValue?.ops &&
      quillValue.ops.length !== 0 &&
      quillValue.ops[0].insert !== '\n'
    ) {
      addCommentMutation({
        variables: {
          input: {
            caseServiceProviderId,
            comment: textValue,
          },
        },
      });
    } else {
      dispatch(notificationError('Please enter comments to save'));
    }
  };

  const commentedByUsers: string[] = [...new Set(data?.notificationComments.map((nc: any) => nc.commentedBy.id))];

  const getColor = (userId: string) => {
    const index = commentedByUsers.indexOf(userId);
    return index === 0 ? colorArray[index + 1] : colorArray[index];
  };

  return (
    <>
      <Row className={styles.mb40}>
        <Button
          label={t(Messages.messageResendNotification)}
          className={styles.buttonWidth}
          onClick={resendNotification}
          loading={loading}
        />
      </Row>

      <Row size={8}>
        <CautionBox containerClassName={styles.cautionBox} info={t('info_ll_comments' as Messages)} />
      </Row>

      <Row className={styles.mt40}>
        <div style={{ backgroundColor: colorArray[0] }} className={styles.personContainer}>
          {user && (
            <Typography
              msg={`${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`}
              tag="span"
              color="white"
              bold
              size="lcx"
            />
          )}
        </div>
        <ReactQuill
          ref={quillRef}
          value={textValue}
          onChange={handleChange}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ],
          }}
          formats={['bold', 'italic', 'underline', 'list', 'bullet']}
          placeholder="Write your comment here"
          className={styles.quillArea}
        />
      </Row>

      <Row className={styles.mt60}>
        <Button label="Save comment" isFullWidth className={styles.buttonWidth} onClick={submitComment} />
      </Row>

      {loadingQuery ? (
        <LoadingSpinner />
      ) : (
        <>
          {data?.notificationComments
            .sort((a: any, b: any) => {
              const aDate = new Date(a.commentedAt);
              const bDate = new Date(b.commentedAt);
              if (aDate > bDate) return 1;
              if (aDate < bDate) return -1;
              return 0;
            })
            .reverse()
            .map((nc: any, idx: number) => {
              return (
                <>
                  <Row key={idx} className={styles.commentsLayout}>
                    <div
                      style={{
                        backgroundColor: user?.id === nc.commentedBy.id ? colorArray[0] : getColor(nc.commentedBy.id),
                      }}
                      className={styles.personContainer}
                    >
                      <Typography
                        msg={`${nc.commentedBy.firstName.charAt(0).toUpperCase()}${nc.commentedBy.lastName
                          .charAt(0)
                          .toUpperCase()}`}
                        tag="span"
                        color="white"
                        bold
                        size="lcx"
                      />
                    </div>
                    <Col>
                      <Row className={styles.nameDate}>
                        <Typography
                          msg={`${nc.commentedBy.firstName} ${nc.commentedBy.lastName}`}
                          tag="div"
                          size="l"
                          bolder
                        />
                        <Typography msg={djangoDateFormat(nc.commentedAt)} tag="div" color="footerColor" size="m" />
                      </Row>
                      <div
                        className={styles.fs18}
                        //eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: nc.comment,
                        }}
                      />
                    </Col>
                  </Row>
                  {idx < data?.notificationComments.length - 1 && <div className={styles.separator} />}
                </>
              );
            })}
        </>
      )}
    </>
  );
};

export default LLComments;
