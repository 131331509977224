import classNames from 'classnames';
import * as React from 'react';

import styles from './NavigationLayout.scss';

export interface UserLayoutProps {
  className?: string;
  rightSideComponent?: React.ReactNode;
  fromNotification?: boolean;
}

const NavigationLayout: React.FunctionComponent<UserLayoutProps> = ({
  rightSideComponent,
  fromNotification = false,
}) => {
  return (
    <div className={classNames(styles.layoutContainer, { [styles.innerSpacing]: fromNotification })}>
      {rightSideComponent}
    </div>
  );
};

export default NavigationLayout;
