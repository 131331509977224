import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import Col from '@Components/layout/Col';
import LoadingSpinner from '@Components/LoadingSpinner';
import { LocalStorage } from '@Config/constants';
import { useGetMeQuery, UserNode } from '@Graphql/graphqlTypes.generated';
import { professionalAccessCode } from '@Store/provider/provider.actions';
import { deleteFromLocalStorage } from '@Utils/localStorage';

import NotificationsPage from '../ProviderDCInfoPage/NotificationsPage';

import styles from './NotificationDashboard.scss';

export interface NotificationDashboardProps {}

const NotificationDashboard: React.FunctionComponent<NotificationDashboardProps> = () => {
  deleteFromLocalStorage(LocalStorage.deathCaseId);

  const dispatch = useDispatch();
  const { accessCode } = useParams();
  const [dcInfo, setDcInfo] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const user = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  deleteFromLocalStorage(LocalStorage.spNotification);

  const isStaff = user.data?.me.info.isStaff;

  React.useEffect(() => {
    if (accessCode !== '') {
      setIsLoading(true);
      dispatch(professionalAccessCode.started({ setDcInfo, accessCode, isStaff, setIsLoading }));
    }
  }, [accessCode, dispatch, isStaff]);

  return (
    <main>
      <div>
        <Col className={styles.relative} size={12}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <NotificationsPage
              user={user.data?.me.info as UserNode}
              providerDash={false}
              dcInfo={dcInfo}
              isStaff={isStaff}
            />
          )}
        </Col>
      </div>
    </main>
  );
};

export default NotificationDashboard;
