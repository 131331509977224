export enum TransUnionSubpages {
  // VerificationType = 'verificationType',
  DecideComponent = 'decideComponent',
  Summary = 'summary',
  SupportingDocuments = 'supportingDocuments',
  VerficationStatus = 'verficationStatus',
}

export enum IdDocunentSubpages {
  SupportingDocuments = 'supportingDocuments',
  VerficationStatus = 'verficationStatus',
}
