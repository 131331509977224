import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@Components/Button';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  usePackagesQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import DiscoveryInfo from '@Routes/lifeCase/ReadyLCPage/DiscoveryInfo';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './DiscoveryDescModalBody.scss';

export interface DiscoveryDescBodyProps {
  baseCase: string;
}

const DiscoveryDescBody: React.FunctionComponent<ModalProviderProps<DiscoveryDescBodyProps>> = ({
  closeModal,
  modalData: { baseCase },
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const t = useTranslations();
  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      closeModal();
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (packagesData.loading) {
    return <LoadingSpinner />;
  }

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  const discoveryPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'DISCOVERY');
  return (
    <div className={styles.paddingBody}>
      <DiscoveryInfo />
      <Row constant className={styles.pb25}>
        {discoveryPackage?.unitAmount && (
          <Button
            onClick={() => {
              stripeCreateDcSubscriptionMutation({
                variables: { input: { priceId: discoveryPackage?.priceId, baseCase } },
              });
            }}
            label="Subscribe now to Life Ledger Discovery"
            className={styles.mt40}
            isFullWidth
          />
        )}
      </Row>
    </div>
  );
};

export default DiscoveryDescBody;
