import typescriptFsa from 'typescript-fsa';

import { DeathCaseNode, UploadDocumentMutationPayload } from '@Graphql/graphqlTypes.generated';
import { ActionErrorType } from '@Utils/types';

import {
  AddAdminPayload,
  AddExecutorPayload,
  AddLegalPayload,
  CreateDCPayload,
  DeleteDocumentPayload,
  FetchDCPayload,
  RemoveCollaboratorPayload,
  SendAllPayload,
  SendOnePayload,
  UpdateDCPayload,
  UploadDocumentsPayload,
  EditCollaboratorPayload,
} from './dc.types';

const actionCreator = typescriptFsa('@DEATH_CASE');

export const fetchDC = actionCreator.async<FetchDCPayload, DeathCaseNode, ActionErrorType>('FETCH_DC');
export const createDC = actionCreator.async<CreateDCPayload, {}>('CREATE_DC');
export const updateDC = actionCreator.async<UpdateDCPayload, {}>('UPDATE_DC');
export const uploadDocuments = actionCreator.async<UploadDocumentsPayload, UploadDocumentMutationPayload>(
  'UPLOAD_DOCUMENTS'
);
export const removeDocuments = actionCreator.async<DeleteDocumentPayload, {}>('REMOVE_DOCUMENTS');
export const clearDC = actionCreator<void>('CLEAR_DC');

export const addExecutor = actionCreator.async<AddExecutorPayload, {}>('ADD_EXEC');
export const removeExecutor = actionCreator.async<RemoveCollaboratorPayload, {}>('REMOVE_EXEC');

export const sendOne = actionCreator.async<SendOnePayload, {}>('SEND_ONE');
export const sendAll = actionCreator.async<SendAllPayload, {}>('SEND_ALL');

export const addLegal = actionCreator.async<AddLegalPayload, {}>('ADD_LEGAL');
export const addAdministrator = actionCreator.async<AddAdminPayload, {}>('ADD_ADMIN');
export const editCollaborator = actionCreator.async<EditCollaboratorPayload, {}>('CHANGE_COLLABORATOR');
