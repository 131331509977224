import { error, success } from 'react-notification-system-redux';

import { notificationsConfig } from '@Config/constants';

export const notificationError = (message: string) =>
  error({
    message,
    ...notificationsConfig.defaultConf,
  });

export const notificationSuccess = (message: string) =>
  success({
    message,
    ...notificationsConfig.defaultConf,
  });
