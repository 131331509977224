import classNames from 'classnames';
import * as React from 'react';
import ReactSelect from 'react-select';

import Icon, { IconSizes } from '@Components/Icon';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import { OptionItem } from './Select';
import styles from './Select.scss';

export type SelectOnlySizes = 'xs' | 'ss' | 's' | 'm' | 'l';

export interface SelectOnlyProps {
  placeholder?: Messages;
  options: OptionItem[];
  input?: any;
  isMulti?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  size?: SelectOnlySizes;
  onChange?: (value: OptionItem) => void;
  withError?: boolean;
  disabled?: boolean;
  selectClass?: string;
  value?: string;
  customStyles?: any;
  menuIsOpen?: boolean;
}

export enum ClassNamesForOverwrite {
  SelectContainer = 'Select__container',
  SelectWithErrors = 'Select--with-error',
  SelectContainment = 'Select__containment',
  SelectMenuPosition = 'Select__menuposition',
  SelectCollaborator = 'Select__collaborator',
  SelectReferralField = 'Select__referralField',
  SelectAddress = 'Select__address',
  SelectPermissions = 'Select__permissions',
  SelectServiceProvider = 'Select__serviceProvider',
}

const SelectOnly: React.FunctionComponent<SelectOnlyProps> = (props) => {
  const {
    options = [],
    placeholder,
    input,
    isClearable,
    isMulti,
    isSearchable = true,
    isLoading = false,
    size = 'm',
    withError,
    disabled,
    onChange,
    selectClass = ClassNamesForOverwrite.SelectContainer,
    value,
    customStyles,
    menuIsOpen,
  } = props;

  const SelectOnlyIndicator: React.FunctionComponent<{}> = () => {
    return <Icon size={IconSizes.sss} icon="chevronDown" className={styles.dropDownPosition} />;
  };

  const t = useTranslations();

  return (
    <ReactSelect
      {...input}
      isSearchable={isSearchable}
      placeholder={t(placeholder || Messages.defaultSelectPlaceholder)}
      onChange={onChange}
      formatOptionLabel={(opt: { value: string; label: string; labelDisplay?: JSX.Element }) => {
        return opt.labelDisplay ? opt.labelDisplay : opt.label;
      }}
      className={classNames(selectClass, `size-${size}`, withError && ClassNamesForOverwrite.SelectWithErrors)}
      classNamePrefix="Select"
      options={options}
      isMulti={isMulti}
      isLoading={isLoading}
      isClearable={isClearable}
      components={{ SelectOnlyIndicator }}
      isDisabled={disabled}
      value={value}
      styles={customStyles}
      menuIsOpen={menuIsOpen}
      menuPosition="fixed"
    />
  );
};

export default SelectOnly;
