export { default as mutationForgotPassword } from '@Graphql/auth/mutations/forgotPassword.graphql';
export { default as mutationLogin } from '@Graphql/auth/mutations/login.graphql';
export { default as mutationRegisterUser } from '@Graphql/auth/mutations/registerUser.graphql';
export { default as mutationVerifyEmail } from '@Graphql/auth/mutations/verifyEmail.graphql';
export { default as mutationResendEmail } from '@Graphql/auth/mutations/resendEmail.graphql';
export { default as mutationResendVerification } from '@Graphql/auth/mutations/resendEmail.graphql';
export { default as mutationResetPassword } from '@Graphql/auth/mutations/resetPassword.graphql';
export { default as mutationChangePassword } from '@Graphql/auth/mutations/changePassword.graphql';
export { default as mutationGenerateOtp } from '@Graphql/auth/mutations/generateOtp.graphql';
export { default as mutationRefreshToken } from '@Graphql/auth/mutations/refreshToken.graphql';
export { default as mutationSocialLogin } from '@Graphql/auth/mutations/socialLogin.graphql';
