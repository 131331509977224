import { connect } from 'react-redux';

import { getUser } from '@Store/auth/auth.selector';
import { getLCData } from '@Store/lc/lc.selector';
import { RootState } from '@Store/reducers';

import AdditionalContactsForm, {
  AdditionalContactsFormProps,
  AdditionalContactsFormStateProps,
} from './AdditionalContactsForm';

const mapStateToProps = (state: RootState) => ({
  data: getLCData(state),
  user: getUser(state),
});

export default connect<AdditionalContactsFormStateProps, {}, AdditionalContactsFormProps, RootState>(mapStateToProps)(
  AdditionalContactsForm
);
