import * as React from 'react';
import { useParams } from 'react-router';

import LifeEventPage from '../LifeEventPage';

export interface EditLifeProps {}

const EditLifeEvent: React.FunctionComponent<EditLifeProps> = () => {
  const { subpage } = useParams();

  return <LifeEventPage preferred={subpage} />;
};

export default EditLifeEvent;
