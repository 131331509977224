import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import EmptyFileView from '@Components/application/EmptyFileView';
import FileView from '@Components/application/FileView';
import Paper from '@Components/application/GrayPaper';
import Popover, { PopoverPosition } from '@Components/application/Popover';
import UploadDocument from '@Components/application/UploadDocument';
import Button, { ButtonStyles } from '@Components/Button';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { fileUploadSettings, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderFragment,
  DeathCaseFragment,
  DeleteDocumentInNotificationMutation,
  DeleteDocumentInNotificationMutationVariables,
  DocumentDocumentType,
  LifeCaseFragment,
  NotificationChatUserType,
  NotificationDocumentNode,
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
  UploadDocumentInNotificationMutation,
  UploadDocumentInNotificationMutationVariables,
  useCaseDocumentsQuery,
} from '@Graphql/graphqlTypes.generated';
import {
  mutationDeleteDocumentInNotification,
  mutationUploadDocumentInNotification,
} from '@Graphql/providers/mutations';
import { queryCaseDocuments } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { getReadyOnlyPermissions } from '@Utils/helpers';
import { deathCaseNotificationHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './ChatUploadModalBody.scss';

export interface ChatUploadModalBodyProps {
  deathCaseServiceProvider: CaseServiceProviderFragment | RestrictedCaseServiceProviderNode;
  accessCode: string;
  isFromApp?: boolean;
  isCustomer?: boolean;
  caseData: DeathCaseFragment | LifeCaseFragment | RestrictedDeathCaseNode | undefined;
}

const ChatUploadModalBody: React.FunctionComponent<ChatUploadModalBodyProps> = ({
  deathCaseServiceProvider,
  accessCode,
  isFromApp = false,
  isCustomer = false,
  caseData,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isIpadAir = useMediaQuery({ query: ReactResponsiveQueries.Res986 });
  const [showModalOverlay, setShowModalOverlay] = React.useState(false);

  const caseDocuments = useCaseDocumentsQuery({
    variables: {
      caseServiceProvider: deathCaseServiceProvider.id,
      accessCode,
    },
    fetchPolicy: 'network-only',
  });

  const [updateDocumentInChat] = useMutation<
    UploadDocumentInNotificationMutation,
    UploadDocumentInNotificationMutationVariables
  >(mutationUploadDocumentInNotification, {
    refetchQueries: [getOperationName(queryCaseDocuments) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.labelChatDocumentUploadSuccess)));
      if (isFromApp) {
        deathCaseNotificationHandler(dispatch, deathCaseServiceProvider.id, false, caseData, true);
      }
    },
    onError: (apiError) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
    },
  });

  const [deleteDocumentInChat] = useMutation<
    DeleteDocumentInNotificationMutation,
    DeleteDocumentInNotificationMutationVariables
  >(mutationDeleteDocumentInNotification, {
    refetchQueries: [getOperationName(queryCaseDocuments) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess('Document deleted'));
    },
    onError: (apiError) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
    },
  });

  if (caseDocuments.loading) {
    return <LoadingSpinner />;
  }

  // const caseDocumentsUploaded = [
  //   ...(caseData?.wills as DocumentNode[]),
  //   ...(caseData?.representationLetters as DocumentNode[]),
  //   ...(caseData?.powerOfAttorneys as DocumentNode[]),
  //   ...(caseData?.instructionLetters as DocumentNode[]),
  //   ...(caseData?.letterToCompanys as DocumentNode[]),
  // ];

  const caseDocumentsUploaded = [
    ...(caseData?.wills ? caseData.wills : []),
    ...(caseData?.representationLetters ? caseData.representationLetters : []),
    ...(caseData?.powerOfAttorneys ? caseData.powerOfAttorneys : []),
    ...(caseData?.instructionLetters ? caseData.instructionLetters : []),
    ...(caseData?.letterToCompanys ? caseData.letterToCompanys : []),
  ];

  const uploadedFiles = caseDocuments.data?.caseDocuments as NotificationDocumentNode[];

  const notifierDocuments = uploadedFiles.filter(
    (doc: NotificationDocumentNode) => !doc.isAddedBySp && doc.uploadedBy === NotificationChatUserType.Individual
  );

  const spDocuments = uploadedFiles.filter((doc: NotificationDocumentNode) => doc.isAddedBySp);

  const lifeLedgerDocuments = uploadedFiles.filter(
    (doc: NotificationDocumentNode) => !doc.isAddedBySp && doc.uploadedBy === NotificationChatUserType.Lifeledger
  );

  const deleteConfirmationHandler = (fileId: string) => {
    return dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () =>
              deleteDocumentInChat({
                variables: {
                  input: {
                    id: fileId,
                    accessCode,
                  },
                },
              }),
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
          },
          title: t(Messages.modalRemoveDocumentTitle),
          subtitle: t(Messages.modalRemoveDocumentSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const alterPosition = !!(isTablet && isFromApp);
  const { maxUploadSize, supportedUploadFormats } = fileUploadSettings;

  const documentTypeHtml = (
    <UploadDocument
      csp={deathCaseServiceProvider as CaseServiceProviderFragment}
      deathCase={caseData as DeathCaseFragment}
      uploadedFiles={uploadedFiles}
      closePopOver={() => setShowModalOverlay(false)}
    />
  );

  const isReadOnlyUser = getReadyOnlyPermissions(caseData);

  const getFileId = (fileDetails: any) => {
    if (isReadOnlyUser) {
      return '';
    }
    return isCustomer ? fileDetails.id : '';
  };

  return (
    <>
      {!isReadOnlyUser && (
        <Row columnTablet={alterPosition} size={10}>
          <Col size={12} tabindex={0}>
            <div
              onClick={() => {
                if (isFromApp) {
                  setShowModalOverlay(true);
                } else {
                  // fileUploadHandler();
                }
              }}
              className={styles.topSpacing}
            >
              <Dropzone
                maxSize={maxUploadSize}
                onDrop={(files: File[]) =>
                  updateDocumentInChat({
                    variables: {
                      input: {
                        deathCaseProviderId: deathCaseServiceProvider.id,
                        documentType: DocumentDocumentType.SpUploaded,
                        files,
                      },
                    },
                  })
                }
                multiple={true}
                accept={supportedUploadFormats}
                onDropRejected={(fileRejected: FileRejection[]) => {
                  if (fileRejected[0].errors[0].code === 'file-too-large') {
                    dispatch(notificationError(t(Messages.msgFileSizeLarge)));
                  } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
                    dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={styles.wrapperBlock} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Row justifyCenter constant>
                      <Popover
                        showContent={showModalOverlay}
                        contentElement={documentTypeHtml}
                        position={isIpadAir ? PopoverPosition.bottom : PopoverPosition.right}
                        callBack={() => setShowModalOverlay(false)}
                      >
                        <Button
                          style={ButtonStyles.transparent}
                          onClick={() => {
                            if (isFromApp) {
                              setShowModalOverlay(true);
                            } else {
                              //  fileUploadHandler();
                            }
                          }}
                          icon="upload"
                          iconSize={IconSizes.ss}
                          constant
                        >
                          <Typography msg="Upload document" tag="span" />
                        </Button>
                      </Popover>
                    </Row>
                    <Typography
                      size="m"
                      color="footerColor"
                      tag="div"
                      msg={t(Messages.labelAcceptedFileFormats)}
                      className={styles.topSpacing}
                    />
                  </section>
                )}
              </Dropzone>
            </div>
          </Col>
        </Row>
      )}
      <Row className={styles.margintop32}>
        <Typography msg="Uploaded by notifier" tag="h4" size="xl" />
      </Row>
      {notifierDocuments.length === 0 && caseDocumentsUploaded.length === 0 ? (
        <Col size={10}>
          <Paper width="width-full">
            <EmptyFileView />
          </Paper>
        </Col>
      ) : (
        <>
          {notifierDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
            <Col size={isFromApp && !isReadOnlyUser ? 11 : 10} key={idx} className={styles.mb8}>
              <FileView
                key={idx}
                fileDetails={fileDetails}
                isGray
                onRemoveClick={() => deleteConfirmationHandler(fileDetails.id)}
                fileId={getFileId(fileDetails)}
                deleteIconOutSide={!isMobile}
              />
            </Col>
          ))}
          {caseDocumentsUploaded.map((fileDetails: any, idx: number) => (
            <Col size={isFromApp && !isReadOnlyUser ? 11 : 10} key={idx} className={styles.mb8}>
              <FileView
                key={idx}
                fileDetails={fileDetails}
                isGray
                onRemoveClick={() => deleteConfirmationHandler(fileDetails.id)}
                fileId={getFileId(fileDetails)}
                deleteIconOutSide={!isMobile}
              />
            </Col>
          ))}
        </>
      )}

      <Row className={styles.margintop32}>
        <Typography msg={`Uploaded by ${deathCaseServiceProvider.serviceProvider.name}`} tag="h4" size="xl" />
      </Row>
      {spDocuments.length === 0 ? (
        <Col size={10}>
          <Paper width="width-full">
            <EmptyFileView />
          </Paper>
        </Col>
      ) : (
        spDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
          <Col key={idx} size={isFromApp ? 12 : 10} className={styles.mb8}>
            <FileView
              key={idx}
              fileDetails={fileDetails}
              isGray
              onRemoveClick={() => deleteConfirmationHandler(fileDetails.id)}
              fileId={isCustomer ? '' : fileDetails.id}
            />
          </Col>
        ))
      )}

      <Row className={styles.margintop32}>
        <Typography msg="Uploaded by Life Ledger" tag="h4" size="xl" />
      </Row>
      {lifeLedgerDocuments.length === 0 ? (
        <Col size={10}>
          <Paper width="width-full">
            <EmptyFileView />
          </Paper>
        </Col>
      ) : (
        lifeLedgerDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
          <Col size={11} key={idx} className={styles.mb8}>
            <FileView
              key={idx}
              fileDetails={fileDetails}
              isGray
              onRemoveClick={() => deleteConfirmationHandler(fileDetails.id)}
              fileId={isCustomer ? fileDetails.id : ''}
              deleteIconOutSide={!isMobile}
            />
          </Col>
        ))
      )}
    </>
  );
};

export default ChatUploadModalBody;
