import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { CaseTags, EnterKeyCode, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  LifeCaseDeleteMutation,
  LifeCaseDeleteMutationVariables,
  MissingInfoNode,
  useDeathCaseQuery,
  useLifeCaseQuery,
  UserReferralSource,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteLC } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { LifeCaseSubpages } from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';
import { getUser } from '@Store/auth/auth.selector';

import styles from './VerticalStepper.scss';

interface VerticalStepperProps {
  activeStepIdx: number;
  steps: string[];
  id?: string;
  isLifeCase?: boolean;
  missing?: boolean;
  isLifeEvent?: boolean;
  isDeathCase?: boolean;
}

interface StepActivity {
  stepName: string;
  visited: boolean;
  stepNumber: number;
}

const VerticalStepper: React.FunctionComponent<VerticalStepperProps> = ({
  activeStepIdx,
  steps,
  id,
  isLifeCase,
  missing,
  isLifeEvent,
  isDeathCase,
}) => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;
  const pathName = history.location.pathname;
  const user = useSelector(getUser);
  const isHospice = user?.referralSource === UserReferralSource.LifeHospice;
  const isCreateCase = pathName.includes(CaseTags.deathCaseCreated);
  const isCreateForm =
    pathName.includes(CaseTags.deathCaseCreated) ||
    pathName.includes(CaseTags.lifeCaseCreated) ||
    (isLifeCase && activeStepIdx === 0);
  const isLifeCaseCreate = pathName.includes(CaseTags.lifeCaseCreated);
  const link = isLifeCase ? Links.lcEdit : Links.dcEdit;

  const [stepsVisibility, setStepVisibility] = React.useState<StepActivity[]>([]);

  const [deleteLifeCase] = useMutation<LifeCaseDeleteMutation, LifeCaseDeleteMutationVariables>(mutationDeleteLC, {});

  React.useEffect(() => {
    const formSteps: StepActivity[] = steps.map((s: string, index: number) => {
      const pageVisited = stepsVisibility.find((sv: StepActivity) => sv.stepNumber === index);
      return {
        stepName: s,
        visited: pageVisited?.visited ? pageVisited.visited : index === activeStepIdx,
        stepNumber: index,
      };
    });
    setStepVisibility(formSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, setStepVisibility, activeStepIdx]);

  const deathCase = useDeathCaseQuery({
    variables: {
      id: id || '',
    },
    skip: isLifeCase || isCreateCase,
    fetchPolicy: 'network-only',
  });

  const lifeCase = useLifeCaseQuery({
    variables: {
      id: id || '',
    },
    skip: !isLifeCase,
    fetchPolicy: 'network-only',
  });

  const getMissingItems = () => {
    if (isLifeCase) {
      return lifeCase.data?.lifeCase
        ? lifeCase.data?.lifeCase.missingInfo.filter((x: any) => x.value === false && x.field !== 'user_verification')
        : undefined;
    }
    return deathCase.data?.deathCase
      ? deathCase.data?.deathCase.missingInfo.filter((x: any) => x.value === false && x.field !== 'user_verification')
      : undefined;
  };

  const allMissingItem = getMissingItems();

  const showBackToLifeLedger = (): boolean | undefined => {
    const confirmationFormIndex = Object.values(LifeCaseSubpages).indexOf(LifeCaseSubpages.Confirmation);
    return isLifeCase && isHospice && activeStepIdx === confirmationFormIndex && isCreateForm;
  };

  return (
    <div className={styles.stepperNew}>
      {stepsVisibility.map((sidebarItem: StepActivity, index: number) => {
        const itemStyle = {
          [styles.before]: index !== activeStepIdx && sidebarItem.visited,
          [styles.active]: index === activeStepIdx,
          [styles.after]: index !== activeStepIdx && !sidebarItem.visited,
        };

        const clickHandler = (indexLess: boolean) => {
          if (indexLess || !isCreateForm) {
            return history.push(
              generatePath(`${link}${queryString}`, {
                id,
                subpage: sidebarItem.stepName,
                missing,
                // tag: `tag=${CaseTags.deathCaseEdit}`,
              })
            );
          }
          return null;
        };

        const missingInfoCheck = () => {
          if (sidebarItem.stepName === DeathCaseSubpages.General || sidebarItem.stepName === LifeCaseSubpages.General) {
            const pageMissingItems = allMissingItem?.filter(
              (m: MissingInfoNode) =>
                m.field.includes('name') ||
                m.field.includes('date') ||
                m.field.includes('address') ||
                m.field.includes('city') ||
                m.field.includes('post')
            );
            return pageMissingItems ? pageMissingItems.length > 0 : false;
          }
          if (sidebarItem.stepName === DeathCaseSubpages.Certificate) {
            const pageMissingItems = allMissingItem?.filter((m: MissingInfoNode) =>
              m.field.includes('has_death_certificate_files')
            );
            return pageMissingItems ? pageMissingItems.length > 0 : false;
          }
          return false;
        };

        const isInfoMissing = () => {
          if (isLifeCase) {
            if (isLifeCaseCreate) {
              return false;
            }
            return missingInfoCheck();
          }
          return missingInfoCheck();
        };

        const indexLess = index !== activeStepIdx && sidebarItem.visited;
        const currentSelected = index === activeStepIdx;

        const showPointer = () => {
          if (id === undefined) {
            return false;
          }
          if (isCreateCase || isLifeCaseCreate) {
            return indexLess;
          }
          return true;
        };

        return (
          <div key={index} className={styles.lineSpacing}>
            <Row className={classNames(styles.step, itemStyle)} key={sidebarItem.stepName}>
              <Col size={12} constant>
                <div
                  //eslint-disable-next-line
                  tabIndex={0}
                  className={classNames({ [styles.clickable]: showPointer() })}
                  onClick={() => clickHandler(indexLess)}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      clickHandler(indexLess);
                    }
                  }}
                >
                  {indexLess ? <Icon size={IconSizes.ss} className={styles.tickIcon} icon="checkmark" /> : null}
                  {currentSelected ? <Icon className={styles.arrowsIcon} icon="arrowhead-right" /> : null}
                  <Typography
                    className={classNames(styles.title, itemStyle)}
                    size="l"
                    msg={t(
                      `${isLifeCase ? Messages.titleCreateLCSubItemPrefix : Messages.titleCreateDCSubItemPrefix}${
                        sidebarItem.stepName
                      }` as Messages
                    )}
                    tag="div"
                  />
                </div>
              </Col>

              {/* } */}
            </Row>

            {isInfoMissing() && (
              <Typography
                className={styles.infoMissingText}
                bold
                msg="Information missing"
                tag="div"
                size="xs"
                color="red"
              />
            )}
          </div>
        );
      })}
      {(!isCreateForm || showBackToLifeLedger()) && !isDeathCase && (
        <Row
          //eslint-disable-next-line
          tabIndex={0}
          onClick={() => {
            if (showBackToLifeLedger()) {
              deleteLifeCase({ variables: { input: { id: id || '' } } });
            }
            window.onpopstate = null;
            history.push(generatePath(`${Links.home}${queryString}`));
          }}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              history.push(generatePath(`${Links.home}${queryString}`));
            }
          }}
          className={styles.exitButtonPosition}
        >
          <Icon className={styles.exitIcon} icon="chevronLeft" />
          {!isLifeEvent && <Typography className={styles.exitButton} msg={t(Messages.backToDashboardLink)} tag="div" />}
          {isLifeEvent && (
            <Typography className={styles.exitButton} msg={t('link_save_and_exit' as Messages)} tag="div" />
          )}
        </Row>
      )}
    </div>
  );
};

export default VerticalStepper;
