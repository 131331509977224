import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  usePackagesQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler, paymentHandler, registerLifeCaseModal } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './BasicServicePayment.scss';

export interface BasicServicePaymentProps {
  title: string;
  bodyMsg: string;
  firstButtonMsg: string;
  secondButtonMsg: string;
  onClose?: () => void;
  caseId: string;
  isHospice: boolean;
  isFromServices?: boolean;
}

const BasicServicePayment: React.FunctionComponent<ModalProviderProps<BasicServicePaymentProps>> = ({
  closeModal,
  modalData: { title, bodyMsg, firstButtonMsg, caseId, isHospice, isFromServices },
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const t = useTranslations();
  const user = useSelector(getUser);
  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      closeModal();
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (packagesData.loading) {
    return <LoadingSpinner />;
  }

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  const basicPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'BASIC_LIFE_CASE');

  return (
    <div className={styles.container}>
      {isHospice && <Icon icon="tick" size={IconSizes.m} />}
      <Typography msg={title} tag="h4" bold size="xl" className={styles.marginBottom15} />

      <Typography msg={bodyMsg} tag="div" size="l" />

      <Row constant justifyCenter>
        <Typography bold msg="£12" tag="div" size="xxxl" className={styles.currency} />
        <Typography msg="/year" tag="div" size="l" className={styles.tenure} />
      </Row>

      <Row constant alignCenter justifyCenter className={isHospice ? styles.marginBotto28 : styles.marginBotto25}>
        <Icon icon="tick" size={IconSizes.ss} />
        <Typography msg="Add unlimited service providers" tag="div" size="m" />
      </Row>

      {!isHospice && (
        <Typography
          className={styles.bottomSpacing}
          msg="Cancel at any time, effective at the end of the payment period"
          color="footerColor"
          tag="div"
          size="m"
        />
      )}

      <Row justifyCenter size={12}>
        <Col size={6}>
          {basicPackage?.unitAmount && (
            <Button
              onClick={() => {
                if (isHospice) {
                  if (user?.enableDiscovery) {
                    closeModal();
                    paymentHandler(dispatch, caseId);
                  } else {
                    stripeCreateDcSubscriptionMutation({
                      variables: { input: { priceId: basicPackage?.priceId || '', baseCase: caseId } },
                    });
                  }
                } else if (isFromServices) {
                  stripeCreateDcSubscriptionMutation({
                    variables: { input: { priceId: basicPackage?.priceId || '', baseCase: caseId } },
                  });
                } else {
                  registerLifeCaseModal(dispatch, basicPackage);
                }
              }}
              label={firstButtonMsg}
              isFullWidth
            />
          )}
        </Col>
      </Row>
      {isHospice && (
        <Typography
          className={styles.padding}
          msg={t('footer_hospice_life_case_created' as Messages)}
          color="footerColor"
          tag="div"
          size="m"
        />
      )}
      {!isHospice && (
        <LinkButton textCenter className={styles.mt18} linkTitle="No thanks" onClick={() => closeModal()} />
      )}
    </div>
  );
};

export default BasicServicePayment;
