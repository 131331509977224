import * as React from 'react';

import LoadingSpinner from '@Components/LoadingSpinner';
import { CaseServiceProviderNode, useCaseServiceProviderQuery } from '@Graphql/graphqlTypes.generated';
import IdDocumentSteps from '@Routes/settings/IdValidationPage/IdDocumentSteps';
import TransUnionSteps from '@Routes/settings/IdValidationPage/TransUnionSteps';

import { ModalProviderProps } from '../Modal';

export interface IdVerificationFileUploadModalBodyProps {
  deathCaseId?: string;
  deathCaseServiceProviderId?: string;
  submitAction?: string;
}

const IdVerfificationFileUploadModalBody: React.FunctionComponent<ModalProviderProps<
  IdVerificationFileUploadModalBodyProps
>> = ({ modalData: { deathCaseServiceProviderId, submitAction } }) => {
  const deathCaseServiceProvider = useCaseServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: !deathCaseServiceProviderId,
    variables: { id: deathCaseServiceProviderId || '' },
  });

  if (deathCaseServiceProvider.loading) {
    return <LoadingSpinner />;
  }

  return deathCaseServiceProvider.data?.caseServiceProvider.serviceProvider.isUserValidationRequired ? (
    <TransUnionSteps
      deathCaseServiceProviderId={deathCaseServiceProviderId}
      fromNotification={true}
      submitAction={submitAction}
      deathCaseServiceProvider={deathCaseServiceProvider.data.caseServiceProvider as CaseServiceProviderNode}
    />
  ) : (
    <IdDocumentSteps
      deathCaseServiceProviderId={deathCaseServiceProviderId}
      fromNotification={true}
      submitAction={submitAction}
      deathCaseServiceProvider={deathCaseServiceProvider.data?.caseServiceProvider as CaseServiceProviderNode}
    />
  );
};

export default IdVerfificationFileUploadModalBody;
