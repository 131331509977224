import classNames from 'classnames';
import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';

import styles from './TitleWithSubtitle.scss';

export interface TitleWithSubtitleProps {
  title: string;
  subtitle: string;
  userSection?: boolean;
  withBiggerSpace?: boolean;
  titleIcon?: string;
  logo?: string;
}

const TitleWithSubtitle: React.FunctionComponent<TitleWithSubtitleProps> = (props) => {
  const { title, subtitle, userSection = false, withBiggerSpace, titleIcon, logo } = props;

  return (
    <div className={styles.container}>
      <Row constant alignCenter>
        {logo && (
          <div className={styles.logoWrapper}>
            <img src={logo} alt={logo} />
          </div>
        )}
        <Typography
          bold
          tag="h3"
          size="xxl"
          msg={title}
          className={classNames({ [styles.userSectionTitle]: userSection })}
        />
        {titleIcon && <Icon icon={titleIcon} size={IconSizes.ss} className={styles.titleIcon} />}
      </Row>
      {userSection && <div className={`${styles.marginBetween} ${styles.withLessMarging}`} />}
      <Typography
        tag="div"
        size="l"
        msg={subtitle}
        className={classNames({ [styles.userSectionSubtitle]: userSection }, { [styles.biggerSpace]: withBiggerSpace })}
      />
    </div>
  );
};

export default TitleWithSubtitle;
