import { generatePath } from 'react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { Links, LocalStorage } from '@Config/constants';
import { UploadUserDocumentPayload } from '@Graphql/graphqlTypes.generated';
import {
  deleteIdDocumentsMutation,
  updateUserMutation,
  uploadIdDocumentsMutation,
} from '@Graphql/settings/settings.mutations';
import { TransUnionSubpages } from '@Routes/settings/IdValidationPage/TransUnionSteps/transUnionSubPages';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { locationChange } from '@Store/navigation/navigation.actions';
import { DeleteIdDocumentsPayload, UpdateAccountPayload } from '@Store/settings/settings.types';
import { normaliseGqlError } from '@Utils/form';
import { deleteFromLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { deleteIdDcoument, updateAccount, uploadIdDocument } from './settings.actions';

export function* updateAccountSaga(action: Action<UpdateAccountPayload>) {
  const { setStatus, setSubmitting, isTransunionStep, ...restProps } = action.payload;
  try {
    yield call(updateUserMutation, restProps);
    yield setSubmitting(false);
    yield put(notificationSuccess('Changes saved'));
    yield deleteFromLocalStorage(LocalStorage.settingFormChanged);
    if (isTransunionStep) {
      yield put(
        locationChange({
          path: generatePath(`${Links.settings}`, {
            subpage: SettingsSubpages.IDValidation,
            transpage: TransUnionSubpages.SupportingDocuments,
          }),
        })
      );
    }
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* watchSettingsSaga() {
  yield all([
    yield takeLatest(updateAccount.started, updateAccountSaga),
    yield takeLatest(uploadIdDocument.started, uploadIdDocumentSaga),
    yield takeLatest(deleteIdDcoument.started, deleteIdDocumentSaga),
  ]);
}

export function* uploadIdDocumentSaga(action: ReturnType<typeof uploadIdDocument.started>) {
  const filesWithId = action.payload;
  try {
    const result: UploadUserDocumentPayload = yield call(uploadIdDocumentsMutation, filesWithId);
    yield put(uploadIdDocument.done({ result, params: action.payload }));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}

export function* deleteIdDocumentSaga(action: Action<DeleteIdDocumentsPayload>) {
  const id = action.payload;
  try {
    yield call(deleteIdDocumentsMutation, id);
    yield put(notificationSuccess('Document deleted successfully'));
  } catch (error: any) {
    yield put(notificationError(normaliseGqlError(error.message)));
  }
}
