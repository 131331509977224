import * as Sentry from '@sentry/browser';
import React from 'react';
import { connect } from 'react-redux';

import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import config from '@Config/config';
import { Messages } from '@Config/messages';
import { RootState } from '@Store/reducers';
import { MapT } from '@Utils/types';

import styles from './ErrorWrapper.scss';

export interface ErrorWrapperProps {
  currentLanguage: string;
  translations: MapT<MapT<string>>;
}

const ErrorWrapper = <BaseProps extends {}>(
  WrappedComponent: React.ComponentType<BaseProps>,
  fallbackText?: Messages
) => {
  type HocState = {
    readonly error: Error | null | undefined;
  };

  class Hoc extends React.Component<ErrorWrapperProps, HocState> {
    // eslint-disable-next-line react/state-in-constructor
    readonly state: HocState = {
      error: undefined,
    };

    componentDidCatch(error: Error | null) {
      this.setState({ error: error || new Error() });
      if (config.SENTRY_DSN) {
        Sentry.captureException(error);
      }
    }

    render() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { children, currentLanguage, translations, ...restProps } = this.props;
      const { error } = this.state;

      return error ? (
        <Row alignCenter justifyCenter className={styles.fallback}>
          <Typography msg={translations[currentLanguage][fallbackText || Messages.errorOccurred]} tag="h4" />
        </Row>
      ) : (
        <WrappedComponent {...(restProps as BaseProps)} />
      );
    }
  }

  const mapStateToProps = (state: RootState): ErrorWrapperProps => ({
    currentLanguage: state.localization.currentLanguage,
    translations: state.app.config.translations,
  });

  return connect<ErrorWrapperProps, null, {}, RootState>(mapStateToProps)(Hoc);
};

export default ErrorWrapper;
