import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Tour from 'reactour';

import Button, { ButtonSizes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { AppVersion, EnterKeyCode, LocalStorage, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationDeleteDCSP } from '@Graphql/dc/mutations';
import {
  CaseProviderDeleteMutation,
  CaseProviderDeleteMutationVariables,
  CaseServiceProviderFragment,
  CaseServiceProviderStatus,
  DeathCaseFragment,
  LifeCaseFragment,
  Permissions,
  UserAccountType,
  UserVerificationStatus,
} from '@Graphql/graphqlTypes.generated';
import { queryCaseServiceProviders } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { StatusColor, StatusTitleColors } from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel';
import notifyStyles from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel.scss';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { sendOne } from '@Store/dc/dc.actions';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { convertStatus, decideStatus } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
import { deathCaseNotificationHandler, editProviderHandler, idVerfiyfileUploadHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import Tooltip, { TooltipPosition } from '../Tooltip';
import TourContent from '../TourContent/TourContent';

import styles from './NotificationCard.scss';

export interface NotificationCardProps {
  service: CaseServiceProviderFragment;
  isLifeCase?: boolean;
  caseData?: DeathCaseFragment | LifeCaseFragment;
  initialBackground?: string;
  cardId?: string;
  isMissingInfo?: boolean;
  showTourCallback: () => void;
  isTourOpen: boolean;
  goToTab?: () => void;
}

const NotificationCard: React.FunctionComponent<NotificationCardProps> = ({
  service,
  isLifeCase = false,
  caseData,
  initialBackground,
  cardId,
  isMissingInfo = false,
  showTourCallback,
  isTourOpen,
  goToTab,
}) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const isMidDesktopBetweenMaxDesktop = useMediaQuery({ query: ReactResponsiveQueries.MidDesktopBetweenMaxDesktop });
  const isPadAir = useMediaQuery({ query: ReactResponsiveQueries.IpadAir });
  const isIpadAirBetweenSmallDesktop = useMediaQuery({ query: ReactResponsiveQueries.IpadAirBetweenSmallDesktop });
  const isUnOpenedResolution = useMediaQuery({ query: ReactResponsiveQueries.UnopenedStatus });
  const isReplyExpectedByRes = useMediaQuery({ query: ReactResponsiveQueries.ReplyExepctedBy });
  const isResponseExpectedBy = useMediaQuery({ query: ReactResponsiveQueries.SmallDesktopReply });
  const isIpadAirBnDesktop = useMediaQuery({ query: ReactResponsiveQueries.IpadAirBetweenDesktopS });
  const isMobileBnAllSiz = useMediaQuery({ query: ReactResponsiveQueries.mobileBnAllSix });

  const user = useSelector(getUser);
  const t = useTranslations();

  const agentUser = user?.accountType === UserAccountType.Agent;

  const [deleteDCSPMutation] = useMutation<CaseProviderDeleteMutation, CaseProviderDeleteMutationVariables>(
    mutationDeleteDCSP,
    {
      refetchQueries: [getOperationName(queryCaseServiceProviders) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess(t(Messages.notificationProviderDeleteSuccess)));
        if (user?.appVersion === AppVersion.second) {
          window.location.reload();
        }
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const deleteHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCSPMutation({ variables: { input: { id: service.id } } }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.modalRemoveDCSPTitle),
          subtitle: t(Messages.modalRemoveDCSPSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const { serviceProvider } = service;

  const currentStatus = decideStatus(convertStatus(service.status));

  const hideStatusPanel = () => {
    if (!isLifeCase) {
      if (service.status !== CaseServiceProviderStatus.ReadyToSend) {
        return true;
      }
      return false;
    }
    return false;
  };

  const sendNotification = () => {
    if (user?.verificationStatus === UserVerificationStatus.Verified) {
      dispatch(sendOne.started({ deathCaseProviderId: service.id }));
    } else {
      idVerfiyfileUploadHandler(dispatch, service.id, 'send');
    }
  };

  const completedDateVisibility = () => {
    if (
      service.status === CaseServiceProviderStatus.InProgress ||
      service.status === CaseServiceProviderStatus.Sent ||
      service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier ||
      service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromSp ||
      service.status === CaseServiceProviderStatus.InProgressAutoPopulationFailed
    ) {
      return false;
    }
    return true;
  };

  const dueDateVisibility = () => {
    if (
      (service.status === CaseServiceProviderStatus.InProgress ||
        service.status === CaseServiceProviderStatus.Sent ||
        service.status === CaseServiceProviderStatus.InProgressAutoPopulationFailed ||
        service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier ||
        service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromSp) &&
      service.responseDate
    ) {
      // if (isReplyExpectedByRes) {
      //   return false;
      // }
      return true;
    }
    return false;
  };

  const onClickActivity = () => {
    showNotification();
    if (user?.appVersion === '2.0' && isTourOpen) {
      showTourCallback();
      setToLocalStorage(LocalStorage.widgetUserNotification, true);
    }
  };

  const showNotification = () =>
    deathCaseNotificationHandler(dispatch, service.id, isLifeCase, caseData, undefined, initialBackground, goToTab);

  const leftSection = () => {
    if (service.status === CaseServiceProviderStatus.ReadyToSend) {
      return isTablet ? 4 : 5;
    }
    if (
      service.status === CaseServiceProviderStatus.InProgress ||
      service.status === CaseServiceProviderStatus.Sent ||
      service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier ||
      service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromSp ||
      service.status === CaseServiceProviderStatus.InProgressAutoPopulationFailed
    ) {
      return isTablet ? 8 : 5;
    }
    return isTablet ? 8 : 6;
  };

  const rightSection = () => {
    if (service.status === CaseServiceProviderStatus.ReadyToSend) {
      return isTablet ? 8 : 7;
    }
    if (
      service.status === CaseServiceProviderStatus.InProgress ||
      service.status === CaseServiceProviderStatus.Sent ||
      service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier ||
      service.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromSp ||
      service.status === CaseServiceProviderStatus.InProgressAutoPopulationFailed
    ) {
      return isTablet ? 4 : 7;
    }
    return isTablet ? 3 : 6;
  };

  const alignViewDetailsAtBottom = () => {
    if (service.status !== CaseServiceProviderStatus.ReadyToSend) {
      if (isPadAir) {
        return true;
      }
      return false;
    }
    return false;
  };

  const hideStatusText = () => {
    if (
      service.status === CaseServiceProviderStatus.Closed ||
      service.status === CaseServiceProviderStatus.NotFound ||
      service.status === CaseServiceProviderStatus.Confirmed ||
      service.status === CaseServiceProviderStatus.CompletedByLl ||
      service.status === CaseServiceProviderStatus.CompletedClosed ||
      service.status === CaseServiceProviderStatus.NoActionRequired ||
      service.status === CaseServiceProviderStatus.CompletedSwitched ||
      service.status === CaseServiceProviderStatus.AlreadyRegistered
    ) {
      if (isMobile || isPadAir) {
        return false;
      }
      if (isMidDesktopBetweenMaxDesktop) {
        if (service.serviceProvider.name.length > 9) {
          return false;
        }
        if (service.status === CaseServiceProviderStatus.NoActionRequired) {
          return false;
        }
        return true;
      }

      if (isIpadAirBetweenSmallDesktop) {
        if (service.serviceProvider.name.length > 9) {
          return false;
        }
        return true;
      }
      return true;
    }
    if (service.status === CaseServiceProviderStatus.Sent && isUnOpenedResolution) {
      if (service.serviceProvider.name.length > 9) {
        return false;
      }
      return true;
    }
    if (isMobileBetweenTablet || isMobile) {
      return false;
    }
    return true;
  };

  const isReadOnlyUser = caseData?.myPermission === Permissions.ReadOnly;

  const mainSection = (
    <Col alignCenter size={leftSection()} className={styles.nameDetails}>
      <Row className={styles.lineHeight} column>
        <Col
          className={
            service.status === CaseServiceProviderStatus.ReadyToSend && !isLifeCase
              ? styles.draftSplitSpNameAndStatusPanel
              : styles.splitSpNameAndStatusPanel
          }
        >
          <Typography className={styles.spName} msg={serviceProvider.name} tag="span" size="m" bolder />
          {service.status === CaseServiceProviderStatus.ReadyToSend && !isLifeCase && (
            <Tooltip
              title={t('tooltip_title_notification_unsent' as Messages)}
              message={t('tooltip_desc_notification_unsent' as Messages)}
              position={TooltipPosition.top}
            >
              <Icon className={styles.infoIcon} icon="info" />
            </Tooltip>
          )}
          {hideStatusPanel() && (
            <span
              className={classNames(styles.statusPanel, {
                [notifyStyles[`${currentStatus.backgroundColor}` as StatusColor]]: currentStatus.backgroundColor,
              })}
            >
              <Icon icon={currentStatus.icon} size={IconSizes.ss} />
              <span
                className={classNames(styles.statusTitle, {
                  [notifyStyles[`${currentStatus.statusTitleColor}` as StatusTitleColors]]:
                    currentStatus.statusTitleColor,
                })}
              >
                {hideStatusText() && t(currentStatus.statusTitle as Messages)}
              </span>
            </span>
          )}
        </Col>

        {caseData && (
          <>
            <Col className={styles.todoTitle}>
              <Typography msg="Unique identifier" tag="div" size="s" color="footerColor" />
            </Col>
            <Col>
              <Row constant>
                <Icon size={IconSizes.sxs} className={styles.globeIcon} icon="globe-outline" />
                <Typography
                  className={styles.rightSpacing}
                  msg={
                    service.accountIdentifierPrimary !== '' ? service.accountIdentifierPrimary : 'Missing information'
                  }
                  tag="div"
                  size="xs"
                  bolder
                  color={service.accountIdentifierPrimary !== '' ? 'newGray' : 'red'}
                />
              </Row>
            </Col>
          </>
        )}

        {dueDateVisibility() && isResponseExpectedBy && (
          <Col>
            <Typography
              msg={t('label_reply_expected_by' as Messages)}
              tag="span"
              size="s"
              color="footerColor"
              className={classNames(styles.todoTitle, styles.noWrap)}
            />
            <Row constant alignCenter size={12}>
              <Tooltip
                position={TooltipPosition.top}
                message={t('label_expected_response_on_previous_data_notification' as Messages)}
                title="What is this?"
              >
                <Icon className={styles.replyIcon} icon="info" size={IconSizes.s16} />
              </Tooltip>
              <Typography
                msg={languageBasedDate(service.responseDate, currentLanguage)}
                tag="span"
                size="s"
                color="footerColor"
                className={styles.rightSpacing}
              />
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  );

  const moreHandler = () => {
    dispatch(
      showModal({
        type: ModalTypes.mobileNotificationActionsModal,
        params: {
          data: {
            serviceId: service.id,
            caseData,
            isLifeCase,
            service,
            isMissingInfo,
          },
          title: '',
          subtitle: '',
          modalSize: PaperWidths.m,
          showCloseIcon: true,
        },
      })
    );
  };

  const editLinkVisibility = () => {
    if (isMobile || isReadOnlyUser) {
      return false;
    }
    if (isLifeCase && isMissingInfo) {
      return false;
    }
    return true;
  };

  const widgetUserSteps = [
    {
      selector: '#widgetFirstNotification',
      content: (
        <TourContent content="This is the notification that you just sent, click it to check the status of your notification" />
      ),
    },
  ];

  const closeActiveTour = () => {
    showTourCallback();
    setToLocalStorage(LocalStorage.widgetUserNotification, true);
  };

  return (
    <>
      <Row id={cardId} constant={!isMobile} className={classNames(styles.bottomSpacing)} size={12}>
        <Col
          tabindex={0}
          constant={!isMobile}
          onClick={onClickActivity}
          size={11}
          className={classNames(styles.container, styles.cardClickable)}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              onClickActivity();
            }
          }}
        >
          <Row constant={!isMobile} size={12}>
            <div className={styles.combined}>
              {serviceProvider.logo ? (
                <Col className={styles.logoWrapper}>
                  <img src={serviceProvider.logo} alt="" />
                </Col>
              ) : (
                <div className={styles.initialLogoContainer} style={{ backgroundColor: initialBackground }}>
                  <span>{serviceProvider.name.charAt(0).toUpperCase()}</span>
                </div>
              )}
              {isMobile ? mainSection : null}
            </div>
            {isMobile ? null : mainSection}

            <Col alignBottom={alignViewDetailsAtBottom()} size={rightSection()}>
              {service.status === CaseServiceProviderStatus.ReadyToSend && (
                <Row
                  constant
                  className={styles.readyToSendActions}
                  justifyEnd={!isMobile}
                  justifyBetween={isMobile}
                  // justifyCenter={!isMobile}
                  alignCenter={!isMobile}
                  size={12}
                >
                  {editLinkVisibility() && (
                    <div
                      /* eslint-disable-next-line */
                      tabIndex={0}
                      className={styles.editLink}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        editProviderHandler(
                          t,
                          caseData ? caseData.id : '',
                          dispatch,
                          isLifeCase,
                          service,
                          user?.appVersion
                        );
                      }}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          e.stopPropagation();
                          editProviderHandler(
                            t,
                            caseData ? caseData.id : '',
                            dispatch,
                            isLifeCase,
                            service,
                            user?.appVersion
                          );
                        }
                      }}
                    >
                      Edit
                    </div>
                  )}
                  {!isMobile && <Col size={1} />}
                  {!isMobile && !isMobileBetweenTablet && (
                    <>
                      <Col
                        onKeyDown={(e: any) => {
                          if (e.keyCode === EnterKeyCode) {
                            onClickActivity();
                          }
                        }}
                        onClick={() => showNotification()}
                        tabindex={0}
                      >
                        <Typography
                          msg="View details"
                          tag="span"
                          size="l"
                          className={styles.rightSpacing}
                          underline
                          color="newGray"
                        />
                      </Col>
                      <Col size={1} />
                    </>
                  )}
                  {isMobile && (
                    <Col>
                      <Row
                        constant={isMobile}
                        size={12}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          moreHandler();
                        }}
                        justifyEnd={!isMobile}
                      >
                        <Icon icon="more-horizontal" className={styles.moreHoriz} />
                        <Typography msg="More" tag="span" size="mb" bold className={styles.moreEllipsis} />
                      </Row>
                    </Col>
                  )}
                  {!isLifeCase && !isReadOnlyUser && (
                    <>
                      <Col textCenter={!isMobile}>
                        {agentUser ? (
                          <Tooltip
                            message={t('tooltip_agent_no_permission' as Messages)}
                            position={TooltipPosition.top}
                          >
                            <Button
                              constant
                              onClick={(e: any) => {
                                e.stopPropagation();
                                sendNotification();
                              }}
                              label="Send"
                              icon="paper-plane"
                              disabled
                              iconSize={IconSizes.s}
                              size={ButtonSizes.send}
                            />
                          </Tooltip>
                        ) : (
                          <Button
                            constant
                            onClick={(e: any) => {
                              e.stopPropagation();
                              sendNotification();
                            }}
                            label="Send"
                            icon="paper-plane"
                            iconSize={IconSizes.s}
                            size={ButtonSizes.send}
                          />
                        )}
                      </Col>
                    </>
                  )}
                  {isLifeCase && isMissingInfo && !isMobile && !isReadOnlyUser && (
                    <>
                      <Col textCenter={!isMobile}>
                        <Button
                          constant
                          onClick={(e: any) => {
                            e.stopPropagation();
                            editProviderHandler(t, caseData ? caseData.id : '', dispatch, isLifeCase, service);
                          }}
                          label="Complete missing info"
                          icon="plus"
                          iconSize={IconSizes.s}
                          size={ButtonSizes.send}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              )}
              {service.status !== CaseServiceProviderStatus.ReadyToSend && (
                <>
                  {!isTablet && (
                    <>
                      <Row
                        alignCenter
                        className={classNames(styles.bottomSpacing, styles.flexGap)}
                        justifyEnd
                        size={12}
                      >
                        {dueDateVisibility() && !isReplyExpectedByRes && (
                          <Col>
                            <Row alignCenter size={12} justifyEnd>
                              <Tooltip
                                position={TooltipPosition.top}
                                message="This is the time that we expect the company to respond to your notification, it is based on previous data of how quickly they process notifications"
                                title="What is this?"
                              >
                                <Icon className={styles.replyIcon} icon="info" size={IconSizes.s16} />
                              </Tooltip>
                              <Typography
                                msg={t('label_reply_expected_by' as Messages)}
                                tag="span"
                                size="s"
                                color="footerColor"
                                className={classNames(styles.rightSpacing, styles.noWrap)}
                              />

                              <Typography
                                msg={languageBasedDate(service.responseDate, currentLanguage)}
                                tag="span"
                                size="s"
                                color="footerColor"
                                className={styles.rightSpacing}
                              />
                            </Row>
                          </Col>
                        )}

                        <Col>
                          <Row alignCenter size={12} justifyEnd>
                            <Icon className={styles.sentDate} icon="checkmark" size={IconSizes.sss} />
                            <Typography
                              msg="Sent"
                              tag="span"
                              size="s"
                              color="footerColor"
                              className={styles.rightSpacing}
                            />

                            <Typography
                              msg={languageBasedDate(service.sentAt, currentLanguage)}
                              tag="span"
                              size="s"
                              color="footerColor"
                              className={styles.rightSpacing}
                            />
                          </Row>
                        </Col>
                        {completedDateVisibility() && (
                          <Col>
                            <Row alignCenter size={12} justifyEnd>
                              <Icon className={styles.completedDate} icon="doneAll" size={IconSizes.sss} />
                              <Typography
                                msg="Completed"
                                tag="span"
                                size="s"
                                color="footerColor"
                                className={styles.rightSpacing}
                              />

                              <Typography
                                msg={languageBasedDate(service.completedAt, currentLanguage)}
                                tag="span"
                                size="s"
                                color="footerColor"
                                className={styles.rightSpacing}
                              />
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                  <Row
                    constant
                    className={classNames(
                      { [styles.mt50]: dueDateVisibility() && isResponseExpectedBy },
                      { [styles.mt80]: dueDateVisibility() && isIpadAirBnDesktop },
                      { [styles.viewDetailsGap]: isPadAir }
                    )}
                    justifyEnd
                    alignCenter
                    size={12}
                  >
                    {isPadAir ? (
                      <>
                        {!isMobileBnAllSiz && <Icon icon="grid" />}
                        <Typography className={styles.alignRight} msg="View details" tag="span" size="mb" bold />
                      </>
                    ) : (
                      <Typography
                        className={styles.alignRight}
                        msg="View details"
                        tag="span"
                        size="l"
                        color="newGray"
                        underline
                        tabIndex={0}
                      />
                    )}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>

        {service.status === CaseServiceProviderStatus.ReadyToSend && !isMobile && !isReadOnlyUser && (
          <Col constant={!isMobile} size={1} alignCenter textCenter>
            <div
              //eslint-disable-next-line
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  deleteHandler();
                }
              }}
              onClick={() => deleteHandler()}
            >
              <Icon icon="trash" className={styles.handSymbol} />
            </div>
          </Col>
        )}
      </Row>
      <Tour
        steps={widgetUserSteps}
        showNumber={false}
        isOpen={isTourOpen}
        onRequestClose={closeActiveTour}
        showNavigationNumber={false}
        lastStepNextButton={<Button onClick={closeActiveTour} label="Finish" isFullWidth />}
      />
    </>
  );
};

export default NotificationCard;
