
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NotificationChats"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NotificationChatNode"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"commentedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"commentedBy"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"commentedByName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chatType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chatSubject"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":150}};
    doc.loc.source = {"body":"fragment NotificationChats on NotificationChatNode {\nchatText\nfile\nfileName\ncommentedAt\ncommentedBy\ncommentedByName  \nchatType\nuserType\nchatSubject\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
