import classNames from 'classnames';
import { FieldProps, useField, useFormikContext } from 'formik';
import * as React from 'react';

import { hasFieldError } from '@Utils/form';

import InputContainer from '../InputContainer';

import styles from './Checkbox.scss';

export enum CheckBoxTypes {
  radio = 'radio',
  checkbox = 'checkbox',
}

export interface CheckboxInputProps {
  label: string;
  subLabel?: string;
  required?: boolean;
  className?: string;
  disabled: boolean;
  type: CheckBoxTypes;
  value: string;
  isNotificationConsent?: boolean;
}

interface CheckboxPrivateProps extends CheckboxInputProps, FieldProps {}

const Checkbox: React.FunctionComponent<CheckboxPrivateProps> = (props) => {
  const {
    label,
    subLabel,
    required,
    form,
    type = CheckBoxTypes.radio,
    isNotificationConsent = false,
    disabled = false,
    ...restProps
  } = props;
  const [field, meta] = useField(props.field);
  const hasError = hasFieldError(meta, form);
  const { setFieldValue } = useFormikContext();

  return (
    <InputContainer showErrorIcon={true} meta={meta} form={form} required={required}>
      <label className={classNames(styles.checkbox)}>
        <input
          {...field}
          {...restProps}
          type={type}
          checked={type === CheckBoxTypes.radio ? props.value === field.value : field.value}
          disabled={disabled}
        />
        {/* eslint-disable-next-line */}
        <span 
          className={classNames(styles.inputBox, { [styles.error]: hasError, [styles.disabled]: disabled })}
          //eslint-disable-next-line
          tabIndex={0}
          id={field.name}
          data-radio-value={field.value}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const buttonValue = document.getElementById(field.name)?.getAttribute('data-radio-value');
              setFieldValue(field.name, buttonValue ? !field.value : true);
            }
          }}
        />
        <div className={isNotificationConsent ? styles.consentLabel : styles.label}>
          <div>{label}</div>
          {subLabel && <div className={styles.subLabel}>{subLabel}</div>}
        </div>
      </label>
    </InputContainer>
  );
};

export default Checkbox;
