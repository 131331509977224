import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import CaseInfo from '@Components/application/CaseInfo';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { colorArray, ReactResponsiveQueries } from '@Config/constants';
import { getUser } from '@Store/auth/auth.selector';

import styles from './DeathCasesColumn.scss';

export interface DeathCasesColumnProps {
  CardComponent: typeof CaseInfo;
  title: string;
  dcCases: any[];
  subTitle?: string;
  showAssignedTo?: boolean;
  isActiveStatus?: boolean;
}

const DeathCasesColumn: React.FunctionComponent<DeathCasesColumnProps> = ({
  CardComponent,
  title,
  dcCases,
  subTitle,
  showAssignedTo = false,
  isActiveStatus = false,
}) => {
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  let i = -1;
  return (
    <>
      <Typography msg={`${title} (${dcCases.length})`} tag="h5" size="l" />
      {subTitle && (
        <Row size={12} className={styles.bottomSpacing}>
          <Col size={isTablet ? 11 : 5}>
            <Typography msg={subTitle} tag="div" size="m" />
          </Col>
          {user?.isPortalAdmin && showAssignedTo && (
            <>
              {!isTablet && <Col size={4} />}

              <Col size={isTablet ? 3 : 2} textCenter alignCenter>
                <Typography msg="Assigned to" tag="div" size="l" />
              </Col>
            </>
          )}
        </Row>
      )}

      {dcCases.map((item: any, idx: number) => {
        i += 1;
        const j = i;
        i = i === 4 ? -1 : i;
        return (
          <CardComponent
            caseNumber={isActiveStatus ? idx : undefined}
            key={idx}
            initialBackground={colorArray[j]}
            data={item.node}
          />
        );
      })}
    </>
  );
};

export default DeathCasesColumn;
