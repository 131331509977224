import { useMutation } from '@apollo/react-hooks';
import ProgressBar from '@ramonak/react-progress-bar';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useParams } from 'react-router';

import CompanyCard from '@Components/application/CompanyCard';
import Button, { ButtonSizes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { colorArray, Links, LocalStorage, ObTransactionType, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  ConvertToNotificationMutation,
  ConvertToNotificationMutationVariables,
  UpdateObAuthIdMutation,
  UpdateObAuthIdMutationVariables,
  useObSessionProgressLazyQuery,
  useObTransactionsLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationConvertToNotification, mutationUpdateObAuthId } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import EmptyCaseCard from '@Routes/dashboard/shared/EmptyCaseCard';
import { normaliseGqlError } from '@Utils/form';
import {
  deleteFromLocalStorage,
  getArrayFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from '@Utils/localStorage';
import { obNeverLeaveHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './SelectCompanies.scss';

export interface SelectCompaniesProps extends NavigationProps {}

interface CompanySelected {
  selected: boolean;
  companyId: string;
  undefinedTrans?: boolean;
}

export interface UnstructuredCompanies {
  name: string;
  information: string;
}

const SelectCompanies: React.FunctionComponent<SelectCompaniesProps> = ({ next }) => {
  const t = useTranslations();
  const { id } = useParams();
  const history = useHistory();
  const queryString = history.location.search;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(history.location.search);
  const sessionId = searchParams.get('session') || '';
  const [showProgressBar, setShowProgressBar] = React.useState(sessionId !== '');
  const [addedCompanies, setAddedCompanies] = React.useState<CompanySelected[]>([]);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const localStorageCompanies = getArrayFromLocalStorage(LocalStorage.obCompanyIds);

  const [getObSession, obSessionResult] = useObSessionProgressLazyQuery({
    pollInterval: 2000, // Poll every second
    onError: () => {
      obSessionResult.stopPolling();
    },
  });

  const [getBankDetails, bankDetails] = useObTransactionsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      obSessionResult.stopPolling();
      setShowProgressBar(false);
    },
    onError: () => {
      obSessionResult.stopPolling();
    },
  });

  const [updateObAuthId] = useMutation<UpdateObAuthIdMutation, UpdateObAuthIdMutationVariables>(
    mutationUpdateObAuthId,
    {
      onCompleted: () => {
        getBankDetails({
          variables: { baseCase: id },
        });
      },
      onError: (mutError: any) => {
        obSessionResult.stopPolling();
        dispatch(notificationError(normaliseGqlError(mutError.message)));
      },
    }
  );

  const [convertToNotification] = useMutation<ConvertToNotificationMutation, ConvertToNotificationMutationVariables>(
    mutationConvertToNotification,
    {
      onCompleted: () => {
        dispatch(
          notificationSuccess(`${addedCompanies.length} ${addedCompanies.length > 1 ? 'companies' : 'company'} added`)
        );
        deleteFromLocalStorage(LocalStorage.obCompanySelected);
        history.push(generatePath(`${Links.lcReady}${queryString}`, { id }));
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  React.useEffect(() => {
    if (localStorageCompanies.length > 0 && addedCompanies.length === 0 && !showProgressBar) {
      const selectedCompanies: CompanySelected[] = [];
      const companyDetails = localStorageCompanies.map((cc: string) => {
        const trans = bankDetails.data?.obTransactions.find((obt: any) => obt.id === cc);
        const compObject: CompanySelected = {
          selected: true,
          companyId: trans?.id ? trans.id : '',
          undefinedTrans: !trans?.serviceProvider,
        };
        selectedCompanies.push(compObject);
        return null;
      });
      setAddedCompanies(companyDetails);
    }

    if (sessionId && addedCompanies.length === 0 && showProgressBar) {
      updateObAuthId({
        variables: {
          input: {
            sessionId,
          },
        },
      });
      getObSession({
        variables: {
          sessionId,
        },
      });
    }
    //eslint-disable-next-line
  },[sessionId]);

  const onChecked = (checked: boolean, checkedId: string, isMissingTrans?: boolean) => {
    let existingCompanies = [...addedCompanies];
    if (checked) {
      existingCompanies[existingCompanies.length] = {
        selected: checked,
        companyId: checkedId,
        undefinedTrans: !!(isMissingTrans && checked),
      };
    } else {
      existingCompanies =
        existingCompanies && existingCompanies.filter((c: CompanySelected) => c.companyId !== checkedId);
    }
    setAddedCompanies(existingCompanies);
    const hasUndefinedTransaction =
      existingCompanies && existingCompanies.filter((ec: CompanySelected) => ec.undefinedTrans);
    if (existingCompanies.length > 0) {
      setToLocalStorage(
        LocalStorage.obCompanySelected,
        hasUndefinedTransaction.length > 0 ? ObTransactionType.undefined : ObTransactionType.defined
      );
      const obCompaniesIds = existingCompanies.map((ac: CompanySelected) => ac.companyId);
      setToLocalStorage(LocalStorage.obCompanyIds, JSON.stringify(obCompaniesIds));
    } else {
      deleteFromLocalStorage(LocalStorage.obCompanySelected);
      deleteFromLocalStorage(LocalStorage.obCompanyIds);
    }
  };

  const onSubmit = () => {
    const transactions = addedCompanies.map((ac: CompanySelected) => ac.companyId);
    convertToNotification({
      variables: {
        input: {
          transactions,
        },
      },
    });
  };

  let i = -1;

  const sortSpCompaniesArray = (spArray: any) => {
    return spArray?.sort((a: any, b: any) =>
      (a.caseAccountIdentifier ?? '').localeCompare(b.caseAccountIdentifier ?? '')
    );
  };

  const sortNotStructuredCompanies = (notStructArray: any) => {
    return notStructArray?.sort((a: any, b: any) => (a.unstructuredInfo ?? '').localeCompare(b.unstructuredInfo ?? ''));
  };

  const spCompanies = sortSpCompaniesArray(
    bankDetails.data?.obTransactions.filter((obt: any) => obt.serviceProvider !== null)
  );

  const notStructuredCompanies = sortNotStructuredCompanies(
    bankDetails.data?.obTransactions.filter((obt: any) => obt.serviceProvider === null)
  );

  const hasBanks =
    (spCompanies && spCompanies?.length > 0) || (notStructuredCompanies && notStructuredCompanies.length > 0);

  const getIsCardChecked = (b: any) => {
    const isThere = localStorageCompanies.filter((lsc: string) => lsc === b.id);
    return isThere.length > 0;
  };

  return (
    <>
      {isMobile && (
        <Row alignCenter justifyBetween constant className={styles.mobileMenu}>
          <Typography msg="Life Ledger Discovery" tag="div" bold size="l" />
          <Icon
            onClick={() => {
              if (getFromLocalStorage(LocalStorage.obCompanySelected) !== null) {
                obNeverLeaveHandler(dispatch, id || '');
              } else {
                history.push(generatePath(`${Links.lcReady}${queryString}`, { id }));
              }
            }}
            icon="close-gray"
            size={IconSizes.sxs}
          />
        </Row>
      )}
      <div className={styles.mobileBody}>
        <div className={styles.companiesheader}>
          <Typography msg="Select companies" tag="h3" size="xxl" bold />
          <Row size={9}>
            <Typography msg={t('subtitle_company_profile_selectcompanies' as Messages)} tag="div" size="l" />
          </Row>
        </div>
        <div className={styles.bodyContainer}>
          {showProgressBar && (
            <>
              <Typography
                msg={obSessionResult.data?.obSessionProgress?.status || ''}
                bolder
                tag="div"
                className={styles.progressBarFont}
              />
              <ProgressBar
                completed={obSessionResult.data?.obSessionProgress?.progressPercentage || 0}
                baseBgColor="#F8F8F8"
                bgColor="#2E60E0"
                isLabelVisible={false}
              />
              <EmptyCaseCard />
              <EmptyCaseCard />
              <EmptyCaseCard />
            </>
          )}
          {!showProgressBar && (
            <>
              {spCompanies &&
                spCompanies.map((b: any, idx: number) => {
                  i += 1;
                  const j = i;
                  i = i === 4 ? -1 : i;

                  return (
                    <CompanyCard
                      initialBackground={colorArray[j]}
                      key={idx}
                      onSelection={onChecked}
                      company={b}
                      isCardChecked={getIsCardChecked(b)}
                    />
                  );
                })}
              {/* {!hasBanks && (
            <div className={styles.emptyCompany}>
              <Icon icon="globe-outline" size={IconSizes.ssm} />
              <Typography
                color="footerColor"
                msg="There are no recognised companies in your online banking history"
                tag="div"
                size="m"
              />
            </div>
          )} */}

              {notStructuredCompanies && notStructuredCompanies.length > 0 && (
                <>
                  <Typography
                    className={styles.mt54}
                    msg="We also found the following transactions"
                    tag="div"
                    bold
                    size="xl"
                  />
                  <Typography
                    msg="We identified the following companies from your account, however we could not find an exact match for the following companies"
                    tag="div"
                    size="l"
                    className={styles.mb4}
                  />
                  {notStructuredCompanies.map((b: any, idx: number) => {
                    i += 1;
                    const j = i;
                    i = i === 4 ? -1 : i;

                    return (
                      <CompanyCard
                        initialBackground={colorArray[j]}
                        key={idx}
                        onSelection={onChecked}
                        missingInfoCompanies={b}
                        isCardChecked={getIsCardChecked(b)}
                      />
                    );
                  })}
                </>
              )}

              {!hasBanks && (
                <div className={styles.noCompaniesFound}>
                  <Typography
                    className={styles.bottomSpacing}
                    msg={t('label_no_companies_found' as Messages)}
                    tag="div"
                    color="footerColor"
                    size="m"
                  />
                  <Button
                    label={t('button_try_again' as Messages)}
                    size={ButtonSizes.fill}
                    onClick={() => history.push(generatePath(Links.institutions, { id }))}
                    className={styles.buttonSpacing}
                    constant
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.footer}>
          <Row constant className={styles.gap24}>
            <Col>
              <Typography msg={t('label_selected' as Messages)} tag="div" size="l" color="footerColor" />
            </Col>
            <Col>
              <Typography
                msg={`${addedCompanies.length} ${
                  addedCompanies.length > 1 ? t('label_companies' as Messages) : t('label_company_name' as Messages)
                } `}
                tag="div"
                size="l"
                bold
              />
            </Col>
          </Row>
          <Row constant className={styles.bottomSpacing} size={isMobile ? 12 : 3}>
            {next && (
              <Button
                label={`Add ${addedCompanies.length} ${
                  addedCompanies.length > 1 ? t('label_companies' as Messages) : t('label_company_name' as Messages)
                } `}
                size={ButtonSizes.fill}
                onClick={() => onSubmit()}
                className={styles.buttonSpacing}
                disabled={addedCompanies.length === 0}
                constant
                isFullWidth
              />
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default SelectCompanies;
