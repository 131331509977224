import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import NewsLetters from '@Components/application/NewsLetters';
import Footer from '@Components/Footer';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import BeSpokeLoginForm from './BeSpokeLoginForm';
import styles from './BeSpokeLoginPage.scss';

export interface BeSpokeLoginPageProps {}

const BeSpokeLoginPage: React.FunctionComponent<BeSpokeLoginPageProps> = () => {
  const t = useTranslations();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [emailSentTo, setEmailSentTo] = React.useState('');

  const advantagePoints = [
    t('label_bespoke_advantage_1' as Messages),
    t('label_bespoke_advantage_2' as Messages),
    t('label_bespoke_advantage_3' as Messages),
    t('label_bespoke_advantage_4' as Messages),
    t('label_bespoke_advantage_5' as Messages),
  ];

  const isDesktop = !isTablet && !isMobile;

  return (
    <>
      <section className={styles.headerLayout}>
        <a href="https://lifeledger.com/">
          <img
            width={!isTablet ? '228' : '161'}
            height={!isTablet ? '49' : '35'}
            src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
            alt=""
          />
        </a>
      </section>
      <section className={styles.mainContentLayout}>
        <Row size={12} justifyCenter={emailSentTo !== ''} className={styles.gapBetween}>
          {isDesktop && !emailSentTo && <Col size={1} />}
          {!emailSentTo && (
            <Col className={styles.infoContent} size={isTablet ? 6 : 4}>
              <Typography family="palatino" msg={t(Messages.pageInvite)} tag="div" size={isDesktop ? 'xxxl' : 'xxl'} />
              <Typography
                family="palatino"
                msg={t('label_service_free' as Messages)}
                tag="div"
                size={isDesktop ? 'h1l' : 'xxl'}
              />

              <div className={styles.closeFreeze}>
                We will help you
                <b> close, </b>
                <b> freeze, </b>
                <b> switch or </b>
                <b> transfer </b>
                all of the accounts connected to the deceased,
                <b> quickly,</b>
                <b> easily </b>
                and
                <b> from one place </b>
              </div>
              {!isMobile &&
                advantagePoints.map((point: any, idx: number) => (
                  <Row key={idx} constant className={styles.points} alignCenter>
                    <Icon className={styles.tickIcon} size={IconSizes.tick} icon="tick" />
                    <Typography msg={point} size="l" tag="div" />
                  </Row>
                ))}
            </Col>
          )}
          {isDesktop && !emailSentTo && <Col size={1} />}
          <Col size={!emailSentTo ? 6 : 4}>
            {isDesktop && <div className={styles.greenEllipsis} />}
            <BeSpokeLoginForm emailSentTo={emailSentTo} setEmailSentTo={setEmailSentTo} />
          </Col>
        </Row>
      </section>

      {isMobile &&
        advantagePoints.map((point: any, idx: number) => (
          <Row key={idx} constant className={styles.points} alignCenter>
            <Icon className={styles.tickIcon} size={IconSizes.tick} icon="tick" />
            <Typography msg={point} size="l" tag="div" />
          </Row>
        ))}
      <div className={styles.pb100}>
        <NewsLetters containerClassName={styles.mb40} />
      </div>

      <Footer />
    </>
  );
};

export default BeSpokeLoginPage;
