import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryCollaboratorsList } from '@Graphql/collaborators/queries';
import { mutationDeleteCollaborator } from '@Graphql/dc/mutations';
import {
  CollaboratorNode,
  CollaboratorNodeEdge,
  CollaboratorPermission,
  CollaboratorProRelationFe,
  CollaboratorRelationshipFe,
  DeleteCollaboratorMutation,
  DeleteCollaboratorMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './CollaboratorCard.scss';
import CollaboratorPermissionPage from './CollaboratorPermission/CollaboratorPermissionPage';

export interface CollaboratorCardProps {
  containerClassName?: string;
  data: CollaboratorNode;
  allCollaborators: CollaboratorNodeEdge[];
  isReadyOnly: boolean;
}

const CollaboratorCard: React.FunctionComponent<CollaboratorCardProps> = ({ data, allCollaborators, isReadyOnly }) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [deleteCollaboratorMutation] = useMutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>(
    mutationDeleteCollaborator,
    {
      refetchQueries: [getOperationName(queryCollaboratorsList) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess(t(Messages.notificationCollaboratorDeleteSuccess)));
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const deleteHandler = (id: CollaboratorNode['id']) => {
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteCollaboratorMutation({ variables: { input: { id } } }),
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
            content:
              'Deleting this collaborator will mean they will not recieve any notificaitons or updates on the status of the death case and death notifications that are subsequently sent.',
            deleteMessage: 'Do you want to remove this collaborator anyway?',
          },
          title: 'Remove from death case?',
          modalSize: PaperWidths.m,
          isGrayPaper: true,
        },
      })
    );
  };

  const permissionOptions: any = Object.values(CollaboratorPermission).map((desTitle: string) => ({
    label: t(`${Messages.labelPermissionTypePrefix}${desTitle.toLowerCase()}` as Messages),
    value: desTitle,
  }));

  return (
    <Row constant size={12}>
      <Col constant size={11} className={styles.missingContainer}>
        <Row size={12}>
          <Col size={5}>
            <Row column>
              <Typography className={styles.capitalize} tag="div" msg={`${data.firstName} ${data.lastName}`} size="m" />
              <Row className={styles.relationshipGap}>
                <Typography
                  className={styles.relationshipLayout}
                  tag="span"
                  msg={t(`${Messages.labelRelationshipPrefix}${data.relationship.toLowerCase()}` as Messages)}
                  size="s"
                />

                {data.isAlsoExecutor && data.relationship !== CollaboratorRelationshipFe.Executor && (
                  <Typography
                    className={styles.relationshipLayout}
                    tag="span"
                    msg={t(
                      `${
                        Messages.labelRelationshipPrefix
                      }${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages
                    )}
                    size="s"
                  />
                )}

                {data.isAlsoAdministrator && data.relationship !== CollaboratorRelationshipFe.WillAdministrator && (
                  <Typography
                    className={styles.relationshipLayout}
                    tag="span"
                    msg={t(
                      `${
                        Messages.labelRelationshipPrefix
                      }${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
                    )}
                    size="s"
                  />
                )}

                {data.isAlsoLegal && data.relationship !== CollaboratorProRelationFe.LegalRepresentative && (
                  <Typography
                    className={styles.relationshipLayout}
                    tag="span"
                    msg={t(
                      `${
                        Messages.labelRelationshipPrefix
                      }${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
                    )}
                    size="s"
                  />
                )}

                {data.isAlsoOtherAdvisor && data.relationship !== CollaboratorProRelationFe.OtherAdvisor && (
                  <Typography
                    className={styles.relationshipLayout}
                    tag="span"
                    msg={t(
                      `${
                        Messages.labelRelationshipPrefix
                      }${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
                    )}
                    size="s"
                  />
                )}
              </Row>
            </Row>
          </Col>

          <Col size={4}>
            <Typography tag="div" msg={data.email} size="m" />
          </Col>

          <Col size={3}>
            {isReadyOnly ? (
              <Typography
                tag="div"
                msg={t(`${Messages.labelPermissionTypePrefix}${data.permission.toLowerCase()}` as Messages)}
                size="m"
              />
            ) : (
              <CollaboratorPermissionPage
                data={data}
                permissionOptions={permissionOptions}
                allCollaborators={allCollaborators}
              />
            )}
          </Col>
        </Row>
      </Col>
      {data.isInvitePending && !isReadyOnly && (
        <Col constant size={1} alignCenter textCenter>
          <div
            //eslint-disable-next-line
            tabIndex={0}
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                deleteHandler(data.id);
              }
            }}
            onClick={() => deleteHandler(data.id)}
          >
            <Icon icon="trash" className={styles.handSymbol} />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default CollaboratorCard;
