import * as dotProp from 'dot-prop-immutable';
import { Action } from 'typescript-fsa';

import { UserNode, UserNodeDetails } from '@Graphql/graphqlTypes.generated';
import { createReducer } from '@Utils/redux';

import { getUserData, login, logout, yotiPolling } from './auth.actions';
import { UserAuth } from './auth.types';

export type AuthState = {
  readonly user: UserAuth | null;
  readonly userData: UserNode | null;
};

export const initialState: AuthState = {
  user: null,
  userData: null,
};

const authReducer = createReducer<AuthState>(initialState, {
  [login.started.type]: (state) => {
    return dotProp.set(state, 'user', null);
  },
  [login.done.type]: (state, action: Action<{ result: UserAuth }>) => {
    const user = action.payload.result;
    return dotProp.set(state, 'user', user);
  },
  [logout.type]: () => {
    return initialState;
  },
  [getUserData.done.type]: (state, action: Action<{ result: UserNodeDetails }>) => {
    return dotProp.set(state, 'userData', action.payload.result.info);
  },
  [yotiPolling.done.type]: (state, action: Action<{ result: UserNodeDetails }>) => {
    if (state.userData?.verificationStatus !== action.payload.result.info.verificationStatus) {
      return dotProp.merge(state, 'userData', action.payload.result.info);
    }
    return state;
  },
});

export default authReducer;
