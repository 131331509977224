import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import MiniFooter from '@Components/application/MiniFooter';
import MobileMenu from '@Components/application/MobileMenu';
import SideMenu from '@Components/application/SideMenu';
import { MenuItems } from '@Components/application/SideMenu/SideMenu';
import SpSideMenu from '@Components/application/SpSideMenu/SpSideMenu';
import { ReactResponsiveQueries } from '@Config/constants';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
//import layoutStyles from '@Routes/dashboard/DashboardPage/DashboardPage.scss';
import ProviderDashboardNavbar from '@Routes/provider/ProviderDashboardPage/ProviderDashboardNavbar';
import { getUser } from '@Store/auth/auth.selector';

import Col from '../Col';

import styles from './SettingsLayout.scss';

export interface SettingsLayoutProps {
  className?: string;
  rightSideComponent?: React.ReactNode;
  settingsMenuComponent?: React.ReactNode;
  isValidationPage?: boolean;
}

const SettingsLayout: React.FunctionComponent<SettingsLayoutProps> = ({
  rightSideComponent,
  settingsMenuComponent,
  isValidationPage,
}) => {
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [mobileShowSideMenu, setMobileShowSideMenu] = React.useState(false);
  const [isBackToSettings, setIsBackToSettings] = React.useState(false);
  const onHamburgerClick = () => {
    setMobileShowSideMenu(!mobileShowSideMenu);
  };

  const onBackToSettingsClick = () => {
    setIsBackToSettings(true);
    setMobileShowSideMenu(true);
  };

  const spUser = user?.accountType === UserAccountType.ServiceProvider;

  const desktopSideMenu = () => {
    if (spUser) {
      return <SpSideMenu page={MenuItems.Settings} />;
    }
    return (
      <SideMenu
        backToSettingsMenu={isBackToSettings}
        closeClick={() => setMobileShowSideMenu(!mobileShowSideMenu)}
        page={MenuItems.Settings}
        showSideMenuItems={true}
      />
    );
  };

  return (
    <>
      {!spUser ? (
        <main className={styles.layoutContainer}>
          <div>
            {isMobile && !mobileShowSideMenu ? (
              <MobileMenu
                onBackToSettings={onBackToSettingsClick}
                onHamburgerClick={onHamburgerClick}
                showBackToSettings={true}
                showHamBurger={true}
                isValidationPage={isValidationPage}
              />
            ) : (
              desktopSideMenu()
            )}
          </div>
          {!isMobile && (
            <div className={classNames(styles.settingsMenuComponent, styles.secondLeftSideContainer)}>
              {settingsMenuComponent}
            </div>
          )}

          {!mobileShowSideMenu && (
            <div className={classNames(styles.rightSideContainer, styles.rightSidePosition)}>
              {rightSideComponent}
              {!isTablet && <MiniFooter containerClassName={styles.footerSpacing} centerAlign={false} />}
            </div>
          )}
        </main>
      ) : (
        <Col className={styles.overallContainer} size={12}>
          <ProviderDashboardNavbar />
          <div className={classNames(styles.cardBackground, styles.mt34, styles.flex)}>
            <Col className={classNames(styles.spSetiingMenuComponent, styles.secondLeftSideContainer)}>
              {settingsMenuComponent}
            </Col>
            <Col className={classNames(styles.rightSideContainer)}>{rightSideComponent}</Col>
          </div>
          <MiniFooter centerAlign noMaxWidth />
        </Col>
      )}
    </>
  );
};

export default SettingsLayout;
