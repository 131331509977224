import * as React from 'react';

import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './FormError.scss';

export interface FormErrorProps {
  formError: string | undefined;
}

const FormError: React.FunctionComponent<FormErrorProps> = ({ formError }) => {
  const t = useTranslations();

  return formError ? (
    <Row constant alignCenter className={styles.error}>
      <Icon icon="warningSign" className={styles.iconPlacing} />
      <Typography className={styles.message} tag="div" msg={t(formError as Messages)} />
    </Row>
  ) : null;
};

export default FormError;
