import classNames from 'classnames';
import React from 'react';

import styles from './Hamburger.scss';

export interface HamburgerProps {
  isOpen: boolean;
  handleClick: () => void;
}

const Hamburger: React.FunctionComponent<HamburgerProps> = ({ isOpen, handleClick }) => {
  return (
    <div className={classNames(styles.hamburger, { [styles.isOpen]: isOpen })} onClick={handleClick}>
      <span />
      <span />
      <span />
    </div>
  );
};

export default Hamburger;
