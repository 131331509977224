import classNames from 'classnames';
import * as React from 'react';

import inputContainerStyles from '@Components/form/inputs/InputContainer/InputContainer.scss';
import Typography from '@Components/Typography';

import styles from './RadioButton.scss';

export interface RadioButtonProps {
  label?: string;
  subLabel?: string;
  className?: string;
  disabled?: boolean;
  value: string;
  checked: boolean;
  name: string;
  onChange: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onlyButton?: boolean;
  isLabelBold?: boolean;
}

const RadioButton: React.FunctionComponent<RadioButtonProps> = (props) => {
  const {
    label,
    subLabel,
    disabled = false,
    value,
    className,
    checked,
    name,
    onChange,
    onlyButton = false,
    onKeyDown,
    isLabelBold,
  } = props;

  return !onlyButton ? (
    <div className={inputContainerStyles.container}>
      <div className={inputContainerStyles.relative}>
        <label className={classNames(styles.checkbox)}>
          <input
            type="radio"
            name={name}
            checked={checked}
            disabled={disabled}
            className={className}
            value={value}
            onChange={onChange}
          />
          <span
            onKeyDown={onKeyDown}
            id={value}
            data-radio-value={value}
            className={classNames(styles.inputBox)}
            //eslint-disable-next-line
            tabIndex={0}
          />
          <div className={styles.labelSpacing}>
            {label && <Typography msg={label} tag="div" size="l" bolder={isLabelBold} />}
            {subLabel && <Typography className={styles.subLabel} msg={subLabel} tag="div" size="m" />}
          </div>
        </label>
      </div>
    </div>
  ) : (
    <div className={inputContainerStyles.container}>
      {/* eslint-disable-next-line */}
      <div className={inputContainerStyles.relative} tabIndex={0}>
        <label className={classNames(styles.radioButtonContainer)}>
          <input
            type="radio"
            name={name}
            checked={checked}
            disabled={disabled}
            className={className}
            value={value}
            onChange={onChange}
          />
          <span
            onKeyDown={onKeyDown}
            id={value}
            data-radio-value={value}
            className={classNames(styles.inputBox)}
            //eslint-disable-next-line
            tabIndex={0}
          />
          <div>
            <div>{label}</div>
            {subLabel && <div className={styles.subLabel}>{subLabel}</div>}
          </div>
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
