import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationDeleteAgentInvitation } from '@Graphql/agent/mutations';
import { queryAgentPortalUserInvitations } from '@Graphql/agent/queries';
import {
  ServiceProviderPortalUserNode,
  UserAccountType,
  DeleteSpInvitationMutation,
  DeleteSpInvitationMutationVariables,
  DeleteProfessionalInvitationMutationVariables,
  DeleteProfessionalInvitationMutation,
  DeleteAgentInvitationMutation,
  DeleteAgentInvitationMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteProfessionalInvitation } from '@Graphql/professional/mutations';
import { mutationDeleteSpInvitation } from '@Graphql/serviceProvidersPortal/mutations';
import {
  queryProfessionalPortalUserInvitations,
  queryServiceProviderUserInvitations,
} from '@Graphql/serviceProvidersPortal/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { removeUserInProvider } from '@Store/provider/provider.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './CaseSummary.scss';

export interface CaseSummaryProps {
  data: any;
  className?: string;
  idx?: number;
  isPending?: boolean;
}

const CaseSummary: React.FunctionComponent<CaseSummaryProps> = ({ data, className, idx, isPending }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const isServiceProviderUser = user?.accountType === UserAccountType.ServiceProvider;

  const editHandler = (userNode: ServiceProviderPortalUserNode) => {
    dispatch(
      showModal({
        type: ModalTypes.addUserModal,
        params: {
          data: {
            userNode,
            firstButtonText: 'Update',
            isPending,
          },
          title: 'Edit user permissions',
          modalSize: PaperWidths.l,
        },
      })
    );
  };
  const [deleteSpInvitationMutation] = useMutation<DeleteSpInvitationMutation, DeleteSpInvitationMutationVariables>(
    mutationDeleteSpInvitation,
    {
      refetchQueries: [getOperationName(queryServiceProviderUserInvitations) || ''],
      onCompleted: () => {
        notificationSuccess(t(Messages.msgDeleteServiceProviderUser));
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [deleteProfInvitationMutation] = useMutation<
    DeleteProfessionalInvitationMutation,
    DeleteProfessionalInvitationMutationVariables
  >(mutationDeleteProfessionalInvitation, {
    refetchQueries: [getOperationName(queryProfessionalPortalUserInvitations) || ''],
    onCompleted: () => {
      notificationSuccess(t(Messages.msgDeleteServiceProviderUser));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [deleteAgentInvitationMutation] = useMutation<
    DeleteAgentInvitationMutation,
    DeleteAgentInvitationMutationVariables
  >(mutationDeleteAgentInvitation, {
    refetchQueries: [getOperationName(queryAgentPortalUserInvitations) || ''],
    onCompleted: () => {
      notificationSuccess('User invite removed');
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const deleteHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => {
              if (data.status !== 'SENT') {
                dispatch(
                  removeUserInProvider.started({
                    id: data.id,
                    successMessage: t(Messages.msgDeleteServiceProviderUser),
                    userAccountType: user?.accountType || UserAccountType.Professional,
                  })
                );
              } else if (user?.accountType === UserAccountType.ServiceProvider) {
                deleteSpInvitationMutation({
                  variables: { input: { id: data.id } },
                });
              } else if (user?.accountType === UserAccountType.Professional) {
                deleteProfInvitationMutation({
                  variables: { input: { id: data.id } },
                });
              } else if (user?.accountType === UserAccountType.Agent) {
                deleteAgentInvitationMutation({
                  variables: { input: { id: data.id } },
                });
              }
            },
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.msgDeleteServiceProviderUser),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const userForm = {
    isAdmin: data.role,
    info: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      id: data.id,
      isMfaEnabled: data.isMfaEnabled,
    },
    serviceProvider: data.serviceProvider,
    id: data.id,
  } as ServiceProviderPortalUserNode;

  const notCurrentUser = data?.email !== user?.email;

  const editPermissions = () => {
    if (isPending) {
      return isServiceProviderUser;
    }
    return true;
  };

  return (
    <Row size={12}>
      <Col
        size={11}
        alignCenter
        key={idx}
        className={classNames(isPending ? styles.pendingUsersContainer : styles.userContainer, className)}
      >
        <Row columnTablet size={12} justifyBetween tabIndex={0}>
          <Col size={isTablet ? 12 : 4}>
            <Row column justifyEnd>
              <Typography msg={`${data.firstName} ${data.lastName}`} tag="div" size="m" bold />
              {isServiceProviderUser && (
                <Row className={styles.gap4} constant alignCenter>
                  <Icon size={IconSizes.sxs} icon="briefcase" />
                  <Typography msg={data.serviceProvider.name} tag="div" size="xs" bolder />
                </Row>
              )}
            </Row>
          </Col>
          <Col size={isTablet ? 12 : 2}>
            <span className={data.role ? styles.adminPanel : styles.regularPanel}>
              <Icon icon={data.role ? 'person-add' : 'person'} className={styles.personIcon} size={IconSizes.ss} />

              <Typography
                msg={data.role ? 'Admin' : 'Regular'}
                tag="span"
                size="s"
                bold
                color={data.role ? 'electricBlue' : 'white'}
                className={styles.roleText}
              />
            </span>
          </Col>
          <Col size={isTablet ? 12 : 4}>
            <Typography msg={data.email} tag="div" size="m" />
          </Col>

          <Col size={isTablet ? 12 : 2}>
            <Row
              className={styles.clickable}
              onClick={() => editHandler(userForm as ServiceProviderPortalUserNode)}
              justifyEnd={!isTablet}
              justifyBetween={isTablet}
              constant
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  editHandler(userForm as ServiceProviderPortalUserNode);
                }
              }}
            >
              {editPermissions() && (
                <Row constant tabIndex={0}>
                  <Icon icon="edit" className={styles.viewNotificationIcon} />

                  <Typography msg="Edit" tag="span" size="mb" bold className={styles.deathDateSpacing} />
                </Row>
              )}

              {isTablet && notCurrentUser && (
                <div onClick={(e: any) => deleteHandler(e)}>
                  <Icon icon="trash" className={styles.clickable} />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      {!isTablet && notCurrentUser && (
        <Col size={1} alignCenter textCenter>
          <div
            onClick={(e: any) => deleteHandler(e)}
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                deleteHandler(e);
              }
            }}
            //eslint-disable-next-line
            tabIndex={0}>
            <Icon icon="trash" className={styles.clickable} />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default CaseSummary;
