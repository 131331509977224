import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './LandingFooter.scss';

export interface LandingFooterProps {
  isRegisterForm?: boolean;
}

const LandingFooter: React.FunctionComponent<LandingFooterProps> = () => {
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.Res768 });
  const t = useTranslations();
  return (
    <footer className={styles.footerWrapper}>
      <Row size={12} className={styles.footerContent}>
        <Col size={9} className={styles.displayFlex}>
          <img width="244" height="52" src="https://lifeledger.com/wp-content/uploads/2021/07/White.svg" alt="" />
        </Col>
        <Col size={3}>
          <div className={styles.lhNormal}>
            <Typography msg="Life Ledger LTD" size="l" tag="div" bolder color="white" />
          </div>
          <div className={styles.textSpacing}>
            <Typography msg="Registered in England and Wales - 11114747" tag="div" color="white" />
          </div>
          <div className={classNames(styles.textSpacing, { [styles.pb24]: isIpadMini })}>
            <Typography msg="The Estate Office, Antony, Cornwall PL11 3AB" tag="div" color="white" />
          </div>
          <div className={styles.elementorPrivacy}>
            <a href="https://lifeledger.com/privacy-policy/" target="_blank" rel="noreferrer">
              <Typography msg={t(Messages.linkPrivacyNotice)} size="m" tag="div" color="white" />
            </a>

            <a href="https://lifeledger.com/terms-conditions/" target="_blank" rel="noreferrer">
              <Typography msg={t(Messages.labelTermsConditionPage)} size="m" tag="div" color="white" />
            </a>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default LandingFooter;
