import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { GenericModalType } from '@Components/Modal/GenericModalBody/GenericModalBody';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { PaperWidths } from '@Components/Paper';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorNode,
  CollaboratorPermission,
  CollaboratorProRelationFe,
  CollaboratorRelationshipFe,
  DeathCaseNode,
  useCollaboratorsQuery,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { addLegal, updateDC } from '@Store/dc/dc.actions';
import { getPermissionSelectOption } from '@Utils/form';
import { manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import { validateEmail, validateName, validatePhone } from '@Utils/validator';

import { RelationshipLevel, RelationShipDefinition } from '../../../../model/Relationship';

import KnownLegals from './KnownLegals/KnownLegals';
import styles from './LegalForm.scss';

export interface LegalFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
}

export interface LegalFormStateProps {
  data: LoadableItem<DeathCaseNode>;
}

export enum LegalFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  hasLegals = 'hasLegals',
  meLegal = 'meLegal',
  permission = 'permission',
  relationship = 'relationship',
}

export interface LegalFormValues {
  [LegalFormFields.firstName]: string;
  [LegalFormFields.lastName]: string;
  [LegalFormFields.email]: string;
  [LegalFormFields.phone]: string;
  [LegalFormFields.hasLegals]: boolean;
  [LegalFormFields.meLegal]: boolean;
  // [LegalFormFields.relationship] : RelationShipDefinition[];
  [LegalFormFields.permission]: { value: string; label: string };
}

type LegalCollaboratorFields = Extract<
  LegalFormFields,
  | LegalFormFields.firstName
  | LegalFormFields.lastName
  | LegalFormFields.email
  | LegalFormFields.phone
  | LegalFormFields.permission
>;

const emptyFormValues = {
  [LegalFormFields.firstName]: '',
  [LegalFormFields.lastName]: '',
  [LegalFormFields.email]: '',
  [LegalFormFields.phone]: '+44',
  [LegalFormFields.permission]: '',
};

interface LegalFormPrivateProps extends LegalFormProps, LegalFormStateProps {}

const LegalForm: React.FunctionComponent<LegalFormPrivateProps> = ({
  data,
  back,
  next,
  setSubpage,
  containerClasses,
}) => {
  const t = useTranslations();

  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const isProfUser = user?.accountType === UserAccountType.Professional;
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [isFormVisible, setFormVisible] = useState(false);
  const [showEmailCaution, setShowEmailCaution] = useState(false);

  const collaboratorsData = useCollaboratorsQuery({
    variables: {
      baseCase: data.item?.id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (collaboratorsData.loading) {
    return (
      <Col size={8} textCenter alignCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const removeNode = collaboratorsData.data?.collaborators.edges.map((x: any) => x.node) || [];

  const lgreps = removeNode.filter(
    (e: any) => e.relationship === CollaboratorProRelationFe.LegalRepresentative || e.isAlsoLegal
  );

  const otherProfAdvisors = removeNode.filter(
    (e: any) => e.relationship === CollaboratorProRelationFe.OtherAdvisor || e.isAlsoOtherAdvisor
  );

  const allProRelations = lgreps.concat(otherProfAdvisors);

  const legalReps = allProRelations.filter((v: any, i: any, a: any) => a.indexOf(v) === i);

  const setNextStep = () => next && setSubpage(next);

  const permissionSelectOptions = Object.values(CollaboratorPermission).map(getPermissionSelectOption(t));

  const getSaveButton = (handleSubmit: () => void, isValid: boolean, values: LegalFormValues) => {
    const disableButtonSave = () => {
      if (!isValid) {
        return values.hasLegals;
      }
      if (isFormVisible) {
        return legalReps.length === 0;
      }
      return false;
    };
    return (
      <Row>
        <Button
          type={ButtonTypes.submit}
          onClick={() => {
            if (isValid) {
              handleSubmit();
              setNextStep();
            } else if (!values.hasLegals) {
              setNextStep();
            }
          }}
          disabled={disableButtonSave()}
          size={ButtonSizes.fill}
          label={t(Messages.buttonNext)}
          isFullWidth
        />
      </Row>
    );
  };
  const userDataValues = {
    [LegalFormFields.firstName]: user?.firstName,
    [LegalFormFields.lastName]: user?.lastName,
    [LegalFormFields.email]: user?.email,
    [LegalFormFields.phone]: user?.phone,
    [LegalFormFields.permission]: permissionSelectOptions.find((ps: any) => ps.value === CollaboratorPermission.Owner),
  };

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm, values } = useFormikContext();
    const { hasLegals, meLegal } = values as LegalFormValues;

    React.useEffect(() => {
      if (!legalReps.length) {
        setFormVisible(hasLegals);
      }
      const lgRepsCount = lgreps.filter((e: CollaboratorNode) => e.email === user?.email);
      if (isProfUser) {
        const getFieldValue = (fieldName: LegalCollaboratorFields) => {
          if (!hasLegals) {
            return emptyFormValues[fieldName];
          }

          return meLegal && lgRepsCount.length === 0 ? userDataValues[fieldName] : emptyFormValues[fieldName];
        };

        const togglePartnerFormField = (fieldName: LegalCollaboratorFields) => {
          setFieldValue(fieldName, getFieldValue(fieldName), false);
          setFieldTouched(fieldName, false, false);
        };

        if (!hasLegals) {
          setFieldValue(LegalFormFields.meLegal, false);
        }

        togglePartnerFormField(LegalFormFields.firstName);
        togglePartnerFormField(LegalFormFields.lastName);
        togglePartnerFormField(LegalFormFields.email);
        togglePartnerFormField(LegalFormFields.phone);
        togglePartnerFormField(LegalFormFields.permission);
      }
    }, [setFieldValue, setFieldTouched, resetForm, hasLegals, meLegal]);

    return null;
  };

  const relationships: RelationShipDefinition[] = [
    // {
    //   label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages),
    //   value: CollaboratorRelationshipFe.Executor,
    //   relationshipType: RelationshipLevel.firstLevel,
    //   selected: false,
    //   index: 0,
    //   infoTitle: manipulateTranslations(t, 'tooltip_title_executor', 'What is an executor?'),
    //   infoMessage: manipulateTranslations(
    //     t,
    //     'tooltip_desc_executor',
    //     "Anyone who makes a will must name an executor. An executor is legally responsible for carrying out the instructions in the person's will and handling their estate (their money, property, and possessions)."
    //   ),
    // },
    // {
    //   label: t(
    //     `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
    //   ),
    //   value: CollaboratorRelationshipFe.WillAdministrator,
    //   relationshipType: RelationshipLevel.firstLevel,
    //   selected: false,
    //   index: 1,
    //   infoTitle: manipulateTranslations(t, 'tooltip_title_administrator', 'What is an administrator?'),
    //   infoMessage: manipulateTranslations(
    //     t,
    //     'tooltip_desc_administrator',
    //     'An administrator is appointed by the courts in the absence of a will. An administrator is legally responsible for handling the estate of the deceased (their money, property, and possessions).'
    //   ),
    // },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.LegalRepresentative,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 0,
      infoTitle: manipulateTranslations(t, 'tooltip_title_legal_representative', 'What is a legal representative?'),
      infoMessage: manipulateTranslations(
        t,
        'tooltip_desc_legal_representative',
        'A legal representative would include, for example, a lawyer, solicitor, probate specialist, or any other legal advisor that is not otherwise listed.'
      ),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.OtherAdvisor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 1,
    },
  ];

  const isCollaboratorDefined = (values: any, relationshipName: any) => {
    return values.relationship.find((x: RelationShipDefinition) => x.value === relationshipName)?.selected;
  };
  const showMeLegal = () => {
    if (data.item?.isAlsoLegal) {
      return !isFormVisible;
    }
    return false;
  };

  const showMeLegalField = () => {
    if (isFormVisible) {
      return !data.item?.isAlsoLegal;
    }
    return true;
  };

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  const permissionWithoutOwner = permissionSelectOptions.filter(
    (pso: any) => pso.value !== CollaboratorPermission.Owner
  );

  const handlePermissionChange = (perm: any, setFieldValue: any) => {
    if (perm.value === CollaboratorPermission.Owner && isProfUser) {
      dispatch(
        showModal({
          type: ModalTypes.genericModal,
          params: {
            data: {
              icon: 'alert-triangle',
              title: t('title_transfer_owner' as Messages),
              bodyText: t('text_transfer_owner' as Messages),
              firstButtonMsg: t('button_transfer_owner' as Messages),
              secondButtonMsg: t('link_not_transfer' as Messages),
              isSecondButtonLink: true,
              genericType: GenericModalType,
              id: '',
              extraParameter: perm,
              onClose: () => setFieldValue(LegalFormFields.permission, emptySelectorValue),
            },
            title: '',
            modalSize: PaperWidths.m581,
          },
        })
      );
    }
  };

  const onFocus = () => setShowEmailCaution(true);

  const onBlur = () => {
    setShowEmailCaution(false);
  };

  return (
    <>
      <Row size={isTablet ? 12 : 10}>
        <CautionBox
          containerClassName={styles.cautionBox}
          info={manipulateTranslations(
            t,
            'label_caution_dc_legal',
            'label_dc_Legal_caution_send_notification ' as Messages
          )}
          title={t(Messages.labelTip)}
        />
      </Row>
      <Formik
        // enableReinitialize
        initialValues={{
          [LegalFormFields.firstName]: '',
          [LegalFormFields.lastName]: '',
          [LegalFormFields.email]: '',
          [LegalFormFields.phone]: '+44',
          [LegalFormFields.permission]: emptySelectorValue,
          [LegalFormFields.hasLegals]: legalReps.length > 0,
          [LegalFormFields.meLegal]: showMeLegal(),
          [LegalFormFields.relationship]: relationshipInitialValue,
        }}
        validationSchema={Yup.object({
          [LegalFormFields.firstName]: validateName(t),
          [LegalFormFields.lastName]: validateName(t),
          [LegalFormFields.email]: validateEmail(t),
          [LegalFormFields.phone]: validatePhone(t),
        })}
        onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const relationshipSelected = values.relationship.filter((x: RelationShipDefinition) => x.selected === true);
          if (relationshipSelected.length > 0) {
            if (!values.meLegal || !isProfUser) {
              dispatch(
                addLegal.started({
                  isAlsoAdministrator: isCollaboratorDefined(values, CollaboratorRelationshipFe.WillAdministrator),
                  isAlsoOtherAdvisor: isCollaboratorDefined(values, CollaboratorProRelationFe.OtherAdvisor),
                  isAlsoExecutor: isCollaboratorDefined(values, CollaboratorRelationshipFe.Executor),
                  isAlsoLegal: isCollaboratorDefined(values, CollaboratorProRelationFe.LegalRepresentative),
                  relationship: values.relationship[0].value,
                  setSubmitting,
                  setStatus,
                  setFormVisible,
                  resetForm,
                  baseCase: id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email.trim(),
                  phone: removeLeadingZeroAndSpaceInContact(values.phone),
                  permission: Object.values(CollaboratorPermission).find(
                    (perms: any) => perms === values.permission.value
                  ),
                })
              );
            } else {
              dispatch(
                updateDC.started({ setSubmitting, setStatus, id: data.item ? data.item?.id : '', isAlsoLegal: true })
              );
            }
          } else {
            setSubmitting(false);
            dispatch(notificationError(t(Messages.msgLegalRelationshipRequired)));
          }
        }}
      >
        {({ isSubmitting, isValid, status, handleSubmit, values, setFieldValue }) => (
          <>
            <Form className={styles.pageWidth}>
              <div className={classNames(containerClasses, styles.legalForm)}>
                <Row justifyBetween>
                  <Col className={styles.bottomSpacing}>
                    <Field
                      name={LegalFormFields.hasLegals}
                      component={ToggleSwitchInput}
                      label="Do you want to add legal representation to this case?"
                      infoMessage={manipulateTranslations(
                        t,
                        'tooltip_desc_legal_representative',
                        'A legal representative would include, for example, a lawyer, solicitor, probate specialist, or any other legal advisor that is not otherwise listed.'
                      )}
                      infoTitle={manipulateTranslations(t, 'tooltip_title_legal_rep', 'What is a legal representative')}
                    />
                  </Col>
                </Row>
                <div className={isIpadMini ? '' : styles.indentFlex}>
                  <div className={isIpadMini ? '' : styles.indentBar} />
                  <div className={styles.fullWidth}>
                    {values.hasLegals && isProfUser && showMeLegalField() && (
                      <Row justifyBetween>
                        <Col className={styles.bottomSpacing}>
                          <Field
                            name={LegalFormFields.meLegal}
                            component={ToggleSwitchInput}
                            label={t('field_is_legal_rep_myself' as Messages)}
                          />
                        </Col>
                      </Row>
                    )}

                    {legalReps.length > 0 && <KnownLegals caseId={data.item?.id} legals={legalReps} />}
                    {isFormVisible && (
                      <GrayPaper width="width-full" className={styles.biggerBottomSpacing}>
                        <Typography msg={`Legal representation ${legalReps.length + 1}`} tag="div" size="m" bold />
                        <GridRow>
                          <GridCol size={5}>
                            <Field
                              name={LegalFormFields.firstName}
                              type="text"
                              component={TextInput}
                              label="First name"
                              required
                            />
                          </GridCol>
                          <GridCol size={5}>
                            <Field
                              name={LegalFormFields.lastName}
                              type="text"
                              component={TextInput}
                              label="Surname"
                              required
                            />
                          </GridCol>
                        </GridRow>
                        <GridRow>
                          <GridCol size={8}>
                            <Field
                              name={LegalFormFields.email}
                              type="text"
                              component={TextInput}
                              label="Email address"
                              required
                              onBlur={onBlur}
                              onFocus={onFocus}
                              onChange={(e: any) => {
                                setFieldValue(LegalFormFields.email, e.target.value);
                              }}
                            />
                          </GridCol>
                        </GridRow>
                        {showEmailCaution && (
                          <GridRow>
                            <GridCol size={8}>
                              <CautionBox
                                containerClassName={styles.cautionBox}
                                info={manipulateTranslations(
                                  t,
                                  'label_email_caution',
                                  "We'll be sending an email to this individual, inviting them to make an account so they can see the information you've given."
                                )}
                              />
                            </GridCol>
                          </GridRow>
                        )}
                        <GridRow className={styles.biggerBottomSpacing}>
                          <GridCol size={8}>
                            <Field
                              name={LegalFormFields.phone}
                              type="text"
                              component={TextInput}
                              label="Phone number"
                              required
                            />
                          </GridCol>
                        </GridRow>
                        {/* Adding level of access  */}
                        <GridRow>
                          <GridCol size={9} className={styles.biggerBottomSpacing}>
                            <Field
                              name={LegalFormFields.permission}
                              label="Level of access"
                              component={SelectInput}
                              onSelectChange={(e: any) => handlePermissionChange(e, setFieldValue)}
                              options={values.meLegal || isProfUser ? permissionSelectOptions : permissionWithoutOwner}
                              selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                              // disabled={values.meLegal}
                              required
                            />
                          </GridCol>
                        </GridRow>

                        <GridRow>
                          <GridCol size={10}>
                            <Field
                              name={LegalFormFields.relationship}
                              options={relationships}
                              type="text"
                              component={MultiSelect}
                              label={t('label_field_relationship_legal' as Messages)}
                              controlDescription="You can choose more than one"
                            />
                          </GridCol>
                        </GridRow>

                        <GridRow>
                          <GridCol size={5}>
                            <Button
                              type={ButtonTypes.submit}
                              loading={isSubmitting}
                              label="Add to case"
                              icon="plus"
                              iconSize={IconSizes.ss}
                              constant
                            />
                          </GridCol>
                        </GridRow>
                      </GrayPaper>
                    )}
                  </div>
                </div>
                {!isFormVisible && legalReps.length > 0 && (
                  <Row justifyCenter>
                    <Button
                      label="Add another"
                      icon="person-add"
                      iconSize={IconSizes.ss}
                      style={ButtonStyles.transparent}
                      onClick={() => setFormVisible(true)}
                      constant
                    />
                  </Row>
                )}
              </div>
              <AutoToggleForm />
              <FormError formError={status} />
              <NavigationBlock
                isSubmitting={false}
                back={back}
                next={next}
                setSubpage={setSubpage}
                SaveButton={getSaveButton(handleSubmit, isValid, values)}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default LegalForm;
