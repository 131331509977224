import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { LocalStorage, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { hideModal, showModal } from '@Store/app/app.actions';
import { setToLocalStorage } from '@Utils/localStorage';

import styles from './Footer.scss';

export interface FooterProps {
  isRegisterForm?: boolean;
}

const Footer: React.FunctionComponent<FooterProps> = ({ isRegisterForm }) => {
  // const fbIcon = <FontAwesomeIcon icon={faFacebook} size="2x" className={styles.iconWidths} color="white" />;
  const linkedIcon = <FontAwesomeIcon icon={faLinkedin} size="2x" className={styles.iconWidths} color="white" />;
  const t = useTranslations();
  const isTabletMax = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Res768 });
  const dispatch = useDispatch();

  const margindifference = isTabletMax ? '530px' : '0px';

  const cookieSettingsHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.cookieSettingsFormModal,
        params: {
          data: {
            onClose: () => {
              onAccepAllClick();
            },
            buttonText: 'Save Settings',
          },
          // title: t(Messages.titleCookieSettings),
          subtitle: '',
          modalSize: PaperWidths.mm,
          fixedFormHeight: false,
          changeModalPosition: true,
          noPadding: true,
          isNotificationModal: true,
        },
      })
    );
  };

  const onAccepAllClick = () => {
    setToLocalStorage(LocalStorage.enablePerformanceCookie, true);
    setToLocalStorage(LocalStorage.cookieConsent, true);
    dispatch(hideModal({}));
  };

  return (
    <div>
      <footer className={styles.footerWrapper} style={{ marginTop: isRegisterForm ? margindifference : '0px' }}>
        <section className={styles.footerContent}>
          <div className={styles.elementorContainer}>
            <div className={styles.elementorCol20}>
              <div className={styles.elementorWidgetWrap}>
                <div className={styles.ledgerIcon}>
                  <a href="https://lifeledger.com/">
                    <img
                      width="179"
                      height="38"
                      src="https://lifeledger.com/wp-content/uploads/2021/07/White.svg"
                      alt=""
                    />
                  </a>
                </div>
                <div className={isTablet ? styles.hide : styles.elementorAddress}>
                  <div className={styles.textSpacing}>
                    <Typography msg="Life Ledger LTD" size="s" tag="div" bold color="white" />
                  </div>
                  <div className={styles.textSpacing}>
                    <Typography msg="Registered in England and Wales - 11114747" size="s" tag="div" color="white" />
                  </div>
                  <div className={styles.textSpacing}>
                    <Typography msg="The Estate Office, Antony, Cornwall PL11 3AB" size="s" tag="div" color="white" />
                  </div>
                </div>
                <div className={isTablet ? styles.hide : styles.border} />
                <div className={styles.elementorPrivacy}>
                  <div className={styles.mb10}>
                    <a href="https://lifeledger.com/privacy-policy/" target="_blank" rel="noreferrer">
                      <Typography msg={t(Messages.linkPrivacyNotice)} size="s" tag="div" color="white" />
                    </a>
                  </div>
                  <div>
                    <a href="https://lifeledger.com/terms-conditions/" target="_blank" rel="noreferrer">
                      <Typography msg={t(Messages.labelTermsConditionPage)} size="s" tag="div" color="white" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.elementorCol15}>
              <div className={styles.textSpacing}>
                <a href="https://lifeledger.com/service-providers/" target="_blank" rel="noreferrer">
                  <Typography msg="For service providers" size="s" tag="div" color="white" />
                </a>
              </div>
              <div className={styles.textSpacing}>
                <a href="https://lifeledger.com/sector-professionals/" target="_blank" rel="noreferrer">
                  <Typography msg="For sector professionals" size="s" tag="div" color="white" />
                </a>
              </div>
              <div className={isTablet ? styles.hide : styles.socialIcon}>
                <div>
                  <a href="https://www.linkedin.com/company/life-ledger" target="_blank" rel="noreferrer">
                    {linkedIcon}
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.elementorHelp}>
              <div className={styles.textSpacing}>
                <a href="https://lifeledger.com/resources" target="_blank" rel="noreferrer">
                  <Typography msg="Resources" size="s" tag="div" color="white" />
                </a>
              </div>
              <div className={styles.textSpacing}>
                <a href="https://lifeledger.com/faq" target="_blank" rel="noreferrer">
                  <Typography msg="FAQs" size="s" tag="div" color="white" />
                </a>
              </div>
              <div className={styles.textSpacing}>
                <a href="https://lifeledger.com/contact" target="_blank" rel="noreferrer">
                  <Typography msg="Contact" size="s" tag="div" color="white" />
                </a>
              </div>
              <div className={styles.textSpacing}>
                <a href="https://lifeledger.com/cookies" target="_blank" rel="noreferrer">
                  <Typography msg="Cookie policy" size="s" tag="div" color="white" />
                </a>
              </div>
              <div
                onClick={() => cookieSettingsHandler()}
                className={classNames(styles.textSpacing, styles.handSymbol)}
              >
                <Typography msg="Cookie preferences" size="s" tag="div" color="white" />
              </div>
              <div className={isTablet ? styles.hide : styles.socialIcon}>
                <div>
                  <img
                    width="100"
                    height="24"
                    src="https://lifeledger.com/wp-content/uploads/2021/07/ce-logo-1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className={isTablet ? styles.socialIcon : styles.hide}>
              <div>
                <a href="https://www.linkedin.com/company/life-ledger" target="_blank" rel="noreferrer">
                  {linkedIcon}
                </a>
              </div>
              <div>
                <img
                  width="84"
                  height="24"
                  src="https://lifeledger.com/wp-content/uploads/2021/07/ce-logo-1.png"
                  alt=""
                />
              </div>
            </div>
            <div className={isTablet ? styles.elementorAddress : styles.hide}>
              <div className={styles.textSpacing}>
                <Typography msg="Life Ledger LTD" size="s" tag="div" bold color="white" />
              </div>
              <div className={styles.textSpacing}>
                <Typography msg="Registered in England and Wales - 11114747" size="s" tag="div" color="white" />
              </div>
              <div className={styles.textSpacing}>
                <Typography msg="The Estate Office, Antony, Cornwall PL11 3AB" size="s" tag="div" color="white" />
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Footer;
