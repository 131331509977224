import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import { Links } from '@Config/constants';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import DeathCasePage from '@Routes/deathCase/DeathCasePage';
import { getUser } from '@Store/auth/auth.selector';
import { clearDC, fetchDC } from '@Store/dc/dc.actions';

export interface EditDeathCaseProps {}

export interface EditDeathCaseDispatchProps {}

interface EditDeathCasePrivateProps extends EditDeathCaseProps, EditDeathCaseDispatchProps {}

const EditDeathCase: React.FunctionComponent<EditDeathCasePrivateProps> = () => {
  const { id, subpage, missing } = useParams();

  const user = useSelector(getUser);
  const userAccountType = user?.accountType;
  const isUserProfessional = userAccountType === UserAccountType.Professional;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchDC.started({ id }));
    return () => {
      dispatch(clearDC());
    };
  }, [id, dispatch]);

  if (!id) {
    return <Redirect to={Links.home} />;
  }

  return <DeathCasePage preferred={subpage} id={id} missing={missing} isUserProfessional={isUserProfessional} />;
};

export default EditDeathCase;
