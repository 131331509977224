import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useParams } from 'react-router';

import CautionBox from '@Components/application/CautionBox';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { alphabets, EnterKeyCode, Links, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CreateObSessionMutation,
  CreateObSessionMutationVariables,
  useGetNordBanksQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationCreateObSession } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { hideModal, showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import headerStyles from '../../../../components/Header/Header.scss';

import styles from './Institutions.scss';

export interface InstitutionsProps {}

const Institutions: React.FunctionComponent<InstitutionsProps> = () => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const { id } = useParams();
  const history = useHistory();
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.Res769 });
  const isIpadMiniLess = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [banks, setBanks] = React.useState<any[]>([]);

  const nordigeNInstitutes = useGetNordBanksQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { country: 'GB' },
  });

  const [createObSession, { loading }] = useMutation<CreateObSessionMutation, CreateObSessionMutationVariables>(
    mutationCreateObSession,
    {
      onCompleted: (resp: any) => {
        window.location.href = resp.createObSession.sessionUrl;
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
        dispatch(hideModal({}));
      },
    }
  );

  if (loading) {
    loadingHandler(
      dispatch,
      'logo-full',
      t('title_leaving_ll' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_banks' as Messages),
      'internet banking'
    );
  }

  if (nordigeNInstitutes.loading) {
    return <LoadingSpinner />;
  }

  const getCompanies = () => {
    return alphabets.map((char: any) => {
      const bankInstitutes = nordigeNInstitutes.data?.getNordBanks.filter(
        (inst: any) => Array.from(inst.name.toUpperCase())[0] === char
      );

      return {
        label: bankInstitutes && bankInstitutes?.length > 0 ? char : '',
        options:
          bankInstitutes &&
          bankInstitutes.map((bank: any) => {
            return {
              institutionId: bank.institutionId,
              name: bank.name,
              logo: bank.logo,
              restrictedInfo: bank.restrictedInfo,
              transactionalHistoryDays: bank.transactionalHistoryDays,
              infoField: bank.infoField,
            };
          }),
      };
    });
  };

  const searchCompanies = (query: string) => {
    const filteredInstitutes = nordigeNInstitutes.data?.getNordBanks.filter((c: any) =>
      c.name.toLowerCase().includes(query)
    );
    const searched = alphabets.map((char: any) => {
      const buddies =
        filteredInstitutes && filteredInstitutes.filter((inst: any) => Array.from(inst.name.toUpperCase())[0] === char);

      return {
        label: buddies && buddies?.length > 0 ? char : '',
        options:
          buddies &&
          buddies.map((bank: any) => {
            return {
              institutionId: bank.institutionId,
              name: bank.name,
              logo: bank.logo,
            };
          }),
      };
    });
    setBanks(searched);
  };

  const companies = banks.length > 0 ? banks : getCompanies();

  const spacingStyle = (alphaChars: any) => {
    return alphaChars.length > 0 ? styles.alphabet : '';
  };

  return (
    <div className={styles.mainContainer}>
      {!isMobile && (
        <Row
          tabIndex={0}
          onClick={() => history.push(generatePath(Links.lcReady, { id }))}
          justifyCenter
          className={classNames(headerStyles.logoContainer, styles.mt30, styles.handSymbol)}
        >
          <Icon icon="logo-full" size={IconSizes.logoFull} />
        </Row>
      )}
      <div className={styles.whiteBoxContainer}>
        <div className={classNames(styles.successModal)}>
          {isMobile && (
            <>
              <Row
                constant
                onClick={() => history.push(generatePath(Links.lcReady, { id }))}
                alignCenter
                className={styles.backFlex}
                tabIndex={0}
                onKeyDown={(e: any) => {
                  if (e.keyCode === EnterKeyCode) {
                    history.push(generatePath(Links.lcReady, { id }));
                  }
                }}
              >
                <Icon className={styles.backButtonSpacing} icon="chevronLeft" />
                <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
              </Row>

              <Row
                tabIndex={0}
                onClick={() => history.push(generatePath(Links.lcReady, { id }))}
                justifyCenter
                constant
                className={classNames(headerStyles.logoContainer, styles.mt30, styles.handSymbol)}
              >
                <Icon icon="logo-full" size={IconSizes.logoFull} />
              </Row>
            </>
          )}
          <Row
            justifyCenter={isMobile}
            alignCenter
            constant
            size={isIpadMini && !isMobile ? 10 : 12}
            className={styles.mb10}
          >
            {!isMobile && (
              <Col constant size={5}>
                <Row
                  constant
                  onClick={() => history.push(generatePath(Links.lcReady, { id }))}
                  alignCenter
                  className={styles.backFlex}
                  //eslint-disable-next-line
                  tabIndex={0}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      history.push(generatePath(Links.lcReady, { id }));
                    }
                  }}
                >
                  <Icon className={styles.backButtonSpacing} icon="chevronLeft" />
                  <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
                </Row>
              </Col>
            )}
            <Col size={isIpadMini && !isMobile ? 5 : 4}>
              <Typography textCenter={isIpadMiniLess} msg="Choose your bank" tag="h4" bolder size="xl" />
            </Col>
            {!isIpadMini && (
              <Col className={styles.relPosition} size={3}>
                <input
                  type="text"
                  onChange={(e) => searchCompanies(e.currentTarget.value)}
                  className={styles.input}
                  autoComplete="off"
                  placeholder="Search for bank"
                />
                <Icon icon="search" className={styles.visibilityToggleIcon} size={IconSizes.s} />
              </Col>
            )}
          </Row>

          {isIpadMini && (
            <Row constant className={styles.mb24} size={12} justifyCenter>
              <Col constant size={isMobile ? 12 : 7} className={styles.relPosition}>
                <input
                  type="text"
                  onChange={(e) => searchCompanies(e.currentTarget.value)}
                  className={styles.input}
                  autoComplete="off"
                  placeholder="Search for bank"
                />
                <Icon icon="search" className={styles.visibilityToggleIcon} size={IconSizes.s} />
              </Col>
            </Row>
          )}

          <CautionBox containerClassName={styles.cautionBox} info={t('msg_nordigen_banks_info' as Messages)} />

          <Typography className={styles.mb12} tag="div" size="l" bold msg={t('title_popular_banks' as Messages)} />
          <div className={classNames(styles.institueContainer, styles.mb32)}>
            {nordigeNInstitutes.data?.getNordBanks
              .filter((nNi: any) => nNi.isPopular)
              .map((company: any, idx: number) => (
                <Row key={idx} className={styles.instituteSection} column>
                  <div
                    key={idx}
                    onClick={() => {
                      // createObSession({
                      //   variables: {
                      //     input: {
                      //       baseCase: id,
                      //       institutionId: company.institutionId,
                      //     },
                      //   },
                      // })
                      dispatch(
                        showModal({
                          type: ModalTypes.obBanksNoSupport,
                          params: {
                            title: '',
                            modalSize: PaperWidths.m672,
                            noPadding: true,
                            data: {
                              selectedInstitute: company,
                              baseCase: id,
                            },
                          },
                        })
                      );
                    }}
                    className={styles.instituteBox}
                    //eslint-disable-next-line
                    tabIndex={0}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        createObSession({
                          variables: {
                            input: {
                              baseCase: id,
                              institutionId: company.institutionId,
                            },
                          },
                        });
                      }
                    }}
                  >
                    {company.logo ? (
                      <img src={company.logo} alt="" className={styles.profLogo} />
                    ) : (
                      <div className={styles.noLogoContainer}>{company.name.charAt(0).toUpperCase()}</div>
                    )}
                  </div>
                  <Typography msg={company.name} tag="div" size="m" />
                </Row>
              ))}
          </div>

          <Typography tag="div" size="l" bold msg={t('title_all_banks' as Messages)} />
          {companies &&
            companies.map((characters: any) => {
              return (
                <>
                  <Typography
                    className={characters.label !== 'A' ? spacingStyle(characters.options) : styles.mt24}
                    msg={characters.label}
                    tag="div"
                    bold
                    size="l"
                    color="footerColor"
                  />
                  <div className={styles.institueContainer}>
                    {characters.options.map((company: any, idx: number) => (
                      <Row key={idx} className={styles.instituteSection} column>
                        <div
                          key={idx}
                          onClick={() =>
                            dispatch(
                              showModal({
                                type: ModalTypes.obBanksNoSupport,
                                params: {
                                  title: '',
                                  modalSize: PaperWidths.m672,
                                  noPadding: true,
                                  data: {
                                    selectedInstitute: company,
                                    baseCase: id,
                                  },
                                },
                              })
                            )
                          }
                          className={styles.instituteBox}
                          //eslint-disable-next-line
                          tabIndex={0}
                          onKeyDown={(e: any) => {
                            if (e.keyCode === EnterKeyCode) {
                              createObSession({
                                variables: {
                                  input: {
                                    baseCase: id,
                                    institutionId: company.institutionId,
                                  },
                                },
                              });
                            }
                          }}
                        >
                          {company.logo ? (
                            <img src={company.logo} alt="" className={styles.profLogo} />
                          ) : (
                            <div className={styles.noLogoContainer}>{company.name.charAt(0).toUpperCase()}</div>
                          )}
                        </div>
                        <Typography msg={company.name} tag="div" size="m" />
                      </Row>
                    ))}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Institutions;
