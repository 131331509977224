import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Header from '@Components/application/Header';
import NewsLetters from '@Components/application/NewsLetters';
import Button, { ButtonTypes } from '@Components/Button';
import Footer from '@Components/Footer';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import Title from '@Components/Title';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';
import { AuthSuggestions } from '@Routes/auth/shared/SuggestionBlock/SuggestionBlock';
import { sendAccessCode } from '@Store/provider/provider.actions';
import { validateRequired } from '@Utils/validator';

import NotificationsPage from '../NotificationsPage';

import styles from './ProviderDCInfoForm.scss';

export interface ProviderDCInfoFormProps {
  isChat?: boolean;
}

enum ProviderDCInfoFormFields {
  accessCode = 'accessCode',
}

const ProviderDCInfoForm: React.FunctionComponent<ProviderDCInfoFormProps> = ({ isChat }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [dcInfo, setDcInfo] = useState(null);

  const CodeInputForm = () => (
    <>
      <Header showHamBurger={false} showSideBar={false} />
      <main>
        <Container>
          <div className={styles.registerFormlayout}>
            <Title text="Notification of a death" />

            <Typography
              color="black"
              size="l"
              tag="div"
              msg="Please copy the access code from the notification email"
              family="helvetica"
              className={styles.subtitleSpacing}
            />

            <Formik
              initialValues={{
                [ProviderDCInfoFormFields.accessCode]: '',
              }}
              validationSchema={Yup.object({
                [ProviderDCInfoFormFields.accessCode]: validateRequired(t),
              })}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                dispatch(
                  sendAccessCode.started({ setSubmitting, setStatus, setDcInfo, accessCode: values.accessCode.trim() })
                );
              }}
            >
              {({ isSubmitting, status }) => (
                <>
                  <Form>
                    <Row size={12}>
                      <Field
                        name={ProviderDCInfoFormFields.accessCode}
                        type="text"
                        component={TextInput}
                        label={t(Messages.labelAccessCode)}
                        placeholder="281739-18747292-93278"
                        fullWidth
                        required
                      />
                    </Row>
                    <Row alignCenter justifyCenter className={styles.buttonWrapper}>
                      <Button
                        type={ButtonTypes.submit}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        label="Access death notification"
                      />
                    </Row>
                    <FormError formError={status} />
                  </Form>
                </>
              )}
            </Formik>
            <div className={styles.signUpContainer}>
              <SuggestionBlock authType={AuthSuggestions.register} suggestLogin={true} />
            </div>
          </div>
          <NewsLetters />
        </Container>
      </main>
      <Footer />
    </>
  );

  return !dcInfo ? <CodeInputForm /> : <NotificationsPage isChat={isChat} dcInfo={dcInfo} />;
};

export default ProviderDCInfoForm;
