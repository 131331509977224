import { Dispatch, SetStateAction } from 'react';

import {
  ChangePasswordInput,
  LoginMutationVariables,
  RegisterUserInput,
  ResetPasswordInput,
  VerifyUserKeyMutationInput,
} from '@Graphql/graphqlTypes.generated';
import { FormikSagaProps } from '@Utils/types';

export enum VerifySteps {
  progress = 'progress',
  success = 'success',
  failure = 'failure',
}

export interface LoginActionPayload extends LoginMutationVariables, FormikSagaProps {
  setShowOtpBox?: Dispatch<SetStateAction<boolean>>;
  setQrCodeUrl?: Dispatch<SetStateAction<string>>;
  queryString?: string;
}

export interface LoginActionSuccess {
  params: LoginActionPayload;
  result: UserAuth;
}

export interface RegisterActionPayload extends RegisterUserInput, FormikSagaProps {
  setEmailSentTo?: (email: string) => void;
  isGuidanceRight?: boolean;
}

export interface VerifyEmailActionPayload extends VerifyUserKeyMutationInput {
  setStep: Dispatch<SetStateAction<VerifySteps>>;
}

export interface ForgotPasswordActionPayload extends FormikSagaProps {
  email: string;
  setEmailSentTo: (email: string) => void;
}

export interface ResendEmailActionPayload extends FormikSagaProps {
  email: string;
}

export interface ResendVerificationActionPayload extends FormikSagaProps {
  email: string;
}

export interface ResetPasswordActionPayload extends ResetPasswordInput, FormikSagaProps {
  setPasswordChanged: Dispatch<SetStateAction<boolean>>;
  isGuidanceRight?: boolean;
}

export interface ChangePasswordActionPayload extends ChangePasswordInput, FormikSagaProps {
  resetForm: () => void;
  successMessage: string;
}

export type UserAuth = {
  token: string;
};

export type Entity = {
  id: string;
};
