import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import CautionBox from '@Components/application/CautionBox';
import FileInput from '@Components/form/inputs/FileInput';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  DeathCaseNode,
  DocumentDocumentType,
  LifeCaseNode,
  Permissions,
  useCollaboratorsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { updateDC, uploadDocuments } from '@Store/dc/dc.actions';
import { updateLC } from '@Store/lc/lc.actions';
import { manipulateTranslations } from '@Utils/helpers';
import { deleteConfirmationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import ExecutorsForm from '../ExecutorsForm';
import WillAdminForm from '../WillAdminForm';

import styles from './WillForm.scss';

export interface WillFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
  isUserProfessional?: boolean;
}

export interface WillFormStateProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
}

interface WillFormPrivateProps extends WillFormProps, WillFormStateProps {}

enum WillFormFields {
  hasWill = 'hasWill',
  isProfessional = 'isProfessional',
  willVisibility = 'willVisibility',
}

export interface AdminFormValues {
  [WillFormFields.hasWill]: boolean;
}

export enum WillVisibilityType {
  OnlyMe = 'ONLYME',
  MeAndAllCollaborators = 'MEANDALLCOLLABORATORS',
}

const WillForm: React.FunctionComponent<WillFormPrivateProps> = ({
  data,
  back,
  next,
  setSubpage,
  containerClasses,
  isUserProfessional,
  isLifeCase,
}) => {
  // const currentLanguage = useSelector(getCurrentLanguage);
  const t = useTranslations();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const collaboratorsData = useCollaboratorsQuery({
    variables: {
      baseCase: data.item?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const removeWillHandler = deleteConfirmationHandler(t, dispatch, DocumentDocumentType.RepresentationLetter);

  // const legalApplicable = !(isUserProfessional && user?.professionalType === UserProfessionalType.Solicitor);

  const setNextStep = () => (next ? setSubpage(next) : {});

  const willFiles = data.item ? data.item.wills : [];

  const showExecutorsForm = (willControlSelected: boolean) => {
    if (isLifeCase || willControlSelected) {
      return true;
    }
    return false;
  };

  const willVisibilityOptions = () => {
    return [
      { label: t('label_will_visibility_item_ONLYME' as Messages), value: WillVisibilityType.OnlyMe },
      {
        label: t('label_will_visibility_item_MEANDALLCOLLABORATORS' as Messages),
        value: WillVisibilityType.MeAndAllCollaborators,
      },
    ];
  };

  const label_caution_lc_will = manipulateTranslations(
    t,
    'label_caution_lc_will',
    'Completing this section is not essential to send notifications. However, we recommend finishing it as banks use this information to distribute funds accordingly'
  );

  const label_caution_dc_will = manipulateTranslations(
    t,
    'label_caution_dc_will',
    'Completing this section is not essential to send notifications. However, we recommend finishing it as banks use this information to distribute funds accordingly'
  );

  const tooltip_info_will_question = manipulateTranslations(
    t,
    'tooltip_info_will_question',
    'If there is a will, an executor will be named in the document.</br></br>If there is no will,  an administrator will be appointed by the courts.'
  );

  const getCollaboratorDetails = () => {
    const collab = collaboratorsData.data?.collaborators.edges.find((cb: any) => cb.node.email === user?.email);
    return collab ? collab.node.id : undefined;
  };

  return (
    <>
      <Row className={classNames(styles.notesBottomSpacing, styles.pageWidth)}>
        <CautionBox
          containerClassName={styles.cautionBox}
          info={isLifeCase ? label_caution_lc_will : label_caution_dc_will}
          title={t(Messages.labelTip)}
        />
      </Row>
      <Formik
        initialValues={{
          [WillFormFields.hasWill]: data.item?.hasWill || false,
          [WillFormFields.isProfessional]: isUserProfessional,
          [WillFormFields.willVisibility]:
            data.item && data.item?.wills.length > 0 ? data.item.wills[0].collaboratorsView : '',
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          if (data?.item?.__typename === NodeDefiner.DeathCase) {
            if (values.hasWill) {
              dispatch(
                updateDC.started({
                  setSubmitting,
                  setStatus,
                  setNextStep,
                  id,
                  hasWill: values.hasWill,
                })
              );
            } else {
              dispatch(
                updateDC.started({
                  setSubmitting,
                  setStatus,
                  setNextStep,
                  id,
                  isAlsoAdministrator: false,
                  hasWill: false,
                })
              );
            }
          } else if (willFiles.length > 0) {
            dispatch(
              updateLC.started({
                setSubmitting,
                setStatus,
                setNextStep,
                id,
                hasWill: values.hasWill,
              })
            );
          } else {
            setNextStep();
          }
        }}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={styles.pageWidth}>
            <div className={containerClasses}>
              <Row justifyBetween>
                <Col size={12}>
                  <Field
                    name={WillFormFields.hasWill}
                    label={isLifeCase ? 'Is there a will?' : t(Messages.labelHasWill)}
                    component={ToggleSwitchInput}
                    infoMessage={!isLifeCase ? tooltip_info_will_question : undefined}
                    infoTitle={
                      !isLifeCase ? manipulateTranslations(t, 'label_did_you_know', 'Did you know?') : undefined
                    }
                  />
                </Col>
              </Row>

              {values.hasWill && (
                <div className={styles.flexGap}>
                  {!isMobile && isLifeCase && <div className={styles.bar} />}

                  <div className={styles.rightDiv}>
                    {isLifeCase && (
                      <Row justifyBetween>
                        <Col size={12} tabindex={0}>
                          <Field
                            name={WillFormFields.willVisibility}
                            options={willVisibilityOptions()}
                            component={RadioButton}
                            label={t('field_will_visibility' as Messages)}
                          />
                        </Col>
                      </Row>
                    )}
                    <Typography msg="Please upload a copy of the will." size="l" tag="div" />
                    <Typography
                      msg="If you do not have it yet, you can skip this step and come back to it later"
                      size="m"
                      tag="div"
                      color="footerColor"
                      className={styles.notesBottomSpacing}
                    />

                    <div>
                      <FileInput
                        onDrop={
                          (files: File[]) =>
                            dispatch(
                              uploadDocuments.started({
                                baseCaseId: id,
                                documentType: DocumentDocumentType.Will,
                                files,
                                collaboratorsView: values.willVisibility === WillVisibilityType.MeAndAllCollaborators,
                                collaboratorId: getCollaboratorDetails(),
                              })
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        onRemoveClick={removeWillHandler}
                        files={willFiles || []}
                        isReadOnlyUser={data.item?.myPermission === Permissions.ReadOnly}
                        isLifeCase={isLifeCase}
                      />
                    </div>
                  </div>
                </div>
              )}
              {showExecutorsForm(values.hasWill) && <ExecutorsForm data={data} user={user} isLifeCase={isLifeCase} />}
              {!isLifeCase && !values.hasWill && <WillAdminForm data={data} user={user} isLifeCase={isLifeCase} />}
            </div>

            <FormError formError={status} />
            <NavigationBlock isSubmitting={isSubmitting} back={back} next={next} setSubpage={setSubpage} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WillForm;
