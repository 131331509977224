import typescriptFsa from 'typescript-fsa';

import {
  CaseProviderCreateMutationPayload,
  SendAccessCodeActionPayload,
  SendReferenceCodeActionPayload,
  SendNewRequestPayLoad,
  ProfessionalAccessCodeActionPayload,
  CaseDocumentAccessRequestMutationPayload,
  PortalUserDeleteMutationPayload,
  DeleteDataDefinitionMutationPayload,
  CaseProviderUpdateMutationPayload,
} from '@Store/provider/provider.types';

const actionCreator = typescriptFsa('@PROVIDER');

export const sendAccessCode = actionCreator.async<SendAccessCodeActionPayload, {}>('SEND_ACCESS_CODE');
export const professionalAccessCode = actionCreator.async<ProfessionalAccessCodeActionPayload, {}>(
  'PROFESSIONAL_ACCESS_CODE'
);
export const sendReferenceCode = actionCreator.async<SendReferenceCodeActionPayload, {}>('SEND_REFERENCE_CODE');
export const createCaseProvider = actionCreator.async<CaseProviderCreateMutationPayload, {}>('CREATE_PROVIDER');
export const updateCaseProvider = actionCreator.async<CaseProviderUpdateMutationPayload, {}>('UPDATE_PROVIDER');
export const sendNewProvName = actionCreator.async<SendNewRequestPayLoad, {}>('SEND_PROVIDER');
export const caseDocAccessRequest = actionCreator.async<CaseDocumentAccessRequestMutationPayload, {}>(
  'CASE_DOCUMENT_REQUEST'
);

export const removeUserInProvider = actionCreator.async<PortalUserDeleteMutationPayload, {}>('REMOVE_USER_IN_PROVIDER');

export const removeDataDefinition = actionCreator.async<DeleteDataDefinitionMutationPayload, {}>(
  'REMOVE_DATA_DEFINITION'
);

export const chatUploadFiles = actionCreator<{}>('CHAT_UPLOAD_FILES');
