import * as React from 'react';
import { useSelector } from 'react-redux';

import Icon from '@Components/Icon';
import NavigationLayout from '@Components/layout/NavigationLayout/NavigationLayout';
import Row from '@Components/layout/Row';
import { NavigationConfig, SubpageConfig } from '@Components/NavigationBlock/NavigationBlock';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import Typography from '@Components/Typography';
import { LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { CaseServiceProviderNode, UserVerificationStatus } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { getBooleanValueCookie } from '@Utils/localStorage';

import SupportingDocuments from '../SupportingDocuments/SupportingDocuments';
import styles from '../TransUnionSteps/TransUnionSteps.scss';
import { IdDocunentSubpages } from '../TransUnionSteps/transUnionSubPages';
import VerificationStatus from '../VerificationStatus';

export interface IdDocumentStepsProps {
  preferred?: IdDocunentSubpages;
  fromNotification?: boolean;
  deathCaseServiceProviderId?: string;
  deathCaseServiceProvider?: CaseServiceProviderNode;
  submitAction?: string;
}

const RightPanelForms: SubpageConfig<IdDocunentSubpages> = {
  // [TransUnionSubpages.DecideComponent]: TransUnionProcedure,
  // [TransUnionSubpages.Summary]: TransUnionSummary,
  [IdDocunentSubpages.SupportingDocuments]: SupportingDocuments,
  [IdDocunentSubpages.VerficationStatus]: VerificationStatus,
};

const IdDocumentSteps: React.FunctionComponent<IdDocumentStepsProps> = ({
  preferred,
  fromNotification,
  deathCaseServiceProviderId,
  submitAction,
  deathCaseServiceProvider,
}) => {
  const user = useSelector(getUser);
  const t = useTranslations();

  const tuCreationNavigation: NavigationConfig<IdDocunentSubpages> = {
    // [TransUnionSubpages.DecideComponent]: {
    //   back: null,
    //   next: TransUnionSubpages.Summary,
    // },
    // [TransUnionSubpages.Summary]: {
    //   back: TransUnionSubpages.DecideComponent,
    //   next: TransUnionSubpages.SupportingDocuments,
    // },
    [IdDocunentSubpages.SupportingDocuments]: {
      back: null,
      next: IdDocunentSubpages.VerficationStatus,
    },
    [IdDocunentSubpages.VerficationStatus]: { back: IdDocunentSubpages.SupportingDocuments, next: null },
  };

  function chooseSubPage(subPagePreferred: string) {
    let subPageName = IdDocunentSubpages.SupportingDocuments;
    switch (subPagePreferred) {
      // case TransUnionSubpages.DecideComponent:
      //   subPageName = TransUnionSubpages.DecideComponent;
      //   break;
      // case TransUnionSubpages.Summary:
      //   subPageName = TransUnionSubpages.Summary;
      //   break;
      case IdDocunentSubpages.SupportingDocuments:
        subPageName = IdDocunentSubpages.SupportingDocuments;
        break;
      case IdDocunentSubpages.VerficationStatus:
        subPageName = IdDocunentSubpages.VerficationStatus;
        break;
      default:
        break;
    }
    return subPageName;
  }

  const defaultSubPage = () => {
    const firstFail = getBooleanValueCookie(LocalStorage.idVerificationFirstFail);
    if (
      user?.verificationStatus === UserVerificationStatus.Verified ||
      (user?.verificationStatus === UserVerificationStatus.NeedsReview && !firstFail)
    ) {
      return IdDocunentSubpages.VerficationStatus;
    }
    return IdDocunentSubpages.SupportingDocuments;
  };

  const chosenSubpage =
    preferred && Object.values(IdDocunentSubpages).includes(chooseSubPage(preferred)) ? preferred : defaultSubPage();

  const [subpage, setSubpage] = React.useState(chosenSubpage);

  // const stepIndex = Object.values(TransUnionSubpages).indexOf(subpage);

  React.useEffect(() => {
    setSubpage(chosenSubpage);
  }, [chosenSubpage, setSubpage]);

  const RightPanelForm = RightPanelForms[subpage as keyof SubpageConfig<IdDocunentSubpages>] as React.ComponentClass<
    any
  >;

  // const stepIndexClick = (selectedNumber: number) => {
  //   let newSubPage = TransUnionSubpages.DecideComponent;

  //   if (selectedNumber === 0) {
  //     newSubPage = TransUnionSubpages.DecideComponent;
  //   } else if (selectedNumber === 1) {
  //     newSubPage = TransUnionSubpages.Summary;
  //   } else if (selectedNumber === 2) {
  //     newSubPage = TransUnionSubpages.SupportingDocuments;
  //   } else {
  //     newSubPage = TransUnionSubpages.VerficationStatus;
  //   }
  //   setSubpage(newSubPage);
  // };

  const showBackButton = () => {
    if (fromNotification && subpage === IdDocunentSubpages.SupportingDocuments) {
      return true;
    }
    return false;
  };

  const backButtonPage = tuCreationNavigation[subpage as keyof SubpageConfig<IdDocunentSubpages>].back;

  const successMessage = deathCaseServiceProvider?.serviceProvider.isUserValidationRequired
    ? t('label_id_validation_successful' as Messages)
    : t('label_id_doc_upload_success' as Messages);

  const getRightPanel = () => (
    <div className={styles.navLayout}>
      {showBackButton() && backButtonPage && (
        <Row constant onClick={() => setSubpage(backButtonPage)} alignCenter className={styles.handSymbol}>
          <Icon className={styles.backButtonSpacing} icon="chevronLeft" />
          <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
        </Row>
      )}
      {fromNotification && subpage !== IdDocunentSubpages.VerficationStatus && (
        <Typography
          msg={t(`${Messages.transUnionHeaderPrefix}${subpage.toLowerCase()}` as Messages)}
          tag="h4"
          size="xl"
          bold
        />
      )}

      <div className={!fromNotification ? styles.headerContainer : ''}>
        {!fromNotification && (
          <TitleWithSubtitle
            title={
              user?.verificationStatus === UserVerificationStatus.Verified
                ? successMessage
                : t(`${Messages.titleSettingsSubItemPrefix}document` as Messages)
            }
            subtitle={
              user?.verificationStatus === UserVerificationStatus.Verified
                ? t('label_send_notifications' as Messages)
                : t(`${Messages.subTitleSettingsSubItemPrefix}document` as Messages)
            }
            userSection
          />
        )}

        {/* {subpage !== TransUnionSubpages.VerficationStatus && (
          <Stepper
            // onKeyDown={(e: any, selectedNumber: number) => {
            //   if (e.keyCode === EnterKeyCode) {
            //     stepIndexClick(selectedNumber);
            //   }
            // }}
            // onClick={stepIndexClick}
            currentIndex={stepIndex}
            total={3}
          />
        )} */}
      </div>
      <div className={fromNotification ? styles.rightPanelNotification : styles.rightPanelDiv}>
        <RightPanelForm
          back={backButtonPage}
          next={tuCreationNavigation[subpage as keyof SubpageConfig<IdDocunentSubpages>].next}
          setSubpage={setSubpage}
          fromNotification={fromNotification}
          deathCaseServiceProviderId={deathCaseServiceProviderId}
          submitAction={submitAction}
        />
      </div>
    </div>
  );

  return <NavigationLayout fromNotification={fromNotification} rightSideComponent={getRightPanel()} />;
};

export default IdDocumentSteps;
