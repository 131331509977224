
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Document"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DocumentNode"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"documentType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"collaboratorsView"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"collaborator"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Collaborator"},"directives":[]}]}}]}}],"loc":{"start":0,"end":206}};
    doc.loc.source = {"body":"#import \"@Graphql/fragments/collaboratorFragment.graphql\"\n\nfragment Document on DocumentNode {\n  id\n  file\n  fileName\n  documentType\n  createdAt\n  collaboratorsView\n  collaborator {\n  ...Collaborator\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@Graphql/fragments/collaboratorFragment.graphql").definitions));


      module.exports = doc;
    
