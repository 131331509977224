import * as React from 'react';

import Icon from '@Components/Icon';
import Typography from '@Components/Typography';

import styles from './TourContent.scss';

export interface TourContentProps {
  content: string;
}

const TourContent: React.FunctionComponent<TourContentProps> = ({ content }) => {
  return (
    <div className={styles.contentLineSpacing}>
      <Icon className={styles.bulbIcon} icon="bulb" />
      <Typography msg="Tip!" tag="div" bold size="m" />
      <Typography msg={content} tag="div" size="l" />
    </div>
  );
};

export default TourContent;
