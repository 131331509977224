import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import CautionBox from '@Components/application/CautionBox';
import NotificationCard from '@Components/application/NotificationCard';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { colorArray, EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderFragment,
  CaseServiceProviderStatus,
  DeathCaseFragment,
  DeathCaseNode,
  LifeCaseFragment,
  useCaseServiceProvidersQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';

import styles from './DCServicesColumn.scss';

export interface DCServicesColumnProps {
  id: DeathCaseNode['id'];
  statuses: CaseServiceProviderStatus[];
  title: string;
  subtitle: string;
  emptyMessage: string;
  icon: string;
  caseData: DeathCaseFragment | LifeCaseFragment;
  notificationId?: string;
  showTourCallback?: () => void;
  isTourOpen?: boolean;
  goToTab?: () => void;
}

const DCServicesColumn: React.FunctionComponent<DCServicesColumnProps> = ({
  id,
  statuses,
  subtitle,
  title,
  emptyMessage,
  icon,
  caseData,
  notificationId,
  showTourCallback = noop,
  isTourOpen,
  goToTab,
}) => {
  const [hideNotifications, setHideNotifications] = React.useState(false);
  const t = useTranslations();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const { data, loading } = useCaseServiceProvidersQuery({
    variables: {
      baseCase: id,
      status: statuses,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <Col size={8} alignCenter textCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const getItems = () => {
    const mappedNotifications = data?.caseServiceProviders?.edges.map((edge) => edge.node) || [];
    if (statuses.includes(CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier)) {
      return mappedNotifications.sort((a: any, b: any) => a.status.length - b.status.length).reverse();
    }
    return mappedNotifications;
  };

  const items: CaseServiceProviderFragment[] = getItems();

  const EmptyNotificationComponent = (
    <Row size={isMobile ? 12 : 11} constant justifyCenter column className={styles.emptyNotificationContainer}>
      <Col textCenter>
        <Icon className={styles.emptyIcon} icon={icon} />
      </Col>
      <Col className={styles.messageWidth} textCenter>
        <Typography msg={emptyMessage} tag="div" size="m" color="footerColor" />
      </Col>
    </Row>
  );

  const notificationComponent = () => {
    if (items.length > 0) {
      let i = -1;
      return items.map((sp: CaseServiceProviderFragment, idx: number) => {
        i += 1;
        const j = i;
        i = i === 4 ? -1 : i;
        return (
          <NotificationCard
            cardId={notificationId}
            initialBackground={colorArray[j]}
            caseData={caseData}
            key={idx}
            service={sp}
            showTourCallback={showTourCallback}
            isTourOpen={isTourOpen || false}
            goToTab={goToTab}
          />
        );
      });
    }
    return EmptyNotificationComponent;
  };

  const showInfo = statuses.every((s: CaseServiceProviderStatus) =>
    [
      CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier,
      CaseServiceProviderStatus.InProgressAwaitingInformationFromSp,
      CaseServiceProviderStatus.Sent,
      CaseServiceProviderStatus.InProgress,
      CaseServiceProviderStatus.InProgressAutoPopulationFailed,
    ].includes(s)
  );

  return (
    <>
      <Row constant alignCenter>
        <Typography msg={`${title} (${items.length})`} tag="h5" size="l" />

        <Icon
          className={styles.handSymbol}
          onClick={() => setHideNotifications(!hideNotifications)}
          size={IconSizes.s}
          icon={hideNotifications ? 'chevronDown' : 'chevronUp'}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              setHideNotifications(!hideNotifications);
            }
          }}
        />
      </Row>
      {!hideNotifications && (
        <Typography className={styles.bottomSpacing} msg={subtitle} tag="div" size="m" color="footerColor" />
      )}
      {showInfo && items.length > 0 && (
        <CautionBox
          containerClassName={styles.cautionBox}
          info={t('label_sent_notification_info' as Messages)}
          title={t('label_info' as Messages)}
          showMidSection={false}
        />
      )}
      {!hideNotifications && notificationComponent()}
    </>
  );
};

export default DCServicesColumn;
