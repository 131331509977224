import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import GrayPaper from '@Components/application/GrayPaper';
import { TooltipPosition } from '@Components/application/Tooltip';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import { IconSizes } from '@Components/Icon';
import Icon from '@Components/Icon/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton/LinkButton';
import LoadingSpinner from '@Components/LoadingSpinner';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue, LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryCollaboratorsList } from '@Graphql/collaborators/queries';
import { mutationCreateCollaborator } from '@Graphql/dc/mutations';
import {
  CollaboratorNode,
  CollaboratorPermission,
  CollaboratorProRelationFe,
  CollaboratorRelationshipFe,
  CreateCollaboratorMutation,
  CreateCollaboratorMutationVariables,
  LifeCaseNode,
  useCollaboratorsQuery,
  UserAccountType,
  useRefLifeByIdQuery,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import KnownLegals from '@Routes/deathCase/DeathCasePage/LegalForm/KnownLegals/KnownLegals';
import { getPermissionSelectOption, normaliseGqlError } from '@Utils/form';
import { manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { getFromLocalStorageString } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import { validateEmail, validateName, validatePhone } from '@Utils/validator';

import { RelationShipDefinition, RelationshipLevel } from '../../../../model/Relationship';

import styles from './AdditionalContactsForm.scss';

export interface AdditionalContactsFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
  user: UserNode | null;
}

export interface AdditionalContactsFormStateProps {
  data: LoadableItem<LifeCaseNode>;
}

export enum AdditionalContactsFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  relationship = 'relationship',
  permission = 'permission',
}

export interface AdditionalContactsFormValues {
  [AdditionalContactsFormFields.firstName]: string;
  [AdditionalContactsFormFields.lastName]: string;
  [AdditionalContactsFormFields.email]: string;
  [AdditionalContactsFormFields.phone]: string;
}

interface AdditionalContactsFormPrivateProps extends AdditionalContactsFormProps, AdditionalContactsFormStateProps {}

const AdditionalContactsForm: React.FunctionComponent<AdditionalContactsFormPrivateProps> = ({
  back,
  data,
  next,
  setSubpage,
  containerClasses,
  user,
}) => {
  const t = useTranslations();

  const dispatch = useDispatch();
  const [isFormVisible, setFormVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const isRes769 = useMediaQuery({ query: ReactResponsiveQueries.Res769 });
  const refLifeCaseDataId = getFromLocalStorageString(`${LocalStorage.referenceLifeCase}_${user?.firstName}`);

  const noOwner = Object.values(CollaboratorPermission)
    .filter((item: CollaboratorPermission) => item !== CollaboratorPermission.Owner)
    .map(getPermissionSelectOption(t));

  const permissionSelectOptions =
    user?.accountType === UserAccountType.Professional
      ? Object.values(CollaboratorPermission).map(getPermissionSelectOption(t))
      : noOwner;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const collaboratorsData = useCollaboratorsQuery({
    variables: {
      baseCase: data.item?.id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const referLifeCaseQueryData = useRefLifeByIdQuery({
    variables: {
      id: refLifeCaseDataId,
    },
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: refLifeCaseDataId === '',
  });

  const [addCollaboratorMutation] = useMutation<CreateCollaboratorMutation, CreateCollaboratorMutationVariables>(
    mutationCreateCollaborator,
    {
      refetchQueries: [getOperationName(queryCollaboratorsList) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess('Additional contact added'));
        setFormVisible(false);
        setSubmitting(false);
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
        setSubmitting(false);
      },
    }
  );

  if (collaboratorsData.loading || referLifeCaseQueryData.loading) {
    return (
      <Col size={8} textCenter alignCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const additionalContacts =
    collaboratorsData.data?.collaborators.edges
      .map((x: any) => x.node)
      .filter((ac: CollaboratorNode) => ac.showCollaborator) || [];

  const setNextStep = () => next && setSubpage(next);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      width: '581px',
      padding: '14px 24px',
    },
    overlay: {
      border: 'unset',
      borderRadius: '6px',
      background: 'rgba(0, 0, 0, 0.2)',
      zIndex: 1001,
    },
  };

  const handlePermissionChange = (perm: any) => {
    if (perm.value === CollaboratorPermission.Owner && user?.accountType === UserAccountType.Professional) {
      setIsOpen(true);
    }
  };

  const getSaveButton = (
    handleSubmit: () => void,

    isValid: boolean,
    values: AdditionalContactsFormValues
  ) => {
    const allFieldsEmpty =
      !values[AdditionalContactsFormFields.firstName] &&
      !values[AdditionalContactsFormFields.lastName] &&
      !values[AdditionalContactsFormFields.phone] &&
      !values[AdditionalContactsFormFields.email];
    const enabled = isValid || (allFieldsEmpty && additionalContacts.length > 0);
    return (
      <Row>
        <Button
          type={ButtonTypes.submit}
          onClick={() => {
            if (isValid) {
              handleSubmit();
              setNextStep();
            }
          }}
          disabled={!enabled}
          loading={isSubmitting}
          size={ButtonSizes.fill}
          label={t(Messages.buttonNext)}
          isFullWidth
        />
      </Row>
    );
  };

  const refererredLifeCaseData = referLifeCaseQueryData.data?.refLifeById;

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    React.useEffect(() => {
      if (additionalContacts.length === 0 || referLifeCaseQueryData.data !== undefined) {
        setFormVisible(true);
      }
      // const getFieldValue = (fieldName: ExecutiveCollaboratorFields) => {
      //   if (!hasLegals) {
      //     return emptyFormValues[fieldName];
      //   }
      //   return meExecutor ? userDataValues[fieldName] : emptyFormValues[fieldName];
      // };
      // const toggleAdditionalContactsFormField = (fieldName: ExecutiveCollaboratorFields) => {
      //   setFieldValue(fieldName, getFieldValue(fieldName), false);
      //   setFieldTouched(fieldName, false, false);
      // };
      // toggleAdditionalContactsFormField(AdditionalContactsFormFields.firstName);
      // toggleAdditionalContactsFormField(AdditionalContactsFormFields.lastName);
      // toggleAdditionalContactsFormField(AdditionalContactsFormFields.email);
      // toggleAdditionalContactsFormField(AdditionalContactsFormFields.phone);
    }, [setFieldValue, setFieldTouched]);

    return null;
  };

  const relationships: RelationShipDefinition[] = [
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Executor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 0,
      infoTitle: manipulateTranslations(t, 'tooltip_title_executor', 'What is an executor?'),
      infoMessage: t('tooltip_desc_lc_executor' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.WillAdministrator,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 1,
      infoTitle: manipulateTranslations(t, 'tooltip_title_administrator', 'What is an administrator?'),
      infoMessage: t('tooltip_desc_lc_administrator' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.LegalRepresentative,
      relationshipType: RelationshipLevel.firstLevel,
      selected: !!(refererredLifeCaseData && refererredLifeCaseData.representatives.length > 0),
      index: 2,
      infoTitle: manipulateTranslations(t, 'tooltip_title_legal_representative', 'What is a legal representative?'),
      infoMessage: t('tooltip_desc_lc_legal_representative' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.OtherAdvisor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 3,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Partner.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Partner,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 4,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Child.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Child,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 5,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Parent.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Parent,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 6,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Sibling.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Sibling,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 7,
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.FamilyMember.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.FamilyMember,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 8,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Friend.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Friend,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 9,
    },
  ];

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  const isCollaboratorDefined = (values: any, relationshipName: any) => {
    const isSelected = values.relationship.find((x: RelationShipDefinition) => x.value === relationshipName)?.selected;
    return isSelected ? true : undefined;
  };

  const legalReps =
    refererredLifeCaseData && refererredLifeCaseData?.representatives.length > 0
      ? refererredLifeCaseData.representatives[0]
      : undefined;

  return (
    <>
      <Row className={classNames(styles.notesBottomSpacing, styles.pageWidth)}>
        <CautionBox
          containerClassName={styles.cautionBox}
          info={t(Messages.descAdditionalContacts)}
          title={t(Messages.labelTip)}
        />
      </Row>

      <Formik
        enableReinitialize
        initialValues={{
          [AdditionalContactsFormFields.firstName]: legalReps?.firstName || '',
          [AdditionalContactsFormFields.lastName]: legalReps?.lastName || '',
          [AdditionalContactsFormFields.email]: legalReps?.email || '',
          [AdditionalContactsFormFields.phone]: legalReps?.phone || '+44',
          [AdditionalContactsFormFields.relationship]: relationshipInitialValue,
          [AdditionalContactsFormFields.permission]: emptySelectorValue,
        }}
        validationSchema={Yup.object({
          [AdditionalContactsFormFields.firstName]: validateName(t),
          [AdditionalContactsFormFields.lastName]: validateName(t),
          [AdditionalContactsFormFields.email]: validateEmail(t),
          [AdditionalContactsFormFields.phone]: validatePhone(t),
        })}
        onSubmit={(values) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const relationshipSelected = values.relationship.filter((x: RelationShipDefinition) => x.selected === true);
          if (relationshipSelected.length > 0) {
            addCollaboratorMutation({
              variables: {
                input: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phone: removeLeadingZeroAndSpaceInContact(values.phone),
                  baseCase: id,
                  email: values.email.trim(),
                  relationship:
                    values.relationship
                      .filter((rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.secondLevel)
                      .find((x: RelationShipDefinition) => x.selected === true)?.value || undefined,
                  isAlsoAdministrator: isCollaboratorDefined(values, CollaboratorRelationshipFe.WillAdministrator),
                  isAlsoLegal: isCollaboratorDefined(values, CollaboratorProRelationFe.LegalRepresentative),
                  isAlsoOtherAdvisor: isCollaboratorDefined(values, CollaboratorProRelationFe.OtherAdvisor),
                  isAlsoExecutor: isCollaboratorDefined(values, CollaboratorRelationshipFe.Executor),
                  permission: Object.values(CollaboratorPermission).find(
                    (perms: any) => perms === values.permission.value
                  ),
                },
              },
            });
          } else {
            setSubmitting(false);
            dispatch(notificationError(t('msg_relationship_lc_required' as Messages)));
          }
        }}
      >
        {({ isValid, status, handleSubmit, values, resetForm, setFieldValue }) => (
          <>
            <Form>
              <div className={classNames(containerClasses, styles.legalForm)}>
                {additionalContacts.length > 0 && <KnownLegals legals={additionalContacts} isLifeCase />}
                {isFormVisible && (
                  <GrayPaper width="width-full" className={styles.biggerBottomSpacing}>
                    <Typography
                      msg={`${t(Messages.labelNewContact)} ${additionalContacts.length + 1}`}
                      tag="div"
                      size="m"
                      bold
                    />
                    <GridRow>
                      <GridCol size={6}>
                        <Field
                          name={AdditionalContactsFormFields.firstName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldContactFirstName)}
                          required
                        />
                      </GridCol>
                      <GridCol size={6}>
                        <Field
                          name={AdditionalContactsFormFields.lastName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldContactLastName)}
                          required
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={9}>
                        <Field
                          name={AdditionalContactsFormFields.email}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldContactEmail)}
                          required
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow className={styles.biggerBottomSpacing}>
                      <GridCol size={9}>
                        <Field
                          name={AdditionalContactsFormFields.phone}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldContactPhone)}
                          required
                        />
                      </GridCol>
                    </GridRow>
                    {/* Adding level of access  */}
                    <GridRow>
                      <GridCol size={9} className={styles.biggerBottomSpacing}>
                        <Field
                          name={AdditionalContactsFormFields.permission}
                          label="Level of access"
                          component={SelectInput}
                          options={
                            permissionSelectOptions
                            //values.meExecutor
                            // ? permissionSelectOptions
                            // : permissionSelectOptions.filter((pso: any) => pso.value !== CollaboratorPermission.Owner)
                          }
                          selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                          onSelectChange={handlePermissionChange}
                          //disabled={values.meExecutor}
                          required
                        />
                      </GridCol>
                    </GridRow>

                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={AdditionalContactsFormFields.relationship}
                          options={relationships}
                          type="text"
                          component={MultiSelect}
                          position={TooltipPosition.right}
                          label={t(Messages.fieldContactRelationship)}
                        />
                      </GridCol>
                    </GridRow>

                    <GridRow>
                      <GridCol className={isRes769 ? styles.bottomSpacing : ''} size={7}>
                        <Button
                          type={ButtonTypes.submit}
                          loading={isSubmitting}
                          label={t(Messages.buttonAddContact)}
                          icon="plus"
                          iconSize={IconSizes.ss}
                          isFullWidth
                          constant
                        />
                      </GridCol>
                      <GridCol size={5}>
                        <Button
                          type={ButtonTypes.button}
                          label={t(Messages.buttonCancel)}
                          style={ButtonStyles.transparent}
                          onClick={() => {
                            resetForm();
                            setFormVisible(false);
                          }}
                          isFullWidth
                        />
                      </GridCol>
                    </GridRow>
                  </GrayPaper>
                )}

                <Row justifyCenter>
                  <Button
                    label={t(Messages.buttonAddAnother)}
                    icon="person-add"
                    iconSize={IconSizes.ss}
                    style={ButtonStyles.transparent}
                    onClick={() => setFormVisible(true)}
                    constant
                  />
                </Row>
              </div>
              <AutoToggleForm />
              <FormError formError={status} />
              <NavigationBlock
                isSubmitting={isSubmitting}
                back={back}
                next={next}
                setSubpage={setSubpage}
                SaveButton={getSaveButton(handleSubmit, isValid, values)}
              />
            </Form>
            <Modal
              isOpen={modalIsOpen}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              shouldFocusAfterRender
              shouldCloseOnEsc={false}
              onRequestClose={() => setIsOpen(false)}
            >
              <Row justifyCenter>
                <Icon icon="alert-triangle" size={IconSizes.m} />
              </Row>

              <Typography
                textCenter
                msg={t('title_transfer_owner' as Messages)}
                tag="h4"
                size="xl"
                bold
                className={styles.biggerBottomSpacing}
              />

              <Typography
                textCenter
                msg={t('text_transfer_owner' as Messages)}
                tag="div"
                size="l"
                className={styles.biggerBottomSpacing}
              />
              <Button onClick={() => setIsOpen(false)} label={t('button_transfer_owner' as Messages)} isFullWidth />

              <LinkButton
                textCenter
                className={styles.mt18}
                linkTitle={t('link_not_transfer' as Messages)}
                onClick={() => {
                  setFieldValue(AdditionalContactsFormFields.permission, emptySelectorValue);
                  setIsOpen(false);
                }}
              />
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export default AdditionalContactsForm;
