import typescriptFsa from 'typescript-fsa';

import { CreateLCPayload, FetchLCPayload, UpdateLCPayload } from './lc.types';

const actionCreator = typescriptFsa('@LIFE_CASE');

export const fetchLC = actionCreator.async<FetchLCPayload, {}>('FETCH_LC');
export const createLC = actionCreator.async<CreateLCPayload, {}>('CREATE_LC');
export const updateLC = actionCreator.async<UpdateLCPayload, {}>('UPDATE_LC');
export const clearLC = actionCreator<void>('CLEAR_LC');
