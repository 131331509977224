
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ServiceProvider"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ServiceProviderNode"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subCategory"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"parentCategory"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"logo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"identifierType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userSlaInDays"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"turnOffDocTab"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"theirTier"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isUserValidationRequired"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showNotifierIdDocuments"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"organizationType"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":287}};
    doc.loc.source = {"body":"fragment ServiceProvider on ServiceProviderNode {\n  name\n  subCategory {\n    name\n    id\n    parentCategory {\n      name\n      id\n    }\n  }\n  logo\n  id\n  identifierType\n  userSlaInDays\n  turnOffDocTab\n  theirTier\n  isUserValidationRequired\n  showNotifierIdDocuments\n  organizationType\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
