import { connect } from 'react-redux';

import GeneralFormAddress, {
  GeneralFormProps,
  GeneralFormStateProps,
} from '@Routes/deathCase/DeathCasePage/GeneralFormAddress/GeneralFormAddress';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
});

export default connect<GeneralFormStateProps, {}, GeneralFormProps, RootState>(mapStateToProps)(GeneralFormAddress);
