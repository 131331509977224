import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button/Button';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import { NavigationProps } from '@Components/NavigationBlock';
import { CaseTags, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  UserReferralSource,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { loadingHandler, newWelcomeModal } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './RegisterLifeCaseModal.scss';

export interface RegisterLifeCaseProps extends NavigationProps {
  containerClasses?: string;
  packageSelected: any;
}

export enum RegisterLifeCaseFields {
  isTerminallyIll = 'isTerminallyIll',
}

const RegisterLifeCaseModal: React.FunctionComponent<ModalProviderProps<RegisterLifeCaseProps>> = ({
  closeModal,
  modalData: { packageSelected },
}) => {
  const t = useTranslations();
  const stripe = useStripe();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const isHospice = user?.referralSource === UserReferralSource.LifeHospice;

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      closeModal();
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (stripeLoading) {
    closeModal();
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  return (
    <div className={styles.containerPadding}>
      <Formik
        initialValues={{
          [RegisterLifeCaseFields.isTerminallyIll]: false,
        }}
        onSubmit={(values) => {
          if (isHospice || values.isTerminallyIll) {
            if (user?.isFirstLogin) {
              newWelcomeModal(dispatch, values.isTerminallyIll);
            } else {
              closeModal();
              history.push(
                generatePath(`${Links.lcCreate}?ill=${values.isTerminallyIll}`, {
                  tag: `tag=${CaseTags.lifeCaseCreated}`,
                })
              );
            }
          } else {
            stripeCreateDcSubscriptionMutation({
              variables: { input: { priceId: packageSelected?.priceId || '', baseCase: '' } },
            });
          }
        }}
      >
        {({ status }) => (
          <Form>
            <div className={styles.pl5}>
              <GridRow>
                <GridCol size={5} className={styles.mt17}>
                  <Field
                    name={RegisterLifeCaseFields.isTerminallyIll}
                    label={t('label_is_terminally_ill' as Messages)}
                    component={ToggleSwitchInput}
                  />
                </GridCol>
              </GridRow>
              <FormError formError={status} />
            </div>
            <GridRow className={styles.mt17}>
              <GridCol size={12}>
                <Button
                  label={t('button_continue' as Messages)}
                  size={ButtonSizes.fill}
                  type={ButtonTypes.submit}
                  isFullWidth
                  loading={stripeLoading}
                />
              </GridCol>
            </GridRow>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterLifeCaseModal;
