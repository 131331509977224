import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { AzureAD, AuthenticationState, IAccountInfo } from 'react-aad-msal';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, LocalStorage, ReactResponsiveQueries, googleClientId } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationRegisterUser, mutationSocialLogin } from '@Graphql/auth/mutations';
import {
  RegisterUserMutation,
  RegisterUserMutationVariables,
  SocialLoginMedium,
  SocialLoginMutation,
  SocialLoginMutationVariables,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import MicrosoftLandingPage from '@Routes/auth/MicrosoftLandingPage';
import { getGqlErrorMessage } from '@Utils/form';
import { chooseAccountType, msalInstance } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';

import styles from './GoogleSignIn.scss';

export interface GoogleSignInProps {}

interface GoogleSignInPrivateProps extends GoogleSignInProps {
  collaboratorKey?: any;
  isLoginPage?: boolean;
  containerClassName?: string;
  accountType?: UserAccountType;
  referralCode?: string;
  referralSource?: string;
  referralSourceDetails?: string;
  registrationPage?: boolean;
  beSpokenRegistration?: boolean;
}

interface FacebookUserInfo {
  givenName: string;
  email: string;
  socialId: string;
}

const GoogleSignIn: React.FunctionComponent<GoogleSignInPrivateProps> = ({
  collaboratorKey,
  isLoginPage,
  containerClassName,
  accountType,
  referralCode,
  referralSource,
  referralSourceDetails,
  registrationPage = false,
  beSpokenRegistration = false,
}) => {
  const [idToken, setIdToken] = useState('');
  const history = useHistory();
  const t = useTranslations();
  const location = useLocation();
  const [socialTypeClicked, setSocialTypeClicked] = React.useState('');
  const [fbUserData, setFbUserData] = React.useState<FacebookUserInfo | null>(null);
  const isRes1050 = useMediaQuery({ query: ReactResponsiveQueries.Res1050 });
  const dispatch = useDispatch();

  const [socialLogin] = useMutation<SocialLoginMutation, SocialLoginMutationVariables>(mutationSocialLogin, {
    onCompleted: (data: any) => {
      setToLocalStorage(LocalStorage.userToken, data.socialLogin.token);
      history.push(Links.home);
    },
  });

  const [registerUser] = useMutation<RegisterUserMutation, RegisterUserMutationVariables>(mutationRegisterUser, {
    onCompleted: () => {
      socialLogin({
        variables: {
          input: {
            provider: socialTypeClicked.toLowerCase(),
            idToken: socialTypeClicked === SocialLoginMedium.Facebook ? undefined : idToken,
            givenName: fbUserData?.givenName,
            email: fbUserData?.email,
            socialId: fbUserData?.socialId,
          },
        },
      });
    },
    onError: (error: any) => {
      if (getGqlErrorMessage(error.message) === 'msg_error_user_with_this_email_already_exists') {
        dispatch(notificationError(t('msg_user_exists_already_in_app' as Messages)));
      }
    },
  });

  const onSuccess = (res: any) => {
    setIdToken(res.tokenObj.id_token);
    setSocialTypeClicked(SocialLoginMedium.Google);
    registerUser({
      variables: {
        input: {
          firstName: res.profileObj.givenName,
          lastName: res.profileObj.familyName,
          email: res.profileObj.email,
          accountType: getAccountType(),
          accessCode: '',
          collaboratorKey,
          socialLoginType: SocialLoginMedium.Google,
          referralCode,
          referralSource,
          referralSourceDetails,
        },
      },
    });
  };

  const facebookSuccess = (res: any) => {
    //eslint-disable-next-line
    console.log('facebook response:', res)
    setIdToken(res.accessToken);
    setSocialTypeClicked(SocialLoginMedium.Facebook);
    const nameParts = res.name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts[1] : '';
    setFbUserData({
      email: res.email,
      givenName: res.name,
      socialId: res.id,
    });
    registerUser({
      variables: {
        input: {
          firstName,
          lastName,
          email: res.email,
          accountType: getAccountType(),
          accessCode: '',
          collaboratorKey,
          socialLoginType: SocialLoginMedium.Facebook,
          referralCode,
          referralSource,
          referralSourceDetails,
        },
      },
    });
  };

  const getAccountType = () => {
    if (accountType) {
      return accountType;
    }
    if (!isLoginPage) {
      return location.pathname.split('/')[2].toUpperCase()
        ? chooseAccountType(location.pathname.split('/')[2].toUpperCase())
        : undefined;
    }
    return undefined;
  };

  const fmButtonSection = () => {
    if (beSpokenRegistration) {
      return undefined;
    }
    if (registrationPage && isRes1050) {
      return 8;
    }

    return isLoginPage ? 12 : 6;
  };

  return (
    <>
      <Row>
        <Col size={12}>
          <Typography msg="Or" tag="div" className={styles.mt16} textCenter />
        </Col>
      </Row>
      <Row justifyCenter>
        <Col textCenter={beSpokenRegistration} size={fmButtonSection()}>
          <GoogleLogin
            clientId={googleClientId}
            buttonText="Login with Google"
            onSuccess={onSuccess}
            // onFailure={onFailure}
            cookiePolicy="single_host_origin"
            theme="dark"
            className={classNames(styles.googleButton, containerClassName, { [styles.w100]: !beSpokenRegistration })}
          />
        </Col>
      </Row>
      <Row justifyCenter>
        <Col textCenter={beSpokenRegistration} size={fmButtonSection()}>
          <FacebookLogin
            appId="417458177522620"
            buttonStyle={{
              width: beSpokenRegistration ? 'auto' : '100%',
              padding: '10px 10px 10px 12px',
              marginTop: '8px',
              textTransform: 'unset',
              display: 'inline-flex',
              fontSize: '17px',
              fontWeight: 'bolder',
              alignItems: 'center',
              gap: beSpokenRegistration ? undefined : '64px',
              borderRadius: '2px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
            }}
            callback={facebookSuccess}
            fields="name,email,picture"
            icon="fa-facebook"
            size="medium"
          />
        </Col>
      </Row>
      <AzureAD provider={msalInstance}>
        {({
          login,
          authenticationState,
          logout,
          accountInfo,
        }: {
          //eslint-disable-next-line
          login: () => void;
          //eslint-disable-next-line
          logout: () => void;
          //eslint-disable-next-line
          authenticationState: AuthenticationState;
          //eslint-disable-next-line
          accountInfo: IAccountInfo;
        }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return (
                <MicrosoftLandingPage
                  logout={logout}
                  accountInfo={accountInfo}
                  isLoginPage={isLoginPage}
                  accountType={accountType}
                  referralCode={referralCode}
                  referralSource={referralSource}
                  referralSourceDetails={referralSourceDetails}
                  collaboratorKey={collaboratorKey}
                />
              );

            case AuthenticationState.InProgress:
              return <button onClick={() => logout()}>Log out</button>;
            default:
              return (
                <Row justifyCenter>
                  <Col textCenter={beSpokenRegistration} size={fmButtonSection()}>
                    <div className={styles.buttonContainer}>
                      <button
                        className={classNames(styles.microsoftButton, {
                          [styles.w100]: !beSpokenRegistration,
                          [styles.gap62]: !beSpokenRegistration,
                          [styles.gaprem]: beSpokenRegistration,
                        })}
                        onClick={login}
                      >
                        <Icon size={IconSizes.sxs} icon="microsoft" />
                        Login with Microsoft
                      </button>
                    </div>
                  </Col>
                </Row>
              );
          }
        }}
      </AzureAD>
      {isLoginPage && (
        <Row constant alignCenter className={styles.spacing}>
          <Typography msg={t('label_social_login_consent' as Messages)} tag="div" html size="s" />
        </Row>
      )}
    </>
  );
};

export default GoogleSignIn;
