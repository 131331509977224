import classNames from 'classnames';
import * as React from 'react';
import Collapsible from 'react-collapsible';
import { Cookies } from 'react-cookie-consent';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import Switch from 'react-switch';

import Button from '@Components/Button';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { noop } from '@Utils/helpers';
import { getBooleanValueCookie, setToLocalStorage } from '@Utils/localStorage';

import { privacyCookie, essentialCookie, functionalCookie, analyticCookie } from '../../model/CookieTypes';

import styles from './CookieBanner.scss';

export interface CookieBannerProps {
  buttonClasses?: string;
  containerClasses?: string;
  contentClasses?: string;
}

interface CookieBannerPrivateProps extends CookieBannerProps {}

const CookieBanner: React.FunctionComponent<CookieBannerPrivateProps> = () => {
  const [showCookieBanner, setShowCookieBanner] = React.useState(!getBooleanValueCookie(LocalStorage.cookieConsent));
  const [analyticCookieRequired, setAnalyticCookieRequired] = React.useState(
    getBooleanValueCookie(LocalStorage.analyticsCookie)
  );
  const [functionalCookieRequired, setFunctionalCookieRequired] = React.useState(
    getBooleanValueCookie(LocalStorage.functionalCookie)
  );

  const [selectedCookieType, setSelectedCookieType] = React.useState(privacyCookie.type);
  const [selectedCookieRightPanel, setSelectedCookieRightPannel] = React.useState(privacyCookie);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const onSavePreferencesClick = () => {
    if (!analyticCookieRequired || !functionalCookieRequired) {
      Cookies.remove('_ga');
      Cookies.remove('_gat');
      Cookies.remove('_gat_UA-167375481-1');
      Cookies.remove('_gid');
      Cookies.remove('__zlcmid');
      Cookies.remove('_hjid');
      setToLocalStorage(LocalStorage.enablePerformanceCookie, false);
    } else {
      setToLocalStorage(LocalStorage.enablePerformanceCookie, true);
    }
    setToLocalStorage(LocalStorage.cookieConsent, true);
    setShowCookieBanner(false);
  };

  const onAcceptAllClick = () => {
    setToLocalStorage(LocalStorage.enablePerformanceCookie, true);
    setToLocalStorage(LocalStorage.cookieConsent, true);
    setToLocalStorage(LocalStorage.analyticsCookie, true);
    setToLocalStorage(LocalStorage.functionalCookie, true);
    setShowCookieBanner(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      width: isMobile ? '100%' : '650px',
      padding: isMobile ? '20px 20px 10px' : 'unset !important',
      overflow: 'auto',
      height: isMobile ? '100%' : undefined,
    },
    overlay: {
      border: 'unset',
      borderRadius: '6px',
      background: 'rgba(0, 0, 0, 0.2)',
      zIndex: 1001,
    },
  };

  return (
    <Modal
      isOpen={showCookieBanner}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender
      shouldCloseOnEsc={false}
    >
      {!isMobile && (
        <div className={styles.fullContainer}>
          <div className={styles.leftSection}>
            <Row justifyCenter className={styles.bottomSpacing15}>
              <div className={styles.privacyIcon} />
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(privacyCookie.type);
                setSelectedCookieRightPannel(privacyCookie);
              }}
              className={
                selectedCookieType === privacyCookie.type ? styles.selectedCookieContainer : styles.cookieTypeContainer
              }
            >
              {privacyCookie.name}
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(essentialCookie.type);
                setSelectedCookieRightPannel(essentialCookie);
              }}
              className={
                selectedCookieType === essentialCookie.type
                  ? styles.selectedCookieContainer
                  : styles.cookieTypeContainer
              }
            >
              {essentialCookie.name}
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(functionalCookie.type);
                setSelectedCookieRightPannel(functionalCookie);
              }}
              className={
                selectedCookieType === functionalCookie.type
                  ? styles.selectedCookieContainer
                  : styles.cookieTypeContainer
              }
            >
              {functionalCookie.name}
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(analyticCookie.type);
                setSelectedCookieRightPannel(analyticCookie);
              }}
              className={
                selectedCookieType === analyticCookie.type ? styles.selectedCookieContainer : styles.cookieTypeContainer
              }
            >
              {analyticCookie.name}
            </Row>
            <Row
              onClick={() => window.open('https://lifeledger.com/cookies/?nab=1')}
              className={classNames(styles.cookieTypeContainer, styles.bottomSpacing30)}
            >
              How we use cookies
            </Row>
          </div>
          <div className={styles.rightSection}>
            <Row className={styles.titleContainer}>
              <Typography msg="Cookie Settings Center" tag="div" size="xl" bold className={styles.title} />
            </Row>
            <div className={styles.rightPanel}>
              <Typography className={styles.cookieTitle} msg={selectedCookieRightPanel.name} bold tag="h3" size="xxl" />
              <Typography
                msg={selectedCookieRightPanel.description}
                tag="p"
                size="xss"
                className={styles.bottomSpacing30}
              />
              <Row className={styles.bottomSpacing30} justifyEnd>
                {selectedCookieRightPanel === essentialCookie && (
                  <>
                    <Switch
                      onChange={() => noop}
                      checked={true}
                      disabled={true}
                      className={styles.switch}
                      height={25}
                    />
                    <Typography size="xss" msg="Always Active" tag="div" bold />
                  </>
                )}

                {selectedCookieRightPanel === functionalCookie && (
                  <>
                    <Switch
                      onChange={() => {
                        setFunctionalCookieRequired(!functionalCookieRequired);
                        setToLocalStorage(LocalStorage.functionalCookie, !functionalCookieRequired);
                      }}
                      checked={functionalCookieRequired}
                      height={25}
                      className={styles.switch}
                    />
                    <Typography size="xss" msg={functionalCookieRequired ? 'On' : 'Off'} tag="div" bold />
                  </>
                )}

                {selectedCookieRightPanel === analyticCookie && (
                  <>
                    <Switch
                      onChange={() => {
                        setAnalyticCookieRequired(!analyticCookieRequired);
                        setToLocalStorage(LocalStorage.analyticsCookie, !analyticCookieRequired);
                      }}
                      checked={analyticCookieRequired}
                      className={styles.switch}
                      height={25}
                    />
                    <Typography size="xss" msg={analyticCookieRequired ? 'On' : 'Off'} tag="div" bold />
                  </>
                )}
              </Row>

              <Row className={styles.gap8}>
                <Button labelSize="xss" label="Save Preferences" onClick={() => onSavePreferencesClick()} isFullWidth />
                <Button labelSize="xss" label="Accept all cookies" onClick={() => onAcceptAllClick()} isFullWidth />
              </Row>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <>
          <Row alignCenter justifyCenter>
            <div className={styles.privacyIcon} />
            <Typography msg="Cookie Settings Center" bold tag="div" size="xl" className={styles.title} />
          </Row>

          <Collapsible
            trigger={<Typography msg={privacyCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />}
          >
            <div className={styles.contentSpacing}>
              <Typography className={styles.cookieTitle} bold msg={privacyCookie.name} tag="h3" size="xxl" />
              <Typography msg={privacyCookie.description} size="xss" tag="p" />
            </div>
          </Collapsible>
          <Collapsible
            trigger={
              <Typography msg={essentialCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
            }
          >
            <div className={styles.contentSpacing}>
              <Typography className={styles.cookieTitle} bold msg={essentialCookie.name} tag="h3" size="xxl" />
              <Typography msg={essentialCookie.description} size="xss" tag="p" />
              <Row constant className={styles.mt10}>
                <Switch onChange={() => noop} checked={true} disabled={true} className={styles.switch} height={25} />
                <Typography size="xss" msg="Always Active" tag="div" bold />
              </Row>
            </div>
          </Collapsible>
          <Collapsible
            trigger={
              <Typography msg={functionalCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
            }
          >
            <div className={styles.contentSpacing}>
              <Typography className={styles.cookieTitle} bold msg={functionalCookie.name} tag="h3" size="xxl" />
              <Typography msg={functionalCookie.description} size="xss" tag="p" />
              <Row constant className={styles.mt10}>
                <Switch
                  onChange={() => {
                    setFunctionalCookieRequired(!functionalCookieRequired);
                    setToLocalStorage(LocalStorage.functionalCookie, !functionalCookieRequired);
                  }}
                  checked={functionalCookieRequired}
                  height={25}
                  className={styles.switch}
                />
                <Typography size="xss" msg={functionalCookieRequired ? 'On' : 'Off'} tag="div" bold />
              </Row>
            </div>
          </Collapsible>
          <Collapsible
            trigger={
              <Typography msg={analyticCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
            }
          >
            <div className={styles.contentSpacing}>
              <Typography className={styles.cookieTitle} bold msg={analyticCookie.name} tag="h3" size="xxl" />
              <Typography msg={analyticCookie.description} size="xss" tag="p" />
              <Row constant className={styles.mt10}>
                <Switch
                  onChange={() => {
                    setAnalyticCookieRequired(!analyticCookieRequired);
                    setToLocalStorage(LocalStorage.analyticsCookie, !analyticCookieRequired);
                  }}
                  checked={analyticCookieRequired}
                  height={25}
                  className={styles.switch}
                />
                <Typography size="xss" msg={analyticCookieRequired ? 'On' : 'Off'} tag="div" bold />
              </Row>
            </div>
          </Collapsible>
          <Row
            onClick={() => window.open('https://lifeledger.com/cookies/?nab=1')}
            className={classNames(styles.mobileCookieHeader, styles.bottomSpacing30)}
          >
            <Typography msg="How we use cookies" tag="div" size="xss" />
          </Row>
          <Row constant justifyCenter className={styles.buttonDistance}>
            <Button labelSize="xss" label="Save Preferences" onClick={() => onSavePreferencesClick()} isFullWidth />

            <Button labelSize="xss" label="Accept all cookies" onClick={() => onAcceptAllClick()} isFullWidth />
          </Row>
        </>
      )}
    </Modal>
  );
};

export default CookieBanner;
