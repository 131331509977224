import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import GoogleSignIn from '@Components/application/GoogleSignIn';
import Button, { ButtonTypes } from '@Components/Button';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { emptySelectorValue, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType, UserTitle } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';
import { AuthSuggestions } from '@Routes/auth/shared/SuggestionBlock/SuggestionBlock';
import { register } from '@Store/auth/auth.actions';
import { titleOptions } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';
import {
  validateAny,
  validateConfirmPassword,
  validateEmail,
  validateName,
  validatePassword,
  validateRequired,
  validateRequiredBoolean,
} from '@Utils/validator';

import styles from './BeSpokeLoginForm.scss';

export interface BeSpokeLoginFormProps {
  isAbsolute?: boolean;
  partnerName?: string;
  isGlfp?: boolean;
  landingPage?: string;
  showBrand?: boolean;
}

export interface BeSpokeLoginFormValues {
  title: { value: string; label: string } | undefined;
}

enum BeSpokeLoginFormFields {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  confirmPassword = 'confirm_password',
  terms = 'terms',
  optIn = 'optIn',
  titleOther = 'titleOther',
}

const BeSpokeLoginForm: React.FunctionComponent<BeSpokeLoginFormProps> = ({
  isAbsolute = false,
  partnerName,
  isGlfp,
  landingPage,
  showBrand,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const emailId = searchParams.get('email')?.trim() || '';
  const inviteFirstName = searchParams.get('first')?.trim() || '';
  const inviteLastName = searchParams.get('last')?.trim() || '';
  const inviteRefSource = searchParams.get('ref_src')?.trim() || undefined;
  const inviteTitle = searchParams.get('title')?.trim() || '';
  const inviteCompanyName =
    searchParams.get('company_name')?.trim() || searchParams.get('ref_details')?.trim() || undefined;
  const paramCollaboratorKey = searchParams.get('collaborator_key')?.trim() || undefined;

  const RegisterInputForm = ({ isSubmitting, status, values }: FormikProps<BeSpokeLoginFormValues>) => (
    <>
      <Form>
        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.title}
              component={SelectInput}
              label={t(Messages.fieldTitle)}
              options={titleOptions}
              required
            />
          </GridCol>
        </GridRow>

        {values.title?.value === UserTitle.Other && (
          <GridRow className={styles.spacing}>
            <GridCol size={12}>
              <Field
                name={BeSpokeLoginFormFields.titleOther}
                type="text"
                component={TextInput}
                label={t('field_title_other' as Messages)}
                required
              />
            </GridCol>
          </GridRow>
        )}

        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.firstName}
              type="text"
              component={TextInput}
              label={t(Messages.fieldFirstName)}
              placeholder={t('placeholder_first_legal_name' as Messages)}
              helperText={t('label_name_govt_id' as Messages)}
              required
            />
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.lastName}
              type="text"
              component={TextInput}
              label={t(Messages.fieldSurname)}
              placeholder={t('placeholder_last_name' as Messages)}
              required
            />
          </GridCol>
        </GridRow>
        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.email}
              type="text"
              component={TextInput}
              label={t(Messages.fieldEmail)}
              placeholder={t('field_email_address_placeholder' as Messages)}
              required
            />
          </GridCol>
        </GridRow>
        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.password}
              type="password"
              component={TextInput}
              label={t(Messages.fieldPassword)}
              placeholder={t('field_login_password_placeholder' as Messages)}
              required
            />
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.confirmPassword}
              type="password"
              component={TextInput}
              label={t(Messages.fieldConfirmPassword)}
              placeholder={t('field_repeat_password_placeholder' as Messages)}
              required
            />
          </GridCol>
        </GridRow>
        <Row constant alignCenter className={styles.spacing}>
          <Field name={BeSpokeLoginFormFields.terms} label="" component={Checkbox} type={CheckBoxTypes.checkbox} />

          <label className={styles.checkBoxLabel}>
            I agree to the &nbsp;
            <Link className={styles.links} to={{ pathname: Links.lifeLedger + Links.termsConditions }} target="_blank">
              terms &#38; conditions
            </Link>
            &nbsp; of Life Ledger
          </label>
        </Row>

        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={BeSpokeLoginFormFields.optIn}
              label={t(Messages.fieldOptIn)}
              component={Checkbox}
              type={CheckBoxTypes.checkbox}
            />
          </GridCol>
        </GridRow>

        <Row alignCenter justifyCenter>
          <Button
            type={ButtonTypes.submit}
            disabled={isSubmitting}
            loading={isSubmitting}
            label={t(Messages.buttonCreateAccount)}
          />
        </Row>
        <FormError formError={status} />
      </Form>
      <GoogleSignIn
        accountType={UserAccountType.Individual}
        referralCode={!isGlfp ? partnerName : ''}
        referralSource={inviteRefSource}
        collaboratorKey={paramCollaboratorKey}
        isLoginPage
        beSpokenRegistration
      />
    </>
  );

  const titleInitialValue =
    inviteTitle !== '' ? titleOptions.find((item) => item.value === inviteTitle.toUpperCase()) : emptySelectorValue;

  return (
    <section className={isAbsolute ? styles.absoluteContainer : styles.formContainer}>
      <Formik
        initialValues={{
          [BeSpokeLoginFormFields.title]: titleInitialValue,
          [BeSpokeLoginFormFields.firstName]: inviteFirstName,
          [BeSpokeLoginFormFields.lastName]: inviteLastName,
          [BeSpokeLoginFormFields.email]: emailId,
          [BeSpokeLoginFormFields.password]: '',
          [BeSpokeLoginFormFields.confirmPassword]: '',
          [BeSpokeLoginFormFields.terms]: false,
          [BeSpokeLoginFormFields.optIn]: false,
          [BeSpokeLoginFormFields.titleOther]: '',
        }}
        validationSchema={() => {
          return Yup.lazy((values: any) => {
            return Yup.object({
              [BeSpokeLoginFormFields.firstName]: validateName(t),
              [BeSpokeLoginFormFields.lastName]: validateName(t),
              [BeSpokeLoginFormFields.email]: validateEmail(t),
              [BeSpokeLoginFormFields.password]: validatePassword(t),
              [BeSpokeLoginFormFields.confirmPassword]: validateConfirmPassword(
                t,
                values.password,
                values.confirmPassword
              ),
              [BeSpokeLoginFormFields.terms]: validateRequiredBoolean(t),
              [BeSpokeLoginFormFields.titleOther]:
                values.title.value === UserTitle.Other ? validateRequired(t) : validateAny(),
            });
          });
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          const { firstName, lastName, email, password, title, optIn, titleOther } = values;
          if (title?.value !== '') {
            dispatch(
              register.started({
                setSubmitting,
                setStatus,
                firstName,
                lastName,
                email: email.trim(),
                password,
                accessCode: '',
                title: title?.value,
                accountType: UserAccountType.Individual,
                referralCode: !isGlfp ? partnerName : '',
                referralSource: inviteRefSource,
                referralSourceDetails: inviteCompanyName,
                optOut: optIn,
                isGuidanceRight: true,
                collaboratorKey: paramCollaboratorKey,
                titleOther: titleOther === '' ? undefined : titleOther,
                landingPage,
                showBrand,
              })
            );
          } else {
            dispatch(notificationError('Title is required'));
            setSubmitting(false);
          }
        }}
      >
        {RegisterInputForm}
      </Formik>
      <div className={styles.signUpContainer}>
        <SuggestionBlock suggestLogin={true} authType={AuthSuggestions.register} />
      </div>
    </section>
  );
};

export default BeSpokeLoginForm;
