import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import DataWithLabel from '@Components/DataWithLabel';
import FileIconWithLabel from '@Components/FileIconWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { CaseTags, Links, NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DeathCaseStatus, LifeCaseNode, Permissions, UserTitle } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { capitalize } from '@Utils/helpers';
import { showEmptyModalHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './SummaryInfoDeceased.scss';

export interface SummaryInfoDeceasedProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  isLifeCase?: boolean;
  hideEdit?: boolean;
}

const SummaryInfoDeceased: React.FunctionComponent<SummaryInfoDeceasedProps> = ({
  data,
  isLifeCase = false,
  hideEdit = false,
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const history = useHistory();
  const queryString = history.location.search;
  const dispatch = useDispatch();
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });

  const deathCaseItems = data?.item?.__typename === NodeDefiner.DeathCase ? data?.item : null;

  const lifeCaseItems = data?.item?.__typename === NodeDefiner.LifeCase ? data?.item : null;

  const showEdit =
    deathCaseItems?.status === DeathCaseStatus.Draft ||
    deathCaseItems?.status === DeathCaseStatus.Approved ||
    isLifeCase;

  const firstName = deathCaseItems ? deathCaseItems.firstName : lifeCaseItems?.firstName;
  const lastName = deathCaseItems ? deathCaseItems.lastName : lifeCaseItems?.lastName;
  const birthDate = deathCaseItems ? deathCaseItems.birthDate : lifeCaseItems?.birthDate;
  const title = data.item?.title === UserTitle.Other ? data.item.titleOther : data.item?.title;

  const editForm = () => {
    if (showEdit) {
      history.push(
        generatePath(isLifeCase ? `${Links.lcEdit}${queryString}` : `${Links.dcEdit}${queryString}`, {
          id: isLifeCase ? lifeCaseItems?.id : deathCaseItems?.id,
          subpage: DeathCaseSubpages.General,
          tag: `tag=${CaseTags.deathCaseEdit}`,
        })
      );
    } else {
      showEmptyModalHandler(t(Messages.titleCannotEditCase), t(Messages.descCannotEditCase), dispatch);
    }
  };

  const getRelationship = (): string[] | undefined => {
    const caseItems = isLifeCase ? lifeCaseItems : deathCaseItems;

    return caseItems?.allRelationships?.map((rel: string) =>
      t(`${Messages.labelRelationshipPrefix}${rel.toLowerCase()}` as Messages)
    );
  };

  const isReadOnlyUser = data.item?.myPermission === Permissions.ReadOnly;

  return (
    <>
      <ConfirmationHeader
        showEdit={!isReadOnlyUser && showEdit && !hideEdit}
        title={isLifeCase ? 'General information' : 'Deceased’s Info'}
        onClick={editForm}
      />
      <Paper width="width-full" className={styles.deathCaseContainer}>
        <Row>
          <Col size={5}>
            <Row constant column>
              <Col size={5}>
                <DataWithLabel dashForEmpty data={`${capitalize(title || '-')}`} label="Title" isConfirmationForm />
              </Col>
              <Col size={5}>
                <DataWithLabel
                  dashForEmpty
                  data={[`${capitalize(title || '')}`, firstName, lastName].join(' ')}
                  label="Name"
                  isConfirmationForm
                />
              </Col>
              <Col size={5}>
                <DataWithLabel
                  data={languageBasedDate(birthDate, currentLanguage)}
                  label={t(Messages.labelBirthDate)}
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>

              {deathCaseItems && (
                <Col size={5}>
                  <DataWithLabel
                    data={languageBasedDate(deathCaseItems?.deathDate, currentLanguage)}
                    label={t(Messages.fieldDeathDate)}
                    isConfirmationForm
                    dashForEmpty
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col size={1} />
          <Col size={5}>
            <Row constant column>
              {deathCaseItems && (
                <Col size={5}>
                  <DataWithLabel
                    data={languageBasedDate(deathCaseItems.funeralDate, currentLanguage)}
                    label={t(Messages.fieldFuneralDate)}
                    isConfirmationForm
                    dashForEmpty
                  />
                </Col>
              )}
              {isLifeCase && (
                <Col size={5}>
                  <DataWithLabel
                    dashForEmpty
                    data={lifeCaseItems?.isTerminallyIll ? 'Yes' : 'No'}
                    label="In end of life care/ terminally ill?"
                    isConfirmationForm
                  />
                </Col>
              )}

              <Col size={5}>
                <DataWithLabel
                  dashForEmpty
                  dataArray={getRelationship()}
                  label={
                    isLifeCase ? t('label_life_relationships' as Messages) : t('label_rad_relationships' as Messages)
                  }
                  isConfirmationForm
                />
              </Col>

              {deathCaseItems && (
                <Col size={isIpadMini ? 5 : 12}>
                  <FileIconWithLabel
                    file={deathCaseItems?.certificates.length > 0 ? deathCaseItems?.certificates[0] : null}
                    fileType=""
                    label="Death certificate or interim death certificate"
                  />
                </Col>
              )}

              {lifeCaseItems && (
                <Row constant columnMobile>
                  <Col size={5}>
                    <DataWithLabel
                      data={lifeCaseItems.email}
                      label={t(Messages.fieldEmail)}
                      isConfirmationForm
                      dashForEmpty
                    />
                  </Col>
                </Row>
              )}
            </Row>
          </Col>
        </Row>
      </Paper>
    </>
  );
};

export default SummaryInfoDeceased;
