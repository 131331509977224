import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Paper from '@Components/application/GrayPaper';
import CardTitle from '@Components/CardTitle';
import DataWithLabel from '@Components/DataWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import { CaseAccountDetailsNode, RestrictedCaseServiceProviderNode } from '@Graphql/graphqlTypes.generated';
import { somethingWrongHandler } from '@Utils/modal';

import styles from '../SummaryInfoDeceased/SummaryInfoDeceased.scss';

export interface SummaryAdditionalInfoProps {
  caseDetails: CaseAccountDetailsNode[];
  userAccountType?: string;
  deathCaseServiceProvider?: RestrictedCaseServiceProviderNode;
  accessCode?: string;
}

const SummaryAdditionalInfo: React.FunctionComponent<SummaryAdditionalInfoProps> = ({
  caseDetails,
  userAccountType,
  deathCaseServiceProvider,
  accessCode = '',
}) => {
  const dispatch = useDispatch();

  const additionalComments = caseDetails.find((cd: CaseAccountDetailsNode) =>
    cd.key.toLowerCase().includes('additional comments')
  )?.value;

  return (
    <Paper
      width="width-full"
      className={classNames(userAccountType ? styles.professionalPaper : styles.normalGrayPaper, styles.sameHeight)}
    >
      <div className={styles.deceasedContainer}>
        {deathCaseServiceProvider && (
          <Row justifyEnd>
            <LinkButton
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode);
                }
              }}
              linkTitle="Something wrong?"
              onClick={() => somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode)}
            />
          </Row>
        )}
        <CardTitle
          heading="Additional info"
          description="Extra information and instruction on what to do with this account"
        />
      </div>

      <Row constant columnMobile>
        <Col>
          <DataWithLabel data={additionalComments || ''} label="Comments" />
        </Col>
      </Row>
    </Paper>
  );
};

export default SummaryAdditionalInfo;
