import classNames from 'classnames';
import * as React from 'react';

import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography';
import { hasFieldError } from '@Utils/form';

import ErrorDisplay from '../ErrorDisplay';

import styles from './InputContainer.scss';

export interface InputContainerProps {
  label?: string;
  disabled?: boolean;
  required?: boolean;
  showErrorIcon?: boolean;
  containerClass?: string;
  wrapperClassName?: string;
  meta: any;
  form: any;
  fullWidth?: boolean;
  wrapWhiteSpace?: boolean;
  controlDescription?: string;
  boldLabel?: string;
  toggleBold?: boolean;
  infoMessage?: string;
  infoTitle?: string;
  labelBold?: boolean;
  helperText?: string;
}

const InputContainer: React.FunctionComponent<InputContainerProps> = (props) => {
  const {
    label,
    disabled = false,
    children = [],
    required = false,
    showErrorIcon = false,
    meta,
    form,
    containerClass,
    wrapperClassName,
    wrapWhiteSpace,
    fullWidth,
    controlDescription = '',
    boldLabel,
    toggleBold = false,
    infoMessage,
    infoTitle,
    labelBold,
    helperText,
  } = props;

  const hasError = hasFieldError(meta, form);
  return (
    <div
      className={classNames(styles.container, wrapperClassName, {
        [styles.disabled]: disabled,
        // [styles.required]: required,
        [styles.fullWidth]: fullWidth,
        [styles.wrapWhiteSpace]: wrapWhiteSpace,
      })}
    >
      {label && boldLabel === undefined && (
        <div
          className={classNames(styles.label, {
            [styles.flexDisplay]: infoMessage,
            [styles.bold]: labelBold,
            [styles.helperDisplay]: helperText,
          })}
        >
          {`${label}${required ? '*' : ''}`}
          {infoMessage && (
            <Tooltip
              position={TooltipPosition.top}
              message={infoMessage}
              title={infoTitle}
              className={styles.tooltipMaxWidth}
            >
              <Icon size={IconSizes.sxs} icon="info" />
            </Tooltip>
          )}
          {helperText && (
            <>
              &nbsp;
              <Typography msg={helperText} tag="div" size="m" color="footerColor" className={styles.nearLabel} />
            </>
          )}
        </div>
      )}
      {label && boldLabel && !toggleBold && (
        <span className={styles.label}>
          {label}
          <b>{boldLabel}</b>
        </span>
      )}
      {label && boldLabel && toggleBold && !infoMessage && (
        <span className={styles.label}>
          <b>{boldLabel}</b>
          &nbsp;
          {label}
        </span>
      )}
      {label && boldLabel && toggleBold && infoMessage && (
        <div className={classNames(styles.label, { [styles.bold]: labelBold })}>
          <span className={classNames(styles.label)}>
            <b>{boldLabel}</b>
            &nbsp;
            {label}
          </span>
          {infoMessage && (
            <Tooltip
              position={TooltipPosition.right}
              message={infoMessage}
              title={infoTitle}
              className={styles.tooltipMaxWidth}
            >
              <Icon size={IconSizes.sxs} icon="info" className={styles.ml4} />
            </Tooltip>
          )}
        </div>
      )}
      {controlDescription && (
        <Typography
          className={classNames(styles.helperText)}
          msg={controlDescription}
          tag="span"
          size="m"
          color="footerColor"
        />
      )}
      <div className={classNames(containerClass, styles.relative)}>{children}</div>
      {hasError && (
        <ErrorDisplay msg={meta.error?.value || meta.error || meta.submitError} showErrorIcon={showErrorIcon} />
      )}
    </div>
  );
};

export default InputContainer;
