import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { SubscriptionPaymentNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { capitalize } from '@Utils/helpers';

import styles from './BillingCard.scss';

export enum PaymentStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
}

export interface BillingCardProps {
  paymentStatus: PaymentStatus;
  userSubscription: SubscriptionPaymentNode;
  //lifeCase: LifeCaseNode;
}
const BillingCard: React.FunctionComponent<BillingCardProps> = ({ paymentStatus, userSubscription }) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const isDiscovery = userSubscription.product.features[0].toLowerCase() === 'discovery';

  const packageName =
    userSubscription.amountPaidInPence === 1200
      ? t('label_basic_package' as Messages)
      : t('label_discovery_package' as Messages);

  const name = `${capitalize(userSubscription.subscription.baseCase.title)} ${capitalize(
    userSubscription.subscription.baseCase.firstName
  )} ${capitalize(userSubscription.subscription.baseCase.lastName)}`;

  const roundPenceToPounds = (pence: number) => {
    const decPence = pence / 100;
    return Math.round(decPence);
  };

  return (
    <Row
      size={12}
      alignCenter
      className={classNames(
        styles.billingContainer,
        paymentStatus === PaymentStatus.Unpaid ? styles.unpaidContainer : styles.paidContainer
      )}
    >
      <Col size={3}>
        <Typography msg={name} tag="div" bold size="m" />
      </Col>
      <Col className={styles.designation} size={4}>
        <Icon className={styles.personIcon} size={IconSizes.sxs} icon={isDiscovery ? 'star' : 'person'} />
        <Typography msg={packageName} tag="div" size="m" />
      </Col>
      <Col size={1}>
        <Typography
          msg={`${userSubscription.product.currencySymbol}${roundPenceToPounds(userSubscription.amountPaidInPence)}`}
          tag="div"
          size="m"
        />
      </Col>
      <Col size={3}>
        <span className={paymentStatus === PaymentStatus.Unpaid ? styles.unpaidPanel : styles.paidPanel}>
          <Icon
            icon={paymentStatus === PaymentStatus.Unpaid ? 'more-horizontal' : 'doneAll'}
            className={styles.paymentStatusIcon}
            size={IconSizes.ss}
          />

          <Typography
            msg={
              paymentStatus === PaymentStatus.Unpaid
                ? t('label_payment_pending' as Messages)
                : t('label_paid' as Messages)
            }
            tag="span"
            size="s"
            bold
            color={paymentStatus === PaymentStatus.Unpaid ? 'yellowishOrange' : 'profRegisterBackground'}
            className={styles.roleText}
          />
        </span>
      </Col>
      <Col size={3}>
        {paymentStatus === PaymentStatus.Paid && (
          <div className={styles.dateColumn}>
            <Icon size={IconSizes.s16} icon="doneAll" className={styles.doubleTickIcon} />
            <Typography msg={t('label_paid' as Messages)} tag="div" size="xs" />
            <Typography
              msg={languageBasedDate(userSubscription.subscription.updatedAt, currentLanguage)}
              tag="div"
              size="xs"
            />
          </div>
        )}
      </Col>
      <Col size={1}>
        <Icon
          icon="download"
          size={IconSizes.s24}
          className={styles.downloadIcon}
          onClick={() => window.open(userSubscription.invoiceLink, '_blank')}
        />
      </Col>
    </Row>
  );
};

export default BillingCard;
