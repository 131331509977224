import classNames from 'classnames';
import * as React from 'react';
import { Link, generatePath, useHistory } from 'react-router-dom';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { ActivityStreamNode, ActivityStreamNotificationType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { decideStatus } from '@Utils/helpers';

import styles from './ActivityCard.scss';

export interface ActivityCardProps {
  data: ActivityStreamNode;
}
const ActivityCard: React.FunctionComponent<ActivityCardProps> = ({ data }) => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;

  const statusUpdates = () => {
    let activityMessage = '';
    switch (data.notificationType) {
      case ActivityStreamNotificationType.StatusUpdate: {
        const deathCaseName = `${data.caseServiceProvider.deceasedFirstname} ${data.caseServiceProvider.deceasedSurname}`;
        const spName = data.caseServiceProvider.serviceProvider.name;
        const status = decideStatus(data.caseServiceProvider.status).statusTitle;
        activityMessage = `${deathCaseName}: ${spName} notification has changed status to: ${status}`;
        break;
      }
      case ActivityStreamNotificationType.ChatMessage: {
        const deathCaseName = `${data.caseServiceProvider.deceasedFirstname} ${data.caseServiceProvider.deceasedSurname}`;
        const spName = data.caseServiceProvider.serviceProvider.name;
        activityMessage = `Message from ${spName} regarding ${deathCaseName}`;
        break;
      }
      default:
        break;
    }
    return activityMessage;
  };

  const toLink = () => {
    if (data.notificationType === ActivityStreamNotificationType.StatusUpdate) {
      return generatePath(`${Links.notificationSummary}${queryString}`, {
        id: data.caseServiceProvider.id,
        deathCaseId: data.dcId ? data.dcId : 'RGVhdGhDYXNlTm9kZTo3NjY=',
        isLifeCase: false,
      });
    }

    return generatePath(`${Links.notificationSummary}${queryString}`, {
      id: data.caseServiceProvider.id,
      deathCaseId: data.dcId ? data.dcId : 'RGVhdGhDYXNlTm9kZTo3NjY=',
      isLifeCase: false,
    });
  };

  return (
    <Row justifyBetween alignCenter className={styles.activityContainer} size={12}>
      <Col>
        <Row alignCenter>
          <Icon
            icon={data.notificationType === ActivityStreamNotificationType.ChatMessage ? 'email' : 'bell'}
            className={classNames(styles.rightSpacing, data.isSeen ? styles.seenIcon : styles.unSeenIcon)}
          />
          {!data.isSeen && <div className={styles.newNotify} />}
          <Typography
            msg={statusUpdates()}
            tag="div"
            size="m"
            bold={!data.isSeen}
            className={data.isSeen ? styles.seenIcon : styles.unSeenIcon}
          />
        </Row>
      </Col>
      <Link to={toLink()}>
        <Typography
          className={classNames(styles.handSymbol, data.isSeen ? styles.seenIcon : styles.unSeenIcon)}
          msg={t(Messages.linkViewNotification)}
          tag="div"
          size="m"
          underline
        />
      </Link>
    </Row>
  );
};

export default ActivityCard;
