import classNames from 'classnames';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { resendEmail, resendVerification } from '@Store/auth/auth.actions';
import { hideTrueEmail } from '@Utils/form';

import styles from './EmailSentPage.scss';

export interface EmailSentPageProps {
  isRegister?: boolean;
  email: string;
  setStatus: (status: string) => void;
}

const EmailSentPage: React.FunctionComponent<EmailSentPageProps> = ({ isRegister, email, setStatus }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const { isSubmitting, setSubmitting } = useFormikContext();
  const titleKey = isRegister ? t(Messages.labelVerificationLinkSent) : t(Messages.labelCheckYourEmail);
  const resendRequest = isRegister ? resendVerification.started : resendEmail.started;

  return (
    <>
      <Typography
        color="black"
        size="l"
        tag="div"
        msg={titleKey}
        family="helvetica"
        className={classNames(styles.lineHeight, styles.spaceBetween)}
      />
      {!isRegister && (
        <Typography
          color="black"
          size="l"
          tag="div"
          msg={t(Messages.labelAccountExistsEmailSent)}
          family="helvetica"
          className={classNames(styles.lineHeight, styles.spaceBetween)}
        />
      )}
      <Typography
        color="black"
        size="xxl"
        tag="div"
        msg={hideTrueEmail(email)}
        className={classNames(styles.lineHeight, styles.spaceBetween)}
      />
      {!isSubmitting && (
        <>
          <Row className={styles.secondLine} constant>
            <Typography color="black" size="l" tag="div" msg={t(Messages.labelEmailCheckSteps)} />
          </Row>
          <div className={styles.alignTextCenter}>
            <LinkButton
              className={styles.linkButton}
              onClick={() => dispatch(resendRequest({ email, setSubmitting, setStatus }))}
              linkTitle={t(Messages.linkResendEmailResetPassword)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EmailSentPage;
