import { classNames } from 'primereact/utils';
import * as React from 'react';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper/';
import DataWithLabel from '@Components/DataWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { CaseServiceProviderNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import styles from '../SummaryInfoDeceased/SummaryInfoDeceased.scss';

export interface SummaryInfoVulnerabilityProps {
  caseServiceProvider: CaseServiceProviderNode;
  showTitle?: boolean;
  userAccountType?: string;
}

const SummaryInfoVulnerability: React.FunctionComponent<SummaryInfoVulnerabilityProps> = ({
  caseServiceProvider,
  showTitle,
  userAccountType,
}) => {
  const t = useTranslations();

  const checkNatureIsDefined = (input: any) => {
    // Check if the input is null
    if (input === null) {
      //eslint-disable-next-line
      console.log('Input is null');
      return false;
    }

    // Check if the input is a string
    if (typeof input === 'string') {
      return input !== '';
    }

    // Check if the input is already an array
    if (Array.isArray(input)) {
      return input.length > 0;
    }
    return input;
  };

  const natureItems = () => {
    const vulnerabilityNature = caseServiceProvider.vulnerableInfo.vulnerabilityNature as
      | string
      | string[]
      | null
      | undefined;

    if (Array.isArray(vulnerabilityNature)) {
      // If vulnerabilityNature is already an array, map through it
      const mappedNature = vulnerabilityNature.map((vn: string) =>
        t(`label_vulnerable_nature_${vn.toLowerCase()}` as Messages)
      );
      return mappedNature;
    }

    if (typeof vulnerabilityNature === 'string') {
      try {
        // Replace single quotes with double quotes and parse it into an array
        const parsedArray = JSON.parse(vulnerabilityNature.replace(/'/g, '"')) as string[];

        if (Array.isArray(parsedArray)) {
          const mappedNature = parsedArray.map((vn: string) =>
            t(`label_vulnerable_nature_${vn.toLowerCase()}` as Messages)
          );
          return mappedNature;
        }
      } catch (error) {
        //eslint-disable-next-line
        console.error('Invalid JSON format for vulnerabilityNature:', error);
      }
    }

    return []; // Return an empty array if it's neither a string nor an array
  };

  return (
    <>
      {showTitle && <ConfirmationHeader title={t('title_notifier_vulnerability_details' as Messages)} />}

      <Paper
        width="width-full"
        className={classNames(userAccountType ? styles.professionalPaper : styles.normalGrayPaper, styles.sameHeight)}
      >
        {caseServiceProvider.vulnerableInfo.declaredVulnerability && (
          <Row constant columnMobile>
            <Col>
              <DataWithLabel
                data={caseServiceProvider.vulnerableInfo.declaredVulnerability ? 'Yes' : 'No'}
                label={t('label_notifier_has_vulnerability' as Messages)}
                dashForEmpty
              />
            </Col>
          </Row>
        )}
        {checkNatureIsDefined(caseServiceProvider.vulnerableInfo.vulnerabilityNature) && (
          <Row column>
            <Typography
              color="footerColor"
              tag="div"
              msg={t('label_notifier_vulnerability_nature' as Messages)}
              size="s"
              bold
            />

            <List listItems={natureItems()} containerClassName={styles.noPadding} />
          </Row>
        )}
        {caseServiceProvider.vulnerableInfo.vulnerabilityOtherDetails && (
          <Row>
            <Col>
              <DataWithLabel
                data={caseServiceProvider.vulnerableInfo.vulnerabilityOtherDetails}
                dashForEmpty
                label={t('label_notifier_vulnerability_other_details' as Messages)}
              />
            </Col>
          </Row>
        )}
      </Paper>
    </>
  );
};

export default SummaryInfoVulnerability;
