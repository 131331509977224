import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';

import AuthPageLayout from '@Components/layout/AuthPageLayout';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';
import { AuthSuggestions } from '@Routes/auth/shared/SuggestionBlock/SuggestionBlock';

import IndividualRegisterForm from './IndividualRegisterForm';

export interface IndividualRegisterPageProps {
  emailSentTo: string;
  setEmailSentTo: Dispatch<SetStateAction<string>>;
}

const IndividualRegisterPage: React.FunctionComponent<IndividualRegisterPageProps> = () => {
  const { accountType } = useParams();
  const [emailSentTo, setEmailSentTo] = useState('');

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  return (
    <AuthPageLayout
      titleKey={Messages.registerFormTitle}
      isRegisterForm={emailSentTo === undefined || emailSentTo === ''}
      isSmallLayout={false}
      leftBottomComponent={<SuggestionBlock suggestLogin={true} authType={AuthSuggestions.register} />}
      accountType={accountType}
      emailSentTo={emailSentTo}
      mobileRegisterFormHeight="100rem"
      showFooter={!(isTablet || isMobile)}
    >
      <IndividualRegisterForm emailSentTo={emailSentTo} setEmailSentTo={setEmailSentTo} />
    </AuthPageLayout>
  );
};

export default IndividualRegisterPage;
