import classNames from 'classnames';
import React from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, Link, useHistory } from 'react-router-dom';

import Breadcrumb, { BreadcrumbItem } from '@Components/Breadcrumb';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Container from '@Components/layout/Container';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
//import MissingInfoBlock from '@Routes/deathCase/ReadyDCPage/ServicesPage/MissingInfoBlock';
import { NonAdminSettingsSubpages, SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { logout } from '@Store/auth/auth.actions';
import { getIsAccountInfoMissing, getUser } from '@Store/auth/auth.selector';

import Hamburger from './Hamburger';
import styles from './Header.scss';

export interface HeaderProps {
  breadcrumbItems?: BreadcrumbItem[];
  backLink?: { title: string; link: string };
  isSettingsActive?: boolean;
  titleOnMobile: string;
  hideUpgrade?: boolean;
  hideBackLink?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  breadcrumbItems,
  isSettingsActive,
  titleOnMobile,
  backLink,
  hideBackLink,
  // hideUpgrade,
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const history = useHistory();
  const queryString = history.location.search;
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(1);
  const dispatch = useDispatch();
  const isAccountInfoMissing = useSelector(getIsAccountInfoMissing);
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const formMenu = () => {
    if (user?.accountType === UserAccountType.ServiceProvider) {
      return (
        <span {...itemProps[0]} className={styles.spMenuContainer} onClick={() => dispatch(logout())}>
          <Icon size={IconSizes.sss} icon="logout" />
          <Typography color="pineGreen" size="m" tag="span" msg={t(Messages.linkLogout)} />
        </span>
      );
    }
    return (
      <>
        <span
          {...itemProps[0]}
          className={styles.accMgmtContainer}
          onClick={() =>
            history.push(
              generatePath(`${Links.settings}?${queryString}`, {
                subpage:
                  user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
                    ? NonAdminSettingsSubpages.IDValidation
                    : SettingsSubpages.Account,
              })
            )
          }
        >
          <Icon size={IconSizes.sss} icon="settings-yellow" />
          <Typography color="pineGreen" size="m" tag="span" msg={t(Messages.pageSettings)} />
        </span>
        <span {...itemProps[1]} className={styles.logOutContainer} onClick={() => dispatch(logout())}>
          <Icon size={IconSizes.sss} icon="logout" />
          <Typography color="pineGreen" size="m" tag="span" msg={t(Messages.linkLogout)} />
        </span>
      </>
    );
  };

  React.useEffect(() => {
    if (!isTablet) {
      setMobileMenuOpen(false);
    }
  }, [isTablet]);

  return (
    <Container className={classNames({ [styles.mobileContainer]: isMobileMenuOpen })}>
      <header className={classNames(styles.header, { [styles.mobileHeader]: isMobileMenuOpen })}>
        <div className={styles.logoContainer}>
          <a href={`${Links.home}${queryString}`}>
            <Icon icon="logo-full" size={IconSizes.logoFull} />
          </a>
        </div>

        {isTablet && (
          <>
            <Col size={8} constant textCenter alignCenter>
              {isMobileMenuOpen && <Typography color="black" size="l" tag="span" bold msg={titleOnMobile} />}
            </Col>
            <Col size={1} constant alignCenter>
              <Hamburger isOpen={isMobileMenuOpen} handleClick={() => setMobileMenuOpen(!isMobileMenuOpen)} />
            </Col>
          </>
        )}
        <div className={classNames(styles.linksContainer, { [styles.openInTablet]: isMobileMenuOpen })}>
          <span className={classNames(styles.settingsContainer, styles.active)}>
            <div className={classNames(styles.settingsIcon, { [styles.settingsMissing]: isAccountInfoMissing })}>
              <Icon size={IconSizes.sss} icon="text" />
            </div>
            {isSettingsActive ? (
              <Link
                to={generatePath(`${Links.settings}?${queryString}`, {
                  subpage:
                    user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
                      ? NonAdminSettingsSubpages.IDValidation
                      : SettingsSubpages.Account,
                })}
              >
                <Typography color="black" size="m" tag="span" msg={userName} />
              </Link>
            ) : (
              <span className={styles.nameContainer} {...buttonProps} onClick={() => setIsOpen(!isOpen)}>
                <Typography color="black" className={styles.leftSpacing} size="m" tag="span" msg={userName} />
              </span>
            )}
          </span>
          {isTablet && (
            <span className={styles.logOutContainer} onClick={() => dispatch(logout())}>
              <Icon size={IconSizes.sss} icon="logout" />
              <Typography color="pineGreen" size="m" tag="span" msg={t(Messages.linkLogout)} />
            </span>
          )}
          {!isSettingsActive && (
            <div className={classNames(styles.demoMenu, { [styles.visible]: isOpen })} role="menu">
              {formMenu()}
            </div>
          )}
        </div>
      </header>
      {(!isTablet || backLink) && !hideBackLink && (
        <div className={classNames(styles.breadcrumb)}>
          {backLink ? (
            <Link className={styles.backLink} to={backLink.link}>
              {'← '}
              {backLink.title}
            </Link>
          ) : (
            <Breadcrumb breadcrumbItems={breadcrumbItems} />
          )}
        </div>
      )}
    </Container>
  );
};

export default Header;
