import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput, { InputTypes } from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { emptySelectorValue } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  AccountDataDefinitionInputType,
  AddDataDefinitionMutation,
  AddDataDefinitionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { queryServiceProviderDataFields } from '@Graphql/providers/queries';
import { mutationAddDataDefinition } from '@Graphql/serviceProvidersPortal/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateAny, validateRequired, validateSelector } from '@Utils/validator';

import styles from '../NewProviderFormModalBody/NewProviderFormModalBody.scss';

export interface DataDefinitionModalBodyProps {
  serviceProviderId: string;
  newOrder?: number;
  closeForm: () => void;
}

enum DataDefinitionFormFields {
  inputLabel = 'inputLabel',
  inputType = 'inputType',
  inputValues = 'inputValues',
  isPrimaryIdentifier = 'isPrimaryIdentifier',
  isMandatory = 'isMandatory',
  isMultipleOptions = 'isMultipleOptions',
}

export enum CustomAccountDataDefinitionInputType {
  Textbox = 'TEXTBOX',
  Textarea = 'TEXTAREA',
  Dropdown = 'DROPDOWN',
  Date = 'DATE',
  MultipleChoice = 'MULTIPLECHOICE',
  FileUpload = 'FILE_UPLOAD',
}

const DataDefinitionModalBody: React.FunctionComponent<DataDefinitionModalBodyProps> = ({
  serviceProviderId,
  newOrder,
  closeForm,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);

  const inputTypeOptions = Object.values(CustomAccountDataDefinitionInputType).map((inputType: any) => ({
    label: t(`${Messages.labelDataDefnInputTypePrefix}${inputType.toLowerCase()}` as Messages),
    value: inputType,
  }));

  const initialValues = {
    [DataDefinitionFormFields.inputLabel]: '',
    [DataDefinitionFormFields.inputType]: emptySelectorValue,
    [DataDefinitionFormFields.inputValues]: '',
    [DataDefinitionFormFields.isPrimaryIdentifier]: false,
    [DataDefinitionFormFields.isMandatory]: false,
    [DataDefinitionFormFields.isMultipleOptions]: false,
  };

  const [addDataDefinition] = useMutation<AddDataDefinitionMutation, AddDataDefinitionMutationVariables>(
    mutationAddDataDefinition,
    {
      refetchQueries: [getOperationName(queryServiceProviderDataFields) || ''],
      onCompleted: () => {
        setIsSending(false);
        dispatch(notificationSuccess(t(Messages.msgSuccessDataDefinitionAdded)));
        closeForm();
      },
      onError: (error: any) => {
        setIsSending(false);
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const getAccountDataDefns = (fieldType: string, isMultiOption: boolean): AccountDataDefinitionInputType => {
    if (fieldType === CustomAccountDataDefinitionInputType.MultipleChoice) {
      return isMultiOption ? AccountDataDefinitionInputType.Checkbox : AccountDataDefinitionInputType.Radiobutton;
    }
    if (fieldType === CustomAccountDataDefinitionInputType.Date) {
      return AccountDataDefinitionInputType.Date;
    }
    if (fieldType === CustomAccountDataDefinitionInputType.Dropdown) {
      return AccountDataDefinitionInputType.Dropdown;
    }
    if (fieldType === CustomAccountDataDefinitionInputType.Textarea) {
      return AccountDataDefinitionInputType.Textarea;
    }
    if (fieldType === CustomAccountDataDefinitionInputType.FileUpload) {
      return AccountDataDefinitionInputType.FileUpload;
    }
    return AccountDataDefinitionInputType.Textbox;
  };

  const showTextArea = (values: any) => {
    return (
      values.inputType.value === CustomAccountDataDefinitionInputType.Dropdown ||
      values.inputType.value === CustomAccountDataDefinitionInputType.MultipleChoice
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() =>
        Yup.lazy((values: any) => {
          return Yup.object({
            [DataDefinitionFormFields.inputLabel]: validateRequired(t),
            [DataDefinitionFormFields.inputType]: validateSelector(t),
            [DataDefinitionFormFields.inputValues]: showTextArea(values) ? validateRequired(t) : validateAny(),
          });
        })
      }
      onSubmit={(values) => {
        setIsSending(true);
        const { inputLabel, isMultipleOptions, isMandatory, isPrimaryIdentifier } = values;
        addDataDefinition({
          variables: {
            input: {
              inputLabel,
              inputValues: showTextArea(values) ? values.inputValues.split(',') : [],
              inputType: getAccountDataDefns(values.inputType.value, isMultipleOptions),
              serviceProvider: serviceProviderId,
              displayOrder: newOrder,
              isMandatory,
              isPrimaryIdentifier,
            },
          },
        });
      }}
    >
      {({ status, handleSubmit, values }) => {
        return (
          <Form className={styles.formContainer} onSubmit={handleSubmit}>
            <GrayPaper width="width-full">
              <Typography msg={t('New required field' as Messages)} tag="div" size="m" bolder />
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={DataDefinitionFormFields.inputLabel}
                    type={InputTypes.text}
                    component={TextInput}
                    label={t(Messages.titleDataDefnInputLabel)}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={DataDefinitionFormFields.inputType}
                    label={t(Messages.titleDataDefnInputType)}
                    component={SelectInput}
                    options={inputTypeOptions}
                  />
                </GridCol>
              </GridRow>
              {showTextArea(values) && (
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={DataDefinitionFormFields.inputValues}
                      type={InputTypes.textArea}
                      component={TextInput}
                      placeholder={t(Messages.msgCommaSeparationPlaceHolder)}
                      label={t('label_input_control_values' as Messages)}
                      controlDescription={t('label_input_values_help_text' as Messages)}
                    />
                  </GridCol>
                </GridRow>
              )}
              {values.inputType.value === CustomAccountDataDefinitionInputType.MultipleChoice && (
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={DataDefinitionFormFields.isMultipleOptions}
                      component={ToggleSwitchInput}
                      label={t('field_multiple_option_question' as Messages)}
                    />
                  </GridCol>
                </GridRow>
              )}

              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={DataDefinitionFormFields.isPrimaryIdentifier}
                    component={ToggleSwitchInput}
                    label={t(Messages.titleDataDefnIsPrimaryIdentifier)}
                    infoMessage={t('tooltip_desc_primary_identifier' as Messages)}
                    infoTitle={t('tooltip_title_primary_identifier' as Messages)}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={DataDefinitionFormFields.isMandatory}
                    component={ToggleSwitchInput}
                    label={t(Messages.titleDataDefnIsMandatory)}
                    infoMessage={t('tooltip_desc_mandatory_field' as Messages)}
                    infoTitle={t('tooltip_title_mandatory_field' as Messages)}
                  />
                </GridCol>
              </GridRow>

              <FormError formError={status} />
            </GrayPaper>
            <Row alignCenter className={styles.gap24}>
              <Col size={7}>
                <Button
                  disabled={isSending}
                  loading={isSending}
                  isFullWidth
                  constant
                  label={t(Messages.buttonSave)}
                  type={ButtonTypes.submit}
                />
              </Col>
              <Col size={5}>
                <Button
                  style={ButtonStyles.transparent}
                  onClick={closeForm}
                  constant
                  isFullWidth
                  label={t('button_discard_changes' as Messages)}
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataDefinitionModalBody;
