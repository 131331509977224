import { classNames } from 'primereact/utils';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useLocation } from 'react-router';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { Links, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { usePackagesQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';

import { ModalProviderProps } from '../Modal';

import styles from './LifeEventPaymentModalBody.scss';

export interface PaymentDetailsModalBodyProps {
  changeNameField: boolean;
}

const PaymentDetailsModalBody: React.FunctionComponent<ModalProviderProps<PaymentDetailsModalBodyProps>> = ({
  modalData: { changeNameField },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.replace('/', '');
  const history = useHistory();
  const queryString = history.location.search;
  const isIpadAir = useMediaQuery({ query: ReactResponsiveQueries.IpadAir });

  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    // Function to be executed on click
    const handleClick = () => {
      dispatch(
        showModal({
          type: ModalTypes.obBanksNoSupport,
          params: {
            title: '',
            modalSize: PaperWidths.m672,
            noPadding: true,
            data: {
              selectedInstitute: undefined,
              baseCase: undefined,
            },
          },
        })
      );
    };

    setTimeout(() => {
      const element = document.getElementById('unsupported_banks');

      // Check if the element exists before adding the event listener
      if (element) {
        // Attach the click event listener
        element.addEventListener('click', handleClick);
      }

      // Clean up the event listener when the component unmounts
      return () => {
        if (element) {
          element.removeEventListener('click', handleClick);
        }
      };
    }, 1000);

    // Get the HTML element by its ID or any other method
  }, [dispatch]);

  if (packagesData.loading) {
    return <LoadingSpinner />;
  }

  const lifeEventPackage = packagesData.data?.packages.find((p: any) => p.features.includes('LIFE_EVENT'));

  return (
    <div className={styles.bodyContainer}>
      <Row
        className={styles.flex}
        constant
        onClick={() => {
          dispatch(
            showModal({
              type: ModalTypes.changeAddressModal,
              params: {
                title: '',
                modalSize: PaperWidths.ml,
                disableOutsideClick: true,
                data: {
                  changeNameField,
                },
              },
            })
          );
        }}
      >
        <Icon icon="chevronLeft" size={IconSizes.s24} />
        <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
      </Row>
      <Row column={isIpadAir}>
        <Col size={isIpadAir ? 12 : 7} className={styles.rightSideContainer}>
          <div className={styles.seperator}>
            {!changeNameField && <Typography msg="Change address" size="xxl" tag="div" bold className={styles.mb32} />}
            {changeNameField && <Typography msg="Change name" size="xxl" tag="div" bold className={styles.mb32} />}
            <Typography msg="How it works" size="xl" tag="div" bold className={styles.mb8} />
            {!changeNameField && (
              <Typography msg={t('desc_how_it_works' as Messages)} size="l" tag="div" className={styles.mb32} />
            )}
            {changeNameField && (
              <Typography
                msg="We make it easy for you, you tell us one time the companies that need to know of your name change, and we do the rest"
                size="l"
                tag="div"
                className={styles.mb32}
              />
            )}

            <Row className={styles.ml32}>
              <Col>
                <Row constant alignCenter className={styles.gap13}>
                  <Col className={styles.blueCircle} />
                  <Typography msg={t('title_provide_details' as Messages)} tag="div" size="l" bolder />
                </Row>
                <Row constant className={styles.gap14}>
                  <Col className={styles.blueBorder} />
                  <Typography msg={t('desc_provide_details' as Messages)} tag="div" size="l" />
                </Row>
                <Row constant alignCenter className={styles.gap13}>
                  <Col className={styles.blueCircle} />
                  <Typography msg={t('title_choose_company' as Messages)} tag="div" size="l" bolder />
                </Row>
                <Row constant className={styles.gap14}>
                  <Col className={styles.blueBorder} />
                  <Typography msg={t('desc_choose_company' as Messages)} tag="div" size="l" />
                </Row>
                <Row constant alignCenter className={styles.gap13}>
                  <Col className={styles.blueCircle} />
                  <Typography msg={t('title_change_address' as Messages)} tag="div" size="l" bolder />
                </Row>
                <Row constant className={styles.gap14}>
                  <Col className={styles.transparentBorder} />
                  <Typography msg={t('desc_change_address' as Messages)} tag="div" size="l" />
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col size={isIpadAir ? 12 : 6}>
          <Row column justifyCenter>
            <div className={styles.packageCard}>
              {lifeEventPackage?.currencySymbol && (
                <Row constant justifyCenter>
                  <Typography size="l" msg="From" tag="div" className={styles.mt33} />
                  &nbsp;
                  <Typography
                    bold
                    msg={`${lifeEventPackage.currencySymbol}${lifeEventPackage.unitAmount}`}
                    tag="div"
                    size="xxxl"
                  />
                </Row>
              )}

              <Typography
                className={styles.discoveryPackDesc}
                msg={t('desc_discovery_pack' as Messages)}
                html
                tag="span"
              />
              <Row constant alignCenter className={classNames(styles.f16, styles.listItemGap, styles.mt24)}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
                <Typography
                  className={classNames(styles.f16, styles.footerColor)}
                  msg={t('msg_one_time_fee' as Messages)}
                  tag="div"
                  align="left"
                />
              </Row>

              <Row constant alignCenter className={classNames(styles.listItemGap, styles.mb21)}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
                <Typography
                  className={classNames(styles.f16, styles.footerColor)}
                  msg={t('msg_unlimited_sp_address_change' as Messages)}
                  tag="div"
                  align="left"
                />
              </Row>
              <Row constant alignCenter className={classNames(styles.listItemGap, styles.mb21)}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
                <Typography
                  className={classNames(styles.f16, styles.footerColor)}
                  msg={t('msg_provide_information' as Messages)}
                  tag="div"
                  align="left"
                />
              </Row>
              {/* {lifeEventPackage?.unitAmount && ( */}
              <Button
                label={t('button_upgrade_now' as Messages)}
                isFullWidth
                className={classNames(styles.f18, styles.subscribeButton)}
                onClick={() => {
                  // stripeCreateDcSubscriptionMutation({
                  //   variables: {
                  //     input: {
                  //       priceId: lifeEventPackage.priceId || '',
                  //     },
                  //   },
                  // });
                  history.push(
                    generatePath(`${Links.lifeEvent}${queryString}`, {
                      type: pathname,
                    })
                  );
                  closeModal();
                }}
              />
              {/* )} */}
            </div>
            <Col size={isIpadAir ? 7 : 10}>
              <Typography
                msg={t('msg_cancel_payment' as Messages)}
                tag="div"
                size="m"
                color="footerColor"
                textCenter
                align="center"
                className={styles.mt12}
              />
              <a href="/">
                <Typography
                  msg={t('msg_more_info' as Messages)}
                  tag="div"
                  size="l"
                  align="center"
                  textCenter
                  className={styles.mt12}
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentDetailsModalBody;
