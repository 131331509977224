import { Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import FileView from '@Components/application/FileView';
import Button, { ButtonStyles } from '@Components/Button';
import FormError from '@Components/FormError';
import { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DocumentDocumentType, DocumentNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { uploadDocuments } from '@Store/dc/dc.actions';
import { manipulateTranslations } from '@Utils/helpers';
import { deleteConfirmationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './LegalDocumentsForm.scss';

export interface LegalDocumentsFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
}

export interface LegalDocumentsFormStateProps {
  data: LoadableItem<DeathCaseNode>;
}

interface LegalDocumentsFormPrivateProps extends LegalDocumentsFormProps, LegalDocumentsFormStateProps {}

const LegalDocumentsForm: React.FunctionComponent<LegalDocumentsFormPrivateProps> = ({
  data,
  back,
  next,
  isLifeCase,
  setSubpage,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const setNextStep = () => (next ? setSubpage(next) : {});

  const legalDocuments = data.item ? data.item.powerOfAttorneys.concat(data.item.instructionLetters) : [];

  const removeDocumentHandler = (id: any, index: number) => {
    return deleteConfirmationHandler(t, dispatch, DocumentDocumentType.InstructionLetter)(id, index);
  };

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const fileUploadHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.fileUploadModal,
        params: {
          data: {
            buttonText: 'Continue',
            isLifeCase,
            isLegalDocuments: true,
            onClose: (documentType: DocumentDocumentType | undefined, files: File[]) => {
              dispatch(
                uploadDocuments.started({
                  baseCaseId: id,
                  documentType,
                  files,
                })
              );
            },
          },
          // title: t(Messages.modalUploadDocumentTitle),
          // subtitle: t(Messages.modalUploadDocumentSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  return (
    <div className={styles.formWidth}>
      <CautionBox
        containerClassName={styles.cautionBox}
        info={manipulateTranslations(
          t,
          'label_caution_dc_legal_form_two',
          "Completing this section is not essential for sending notifications. However, having details of the deceased's solicitor or a solictior or probate firm dealing with the estate will provide a useful point of contact for the companies being notified."
        )}
        title={t(Messages.labelTip)}
      />

      <Typography tag="div" msg={t('label_legal_document_upload' as Messages)} size="l" className={styles.topSpacing} />

      <Formik
        initialValues={
          {
            // [CertificateFormFields.deathRegisteredDate]: deathRegisteredDate,
            // [LegalDocumentsFormFields.hasCertificate]: hasCertificateFiles,
          }
        }
        validationSchema={() => {
          return Yup.lazy(() => {
            return Yup.object({
              // [CertificateFormFields.deathRegisteredDate]: hasCertificate ? validateDate(t) : validateAny(),
            });
          });
        }}
        onSubmit={() => {
          setNextStep();
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <div>
              <>
                <div className={styles.topSpacing15}>
                  <div onClick={() => fileUploadHandler()} className={styles.margintop32}>
                    <section className={styles.wrapperBlock}>
                      <Row justifyCenter constant>
                        <Button
                          style={ButtonStyles.transparent}
                          onClick={() => fileUploadHandler()}
                          icon="upload"
                          iconSize={IconSizes.ss}
                          constant
                        >
                          <Typography msg="Upload document" tag="span" />
                        </Button>
                      </Row>
                      <Typography
                        size="m"
                        color="footerColor"
                        tag="div"
                        msg={t(Messages.labelAcceptedFileFormats)}
                        className={styles.topSpacing}
                      />
                    </section>
                  </div>
                </div>

                {legalDocuments.length > 0 && (
                  <>
                    <Typography
                      className={styles.topSpacing}
                      msg={t('label_uploaded' as Messages)}
                      tag="div"
                      size="m"
                    />
                    {legalDocuments.map((fileDetails: DocumentNode, idx: number) => (
                      <FileView
                        containerClassName={styles.fileBottomSpacing}
                        onRemoveClick={() => removeDocumentHandler(fileDetails.id, idx)}
                        key={idx}
                        fileId={fileDetails.id}
                        fileDetails={fileDetails}
                        isGray
                      />
                    ))}
                  </>
                )}
              </>
              <FormError formError={status} />
            </div>
            <NavigationBlock
              isSubmitting={isSubmitting}
              back={back}
              next={next}
              setSubpage={setSubpage}
              // nextButtonTooltipMsg={
              //   values.hasCertificate && !data.item?.certificates.length ? t(Messages.tooltipMissingDocument) : ''
              // }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LegalDocumentsForm;
