import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseAccountDetailsNode,
  CaseServiceProviderFragment,
  CaseServiceProviderNode,
  CaseServiceProviderStatus,
  DeathCaseFragment,
  DeathCaseNode,
  DocumentNode,
  LifeCaseFragment,
  RestrictedDeathCaseNode,
  ServiceProviderTheirTier,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import ChatUploadModalBody from '@Routes/provider/ProviderDCInfoPage/ChatUploadModalBody';
import AddressPanel from '@Routes/shared/AddressPanel';
import SummaryAdditionalInfo from '@Routes/shared/Notification/SummaryAdditionalInfo';
import SummaryCaseDetails from '@Routes/shared/Notification/SummaryCaseDetails';
import SummaryInfoVulnerability from '@Routes/shared/Notification/SummaryInfoVulnerability';
import {
  StatusIndicators,
  StatusColor,
  StatusTitleColors,
  StatusBorderColor,
} from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel';
import notifyStyles from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel.scss';
import PartnerSummary from '@Routes/shared/PartnerSummary';
import SummaryInfoDeceased from '@Routes/shared/SummaryInfoDeceased';
import SummaryLegal from '@Routes/shared/SummaryLegal';
import WillSummary from '@Routes/shared/WillSummary';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { getReadyOnlyPermissions, noop, notifierVulnerabilityVisibility } from '@Utils/helpers';
import { LoadableItem } from '@Utils/types';

import ChatDeskPage from '../ReadyDCPage/ChatDeskPage';

import styles from './DeathCaseNotification.scss';

export interface DeathCaseNotificationProps {
  deathCaseServiceProvider: CaseServiceProviderNode;
  caseAccountDetails: CaseAccountDetailsNode[];
  fullName: string;
  instructionLetters?: DocumentNode[];
  isLifeCase: boolean;
  caseData: DeathCaseFragment | LifeCaseFragment | RestrictedDeathCaseNode;
  isDocUploaded?: boolean;
  nameBackgroundColor?: string;
  closeModal: () => void;
  goToTab?: () => void;
}

enum DeathCaseNotificationTabs {
  TabOverview,
  TabMessages,
}

const DeathCaseNotification: React.FunctionComponent<DeathCaseNotificationProps> = ({
  deathCaseServiceProvider,
  caseAccountDetails,
  instructionLetters,
  isLifeCase,
  caseData,
  isDocUploaded,
  nameBackgroundColor,
  closeModal,
  goToTab = noop,
}) => {
  const t = useTranslations();
  const { isMessage } = useParams();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isDCHeaderVisible, setIsDCHeaderVisible] = React.useState(true);
  const currentLanguage = useSelector(getCurrentLanguage);

  // const [scrollDown, setScrollDown] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(
    isMessage === 'true' || isDocUploaded
      ? DeathCaseNotificationTabs.TabMessages
      : DeathCaseNotificationTabs.TabOverview
  );

  const singleItemReducerInitialState = {
    item: caseData as DeathCaseNode,
    isFetching: false,
  };

  const observerOptions = {
    rootMargin: '200px 0px 0px 0px',
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsDCHeaderVisible(entry.isIntersecting);
    }, observerOptions);
    observer.observe(scrollRef.current);
  }, [activeTab, scrollRef, observerOptions]);

  const dcNotificationTabs = [
    { title: 'Overview' },
    {
      title: 'Messages & documents',
      disable: deathCaseServiceProvider.serviceProvider.theirTier !== ServiceProviderTheirTier.Premium,
      content: {
        header: t('title_message_tab_not_clickable' as Messages),
        desc: t('desc_message_tab_not_clickable' as Messages),
      },
    },
  ];
  const lcNotificationTabs = [{ title: 'Overview' }, { title: 'Uploaded documents' }];

  const isReadOnlyUser = getReadyOnlyPermissions(caseData);

  const decideStatus = (statusType: CaseServiceProviderStatus): StatusIndicators => {
    let icon = '';
    let statusHeader = '';
    let statusTitle = '';
    let statusDescription = '';
    let backgroundColor = '';
    let statusTitleColor = '';
    let iconColor = '';
    let borderColor = '';

    switch (statusType) {
      case CaseServiceProviderStatus.InProgress:
      case CaseServiceProviderStatus.InProgressAutoPopulationFailed:
        icon = 'clock';
        statusHeader = 'The status of this death notification is: ';
        statusTitle = 'In progress';
        statusDescription =
          'The service provider has started working on your request, they will contact you if more information is needed to process your request. Until then, you are not required to do anything else.';
        backgroundColor = 'inProgessBackgroundColor';
        borderColor = 'inProgessBorderColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.NoActionRequired:
        icon = 'checkmark';
        statusHeader = 'No further Action Required: ';
        statusTitle = 'In progress outside Life Ledger';
        statusDescription = t('label_company_notification_acknowledgement' as Messages);
        backgroundColor = 'statusSuccessColor';
        borderColor = 'statusSuccessBorderColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.CompletedClosed:
        icon = 'doneAll';
        statusHeader = 'Complete:';
        statusTitle = 'Complete: Closed ';
        statusDescription = 'Your request has been successfully processed and the account has been closed.';
        backgroundColor = 'statusSuccessColor';
        borderColor = 'statusSuccessBorderColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.Confirmed:
        icon = 'doneAll';
        statusHeader = 'Confirmed:';
        statusTitle = 'Notification has been confirmed ';
        statusDescription = 'Your notification has been confirmed by the service provider';
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.CompletedSwitched:
        icon = 'shuffle';
        statusHeader = 'Complete:';
        statusTitle = 'Complete: Switched ';
        statusDescription = t('label_company_notification_switched' as Messages);
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.AlreadyRegistered:
        icon = 'doneAll';
        statusHeader = 'No further Action Required: ';
        statusTitle = 'This death has already been registered';
        statusDescription =
          'This company has informed us that the request was resolved previously outside of Life Ledger';
        backgroundColor = 'statusSuccessColor';
        borderColor = 'statusSuccessBorderColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.NotFound:
        icon = 'person-delete';
        statusHeader = 'No further Action Required: ';
        statusTitle = 'No account found';
        statusDescription = t(Messages.labelDetailsNomatch);
        backgroundColor = 'statusErrorColor';
        borderColor = 'statusErrorBorderColor';
        statusTitleColor = 'red';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.ReadyToSend:
        icon = 'more-horizontal';
        statusHeader = 'The status of this death notification is ';
        statusTitle = 'Ready to Send';
        statusDescription = 'Your notification is ready to send to the service provider';
        backgroundColor = 'adminBgColor';
        statusTitleColor = 'electricBlue';
        borderColor = 'adminBorderColor';
        iconColor = 'adminBgColor';
        break;
      case CaseServiceProviderStatus.Sent:
        icon = 'more-horizontal';
        statusHeader = 'The status of this death notification is: ';
        statusTitle = 'Not started';
        statusDescription = 'The service provider has not yet started managing your request yet.';
        backgroundColor = 'adminBgColor';
        borderColor = 'adminBorderColor';
        statusTitleColor = 'electricBlue';
        iconColor = 'adminBgColor';
        break;
      case CaseServiceProviderStatus.CompletedByLl:
        icon = 'doneAll';
        statusHeader = 'Complete:';
        statusTitle = t(Messages.statusCompletedByLl);
        statusDescription = t(Messages.statusDescCompletedByLl);
        backgroundColor = 'statusSuccessColor';
        borderColor = 'statusSuccessBorderColor';
        statusTitleColor = 'profRegisterBackground';
        break;
      case CaseServiceProviderStatus.Closed:
        icon = 'doneAll';
        statusHeader = 'Closed:';
        statusTitle = t('title_notification_closed' as Messages);
        statusDescription = t('desc_notification_closed' as Messages);
        backgroundColor = 'statusSuccessColor';
        statusTitleColor = 'profRegisterBackground';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier:
        icon = 'clock';
        statusHeader = 'The status of this death notification is: ';
        statusTitle = 'in progress: more information required';
        statusDescription = t('desc_notification_awaiting_from_notifier' as Messages);
        backgroundColor = 'inProgessBackgroundColor';
        borderColor = 'inProgessBorderColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        break;
      case CaseServiceProviderStatus.InProgressAwaitingInformationFromSp:
        icon = 'clock';
        statusHeader = 'The status of this death notification is: ';
        statusTitle = 'in progress';
        statusDescription = t('desc_notification_awaiting_from_sp' as Messages);
        backgroundColor = 'inProgessBackgroundColor';
        borderColor = 'inProgessBorderColor';
        statusTitleColor = 'yellowishOrange';
        iconColor = '';
        break;
      default:
        break;
    }
    const statusDetails: StatusIndicators = {
      icon,
      statusHeader,
      statusTitle,
      statusDescription,
      backgroundColor,
      statusTitleColor,
      iconColor,
      borderColor,
    };
    return statusDetails;
  };

  const currentStatus = decideStatus(
    deathCaseServiceProvider ? deathCaseServiceProvider.status : CaseServiceProviderStatus.InProgress
  );

  const isReadyToSend = deathCaseServiceProvider.status === CaseServiceProviderStatus.ReadyToSend;

  const additionalInfoSectionVisibility =
    caseAccountDetails.filter((ca: CaseAccountDetailsNode) => ca.key.toLowerCase().includes('additional comments'))
      .length > 0;

  const awaitingReplyFromNotifier =
    deathCaseServiceProvider.status === CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier;

  const dcItems: LoadableItem<DeathCaseNode> = singleItemReducerInitialState;

  const showWillSummary = () => {
    if (dcItems.item) {
      if (isLifeCase) {
        return dcItems.item.wills.length > 0 || dcItems.item.executors.length > 0;
      }
      return (
        dcItems.item.wills.length > 0 ||
        dcItems.item.executors.length > 0 ||
        dcItems.item.representationLetters.length > 0 ||
        dcItems.item.willAdministrator
      );
    }
    return false;
  };

  const hasLegal = dcItems.item && dcItems.item.legalRepresentatives && dcItems.item.legalRepresentatives.length > 0;

  return (
    <div>
      <div ref={scrollRef} className={styles.headerSection}>
        <Row constant className={styles.titleLogo}>
          {deathCaseServiceProvider.serviceProvider.logo ? (
            <Col className={styles.logoWrapper}>
              <img src={deathCaseServiceProvider.serviceProvider.logo} alt="" />
            </Col>
          ) : (
            <div style={{ background: nameBackgroundColor }} className={styles.initialLogoContainer}>
              {deathCaseServiceProvider.serviceProvider.name.charAt(0).toUpperCase()}
            </div>
          )}
          <Typography
            tag="div"
            bold
            size="xxl"
            className={styles.lh2}
            msg={`${
              isLifeCase ? t('title_lc_notification_for' as Messages) : t('title_dc_notification_for' as Messages)
            } ${deathCaseServiceProvider.serviceProvider.name}`}
          />
        </Row>
        {deathCaseServiceProvider.referenceCode && (
          <Typography
            tag="div"
            size="l"
            msg={`Unique reference - ${deathCaseServiceProvider.referenceCode}`}
            className={isMobile ? '' : styles.leftSpacing}
          />
        )}

        {!isLifeCase && (
          <Row
            className={classNames(styles.statusLayout, {
              [notifyStyles[`${currentStatus.backgroundColor}` as StatusColor]]: currentStatus.backgroundColor,
              [notifyStyles[`${currentStatus.borderColor}` as StatusBorderColor]]: currentStatus.borderColor,
            })}
          >
            <div className={isMobile ? styles.flexBox : ''}>
              <Col>
                <Icon
                  icon={currentStatus.icon}
                  size={IconSizes.mmm}
                  className={classNames({
                    [notifyStyles[`${currentStatus.backgroundColor}` as StatusColor]]: currentStatus.iconColor,
                  })}
                />
              </Col>

              {isMobile && (
                <Col>
                  <span className={styles.titleLayout}>
                    The status of this death notification is &nbsp;
                    <span
                      className={classNames(styles.titleLayout, {
                        [notifyStyles[`${currentStatus.statusTitleColor}` as StatusTitleColors]]:
                          currentStatus.statusTitleColor,
                      })}
                    >
                      {currentStatus.statusTitle}
                    </span>
                  </span>
                </Col>
              )}
            </div>
            <Col>
              <Row column>
                {!isMobile && (
                  <Col>
                    <span className={styles.titleLayout}>
                      The status of this death notification is &nbsp;
                      <span
                        className={classNames(styles.titleLayout, {
                          [notifyStyles[`${currentStatus.statusTitleColor}` as StatusTitleColors]]:
                            currentStatus.statusTitleColor,
                        })}
                      >
                        {currentStatus.statusTitle}
                      </span>
                    </span>
                  </Col>
                )}
                <Col>
                  <Typography
                    msg={currentStatus.statusDescription}
                    size="m"
                    tag={awaitingReplyFromNotifier ? 'span' : 'p'}
                  />
                  {awaitingReplyFromNotifier && (
                    <>
                      &nbsp;
                      <Typography
                        className={styles.handSymbol}
                        msg="messages and document tab"
                        underline
                        onClick={() => setActiveTab(DeathCaseNotificationTabs.TabMessages)}
                        size="m"
                        tag="span"
                      />
                    </>
                  )}
                </Col>
                {deathCaseServiceProvider.status === CaseServiceProviderStatus.NotFound && (
                  <>
                    <Col className={styles.topSpacing}>
                      <Typography bold msg="Please double check the following:" size="m" tag="p" />
                    </Col>
                    <List fontSize="m" listItems={['The company account details', 'The deceased’s address']} />

                    <p className={styles.noAccountFoundInfo}>
                      If you notice a mistake, inform the company directly through the &nbsp;
                      <span
                        className={styles.messagesTab}
                        onClick={() => setActiveTab(DeathCaseNotificationTabs.TabMessages)}
                      >
                        messages tab
                      </span>
                      .
                    </p>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        )}

        <Tabs
          tabs={isLifeCase || isReadyToSend ? lcNotificationTabs : dcNotificationTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className={classNames(styles.tabSpacing, 'tabber')}
          removeTabWidth={true}
        />
      </div>

      {!isDCHeaderVisible && !isLifeCase && !isReadyToSend && activeTab === DeathCaseNotificationTabs.TabMessages && (
        <div className={classNames(styles.staticHeader, styles.headerSection1)}>
          <Row constant={isMobile} alignCenter={!isMobile}>
            {deathCaseServiceProvider.serviceProvider.logo ? (
              <Col className={styles.logoWrapper1}>
                <img src={deathCaseServiceProvider.serviceProvider.logo} alt="" />
              </Col>
            ) : (
              <div style={{ background: nameBackgroundColor }} className={styles.initialLogoContainer}>
                {deathCaseServiceProvider.serviceProvider.name.charAt(0).toUpperCase()}
              </div>
            )}
            <Typography
              tag="div"
              bold
              size="xl"
              className={styles.breakWord}
              msg={`${
                isLifeCase ? t('title_lc_notification_for' as Messages) : t('title_dc_notification_for' as Messages)
              } ${deathCaseServiceProvider.serviceProvider.name}`}
            />
          </Row>
        </div>
      )}
      <div id="bodyContainer" className={styles.bodyContainer}>
        {activeTab === DeathCaseNotificationTabs.TabOverview && (
          <>
            {!isLifeCase && (
              <Row className={classNames(styles.infoBox)}>
                <Col>
                  <Row constant>
                    <Col className={styles.infoIcon}>
                      <Icon icon="info" />
                    </Col>
                    <Col className={styles.fs14}>
                      <Typography
                        msg={t('info_notification_dc_summary' as Messages).replace(
                          '[INSERT DATE]',
                          languageBasedDate(deathCaseServiceProvider.sentAt, currentLanguage)
                        )}
                        tag="span"
                        size="s"
                        html
                      />
                      &nbsp;
                      <Typography
                        msg={t('link_summary_tab' as Messages)}
                        tag="span"
                        size="s"
                        className={styles.messagesTab}
                        onClick={() => {
                          closeModal();
                          goToTab();
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row className={classNames(styles.flexGap, styles.tabSpacing)}>
              <Col size={isTablet ? 12 : 6}>
                <Typography className={styles.gdSpacing} msg="Account details" tag="div" bolder size="l" />
              </Col>
              <Col size={isTablet ? 12 : 6}>
                {additionalInfoSectionVisibility && (
                  <Typography className={styles.gdSpacing} msg="Additional info" tag="div" bolder size="l" />
                )}
              </Col>
            </Row>
            <Row className={classNames(styles.flexGap)}>
              {caseAccountDetails && (
                <SummaryCaseDetails
                  isLifeCase={isLifeCase}
                  instructionLetters={instructionLetters}
                  caseDetails={caseAccountDetails}
                  deathCase={caseData as DeathCaseNode}
                />
              )}
              {additionalInfoSectionVisibility && <SummaryAdditionalInfo caseDetails={caseAccountDetails} />}
            </Row>
            {!isLifeCase && (
              <div className={styles.tabSpacing}>
                <SummaryInfoDeceased data={dcItems} isLifeCase={isLifeCase} hideEdit />
                <AddressPanel data={dcItems} isLifeCase={isLifeCase} hideEdit />
                {dcItems.item?.partner && <PartnerSummary isLifeCase={isLifeCase} data={dcItems} />}
                {showWillSummary() && <WillSummary isLifeCase={isLifeCase} data={dcItems} />}
                {hasLegal && !isLifeCase && (
                  <div className={styles.tabSpacing}>
                    <SummaryLegal deathCase={caseData as DeathCaseNode} />
                  </div>
                )}
                {notifierVulnerabilityVisibility(deathCaseServiceProvider) && (
                  <SummaryInfoVulnerability caseServiceProvider={deathCaseServiceProvider} showTitle />
                )}
              </div>
            )}
          </>
        )}

        {!isLifeCase && !isReadyToSend && activeTab === DeathCaseNotificationTabs.TabMessages && (
          <>
            <Typography
              msg="Use the chat box below to send messages safely and securely to the notifier."
              color="footerColor"
              tag="div"
              size="m"
            />

            <ChatDeskPage
              isCustomer={true}
              accessCode={deathCaseServiceProvider.accessCode}
              deathCaseServiceProvider={deathCaseServiceProvider}
              deathCase={caseData as DeathCaseFragment}
              user={user}
            />
          </>
        )}

        {(isLifeCase || isReadyToSend) && activeTab === DeathCaseNotificationTabs.TabMessages && (
          <>
            {!isReadOnlyUser && (
              <>
                <Typography className={styles.mb8} msg="Upload a document" tag="div" size="l" bold />
                <Typography
                  className={styles.mb8}
                  msg={`You can upload and safely store any useful documents here that relate to ${deathCaseServiceProvider.serviceProvider.name}`}
                  color="footerColor"
                  tag="div"
                  size="m"
                />
              </>
            )}
            <ChatUploadModalBody
              deathCaseServiceProvider={deathCaseServiceProvider as CaseServiceProviderFragment}
              accessCode={deathCaseServiceProvider.accessCode}
              isFromApp={true}
              caseData={caseData}
              isCustomer={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DeathCaseNotification;
