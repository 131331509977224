import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, PaymentStatus } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  StripeUpdateCheckoutSessionMutation,
  StripeUpdateCheckoutSessionMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeUpdateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import styles from '@Routes/settings/StripePaymentsPage/SuccessPage/SuccessPage.scss';
import { normaliseGqlError } from '@Utils/form';
import { notificationError } from '@Utils/notificationUtils';

import headerStyles from '../../../../components/Header/Header.scss';

export interface SuccessPageProps {}

const SuccessPage: React.FunctionComponent<SuccessPageProps> = () => {
  const t = useTranslations();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id') || '';
  const isLifeCase = searchParams.get('life_case') === 'true';
  const caseId = searchParams.get('id') || '';

  const state = decodeURI(searchParams.get('state') || '');
  const error = searchParams.get('error') || '';

  const isBankConnect = history.location.pathname.includes('callback');

  const [updateCheckoutSession] = useMutation<
    StripeUpdateCheckoutSessionMutation,
    StripeUpdateCheckoutSessionMutationVariables
  >(mutationStripeUpdateCheckoutSession, {
    onError: (mutCheckError: any) => {
      dispatch(notificationError(normaliseGqlError(mutCheckError.message)));
    },
  });

  React.useEffect(() => {
    if (sessionId !== '') {
      updateCheckoutSession({ variables: { input: { sessionId, status: PaymentStatus.SUCCESS } } });
    }

    if (isBankConnect) {
      history.push(
        generatePath(`${Links.craCompanies}?session=${state.split(':')[0]}`, {
          id: state.split(':')[1],
        })
      );

      if (!isBankConnect || error !== '') {
        setTimeout(() => {
          history.push(
            generatePath(Links.lcReady, {
              id: caseId,
            })
          );
        }, 10000);
      }
    }
  }, [sessionId, updateCheckoutSession, history, location.search, isBankConnect, state, caseId, error]);

  const title = error !== '' ? `We're sorry, something went wrong ` : 'Congratulations!';
  const buttonText = error === '' ? 'Get started' : 'Back to Life Ledger';

  return (
    <div className={styles.mainContainer}>
      <Row justifyCenter className={classNames(headerStyles.logoContainer, styles.mt30)}>
        <a href={Links.lifeLedger}>
          <Icon icon="logo-full" size={IconSizes.logoFull} />
        </a>
      </Row>

      <div className={classNames(styles.successModal, styles.textCenter)}>
        <div className={styles.relPosition}>
          {!isLifeCase && isBankConnect && (
            <>
              {error === '' ? (
                <Icon icon="lock-tick" />
              ) : (
                <Icon icon="closewindow" className={styles.errorIcon} size={IconSizes.mm} />
              )}
            </>
          )}
          {!isBankConnect && <Icon icon="tick" size={IconSizes.noteIcons} />}
        </div>
        <Typography
          className={styles.mb24}
          size="xl"
          bold
          tag="h4"
          msg={!isBankConnect ? t('title_payment_method_added' as Messages) : title}
        />
        {isBankConnect && (
          <Typography
            className={styles.mb24}
            size="xl"
            bold
            tag="h4"
            msg={
              error === ''
                ? 'You have successfully added your bank account'
                : 'Please try again to connect a bank account'
            }
          />
        )}
        <Typography
          className={styles.subTitle}
          size="l"
          tag="div"
          msg={
            !isBankConnect ? t('label_free_to_add_companies' as Messages) : t(Messages.pageStripeSubtitlePaymentSuccess)
          }
        />

        <Button
          type={ButtonTypes.button}
          label={!isBankConnect ? t('button_continue_adding_companies' as Messages) : buttonText}
          onClick={() =>
            history.push({
              pathname: generatePath(!isBankConnect || error !== '' ? `${Links.lcReady}` : `${Links.craCompanies}`, {
                id: isBankConnect ? state.split(':')[1] : caseId,
              }),
              state: !isBankConnect || error !== '' ? 'payment_success' : undefined,
            })
          }
          isFullWidth
        />
      </div>
    </div>
  );
};

export default SuccessPage;
