import classNames from 'classnames';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonStyles } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { ExecutorCollaboratorFields } from '@Components/Modal/SmartModalBody/SmartModalBody';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { CollaboratorFragment, UserNode, CollaboratorNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { removeExecutor, updateDC } from '@Store/dc/dc.actions';
import { updateLC } from '@Store/lc/lc.actions';

import styles from './KnownExecutors.scss';

export interface KnownExecutorsProps {
  executors: CollaboratorFragment[] | ExecutorCollaboratorFields[];
  setStatus: (status: any) => void;
  user: UserNode | null;
  caseId: string;
  isLifeCase: boolean | undefined;
  isUserProfessional?: boolean;
  isCollabrator?: boolean;
  hasWill?: boolean;
  onClick?: (id: string) => void;
}

const KnownExecutors: React.FunctionComponent<KnownExecutorsProps> = ({
  executors,
  setStatus,
  user,
  caseId,
  isLifeCase,
  hasWill,
  isCollabrator,
  onClick,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const { setSubmitting } = useFormikContext();

  // const removeExecutorFilesHandler = deleteConfirmationHandler(t, DocumentDocumentType.ExecutorId, dispatch);

  // const getExecutorIdFile = (collaboratorId: string) =>
  //   executorIds.filter((execFile) => execFile.collaborator.id === collaboratorId).map((e) => e);

  const editHandler = (collaborator: CollaboratorNode) => {
    dispatch(
      showModal({
        type: ModalTypes.editCollaboratorFormModal,
        params: {
          data: {
            secondButtonText: t(Messages.buttonSave),
            buttonText: t(Messages.buttonCancel),
            collaborator,
          },
          // title: t(Messages.modalEditCollaboratorTitle),
          // subtitle: t(Messages.modalEditCollaboratorSubtitle),
          modalSize: PaperWidths.m,
          isNotificationModal: true,
        },
      })
    );
  };

  const deleteHandler = (id: string, isSelfDelete?: boolean) => {
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () =>
              isSelfDelete
                ? dispatch(updateDC.started({ setSubmitting, setStatus, id: caseId, isAlsoExecutor: false }))
                : dispatch(removeExecutor.started({ id })),
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
            content:
              'Deleting this collaborator will mean they will not recieve any notificaitons or updates on the status of the death case and death notifications that are subsequently sent.',
            deleteMessage: 'Do you want to remove this collaborator anyway?',
          },
          title: 'Remove from death case?',
          modalSize: PaperWidths.m,
          isGrayPaper: true,
        },
      })
    );
  };

  return (
    <div>
      {executors.map((executor, idx) => (
        <GrayPaper width="width-full" className={styles.titleBottom} key={idx}>
          <Row className={styles.titleBottom}>
            {!isCollabrator && <Typography msg={`Executor ${idx + 1}`} tag="div" size="m" bold />}
            {isCollabrator && (
              <Typography msg={hasWill ? `Executor ${idx + 1}` : `Administrator ${idx + 1}`} tag="div" size="m" bold />
            )}
          </Row>
          <Row size={12}>
            <Col size={9}>
              <Row column>
                <Col className={styles.infoBottom}>
                  <Typography msg={`${executor.firstName} ${executor.lastName}`} tag="div" size="m" />
                </Col>

                <Col className={styles.infoBottom}>
                  <Typography msg={executor.email} tag="div" size="m" />
                </Col>

                <Col className={styles.infoBottom}>
                  <Typography msg={executor.phone} tag="div" size="m" />
                </Col>

                <Col className={styles.infoBottom}>
                  <Typography
                    msg={t(`${Messages.labelPermissionTypePrefix}${executor.permission.toLowerCase()}` as Messages)}
                    tag="div"
                    size="m"
                  />
                </Col>
              </Row>
            </Col>
            <Col size={3}>
              <Row column>
                <Col />
                {executor.email !== user?.email && !isCollabrator && (
                  <Col className={styles.titleBottom}>
                    <Button
                      style={ButtonStyles.transparent}
                      onClick={() => editHandler(executor as CollaboratorNode)}
                      icon="button-edit"
                      iconSize={IconSizes.ss}
                      constant
                      isFullWidth
                    >
                      <Typography msg="Edit" tag="span" />
                    </Button>
                  </Col>
                )}
                <Col />
                <Col textCenter>
                  <div
                    /* eslint-disable-next-line */
                    tabIndex={0}
                    onClick={() => {
                      const execId =
                        (executor as CollaboratorFragment).id || (executor as ExecutorCollaboratorFields).firstName;
                      if (executor.email !== user?.email) {
                        if (isCollabrator && onClick) {
                          onClick(execId);
                        } else {
                          deleteHandler(execId);
                        }
                      } else if (isLifeCase !== undefined && isLifeCase) {
                        dispatch(updateLC.started({ setSubmitting, setStatus, id: caseId, isAlsoExecutor: false }));
                      } else {
                        deleteHandler(execId, true);
                      }
                    }}
                    onKeyDown={(e: any) => {
                      const execId =
                        (executor as CollaboratorFragment).id || (executor as ExecutorCollaboratorFields).firstName;
                      if (e.keyCode === EnterKeyCode) {
                        if (executor.email !== user?.email) {
                          if (isCollabrator && onClick) {
                            onClick(execId);
                          } else {
                            deleteHandler(execId);
                          }
                        } else if (isCollabrator && onClick) {
                          onClick(execId);
                        } else if (isLifeCase !== undefined && isLifeCase) {
                          dispatch(updateLC.started({ setSubmitting, setStatus, id: caseId, isAlsoExecutor: false }));
                        } else {
                          deleteHandler(execId, true);
                        }
                      }
                    }}
                  >
                    <Row constant justifyCenter className={classNames(styles.topSpacing, styles.removeContainer)}>
                      <Col textCenter>
                        <Icon icon="trash" />
                      </Col>
                      <Col textCenter>
                        <Typography
                          className={styles.removeTextSpacing}
                          msg="Remove"
                          tag="div"
                          size="m"
                          color="red"
                          bold
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* {isUserProfessional && (
            <>
              <GridRow className={styles.biggerBottomSpacing}>
                <GridCol size={12}>
                  <FileInput
                    onDrop={
                      (files: File[]) =>
                        dispatch(
                          uploadDocuments.started({
                            baseCaseId: caseId,
                            documentType: DocumentDocumentType.ExecutorId,
                            files,
                            collaboratorId: executor.id,
                          })
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    onRemoveClick={removeExecutorFilesHandler}
                    files={getExecutorIdFile(executor.id)}
                  />
                </GridCol>
              </GridRow>
            </>
          )} */}
        </GrayPaper>
      ))}
    </div>
  );
};

export default KnownExecutors;
