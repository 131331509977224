import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import Button, { ButtonTypes } from '@Components/Button';
import AuthPageLayout from '@Components/layout/AuthPageLayout';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { verifyEmail } from '@Store/auth/auth.actions';
import { VerifySteps } from '@Store/auth/auth.types';

import styles from './VerifyEmailPage.scss';

export interface VerifyEmailPageProps {}

const VerifyEmailPage: React.FunctionComponent<VerifyEmailPageProps> = () => {
  const t = useTranslations();
  const { verificationKey } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(VerifySteps.progress);
  const history = useHistory();
  const queryString = history.location.search;

  React.useEffect(() => {
    if (step === VerifySteps.progress) {
      dispatch(verifyEmail.started({ verificationKey, setStep }));
    }
  }, [dispatch, verificationKey, setStep, step]);

  return (
    <AuthPageLayout
      titleKey={step === VerifySteps.success ? Messages.titleEmailVerifiedSuccess : Messages.pageRegister}
      isSmallLayout={true}
    >
      <>
        {step === VerifySteps.failure && (
          <>
            <Typography size="m" bold tag="span" msg={t(Messages.labelTip)} withNormalLineHeight />
            &nbsp;
          </>
        )}
        <Typography
          size="m"
          tag="span"
          msg={t(`${Messages.labelVerifyStepDescriptionPrefix}${step}` as Messages)}
          withNormalLineHeight
        />
        {step !== VerifySteps.progress && (
          <Button
            type={ButtonTypes.button}
            label={t(Messages.buttonLogin)}
            onClick={() => history.push(generatePath(`${Links.login}${queryString}`))}
            isFullWidth
            className={styles.topSpacing}
          />
        )}
      </>
    </AuthPageLayout>
  );
};

export default VerifyEmailPage;
