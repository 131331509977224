import classNames from 'classnames';
import * as React from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Button';
import ErrorDisplay from '@Components/form/inputs/ErrorDisplay';
import Col from '@Components/layout/Col';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { usePageTranslationQuery } from '@Graphql/graphqlTypes.generated';
import { noop } from '@Utils/helpers';

import { ModalProviderProps } from '../Modal';

import styles from './SlugBasedDataModalBody.scss';

export interface SlugBasedDataModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  leaveOpen?: boolean;
  slug: string;
}

const SlugBasedDataModalBody: React.FunctionComponent<ModalProviderProps<SlugBasedDataModalBodyProps>> = ({
  modalData: { buttonText, slug, onClose = noop, leaveOpen = false },
  closeModal,
}) => {
  const { loading, data, error } = usePageTranslationQuery({
    variables: { slug },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <Col size={8}>
        <LoadingSpinner />
      </Col>
    );
  }

  if (error) {
    return <ErrorDisplay msg={error.message} />;
  }

  const onButtonClick = () => {
    onClose();
    if (!leaveOpen) {
      closeModal();
    }
  };

  return (
    <div className={styles.container}>
      <Typography
        className={styles.htmlContainer}
        tag="div"
        size="m"
        msg={data?.pageTranslation.text || ''}
        color="gray"
        html
      />
      <div className={classNames(styles.actions)}>
        <Button label={buttonText} size={ButtonSizes.fill} style={ButtonStyles.black} onClick={onButtonClick} />
      </div>
    </div>
  );
};

export default SlugBasedDataModalBody;
