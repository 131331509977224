export { default as mutationCasePortalAssignUser } from '@Graphql/serviceProvidersPortal/mutations/casePortalAssignUser.graphql';
export { default as mutationPortalUpdateUser } from '@Graphql/serviceProvidersPortal/mutations/portalUpdateUser.graphql';
export { default as mutationPortalDeleteUser } from '@Graphql/serviceProvidersPortal/mutations/portalDeleteUser.graphql';
export { default as mutationPortalUpdateSettings } from '@Graphql/serviceProvidersPortal/mutations/portalUpdateSettings.graphql';
export { default as mutationPortalUserUpdateMfa } from '@Graphql/serviceProvidersPortal/mutations/portalUserUpdateMfa.graphql';
export { default as mutationAddInvitation } from '@Graphql/serviceProvidersPortal/mutations/addInvitation.graphql';
export { default as mutationDeleteDataDefinition } from '@Graphql/serviceProvidersPortal/mutations/deleteDataDefinition.graphql';
export { default as mutationAddDataDefinition } from '@Graphql/serviceProvidersPortal/mutations/addDataDefinition.graphql';
export { default as mutationUpdateDataDefinition } from '@Graphql/serviceProvidersPortal/mutations/updateDataDefinition.graphql';
export { default as mutationDeleteSpInvitation } from '@Graphql/serviceProvidersPortal/mutations/deleteSpInvitation.graphql';
export { default as mutationPortalInvitationUpdate } from '@Graphql/serviceProvidersPortal/mutations/portalInvitationUpdate.graphql';
