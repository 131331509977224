import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useParams } from 'react-router';

import MiniFooter from '@Components/application/MiniFooter';
import MobileMenu from '@Components/application/MobileMenu';
import SideMenu from '@Components/application/SideMenu';
import { MenuItems } from '@Components/application/SideMenu/SideMenu';
import Breadcrumb from '@Components/Breadcrumb';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { AppVersion, EnterKeyCode, Links, LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  LifeCaseFragment,
  LifeCaseNode,
  Permissions,
  StripeCreateCheckoutSessionMutation,
  StripeCreateCheckoutSessionMutationVariables,
  useLifeCaseQuery,
  usePackagesQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeCreateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import SummaryInfo from '@Routes/deathCase/SummaryInfo';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import CollaboratorsPage from '@Routes/shared/CollaboratorsPage';
import DocumentsPage from '@Routes/shared/DocumentsPage';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { chooseIcon, modifyMissingItems, topSection } from '@Utils/helpers';
import { deleteFromLocalStorage } from '@Utils/localStorage';
import { addProviderHandler, discoveryHandler, loadingHandler, paymentModeHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';

import { LifeCaseSubpagesNew } from '../LifeCasePage/lifeCaseSubpages';

import styles from './ReadyLCPage.scss';
import LCServicesPage from './ServicesPage';

export interface ReadyDCPageProps {}

export enum LifeCaseTabs {
  TabServices,
  TabCollaborators,
  TabDocuments,
  TabSummary,
}

const ReadyLCPage: React.FunctionComponent<ReadyDCPageProps> = () => {
  const t = useTranslations();
  const { id } = useParams();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const history = useHistory();
  const queryString = history.location.search;

  const user = useSelector(getUser);

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isRes722 = useMediaQuery({ query: ReactResponsiveQueries.Res722 });
  const isRes875 = useMediaQuery({ query: ReactResponsiveQueries.Res1118 });
  const paymentParam = history.location.state ? history.location.state : '';
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const [mobileShowSideMenu, setMobileShowSideMenu] = React.useState(false);
  const overlayEl = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [showBanner, setShowBanner] = React.useState(true);

  const [activeTab, setActiveTab] = React.useState(LifeCaseTabs.TabServices);

  const lcTabs = [
    { title: t(Messages.tabServiceProviders) },
    { title: t(Messages.tabCollaborators) },
    { title: t(Messages.tabDocuments) },
    { title: t(Messages.tabSummary) },
  ];

  deleteFromLocalStorage(LocalStorage.obCompanyIds);
  deleteFromLocalStorage(LocalStorage.obCompanySelected);

  const { data, loading } = useLifeCaseQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  const [stripeCreateDcSubscriptionMutation, { loading: stripeLoading }] = useMutation<
    StripeCreateCheckoutSessionMutation,
    StripeCreateCheckoutSessionMutationVariables
  >(mutationStripeCreateCheckoutSession, {
    onCompleted: (apiResult) => {
      if (stripe) {
        stripe
          .redirectToCheckout({
            sessionId: apiResult.stripeCreateCheckoutSession.sessionId,
          })
          .then((result: { error?: StripeError }) => {
            if (result.error) {
              dispatch(notificationError(result.error.message ? result.error.message : ''));
            }
          });
      }
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const discoveryPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'DISCOVERY');

  const discPackageObCredits = discoveryPackage ? discoveryPackage?.obCredits : 0;

  const showActiveDiscovery =
    user?.enableDiscovery &&
    data &&
    data.lifeCase.credits &&
    data.lifeCase.credits.totalObCredits > 0 &&
    data.lifeCase?.credits.remainingObCredits > 0;

  React.useEffect(() => {
    if (paymentParam === 'payment_success' && data?.lifeCase.credits) {
      if (
        data.lifeCase.credits.remainingObCredits === discPackageObCredits &&
        data.lifeCase.credits.totalObCredits === discPackageObCredits
      ) {
        discoveryHandler(dispatch, t, id, true);
      } else if (data.lifeCase.credits.remainingNotificationCredits > 0) {
        addProviderHandler(t, id, dispatch, true, AppVersion.first);
      }
    }
  }, [paymentParam, dispatch, t, id, data, discPackageObCredits]);

  const singleItemReducerInitialState = {
    item: data?.lifeCase as LifeCaseNode,
    isFetching: false,
  };

  if (stripeLoading) {
    loadingHandler(
      dispatch,
      'stripe',
      t('title_stripe' as Messages),
      t('label_leaving_ll' as Messages),
      t('label_redirection_payment' as Messages),
      t('title_stripe' as Messages)
    );
  }

  const lcItems: LoadableItem<LifeCaseNode> = singleItemReducerInitialState;

  const lifeCase = data?.lifeCase;

  const isReadOnlyUser = lifeCase?.myPermission === Permissions.ReadOnly;

  const breadcrumbItems = [{ title: `Life notifications: ${lifeCase?.firstName} ${lifeCase?.lastName}` }];

  const onHamburgerClick = () => {
    setMobileShowSideMenu(!mobileShowSideMenu);
  };

  const chooseSubpage = (field: string) => {
    if (field.includes('name') || field.includes('date')) {
      return LifeCaseSubpagesNew.General;
    }
    if (field.includes('address') || field.includes('city') || field.includes('post')) {
      return LifeCaseSubpagesNew.GeneralStep2;
    }
    return LifeCaseSubpagesNew.General;
  };

  const missingItems =
    data &&
    modifyMissingItems(
      data.lifeCase.missingInfo.filter((x: any) => x.value === false && x.field !== 'user_verification')
    );

  const chooseLink = (item: any) => {
    if (item.includes('user_verification')) {
      return history.push(generatePath(`${Links.settings}?${queryString}`, { subpage: SettingsSubpages.IDValidation }));
    }
    return history.push(
      generatePath(`${Links.lcEdit}${queryString}`, { id: data?.lifeCase.id, subpage: chooseSubpage(item) })
    );
  };

  const notDiscovered = (): boolean => {
    if (user?.enableDiscovery) {
      if (data?.lifeCase.credits) {
        return data.lifeCase.credits.remainingObCredits === 0;
      }
      return true;
    }
    return false;
  };

  const decideShowBanner = () => {
    if (notDiscovered()) {
      if (isReadOnlyUser) {
        return false;
      }
      return showBanner;
    }
    return false;
  };

  const otherRightDivSize = isMobile ? 12 : 10;

  const scroll = (scrollOffset: any) => {
    overlayEl.current.scrollLeft += scrollOffset;
  };

  const basicPriceId =
    window.location.hostname === 'app.lifeledger.com'
      ? 'price_1M2qGBLnDfwm8yU9MyZrPYLM'
      : 'price_1M2Cu6LnDfwm8yU9029zrPTq';

  const handlerOfPayments = () => {
    if (lifeCase?.credits) {
      paymentModeHandler(8, dispatch, lifeCase.id, basicPriceId, '');
    } else
      stripeCreateDcSubscriptionMutation({
        variables: { input: { priceId: discoveryPackage ? discoveryPackage?.priceId : '', baseCase: id } },
      });
  };

  const buttonUpgradeToDiscovery = (
    <div
      onClick={() => handlerOfPayments()}
      className={styles.btnUpgrade} // eslint-disable-next-line
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.keyCode === EnterKeyCode) {
          handlerOfPayments();
        }
      }}
    >
      <Typography msg={isRes722 ? 'Upgrade now' : 'Upgrade to Discovery'} size="m" tag="div" bold />
    </div>
  );

  const blueBanner = (
    <div className={classNames(isRes722 ? styles.blueBanner : '', styles.res1400MaxBlue)}>
      <Row constant className={isRes722 ? '' : styles.blueBanner} size={12}>
        <Col>
          <Icon size={IconSizes.ss} className={styles.starColor} icon="star" />
        </Col>
        <Col size={12}>
          <Row className={styles.gap18} constant justifyBetween>
            <Col>
              <Row constant className={styles.wrapDiv}>
                <Col>
                  <Typography msg="Get Life Ledger Discovery" size="m" tag="div" bold />
                </Col>
                {!isRes722 && (
                  <Col constant>
                    <Typography msg={t('label_banner_discovery_text' as Messages)} size="m" tag="div" />
                  </Col>
                )}
              </Row>
            </Col>
            <Col alignCenter={!isRes722}>
              <Row constant className={styles.closeAndButton} alignCenter={!isRes722}>
                {!isRes722 && buttonUpgradeToDiscovery}
                <Icon
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      setShowBanner(false);
                    }
                  }}
                  onClick={() => setShowBanner(false)}
                  icon="close"
                  size={IconSizes.xs}
                  className={styles.closeIcon}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {isRes722 && (
        <>
          <Typography msg={t('label_banner_discovery_text' as Messages)} size="m" tag="div" />
          {buttonUpgradeToDiscovery}
        </>
      )}
    </div>
  );

  const showAddNewBankAccount =
    data?.lifeCase.credits &&
    data.lifeCase.credits.totalObCredits > 0 &&
    (data.lifeCase.credits.remainingObCredits >= 1 || data.lifeCase.configuringOb);

  const banksConnectedNumber = () => {
    if (data?.lifeCase.credits && data.lifeCase.credits.totalObCredits > 0) {
      return data.lifeCase.credits.totalObCredits - data.lifeCase.credits.remainingObCredits;
    }
    return 0;
  };

  return (
    <main className={styles.layoutContainer}>
      {isMobile && decideShowBanner() && <div className={styles.p8}>{blueBanner}</div>}
      <div>
        {isMobile && !mobileShowSideMenu ? (
          <MobileMenu onHamburgerClick={onHamburgerClick} showHamBurger={true} grayBackground />
        ) : (
          <SideMenu
            closeClick={() => setMobileShowSideMenu(!mobileShowSideMenu)}
            page={MenuItems.ViewLifeCase}
            caseId={id}
            showSideMenuItems={true}
          />
        )}
      </div>
      {!mobileShowSideMenu && (
        <div className={classNames(styles.rightSideContainer, styles.hideScroll)}>
          <div className={styles.headerContainer}>
            {decideShowBanner() && !isMobile && blueBanner}

            {lifeCase?.firstName && (
              <div className={styles.mt24}>
                <Breadcrumb breadcrumbItems={breadcrumbItems} />
              </div>
            )}
            {!loading && (
              <>
                <Row className={styles.gap2} size={topSection(user, isRes875)}>
                  <Row size={6} column>
                    <Typography msg="Registered Life" tag="h4" size="xl" className={styles.spaceUpAndDown} />

                    <Row
                      constant
                      onClick={() => {
                        scroll(350);
                        setActiveTab(LifeCaseTabs.TabSummary);
                      }}
                      className={styles.gap16}
                      tabIndex={0}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setActiveTab(LifeCaseTabs.TabSummary);
                          scroll(350);
                        }
                      }}
                    >
                      <Typography
                        className={styles.lh3}
                        msg={`${lifeCase?.firstName} ${lifeCase?.lastName}`}
                        tag="div"
                        size="xxxl"
                      />

                      <Typography msg="See details" size="l" tag="div" className={styles.seeDetails} underline />
                    </Row>
                  </Row>
                  <Row size={4} column className={styles.alignSelf}>
                    {user?.businessReferral && (
                      <>
                        <Typography
                          msg={t('label_as_recommended_by' as Messages)}
                          tag="div"
                          size="l"
                          color="footerColor"
                        />
                        <img className={styles.businessReferralLogo} src={user.businessReferral} alt="" />
                      </>
                    )}
                  </Row>
                </Row>
              </>
            )}

            {showActiveDiscovery && (
              <>
                <Row constant className={styles.letterSpacing} alignCenter>
                  <Row alignCenter constant>
                    <Icon size={IconSizes.sxs} className={styles.starIcon} icon="star" />
                    <Typography size="xs" bold tag="div" msg={t('label_ll_discovery' as Messages)} />
                  </Row>
                  <Typography size="xs" bold tag="div" msg=":" />
                  &nbsp;
                  <Typography
                    size="xs"
                    bold
                    color="profRegisterBackground"
                    tag="div"
                    msg={data.lifeCase.configuringOb ? 'Account synced' : t('label_active' as Messages)}
                  />
                  {showAddNewBankAccount && (
                    <Row
                      onClick={() => history.push(generatePath(Links.institutions, { id }))}
                      constant
                      alignCenter
                      className={classNames(styles.gap7, styles.handSymbol)}
                      tabIndex={0}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          history.push(generatePath(Links.institutions, { id }));
                        }
                      }}
                    >
                      <Icon className={styles.plusIcon} icon="plus" size={IconSizes.sxs} />
                      <Typography
                        size="xs"
                        color="footerColor"
                        bold
                        tag="div"
                        msg={t('button_add_another' as Messages)}
                      />
                    </Row>
                  )}
                </Row>
                {banksConnectedNumber() > 0 && (
                  <Row constant className={styles.banksInfoAdd} alignCenter>
                    <Row constant alignCenter className={styles.banksCountContainer}>
                      <Icon icon="email" />
                      <Typography
                        size="xs"
                        color="profRegisterBackground"
                        bold
                        tag="div"
                        msg={`${banksConnectedNumber()} bank accounts connected`}
                      />
                    </Row>
                  </Row>
                )}
              </>
            )}

            <Row size={7} className={styles.bottomSpacing}>
              <Typography
                msg="From here you can select companies to notify, upload additional documents, and add collaborators to help you with the notifications."
                tag="div"
                size="l"
              />
            </Row>

            {data?.lifeCase.myPermission !== Permissions.ReadOnly && missingItems && missingItems.length > 0 && (
              <Row size={isTablet ? 11 : 8} className={styles.missingInfoBox}>
                <Col size={4}>
                  <Row constant>
                    <Col className={styles.infoIcon}>
                      <Icon icon="info" />
                    </Col>
                    <Col size={12}>
                      <Row>
                        <Col>
                          <Typography msg="To do" tag="div" size="s" bolder />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col size={2} />
                <Col size={5}>
                  <Row className={styles.mobileMissingItems} column>
                    {missingItems &&
                      missingItems.map((item: any, idx: number) => (
                        <Col key={idx}>
                          <Icon
                            className={classNames(styles.iconPlacement, styles.missingItems)}
                            icon={chooseIcon(item)}
                            size={IconSizes.ss}
                          />
                          <span className={styles.handSymbol} onClick={() => chooseLink(item)}>
                            <Typography
                              className={styles.sent}
                              msg={t(`${Messages.missingItemPrefix}${item.toLowerCase()}` as Messages)}
                              tag="span"
                              bold
                              color="red"
                              size="s"
                              underline={true}
                            />
                          </span>
                        </Col>
                      ))}
                  </Row>
                </Col>

                <Col />
              </Row>
            )}

            {!loading && (
              <div ref={overlayEl} className={styles.container}>
                <Tabs tabs={lcTabs} activeTab={activeTab} setActiveTab={setActiveTab} className={styles.tabSpacing} />
              </div>
            )}
          </div>

          {!loading && (
            <>
              {activeTab === LifeCaseTabs.TabServices && (
                <LCServicesPage
                  notDiscovered={notDiscovered()}
                  caseData={lifeCase as LifeCaseFragment}
                  discoveryPackage={discoveryPackage}
                />
              )}
              {activeTab === LifeCaseTabs.TabCollaborators && (
                <Col
                  size={isMobileBetweenTablet ? 11 : otherRightDivSize}
                  constant
                  className={classNames(styles.pl34, styles.mt24, styles.res1400MaxMain)}
                >
                  <CollaboratorsPage isLifeCase={true} caseData={lifeCase as LifeCaseFragment} />
                </Col>
              )}
              {activeTab === LifeCaseTabs.TabDocuments && (
                <Col
                  size={isMobileBetweenTablet ? 11 : otherRightDivSize}
                  constant
                  className={classNames(styles.pl34, styles.res1400MaxDocs)}
                >
                  <DocumentsPage caseData={lifeCase as LifeCaseFragment} isLifeCase />
                </Col>
              )}
              {activeTab === LifeCaseTabs.TabSummary && (
                <Col
                  size={isMobileBetweenTablet ? 11 : otherRightDivSize}
                  constant
                  className={classNames(styles.summaryContainer, styles.res1400MaxSums)}
                >
                  <SummaryInfo isLifeCase={true} data={lcItems} sectionsToCol />
                </Col>
              )}
            </>
          )}
          {loading && (
            <Col size={8} alignCenter textCenter>
              <LoadingSpinner />
            </Col>
          )}
          {!isTablet && <MiniFooter />}
        </div>
      )}
    </main>
  );
};

export default ReadyLCPage;
