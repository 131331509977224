export { default as mutationDeathCaseProviderCreate } from '@Graphql/providers/mutations/createDCProvider.graphql';
export { default as mutationAddReferenceCode } from '@Graphql/providers/mutations/deathCaseProviderAddReferenceCode.graphql';
export { default as mutationDownloadDCProviderInfo } from '@Graphql/providers/mutations/downloadDCProviderInfo.graphql';
export { default as mutationNewServiceProvider } from '@Graphql/providers/mutations/serviceProviderRequestNew.graphql';
export { default as mutationCaseChatCreate } from '@Graphql/providers/mutations/createCaseChat.graphql';
export { default as mutationUploadDocumentInChat } from '@Graphql/providers/mutations/uploadDocumentInChat.graphql';
export { default as mutationCaseDocumentAccessRequest } from '@Graphql/providers/mutations/caseDocumentAccessRequest.graphql';
export { default as mutationUpdateNotificationEmail } from '@Graphql/providers/mutations/updateNotificationEmail.graphql';
export { default as mutationCaseProviderUpdateStatus } from '@Graphql/providers/mutations/caseProviderUpdateStatus.graphql';
export { default as mutationUploadDocumentInNotification } from '@Graphql/providers/mutations/uploadDocumentInNotification.graphql';
export { default as mutationDeleteDocumentInNotification } from '@Graphql/providers/mutations/deleteDocumentInNotification.graphql';
export { default as mutationCaseProviderUpdate } from '@Graphql/providers/mutations/caseProviderUpdate.graphql';
export { default as mutationUpdateCaseAccountDetails } from '@Graphql/providers/mutations/updateCaseAccountDetails.graphql';
