import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { Links, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryDCList } from '@Graphql/dc/queries';
import {
  DeathCaseDeleteMutation,
  DeathCaseDeleteMutationVariables,
  LifeCaseTransformMutation,
  LifeCaseTransformMutationVariables,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteLC, mutationTransform } from '@Graphql/lc/mutations';
import { queryLCList } from '@Graphql/lc/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { LifeCaseSubpages } from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './LifeCaseActionsModalBody.scss';

export interface LifeCaseActionsModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  leaveOpen?: boolean;
  slug: string;
  name: string;
  caseId: string;
}

const LifeCaseActionsModalBody: React.FunctionComponent<ModalProviderProps<LifeCaseActionsModalBodyProps>> = ({
  modalData: { name, caseId },
  closeModal,
}) => {
  const history = useHistory();
  const user = useSelector(getUser);
  const queryString = history.location.search;
  const dispatch = useDispatch();
  const t = useTranslations();
  const [transformMutation] = useMutation<LifeCaseTransformMutation, LifeCaseTransformMutationVariables>(
    mutationTransform,
    {
      refetchQueries: [getOperationName(queryDCList) || '', getOperationName(queryLCList) || ''],
      onCompleted: (result) => {
        history.push(
          generatePath(`${Links.dcEdit}${queryString}`, {
            id: result.lifeCaseTransform.deathCaseId,
            subpage: DeathCaseSubpages.General,
            missing: true,
          })
        );
        closeModal();
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [deleteDCMutation] = useMutation<DeathCaseDeleteMutation, DeathCaseDeleteMutationVariables>(mutationDeleteLC, {
    refetchQueries: [getOperationName(queryLCList) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.notificationLCDeleteSuccess)));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const transformHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: secondTransformHandler,
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
            leaveOpen: true,
          },
          title: t(Messages.modalConvertToDCTitle, { name }),
          subtitle: t(Messages.modalConvertToDCSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const secondTransformHandler = () => {
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => transformMutation({ variables: { input: { lifeCaseId: caseId } } }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.modalConvertToDCSecondTitle),
          subtitle: t(Messages.modalConvertToDCSecondSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const deleteHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCMutation({ variables: { input: { id: caseId } } }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t('modal_remove_lc_title' as Messages),
          subtitle: '',
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  return (
    <div className={styles.topSpacing}>
      <Row
        constant
        alignCenter
        justifyCenter
        className={classNames(styles.bottomSpacing, styles.flexDisplay)}
        onClick={(e: any) => transformHandler(e)}
      >
        <Icon icon="swap" />
        <Typography msg="This person has passed away" tag="div" size="mb" />
      </Row>
      <div className={styles.breaker} />
      <Row
        constant
        justifyCenter
        onClick={() => {
          closeModal();
          history.push(
            generatePath(`${Links.lcEdit}${queryString}`, {
              id: caseId,
              subpage: LifeCaseSubpages.General,
              missing: true,
            })
          );
        }}
      >
        <Col>
          <Icon icon="edit" className={styles.viewNotificationIcon} />
        </Col>
        <Col>
          <Typography msg="Edit" tag="span" size="mb" className={styles.deathDateSpacing} />
        </Col>
      </Row>
      {user?.accountType === UserAccountType.Professional && (
        <>
          <div className={styles.breaker} />
          <Row onClick={(e: any) => deleteHandler(e)} className={styles.flexDisplay} constant justifyCenter>
            <Col>
              <Icon icon="trash" />
            </Col>
            <Col>
              <Typography msg="Remove" tag="div" size="m" color="red" bold />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default LifeCaseActionsModalBody;
