// import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import CollaboratorCard from '@Components/application/CollaboratorCard';
import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorNodeEdge,
  DeathCaseFragment,
  LifeCaseFragment,
  Permissions,
  useCollaboratorsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { inviteCollaboratorHandler } from '@Utils/modal';

import layoutStyles from '../../deathCase/ReadyDCPage/ReadyDCPage.scss';

import styles from './CollaboratorsPage.scss';

export interface CollaboratorsPageProps {
  caseData: DeathCaseFragment | LifeCaseFragment;
  isLifeCase?: boolean;
}

const CollaboratorsPage: React.FunctionComponent<CollaboratorsPageProps> = ({ caseData, isLifeCase = false }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const { data, loading } = useCollaboratorsQuery({
    variables: {
      baseCase: caseData.id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const isReadOnlyUser = caseData.myPermission === Permissions.ReadOnly;

  return (
    <div className={classNames({ [layoutStyles.rightSideGap]: !isLifeCase })}>
      <Typography tag="div" size="m" color="footerColor" msg={t(Messages.labelColloboratorsInfo)} />
      {!isReadOnlyUser && (
        <Row constant size={isTablet ? 11 : 6} justifyCenter className={styles.addCompanyBlock}>
          <Button
            onClick={() => inviteCollaboratorHandler(t, caseData.id, dispatch, isLifeCase)}
            icon="person-add"
            iconSize={IconSizes.ss}
            constant
            label={t(Messages.buttonAddCollaborator)}
            isFullWidth
          />
        </Row>
      )}

      <Typography
        className={isReadOnlyUser ? styles.smallTopSpacing : ''}
        tag="div"
        size="m"
        msg={t(Messages.labelActiveCollaborators)}
        bold
      />

      {!isMobile && (
        <Row className={styles.smallTopSpacing} size={12}>
          <Col size={4}>
            <Typography tag="div" size="s" msg={t(Messages.fieldName)} bold />
          </Col>

          <Col size={4}>
            <Typography
              className={styles.leftSpacing}
              tag="div"
              size="s"
              msg={t(Messages.titleCollaboratorEmail)}
              bold
            />
          </Col>

          <Col size={3}>
            <Typography
              className={styles.leftPermissionSpacing}
              tag="div"
              size="s"
              msg={t(Messages.titleCollaboratorPermissions)}
              bold
            />
          </Col>
        </Row>
      )}
      {data?.collaborators.edges
        .filter((c: any) => c.node.isInvitePending !== true && c.node.showCollaborator)
        .map((c: any, idx: number) => (
          <CollaboratorCard
            key={idx}
            data={c.node}
            allCollaborators={data.collaborators.edges as CollaboratorNodeEdge[]}
            isReadyOnly={isReadOnlyUser}
          />
        ))}

      <Row constant className={styles.bigTopSpacing}>
        <Typography
          className={styles.messageInfo}
          tag="div"
          size="m"
          msg={t(Messages.labelPending)}
          color="dividerColor"
          bold
        />
        <Tooltip
          title={t(Messages.tootltipWhyPending)}
          message={t(Messages.tooltipWhyPendingDesc)}
          position={TooltipPosition.right}
        >
          <Icon icon="info" />
        </Tooltip>
      </Row>

      {data?.collaborators.edges
        .filter((c: any) => c.node.isInvitePending === true && c.node.showCollaborator)
        .map((c: any, idx: number) => (
          <CollaboratorCard
            key={idx}
            data={c.node}
            allCollaborators={data.collaborators.edges as CollaboratorNodeEdge[]}
            isReadyOnly={isReadOnlyUser}
          />
        ))}
    </div>
  );
};

export default CollaboratorsPage;
