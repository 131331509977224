import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationAgentCompanyUpdate } from '@Graphql/agent/mutations';
import { queryAgentCompanyDetails } from '@Graphql/agent/queries';
import { AgentCompanyUpdateMutation, AgentCompanyUpdateMutationVariables } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateRequired } from '@Utils/validator';

import styles from './AgentCompanyUpdateModal.scss';

export interface AgentCompanyUpdateModalProps {
  id: string | undefined;
}

enum AgentCompanyUpdateModalField {
  companyNumber = 'companyNumber',
}

const AgentCompanyUpdateModal: React.FunctionComponent<AgentCompanyUpdateModalProps> = ({ id }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;

  const [updateAgentCompany, { loading }] = useMutation<
    AgentCompanyUpdateMutation,
    AgentCompanyUpdateMutationVariables
  >(mutationAgentCompanyUpdate, {
    refetchQueries: [getOperationName(queryAgentCompanyDetails) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess('Company number added'));
      history.push(generatePath(`${Links.home}${queryString}`));
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  return (
    <Col size={6}>
      <TitleWithSubtitle title={t('title_agent_company_number' as Messages)} subtitle="" userSection />

      <Formik
        initialValues={{
          [AgentCompanyUpdateModalField.companyNumber]: '',
        }}
        validationSchema={Yup.object({
          [AgentCompanyUpdateModalField.companyNumber]: validateRequired(t),
        })}
        onSubmit={(values) => {
          updateAgentCompany({
            variables: {
              input: {
                id: id || '',
                companyNumber: values[AgentCompanyUpdateModalField.companyNumber],
              },
            },
          });
        }}
      >
        {({ status, values }) => (
          <Form>
            <GridRow>
              <GridCol size={10}>
                <Field
                  name={AgentCompanyUpdateModalField.companyNumber}
                  type="text"
                  component={TextInput}
                  label={t(Messages.fieldcompanyNumber)}
                  required
                />
              </GridCol>
              <GridCol size={7} className={styles.mt20}>
                <Button
                  label="Submit"
                  size={ButtonSizes.fill}
                  type={ButtonTypes.submit}
                  disabled={loading || !values[AgentCompanyUpdateModalField.companyNumber]}
                  loading={loading}
                />
              </GridCol>
            </GridRow>
            <FormError formError={status} />
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export default AgentCompanyUpdateModal;
