import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { DeathCaseNode, LifeCaseNode } from '@Graphql/graphqlTypes.generated';
// import { useTranslations } from '@Hooks/useTranslations';
import AdditionalContactSummary from '@Routes/lifeCase/LifeCasePage/AdditionalContactSummary';
import AdditionalInfoSummary from '@Routes/lifeCase/LifeCasePage/AdditionalInfoSummary';
import AddressPanel from '@Routes/shared/AddressPanel';
import PartnerSummary from '@Routes/shared/PartnerSummary';
import SummaryInfoDeceased from '@Routes/shared/SummaryInfoDeceased';
import SummaryLegal from '@Routes/shared/SummaryLegal';
import WillSummary from '@Routes/shared/WillSummary';
import { LoadableItem } from '@Utils/types';

import styles from './SummaryInfo.scss';

export interface SummaryInfoProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  sectionsToCol?: boolean;
  isLifeCase?: boolean;
}

const SummaryInfo: React.FunctionComponent<SummaryInfoProps> = ({ data, sectionsToCol, isLifeCase }) => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const id = data.item?.id;
  if (!id) {
    return null;
  }
  const deathCaseItems = data?.item?.__typename === NodeDefiner.DeathCase ? data?.item : null;

  const showWillSummary = () => {
    if (data.item) {
      if (isLifeCase) {
        return data.item.wills.length > 0 || data.item.executors.length > 0;
      }
      return (
        data.item.wills.length > 0 ||
        data.item.executors.length > 0 ||
        data.item.representationLetters.length > 0 ||
        deathCaseItems?.willAdministrator
      );
    }
    return false;
  };

  return (
    <Row className={classNames(styles.deathCaseDisplayWrapper, { [styles.sectionsToCol]: sectionsToCol })}>
      {!isTablet && <div className={styles.greenEllipsis} />}
      {!isTablet && <div className={styles.greenEllipsis1} />}
      <Col size={sectionsToCol ? 12 : 6} className={styles.deathCaseDetailsContainer}>
        <SummaryInfoDeceased data={data} isLifeCase={isLifeCase} />
        <AddressPanel data={data} isLifeCase={isLifeCase} />
      </Col>
      <Col size={sectionsToCol ? 12 : 6}>
        {data.item?.partner && <PartnerSummary isLifeCase={isLifeCase} data={data} />}
        {showWillSummary() && <WillSummary isLifeCase={isLifeCase} data={data} />}
        {isLifeCase && <AdditionalInfoSummary data={data as LoadableItem<LifeCaseNode>} />}
        {isLifeCase && <AdditionalContactSummary data={data as LoadableItem<LifeCaseNode>} />}
        {deathCaseItems &&
          deathCaseItems?.legalRepresentatives &&
          deathCaseItems?.legalRepresentatives.length > 0 &&
          !isLifeCase && <SummaryLegal deathCase={deathCaseItems} />}
      </Col>
    </Row>
  );
};

export default SummaryInfo;
