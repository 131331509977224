import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import TextInput from '@Components/form/inputs/TextInput';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { fileUploadSettings, emptySelectorValue } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderNode,
  DeathCaseFragment,
  DocumentDocumentType,
  DocumentNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { notificationError } from '@Utils/notificationUtils';
import { validateRequired } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './FileUploadModalBody.scss';

export interface FileUploadModalBodyProps {
  onClose: (documentType: DocumentDocumentType | undefined, files?: File[], otherDocumentTypeName?: string) => void;
  buttonText: string;
  isLifeCase?: boolean;
  uploadedFiles?: DocumentNode[];
  isLegalDocuments?: boolean;
  isDraggedFile?: boolean;
  caseServiceProvider?: CaseServiceProviderNode;
  deathCase?: DeathCaseFragment;
}

enum FileUploadModalBodyFields {
  documentType = 'documentType',
  otherDocumentTypeName = 'otherDocumentTypeName',
}

export enum LifeCaseDocumentType {
  Will = 'WILL',
  FuneralPlan = 'FUNERAL_PLAN',
  InsurancePolicy = 'INSURANCE_POLICY',
  Other = 'OTHER',
}

enum LegalCaseDocumentType {
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  InstructionLetter = 'INSTRUCTION_LETTER',
  Other = 'OTHER',
}

export interface FileUploadModalFormValues {
  [FileUploadModalBodyFields.documentType]: { label: ''; value: '' };
}

const FileUploadModalBody: React.FunctionComponent<ModalProviderProps<FileUploadModalBodyProps>> = ({
  modalData: { buttonText, uploadedFiles, onClose, isLifeCase, isLegalDocuments = false, isDraggedFile },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const dcDocumentType = isLegalDocuments ? LegalCaseDocumentType : DocumentDocumentType;
  const dcDocuments = Object.values(isLifeCase ? LifeCaseDocumentType : dcDocumentType).map((documentType) => ({
    label: t(`${Messages.documentTypePrefix}${documentType.toUpperCase()}` as Messages),
    value: documentType,
  }));

  const initialValues = {
    [FileUploadModalBodyFields.documentType]: emptySelectorValue,
    [FileUploadModalBodyFields.otherDocumentTypeName]: '',
  };

  const { maxUploadSize, supportedUploadFormats, maxFiles } = fileUploadSettings;

  const disabled = uploadedFiles && uploadedFiles.length >= maxFiles;

  const isOtherSelected = (values: any) => {
    return values.documentType === LifeCaseDocumentType.Other;
  };

  const AutoToggleForm = () => {
    const { setFieldValue, values } = useFormikContext();

    React.useEffect(() => {
      if (!isOtherSelected(values)) {
        setFieldValue(FileUploadModalBodyFields.otherDocumentTypeName, '');
      }
    }, [setFieldValue, values]);

    return null;
  };

  const callMutation = (values: any, files: File[]) => {
    closeModal();
    onClose(
      Object.values(DocumentDocumentType).find((doc: any) => values.documentType === doc),
      files,
      values.otherDocumentTypeName
    );
  };

  return (
    <div className={styles.container}>
      <Typography msg={t(Messages.modalUploadDocumentTitle)} tag="h4" size="xl" bold />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          [FileUploadModalBodyFields.documentType]: validateRequired(t),
        })}
        onSubmit={(values) => {
          closeModal();
          onClose(
            Object.values(DocumentDocumentType).find((doc: any) => values.documentType === doc),
            undefined,
            values.otherDocumentTypeName
          );
        }}
      >
        {({ values }) => {
          return (
            <Form>
              <Row size={12} justifyBetween className={styles.radioButtonContainer}>
                <Col size={12}>
                  <Field
                    name={FileUploadModalBodyFields.documentType}
                    options={dcDocuments}
                    component={RadioButton}
                    required
                    label="Which type of document are you uploading?"
                  />
                </Col>
              </Row>
              {isOtherSelected(values) && (
                <Field
                  name={FileUploadModalBodyFields.otherDocumentTypeName}
                  type="text"
                  component={TextInput}
                  label=""
                  placeholder={t('field_placeholder_specify_document_type' as Messages)}
                />
              )}

              {!isDraggedFile && (
                <Dropzone
                  maxSize={maxUploadSize}
                  disabled={disabled}
                  onDrop={(files: File[]) => callMutation(values, files)}
                  multiple={true}
                  accept={supportedUploadFormats}
                  onDropRejected={(fileRejected: FileRejection[]) => {
                    if (fileRejected[0].errors[0].code === 'file-too-large') {
                      dispatch(notificationError(t(Messages.msgFileSizeLarge)));
                    } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
                      dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className={classNames(styles.actions)} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        label={buttonText}
                        size={ButtonSizes.fill}
                        type={ButtonTypes.button}
                        isFullWidth
                        onClick={(e: any) => {
                          if (values.documentType.value === '') {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(notificationError(t('msg_select_doc_type' as Messages)));
                          }
                        }}
                      />
                    </div>
                  )}
                </Dropzone>
              )}

              {isDraggedFile && (
                <Button label={buttonText} size={ButtonSizes.fill} type={ButtonTypes.submit} isFullWidth />
              )}

              <AutoToggleForm />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FileUploadModalBody;
