import * as dotProp from 'dot-prop-immutable';
import { Action } from 'typescript-fsa';

import {
  CollaboratorNode,
  CollaboratorProRelationFe,
  CreateCollaboratorPayload,
  DeleteCollaboratorPayload,
  DocumentDocumentType,
  UpdateCollaboratorPayload,
} from '@Graphql/graphqlTypes.generated';
import { editCollaborator } from '@Store/collaborator/collaborator.actions';
import {
  addAdministrator,
  addExecutor,
  addLegal,
  clearDC,
  fetchDC,
  removeDocuments,
  removeExecutor,
  updateDC,
  uploadDocuments,
} from '@Store/dc/dc.actions';
import { DCData, UpdateDCPayload } from '@Store/dc/dc.types';
import { singleItemReducer, singleItemReducerInitialState } from '@Utils/reducers';
import { createReducer } from '@Utils/redux';

export type DCState = {
  dc: DCData;
};

export const initialState: DCState = {
  dc: singleItemReducerInitialState,
};

export const documentTypeMapper: Record<DocumentDocumentType, string> = {
  [DocumentDocumentType.Certificate]: 'certificates',
  [DocumentDocumentType.Will]: 'wills',
  [DocumentDocumentType.RepresentationLetter]: 'representationLetters',
  [DocumentDocumentType.Other]: 'otherDocuments',
  [DocumentDocumentType.PowerOfAttorney]: 'powerOfAttorneys',
  [DocumentDocumentType.InstructionLetter]: 'instructionLetters',
  [DocumentDocumentType.LetterToCompany]: 'letterToCompanys',
  [DocumentDocumentType.ExecutorId]: 'executorIds',
  [DocumentDocumentType.FuneralPlan]: 'funeralPlans',
  [DocumentDocumentType.InsurancePolicy]: 'insurancePolicies',
};

const dcReducer = createReducer<DCState>(initialState, {
  [clearDC.type]: (state) => dotProp.set(state, 'dc', { isFetching: false, item: null }),

  [addExecutor.done.type]: (state, action: Action<{ result: CreateCollaboratorPayload }>) => {
    const executors = state.dc.item?.executors || [];
    const item = state.dc.item
      ? { ...state.dc.item, executors: [...executors, action.payload.result.collaborator] }
      : null;
    return dotProp.set(state, 'dc', { isFetching: false, item });
  },

  [addLegal.done.type]: (state, action: Action<{ result: CreateCollaboratorPayload }>) => {
    const legalRepresentatives = state.dc.item?.legalRepresentatives || [];
    const item = state.dc.item
      ? { ...state.dc.item, legalRepresentatives: [...legalRepresentatives, action.payload.result.collaborator] }
      : null;
    return dotProp.set(state, 'dc', { isFetching: false, item });
  },

  [addAdministrator.done.type]: (state, action: Action<{ result: CreateCollaboratorPayload }>) => {
    const admins = state.dc.item?.willAdministrator || [];
    const item = state.dc.item
      ? { ...state.dc.item, willAdministrator: [...admins, action.payload.result.collaborator] }
      : null;
    return dotProp.set(state, 'dc', { isFetching: false, item });
  },

  [removeExecutor.done.type]: (state, action: Action<{ result: DeleteCollaboratorPayload }>) => {
    const executors = state.dc.item?.executors || [];
    const legalRepresentatives = state.dc.item?.legalRepresentatives || [];
    const admins = state.dc.item?.willAdministrator || [];
    const filteredExecutors = executors.filter(
      (ex: CollaboratorNode) => ex.id !== action.payload.result?.collaborator?.id
    );
    const filteredlegals = legalRepresentatives.filter(
      (ex: CollaboratorNode) => ex.id !== action.payload.result?.collaborator?.id
    );

    const filteredAdmins = admins.filter((ex: CollaboratorNode) => ex.id !== action.payload.result?.collaborator?.id);
    const item = {
      ...state.dc.item,
      executors: filteredExecutors,
      legalRepresentatives: filteredlegals,
      willAdministrator: filteredAdmins,
    };
    return dotProp.set(state, 'dc', { isFetching: false, item });
  },

  [editCollaborator.done.type]: (state, action: Action<{ result: UpdateCollaboratorPayload }>) => {
    const legalRepresentatives = state.dc.item?.legalRepresentatives || [];
    const executors = state.dc.item?.executors || [];
    const result = action.payload.result?.collaborator;
    const filteredlegals = legalRepresentatives.filter((x: CollaboratorNode) => x.id !== result.id);

    const filteredExecutors = executors.filter((ex: CollaboratorNode) => ex.id !== result.id);

    const item =
      result.relationship === CollaboratorProRelationFe.LegalRepresentative
        ? { ...state.dc.item, legalRepresentatives: [...filteredlegals, action.payload.result.collaborator] }
        : { ...state.dc.item, executors: [...filteredExecutors, action.payload.result.collaborator] };
    return dotProp.set(state, 'dc', { isFetching: false, item });
  },

  [updateDC.done.type]: (state, action: Action<{ result: UpdateDCPayload }>) => {
    const result = action.payload.result?.deathCase?.willAdministrator;
    const item = { ...state.dc.item, willAdministrator: result };
    return dotProp.set(state, 'dc', { isFetching: false, item });
  },

  [uploadDocuments.done.type]: (state, { payload: { params, result } }: ReturnType<typeof uploadDocuments.done>) => {
    if (!state.dc.item) {
      return state;
    }
    return dotProp.merge(
      state,
      `dc.item.${documentTypeMapper[params.documentType as DocumentDocumentType]}`,
      result.document
    );
  },
  [removeDocuments.done.type]: (state, { payload: { params } }: ReturnType<typeof removeDocuments.done>) => {
    return dotProp.delete(
      state,
      `dc.item.${documentTypeMapper[params.documentType as DocumentDocumentType]}.${[params.index]}`
    ) as DCState;
  },
  ...singleItemReducer<any, any, any>(fetchDC, 'dc'),
});

export default dcReducer;
