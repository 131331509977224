import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import AccountForm from '@Routes/settings/AccountPage/AccountForm';
import { getUser } from '@Store/auth/auth.selector';

import styles from './TransUnionSummary.scss';

export interface TransUnionSummaryProps extends NavigationProps {
  fromNotification?: boolean;
}

const TransUnionSummary: React.FunctionComponent<TransUnionSummaryProps> = ({
  back,
  next,
  setSubpage,
  fromNotification,
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  if (!user?.id) {
    return null;
  }

  const transunionForm = () => {
    return (
      <div id="divTransunionCheck">
        <div className={classNames({ [styles.summaryHeadSection]: !fromNotification })}>
          <Typography msg={t('transunion_header_prefix_summary' as Messages)} size="xl" tag="h4" bold />
          <Typography msg={t(Messages.descTransunionSummary)} size="l" tag="p" />

          <Row columnTablet className={styles.infoBox}>
            <Col size={isTablet ? 12 : 5}>
              <Row constant>
                <Col className={styles.infoIcon}>
                  <Icon icon="info" />
                </Col>
                <Col size={6}>
                  <Row>
                    <Col>
                      <Typography msg={t('label_before_you_continue' as Messages)} tag="div" size="s" bolder />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <ol className={styles.listFontStyle}>
                <li>
                  Please check that your&nbsp;
                  <b>first name,</b>
                  &nbsp;
                  <b>last name</b>
                  &nbsp; and&nbsp;
                  <b>date of birth</b>
                  &nbsp; are correct
                </li>

                <li>
                  Please check that the &nbsp;
                  <b>address</b>
                  &nbsp; you have given is your current address
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        {/* <Paper className={layoutStyles.layoutContainer} width={PaperWidths.transunionWidth}>
          <div className={styles.summaryContainer}>
            <Typography tag="p" msg={t(Messages.titlePersonalDetails)} color="gray2" size="l" bold />
            <Row>
              <Col size={3}>
                <DataWithLabel data={user.title} label={t(Messages.fieldTitle)} dashForEmpty />
              </Col>
            </Row>
            <Row columnTablet>
              <Col size={3}>
                <DataWithLabel data={user.firstName} label={t(Messages.fieldFirstName)} dashForEmpty />
              </Col>
              <Col size={1} />
              <Col size={3}>
                <DataWithLabel data={user.lastName} label={t(Messages.fieldLastName)} dashForEmpty />
              </Col>
            </Row>
            <Row>
              <Col size={3}>
                <DataWithLabel
                  data={languageBasedDate(user.birthDate, currentLanguage)}
                  label={t(Messages.fieldBirthDate)}
                  dashForEmpty
                />
              </Col>
            </Row>
          </div>
        </Paper>
        <Paper className={layoutStyles.layoutContainer} width={PaperWidths.transunionWidth}>
          <div className={styles.summaryContainer}>
            <Typography tag="p" msg={t(Messages.titleAddressDetails)} color="gray2" size="s" bold />
            <Row columnTablet>
              <Col size={3}>
                <DataWithLabel data={user.buildingNumber} label={t(Messages.fieldBuildingNumber)} dashForEmpty />
              </Col>
              <Col size={1} />
              <Col size={3}>
                <DataWithLabel data={user.buildingName} label={t(Messages.fieldBuildingName)} dashForEmpty />
              </Col>
            </Row>
            <Row columnTablet>
              <Col size={3}>
                <DataWithLabel data={user.address1} label={t(Messages.fieldAddress1)} dashForEmpty />
              </Col>
              <Col size={1} />
              <Col size={3}>
                <DataWithLabel data={user.address2} label={t(Messages.fieldAddress2)} dashForEmpty />
              </Col>
            </Row>
            <Row columnTablet>
              <Col size={3}>
                <DataWithLabel data={user.city} label={t(Messages.fieldCity)} dashForEmpty />
              </Col>
              <Col size={1} />
              <Col size={3}>
                <DataWithLabel data={user.postalCode} label={t(Messages.fieldPostalCode)} dashForEmpty />
              </Col>
            </Row>
          </div>
        </Paper> */}
        {next && (
          <div className={classNames({ [styles.accountFormWidth]: !fromNotification })}>
            <AccountForm
              user={user}
              fromNotification={fromNotification}
              isTransunionStep={true}
              setNextPage={() => setSubpage(next)}
            />
          </div>
        )}
        {/* <Row columnTablet size={12}>
          {!fromNotification ? (
            <>
              {next && (
                <Col size={isTablet ? 9 : 5}>
                  <Button
                    type={ButtonTypes.button}
                    isFullWidth
                    onClick={() => {
                      setSubpage(next);
                    }}
                    label="Verify and continue"
                  />
                </Col>
              )}
            </>
          ) : (
            <>
              {next && (
                <Col size={7} className={styles.rightSpacing}>
                  <Button
                    type={ButtonTypes.button}
                    isFullWidth
                    icon="lock"
                    iconSize={IconSizes.ss}
                    onClick={() => {
                      setSubpage(next);
                    }}
                    label="Confirm and continue"
                    constant
                  />
                </Col>
              )}

            </>
          )}
        </Row> */}
        {back && !fromNotification && (
          <Row size={6}>
            <LinkButton
              className={styles.backToPrevStep}
              linkTitle={t(Messages.backToPrevStep)}
              onClick={() => setSubpage(back)}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  setSubpage(back);
                }
              }}
            />
          </Row>
        )}
      </div>
    );
  };

  return transunionForm();
};

export default TransUnionSummary;
