import * as React from 'react';
import { generatePath, useHistory, useParams } from 'react-router';

import ReviewCompanyCard from '@Components/application/ReviewCompanyCard';
import Button, { ButtonSizes } from '@Components/Button';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { colorArray, Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { deleteFromLocalStorage, getFromLocalStorageString } from '@Utils/localStorage';

import styles from './ReviewCompanies.scss';

export interface ReviewCompaniesProps {}

const ReviewCompanies: React.FunctionComponent<ReviewCompaniesProps> = () => {
  const t = useTranslations();
  const { id } = useParams();
  const history = useHistory();

  const caseProviders = getFromLocalStorageString(LocalStorage.caseServiceProviders);

  const caseProvidersArray = caseProviders.split(',');

  deleteFromLocalStorage(LocalStorage.caseServiceProviders);

  return (
    <>
      <div className={styles.reviewLayout}>
        <Row size={9}>
          <Typography
            className={styles.bottomSpacing}
            msg={t('subtitle_company_profile_reviewcompanies' as Messages)}
            tag="div"
            size="l"
          />
        </Row>
        {caseProvidersArray.map((c: any, idx: number) => (
          <ReviewCompanyCard initialBackground={colorArray[idx]} key={1} caseServiceProviderId={c} />
        ))}
      </div>

      <Row size={3}>
        <Button
          label={t('button_confirm_add_companies' as Messages)}
          size={ButtonSizes.fill}
          onClick={() => history.push(generatePath(Links.lcReady, { id }))}
          className={styles.buttonSpacing}
        />
      </Row>
      {/* <Row>
        <Icon icon="info" className={styles.messageInfo} />
        <Typography msg={t('note_complete_missing_infor_later' as Messages)} tag="div" size="l" color="footerColor" />
      </Row> */}
    </>
  );
};

export default ReviewCompanies;
