import moment from 'moment';
import { generatePath } from 'react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { AppVersion, Links, LocalStorage, Timeouts } from '@Config/constants';
import {
  changePasswordMutation,
  forgotPasswordMutation,
  loginMutation,
  registerUserMutation,
  resendEmailMutation,
  resendVerificationMutation,
  resetPasswordMutation,
  verifyEmailMutation,
} from '@Graphql/auth/auth.mutations';
import { getUserQuery } from '@Graphql/auth/auth.queries';
import { fetchDeathCasesQuery } from '@Graphql/dc/dc.queries';
import {
  DeathCaseNode,
  ObtainJsonWebToken,
  UserAccountType,
  UserLandingPage,
  UserNodeDetails,
  UserSocialLoginType,
} from '@Graphql/graphqlTypes.generated';
import { normaliseGqlError, getGqlErrorMessage } from '@Utils/form';
import { deleteAllCookies, logInfo, msalInstance } from '@Utils/helpers';
import { deleteFromLocalStorage, getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationSuccess, notificationError } from '@Utils/notificationUtils';

import { locationChange } from '../navigation/navigation.actions';

import {
  changePassword,
  forgotPassword,
  getUserData,
  login,
  logout,
  register,
  resendEmail,
  resendVerification,
  resetPassword,
  verifyEmail,
} from './auth.actions';
import {
  ChangePasswordActionPayload,
  ForgotPasswordActionPayload,
  LoginActionPayload,
  LoginActionSuccess,
  RegisterActionPayload,
  ResendEmailActionPayload,
  ResendVerificationActionPayload,
  ResetPasswordActionPayload,
  VerifyEmailActionPayload,
  VerifySteps,
} from './auth.types';

export function* loginSaga(action: Action<LoginActionPayload>) {
  deleteFromLocalStorage(LocalStorage.userToken);
  deleteFromLocalStorage(LocalStorage.accessTokenExpiry);
  const { setStatus, setSubmitting, setShowOtpBox, setQrCodeUrl, ...respProps } = action.payload;
  try {
    const result: ObtainJsonWebToken = yield call(loginMutation, respProps);
    yield put(login.done({ result, params: action.payload }));
  } catch (error: any) {
    const msg = getGqlErrorMessage(error.message);
    if (msg === 'msg_otp_required') {
      yield setShowOtpBox && setShowOtpBox(true);
    } else if (msg === 'msg_error_configure_new_device') {
      yield setQrCodeUrl && setQrCodeUrl(error[0].params.url);
    }
    let errorMessage = error.message;
    if (getGqlErrorMessage(errorMessage) === 'GOOGLE') {
      errorMessage = 'GraphQL error: msg_use_google_to_sign_in';
    }
    yield put(notificationError(normaliseGqlError(errorMessage)));
    yield setStatus(normaliseGqlError(errorMessage));
    yield setSubmitting(false);
  }
}

export function* loginSuccessSaga(action: Action<LoginActionSuccess>) {
  try {
    yield setToLocalStorage(LocalStorage.userToken, action.payload.result.token);
    // Get the current time
    const currentTime = moment();
    // Calculate the time after 55 minutes
    const timeAfter55Minutes = currentTime.add(50, 'minutes');
    const result: UserNodeDetails = yield call(getUserQuery);
    // const { queryString } = action.payload.params;
    yield put(getUserData.done({ result, params: {} }));
    const sessionEmailId = getFromLocalStorage(LocalStorage.providerEmail);
    if (sessionEmailId !== result.info.email) {
      yield deleteFromLocalStorage(LocalStorage.providerEmail);
    }
    const isNotificationSummaryLink = getFromLocalStorage(LocalStorage.isNotificationSummary);
    const spNotiLink = getFromLocalStorage(LocalStorage.spNotification);
    if (isNotificationSummaryLink !== null && result.info.accountType === UserAccountType.ServiceProvider) {
      const splitLink = isNotificationSummaryLink.split('/');
      deleteFromLocalStorage(LocalStorage.isNotificationSummary);
      yield put(
        locationChange({
          path: generatePath(`${Links.dcReady}?caseproviderid=${splitLink[1]}`, {
            id: splitLink[0],
          }),
        })
      );
    } else if (spNotiLink !== null) {
      deleteFromLocalStorage(LocalStorage.spNotification);
      yield put(
        locationChange({
          path: generatePath(Links.notificationDashboard, {
            accessCode: spNotiLink,
          }),
        })
      );
    } else if (result.info.accountType === UserAccountType.ServiceProvider) {
      yield put(locationChange({ path: `${Links.providerDashboard}` }));
    } else if (
      result.info.accountType === UserAccountType.Individual &&
      result.info.isStaff &&
      result.info.lifeLedgerPortalAccess
    ) {
      yield setToLocalStorage(LocalStorage.accessTokenExpiry, timeAfter55Minutes);
      yield put(locationChange({ path: `${Links.staffDashboard}` }));
    } else if (result.info.appVersion === AppVersion.second && result.info.isFirstLogin) {
      const dcResult: DeathCaseNode = yield call(fetchDeathCasesQuery);
      yield put(
        locationChange({
          path: generatePath(Links.dcReady, {
            id: dcResult.id,
          }),
        })
      );
    } else {
      const homeUrl =
        (result.info.landingPage === UserLandingPage.RetargetLifeCase ||
          result.info.landingPage === UserLandingPage.RetargetDeathCase) &&
        result.info.isFirstLogin
          ? `${Links.home}?registration=success`
          : Links.home;
      if (result.info.socialLoginType === UserSocialLoginType.Azure) {
        yield setToLocalStorage(LocalStorage.socialLoginType, result.info.socialLoginType);
      }
      yield put(locationChange({ path: generatePath(homeUrl) }));
    }
  } catch (error) {
    yield put(locationChange({ path: generatePath(Links.login) }));
  }
}

export function* getUserDataSaga() {
  try {
    const result: UserNodeDetails = yield call(getUserQuery);
    yield put(getUserData.done({ result, params: {} }));
    // if (isYotiStatusPending(result.info?.verificationStatus)) {
    //   yield put(startYotiPolling());
    // }
  } catch (error) {
    yield logInfo('getUserQuery failed', error);
    yield deleteFromLocalStorage(LocalStorage.userToken);
    yield deleteFromLocalStorage(LocalStorage.accessTokenExpiry);
    yield put(locationChange({ path: generatePath(Links.login) }));
  }
}

export function* logoutSaga() {
  const socialLoginType = getFromLocalStorage(LocalStorage.socialLoginType);
  if (socialLoginType === UserSocialLoginType.Azure) {
    msalInstance.logout();
  }
  yield deleteFromLocalStorage(LocalStorage.userToken);
  yield deleteFromLocalStorage(LocalStorage.accessTokenExpiry);
  sessionStorage.clear();
  deleteAllCookies();
  yield deleteFromLocalStorage(LocalStorage.socialLoginType);
  const loginPage = socialLoginType === UserSocialLoginType.Saml ? `${Links.lvlogin}?sp=lvinsurance` : Links.logout;
  yield put(locationChange({ path: generatePath(loginPage) }));
}

export function* registerUserSaga(action: Action<RegisterActionPayload>) {
  const { setStatus, setSubmitting, isGuidanceRight, ...restProps } = action.payload;
  try {
    if (isGuidanceRight) {
      yield call(registerUserMutation, restProps);
      const updatedRestProps = { ...restProps, accessCode: undefined };
      const result: ObtainJsonWebToken = yield call(loginMutation, updatedRestProps);
      yield put(
        login.done({
          result,
          params: { queryString: '', email: restProps.email, password: restProps.password, setStatus, setSubmitting },
        })
      );
    } else {
      yield setSubmitting(false);
    }
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* forgotPasswordSaga(action: Action<ForgotPasswordActionPayload>) {
  const { setStatus, setSubmitting, setEmailSentTo, email } = action.payload;
  try {
    yield call(forgotPasswordMutation, { email });
    yield setEmailSentTo(email);
    yield setSubmitting(false);
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* resendEmailSaga(action: Action<ResendEmailActionPayload>) {
  const { setStatus, setSubmitting, email } = action.payload;
  try {
    yield setSubmitting(true);
    yield call(resendEmailMutation, { email });
    yield setTimeout(() => setSubmitting(false), Timeouts.resendEmail * 1000);
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* verifyEmailSaga(action: Action<VerifyEmailActionPayload>) {
  const { verificationKey, setStep } = action.payload;
  try {
    yield call(verifyEmailMutation, { verificationKey });
    yield setStep(VerifySteps.success);
    yield put(locationChange({ path: generatePath(`${Links.login}?email_verified=true`) }));
  } catch (error) {
    yield setStep(VerifySteps.failure);
  }
}

export function* resendVerificationSaga(action: Action<ResendVerificationActionPayload>) {
  const { setStatus, setSubmitting, email } = action.payload;
  try {
    yield setSubmitting(true);
    yield call(resendVerificationMutation, { email });
    yield setTimeout(() => setSubmitting(false), Timeouts.resendEmail * 1000);
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* resetPasswordSaga(action: Action<ResetPasswordActionPayload>) {
  const { setStatus, setSubmitting, setPasswordChanged, password, passwordKey, isGuidanceRight } = action.payload;
  try {
    if (isGuidanceRight) {
      yield call(resetPasswordMutation, { password, passwordKey });
      yield setPasswordChanged(true);
    } else {
      yield setSubmitting(false);
    }
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}
export function* changePasswordSaga(action: Action<ChangePasswordActionPayload>) {
  const { setStatus, setSubmitting, password, oldPassword, resetForm, successMessage } = action.payload;
  try {
    yield call(changePasswordMutation, { password, oldPassword });
    yield put(notificationSuccess(successMessage));
    yield setSubmitting(false);
    yield resetForm();
  } catch (error: any) {
    yield setStatus(normaliseGqlError(error.message));
    yield setSubmitting(false);
  }
}

export function* watchAuthSaga() {
  yield all([
    yield takeLatest(login.started, loginSaga),
    yield takeLatest(login.done, loginSuccessSaga),
    yield takeLatest(login.done, getUserDataSaga),
    yield takeLatest(getUserData.started, getUserDataSaga),
    yield takeLatest(logout, logoutSaga),
    yield takeLatest(register.started, registerUserSaga),
    yield takeLatest(verifyEmail.started, verifyEmailSaga),
    yield takeLatest(forgotPassword.started, forgotPasswordSaga),
    yield takeLatest(resendEmail.started, resendEmailSaga),
    yield takeLatest(resendVerification.started, resendVerificationSaga),
    yield takeLatest(resetPassword.started, resetPasswordSaga),
    yield takeLatest(changePassword.started, changePasswordSaga),
  ]);
}
