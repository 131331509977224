import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import CautionBox from '@Components/application/CautionBox';
import FileInput from '@Components/form/inputs/FileInput';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  DocumentDocumentType,
  LifeCaseNode,
  Permissions,
  useCollaboratorsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { WillVisibilityType } from '@Routes/deathCase/DeathCasePage/WillForm/WillForm';
import { getUser } from '@Store/auth/auth.selector';
import { uploadDocuments } from '@Store/dc/dc.actions';
import { deleteConfirmationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './AdditionalInfoForm.scss';

export interface AdditionalInfoFormProps extends NavigationProps {
  containerClasses?: string;
}

export interface AdditionalInfoFormStateProps {
  data: LoadableItem<LifeCaseNode>;
}

interface AdditionalInfoFormPrivateProps extends AdditionalInfoFormProps, AdditionalInfoFormStateProps {}

enum AdditionalInfoFormFields {
  hasFuneralPlan = 'hasFuneralPlan',
  hasInsurancePolicy = 'hasInsurancePolicy',
  hasOtherDocuments = 'hasOtherDocuments',
  funeralPlanVisibility = 'funeralPlanVisibility',
  insurancePolicyVisibility = 'insurancePolicyVisibility',
  otherDocsVisibility = 'otherDocsVisibility',
}

const AdditionalInfoForm: React.FunctionComponent<AdditionalInfoFormPrivateProps> = ({
  data,
  back,
  next,
  setSubpage,
  containerClasses,
}) => {
  // const currentLanguage = useSelector(getCurrentLanguage);
  const t = useTranslations();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const user = useSelector(getUser);

  const collaboratorsData = useCollaboratorsQuery({
    variables: {
      baseCase: data.item?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  const willVisibilityOptions = () => {
    return [
      { label: t('label_will_visibility_item_ONLYME' as Messages), value: WillVisibilityType.OnlyMe },
      {
        label: t('label_will_visibility_item_MEANDALLCOLLABORATORS' as Messages),
        value: WillVisibilityType.MeAndAllCollaborators,
      },
    ];
  };

  const setNextStep = () => (next ? setSubpage(next) : {});

  const funeralPlanVisibilityInitialValue = () => {
    if (data.item && data.item?.funeralPlans.length > 0) {
      return data.item.funeralPlans[0].collaboratorsView
        ? WillVisibilityType.MeAndAllCollaborators
        : WillVisibilityType.OnlyMe;
    }
    return '';
  };

  const insurancePolicyInitialValue = () => {
    if (data.item && data.item?.insurancePolicies.length > 0) {
      return data.item.insurancePolicies[0].collaboratorsView
        ? WillVisibilityType.MeAndAllCollaborators
        : WillVisibilityType.OnlyMe;
    }
    return '';
  };

  const otherDocsInitialValue = () => {
    if (data.item && data.item?.otherDocuments.length > 0) {
      return data.item.otherDocuments[0].collaboratorsView
        ? WillVisibilityType.MeAndAllCollaborators
        : WillVisibilityType.OnlyMe;
    }
    return '';
  };

  const getCollaboratorDetails = () => {
    const collab = collaboratorsData.data?.collaborators.edges.find((cb: any) => cb.node.email === user?.email);
    return collab ? collab.node.id : undefined;
  };

  return (
    <>
      <Row className={classNames(styles.notesBottomSpacing, styles.pageWidth)}>
        <CautionBox
          containerClassName={styles.cautionBox}
          info={t('desc_additional_info' as Messages)}
          title={t(Messages.labelTip)}
        />
      </Row>
      <Formik
        initialValues={{
          [AdditionalInfoFormFields.hasFuneralPlan]: data.item && data.item?.funeralPlans.length > 0,
          [AdditionalInfoFormFields.hasInsurancePolicy]: data.item && data.item?.insurancePolicies.length > 0,
          [AdditionalInfoFormFields.hasOtherDocuments]: data.item && data.item?.otherDocuments.length > 0,
          [AdditionalInfoFormFields.funeralPlanVisibility]: funeralPlanVisibilityInitialValue(),
          [AdditionalInfoFormFields.insurancePolicyVisibility]: insurancePolicyInitialValue(),
          [AdditionalInfoFormFields.otherDocsVisibility]: otherDocsInitialValue(),
        }}
        onSubmit={() => {
          setNextStep();
        }}
      >
        {({ isSubmitting, values, status }) => (
          <Form className={styles.pageWidth}>
            <div className={containerClasses}>
              <Row justifyBetween>
                <Col size={12}>
                  <Field
                    name={AdditionalInfoFormFields.hasFuneralPlan}
                    label="Would you like to upload a funeral plan?"
                    component={ToggleSwitchInput}
                  />
                </Col>
              </Row>

              {values.hasFuneralPlan && (
                <div className={styles.flexGap}>
                  {!isMobile && <div className={styles.bar} />}

                  <div className={styles.rightDiv}>
                    <Row justifyBetween>
                      <Col size={12}>
                        <Field
                          name={AdditionalInfoFormFields.funeralPlanVisibility}
                          options={willVisibilityOptions()}
                          component={RadioButton}
                          label="Who do you want the funeral plan to be visible to?"
                        />
                      </Col>
                    </Row>

                    <Typography msg="Please upload a copy of the funeral plan" size="l" tag="div" />
                    <div>
                      <FileInput
                        onDrop={
                          (files: File[]) =>
                            dispatch(
                              uploadDocuments.started({
                                baseCaseId: id,
                                documentType: DocumentDocumentType.FuneralPlan,
                                files,
                                collaboratorsView:
                                  values.funeralPlanVisibility === WillVisibilityType.MeAndAllCollaborators,
                                collaboratorId: getCollaboratorDetails(),
                              })
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        onRemoveClick={deleteConfirmationHandler(
                          t,
                          dispatch,
                          DocumentDocumentType.RepresentationLetter
                        )}
                        files={data.item?.funeralPlans || []}
                        isReadOnlyUser={data.item?.myPermission === Permissions.ReadOnly}
                        isLifeCase={true}
                      />
                    </div>
                  </div>
                </div>
              )}

              <Row justifyBetween>
                <Col size={12}>
                  <Field
                    name={AdditionalInfoFormFields.hasInsurancePolicy}
                    label="Would you like to upload an insurance policy?"
                    component={ToggleSwitchInput}
                  />
                </Col>
              </Row>

              {values.hasInsurancePolicy && (
                <div className={styles.flexGap}>
                  {!isMobile && <div className={styles.bar} />}

                  <div className={styles.rightDiv}>
                    <Row justifyBetween>
                      <Col size={12}>
                        <Field
                          name={AdditionalInfoFormFields.insurancePolicyVisibility}
                          options={willVisibilityOptions()}
                          component={RadioButton}
                          label="Who do you want the insurance policy to be visible to?"
                        />
                      </Col>
                    </Row>

                    <Typography msg="Please upload a copy of the insurance policy" size="l" tag="div" />
                    <div>
                      <FileInput
                        onDrop={
                          (files: File[]) =>
                            dispatch(
                              uploadDocuments.started({
                                baseCaseId: id,
                                documentType: DocumentDocumentType.InsurancePolicy,
                                files,
                                collaboratorId: getCollaboratorDetails(),
                                collaboratorsView:
                                  values.insurancePolicyVisibility === WillVisibilityType.MeAndAllCollaborators,
                              })
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        onRemoveClick={deleteConfirmationHandler(
                          t,
                          dispatch,
                          DocumentDocumentType.RepresentationLetter
                        )}
                        files={data.item?.insurancePolicies || []}
                        isReadOnlyUser={data.item?.myPermission === Permissions.ReadOnly}
                        isLifeCase={true}
                      />
                    </div>
                  </div>
                </div>
              )}

              <Row justifyBetween>
                <Col size={12}>
                  <Field
                    name={AdditionalInfoFormFields.hasOtherDocuments}
                    label="Would you like to upload any additional documents?"
                    component={ToggleSwitchInput}
                  />
                </Col>
              </Row>

              {values.hasOtherDocuments && (
                <div className={styles.flexGap}>
                  {!isMobile && <div className={styles.bar} />}

                  <div className={styles.rightDiv}>
                    <Row justifyBetween>
                      <Col size={12}>
                        <Field
                          name={AdditionalInfoFormFields.otherDocsVisibility}
                          options={willVisibilityOptions()}
                          component={RadioButton}
                          label="Who do you want these additional documents to be visible to?"
                        />
                      </Col>
                    </Row>

                    <Typography msg="Please upload any additional documents" size="l" tag="div" />

                    <div>
                      <FileInput
                        onDrop={
                          (files: File[]) =>
                            dispatch(
                              uploadDocuments.started({
                                baseCaseId: id,
                                documentType: DocumentDocumentType.Other,
                                files,
                                collaboratorId: getCollaboratorDetails(),
                                collaboratorsView:
                                  values.otherDocsVisibility === WillVisibilityType.MeAndAllCollaborators,
                              })
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        onRemoveClick={deleteConfirmationHandler(
                          t,
                          dispatch,
                          DocumentDocumentType.RepresentationLetter
                        )}
                        files={data.item?.otherDocuments || []}
                        isReadOnlyUser={data.item?.myPermission === Permissions.ReadOnly}
                        isLifeCase={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <FormError formError={status} />
            <NavigationBlock isSubmitting={isSubmitting} back={back} next={next} setSubpage={setSubpage} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdditionalInfoForm;
