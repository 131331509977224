import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import { Links, LocalStorage, ObTransactionType } from '@Config/constants';
import { ConvertToNotificationMutation, ConvertToNotificationMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationConvertToNotification } from '@Graphql/lc/mutations';
import { normaliseGqlError } from '@Utils/form';
import { deleteFromLocalStorage, getArrayFromLocalStorage, getFromLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './ObNeverLeaveModalBody.scss';

export interface ObNeverLeaveModalBodyProps {
  lifeCaseId: string;
}

const ObNeverLeaveModalBody: React.FunctionComponent<ModalProviderProps<ObNeverLeaveModalBodyProps>> = ({
  modalData: { lifeCaseId },
  closeModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryString = history.location.search;

  const [updateTransactions, { loading }] = useMutation<
    ConvertToNotificationMutation,
    ConvertToNotificationMutationVariables
  >(mutationConvertToNotification, {
    onCompleted: () => {
      const addedCompanies = getArrayFromLocalStorage(LocalStorage.obCompanyIds);
      dispatch(
        notificationSuccess(`${addedCompanies.length} ${addedCompanies.length > 1 ? 'companies' : 'company'} added`)
      );
      closeModal();
      deleteFromLocalStorage(LocalStorage.obCompanySelected);
      deleteFromLocalStorage(LocalStorage.obCompanyIds);
      history.push(generatePath(`${Links.lcReady}${queryString}`, { id: lifeCaseId }));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  return (
    <>
      <Typography bold msg="Do you want to add the companies that you have selected?" tag="h4" size="xl" />

      {getFromLocalStorage(LocalStorage.obCompanySelected) === ObTransactionType.undefined && (
        <Typography
          className={styles.mt16}
          msg="Keep in mind that any companies that you have selected but have not identified can not be added. You can come back later and identify these companies at any point."
          tag="div"
          size="l"
        />
      )}

      <Button
        className={styles.mt30}
        onClick={() => {
          const transactions = getArrayFromLocalStorage(LocalStorage.obCompanyIds);
          updateTransactions({
            variables: {
              input: {
                transactions,
              },
            },
          });
        }}
        isFullWidth
        type={ButtonTypes.button}
        label="Yes, I want to add the companies"
        loading={loading}
      />
      <LinkButton
        textCenter
        className={styles.mt18}
        linkTitle="No, I want to exit without adding any companies"
        onClick={() => {
          closeModal();
          deleteFromLocalStorage(LocalStorage.obCompanySelected);
          deleteFromLocalStorage(LocalStorage.obCompanyIds);
          history.push(generatePath(`${Links.lcReady}${queryString}`, { id: lifeCaseId }));
        }}
      />
    </>
  );
};

export default ObNeverLeaveModalBody;
