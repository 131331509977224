import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router';

import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './MiniFooter.scss';

export interface MiniFooterProps {
  centerAlign?: boolean;
  containerClassName?: string;
  noMaxWidth?: boolean;
}

const MiniFooter: React.FunctionComponent<MiniFooterProps> = ({
  centerAlign = true,
  containerClassName,
  noMaxWidth,
}) => {
  const t = useTranslations();

  const history = useHistory();
  const queryString = history.location.search;

  return (
    <footer>
      {/* {googleTagManagerId && enablePerformanceCookie && <GoogleTagManager gtmId={googleTagManagerId} />} */}
      <div
        className={classNames(styles.marginTop80, { [styles.justifyCenter]: centerAlign }, containerClassName, {
          [styles.res1400Max]: !noMaxWidth,
        })}
      >
        <div
          // eslint-disable-next-line
         tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              history.push(`${Links.home}${queryString}`);
            }
          }}
          className={styles.handSymbol}
          onClick={() => history.push(`${Links.home}${queryString}`)}
        >
          <img width="173" height="37" src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg" alt="" />
        </div>

        <a href="https://lifeledger.com/terms-conditions/" target="_blank" rel="noreferrer">
          <Typography
            msg={t(Messages.labelTermsConditionPage)}
            size="m"
            tag="div"
            family="helvetica"
            color="black"
            underline
            className={styles.links}
          />
        </a>

        <a href="https://lifeledger.com/privacy-policy/" target="_blank" rel="noreferrer">
          <Typography
            msg={t(Messages.linkPrivacyNotice)}
            size="m"
            tag="div"
            family="helvetica"
            color="black"
            underline
            className={styles.links}
          />
        </a>

        <a href="https://lifeledger.com/cookies/" target="_blank" rel="noreferrer">
          <Typography
            msg="Cookies"
            size="m"
            tag="div"
            family="helvetica"
            color="black"
            underline
            className={styles.links}
          />
        </a>
      </div>
    </footer>
  );
};

export default MiniFooter;
