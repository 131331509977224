import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import LifeCaseInfo from '@Components/application/LifeCaseInfo';
import Button from '@Components/Button/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { AllLCStatuses, CaseTags, Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { LifeCaseStatus, useLifeCasesQuery, UserAccountType, usePackagesQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import LifeCasesColumn from '@Routes/dashboard/LifeCasesPage/LifeCasesContent/LifeCasesColumn';
import emptyStyles from '@Routes/deathCase/ReadyDCPage/ServicesPage/DCServicesColumn/DCServicesColumn.scss';
import { getUser } from '@Store/auth/auth.selector';
import { registerLifeCaseModal } from '@Utils/modal';

import DiscoveryComponent from './DiscoveryComponent';

export interface LifeCasesContentProps {
  hasUnusedLifeSubscription?: boolean;
}

const LifeCasesContent: React.FunctionComponent<LifeCasesContentProps> = ({ hasUnusedLifeSubscription }) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isRes936 = useMediaQuery({ query: ReactResponsiveQueries.Res986 });
  const dispatch = useDispatch();

  // Call the usePackagesQuery hook unconditionally at the top level
  const paymentPackages = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  // Call the useLifeCaseQuery hook unconditionally at the top level
  const { data: lifeCase, loading: lifeCaseLoading } = useLifeCasesQuery({
    variables: {
      status: AllLCStatuses,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (paymentPackages.loading || lifeCaseLoading) {
    return (
      <Col alignCenter textCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const draftStatusCases = lifeCase?.lifeCases.edges.filter((lc: any) => lc.node.status === LifeCaseStatus.Draft);
  const preparedStatusCases = lifeCase?.lifeCases.edges.filter((lc: any) => lc.node.status === LifeCaseStatus.Prepared);

  const draftCount = draftStatusCases ? draftStatusCases.length : 0;
  const restCasesCount = preparedStatusCases ? preparedStatusCases.length : 0;

  const totalCount = draftCount + restCasesCount;

  const basicPackage = paymentPackages?.data?.packages.find((p: any) => p.features[0] === 'BASIC_LIFE_CASE');

  const emptyCaseComponent = () => {
    return user?.accountType === UserAccountType.Individual ? (
      enableDiscoveryComponent()
    ) : (
      <Row size={10} justifyCenter column className={emptyStyles.emptyContainer}>
        <Col textCenter>
          <Icon className={emptyStyles.emptyIcon} icon="sun" size={IconSizes.sm} />
        </Col>
        <Col textCenter>
          <Typography msg={t(Messages.labelZeroRegisteredLives)} tag="div" size="m" color="gray3" />
        </Col>
      </Row>
    );
  };

  const enableDiscoveryComponent = () => {
    return user?.enableDiscovery ? (
      <>
        <Typography msg={t('title_life_detail' as Messages)} tag="h4" size="xl" />
        <Typography msg={t('desc_life_detail' as Messages)} tag="p" color="footerColor" size="l" />
        <Row column={isRes936} className={emptyStyles.mt45}>
          <Col>
            <Row constant alignCenter className={emptyStyles.gap13}>
              <Col className={emptyStyles.blueCircle} />
              <Typography msg={t('title_register_your_life' as Messages)} tag="div" size="l" bolder />
            </Row>
            <Row constant className={emptyStyles.gap14}>
              <Col className={emptyStyles.blueBorder} />
              <Typography msg={t('desc_register_your_life' as Messages)} tag="div" size="l" />
            </Row>
            <Row constant alignCenter className={emptyStyles.gap13}>
              <Col className={emptyStyles.blueCircle} />
              <Typography msg={t('title_choose_company' as Messages)} tag="div" size="l" bolder />
            </Row>
            <Row constant className={emptyStyles.gap14}>
              <Col className={emptyStyles.blueBorder} />
              <Typography msg={t('desc_choose_company' as Messages)} tag="div" size="l" />
            </Row>

            <Row constant className={emptyStyles.gap13}>
              <Col className={classNames(emptyStyles.blueCircle, emptyStyles.mt12, emptyStyles.ticks)} />
              <Typography msg={t('title_no_stress' as Messages)} tag="div" size="l" bolder />
            </Row>

            <Row constant className={emptyStyles.gap14}>
              <Col className={emptyStyles.transparentBorder} />
              <Typography msg={t('desc_no_stress' as Messages)} tag="div" size="l" />
            </Row>
          </Col>
          {paymentPackages.data?.packages && paymentPackages.data.packages.length > 0 && (
            <DiscoveryComponent packagesData={paymentPackages} />
          )}
        </Row>
      </>
    ) : (
      <>
        <Typography msg={t('title_life_detail' as Messages)} tag="h4" size="xl" />
        <Typography msg={t('desc_life_detail' as Messages)} tag="p" color="footerColor" size="l" />
        <Row className={emptyStyles.mt45}>
          <Col>
            <Row constant alignCenter className={emptyStyles.gap13}>
              <Col className={emptyStyles.blueCircle} />
              <Typography msg={t('title_register_your_life' as Messages)} tag="div" size="l" bolder />
            </Row>
            <Row constant className={emptyStyles.gap14}>
              <Col className={emptyStyles.blueBorder} />
              <Typography msg={t('desc_register_your_life' as Messages)} tag="div" size="l" />
            </Row>
            <Row constant alignCenter className={emptyStyles.gap13}>
              <Col className={emptyStyles.blueCircle} />
              <Typography msg={t('title_choose_company' as Messages)} tag="div" size="l" bolder />
            </Row>
            <Row constant className={emptyStyles.gap14}>
              <Col className={emptyStyles.blueBorder} />
              <Typography msg={t('desc_choose_company' as Messages)} tag="div" size="l" />
            </Row>

            <Row constant className={emptyStyles.gap13}>
              <Col className={classNames(emptyStyles.blueCircle, emptyStyles.mt12, emptyStyles.ticks)} />
              <Typography msg={t('title_no_stress' as Messages)} tag="div" size="l" bolder />
            </Row>

            <Row constant className={emptyStyles.gap14}>
              <Col className={emptyStyles.transparentBorder} />
              <Typography msg={t('desc_no_stress' as Messages)} tag="div" size="l" />
            </Row>
          </Col>
          <Col className={emptyStyles.packageBox}>
            <Typography textCenter msg={t('title_get_affairs' as Messages)} tag="div" size="l" bolder />
            <Row className={emptyStyles.mt24} justifyCenter constant>
              <Typography className={emptyStyles.fromLabel} msg="From" tag="div" size="l" />
              &nbsp;
              <Typography bold msg="£12" tag="div" size="xxxl" />
              <Typography msg="/year" tag="div" size="l" className={emptyStyles.tenure} />
            </Row>
            <Typography msg={t('desc_own_register' as Messages)} tag="div" size="l" />
            <Row
              justifyCenter
              constant
              alignCenter
              className={classNames(emptyStyles.listItemGap, emptyStyles.lineHeight, emptyStyles.mt24)}
            >
              <Icon className={emptyStyles.ticks} icon="tick" size={IconSizes.tick1} />
              <Typography msg={t('label_unlimited_companies' as Messages)} tag="div" size="m" color="footerColor" />
            </Row>
            <Button
              onClick={() => {
                if (hasUnusedLifeSubscription) {
                  history.push(
                    generatePath(`${Links.lcCreate}?ill=undefined`, { tag: `tag=${CaseTags.lifeCaseCreated}` })
                  );
                } else {
                  registerLifeCaseModal(dispatch, basicPackage);
                }
              }}
              label={t('cta_register_your_life' as Messages)}
              isFullWidth
              className={emptyStyles.mt18}
              autoFocus
            />
          </Col>
        </Row>
      </>
    );
  };

  return totalCount > 0 ? (
    <div>
      {draftStatusCases && draftStatusCases?.length > 0 && (
        <div style={{ marginBottom: '30px' }}>
          <LifeCasesColumn
            CardComponent={LifeCaseInfo}
            statuses={[LifeCaseStatus.Draft]}
            title="Information missing"
            lcCases={draftStatusCases}
            subTitle={user?.accountType === UserAccountType.Professional ? t('info_prof_draft_lc' as Messages) : ''}
          />
        </div>
      )}
      {preparedStatusCases && preparedStatusCases.length > 0 && (
        <LifeCasesColumn
          CardComponent={LifeCaseInfo}
          statuses={[LifeCaseStatus.Prepared]}
          title="Active"
          lcCases={preparedStatusCases}
          subTitle={user?.accountType === UserAccountType.Professional ? t('info_prof_active_lc' as Messages) : ''}
          showAssignedTo={!isMobile}
        />
      )}
    </div>
  ) : (
    emptyCaseComponent()
  );
};

export default LifeCasesContent;
