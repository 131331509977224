import * as React from 'react';

import { DeathCaseFragment, LifeCaseFragment } from '@Graphql/graphqlTypes.generated';
import DeathCaseNotificationPage from '@Routes/deathCase/DeathCaseNotification/DeathCaseNotificationPage';

import { ModalProviderProps } from '../Modal';

export interface DeathNotificationModalBodyProps {
  onClose?: () => void;
  isLifeCase?: boolean;
  caseData?: DeathCaseFragment | LifeCaseFragment;
  serviceId: string;
  isDocUploaded?: boolean;
  backgroundColor?: string;
  goToTab?: () => void;
}

const DeathNotificationModalBody: React.FunctionComponent<ModalProviderProps<DeathNotificationModalBodyProps>> = ({
  modalData: { caseData, isLifeCase, serviceId, isDocUploaded, backgroundColor, goToTab },
  closeModal,
}) => {
  return (
    <DeathCaseNotificationPage
      isDocUploaded={isDocUploaded}
      id={serviceId}
      caseData={caseData}
      isLifeCase={isLifeCase}
      backgroundColor={backgroundColor}
      closeModal={() => closeModal()}
      goToTab={goToTab}
    />
  );
};

export default DeathNotificationModalBody;
