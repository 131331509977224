import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import ReferredDeathCase from '@Routes/deathCase/ReferredDeathCase';

import { RouteInfo } from '../../appRoutes';

const routes: RouteInfo[] = [
  {
    path: Links.dcRefer,
    component: ReferredDeathCase,
    title: Messages.pageReferDeathCase,
    isServiceProviderRoute: false,
  },
];

export default routes;
