import * as React from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Button';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';

import { ModalProviderProps } from '../Modal';

import styles from './EmptyModalBody.scss';

export interface EmptyModalBodyProps {
  content?: string;
}

const EmptyModalBody: React.FunctionComponent<ModalProviderProps> = ({ modalData: { content }, closeModal }) => {
  return (
    <div>
      <Row alignCenter className={styles.bottomSpacing}>
        <Typography msg={content} tag="span" className={styles.title} />
      </Row>
      <Row alignCenter>
        <Button label="Close" size={ButtonSizes.fill} style={ButtonStyles.black} onClick={() => closeModal()} />
      </Row>
    </div>
  );
};

export default EmptyModalBody;
