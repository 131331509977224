import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import ForgotPasswordPage from '@Routes/auth/ForgotPasswordPage';
import InvitePage from '@Routes/auth/InvitePage';
import ResetPasswordPage from '@Routes/auth/ResetPasswordPage';
import VerifyEmailPage from '@Routes/auth/VerifyEmailPage';
import SuccessPage from '@Routes/settings/StripePaymentsPage/SuccessPage/SuccessPage';

import { RouteInfo } from '../appRoutes';

import BeSpokeLoginPage from './BeSpokeLoginPage';
import LoginPage from './LoginPage';
import MicrosoftLandingPage from './MicrosoftLandingPage';
import NewRegisterPage from './NewRegisterPage';
import PersonalisedLoginPage from './PersonalisedLoginPage/PersonalisedLoginPage';
import RegisterPage from './RegisterPage';
import IndividualRegisterPage from './RegisterPage/IndividualRegisterPage/IndividualRegisterPage';
import SPLoginPage from './SPLoginPage/SPLoginPage';
import SSOLogin from './SSOLogin/SSOLogin';

const routes: RouteInfo[] = [
  {
    path: Links.login,
    component: LoginPage,
    title: Messages.pageLogin,
  },
  {
    path: Links.logout,
    component: LoginPage,
    title: Messages.pageLogin,
    isServiceProviderRoute: true,
  },
  {
    path: Links.register,
    component: RegisterPage,
    title: Messages.pageRegister,
    isServiceProviderRoute: true,
  },
  {
    path: Links.individualRegister,
    component: IndividualRegisterPage,
    title: Messages.pageRegister,
    isServiceProviderRoute: true,
  },
  {
    path: Links.verifyEmail,
    component: VerifyEmailPage,
    title: Messages.pageRegister,
    isServiceProviderRoute: false,
  },
  {
    path: Links.forgotPassword,
    component: ForgotPasswordPage,
    title: Messages.pageForgotPassword,
    isServiceProviderRoute: true,
  },
  {
    path: Links.resetPassword,
    component: ResetPasswordPage,
    title: Messages.pageResetPassword,
    isServiceProviderRoute: true,
  },
  {
    path: Links.invite,
    component: InvitePage,
    title: Messages.pageInvite,
    isServiceProviderRoute: false,
  },
  {
    path: Links.beSpokeLogin,
    component: BeSpokeLoginPage,
    title: 'page_title_be_spoke_login' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.bankConnectSuccess,
    component: SuccessPage,
    title: Messages.pageLCServices,
    isServiceProviderRoute: false,
  },
  {
    path: Links.glfp,
    component: PersonalisedLoginPage,
    title: 'page_title_be_spoke_login' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.retargetDeathRegistration,
    component: NewRegisterPage,
    title: 'page_title_retarget_register' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.retargetLifeRegistration,
    component: NewRegisterPage,
    title: 'page_title_retarget_register' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.microsoftLanding,
    component: MicrosoftLandingPage,
    title: '' as Messages,
    isServiceProviderRoute: false,
  },
  {
    path: Links.lvlogin,
    component: SPLoginPage,
    title: 'page_title_corporate_login' as Messages,
    isServiceProviderRoute: true,
  },
  {
    path: Links.ssoLogin,
    component: SSOLogin,
    title: 'page_title_corporate_login' as Messages,
    isServiceProviderRoute: true,
  },
];

export default routes;
