import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import FileView from '@Components/application/FileView';
import Button, { ButtonSizes, ButtonStyles } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes, NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderNode,
  DeathCaseFragment,
  DeleteDocumentInNotificationMutation,
  DeleteDocumentInNotificationMutationVariables,
  NotificationChatChatType,
  NotificationChatUserType,
  NotificationDocumentNode,
  Permissions,
  RestrictedDeathCaseNode,
  useCaseDocumentsQuery,
  useNotifierIdDocumentsQuery,
  UserDocumentsNode,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteDocumentInNotification } from '@Graphql/providers/mutations';
import { queryCaseDocuments } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { hideModal, showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { deathCaseNotificationHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import ChatMessages from '../ChatMessages';

import styles from './ChatDeskForm.scss';

export interface ChatDeskFormProps {
  caseServiceProvider: CaseServiceProviderNode;
  accessCode: string;
  isCustomer?: boolean;
  deathCase: DeathCaseFragment | RestrictedDeathCaseNode;
  user?: UserNode | null;
  msgParent?: string;
  setMsgCallback?: (comm: string) => void;
}

const ChatDeskForm: React.FunctionComponent<ChatDeskFormProps> = ({
  caseServiceProvider,
  accessCode,
  isCustomer = false,
  deathCase,
  user,
  msgParent,
  setMsgCallback = noop,
}) => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [deleteFileId, setDeleteFileId] = React.useState('');
  const dispatch = useDispatch();
  const t = useTranslations();

  const caseDocuments = useCaseDocumentsQuery({
    variables: {
      caseServiceProvider: caseServiceProvider.id,
      accessCode,
    },
    fetchPolicy: 'network-only',
  });

  const notifierIdDocuments = useNotifierIdDocumentsQuery({
    variables: {
      caseServiceProvider: caseServiceProvider.id,
    },
    fetchPolicy: 'network-only',
    skip: !user?.isStaff,
  });

  const [deleteDocumentInChat] = useMutation<
    DeleteDocumentInNotificationMutation,
    DeleteDocumentInNotificationMutationVariables
  >(mutationDeleteDocumentInNotification, {
    refetchQueries: [getOperationName(queryCaseDocuments) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess('Document deleted'));
      if (isCustomer) {
        deathCaseNotificationHandler(dispatch, caseServiceProvider.id, false, deathCase, true);
      }
      setDeleteFileId('');
      setShowRemoveModal(false);
    },
    onError: (apiError) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
      deathCaseNotificationHandler(dispatch, caseServiceProvider.id, false, deathCase, true);
    },
  });

  if (caseDocuments.loading) {
    return <LoadingSpinner />;
  }

  const caseDocumentsUploaded = [
    ...(deathCase.wills ? deathCase.wills : []),
    ...(deathCase.representationLetters ? deathCase.representationLetters : []),
    ...(deathCase.powerOfAttorneys ? deathCase.powerOfAttorneys : []),
    ...(deathCase.instructionLetters ? deathCase.instructionLetters : []),
    ...(deathCase.letterToCompanys ? deathCase.letterToCompanys : []),
  ];

  const uploadedFiles = caseDocuments.data?.caseDocuments as NotificationDocumentNode[];

  const notifierDocuments = uploadedFiles.filter(
    (doc: NotificationDocumentNode) => !doc.isAddedBySp && doc.uploadedBy === NotificationChatUserType.Individual
  );

  const spDocuments = uploadedFiles.filter((doc: NotificationDocumentNode) => doc.isAddedBySp);

  const lifeLedgerDocuments = uploadedFiles.filter(
    (doc: NotificationDocumentNode) => !doc.isAddedBySp && doc.uploadedBy === NotificationChatUserType.Lifeledger
  );

  const getChatType = () => {
    if (user?.isStaff) {
      return NotificationChatChatType.Lifeledger;
    }
    return NotificationChatChatType.ServiceProvider;
  };

  const deleteConfirmationHandler = (fileId: string) => {
    return dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () =>
              deleteDocumentInChat({
                variables: {
                  input: {
                    id: fileId,
                    accessCode,
                  },
                },
              }),
            secondButtonAction: () => {
              if (isCustomer) {
                deathCaseNotificationHandler(dispatch, caseServiceProvider.id, false, deathCase, true);
              } else {
                dispatch(hideModal({}));
              }
            },
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
            needSecondButtonCallback: true,
          },
          title: t(Messages.modalRemoveDocumentTitle),
          subtitle: t(Messages.modalRemoveDocumentSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const chatMessageLayout = () => {
    if (isMobile) {
      return 12;
    }
    if (isTablet) {
      return 11;
    }
    if (!isCustomer) {
      return 10;
    }
    return 9;
  };

  const isReadOnlyUser =
    deathCase.__typename === NodeDefiner.DeathCase && deathCase?.myPermission === Permissions.ReadOnly;

  const getFileId = (fileDetails: any) => {
    if (isReadOnlyUser) {
      return '';
    }
    return isCustomer ? fileDetails.id : '';
  };

  const readOnlySize = isReadOnlyUser ? 9 : 10;

  const spDocumentSize = () => {
    if (!isCustomer) {
      return 11;
    }
    return readOnlySize;
  };

  const notifierIdDocumentsVisibility =
    user?.isStaff && notifierIdDocuments.data && notifierIdDocuments.data?.notifierIdDocuments.length > 0;

  return (
    <>
      {showRemoveModal && (
        <div className={styles.modalOverlay}>
          <div
            /* eslint-disable-next-line */
            tabIndex={0} id="deleteDiv" />
          <div className={styles.modalContent}>
            <Typography className={styles.bottomSpacing} msg="Remove document" tag="h4" bold size="xl" />
            <Icon
              icon="close-gray"
              onClick={() => setShowRemoveModal(false)}
              className={styles.closeIcon}
              size={IconSizes.sss}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  setShowRemoveModal(false);
                }
              }}
            />
            <Row className={styles.btnsGap}>
              <Button
                label="Remove"
                size={ButtonSizes.fill}
                onClick={() =>
                  deleteDocumentInChat({
                    variables: {
                      input: {
                        id: deleteFileId,
                        accessCode,
                      },
                    },
                  })
                }
              />

              <Button
                label="Do not remove"
                size={ButtonSizes.fill}
                style={ButtonStyles.white}
                onClick={() => setShowRemoveModal(false)}
              />
            </Row>
          </div>
        </div>
      )}

      <Row columnTablet size={12} className={classNames(styles.marginRight16, styles.marginTop33)}>
        <Col constant size={chatMessageLayout()}>
          <ChatMessages
            uploadedFiles={uploadedFiles}
            deathCaseServiceProvider={caseServiceProvider}
            chatType={getChatType()}
            deathCase={deathCase}
            isCustomer={isCustomer}
            user={user}
            msgParent={msgParent}
            setMsgCallback={setMsgCallback}
          />
        </Col>
      </Row>

      <Col size={isTablet ? 12 : readOnlySize}>
        {notifierIdDocumentsVisibility && (
          <>
            <Row className={styles.margintop32}>
              <Typography msg="Notifier ID document" tag="h4" size="xl" />
            </Row>
            {notifierIdDocuments.data?.notifierIdDocuments.map((fileDetails: UserDocumentsNode, idx: number) => (
              <div key={idx} className={styles.mb8}>
                <FileView
                  key={idx}
                  fileDetails={fileDetails}
                  isGray
                  onRemoveClick={() => {
                    setDeleteFileId(fileDetails.id);
                    setShowRemoveModal(true);
                  }}
                  fileId={getFileId(fileDetails)}
                  deleteIconOutSide={!isMobile}
                />
              </div>
            ))}
          </>
        )}
      </Col>

      <Col size={isTablet ? 12 : readOnlySize}>
        {(notifierDocuments.length > 0 || caseDocumentsUploaded.length > 0) && (
          <>
            <Row className={styles.margintop32}>
              <Typography msg={isCustomer ? 'Uploaded by you' : 'Uploaded by notifier'} tag="h4" size="xl" />
            </Row>
            {notifierDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
              <div key={idx} className={styles.mb8}>
                <FileView
                  key={idx}
                  fileDetails={fileDetails}
                  isGray
                  onRemoveClick={() => {
                    setDeleteFileId(fileDetails.id);
                    setShowRemoveModal(true);
                  }}
                  fileId={getFileId(fileDetails)}
                  deleteIconOutSide={!isMobile}
                />
              </div>
            ))}
            {caseDocumentsUploaded.map((fileDetails: any, idx: number) => (
              <div key={idx} className={styles.mb8}>
                <FileView
                  key={idx}
                  fileDetails={fileDetails}
                  isGray
                  onRemoveClick={() => {
                    setDeleteFileId(fileDetails.id);
                    setShowRemoveModal(true);
                  }}
                  fileId={getFileId(fileDetails)}
                  deleteIconOutSide={!isMobile}
                />
              </div>
            ))}
          </>
        )}
      </Col>
      <Col size={isTablet ? 12 : spDocumentSize()}>
        {spDocuments.length > 0 && (
          <>
            <Row className={styles.margintop32}>
              <Typography msg={`Uploaded by ${caseServiceProvider.serviceProvider.name}`} tag="h4" size="xl" />
            </Row>
            {spDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
              <Col size={isCustomer || user?.isStaff ? 11 : 12} key={idx} className={styles.mb8}>
                <FileView
                  key={idx}
                  fileDetails={fileDetails}
                  isGray
                  onRemoveClick={() => {
                    deleteConfirmationHandler(fileDetails.id);
                  }}
                  fileId={isCustomer || user?.isStaff ? '' : fileDetails.id}
                  deleteIconOutSide={!user?.isStaff && !isCustomer}
                />
              </Col>
            ))}
          </>
        )}
      </Col>
      <Col size={isTablet ? 12 : spDocumentSize()}>
        {lifeLedgerDocuments.length > 0 && (
          <>
            <Row className={styles.margintop32}>
              <Typography msg="Uploaded by Life Ledger" tag="h4" size="xl" />
            </Row>
            {lifeLedgerDocuments.map((fileDetails: NotificationDocumentNode, idx: number) => (
              <Col key={idx} className={styles.mb8} size={isCustomer || !user?.isStaff ? 11 : 12}>
                <FileView
                  key={idx}
                  fileDetails={fileDetails}
                  isGray
                  onRemoveClick={() => deleteConfirmationHandler(fileDetails.id)}
                  fileId={isCustomer || !user?.isStaff ? '' : fileDetails.id}
                  deleteIconOutSide
                />
              </Col>
            ))}
          </>
        )}
      </Col>
    </>
  );
};

export default ChatDeskForm;
