import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationDeleteDCSP } from '@Graphql/dc/mutations';
import {
  CaseProviderDeleteMutation,
  CaseProviderDeleteMutationVariables,
  useCaseServiceProviderQuery,
} from '@Graphql/graphqlTypes.generated';
import { queryCaseServiceProvider } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { editProviderHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './ReviewCompanyCard.scss';

export interface ReviewCompanyCardProps {
  onSelection?: (checked: boolean, id: string) => void;
  initialBackground: string;
  caseServiceProviderId: string;
}

const ReviewCompanyCard: React.FunctionComponent<ReviewCompanyCardProps> = ({ caseServiceProviderId }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const caseCompany = useCaseServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: { id: caseServiceProviderId },
  });

  const [deleteDCSPMutation] = useMutation<CaseProviderDeleteMutation, CaseProviderDeleteMutationVariables>(
    mutationDeleteDCSP,
    {
      refetchQueries: [getOperationName(queryCaseServiceProvider) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess(t(Messages.notificationProviderDeleteSuccess)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  if (caseCompany.loading) {
    return <LoadingSpinner />;
  }

  const data = caseCompany.data?.caseServiceProvider;
  if (!data) {
    return null;
  }

  const accountInfoColSize = 3;

  const deleteHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCSPMutation({ variables: { input: { id: data.id } } }),
            secondButtonText: 'Do not remove',
            buttonText: 'Yes, I want to remove',
          },
          title: 'Are you sure that you want to remove this company?',
          subtitle: t(Messages.modalRemoveDCSPSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  return (
    <Row size={12} className={styles.reviewContainer}>
      <Col className={styles.logoContainer}>
        <div className={styles.logoCircle}>
          <img className={styles.companyLogoImg} src={data?.serviceProvider.logo} alt="" />
        </div>
      </Col>
      <Col size={8}>
        <Typography
          className={styles.companyNameSpacing}
          msg={data ? data?.serviceProvider.name : ''}
          tag="div"
          size="m"
          bold
        />

        <Row>
          <Col size={accountInfoColSize}>
            <Typography msg="Unique identifier" tag="div" size="s" color="footerColor" />
          </Col>
          {/* <Col size={1} />
          <Col size={accountInfoColSize}>
            <Typography
              msg={t(`${Messages.fieldProviderPrefix}${ProviderBankFieldLabels.sortCode.toLowerCase()}` as Messages)}
              tag="div"
              size="s"
              color="footerColor"
            />
          </Col> */}
        </Row>
        <Row>
          <Col size={accountInfoColSize}>
            <Typography msg={data?.accountIdentifierPrimary} tag="div" size="xs" bold />
          </Col>
          {/* <Col size={1} />
          <Col size={accountInfoColSize}>
            <Typography msg={data?.accountIdentifierSecondary} tag="div" size="xs" bold />
          </Col> */}
        </Row>
      </Col>
      <div className={styles.reviewActions}>
        <LinkButton
          className={styles.handSymbol}
          linkTitle={t(Messages.buttonEdit)}
          onClick={() => editProviderHandler(t, data.id, dispatch, true, data)}
        />
        <div>
          <Icon onClick={() => deleteHandler()} icon="trash" className={styles.handSymbol} />
        </div>
      </div>
    </Row>
  );
};

export default ReviewCompanyCard;
