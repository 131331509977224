import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import DataWithLabel from '@Components/DataWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { CaseTags, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { CollaboratorNode, DeathCaseNode, DeathCaseStatus, Permissions } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { showEmptyModalHandler } from '@Utils/modal';

import styles from './SummaryLegal.scss';

export interface SummaryLegalProps {
  deathCase: DeathCaseNode;
}

const SummaryLegal: React.FunctionComponent<SummaryLegalProps> = ({ deathCase }) => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();
  const dispatch = useDispatch();

  const showEdit = deathCase.status === DeathCaseStatus.Draft || deathCase.status === DeathCaseStatus.Approved;

  const editForm = () => {
    if (showEdit) {
      history.push(
        generatePath(`${Links.dcEdit}${queryString}`, {
          id: deathCase.id,
          subpage: DeathCaseSubpages.Legal,
          tag: `tag=${CaseTags.deathCaseEdit}`,
        })
      );
    } else {
      showEmptyModalHandler(t(Messages.titleCannotEditCase), t(Messages.descCannotEditCase), dispatch);
    }
  };

  const showEditOption = deathCase?.myPermission !== Permissions.ReadOnly && showEdit;

  return (
    <>
      <ConfirmationHeader showEdit={showEditOption} title="Legal representation" onClick={editForm} />
      <Paper width="width-full" className={styles.deathCaseContainer}>
        {deathCase.legalRepresentatives.map((leg: CollaboratorNode) => (
          <>
            <Typography msg="Solicitor" tag="div" bold size="m" />
            <div className={styles.addressContainer}>
              <Row constant columnMobile>
                <Col size={5}>
                  <DataWithLabel data={`${leg.firstName} ${leg.lastName}`} label="Name" isConfirmationForm />
                </Col>
              </Row>
              <Row constant columnMobile>
                <Col size={5}>
                  <DataWithLabel data={leg.email} label="Email" isConfirmationForm />
                </Col>
                <Col size={1} constant />
                <Col size={5}>
                  <DataWithLabel data={leg.phone} label="Phone number" isConfirmationForm />
                </Col>
              </Row>
              <Row constant columnMobile>
                <Col size={5}>
                  <DataWithLabel
                    data={t(`${Messages.labelPermissionTypePrefix}${leg.permission.toLowerCase()}` as Messages)}
                    label="Access level"
                    isConfirmationForm
                  />
                </Col>
              </Row>
            </div>
          </>
        ))}
      </Paper>
    </>
  );
};

export default SummaryLegal;
