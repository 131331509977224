import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import MobileNavigator from '@Components/application/MobileNavigator';
import UserLayoutNew from '@Components/layout/UserLayoutNew';
import LeftSidebar from '@Components/LeftSidebar';
import Stepper from '@Components/Stepper';
import Typography from '@Components/Typography';
import { EnterKeyCode, Links, ReactResponsiveQueries } from '@Config/constants';
import { RefDeathNode, UserAccountType } from '@Graphql/graphqlTypes.generated';
import {
  DeathCaseSubpages,
  deathCaseSubpagesConfig,
  DeathCaseSubpagesNew,
  DeathCaseSubpagesNewMenu,
  profDeathCaseSubpagesConfig,
  ProfDeathCaseSubpages,
  DeathCaseSubpagesGeneral,
  DeathCaseSubpages2,
} from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { getUser } from '@Store/auth/auth.selector';

import styles from './DeathCasePage.scss';

export interface DeathCasePageProps {
  id?: string;
  preferred?: DeathCaseSubpagesNew | ProfDeathCaseSubpages | DeathCaseSubpagesGeneral;
  missing?: boolean;
  isUserProfessional?: boolean;
}

const DeathCasePage: React.FunctionComponent<DeathCasePageProps> = ({ preferred, id, missing }) => {
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const user = useSelector(getUser);
  const history = useHistory();
  const queryString = history.location.search;
  const isIndividualUser = user?.accountType === UserAccountType.Individual;

  const refDeathCaseData: RefDeathNode = history.location.state as RefDeathNode;

  const chosenIndividualPage =
    preferred && deathCaseSubpagesConfig[preferred as DeathCaseSubpagesGeneral]
      ? deathCaseSubpagesConfig[preferred as DeathCaseSubpagesGeneral]
      : deathCaseSubpagesConfig[DeathCaseSubpagesNew.General];

  const chosenProfessionalPage =
    preferred && profDeathCaseSubpagesConfig[preferred as ProfDeathCaseSubpages]
      ? profDeathCaseSubpagesConfig[preferred as ProfDeathCaseSubpages]
      : profDeathCaseSubpagesConfig[ProfDeathCaseSubpages.General];

  const chosenSubpage = isIndividualUser ? chosenIndividualPage : chosenProfessionalPage;

  const [subpage, setSubpage] = React.useState(chosenSubpage);

  React.useEffect(() => {
    setSubpage(chosenSubpage);
  }, [chosenSubpage, setSubpage]);

  const setSubpageHandler = (page: DeathCaseSubpagesNew | ProfDeathCaseSubpages | DeathCaseSubpagesGeneral) => {
    const newPage = isIndividualUser
      ? deathCaseSubpagesConfig[page as DeathCaseSubpagesGeneral]
      : profDeathCaseSubpagesConfig[page as ProfDeathCaseSubpages];
    history.push(
      generatePath(`${Links.dcEdit}${queryString}`, {
        id,
        subpage: newPage.current,
        missing,
      })
    );
    setSubpage(newPage);
  };

  const RightPanelForm = (subpage as any).component;

  const { stepIndex = -1, totalSteps, back, next } = subpage as any;

  const stepIndexClick = (selectedNumber: number) => {
    let newSubpage = isIndividualUser ? DeathCaseSubpagesNew.General : ProfDeathCaseSubpages.General;
    if (
      subpage.current === DeathCaseSubpagesGeneral.General ||
      subpage.current === DeathCaseSubpagesGeneral.GeneralStep2
    ) {
      newSubpage = selectedNumber === 1 ? DeathCaseSubpagesNew.GeneralStep2 : DeathCaseSubpagesNew.General;
    }

    // if (subpage.current === DeathCaseSubpagesNew.Will || subpage.current === DeathCaseSubpagesNew.Probate) {
    //   newSubpage = selectedNumber === 1 ? DeathCaseSubpagesNew.Probate : DeathCaseSubpagesNew.Will;
    // }

    if (subpage.current === ProfDeathCaseSubpages.Legal || subpage.current === ProfDeathCaseSubpages.Legal2) {
      newSubpage = selectedNumber === 1 ? ProfDeathCaseSubpages.Legal2 : ProfDeathCaseSubpages.Legal;
    }

    return history.push(
      generatePath(`${Links.dcEdit}${queryString}`, {
        id,
        subpage: newSubpage,
        missing,
      })
    );
  };

  const getRightPanel = () => (
    <>
      <Typography msg={subpage.title} tag="h3" size="xxl" />

      {stepIndex > -1 ? (
        <Stepper
          onKeyDown={(e: any, selectedNumber: number) => {
            if (e.keyCode === EnterKeyCode) {
              stepIndexClick(selectedNumber);
            }
          }}
          onClick={stepIndexClick}
          currentIndex={stepIndex}
          total={totalSteps}
        />
      ) : null}

      <RightPanelForm
        refDeathCaseData={refDeathCaseData}
        back={back}
        next={next}
        setSubpage={setSubpageHandler}
        containerClasses={styles.formContainer}
      />
    </>
  );

  // const user = useSelector(getUser);

  const activeStepIdx = Object.values(DeathCaseSubpagesNewMenu).indexOf(subpage.step as DeathCaseSubpagesNewMenu);

  return (
    <UserLayoutNew
      leftSideComponent={
        isMobile ? (
          <MobileNavigator
            activeStepIdx={activeStepIdx}
            steps={Object.values(isIndividualUser ? DeathCaseSubpages2 : DeathCaseSubpages)}
            title=""
            id={id}
            missing={missing}
          />
        ) : (
          <LeftSidebar
            activeStepIdx={activeStepIdx}
            subpages={isIndividualUser ? DeathCaseSubpages2 : DeathCaseSubpages}
            title=""
            id={id}
            missing={missing}
            isDeathCase={true}
          />
        )
      }
      rightSideComponent={getRightPanel()}
      hasStepper
      hasSeparator
    />
  );
};

export default DeathCasePage;
