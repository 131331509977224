import * as React from 'react';

import styles from './EmptyCaseCard.scss';

export interface EmptyCaseProps {}

const EmptyCaseCard: React.FunctionComponent<EmptyCaseProps> = () => {
  return (
    <div className={styles.container}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 72" fill="none" className={styles.svg}>
        <g filter="url(#filter0_f_2929_6716)">
          <path d="M-131 -85H43L-175 183H-349L-131 -85Z" fill="#ccc" />
        </g>
        <defs>
          <filter
            id="filter0_f_2929_6716"
            x="-411"
            y="-147"
            width="516"
            height="392"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="31" result="effect1_foregroundBlur_2929_6716" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default EmptyCaseCard;
