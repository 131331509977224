import { ExecutionResult } from 'apollo-link';

import ApolloClient from '@Graphql/graphqlClient';
import {
  LifeCaseCreateMutation,
  LifeCaseCreateMutationInput,
  LifeCaseCreateMutationVariables,
  LifeCaseUpdateMutation,
  LifeCaseUpdateMutationInput,
  LifeCaseUpdateMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { mutationCreateLC, mutationUpdateLC } from '@Graphql/lc/mutations';

export const createLCMutation = async (input: LifeCaseCreateMutationInput) => {
  const result: ExecutionResult<LifeCaseCreateMutation> = await ApolloClient.mutate<
    LifeCaseCreateMutation,
    LifeCaseCreateMutationVariables
  >({
    mutation: mutationCreateLC,
    variables: {
      input,
    },
  });

  return result.data?.lifeCaseCreate;
};

export const updateLCMutation = async (input: LifeCaseUpdateMutationInput) => {
  const result: ExecutionResult<LifeCaseUpdateMutation> = await ApolloClient.mutate<
    LifeCaseUpdateMutation,
    LifeCaseUpdateMutationVariables
  >({
    mutation: mutationUpdateLC,
    variables: {
      input,
    },
  });

  return result.data?.lifeCaseUpdate;
};
