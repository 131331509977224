import * as React from 'react';

import LoadingSpinner from '@Components/LoadingSpinner';
import {
  RestrictedCaseServiceProviderNode,
  NotificationChatChatType,
  useCaseChatsLlQuery,
} from '@Graphql/graphqlTypes.generated';

import MessageBoxLL from './MessageBoxLL';

export interface MessageBoxLLPageProps {
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
  chatType: NotificationChatChatType;
}

const MessageBoxLLPage: React.FunctionComponent<MessageBoxLLPageProps> = ({ deathCaseServiceProvider, chatType }) => {
  const { data, loading } = useCaseChatsLlQuery({
    variables: {
      caseServiceProvider: deathCaseServiceProvider.id,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <MessageBoxLL
      caseChats={data?.caseChatsLl as any[]}
      deathCaseServiceProvider={deathCaseServiceProvider}
      chatTitlePersonName="Life Ledger"
      chatType={chatType}
    />
  );
};

export default MessageBoxLLPage;
