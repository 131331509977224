import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import Compressor from 'compressorjs';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import LogoUpload from '@Components/application/LogoUpload';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import {
  PortalUpdateSettingsMutation,
  PortalUpdateSettingsMutationVariables,
  UserAccountType,
  useServiceProviderQuery,
} from '@Graphql/graphqlTypes.generated';
import { queryServiceProvider } from '@Graphql/providers/queries';
import { mutationPortalUpdateSettings } from '@Graphql/serviceProvidersPortal/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { logout } from '@Store/auth/auth.actions';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { logoUploadConfirmationHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './ProviderDashboardPage.scss';

export interface ProviderDashboardNavbarProps {}

const ProviderDashboardNavbar: React.FunctionComponent<ProviderDashboardNavbarProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslations();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const user = useSelector(getUser);

  const serviceProviderId = user?.serviceProvider || '';

  const providerInfo = useServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: user?.accountType !== UserAccountType.ServiceProvider,
    variables: {
      id: serviceProviderId,
    },
  });
  const [spUpdateSettings] = useMutation<PortalUpdateSettingsMutation, PortalUpdateSettingsMutationVariables>(
    mutationPortalUpdateSettings,
    {
      refetchQueries: [getOperationName(queryServiceProvider) || ''],
      onCompleted: (response: any) => {
        dispatch(notificationSuccess(t(response.portalUpdateSettings.message)));
        logoUploadConfirmationHandler(dispatch, true, serviceProviderId);
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const logoUploadComponent = (
    <Col className={styles.handSymbol}>
      <div className={styles.uploadOuterCircle}>
        <div className={styles.uploadInnerCircle}>
          <Icon size={IconSizes.s24} className={styles.uploadIcon} icon="download" />
        </div>
      </div>
    </Col>
  );

  const compressAndUpload = (file: File) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: (compressedResult: File) => {
        dispatch(
          spUpdateSettings({
            variables: {
              input: {
                logo: compressedResult,
                id: providerInfo.data?.serviceProvider.id || '',
              },
            },
          })
        );
      },
    });
  };

  const navBar = (
    <nav className={styles.layoutHorizontal}>
      <ul className={styles.elementorNavMenu}>
        <li>
          <span onClick={() => history.push(Links.providerDashboard)} className={styles.itemMenuLink}>
            Dashboard
          </span>
        </li>
        <li>
          <span onClick={() => history.push(Links.serviceProviderReports)} className={styles.itemMenuLink}>
            Reports
          </span>
        </li>
        <li>
          <span
            onClick={() =>
              history.push(
                generatePath(Links.settings, {
                  subpage: SettingsSubpages.Account,
                })
              )
            }
            className={styles.itemMenuLink}
          >
            Settings
          </span>
        </li>
        <li>
          <span onClick={() => dispatch(logout())} className={styles.itemMenuLink}>
            Log out
          </span>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      {providerInfo.data?.serviceProvider.logo ? (
        <Row className={styles.cardBackground} alignCenter constant size={12}>
          <Col className={styles.menuBox}>
            <Row alignCenter>
              <div className={styles.outerCircle}>
                <div className={styles.innerCircle}>
                  <img className={styles.profLogo} src={providerInfo.data?.serviceProvider.logo} alt="" />
                </div>
              </div>

              <Typography
                msg={providerInfo.data?.serviceProvider ? providerInfo.data?.serviceProvider.name : ''}
                tag="h4"
                size="xl"
                bold
              />
            </Row>
          </Col>
          <Col className={styles.menuBox}>{navBar}</Col>
          <Col className={classNames(styles.menuBox, styles.justifyEnd)}>
            <div className={styles.handSymbol} onClick={() => history.push(Links.providerDashboard)}>
              <img
                width={isTablet ? '128' : '173'}
                height="37"
                src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
                alt=""
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row className={styles.cardBackground} alignCenter constant size={12}>
          <Col className={styles.menuBox}>
            <Row alignCenter constant>
              <LogoUpload
                logoUploadComponent={logoUploadComponent}
                onDrop={
                  (files: File[]) => {
                    compressAndUpload(files[0]);
                  }
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
              <Typography
                msg={providerInfo.data?.serviceProvider ? providerInfo.data?.serviceProvider.name : ''}
                tag="h4"
                size="xl"
                bold
              />
            </Row>
          </Col>
          <Col className={styles.menuBox}>{navBar}</Col>
          <Col className={classNames(styles.menuBox, styles.justifyEnd)}>
            <div className={styles.handSymbol} onClick={() => history.push(Links.providerDashboard)}>
              <img
                width={isTablet ? '128' : '173'}
                height="37"
                src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
                alt=""
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProviderDashboardNavbar;
