import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@Components/Button';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryGetUser } from '@Graphql/auth/queries';
import { UpdateUserMutation, UpdateUserMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';

import { ModalProviderProps } from '../Modal';

import styles from './TermsAndConditionsModalBody.scss';

export interface TermsAndConditionsModalBodyProps {
  termsVersion?: string;
}

const TermsAndConditionsModalBody: React.FunctionComponent<ModalProviderProps> = ({
  closeModal,
  modalData: { termsVersion },
}) => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const [updateUserMutation] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    refetchQueries: [getOperationName(queryGetUser) || ''],
    onCompleted: () => {
      closeModal();
    },
  });

  return (
    <div className={styles.container}>
      <Typography msg={t('title_terms_and_conditions' as Messages)} tag="h4" size="xl" bold />
      <Row justifyCenter className={styles.bottomSpacing}>
        <Typography msg="Please take a moment to read our" tag="div" size="l" />
        &nbsp;
        <Link className={styles.termsLink} to={{ pathname: Links.lifeLedger + Links.termsConditions }} target="_blank">
          <Typography msg="terms and conditions" underline tag="div" size="l" />
        </Link>
      </Row>
      <Row justifyCenter size={12}>
        <Col size={9}>
          <Button
            isFullWidth
            label={t('button_agree_changes' as Messages)}
            onClick={() =>
              updateUserMutation({
                variables: {
                  input: { id: user ? user.id : '', termsVersion },
                },
              })
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default TermsAndConditionsModalBody;
