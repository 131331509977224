import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import DashboardPage from '@Routes/dashboard/DashboardPage';

import { RouteInfo } from '../appRoutes';

const routes: RouteInfo[] = [
  {
    path: Links.home,
    component: DashboardPage,
    title: Messages.pageDashboard,
    isServiceProviderRoute: false,
  },
  {
    path: Links.dashboard,
    component: DashboardPage,
    title: Messages.pageDashboard,
    isServiceProviderRoute: false,
  },
];

export default routes;
