import * as React from 'react';

import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import { ServiceProviderNode, UserNode, useServiceProviderQuery } from '@Graphql/graphqlTypes.generated';

import SPInfoNotificationDetails from './SPInfoNotificationDetails';

export interface SpInfoPageProps {
  serviceProviderId: string;
  user?: UserNode | null;
  setSpNotesCallback: (comm: string) => void;
  spNotesParent: string;
}

export enum NotificationFields {
  channel = 'channel',
  number = 'number',
  email = 'email',
  formLink = 'formLink',
  processingTime = 'processingTime',
}

const SpInfoPage: React.FunctionComponent<SpInfoPageProps> = ({
  serviceProviderId,
  user,
  setSpNotesCallback,
  spNotesParent,
}) => {
  const { data, loading } = useServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: serviceProviderId,
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const spInfoData = data?.serviceProvider;

  return (
    <SPInfoNotificationDetails
      serviceProvider={spInfoData as ServiceProviderNode}
      user={user}
      setSpNotesCallback={setSpNotesCallback}
      spNotesParent={spNotesParent}
    />
  );
};

export default SpInfoPage;
