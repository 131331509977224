import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { hideModal } from '@Store/app/app.actions';
import { getModalState } from '@Store/app/app.selector';
import { RootState } from '@Store/reducers';

import Modal from './Modal';

const mapStateToProps = (state: RootState) => ({
  modalData: getModalState(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModal: () => hideModal({}),
    },
    dispatch
  );

// ToDo: add types
export default connect<{}, any, any, RootState>(mapStateToProps, mapDispatchToProps)(Modal);
