import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

// import { Messages } from '@Config/messages';
import ProviderDCInfoForm from '@Routes/provider/ProviderDCInfoPage/ProviderDCInfoForm';
import { professionalAccessCode } from '@Store/provider/provider.actions';

import NotificationsPage from './NotificationsPage';

export interface ProviderDCInfoPageProps {}

const ProviderDCInfoPage: React.FunctionComponent<ProviderDCInfoPageProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessCode = searchParams.get('accesscode') || '';
  const isChat = searchParams.get('chat') === 'true' || false;
  const isStaff = searchParams.get('isstaff') === 'true' || false;
  const [dcInfo, setDcInfo] = useState(null);
  const { msgAccessCode } = useParams();

  React.useEffect(() => {
    if (accessCode !== '') {
      dispatch(professionalAccessCode.started({ setDcInfo, accessCode, isStaff }));
    }
    if (msgAccessCode && msgAccessCode !== '') {
      dispatch(professionalAccessCode.started({ setDcInfo, accessCode: msgAccessCode, isStaff }));
    }
  }, [accessCode, msgAccessCode, isStaff, dispatch]);

  return (
    <>
      {accessCode === '' && msgAccessCode === undefined && <ProviderDCInfoForm isChat={isChat} />}
      {accessCode !== '' && (
        <main>
          <NotificationsPage isChat={isChat} dcInfo={dcInfo} isStaff={isStaff} msgAccessCode={msgAccessCode} />
        </main>
      )}
      {msgAccessCode && msgAccessCode !== '' && (
        <main>
          <NotificationsPage isChat={isChat} dcInfo={dcInfo} isStaff={isStaff} msgAccessCode={msgAccessCode} />
        </main>
      )}
    </>
  );
};

export default ProviderDCInfoPage;
