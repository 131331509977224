import classNames from 'classnames';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon/Icon';
import Col from '@Components/layout/Col/Col';
import Row from '@Components/layout/Row/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Typography from '@Components/Typography/Typography';
import { allStatus, LocalStorage } from '@Config/constants';
import {
  CaseServiceProviderStatus,
  useAllNotificationsForStaffLazyQuery,
  UserNode,
  useStaffUsersQuery,
} from '@Graphql/graphqlTypes.generated';
import { NotificationStatuses } from '@Routes/provider/StaffDashboardPage/StaffDashboardPage';
import { djangoDateFormat, formatDateForExchange, stringToDate } from '@Utils/dates';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';

import StaffAssignee from './StaffAssignee/StaffAssignee';
import styles from './StaffPage.scss';

export interface StaffPageProps {
  statuses: CaseServiceProviderStatus[];
  initialData: any;
  totalCountCallback: (count: number) => void;
  filteredResultCount: number;
  statusCallback: (selectedStatus: CaseServiceProviderStatus[], allStatusSelected: boolean) => void;
  allStatusSelected?: boolean;
}

export interface NotificationColumns {
  deathCaseName: string;
  deathCaseLink: string;
  spName: string;
  spLink: string;
  notifierName: string;
  notifierLink: string;
  notifierType: string;
  dueBy: string;
  djangoDueBy: string;
  filterDueBy: Date;
  status: string;
  sentAt: string;
  djangoSentAt: string;
  filterSentAt: Date;
  resentAt: string;
  djangoResentAt: string;
  filterResentAt: Date;
  isSeen: boolean;
  completedAt: string;
  djangoCompletedAt: string;
  filterCompletedAt: Date;
  accessCode: string;
  ref: string;
  latestComment: string;
  notificationChannel: string;
  assignee: any;
  notificationId: string;
  responseDate: string;
  djangoResponseDate: string;
  filterResponseDate: Date;
  refHyperlink: string;
  staffAssigned: string;
  notifierEmail: string;
}

export interface StaffNotifications {
  assigneeId: string;
  notificationRef: string;
}

const StaffPage: React.FunctionComponent<StaffPageProps> = ({
  initialData,
  statuses,
  totalCountCallback,
  filteredResultCount,
  statusCallback,
  allStatusSelected,
}) => {
  const [globalFilterValue, setGlobalFilterValue] = React.useState('');
  const [filteredValues, setFilteredValues] = React.useState(() => {
    const storedFilteredValues = getFromLocalStorage(LocalStorage.filteredValues);
    if (storedFilteredValues) return JSON.parse(storedFilteredValues);
    return {
      deathCaseLink: [] as string[],
      spLink: [] as string[],
      notifierType: [] as string[],
      notifierChannel: [] as string[],
      assignee: [] as string[],
      status: statuses as string[],
      filterDueBy: [] as any[],
      dueDateType: '',
      filterSentAt: [] as any[],
      sentDateType: '',
      filterResentAt: [] as any[],
      resentDateType: '',
      filterResponseDate: [] as any[],
      responseDateType: '',
      filterCompletedAt: [] as any[],
      completeDateType: '',
    };
  });

  const [filters, setFilters] = React.useState(() => {
    const storedFilteredValues = getFromLocalStorage(LocalStorage.filters);
    if (storedFilteredValues) return JSON.parse(storedFilteredValues);
    return {
      deathCaseName: { value: null, matchMode: FilterMatchMode.IN },
      spName: { value: null, matchMode: FilterMatchMode.IN },
      notifierType: { value: null, matchMode: FilterMatchMode.IN },
      notificationChannel: { value: null, matchMode: FilterMatchMode.IN },
      staffAssigned: { value: null, matchMode: FilterMatchMode.IN },
      status: { value: null, matchMode: FilterMatchMode.IN },
      filterDueBy: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      filterSentAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      filterResentAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      filterResponseDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      filterCompletedAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    };
  });

  const [sortField, setSortField] = React.useState('dueBy');
  const [sortOrder, setSortOrder] = React.useState<1 | -1 | null | undefined>(-1);
  const [cursorId, setCursorId] = React.useState(initialData.data.allNotificationsForStaff.pageInfo.endCursor);
  const [caseData, setCaseData] = React.useState(
    initialData.data?.allNotificationsForStaff.edges.map((x: any) => x.node) || []
  );

  const [getAllNotifications, allNotificationValues] = useAllNotificationsForStaffLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const newNotifications = allNotificationValues.data?.allNotificationsForStaff.edges.map((x: any) => x.node);
      const appendToExisting = caseData.concat(newNotifications);
      setCaseData(appendToExisting);
      if (allNotificationValues.data?.allNotificationsForStaff.pageInfo.hasNextPage) {
        setCursorId(
          allNotificationValues.data ? allNotificationValues.data?.allNotificationsForStaff.pageInfo.endCursor : ''
        );
      } else {
        setCursorId('');
      }
    },
  });

  const [notificationStaff, setNotificationStaff] = React.useState<StaffNotifications[]>([]);

  React.useEffect(() => {
    setToLocalStorage(LocalStorage.filteredValues, JSON.stringify(filteredValues));
    setToLocalStorage(LocalStorage.filters, JSON.stringify(filters));
    if (cursorId !== '') {
      getAllNotifications({
        variables: {
          after: cursorId !== '' ? cursorId : undefined,
          status: allStatusSelected ? [] : statuses,
          first: 100,
        },
      });
    }

    // Get the total height of the document
    // const totalHeight = Math.max(
    //   document.body.scrollHeight,
    //   document.body.offsetHeight,
    //   document.documentElement.clientHeight,
    //   document.documentElement.scrollHeight,
    //   document.documentElement.offsetHeight
    // );

    // // Get the half of the document's height
    // const midHeight = totalHeight / 2;

    // const onScroll = () => {
    //   if (window.scrollY > midHeight) {
    //     const parentElement = document.querySelector('.p-datatable-wrapper') as HTMLElement;
    //     parentElement.style.transform = 'rotateX(0deg)';
    //     const childElement = document.querySelector('.p-datatable-table') as HTMLElement;
    //     childElement.style.transform = 'rotateX(0deg)';
    //   } else {
    //     const parentElement = document.querySelector('.p-datatable-wrapper') as HTMLElement;
    //     parentElement.style.transform = 'rotateX(180deg)';
    //     const childElement = document.querySelector('.p-datatable-table') as HTMLElement;
    //     childElement.style.transform = 'rotateX(180deg)';
    //   }
    // };

    // // clean up code
    // window.removeEventListener('scroll', onScroll);
    // window.addEventListener('scroll', onScroll, { passive: true });
    // return () => window.removeEventListener('scroll', onScroll);
  }, [cursorId, getAllNotifications, statuses, allStatusSelected, filters, filteredValues]);

  const { data, loading } = useStaffUsersQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <LoadingSpinner />;
  }
  const notificationData = caseData.map((node: any) => {
    const professionalCompanyLink = (
      new DOMParser().parseFromString(node.professionalCompanyLink, 'text/html').querySelector('a') || {
        getAttribute: () => '',
      }
    ).getAttribute('href');

    const professionalCompany = (
      new DOMParser().parseFromString(node.professionalCompanyLink, 'text/html').querySelector('a') || {
        textContent: '',
      }
    ).textContent;

    return {
      deathCaseName: node.deceasedFirstname ? `${node.deceasedFirstname} ${node.deceasedSurname}` : '-',
      deathCaseLink: node.baseCaseHyperlink,
      spName: node.serviceProvider ? node.serviceProvider.name : '-',
      spLink: node.serviceProviderHyperlink,
      notifierName: node.notifier
        ? `${node.notifier.firstName} ${node.notifier.lastName} - ${node.notifier.relationship}`
        : '-',
      notifierEmail: node.notifier ? node.notifier.email : '',
      notifierLink: node.notifierHyperlink,
      notifierType: node.notifier ? node.notifier.accountType : '-',
      dueBy: node.dueBy ? node.dueBy : '',
      djangoDueBy: node.dueBy ? djangoDateFormat(node.dueBy) : '',
      filterDueBy: node.dueBy ? stringToDate(formatDateForExchange(node.dueBy)) : null,
      status: node.status ? node.status : '-',
      sentAt: node.sentAt ? node.sentAt : '',
      djangoSentAt: node.sentAt ? djangoDateFormat(node.sentAt) : '',
      filterSentAt: node.sentAt ? stringToDate(formatDateForExchange(node.sentAt)) : null,
      isSeen: node.isSeen ? node.isSeen : false,
      completedAt: node.completedAt ? node.completedAt : '',
      djangoCompletedAt: node.completedAt ? djangoDateFormat(node.completedAt) : '',
      filterCompletedAt: node.completedAt ? stringToDate(formatDateForExchange(node.completedAt)) : null,
      accessCode: node.accessCode ? node.accessCode : '',
      ref: node.ref ? `NO${node.ref}` : '-',
      resentAt: node.resentAt ? node.resentAt : '',
      djangoResentAt: node.resentAt ? djangoDateFormat(node.resentAt) : '',
      filterResentAt: node.resentAt ? stringToDate(formatDateForExchange(node.resentAt)) : null,
      latestComment: node.latestComment ? node.latestComment : '-',
      notificationChannel: node.notificationChannel ? node.notificationChannel : '-',
      assignee: node.assignee,
      notificationId: node.notificationId,
      responseDate: node.responseDate ? node.responseDate : '',
      djangoResponseDate: node.responseDate ? djangoDateFormat(node.responseDate) : '',
      filterResponseDate: node.responseDate ? stringToDate(formatDateForExchange(node.responseDate)) : null,
      refHyperlink: node.refHyperlink,
      staffAssigned: node.assignee ? `${node.assignee.firstName} ${node.assignee.lastName}` : 'Unassigned',
      professionalCompanyLink: node.professionalCompanyLink ? professionalCompanyLink : '',
      professionalCompany: node.professionalCompanyLink ? professionalCompany : '-',
    } as NotificationColumns;
  });

  const filteredData = (): any[] => {
    const searchedData = notificationData?.filter((rowData: NotificationColumns) => {
      return (
        (rowData.ref.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.deathCaseName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.spName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.notifierName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.notifierEmail.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.notifierType.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.djangoDueBy.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.djangoResponseDate.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.status.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.djangoSentAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.djangoResentAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.djangoCompletedAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.accessCode.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.latestComment.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.notificationChannel.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.staffAssigned.toLowerCase().includes(globalFilterValue.toLowerCase())) &&
        (filteredValues.status.length === 0 || filteredValues.status.includes(rowData.status)) &&
        (filteredValues.deathCaseLink.length === 0 || filteredValues.deathCaseLink.includes(rowData.deathCaseName)) &&
        (filteredValues.spLink.length === 0 || filteredValues.spLink.includes(rowData.spName)) &&
        (filteredValues.notifierChannel.length === 0 ||
          filteredValues.notifierChannel.includes(rowData.notificationChannel)) &&
        (filteredValues.notifierType.length === 0 || filteredValues.notifierType.includes(rowData.notifierType)) &&
        (filteredValues.assignee.length === 0 ||
          (rowData.staffAssigned && filteredValues.assignee.includes(rowData.staffAssigned))) &&
        checkDateType(rowData.filterDueBy, filteredValues.filterDueBy[0], filteredValues.dueDateType) &&
        checkDateType(rowData.filterResentAt, filteredValues.filterResentAt[0], filteredValues.resentDateType) &&
        checkDateType(rowData.filterSentAt, filteredValues.filterSentAt[0], filteredValues.sentDateType) &&
        checkDateType(
          rowData.filterResponseDate,
          filteredValues.filterResponseDate[0],
          filteredValues.responseDateType
        ) &&
        checkDateType(rowData.filterCompletedAt, filteredValues.filterCompletedAt[0], filteredValues.completeDateType)
      );
    });

    const newData = [...new Map(searchedData.map((item: any) => [item.ref, item])).values()];
    const intersection = filteredValues.status.filter((element: any) => statuses.includes(element));
    if (
      cursorId === '' &&
      (globalFilterValue !== '' ||
        filteredValues.deathCaseLink.length > 0 ||
        filteredValues.spLink.length > 0 ||
        filteredValues.assignee.length > 0 ||
        filteredValues.notifierType.length > 0 ||
        filteredValues.notifierChannel.length > 0 ||
        filteredValues.filterDueBy.length > 0 ||
        filteredValues.filterSentAt.length > 0 ||
        filteredValues.filterResentAt.length > 0 ||
        filteredValues.filterResponseDate.length > 0 ||
        intersection.length < statuses.length ||
        filteredValues.filterCompletedAt.length > 0)
    ) {
      totalCountCallback(newData.length || 0);
    } else {
      totalCountCallback(0);
    }
    return newData;
  };

  const checkDateType = (date: any, filter: any, dateType: any) => {
    if (!filter || filter.length === 0) return true;
    if (!date) {
      return false;
    }
    const cellDate = new Date(date);
    const filterDate = new Date(filter);
    switch (dateType) {
      case 'dateIs':
        return cellDate.toDateString() === filterDate.toDateString();
      case 'dateIsNot':
        return cellDate.toDateString() !== filterDate.toDateString();
      case 'dateBefore':
        return cellDate < filterDate;
      case 'dateAfter':
        return cellDate > filterDate;
      default:
        return true;
    }
  };
  const deathCaseLinkOptions = Array.from(
    new Set(notificationData.map((rowData: NotificationColumns) => rowData.deathCaseName))
  );
  const deathCaseLinkSortedOptions = deathCaseLinkOptions.sort((dc1: any, dc2: any) => dc1.localeCompare(dc2));

  const spLinkOptions = Array.from(new Set(notificationData.map((rowData: NotificationColumns) => rowData.spName)));
  const spLinkSortedOptions = spLinkOptions.sort((sp1: any, sp2: any) => sp1.localeCompare(sp2));

  const notifierChannelOptions = Array.from(
    new Set(notificationData.map((rowData: NotificationColumns) => rowData.notificationChannel))
  );
  const notifierChannelSortedOptions = notifierChannelOptions.sort((nc1: any, nc2: any) => nc1.localeCompare(nc2));

  const notifierTypeOptions = Array.from(
    new Set(notificationData.map((rowData: NotificationColumns) => rowData.notifierType))
  );
  const notifierTypeSortedOptions = notifierTypeOptions.sort((nt1: any, nt2: any) => nt1.localeCompare(nt2));

  const onGlobalFilterChange = (e: any) => {
    const { value } = e.target;
    setGlobalFilterValue(value);
  };

  const onSort = (event: any) => {
    setSortOrder(event.sortField !== sortField ? -1 : event.sortOrder);
    setSortField(event.sortField);
  };

  const dataForSelect = (queryData: any) => {
    const defaultOption = [
      { label: 'Unassigned', value: '', labelDisplay: <div className={styles.unAssigned}>Unassigned</div> },
    ];
    const staffOptions = queryData?.map((item: UserNode) => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
        labelDisplay: '',
      };
    });
    return defaultOption.concat(staffOptions);
  };

  const staffUsersList = dataForSelect(data?.staffUsers.filter((u: any) => u.firstName !== '' && u.lastName !== ''));
  const staffOptions = Array.from(new Set(staffUsersList?.map((rowData: any) => rowData.label)));
  const staffSortedOptions = staffOptions.sort((staff1: any, staff2: any) => staff1.localeCompare(staff2));

  const renderDeathCaseMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filteredValues.deathCaseLink}
        options={deathCaseLinkSortedOptions}
        onChange={(e) => {
          setFilteredValues({ ...filteredValues, deathCaseLink: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Death Case Name"
        style={{ maxWidth: '14rem' }}
      />
    );
  };

  const renderSPLinkMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filteredValues.spLink}
        options={spLinkSortedOptions}
        onChange={(e) => {
          setFilteredValues({ ...filteredValues, spLink: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select SP Name"
      />
    );
  };

  const renderNotificationChannelMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filteredValues.notifierChannel}
        options={notifierChannelSortedOptions}
        onChange={(e) => {
          setFilteredValues({ ...filteredValues, notifierChannel: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Notification Channel"
      />
    );
  };

  const renderNotificationTypeMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filteredValues.notifierType}
        options={notifierTypeSortedOptions}
        onChange={(e) => {
          setFilteredValues({ ...filteredValues, notifierType: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Notification Type"
      />
    );
  };

  const renderAssigneeMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filteredValues.assignee}
        options={staffSortedOptions}
        onChange={(e) => {
          setFilteredValues({ ...filteredValues, assignee: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Assignee"
      />
    );
  };

  const renderStatusMultiSelect = () => {
    return (
      <MultiSelect
        value={filteredValues.status}
        options={allStatus}
        showClear={false}
        onChange={(e) => {
          const intersection = e.value.filter((element: any) => statuses.includes(element));
          if (e.value.length <= intersection.length) {
            setFilteredValues({ ...filteredValues, status: e.value });
          } else {
            setFilteredValues({ ...filteredValues, status: e.value });
            setToLocalStorage(LocalStorage.filteredValues, JSON.stringify({ ...filteredValues, status: e.value }));
            statusCallback(e.value, e.value.length === allStatus.length);
          }
        }}
        placeholder="Select Status"
        maxSelectedLabels={allStatus.length - 1}
        selectedItemsLabel="ALL"
      />
    );
  };

  const dueByFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={stringToDate(filteredValues.filterDueBy[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterDueBy.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilteredValues({ ...filteredValues, filterDueBy: [e.value], dueDateType: options.filterModel.matchMode });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const sentDateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={stringToDate(filteredValues.filterSentAt[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterSentAt.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilteredValues({
            ...filteredValues,
            filterSentAt: [e.value],
            sentDateType: options.filterModel.matchMode,
          });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const resentDateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={stringToDate(filteredValues.filterResentAt[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterResentAt.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilteredValues({
            ...filteredValues,
            filterResentAt: [e.value],
            resentDateType: options.filterModel.matchMode,
          });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const responseDateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={stringToDate(filteredValues.filterResponseDate[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterResponseDate.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilteredValues({
            ...filteredValues,
            filterResponseDate: [e.value],
            responseDateType: options.filterModel.matchMode,
          });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const completeDateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={stringToDate(filteredValues.filterCompletedAt[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterCompletedAt.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilteredValues({
            ...filteredValues,
            filterCompletedAt: [e.value],
            completeDateType: options.filterModel.matchMode,
          });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const hostName = window.location.hostname === 'app.lifeledger.com' ? 'api.lifeledger.com' : 'dev.api.lifeledger.com';

  const assignStaffUserToNotification = (notificationId: string, staffId: string) => {
    const updateStaffNotification: StaffNotifications[] = [
      ...notificationStaff,
      { assigneeId: staffId, notificationRef: notificationId },
    ];
    setNotificationStaff(updateStaffNotification);
  };

  return (
    <div className={styles.tableContainer}>
      <Row constant size={12}>
        <Col constant size={2} className={classNames(styles.relPosition, { [styles.mb11]: filteredResultCount === 0 })}>
          <input
            type="text"
            onChange={onGlobalFilterChange}
            className={styles.input}
            autoComplete="off"
            placeholder="Search"
            value={globalFilterValue}
            disabled={cursorId !== ''}
          />
          <Icon icon="search" className={styles.visibilityToggleIcon} size={IconSizes.s} />
        </Col>
      </Row>

      {filteredResultCount > 0 && (
        <Row className={styles.mb18}>
          <Typography msg="Filtered results:" tag="div" size="m" color="footerColor" />
          <Typography msg={filteredResultCount.toString()} color="footerColor" tag="div" size="m" />
        </Row>
      )}

      <DataTable
        value={filteredData()}
        id="notificationsTable"
        stripedRows
        paginator
        // scrollHeight="490px"
        emptyMessage="No records found"
        filters={filters}
        // onFilter={handleFilter}
        // removableSort
        // sortMode="multiple"
        // multiSortMeta={[{ field: 'dueBy', order: -1 }]}
        sortField={sortField}
        sortOrder={sortOrder}
        scrollable
        onSort={(e: any) => onSort(e)}
        rows={100}
        className={styles.fonts}
        filterDisplay={cursorId === '' ? 'menu' : undefined}
        //rowsPerPageOptions={[5, 10, 25, 50]}
        resizableColumns
        globalFilterFields={[
          'ref',
          'deathCaseName',
          'spName',
          'notifierName',
          'notifierType',
          'filterDueBy',
          'filterSentAt',
          'filterResentAt',
          'filterCompletedAt',
          'filterResponseDate',
          'responseDate',
          'status',
          'sentAt',
          'resentAt',
          'completedAt',
          'accessCode',
          'latestComment',
          'notificationChannel',
          'professionalCompanyLink',
        ]}
      >
        <Column
          field="ref"
          header="Ref"
          sortable
          frozen
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`https://${hostName}${rowData.refHyperlink}`}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.ref}
              </a>
            );
          }}
        />
        <Column
          field="deathCaseName"
          sortable
          frozen
          header="Death case link"
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`https://${hostName}${rowData.deathCaseLink}`}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.deathCaseName}
              </a>
            );
          }}
          filter
          filterField="deathCaseName"
          filterMenuStyle={{ width: '14rem' }}
          showFilterMenu={true}
          showFilterMatchModes={false}
          showClearButton={true}
          showApplyButton={false}
          filterElement={renderDeathCaseMultiSelect}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, deathCaseLink: [] });
          }}
        />
        <Column
          field="spName"
          sortable
          frozen
          header="SP link"
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`https://${hostName}${rowData.spLink}`}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.spName}
              </a>
            );
          }}
          style={{ minWidth: '15rem' }}
          filter
          filterField="spName"
          showFilterMenu={true}
          showFilterMatchModes={false}
          showClearButton={true}
          showApplyButton={false}
          filterElement={renderSPLinkMultiSelect}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, spLink: [] });
          }}
        />
        <Column
          header="Notification link"
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`${window.location.origin}/notification/${rowData.accessCode}`}
                target="_blank"
                rel="noreferrer"
              >
                Notification page link
              </a>
            );
          }}
        />
        <Column
          field="notifierName"
          sortable
          header="Notifier link"
          body={(rowData: any) => {
            return (
              <>
                {rowData.notifierName !== '-' ? (
                  <a
                    className={styles.linkStyle}
                    href={`https://${hostName}${rowData.notifierLink}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {rowData.notifierName}
                  </a>
                ) : (
                  <div>{rowData.notifierName}</div>
                )}
              </>
            );
          }}
        />
        <Column
          field="professionalCompanyLink"
          header="Professional Company"
          body={(rowData: any) => {
            return (
              <>
                {rowData.professionalCompanyLink !== '' ? (
                  <a
                    className={styles.linkStyle}
                    href={`https://${hostName}${rowData.professionalCompanyLink}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {rowData.professionalCompany}
                  </a>
                ) : (
                  <div>-</div>
                )}
              </>
            );
          }}
        />
        <Column
          field="notifierType"
          sortable
          header="Notifier Type"
          filter
          filterField="notifierType"
          showFilterMenu={true}
          showFilterMatchModes={false}
          showClearButton={true}
          showApplyButton={false}
          filterElement={renderNotificationTypeMultiSelect}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, notifierType: [] });
          }}
        />
        <Column
          field="notificationChannel"
          sortable
          header="Notification Channel"
          style={{ minWidth: '18rem' }}
          filter
          filterField="notificationChannel"
          showFilterMenu={true}
          showFilterMatchModes={false}
          showClearButton={true}
          showApplyButton={false}
          filterElement={renderNotificationChannelMultiSelect}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, notifierChannel: [] });
          }}
        />
        <Column
          header="Assignee"
          field="staffAssigned"
          body={(rowData: any) => {
            return (
              <StaffAssignee
                data={rowData}
                staffUsersList={staffUsersList}
                staffAssigneeCallback={assignStaffUserToNotification}
                assignedList={notificationStaff}
              />
            );
          }}
          style={{ minWidth: '15rem' }}
          filter
          filterField="staffAssigned"
          showFilterMenu={true}
          showFilterMatchModes={false}
          showClearButton={true}
          showApplyButton={false}
          filterElement={renderAssigneeMultiSelect}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, assignee: [] });
          }}
        />
        <Column
          field="dueBy"
          sortable
          header="Due by"
          filterField="filterDueBy"
          dataType="date"
          filter
          filterElement={dueByFilterTemplate}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, filterDueBy: [] });
          }}
          showFilterMenu={true}
          showClearButton={true}
          showApplyButton={false}
          body={(rowData: any) => {
            return rowData.dueBy ? djangoDateFormat(rowData.dueBy) : '-';
          }}
        />
        <Column
          field="responseDate"
          sortable
          header="Response date"
          filterField="filterResponseDate"
          dataType="date"
          filter
          filterElement={responseDateFilterTemplate}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, filterResponseDate: [] });
          }}
          showFilterMenu={true}
          showClearButton={true}
          showApplyButton={false}
          body={(rowData: any) => {
            return rowData.responseDate ? djangoDateFormat(rowData.responseDate) : '-';
          }}
        />
        <Column
          field="status"
          sortable
          header="Status"
          style={{ minWidth: '15rem' }}
          filter
          filterField="status"
          showFilterMenu={true}
          showFilterMatchModes={false}
          showClearButton={true}
          showApplyButton={false}
          filterElement={renderStatusMultiSelect}
          onFilterClear={() => {
            statusCallback(NotificationStatuses, false);
            setFilteredValues({ ...filteredValues, status: NotificationStatuses });
          }}
        />
        <Column
          field="sentAt"
          sortable
          header="Sent at"
          filterField="filterSentAt"
          dataType="date"
          filter
          filterElement={sentDateFilterTemplate}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, filterSentAt: [] });
          }}
          showFilterMenu={true}
          showClearButton={true}
          showApplyButton={false}
          body={(rowData: any) => {
            return rowData.sentAt ? djangoDateFormat(rowData.sentAt) : '-';
          }}
        />
        <Column
          field="resentAt"
          sortable
          header="Resent at"
          filterField="filterResentAt"
          dataType="date"
          filter
          filterElement={resentDateFilterTemplate}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, filterResentAt: [] });
          }}
          showFilterMenu={true}
          showClearButton={true}
          showApplyButton={false}
          body={(rowData: any) => {
            return rowData.resentAt ? djangoDateFormat(rowData.resentAt) : '-';
          }}
        />
        <Column
          field="isSeen"
          sortable
          header="Is Seen"
          body={(rowData: any) => {
            return (
              <Icon
                className={classNames({ [styles.closeIcon]: !rowData.isSeen })}
                icon={rowData.isSeen ? 'tick' : 'close'}
                size={IconSizes.sss}
              />
            );
          }}
        />
        <Column
          field="completedAt"
          sortable
          header="Completed at"
          filterField="filterCompletedAt"
          dataType="date"
          filter
          filterElement={completeDateFilterTemplate}
          onFilterClear={() => {
            setFilteredValues({ ...filteredValues, filterCompletedAt: [] });
          }}
          showFilterMenu={true}
          showClearButton={true}
          showApplyButton={false}
          body={(rowData: any) => {
            return rowData.completedAt ? djangoDateFormat(rowData.completedAt) : '-';
          }}
        />
        <Column field="accessCode" sortable header="Access code" />
        <Column
          field="latestComment"
          sortable
          header="Latest comment"
          body={(rowData: any) => {
            return (
              <div
                //eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: rowData.latestComment,
                }}
              />
            );
          }}
        />
      </DataTable>
    </div>
  );
};

export default StaffPage;
