import * as React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';

import ConfirmationHeader from '@Components/application/ConfirmationHeader';
import Paper from '@Components/application/GrayPaper';
import DataWithLabel from '@Components/DataWithLabel';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { CaseTags, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DeathCaseStatus, LifeCaseNode, Permissions } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { showEmptyModalHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './PartnerSummary.scss';

export interface PartnerSummaryProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  isLifeCase?: boolean;
}

const PartnerSummary: React.FunctionComponent<PartnerSummaryProps> = ({ data, isLifeCase = false }) => {
  const history = useHistory();
  const t = useTranslations();
  const queryString = history.location.search;
  const dispatch = useDispatch();

  const showEdit =
    data.item?.status === DeathCaseStatus.Draft || data.item?.status === DeathCaseStatus.Approved || isLifeCase;

  const editForm = () => {
    if (showEdit) {
      history.push(
        generatePath(isLifeCase ? `${Links.lcEdit}` : `${Links.dcEdit}${queryString}`, {
          id: data.item?.id,
          subpage: DeathCaseSubpages.Partner,
          tag: `tag=${CaseTags.deathCaseEdit}`,
        })
      );
    } else {
      showEmptyModalHandler(t(Messages.titleCannotEditCase), t(Messages.descCannotEditCase), dispatch);
    }
  };

  const showEditOption = data.item?.myPermission !== Permissions.ReadOnly && showEdit;

  return (
    <>
      <ConfirmationHeader showEdit={showEditOption} title="Partner’s information" onClick={editForm} />

      <Paper width="width-full" className={styles.deathCaseContainer}>
        {data.item && (
          <>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={`${data.item?.partner.firstName} ${data.item?.partner.lastName}`}
                  label="Name"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel data={data.item?.partner.email} label="Email" isConfirmationForm dashForEmpty />
              </Col>
            </Row>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel data={data.item?.partner.phone} label="Phone number" isConfirmationForm dashForEmpty />
              </Col>
            </Row>
            <Row constant columnMobile>
              <Col size={12}>
                <DataWithLabel
                  data={data.item?.partner.relationship}
                  label="Collaborator"
                  isConfirmationForm
                  dashForEmpty
                />
              </Col>
            </Row>
          </>
        )}
      </Paper>
    </>
  );
};

export default PartnerSummary;
