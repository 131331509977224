import * as React from 'react';

import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import PasswordChangeForm from '@Routes/settings/PasswordChangePage/PasswordChangeForm';

import styles from '../AccountPage/AccountForm/AccountForm.scss';
import { SettingsSubpages } from '../settingsSubpages';

export interface PasswordChangePageProps {}

const PasswordChangePage: React.FunctionComponent<PasswordChangePageProps> = () => {
  const t = useTranslations();

  return (
    <div className={styles.accountPage}>
      <TitleWithSubtitle
        title={t(`${Messages.titleSettingsSubItemPrefix}${SettingsSubpages.PasswordChange}` as Messages)}
        subtitle={t(`${Messages.subTitleSettingsSubItemPrefix}${SettingsSubpages.PasswordChange}` as Messages)}
        userSection
      />
      <PasswordChangeForm />
    </div>
  );
};

export default PasswordChangePage;
