import { connect } from 'react-redux';

import GeneralForm, {
  GeneralFormProps,
  GeneralFormStateProps,
} from '@Routes/deathCase/DeathCasePage/GeneralFormNew/GeneralForm';
import { getDCData } from '@Store/dc/dc.selector';
import { RootState } from '@Store/reducers';

const mapStateToProps = (state: RootState) => ({
  data: getDCData(state),
});

export default connect<GeneralFormStateProps, {}, GeneralFormProps, RootState>(mapStateToProps)(GeneralForm);
