import GeneralForm from '@Routes/lifeCase/LifeCasePage/GeneralForm/GeneralFormContainer';
import PartnerForm from '@Routes/lifeCase/LifeCasePage/PartnerForm';
import WillForm from '@Routes/lifeCase/LifeCasePage/WillForm';

import AdditionalContactsForm from './AdditionalContactsForm';
import AdditionalInfoForm from './AdditionalInfoForm';
import ConfirmationForm from './ConfirmationForm';
import GeneralFormAddress from './GeneralFormAddress';

export enum LifeCaseSubpages {
  General = 'general',
  Partner = 'partner',
  Will = 'will',
  Docs = 'docs',
  Contacts = 'contacts',
  Confirmation = 'confirmation',
}

export enum LifeCaseSubpagesNew {
  General = 'general',
  GeneralStep2 = 'general-address',
  Partner = 'partner',
  Will = 'will',
  Docs = 'docs',
  Contacts = 'contacts',
  Confirmation = 'confirmation',
}

export enum LifeCaseSubpagesNewMenu {
  General = 'General Info',
  Partner = 'Partner’s info',
  Will = 'Will and Probate',
  Docs = 'Additional info',
  Contacts = 'Additional contacts',
  Confirmation = 'Confirm details',
}

export const lifeCaseSubpagesConfig: {
  [key in LifeCaseSubpagesNew]: {
    title: string;
    stepIndex?: number;
    totalSteps?: number;
    step: LifeCaseSubpagesNewMenu;
    component: React.ReactNode;
    back: LifeCaseSubpagesNew | null;
    next: LifeCaseSubpagesNew | null;
    current: LifeCaseSubpagesNew;
  };
} = {
  [LifeCaseSubpagesNew.General]: {
    title: 'General Information',
    step: LifeCaseSubpagesNewMenu.General,
    stepIndex: 0,
    totalSteps: 2,
    component: GeneralForm,
    back: null,
    next: LifeCaseSubpagesNew.GeneralStep2,
    current: LifeCaseSubpagesNew.General,
  },
  [LifeCaseSubpagesNew.GeneralStep2]: {
    title: 'General Information',
    stepIndex: 1,
    totalSteps: 2,
    step: LifeCaseSubpagesNewMenu.General,
    component: GeneralFormAddress,
    back: LifeCaseSubpagesNew.General,
    next: LifeCaseSubpagesNew.Partner,
    current: LifeCaseSubpagesNew.GeneralStep2,
  },
  [LifeCaseSubpagesNew.Partner]: {
    title: 'Partner’s information',
    step: LifeCaseSubpagesNewMenu.Partner,
    component: PartnerForm,
    back: LifeCaseSubpagesNew.GeneralStep2,
    next: LifeCaseSubpagesNew.Will,
    current: LifeCaseSubpagesNew.Partner,
  },
  [LifeCaseSubpagesNew.Will]: {
    title: 'Will and probate',
    step: LifeCaseSubpagesNewMenu.Will,
    component: WillForm,
    back: LifeCaseSubpagesNew.Partner,
    next: LifeCaseSubpagesNew.Docs,
    current: LifeCaseSubpagesNew.Will,
  },
  [LifeCaseSubpagesNew.Docs]: {
    title: 'Additional info',
    step: LifeCaseSubpagesNewMenu.Docs,
    component: AdditionalInfoForm,
    back: LifeCaseSubpagesNew.Will,
    next: LifeCaseSubpagesNew.Contacts,
    current: LifeCaseSubpagesNew.Docs,
  },
  [LifeCaseSubpagesNew.Contacts]: {
    title: 'Add additional contacts',
    step: LifeCaseSubpagesNewMenu.Contacts,
    component: AdditionalContactsForm,
    back: LifeCaseSubpagesNew.Docs,
    next: LifeCaseSubpagesNew.Confirmation,
    current: LifeCaseSubpagesNew.Contacts,
  },
  [LifeCaseSubpagesNew.Confirmation]: {
    title: 'Confirm details',
    step: LifeCaseSubpagesNewMenu.Confirmation,
    component: ConfirmationForm,
    back: LifeCaseSubpagesNew.Will,
    next: null,
    current: LifeCaseSubpagesNew.Confirmation,
  },
};
