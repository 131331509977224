import classNames from 'classnames';
import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { Links, CaseTags } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useRefDeathsByUserQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';

import { ModalProviderProps } from '../Modal';

import styles from './RegisterDeathCaseConfirmModalBody.scss';

export interface RegisterDeathCaseConfimModalBodyProps {
  onClose?: () => void;
  buttonText?: string;
  secondButtonText?: string;
  leaveOpen?: boolean;
}

const RegisterDeathCaseConfimModalBody: React.FunctionComponent<ModalProviderProps> = ({
  modalData: { buttonText, onClose = noop, leaveOpen = false, secondButtonText },
  closeModal,
}) => {
  const history = useHistory();
  const queryString = history.location.search;
  const t = useTranslations();

  const { data, loading } = useRefDeathsByUserQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const onButtonClick = () => {
    onClose();
    if (!leaveOpen) {
      closeModal();
    }
  };
  const onSecondButtonClick = () => {
    history.push({
      pathname: generatePath(`${Links.dcCreate}${queryString}`, { tag: `tag=${CaseTags.registeredDeathCase}` }),
      state: data?.refDeathsByUser[0],
    });
    closeModal();
  };

  return (
    <>
      {/* <GridRow> */}
      <Typography
        tag="p"
        className={styles.subtitle}
        size="m"
        msg={`${t(Messages.msgNewRegisteredDeathCase)} ${
          data && data?.refDeathsByUser.length > 0 ? data?.refDeathsByUser[0].deceasedLastName : ''
        }`}
      />
      {/* </GridRow> */}
      <div className={classNames(styles.actions)}>
        <GridRow fullWidth withRowGap>
          <GridCol size={6}>
            <Button label={buttonText} size={ButtonSizes.fill} style={ButtonStyles.white} onClick={onButtonClick} />
          </GridCol>
          <GridCol size={6}>
            <Button
              label={secondButtonText}
              size={ButtonSizes.fill}
              style={ButtonStyles.black}
              type={ButtonTypes.button}
              onClick={onSecondButtonClick}
            />
          </GridCol>
        </GridRow>
      </div>
    </>
  );
};

export default RegisterDeathCaseConfimModalBody;
