import { FieldProps, useField, useFormikContext } from 'formik';
import * as React from 'react';

import InputContainer from '@Components/form/inputs/InputContainer';
import ToggleSwitch from '@Components/ToggleSwitch/ToggleSwitch';

export interface ToggleSwitchInputProps {
  setValue: (val: boolean) => void;
  label?: string;
  toggleLabels?: string[];
  disabled?: boolean;
  required?: boolean;
  wrapWhiteSpace?: boolean;
  wrapperClassName?: string;
  containerClassName?: string;
  infoMessage?: string;
  infoTitle?: string;
}

interface ToggleSwitchPrivateProps extends ToggleSwitchInputProps, FieldProps {}

const ToggleSwitchInput: React.FunctionComponent<ToggleSwitchPrivateProps> = (props) => {
  const {
    label,
    disabled,
    form,
    required,
    wrapWhiteSpace,
    wrapperClassName,
    containerClassName,
    toggleLabels,
    infoMessage,
    infoTitle,
  } = props;
  const [field, meta] = useField(props.field);
  const { setFieldValue } = useFormikContext();
  return (
    <InputContainer
      meta={meta}
      form={form}
      required={required}
      wrapWhiteSpace={wrapWhiteSpace}
      wrapperClassName={containerClassName}
    >
      <ToggleSwitch
        label={label}
        disabled={disabled}
        value={field.value}
        setValue={(val) => setFieldValue(field.name, val)}
        wrapperClassName={wrapperClassName}
        toggleLabels={toggleLabels}
        infoMessage={infoMessage}
        infoTitle={infoTitle}
        name={field.name}
      />
    </InputContainer>
  );
};

export default ToggleSwitchInput;
