import classNames from 'classnames';
import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import FileView from '@Components/application/FileView';
import Button, { ButtonStyles } from '@Components/Button';
import { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { fileUploadSettings } from '@Config/constants';
// import { Messages } from '@Config/messages';
import { Messages } from '@Config/messages';
import { NotificationDocumentNode, UserDocumentsNode } from '@Graphql/graphqlTypes.generated';
// import { useTranslations } from '@Hooks/useTranslations';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';

import styles from '../FileInput/FileInput.scss';

export interface FileInputUserProps {
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  smallWrapperMargin?: boolean;
  unsetMinHeight?: boolean;
  onRemoveClick?: (id: string, index?: number) => void;
  disableRemoval?: boolean;
  onDrop: (file: File[]) => void;
  files: Array<UserDocumentsNode | NotificationDocumentNode>;
}

interface FileInputUserPrivateProps extends FileInputUserProps {}

const FileInputUser: React.FunctionComponent<FileInputUserPrivateProps> = (props) => {
  const dispatch = useDispatch();
  const { multiple = true, onDrop, files, onRemoveClick = noop } = props;
  const t = useTranslations();
  const { maxUploadSize, supportedUploadFormats, maxFiles } = fileUploadSettings;
  const disabled = files.length >= maxFiles;
  const isFileInputEmpty = files.length === 0;
  return (
    <>
      <Dropzone
        maxSize={maxUploadSize}
        disabled={disabled}
        onDrop={onDrop}
        multiple={multiple}
        accept={supportedUploadFormats}
        onDropRejected={(fileRejected: FileRejection[]) => {
          if (fileRejected[0].errors[0].code === 'file-too-large') {
            dispatch(notificationError(t(Messages.msgFileSizeLarge)));
          } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
            dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            className={classNames(styles.wrapperBlock, { [styles.disabled]: props.disabled })}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Row justifyCenter constant>
              <Button
                style={ButtonStyles.transparent}
                onClick={() => noop}
                icon="upload"
                iconSize={IconSizes.ss}
                constant
                className={classNames({ [styles.disabled]: props.disabled })}
              >
                <Typography msg={t(Messages.buttonUpload)} tag="span" />
              </Button>
            </Row>
            <Typography size="l" tag="div" msg={t(Messages.labelDragFileIntoBox)} className={styles.topSpacing} />
            <Typography
              size="m"
              color="footerColor"
              tag="div"
              msg={t(Messages.labelAcceptedFileFormats)}
              className={styles.topSpacing}
            />
          </section>
        )}
      </Dropzone>
      {!isFileInputEmpty && (
        <>
          {files.map((fileDetails: UserDocumentsNode | NotificationDocumentNode, idx: number) => (
            <FileView
              key={idx}
              fileDetails={fileDetails}
              isGray
              containerClassName={styles.fileContainer}
              onRemoveClick={() => onRemoveClick(fileDetails.id, idx)}
              fileId={fileDetails.id}
              idx={idx}
            />
          ))}
        </>
      )}
    </>
  );
};

export default FileInputUser;
