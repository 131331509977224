import classNames from 'classnames';
import * as React from 'react';

import Typography from '@Components/Typography';

import styles from './DataWithLabel.scss';

export interface DataWithLabelProps {
  data?: string;
  label: string;
  conditional?: boolean;
  dashForEmpty?: boolean;
  noMargin?: boolean;
  containerClassName?: string;
  isConfirmationForm?: boolean;
  dataArray?: string[];
}

const DataWithLabel: React.FunctionComponent<DataWithLabelProps> = ({
  label,
  data,
  conditional,
  dashForEmpty,
  noMargin,
  containerClassName,
  isConfirmationForm = false,
  dataArray,
}) => {
  if (conditional && !data) {
    return null;
  }
  const noDataDisplay = dashForEmpty ? '-' : '';
  return (
    <div className={classNames(styles.container, containerClassName, { [styles.noMargin]: noMargin })}>
      <Typography color="footerColor" tag="div" msg={label} size="s" bold={!isConfirmationForm} />
      {dataArray &&
        dataArray.map((item: string, idx: number) => (
          <Typography key={idx} tag="div" msg={item || noDataDisplay} size="l" />
        ))}

      {(dataArray === undefined || dataArray.length === 0) && (
        <Typography tag="div" msg={data || noDataDisplay} size="l" />
      )}
    </div>
  );
};

export default DataWithLabel;
