import * as React from 'react';
import { useSelector } from 'react-redux';

import Icon from '@Components/Icon';
import NavigationLayout from '@Components/layout/NavigationLayout/NavigationLayout';
import Row from '@Components/layout/Row';
import { NavigationConfig, SubpageConfig } from '@Components/NavigationBlock/NavigationBlock';
import Stepper from '@Components/Stepper';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import Typography from '@Components/Typography';
import { LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { CaseServiceProviderNode, UserVerificationStatus } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { getUser } from '@Store/auth/auth.selector';
import { getBooleanValueCookie } from '@Utils/localStorage';

import SupportingDocuments from '../SupportingDocuments/SupportingDocuments';
import TransUnionProcedure from '../TransUnionProcedure';
import TransUnionSummary from '../TransUnionSummary/TransUnionSummary';
import VerificationStatus from '../VerificationStatus';

import styles from './TransUnionSteps.scss';
import { TransUnionSubpages } from './transUnionSubPages';

export interface TransUnionStepsProps {
  preferred?: TransUnionSubpages;
  fromNotification?: boolean;
  deathCaseServiceProviderId?: string;
  submitAction?: string;
  deathCaseServiceProvider?: CaseServiceProviderNode;
}

const RightPanelForms: SubpageConfig<TransUnionSubpages> = {
  [TransUnionSubpages.DecideComponent]: TransUnionProcedure,
  [TransUnionSubpages.Summary]: TransUnionSummary,
  [TransUnionSubpages.SupportingDocuments]: SupportingDocuments,
  [TransUnionSubpages.VerficationStatus]: VerificationStatus,
};

const TransUnionSteps: React.FunctionComponent<TransUnionStepsProps> = ({
  preferred,
  fromNotification,
  deathCaseServiceProviderId,
  submitAction,
  deathCaseServiceProvider,
}) => {
  const user = useSelector(getUser);
  const t = useTranslations();

  const tuCreationNavigation: NavigationConfig<TransUnionSubpages> = {
    [TransUnionSubpages.DecideComponent]: {
      back: null,
      next: TransUnionSubpages.Summary,
    },
    [TransUnionSubpages.Summary]: {
      back: TransUnionSubpages.DecideComponent,
      next: TransUnionSubpages.SupportingDocuments,
    },
    [TransUnionSubpages.SupportingDocuments]: {
      back: TransUnionSubpages.Summary,
      next: TransUnionSubpages.VerficationStatus,
    },
    [TransUnionSubpages.VerficationStatus]: { back: TransUnionSubpages.SupportingDocuments, next: null },
  };

  function chooseSubPage(subPagePreferred: string) {
    let subPageName = TransUnionSubpages.SupportingDocuments;
    switch (subPagePreferred) {
      case TransUnionSubpages.DecideComponent:
        subPageName = TransUnionSubpages.DecideComponent;
        break;
      case TransUnionSubpages.Summary:
        subPageName = TransUnionSubpages.Summary;
        break;
      case TransUnionSubpages.SupportingDocuments:
        subPageName = TransUnionSubpages.SupportingDocuments;
        break;
      case TransUnionSubpages.VerficationStatus:
        subPageName = TransUnionSubpages.VerficationStatus;
        break;
      default:
        break;
    }
    return subPageName;
  }

  const defaultSubPage = () => {
    const firstFail = getBooleanValueCookie(LocalStorage.idVerificationFirstFail);
    if (
      user?.verificationStatus === UserVerificationStatus.Verified ||
      (user?.verificationStatus === UserVerificationStatus.NeedsReview && !firstFail)
    ) {
      return TransUnionSubpages.VerficationStatus;
    }
    return TransUnionSubpages.DecideComponent;
  };

  const chosenSubpage =
    preferred && Object.values(TransUnionSubpages).includes(chooseSubPage(preferred)) ? preferred : defaultSubPage();

  const [subpage, setSubpage] = React.useState(chosenSubpage);

  const stepIndex = Object.values(TransUnionSubpages).indexOf(subpage);

  React.useEffect(() => {
    setSubpage(chosenSubpage);
  }, [chosenSubpage, setSubpage]);

  const RightPanelForm = RightPanelForms[subpage as keyof SubpageConfig<TransUnionSubpages>] as React.ComponentClass<
    any
  >;

  // const stepIndexClick = (selectedNumber: number) => {
  //   let newSubPage = TransUnionSubpages.DecideComponent;

  //   if (selectedNumber === 0) {
  //     newSubPage = TransUnionSubpages.DecideComponent;
  //   } else if (selectedNumber === 1) {
  //     newSubPage = TransUnionSubpages.Summary;
  //   } else if (selectedNumber === 2) {
  //     newSubPage = TransUnionSubpages.SupportingDocuments;
  //   } else {
  //     newSubPage = TransUnionSubpages.VerficationStatus;
  //   }
  //   setSubpage(newSubPage);
  // };

  const showBackButton = () => {
    if (
      fromNotification &&
      (subpage === TransUnionSubpages.Summary || subpage === TransUnionSubpages.SupportingDocuments)
    ) {
      return true;
    }
    return false;
  };

  const backButtonPage = tuCreationNavigation[subpage as keyof SubpageConfig<TransUnionSubpages>].back;

  const successMessage = deathCaseServiceProvider?.serviceProvider.isUserValidationRequired
    ? t('label_id_validation_successful' as Messages)
    : t('label_id_doc_upload_success' as Messages);

  const getRightPanel = () => (
    <div className={styles.navLayout}>
      {showBackButton() && backButtonPage && (
        <Row constant onClick={() => setSubpage(backButtonPage)} alignCenter className={styles.handSymbol}>
          <Icon className={styles.backButtonSpacing} icon="chevronLeft" />
          <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
        </Row>
      )}
      {fromNotification && subpage !== TransUnionSubpages.VerficationStatus && (
        <Typography
          msg={t(`${Messages.transUnionHeaderPrefix}${subpage.toLowerCase()}` as Messages)}
          tag="h4"
          size="xl"
          bold
        />
      )}

      <div className={!fromNotification ? styles.headerContainer : ''}>
        {!fromNotification && (
          <TitleWithSubtitle
            title={
              user?.verificationStatus === UserVerificationStatus.Verified
                ? successMessage
                : t(`${Messages.titleSettingsSubItemPrefix}${SettingsSubpages.IDValidation}` as Messages)
            }
            subtitle={
              user?.verificationStatus === UserVerificationStatus.Verified
                ? t('label_send_notifications' as Messages)
                : t(`${Messages.subTitleSettingsSubItemPrefix}${SettingsSubpages.IDValidation}` as Messages)
            }
            userSection
          />
        )}

        {subpage !== TransUnionSubpages.VerficationStatus && (
          <Stepper
            // onKeyDown={(e: any, selectedNumber: number) => {
            //   if (e.keyCode === EnterKeyCode) {
            //     stepIndexClick(selectedNumber);
            //   }
            // }}
            // onClick={stepIndexClick}
            currentIndex={stepIndex}
            total={3}
          />
        )}
      </div>
      <div className={fromNotification ? styles.rightPanelNotification : styles.rightPanelDiv}>
        <RightPanelForm
          back={backButtonPage}
          next={tuCreationNavigation[subpage as keyof SubpageConfig<TransUnionSubpages>].next}
          setSubpage={setSubpage}
          fromNotification={fromNotification}
          deathCaseServiceProviderId={deathCaseServiceProviderId}
          submitAction={submitAction}
          deathCaseServiceProvider={deathCaseServiceProvider}
        />
      </div>
    </div>
  );

  return <NavigationLayout fromNotification={fromNotification} rightSideComponent={getRightPanel()} />;
};

export default TransUnionSteps;
