import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { Timeouts } from '@Config/constants';
import { Messages } from '@Config/messages';
import { getYotiQuery } from '@Graphql/auth/auth.queries';
import { UserNodeDetails, UserVerificationStatus } from '@Graphql/graphqlTypes.generated';
import { getCurrentTranslations } from '@Store/app/app.selector';
import { startYotiPolling, stopYotiPolling, yotiPolling } from '@Store/auth/auth.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

export function* yotiPollingSaga() {
  let reviewedYotiState = true;
  let i = Timeouts.yotiPollingPeriod;
  while (reviewedYotiState) {
    try {
      const result: UserNodeDetails = yield call(getYotiQuery);
      yield put(yotiPolling.done({ result, params: {} }));
      reviewedYotiState = result.info.verificationStatus !== UserVerificationStatus.Verified;
      if (result.info.verificationStatus === UserVerificationStatus.Verified) {
        const translations = yield select(getCurrentTranslations);
        const successKey = Messages.notificationYotiVerified;
        yield put(notificationSuccess(normaliseGqlError(translations[successKey] || successKey)));
      }

      yield delay(i * 1000);
      i *= Timeouts.yotiPollingPeriod;
    } catch (err) {
      yield put(notificationError(normaliseGqlError(err.message)));
      reviewedYotiState = false;
    }
  }
  yield put(stopYotiPolling());
}

export function* yotiPollTaskWatcher() {
  while (true) {
    yield take(startYotiPolling);
    yield race([call(yotiPollingSaga), take(stopYotiPolling)]);
  }
}
