import { useMutation } from '@apollo/react-hooks';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { emptySelectorValue, Links, placeholderSelectorValue } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserAccountType,
  UserTitle,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError, titleOptions } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateAny, validateRequired, validateSelector } from '@Utils/validator';

import styles from './UserTitleForm.scss';

export interface UserTitleFormProps {}

enum UserTitleFormFields {
  title = 'title',
  titleOther = 'titleOther',
  accountType = 'accountType',
}

const UserTitleForm: React.FunctionComponent<UserTitleFormProps> = () => {
  const t = useTranslations();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;

  const accTypeOptions = Object.values(UserAccountType)
    .filter((a: any) => a !== 'SERVICE_PROVIDER')
    .map((acc: any) => ({
      label: t(`option_account_type_${acc.toLowerCase()}` as Messages),
      value: acc,
    }));

  const [updateUser, { loading }] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: () => {
      dispatch(notificationSuccess('Your title added'));
      history.push(`${Links.home}${queryString}`);
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  return (
    <Formik
      initialValues={{
        [UserTitleFormFields.title]: placeholderSelectorValue('Select title'),
        [UserTitleFormFields.titleOther]: '',
        [UserTitleFormFields.accountType]: emptySelectorValue,
      }}
      validationSchema={() => {
        return Yup.lazy((values: any) => {
          return Yup.object({
            [UserTitleFormFields.title]: validateSelector(t),
            [UserTitleFormFields.titleOther]:
              values.title.value === UserTitle.Other ? validateRequired(t) : validateAny(),
            [UserTitleFormFields.accountType]:
              user?.accountType !== null ? Yup.mixed().notRequired() : validateSelector(t),
          });
        });
      }}
      onSubmit={(values) => {
        const selectedTitle = Object.values(UserTitle).find((respect: any) => respect === values.title.value);
        const selectedAccountType = Object.values(UserAccountType).find(
          (respect: any) => respect === values.accountType.value
        );
        //const titleOther = values;
        updateUser({
          variables: {
            input: {
              id: user ? user?.id : '',
              title: selectedTitle,
              accountType: selectedAccountType,
              //  titleOther: titleOther === '' ? undefined : titleOther,
            },
          },
        });
      }}
    >
      {({ status, values }) => (
        <Form>
          <div className={styles.container}>
            <Typography msg={t('label_welcome_title' as Messages)} tag="h3" size="xxl" />
            <Row size={11} className={styles.bottomSpacing}>
              <Typography msg={t('subtitle_following_details' as Messages)} tag="div" size="l" />
            </Row>
            <Row>
              <Field
                name={UserTitleFormFields.title}
                component={SelectInput}
                label="Your title"
                options={titleOptions}
                className={styles.titleSelect}
                placeholder="Select title"
                size="xl"
                required={true}
              />
            </Row>
            {values.title?.value === UserTitle.Other && (
              <Row className={styles.spacing}>
                <Col size={12}>
                  <Field
                    name={UserTitleFormFields.titleOther}
                    type="text"
                    component={TextInput}
                    label={t('field_title_other' as Messages)}
                  />
                </Col>
              </Row>
            )}

            {user?.accountType === null && (
              <Row>
                <Field
                  name={UserTitleFormFields.accountType}
                  component={SelectInput}
                  label={t(Messages.labelRegisterType)}
                  options={accTypeOptions}
                  className={styles.titleSelect}
                  placeholder="Account type"
                  size="xl"
                  required={true}
                />
              </Row>
            )}
            <FormError formError={status} />
            <Row className={styles.bigTopSpacing}>
              <Col size={7}>
                <Button
                  type={ButtonTypes.submit}
                  size={ButtonSizes.fill}
                  label="Continue"
                  isFullWidth
                  loading={loading}
                  disabled={values.title.value === ''}
                />
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserTitleForm;
