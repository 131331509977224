import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import MobileMenu from '@Components/application/MobileMenu';
import SideMenu from '@Components/application/SideMenu';
import { MenuItems } from '@Components/application/SideMenu/SideMenu';
import Col from '@Components/layout/Col';
// import { Steps } from '@Components/TourSelect';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { AllLCStatuses, LocalStorage, PreArchivedDCStatuses, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useDeathCasesQuery, useLifeCasesQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import layoutStyles from '@Routes/dashboard/DashboardPage/DashboardPage.scss';
import { getUser } from '@Store/auth/auth.selector';
import { deleteFromLocalStorage } from '@Utils/localStorage';

import ActivityForm from '../ActivityForm';

import styles from './ActivityPage.scss';

export interface ActivityPageProps {}

const ActivityPage: React.FunctionComponent<ActivityPageProps> = () => {
  const t = useTranslations();
  const user = useSelector(getUser);
  deleteFromLocalStorage(LocalStorage.deathCaseId);
  deleteFromLocalStorage(LocalStorage.lifeCaseId);

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const [mobileShowSideMenu, setMobileShowSideMenu] = React.useState(false);

  const dcData = useDeathCasesQuery({
    variables: {
      status: PreArchivedDCStatuses,
    },
    fetchPolicy: 'cache-and-network',
    skip: user?.isFirstLogin,
  });

  const lcData = useLifeCasesQuery({
    variables: {
      status: AllLCStatuses,
    },
    fetchPolicy: 'cache-and-network',
    skip: user?.isFirstLogin,
  });

  if (dcData.loading || lcData.loading) {
    return (
      <Col size={8} textCenter alignCenter>
        <LoadingSpinner />
      </Col>
    );
  }

  const dcCount = dcData.data ? dcData.data.deathCases.totalCount : 0;

  const lcCount = lcData.data ? lcData.data.lifeCases.totalCount : 0;

  const casesCount = dcCount + lcCount;

  const onHamburgerClick = () => {
    setMobileShowSideMenu(!mobileShowSideMenu);
  };

  return (
    <main className={layoutStyles.layoutContainer}>
      <div>
        {isMobile && !mobileShowSideMenu ? (
          <MobileMenu onHamburgerClick={onHamburgerClick} showHamBurger={!!(casesCount > 0 && user?.title)} />
        ) : (
          <SideMenu closeClick={() => setMobileShowSideMenu(!mobileShowSideMenu)} page={MenuItems.Activity} />
        )}
      </div>
      {!mobileShowSideMenu && (
        <div className={layoutStyles.rightSideContainer}>
          <Col size={isTablet ? 11 : 10}>
            <Typography msg={t(Messages.pageActivity)} tag="h3" size="xxl" />
            <Typography
              msg={t(Messages.subtitlePageActivity)}
              tag="div"
              size="l"
              className={styles.bottomSpacing}
              color="footerColor"
            />
            <ActivityForm />
          </Col>
        </div>
      )}
    </main>
  );
};

export default ActivityPage;
