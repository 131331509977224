import classNames from 'classnames';
import * as React from 'react';

import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './LandingNewsLetters.scss';

export interface LandingNewsLettersProps {
  containerClassName?: string;
  isRetargetedPage?: boolean;
}

const LandingNewsLetters: React.FunctionComponent<LandingNewsLettersProps> = () => {
  const t = useTranslations();

  return (
    <div className={styles.pt80}>
      <Row justifyCenter>
        <Typography
          msg={t('label_as_seen_in_bold' as Messages)}
          family="Montserrat"
          className={styles.letterFonts}
          tag="div"
          html
        />
      </Row>

      <Row alignCenter justifyCenter size={12} wrap className={classNames(styles.logosContainer)}>
        <Icon icon="which" />
        <div className={classNames(styles.border, styles.displayNone)} />
        <Icon icon="goodhousekeeping" />
        <div className={classNames(styles.border, styles.displayNone)} />
        <Icon icon="dailymail" />
        <div className={classNames(styles.border, styles.displayNone)} />
        <Icon icon="gazette" />
        <div className={classNames(styles.border, styles.displayNone)} />
        <Icon icon="financial-times" />
      </Row>
    </div>
  );
};

export default LandingNewsLetters;
