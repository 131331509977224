import * as React from 'react';

import { CaseServiceProviderStatus } from '@Graphql/graphqlTypes.generated';
import CaseSummaryContainer, {
  NotificationStatusCounts,
} from '@Routes/provider/DeathCasesPage/DeathCasesForm/CaseSummary/CaseSummaryContainer';
import { NotificationStatusTabs } from '@Routes/provider/ProviderDashboardPage/ProviderDashboardPage';

export interface DeathCasesFormProps {
  tabSelected: NotificationStatusTabs;
  setLatestCount: (counts: NotificationStatusCounts) => void;
  spId?: string;
}

const DeathCasesForm: React.FunctionComponent<DeathCasesFormProps> = ({ tabSelected, setLatestCount, spId }) => {
  const passStatus = () => {
    if (tabSelected === NotificationStatusTabs.TabUnassigned) {
      return [CaseServiceProviderStatus.Sent];
    }
    if (tabSelected === NotificationStatusTabs.TabInProgress) {
      return [
        CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier,
        CaseServiceProviderStatus.InProgressAwaitingInformationFromSp,
        CaseServiceProviderStatus.InProgress,
      ];
    }
    return [
      CaseServiceProviderStatus.Closed,
      CaseServiceProviderStatus.NotFound,
      CaseServiceProviderStatus.Confirmed,
      CaseServiceProviderStatus.CompletedByLl,
      CaseServiceProviderStatus.CompletedClosed,
      CaseServiceProviderStatus.NoActionRequired,
      CaseServiceProviderStatus.CompletedSwitched,
      CaseServiceProviderStatus.AlreadyRegistered,
    ];
  };

  return <CaseSummaryContainer spId={spId} setLatestCount={setLatestCount} status={passStatus()} />;
};

export default DeathCasesForm;
