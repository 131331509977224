import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import SelectOnly, { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { CaseTags, EnterKeyCode, Links, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationDeleteDC } from '@Graphql/dc/mutations';
import { queryDCList } from '@Graphql/dc/queries';
import {
  CollaboratorPermission,
  CollaboratorRelationshipFe,
  DeathCaseDeleteMutation,
  DeathCaseDeleteMutationVariables,
  DeathCaseListCardFragment,
  DeathCaseStatus,
  ProfessionalPortalUserNode,
  useProfessionalPortalUsersQuery,
  UserAccountType,
  Permissions,
  useAgentPortalUsersQuery,
  AgentPortalUserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpagesNew } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { addCollaborator } from '@Store/collaborator/collaborator.actions';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { chooseIcon, modifyMissingItems } from '@Utils/helpers';
import { moreDCHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './CaseInfo.scss';

export interface CaseInfoProps {
  data: DeathCaseListCardFragment;
  initialBackground: string;
  caseNumber?: number;
}

const CaseInfo: React.FunctionComponent<CaseInfoProps> = ({ data, initialBackground, caseNumber }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();
  const t = useTranslations();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });
  const isRes1118 = useMediaQuery({ query: ReactResponsiveQueries.Res1118 });
  const isRes875 = useMediaQuery({ query: ReactResponsiveQueries.Res875 });
  const isRes1400Min = useMediaQuery({ query: ReactResponsiveQueries.Res1400Min });
  const isRes1387Max = useMediaQuery({ query: ReactResponsiveQueries.Res1387Max });

  const isProfessionalUser = user?.accountType === UserAccountType.Professional;
  const isAgentUser = user?.accountType === UserAccountType.Agent;

  const profUsers = useProfessionalPortalUsersQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isProfessionalUser,
  });

  const agentUsers = useAgentPortalUsersQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isAgentUser,
  });

  const [deleteDCMutation] = useMutation<DeathCaseDeleteMutation, DeathCaseDeleteMutationVariables>(mutationDeleteDC, {
    refetchQueries: [getOperationName(queryDCList) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.notificationDCDeleteSuccess)));
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const history = useHistory();
  const queryString = history.location.search;

  const viewLinkClickHandler = () => {
    return history.push(generatePath(`${Links.dcReady}${queryString}`, { id: data.id }));
  };

  const chooseSubpage = (field: any) => {
    if (field.includes('name') || field.includes('date')) {
      return DeathCaseSubpagesNew.General;
    }
    if (field.includes('address') || field.includes('city') || field.includes('post')) {
      return DeathCaseSubpagesNew.GeneralStep2;
    }
    if (field.includes('has_death_certificate_files')) {
      return DeathCaseSubpagesNew.Certificate;
    }
    return DeathCaseSubpagesNew.General;
  };

  const editLinkClickHandler = (e: any) => {
    e.stopPropagation();
    const firstMissingItem = data.missingInfo.filter((x: any) => x.value === false);

    return history.push(
      generatePath(`${Links.dcEdit}${queryString}`, {
        id: data.id,
        subpage: firstMissingItem.length > 0 ? chooseSubpage(firstMissingItem[0].field) : DeathCaseSubpagesNew.General,
        tag: `tag=${CaseTags.deathCaseEdit}`,
      })
    );
  };

  const deleteHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => deleteDCMutation({ variables: { input: { id: data.id } } }),
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
          },
          title: t(Messages.modalRemoveDCTitle),
          subtitle: t(
            data.status === DeathCaseStatus.Active
              ? Messages.modalRemoveActiveDCSubtitle
              : Messages.modalRemoveDCSubtitle
          ),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const dataForSelect = (queryData: any) =>
    queryData?.map((item: ProfessionalPortalUserNode | AgentPortalUserNode) => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
    });

  const colloboratorDetail = (queryData: any) =>
    queryData?.map((item: ProfessionalPortalUserNode | AgentPortalUserNode) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        id: item.id,
      };
    });

  const colloborators = colloboratorDetail(
    isAgentUser ? agentUsers.data?.agentPortalUsers : profUsers.data?.professionalPortalUsers
  );

  const usersList = dataForSelect(
    isAgentUser ? agentUsers.data?.agentPortalUsers : profUsers.data?.professionalPortalUsers
  );

  const isReadOnlyUser = data.myPermission === Permissions.ReadOnly;

  let selectedOption =
    data.portalUser && usersList ? usersList.find((userDetail: any) => userDetail.value === data.portalUser.id) : null;

  const handleUserChange = (item: any) => {
    selectedOption = item;
    const colloborator = colloborators.find((c: any) => c.id === item.value);
    dispatch(
      addCollaborator.started({
        baseCase: data.id,
        email: colloborator.email,
        firstName: colloborator.firstName,
        lastName: colloborator.lastName,
        relationship: CollaboratorRelationshipFe.Professional,
        permission: CollaboratorPermission.FullAccess,
        shouldNotify: true,
        isProfessionalPortal: true,
      })
    );
  };

  const hideDeleteIcon = () => {
    if (isMobile || data.status === DeathCaseStatus.Active || data.status === DeathCaseStatus.Completed) {
      return true;
    }
    return data.myPermission === null || (data.myPermission && data.myPermission === Permissions.ReadOnly);
  };

  const manipulateMissedOnes = modifyMissingItems(data.missingInfo.filter((x: any) => x.value === false));

  const missingItemRes = isProfessionalUser && isRes1387Max ? 2 : 1;

  const missingItems =
    data.status === DeathCaseStatus.Draft ? manipulateMissedOnes.slice(0, missingItemRes) : manipulateMissedOnes;

  const columnSize = () => {
    if (isProfessionalUser) {
      const portalAdminSize = isTablet ? 10 : 8;
      const nonPortalAdminSize = isTablet ? 10 : 9;
      return user?.isPortalAdmin ? portalAdminSize : nonPortalAdminSize;
    }
    return 11;
  };

  const leftDraftCase = data.status === DeathCaseStatus.Draft ? 11 : 6;
  const rightDraftCase = data.status === DeathCaseStatus.Draft ? 1 : 6;

  const leftInfoSize = isTablet ? leftDraftCase : 7;
  const right1400Size = isRes1400Min ? 5 : 4;
  const rightInfoSize = isTablet ? rightDraftCase : right1400Size;

  const isActiveAndCompleted = () => {
    return data.status !== DeathCaseStatus.Active && data.status !== DeathCaseStatus.Completed;
  };

  const mainSection = (
    <Col size={isProfessionalUser ? 7 : leftInfoSize} className={styles.nameDetails}>
      <Row column>
        <Col className={styles.splitNameAndInfoPanel}>
          <Typography msg={`${data.firstName} ${data.lastName}`} tag="span" size="m" bold />
          {data.status === DeathCaseStatus.Draft && !isMobileBetweenTablet && (
            <span className={styles.missingInfoPanel}>
              <Icon icon="alert-triangle" className={styles.alertTriangle} size={IconSizes.ss} />

              <Typography msg="Information missing" tag="span" size="s" bold color="red" className={styles.sent} />
            </span>
          )}
        </Col>

        {!isRes875 && data.status === DeathCaseStatus.Draft && missingItems.length > 0 && (
          <>
            <Col className={styles.notificationTitle}>
              <Typography msg="To do" tag="div" size="s" color="footerColor" />
            </Col>
            <Col>
              <Row size={12}>
                {missingItems.map((item: any, idx: number) => (
                  <Col key={idx} textCenter className={styles.missingInfoItemsSpacing}>
                    <Icon className={styles.missingItemsIcon} icon={chooseIcon(item)} size={IconSizes.ss} />
                    <Typography
                      className={classNames(styles.missingInfoText, {
                        [styles.profMissingInfoWrapper]: isProfessionalUser,
                      })}
                      msg={t(`${Messages.missingItemPrefix}${item.toLowerCase()}` as Messages)}
                      tag="span"
                      bold
                      size="s"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </>
        )}

        {data.status !== DeathCaseStatus.Draft && (
          <Col className={styles.notificationTitle}>
            <Typography msg="Notifications" tag="div" size="s" color="footerColor" />
          </Col>
        )}

        {data.status !== DeathCaseStatus.Draft && (
          <Col>
            <Row className={styles.noWrap} alignCenter constant>
              <Col alignCenter>
                <Icon className={styles.moreIcon} icon="more-horizontal" />

                <Typography
                  msg={isRes1118 ? data.notificationsReady.toString() : `${data.notificationsReady} Ready to send`}
                  tag="div"
                  size="s"
                  color="footerColor"
                  bold
                  className={styles.readyToSend}
                />
              </Col>

              <Col className={styles.notificationStatusCount}>
                <Icon className={styles.moreIcon} icon="clock" size={IconSizes.ss} />
                <Typography
                  msg={isRes1118 ? data.notificationsSent.toString() : `${data.notificationsSent} Sent`}
                  tag="div"
                  size="s"
                  color="yellowishOrange"
                  bold
                  className={styles.sent}
                />
              </Col>

              <Col className={styles.notificationStatusCount}>
                <Icon className={styles.moreIcon} icon="doneAll" size={IconSizes.ss} />
                <Typography
                  msg={isRes1118 ? `${data.notificationsConfirmed}` : `${data.notificationsConfirmed} Completed`}
                  tag="div"
                  size="s"
                  color="successColor"
                  bold
                  className={styles.sent}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  );

  const viewNotificationsVisibility = () => {
    if (data.status !== DeathCaseStatus.Draft) {
      if (isIpadMini || isRes1400Min) {
        return false;
      }
      return true;
    }
    if (isReadOnlyUser) {
      if (isIpadMini || isRes1400Min) {
        return false;
      }
      return true;
    }
    return false;
  };

  const completeMissingInfoVisibility = () => {
    if (data.status === DeathCaseStatus.Draft) {
      if (isMobile || isRes1400Min) {
        return false;
      }

      return !isReadOnlyUser;
    }
    return false;
  };

  const mobileViewNotificationLink = () => {
    if (isMobile) {
      return !isProfessionalUser;
    }
    return isRes1400Min;
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: '1px solid #C4C4C4 !important',
      borderRadius: '4px',
      paddingLeft: '8px !important',
      paddingRight: '6px !important',
    }),
  };

  const showAssignee = isProfessionalUser;

  return (
    <Row constant={!isMobile} id={`caseCard_${caseNumber}`} size={12}>
      <Col
        tabindex={0}
        constant={!isMobile}
        size={columnSize()}
        className={classNames(
          data.status === DeathCaseStatus.Draft ? styles.missingContainer : styles.activeContainer,
          {
            [styles.profCardHeight]: data.status === DeathCaseStatus.Draft && isProfessionalUser,
            [styles.cardHeight]: data.status === DeathCaseStatus.Draft && !isProfessionalUser,
          }
        )}
        onClick={() => viewLinkClickHandler()}
        onKeyDown={(e: any) => {
          if (e.keyCode === EnterKeyCode) {
            viewLinkClickHandler();
          }
        }}
      >
        <Row constant={!isMobile} size={12}>
          <div className={styles.combined}>
            <div style={{ backgroundColor: initialBackground }} className={styles.personContainer}>
              <Typography
                msg={`${data.firstName.charAt(0).toUpperCase()}${data.lastName.charAt(0).toUpperCase()}`}
                tag="span"
                color="white"
                bold
                size="lcx"
              />
            </div>
            {isMobile ? mainSection : null}
          </div>
          {isMobile ? null : mainSection}

          <Col
            size={isProfessionalUser ? 6 : rightInfoSize}
            textRight
            className={classNames({ [styles.res1400Right]: isRes1400Min })}
          >
            {!isTablet && (
              <Row justifyEnd>
                <Typography
                  msg="Date of death"
                  tag="span"
                  size="s"
                  color="footerColor"
                  className={classNames(styles.deathDateSpacing, styles.noWrap)}
                />

                <Typography msg={languageBasedDate(data.deathDate, currentLanguage)} tag="span" size="s" />
              </Row>
            )}
            {(data.status !== DeathCaseStatus.Draft || isReadOnlyUser) && (
              <Row
                constant={isMobile}
                id="viewDC"
                className={classNames(
                  styles.handSymbol,
                  { [styles.actionLinks]: !isMobile },
                  isRes1400Min ? styles.p22 : styles.p15
                )}
                justifyEnd={!isMobile}
                justifyBetween={isMobile}
              >
                {isProfessionalUser && isMobile && (
                  <Col
                    textLeft
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <SelectOnly
                      size="l"
                      selectClass={ClassNamesForOverwrite.SelectContainment}
                      options={usersList}
                      value={selectedOption}
                      onChange={handleUserChange}
                      placeholder={Messages.fieldUnassignedPlaceholder}
                    />
                  </Col>
                )}

                {mobileViewNotificationLink() && (
                  <span className={styles.mobileViewNotification}>
                    <Col>
                      <Icon icon="radio-button-on" className={styles.viewNotificationIcon} />
                    </Col>
                    <Col onClick={() => viewLinkClickHandler()}>
                      <Typography
                        msg="View notifications"
                        tag="span"
                        size="mb"
                        bold
                        className={styles.deathDateSpacing}
                      />
                    </Col>
                  </span>
                )}

                {!isProfessionalUser && isMobile && isActiveAndCompleted() && !isReadOnlyUser && (
                  <div
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        deleteHandler(e);
                      }
                    }}
                    className={styles.mobileDelete}
                    onClick={(e: any) => deleteHandler(e)}
                  >
                    <Icon icon="trash" className={styles.handSymbol} />
                  </div>
                )}

                {isProfessionalUser && isMobile && (
                  <Row
                    constant={isMobile}
                    size={12}
                    className={styles.moreLink}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      moreDCHandler(dispatch, data);
                    }}
                    justifyEnd
                  >
                    <Icon icon="more-horizontal" className={styles.moreHoriz} />
                    <Typography msg="More" tag="span" size="mb" bold className={styles.moreEllipsis} />
                  </Row>
                )}
              </Row>
            )}
            {data.status === DeathCaseStatus.Draft && isMobile && !isReadOnlyUser && (
              <Row
                constant={isMobile}
                className={classNames(styles.handSymbol, styles.actionLinks)}
                onClick={(e: any) => editLinkClickHandler(e)}
                justifyEnd={!isMobile}
                tabIndex={0}
              >
                <Col>
                  <Icon icon="edit" className={styles.viewNotificationIcon} />
                </Col>
                <Col>
                  <Typography
                    msg="Complete missing info"
                    tag="span"
                    size="mb"
                    bold
                    className={styles.deathDateSpacing}
                  />
                </Col>

                <div
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      deleteHandler(e);
                    }
                  }}
                  className={styles.missingInfoDelete}
                  onClick={(e: any) => deleteHandler(e)}
                >
                  <Icon icon="trash" className={styles.handSymbol} />
                </div>
              </Row>
            )}
            {data.status === DeathCaseStatus.Draft && isRes1400Min && !isReadOnlyUser && (
              <Row
                onClick={editLinkClickHandler}
                tabIndex={0}
                onKeyDown={(e: any) => {
                  if (e.keyCode === EnterKeyCode) {
                    editLinkClickHandler(e);
                  }
                }}
                className={styles.gap4}
                justifyEnd
                alignCenter
              >
                <Icon icon="edit" />
                <Typography msg="Complete missing info" tag="div" size="mb" bold />
              </Row>
            )}
          </Col>
        </Row>

        {(isMobileBetweenTablet && data.status !== DeathCaseStatus.Draft) ||
          (isMobileBetweenTablet && data.status === DeathCaseStatus.Draft && isReadOnlyUser && (
            <Row justifyEnd constant>
              <span className={styles.mobileViewNotification}>
                <Col tabindex={0}>
                  <Icon icon="radio-button-on" className={styles.viewNotificationIcon} />
                </Col>
                <Col onClick={viewLinkClickHandler}>
                  <Typography msg="View notifications" tag="span" size="mb" bold className={styles.deathDateSpacing} />
                </Col>
              </span>
            </Row>
          ))}
      </Col>
      {viewNotificationsVisibility() && (
        <span
          className={isProfessionalUser ? styles.profViewNotificationHover : styles.viewNotificationHover}
          //eslint-disable-next-line
          tabIndex={0}
          onClick={viewLinkClickHandler}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              viewLinkClickHandler();
            }
          }}
        >
          <Col>
            <Icon icon="radio-button-on" className={styles.viewNotificationIcon} />
          </Col>
          <Col>
            <Typography msg="View notifications" tag="span" size="mb" bold className={styles.deathDateSpacing} />
          </Col>
        </span>
      )}

      {completeMissingInfoVisibility() && (
        <Row
          tabIndex={0}
          constant
          className={classNames(
            styles.handSymbol,
            styles.completeMissingInfonHover,
            isProfessionalUser ? styles.profViewRight : styles.completeMissingInfoRight
          )}
          onClick={(e: any) => editLinkClickHandler(e)}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              editLinkClickHandler(e);
            }
          }}
        >
          <Col>
            <Icon icon="edit" className={styles.viewNotificationIcon} />
          </Col>
          <Col>
            <Typography msg="Complete missing info" tag="span" size="mb" bold />
          </Col>
        </Row>
      )}
      <Col constant={!isMobile} size={1} alignCenter textCenter>
        {!hideDeleteIcon() && (
          <div
            onKeyDown={(e: any) => {
              if (e.keyCode === EnterKeyCode) {
                deleteHandler(e);
              }
            }}
            //eslint-disable-next-line
            tabIndex={0}
            onClick={(e: any) => deleteHandler(e)}
          >
            <Icon icon="trash" className={styles.handSymbol} />
          </div>
        )}
      </Col>
      {showAssignee && user?.isPortalAdmin && data.status !== DeathCaseStatus.Draft && !isMobile && (
        <Col id="assignUser" textCenter alignCenter size={isTablet ? 3 : 2} tabindex={0}>
          <SelectOnly
            size="ss"
            selectClass={ClassNamesForOverwrite.SelectContainment}
            options={usersList}
            value={selectedOption}
            onChange={handleUserChange}
            placeholder={Messages.fieldUnassignedPlaceholder}
            customStyles={customStyles}
          />
        </Col>
      )}
    </Row>
  );
};

export default CaseInfo;
