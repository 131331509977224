import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useParams } from 'react-router';

import UserLayoutNew from '@Components/layout/UserLayoutNew';
import LeftSidebar from '@Components/LeftSidebar';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { RefDeathNode } from '@Graphql/graphqlTypes.generated';

import { CompanyProfileSubpages, CompanyProfileSubpagesMenu, CompanyProfileSubpagesConfig } from '../craStepsSubPages';

export interface CompanyProfileProps {}

const CompanyProfile: React.FunctionComponent<CompanyProfileProps> = () => {
  const { id, page } = useParams();
  const preferred = page;
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const history = useHistory();
  const queryString = history.location.search;

  const refDeathCaseData: RefDeathNode = history.location.state as RefDeathNode;

  const chosenSubpage =
    preferred && CompanyProfileSubpagesConfig[preferred as CompanyProfileSubpages]
      ? CompanyProfileSubpagesConfig[preferred as CompanyProfileSubpages]
      : CompanyProfileSubpagesConfig[CompanyProfileSubpages.SelectCompany];

  const [subpage, setSubpage] = React.useState(chosenSubpage);

  React.useEffect(() => {
    setSubpage(chosenSubpage);
  }, [chosenSubpage, setSubpage]);

  const setSubpageHandler = (paged: CompanyProfileSubpages) => {
    const newPage = CompanyProfileSubpagesConfig[paged as CompanyProfileSubpages];
    history.push(
      generatePath(`${Links.craCompanies}${queryString}`, {
        id,
        subpage: newPage.current,
      })
    );
    setSubpage(newPage);
  };

  const RightPanelForm = (subpage as any).component;
  const { back, next } = subpage as any;

  const getRightPanel = () => (
    <RightPanelForm refDeathCaseData={refDeathCaseData} back={back} next={next} setSubpage={setSubpageHandler} />
  );

  // const user = useSelector(getUser);

  const activeStepIdx = Object.values(CompanyProfileSubpagesMenu).indexOf(subpage.step as CompanyProfileSubpagesMenu);

  return (
    <UserLayoutNew
      isCra={true}
      companiesPage={true}
      leftSideComponent={
        !isMobile && (
          <LeftSidebar isCRA={true} activeStepIdx={activeStepIdx} subpages={CompanyProfileSubpages} title="" id={id} />
        )
      }
      rightSideComponent={getRightPanel()}
      hasStepper
      hasSeparator
    />
  );
};

export default CompanyProfile;
