import * as React from 'react';

import LoadingSpinner from '@Components/LoadingSpinner';
import {
  RestrictedCaseServiceProviderNode,
  NotificationChatChatType,
  useCaseChatsQuery,
} from '@Graphql/graphqlTypes.generated';

import MessageBox from './MessageBox';

export interface MessageBoxPageProps {
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
  chatTitlePersonName: string;
  chatType: NotificationChatChatType;
  accessCode: string;
}

const MessageBoxPage: React.FunctionComponent<MessageBoxPageProps> = ({
  deathCaseServiceProvider,
  chatTitlePersonName,
  chatType,
  accessCode,
}) => {
  const { data, loading } = useCaseChatsQuery({
    variables: {
      caseServiceProvider: deathCaseServiceProvider.id,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <MessageBox
      caseChats={data?.caseChats as any[]}
      deathCaseServiceProvider={deathCaseServiceProvider}
      chatTitlePersonName={chatTitlePersonName}
      chatType={chatType}
      accessCode={accessCode}
    />
  );
};

export default MessageBoxPage;
