export enum NodeEnvEnum {
  development = 'development',
  production = 'production',
  test = 'test',
}

const config: any = {
  NODE_ENV: (process.env.NODE_ENV as NodeEnvEnum) || NodeEnvEnum.development,
  API_BASE_URL: process.env.API_BASE_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
};

export default config;
