import { Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import CreateDeathCase from '@Routes/deathCase/CreateDeathCase';
import EditDeathCase from '@Routes/deathCase/EditDeathCase';
import ReadyDCPage from '@Routes/deathCase/ReadyDCPage';

import { RouteInfo } from '../appRoutes';

const routes: RouteInfo[] = [
  {
    path: Links.dcCreate,
    component: CreateDeathCase,
    title: Messages.pageCreateDeathCase,
    isServiceProviderRoute: false,
  },
  {
    path: Links.dcEdit,
    component: EditDeathCase,
    title: Messages.pageCreateDeathCase,
    isServiceProviderRoute: false,
  },
  {
    path: Links.dcReady,
    component: ReadyDCPage,
    title: Messages.pageDCServices,
    isServiceProviderRoute: false,
  },
];

export default routes;
