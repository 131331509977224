import classNames from 'classnames';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Typography from '@Components/Typography';
import { noop } from '@Utils/helpers';

import styles from './Popover.scss';

export enum PopoverPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export interface PopoverProps {
  position: PopoverPosition;
  className?: string;
  showContent: boolean;
  contentElement?: JSX.Element;
  title?: string;
  message?: string;
  callBack?: () => void;
}

const Popover: React.FunctionComponent<PopoverProps> = ({
  children,
  className,
  position,
  contentElement,
  showContent,
  callBack = noop,
  title,
  message,
}) => {
  const clickRef = React.useRef<null | HTMLDivElement>(null);
  React.useEffect(() => {
    const handleClick = (e: any) => {
      if (clickRef.current && !clickRef.current.contains(e.target)) {
        callBack();
      }
    };

    const windowEvents = () => {
      window.addEventListener('mousedown', handleClick);
      return () => {
        window.removeEventListener('mousedown', handleClick);
      };
    };
    windowEvents();
  }, [callBack]);
  return (
    <div ref={clickRef} className={styles.popoverContainer}>
      {children}
      {showContent && contentElement && (
        <div className={classNames(styles.popover, className, styles[position])}>{contentElement}</div>
      )}
      {showContent && title && (
        <div className={classNames(styles.tooltip, className, styles[position], styles.text)}>
          <Typography tag="div" size="s" bold msg={title} color="white" />
          {message && <p className={styles.tooltipContent}>{ReactHtmlParser(message)}</p>}
        </div>
      )}
    </div>
  );
};

export default Popover;
