import { useState } from 'react';
import * as React from 'react';

import AuthPageLayout from '@Components/layout/AuthPageLayout';
import { Messages } from '@Config/messages';
import SuggestionBlock from '@Routes/auth/shared/SuggestionBlock';

import { AuthSuggestions } from '../shared/SuggestionBlock/SuggestionBlock';

import ResetPasswordForm from './ResetPasswordForm';

export interface ResetPasswordPageProps {}

const ResetPasswordPage: React.FunctionComponent<ResetPasswordPageProps> = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);

  return (
    <AuthPageLayout
      titleKey={Messages.resetPasswordFormTitle}
      isSmallLayout={true}
      leftBottomComponent={<SuggestionBlock authType={AuthSuggestions.forgotPassword} suggestLogin={false} />}
    >
      <ResetPasswordForm passwordChanged={passwordChanged} setPasswordChanged={setPasswordChanged} />
    </AuthPageLayout>
  );
};

export default ResetPasswordPage;
