import * as React from 'react';

import AutomatedReportsForm from '../AutomatedReportsForm';

export interface AutomatedReportsFormProps {}

const AutomatedReportsPage: React.FunctionComponent<AutomatedReportsFormProps> = () => {
  return <AutomatedReportsForm />;
};

export default AutomatedReportsPage;
