import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useMediaQuery } from 'react-responsive';

import AuthPageLayout from '@Components/layout/AuthPageLayout';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';

import RegisterForm from './RegisterForm';

export interface RegisterPageProps {
  emailSentTo: string;
  setEmailSentTo: Dispatch<SetStateAction<string>>;
}

const RegisterPage: React.FunctionComponent<RegisterPageProps> = () => {
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  return (
    <AuthPageLayout
      titleKey={Messages.registerFormTitle}
      isRegisterForm={true}
      isSmallLayout={!isTablet}
      mobileRegisterFormHeight="20rem"
    >
      <RegisterForm />
    </AuthPageLayout>
  );
};

export default RegisterPage;
