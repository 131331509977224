import { Field, Form, Formik, useFormikContext } from 'formik';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { AddressPortal, EnterKeyCode, Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { BaseCasePropertyType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { currentDateTime, normalizeDate } from '@Utils/dates';
import { addressHelper } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';
import { validateMinLength, validatePostalCodeCharacters } from '@Utils/validator';

import { Address, FullAddress } from '../../../../model/Address';
import { LifeEventSubPages } from '../LifeEventSubpages';

import styles from './ChangeAddressForm.scss';

export interface GenerealAddressProps extends NavigationProps {
  missing: boolean;
}

export enum ChangeAddressFormFields {
  fromAddress1 = 'fromAddress1',
  fromAddress2 = 'fromAddress2',
  fromCity = 'fromCity',
  fromPostalCode = 'fromPostalCode',
  fromAutoAddress = 'fromAutoAddress',
  fromChooseAddress = 'fromChooseAddress',
  fromBuildingName = 'fromBuildingName',
  fromBuildingNumber = 'fromBuildingNumber',
  toAddress1 = 'address1',
  toAddress2 = 'address2',
  toCity = 'city',
  toPostalCode = 'postalCode',
  toAutoAddress = 'autoAddress',
  toChooseAddress = 'chooseAddress',
  toBuildingName = 'buildingName',
  toBuildingNumber = 'buildingNumber',
  propertyType = 'propertyType',
  dateOfMove = 'dateOfMove',
}

const ChangeAddressForm: React.FunctionComponent<GenerealAddressProps> = ({
  back,
  next,
  setSubpage,
  missing = true,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const history = useHistory();
  const queryString = history.location.search;

  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });

  const [manualAddress, setManualAddress] = React.useState(false);
  const [toManualAddress, setToManualAddress] = React.useState(false);

  const [fromPostalCode, setFromPostalCode] = React.useState('');
  const [toPostalCode, setToPostalCode] = React.useState('');

  const [searching, setSearching] = React.useState(false);
  const [toSearching, setToSearching] = React.useState(false);

  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);
  const [toAddressResult, settoAddressResult] = React.useState<FullAddress[]>([]);

  const [assignedAddress, setAssignedAddress] = React.useState(-1);
  const [toAssignedAddress, setToAssignedAddress] = React.useState(-2);

  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [showToClearAddress, setShowToClearAddress] = React.useState(false);

  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);
  const [toPostCodeTabOut, setToPostCodeTabOut] = React.useState(false);

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const resultToAddressOptions =
    toAddressResult &&
    toAddressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const baseCaseProperties = Object.values(BaseCasePropertyType).map((baseCaseProps) => ({
    label: t(`${Messages.labelBaseCasePropertyPrefix}${baseCaseProps.toLowerCase()}` as Messages),
    value: baseCaseProps,
  }));

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
    setManualAddress(true);
  };

  const handleToAddressChange = (item: any) => {
    setToAssignedAddress(item.value);
    setToManualAddress(true);
  };

  const getAddress = (to: boolean) => {
    const paramPostalCode = to ? toPostalCode : fromPostalCode;
    if (paramPostalCode !== '') {
      if (to) {
        setToSearching(true);
      } else {
        setSearching(true);
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', paramPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apidata) => {
          if (apidata.Message) {
            dispatch(notificationError(apidata.Message));
            if (to) {
              settoAddressResult([]);
              setToSearching(false);
            } else {
              setAddressResult([]);
              setSearching(false);
            }
          } else {
            const outputData = new Address(apidata);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError('Address not found for this postal code'));
            }
            if (to) {
              settoAddressResult(outputData.addresses);
              setToSearching(false);
            } else {
              setAddressResult(outputData.addresses);
              setSearching(false);
            }
          }
        });
    }
  };

  const AutoToggleForm = () => {
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
      const searchedAddress = addressResult.find((addr: FullAddress) => addr.id === assignedAddress);
      if (searchedAddress) {
        setFieldValue(ChangeAddressFormFields.fromBuildingName, addressHelper(searchedAddress.buildingName));
        setFieldValue(ChangeAddressFormFields.fromBuildingNumber, addressHelper(searchedAddress.buildingNumber));
        setFieldValue(ChangeAddressFormFields.fromAddress1, addressHelper(searchedAddress.address1));
        setFieldValue(ChangeAddressFormFields.fromAddress2, addressHelper(searchedAddress.address2));
        setFieldValue(ChangeAddressFormFields.fromCity, addressHelper(searchedAddress.city));
        setFieldValue(ChangeAddressFormFields.fromPostalCode, addressHelper(searchedAddress.postalCode));
      }

      const toSearchedAddress = toAddressResult.find((addr: FullAddress) => addr.id === toAssignedAddress);
      if (toSearchedAddress) {
        setFieldValue(ChangeAddressFormFields.toBuildingName, addressHelper(toSearchedAddress.buildingName));
        setFieldValue(ChangeAddressFormFields.toBuildingNumber, addressHelper(toSearchedAddress.buildingNumber));
        setFieldValue(ChangeAddressFormFields.toAddress1, addressHelper(toSearchedAddress.address1));
        setFieldValue(ChangeAddressFormFields.toAddress2, addressHelper(toSearchedAddress.address2));
        setFieldValue(ChangeAddressFormFields.toCity, addressHelper(toSearchedAddress.city));
        setFieldValue(ChangeAddressFormFields.toPostalCode, addressHelper(toSearchedAddress.postalCode));
      }
    }, [setFieldValue]);

    return null;
  };

  return (
    <>
      <Formik
        initialValues={{
          [ChangeAddressFormFields.fromAddress1]: '',
          [ChangeAddressFormFields.fromAddress2]: '',
          [ChangeAddressFormFields.fromCity]: '',
          [ChangeAddressFormFields.fromPostalCode]: '',
          [ChangeAddressFormFields.propertyType]: '',
          [ChangeAddressFormFields.dateOfMove]: '',
          [ChangeAddressFormFields.toAddress1]: '',
          [ChangeAddressFormFields.toAddress2]: '',
          [ChangeAddressFormFields.toCity]: '',
          [ChangeAddressFormFields.toPostalCode]: '',
          [ChangeAddressFormFields.toBuildingName]: '',
          [ChangeAddressFormFields.toBuildingNumber]: '',
          [ChangeAddressFormFields.fromBuildingName]: '',
          [ChangeAddressFormFields.fromBuildingNumber]: '',
        }}
        onSubmit={() => {
          history.push(
            generatePath(`${Links.lifeEventEdit}${queryString}`, {
              subpage: LifeEventSubPages.ProofOfAddress,
              missing,
            })
          );
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.mt56}>
              <div className={styles.mt40}>
                <Typography tag="div" size="l" msg={t('title_address_change_from' as Messages)} />
                <Row className={styles.addressContainer} size={12}>
                  <Col size={isTablet ? 12 : 7}>
                    <input
                      name={ChangeAddressFormFields.fromAutoAddress}
                      type="text"
                      value={fromPostalCode}
                      className={styles.input}
                      autoComplete="off"
                      onChange={(e: any) => {
                        setFromPostalCode(e.currentTarget.value);
                        setShowClearAddress(true);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode && validatePostalCodeCharacters(fromPostalCode)) {
                          e.preventDefault();
                          getAddress(false);
                        }
                      }}
                      onBlur={() => {
                        if (validateMinLength(fromPostalCode) && resultAddressOptions.length === 0) {
                          setPostCodeTabOut(true);
                        } else {
                          setPostCodeTabOut(false);
                        }
                      }}
                      placeholder={t('msg_search_by_postalcode' as Messages)}
                    />
                    {showClearAddress && (
                      <Icon
                        icon="close-outline"
                        className={styles.visibilityToggleIcon}
                        onClick={() => {
                          setShowClearAddress(false);
                          setFromPostalCode('');
                          setAddressResult([]);
                          setAssignedAddress(-1);
                          setPostCodeTabOut(false);
                        }}
                        size={IconSizes.s}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            setShowClearAddress(false);
                            setFromPostalCode('');
                            setAddressResult([]);
                            setAssignedAddress(-1);
                            setPostCodeTabOut(false);
                          }
                        }}
                      />
                    )}
                  </Col>
                  <Col size={isTablet ? 12 : 5} className={classNames(styles.searchButtonContainer)}>
                    <Popover
                      showContent={postCodeTabOut}
                      position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                      title={t('title_postcode_warning' as Messages)}
                      message={t('desc_postcode_warning' as Messages)}
                    >
                      <Button
                        type={ButtonTypes.button}
                        style={ButtonStyles.transparent}
                        loading={searching}
                        label={t(Messages.buttonSearch)}
                        icon="search"
                        iconSize={IconSizes.sxs}
                        onClick={() => {
                          setPostCodeTabOut(false);
                          getAddress(false);
                        }}
                        isFullWidth={true}
                        constant
                        disabled={!validatePostalCodeCharacters(fromPostalCode)}
                      />
                    </Popover>
                  </Col>
                </Row>
                {resultAddressOptions.length > 0 && (
                  <Row className={styles.topSpacing} size={isTablet ? 12 : 7}>
                    <Col size={12}>
                      <Field
                        name={ChangeAddressFormFields.fromChooseAddress}
                        component={SelectInput}
                        label=""
                        placeholder={t('label_selected' as Messages)}
                        options={resultAddressOptions}
                        disabled={!resultAddressOptions.length}
                        onSelectChange={handleAddressChange}
                        selectClass={ClassNamesForOverwrite.SelectAddress}
                        onFocus={() => setPostCodeTabOut(false)}
                        hasErrorProp={assignedAddress === -1}
                      />
                    </Col>
                  </Row>
                )}

                <GridRow>
                  <GridCol className={styles.adresslink} size={9}>
                    {/* eslint-disable-next-line */}
                    <div
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setManualAddress(!manualAddress);
                        }
                      }}
                      onClick={() => setManualAddress(!manualAddress)}
                      //eslint-disable-next-line
                      tabIndex={0}
                    >
                      <Typography msg="Or enter address manually" tag="span" />
                    </div>
                  </GridCol>
                </GridRow>

                {manualAddress && (
                  <>
                    <GridRow>
                      <GridCol size={4}>
                        <Field
                          name={ChangeAddressFormFields.fromBuildingName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldBuildingName)}
                        />
                      </GridCol>
                      <GridCol size={4}>
                        <Field
                          name={ChangeAddressFormFields.fromBuildingNumber}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldBuildingNumber)}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={ChangeAddressFormFields.fromAddress1}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldAddress1)}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={ChangeAddressFormFields.fromAddress2}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldAddress2)}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={4}>
                        <Field
                          name={ChangeAddressFormFields.fromCity}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldCity)}
                        />
                      </GridCol>
                      <GridCol size={4}>
                        <Field
                          name={ChangeAddressFormFields.fromPostalCode}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldPostalCode)}
                        />
                      </GridCol>
                    </GridRow>
                  </>
                )}
              </div>

              <Typography tag="div" size="l" msg={t('title_address_change_to' as Messages)} />
              <Row className={styles.addressContainer} size={12}>
                <Col size={isTablet ? 12 : 7}>
                  <input
                    name={ChangeAddressFormFields.toAutoAddress}
                    type="text"
                    value={toPostalCode}
                    className={styles.input}
                    autoComplete="off"
                    onChange={(e: any) => {
                      setToPostalCode(e.currentTarget.value);
                      setShowToClearAddress(true);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode && validatePostalCodeCharacters(toPostalCode)) {
                        e.preventDefault();
                        getAddress(true);
                      }
                    }}
                    onBlur={() => {
                      if (validateMinLength(toPostalCode) && resultAddressOptions.length === 0) {
                        setToPostCodeTabOut(true);
                      } else {
                        setToPostCodeTabOut(false);
                      }
                    }}
                    placeholder={t('msg_search_by_postalcode' as Messages)}
                  />
                  {showToClearAddress && (
                    <Icon
                      icon="close-outline"
                      className={styles.visibilityToggleIcon}
                      onClick={() => {
                        setShowToClearAddress(false);
                        setToPostalCode('');
                        settoAddressResult([]);
                        setToAssignedAddress(-1);
                        setToPostCodeTabOut(false);
                      }}
                      size={IconSizes.s}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          setShowToClearAddress(false);
                          setToPostalCode('');
                          settoAddressResult([]);
                          setToAssignedAddress(-1);
                          setToPostCodeTabOut(false);
                        }
                      }}
                    />
                  )}
                </Col>
                <Col size={isTablet ? 12 : 5} className={classNames(styles.searchButtonContainer)}>
                  <Popover
                    showContent={toPostCodeTabOut}
                    position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                    title={t('title_postcode_warning' as Messages)}
                    message={t('desc_postcode_warning' as Messages)}
                  >
                    <Button
                      type={ButtonTypes.button}
                      style={ButtonStyles.transparent}
                      loading={toSearching}
                      label={t(Messages.buttonSearch)}
                      icon="search"
                      iconSize={IconSizes.sxs}
                      onClick={() => {
                        setToPostCodeTabOut(false);
                        getAddress(true);
                      }}
                      isFullWidth={true}
                      constant
                      disabled={!validatePostalCodeCharacters(toPostalCode)}
                    />
                  </Popover>
                </Col>
              </Row>
              {resultToAddressOptions.length > 0 && (
                <Row className={styles.topSpacing} size={12}>
                  <Col size={isTablet ? 12 : 7}>
                    <Field
                      name={ChangeAddressFormFields.toChooseAddress}
                      component={SelectInput}
                      label=""
                      placeholder={t('label_selected' as Messages)}
                      options={resultToAddressOptions}
                      disabled={!resultToAddressOptions.length}
                      onSelectChange={handleToAddressChange}
                      selectClass={ClassNamesForOverwrite.SelectAddress}
                      onFocus={() => setToPostCodeTabOut(false)}
                      hasErrorProp={toAssignedAddress === -1}
                    />
                  </Col>
                </Row>
              )}

              <GridRow>
                <GridCol className={styles.adresslink} size={9}>
                  {/* eslint-disable-next-line */}
                  <div
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        setToManualAddress(!toManualAddress);
                      }
                    }}
                    onClick={() => setToManualAddress(!toManualAddress)}
                    //eslint-disable-next-line
                    tabIndex={0}
                  >
                    <Typography msg="Or enter address manually" tag="span" />
                  </div>
                </GridCol>
              </GridRow>

              {toManualAddress && (
                <>
                  <GridRow>
                    <GridCol size={4}>
                      <Field
                        name={ChangeAddressFormFields.toBuildingName}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldBuildingName)}
                      />
                    </GridCol>
                    <GridCol size={4}>
                      <Field
                        name={ChangeAddressFormFields.toBuildingNumber}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldBuildingNumber)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={ChangeAddressFormFields.toAddress1}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldAddress1)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={12}>
                      <Field
                        name={ChangeAddressFormFields.toAddress2}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldAddress2)}
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={4}>
                      <Field
                        name={ChangeAddressFormFields.toCity}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldCity)}
                      />
                    </GridCol>
                    <GridCol size={4}>
                      <Field
                        name={ChangeAddressFormFields.toPostalCode}
                        type="text"
                        component={TextInput}
                        label={t(Messages.fieldPostalCode)}
                      />
                    </GridCol>
                  </GridRow>
                </>
              )}
              <GridRow>
                <GridCol size={12}>
                  <Field
                    name={ChangeAddressFormFields.propertyType}
                    options={baseCaseProperties}
                    component={RadioButton}
                    label="What is the ownership status of their property?*"
                    required
                  />
                </GridCol>
                <GridCol size={6}>
                  <Typography msg={t('msg_ownership_status' as Messages)} size="l" tag="div" />
                </GridCol>
              </GridRow>
              <GridRow className={styles.topSpacing}>
                <GridCol size={10} className={styles.topSpacing}>
                  <Field
                    name={ChangeAddressFormFields.dateOfMove}
                    type="text"
                    component={DatePickerInput}
                    parse={normalizeDate(currentLanguage)}
                    label={t('label_date_of_move' as Messages)}
                    maxDate={currentDateTime().toDate()}
                    required
                  />
                </GridCol>
              </GridRow>
              <AutoToggleForm />
            </div>
            <NavigationBlock
              isSubmitting={isSubmitting}
              back={back}
              next={next}
              setSubpage={setSubpage}
              hideSkip={true}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangeAddressForm;
