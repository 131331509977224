import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import { EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UpdateUserMutation, UpdateUserMutationVariables, UserLandingPage } from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { DeathCaseSubpages } from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { LifeCaseSubpages } from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';
import { TransUnionSubpages } from '@Routes/settings/IdValidationPage/TransUnionSteps/transUnionSubPages';
import { getUser } from '@Store/auth/auth.selector';

import styles from './NavigationBlock.scss';

export interface NavigationProps {
  back: DeathCaseSubpages | LifeCaseSubpages | TransUnionSubpages | null;
  next: DeathCaseSubpages | LifeCaseSubpages | TransUnionSubpages | null;
  setSubpage: (subpage: string) => void;
}

export interface NavigationBlockProps extends NavigationProps {
  isSubmitting: boolean;
  isDisabled?: boolean;
  hideSkip?: boolean;
  SaveButton?: React.ReactNode;
  nextButtonTooltipMsg?: string;
  addClickFunctionality?: boolean;
  backUrl?: string;
  nextButtonSize?: number;
}

type nullOrSubpage<T extends string> = null | T;

export type SubpageConfig<T extends string> = { [key in T]: React.ReactNode };

export type NavigationConfig<T extends string> = {
  [key in T]: { back: nullOrSubpage<T>; next: nullOrSubpage<T> };
};

const NavigationBlock: React.FunctionComponent<NavigationBlockProps> = (props) => {
  const {
    isDisabled,
    isSubmitting,
    back,
    next,
    hideSkip,
    nextButtonTooltipMsg = '',
    setSubpage,
    SaveButton,
    addClickFunctionality,
    backUrl,
    nextButtonSize = 7,
  } = props;
  const t = useTranslations();
  const history = useHistory();
  const user = useSelector(getUser);
  const queryString = history.location.search;
  const searchParams = new URLSearchParams(queryString);
  const dcRetargetParam = searchParams.get('retarget') || undefined;
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: () => {
      history.push(`${backUrl}${queryString}`);
    },
  });

  const NextButton = () => {
    if (addClickFunctionality && !!next) {
      return (
        <Button
          size={ButtonSizes.fill}
          type={ButtonTypes.button}
          onClick={() => setSubpage(next)}
          disabled={isDisabled || isSubmitting}
          label={t(Messages.buttonNext)}
          isFullWidth
        />
      );
    }
    if (next) {
      return (
        <Button
          size={ButtonSizes.fill}
          type={ButtonTypes.submit}
          disabled={isDisabled || isSubmitting}
          loading={isSubmitting}
          label={t(Messages.buttonNext)}
          isFullWidth
        />
      );
    }
    return null;
  };

  return !isTablet ? (
    <>
      <Row size={12} justifyBetween alignCenter className={styles.navigationContainer} constant>
        {/* <Col size={2}>{!!back && <LinkButton linkTitle={t(Messages.buttonBack)} onClick={() => setSubpage(back)} />}</Col> */}
        {!!back && (
          <Col size={5} className={classNames(styles.searchButtonContainer)}>
            <Button
              size={ButtonSizes.fill}
              type={ButtonTypes.button}
              style={ButtonStyles.outlined}
              label="Go back"
              icon="chevronLeft"
              className={classNames(styles.searchButton)}
              iconSize={IconSizes.ss}
              isFullWidth
              onClick={() => setSubpage(back)}
            />
          </Col>
        )}

        {!!backUrl && (
          <Col size={5} className={classNames(styles.searchButtonContainer)}>
            <Button
              size={ButtonSizes.fill}
              type={ButtonTypes.button}
              style={ButtonStyles.outlined}
              label="Go back"
              icon="chevronLeft"
              iconSize={IconSizes.ss}
              className={classNames(styles.searchButton)}
              isFullWidth
              onClick={() => {
                if (dcRetargetParam && user?.landingPage === UserLandingPage.RetargetDeathCase) {
                  updateUser({
                    variables: {
                      input: {
                        id: user ? user?.id : '',
                        isFirstLogin: true,
                      },
                    },
                  });
                } else {
                  history.push(`${backUrl}${queryString}`);
                }
              }}
            />
          </Col>
        )}

        <Col size={nextButtonSize}>
          <Tooltip message={nextButtonTooltipMsg} position={TooltipPosition.top}>
            {SaveButton || NextButton()}
          </Tooltip>
        </Col>
      </Row>
      <Row size={12} justifyEnd alignCenter className={styles.skipContainer} constant>
        <Col size={7} className="p-l-sm">
          {!!next && !hideSkip && (
            <LinkButton
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  setSubpage(next);
                }
              }}
              linkTitle="Skip this step for now"
              onClick={() => setSubpage(next)}
            />
          )}
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row size={12} alignCenter className={classNames(styles.navigationContainer, styles.bottomSpacing)} constant>
        <Col size={!isTablet ? nextButtonSize : 10}>
          <Tooltip message={nextButtonTooltipMsg} position={TooltipPosition.top}>
            {SaveButton || NextButton()}
          </Tooltip>
        </Col>
      </Row>
      {!!next && !hideSkip && (
        <Row size={12} alignCenter justifyCenter={isMobile} className={styles.tabletSkipContainer} constant>
          {!isMobile && <Col size={3} />}
          <Col>
            <LinkButton
              linkTitle="Skip this step for now"
              onClick={() => setSubpage(next)}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  setSubpage(next);
                }
              }}
            />
          </Col>
        </Row>
      )}
      <Row>
        {!!back && (
          <Col size={10} className={classNames(styles.searchButtonContainer)}>
            <Button
              size={ButtonSizes.fill}
              type={ButtonTypes.button}
              style={ButtonStyles.outlined}
              label="Go back"
              icon="chevronLeft"
              className={classNames(styles.searchButton)}
              iconSize={IconSizes.ss}
              isFullWidth
              constant
              onClick={() => setSubpage(back)}
            />
          </Col>
        )}

        {!!backUrl && (
          <Col size={10} className={classNames(styles.searchButtonContainer)}>
            <Button
              size={ButtonSizes.fill}
              type={ButtonTypes.button}
              style={ButtonStyles.outlined}
              label="Go back"
              icon="chevronLeft"
              iconSize={IconSizes.ss}
              className={classNames(styles.searchButton)}
              isFullWidth
              constant
              onClick={() => {
                if (dcRetargetParam && user?.landingPage === UserLandingPage.RetargetDeathCase) {
                  updateUser({
                    variables: {
                      input: {
                        id: user ? user?.id : '',
                        isFirstLogin: true,
                      },
                    },
                  });
                } else {
                  history.push(`${backUrl}${queryString}`);
                }
              }}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default NavigationBlock;
