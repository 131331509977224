import { ExecutionResult } from 'graphql';

import ApolloClient from '@Graphql/graphqlClient';
import { DeathCaseInformationQuery, DeathCaseInformationQueryVariables } from '@Graphql/graphqlTypes.generated';
import { queryDeathCaseInformation } from '@Graphql/providers/queries';

export const deathCaseInformationQuery = async (input: DeathCaseInformationQueryVariables) => {
  const result: ExecutionResult<DeathCaseInformationQuery> = await ApolloClient.query<
    DeathCaseInformationQuery,
    DeathCaseInformationQueryVariables
  >({
    query: queryDeathCaseInformation,
    variables: {
      accessCode: input.accessCode,
      isStaff: input.isStaff ? input.isStaff : false,
    },
    fetchPolicy: 'network-only',
  });

  return result.data?.deathCaseInformation;
};
