import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import MobileNavigator from '@Components/application/MobileNavigator';
import UserLayoutNew from '@Components/layout/UserLayoutNew';
import LeftSidebar from '@Components/LeftSidebar';
import Stepper from '@Components/Stepper';
import Typography from '@Components/Typography';
import { EnterKeyCode, Links, ReactResponsiveQueries } from '@Config/constants';
import { FpHolderNode } from '@Graphql/graphqlTypes.generated';
import {
  LifeCaseSubpages,
  lifeCaseSubpagesConfig,
  LifeCaseSubpagesNew,
  LifeCaseSubpagesNewMenu,
} from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';

import styles from './LifeCasePage.scss';

export interface LifeCasePageProps {
  id?: string;
  preferred?: LifeCaseSubpagesNew;
  missing?: boolean;
}

const LifeCasePage: React.FunctionComponent<LifeCasePageProps> = ({ preferred, id, missing }) => {
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const chosenSubpage =
    preferred && lifeCaseSubpagesConfig[preferred as LifeCaseSubpagesNew]
      ? lifeCaseSubpagesConfig[preferred as LifeCaseSubpagesNew]
      : lifeCaseSubpagesConfig[LifeCaseSubpagesNew.General];

  const history = useHistory();
  const queryString = history.location.search;
  const refLifeCaseData: FpHolderNode = history.location.state as FpHolderNode;
  const [subpage, setSubpage] = React.useState(chosenSubpage);

  React.useEffect(() => {
    setSubpage(chosenSubpage);
  }, [chosenSubpage, setSubpage]);

  const RightPanelForm = (subpage as any).component;
  const { stepIndex = -1, totalSteps, back, next } = subpage as any;

  const setSubpageHandler = (page: LifeCaseSubpagesNew) => {
    const newPage = lifeCaseSubpagesConfig[page as LifeCaseSubpagesNew];
    history.push(
      generatePath(`${Links.lcEdit}${queryString}`, {
        id,
        subpage: newPage.current,
        missing,
      })
    );
    setSubpage(newPage);
  };

  const stepIndexClick = (selectedNumber: number) => {
    let newSubpage = LifeCaseSubpagesNew.General;
    if (subpage.current === LifeCaseSubpagesNew.General || subpage.current === LifeCaseSubpagesNew.GeneralStep2) {
      newSubpage = selectedNumber === 1 ? LifeCaseSubpagesNew.GeneralStep2 : LifeCaseSubpagesNew.General;
    }

    return history.push(
      generatePath(`${Links.lcEdit}${queryString}`, {
        id,
        subpage: newSubpage,
        missing,
      })
    );
  };

  const getRightPanel = () => (
    <>
      <Typography msg={subpage.title} tag="h3" size="xxl" />

      {stepIndex > -1 ? (
        <Stepper
          onKeyDown={(e: any, selectedNumber: number) => {
            if (e.keyCode === EnterKeyCode) {
              stepIndexClick(selectedNumber);
            }
          }}
          onClick={stepIndexClick}
          currentIndex={stepIndex}
          total={totalSteps}
        />
      ) : null}

      <RightPanelForm
        back={back}
        refLifeCaseData={refLifeCaseData}
        next={next}
        setSubpage={setSubpageHandler}
        containerClasses={styles.formContainer}
        isLifeCase
      />
    </>
  );

  const activeStepIdx = Object.values(LifeCaseSubpagesNewMenu).indexOf(subpage.step as LifeCaseSubpagesNewMenu);

  return (
    <UserLayoutNew
      leftSideComponent={
        isMobile ? (
          <MobileNavigator
            isLifeCase={true}
            activeStepIdx={activeStepIdx}
            steps={Object.values(LifeCaseSubpages)}
            title=""
            id={id}
            missing={missing}
          />
        ) : (
          <LeftSidebar
            activeStepIdx={activeStepIdx}
            subpages={LifeCaseSubpages}
            title=""
            id={id}
            isLifeCase
            missing={missing}
          />
        )
      }
      rightSideComponent={getRightPanel()}
      hasStepper
      hasSeparator
    />
  );
};

export default LifeCasePage;
