import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonStyles } from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryCollaboratorsList } from '@Graphql/collaborators/queries';
import { mutationDeleteCollaborator, mutationUpdateDC } from '@Graphql/dc/mutations';
import {
  CollaboratorNode,
  CollaboratorProRelationFe,
  CollaboratorRelationshipFe,
  DeathCaseUpdateMutation,
  DeathCaseUpdateMutationVariables,
  DeleteCollaboratorMutation,
  DeleteCollaboratorMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { updateDC } from '@Store/dc/dc.actions';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './KnownLegals.scss';

export interface KnownLegalsProps {
  legals: CollaboratorNode[];
  isLifeCase?: boolean;
  isWillAdmin?: boolean;
  caseId?: string;
}

const KnownLegals: React.FunctionComponent<KnownLegalsProps> = ({ legals, isLifeCase, isWillAdmin, caseId }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const user = useSelector(getUser);

  const [deleteCollaboratorMutation] = useMutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>(
    mutationDeleteCollaborator,
    {
      refetchQueries: [getOperationName(queryCollaboratorsList) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess('Contact deleted'));
      },
      onError: (error) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [updateDCMutation] = useMutation<DeathCaseUpdateMutation, DeathCaseUpdateMutationVariables>(mutationUpdateDC, {
    refetchQueries: [getOperationName(queryCollaboratorsList) || ''],
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const decideDeletion = (id: string) => {
    deleteCollaboratorMutation({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const deleteHandler = (id: string) => {
    dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () => decideDeletion(id),
            secondButtonText: 'Do not remove',
            buttonText: 'Remove',
            content:
              'Deleting this collaborator will mean they will not recieve any notificaitons or updates on the status of the death case and death notifications that are subsequently sent.',
            deleteMessage: 'Do you want to remove this collaborator anyway?',
          },
          title: 'Remove from death case?',
          modalSize: PaperWidths.m,
          isGrayPaper: true,
        },
      })
    );
  };

  const editHandler = (collaborator: CollaboratorNode) => {
    dispatch(
      showModal({
        type: ModalTypes.editCollaboratorFormModal,
        params: {
          data: {
            secondButtonText: t(Messages.buttonSave),
            buttonText: t(Messages.buttonCancel),
            collaborator,
            isLegalForm: !isWillAdmin,
            isLifeCase,
          },
          //title: t(Messages.modalEditCollaboratorTitle),
          //subtitle: t(Messages.modalEditCollaboratorSubtitle),
          modalSize: PaperWidths.m672,
          isNotificationModal: true,
        },
      })
    );
  };

  const chooseTitle = (relationshipType: string, idx: number) => {
    if (isLifeCase) {
      return t(`${Messages.labelRelationshipTypePrefix}${relationshipType.toLowerCase()}` as Messages);
    }
    if (isWillAdmin) {
      return `Administrator ${idx + 1}`;
    }
    return `Legal representation ${idx + 1}`;
  };

  return (
    <div className={styles.fullWidth}>
      {legals.map((legal, idx) => (
        <GrayPaper key={idx} width="width-full" className={styles.titleBottom}>
          <Row className={styles.titleBottom}>
            <Typography msg={chooseTitle(legal.relationship, idx)} tag="div" size="m" bold />
          </Row>
          <Row className={styles.relationshipGap}>
            {legal.isAlsoLegal && (
              <Typography
                className={styles.relationshipLayout}
                tag="span"
                msg={t(
                  `${
                    Messages.labelRelationshipPrefix
                  }${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
                )}
                size="s"
              />
            )}
            {legal.isAlsoExecutor && (
              <Typography
                className={styles.relationshipLayout}
                tag="span"
                msg={t(
                  `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages
                )}
                size="s"
              />
            )}

            {legal.isAlsoAdministrator && (
              <Typography
                className={styles.relationshipLayout}
                tag="span"
                msg={t(
                  `${
                    Messages.labelRelationshipPrefix
                  }${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
                )}
                size="s"
              />
            )}

            {legal.isAlsoOtherAdvisor && (
              <Typography
                className={styles.relationshipLayout}
                tag="span"
                msg={t(
                  `${
                    Messages.labelRelationshipPrefix
                  }${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
                )}
                size="s"
              />
            )}
          </Row>
          <Row size={12}>
            <Col size={9}>
              <Row column>
                <Col className={styles.infoBottom}>
                  <Typography msg={`${legal.firstName} ${legal.lastName}`} tag="div" size="m" />
                </Col>

                <Col className={styles.infoBottom}>
                  <Typography msg={legal.email} tag="div" size="m" />
                </Col>

                <Col className={styles.infoBottom}>
                  <Typography msg={legal.phone} tag="div" size="m" />
                </Col>

                <Col className={styles.infoBottom}>
                  <Typography
                    msg={t(`${Messages.labelPermissionTypePrefix}${legal.permission.toLowerCase()}` as Messages)}
                    tag="div"
                    size="m"
                  />
                </Col>
              </Row>
            </Col>
            {legal.relationship !== CollaboratorRelationshipFe.RegisteredLife && (
              <Col size={3}>
                <Row column>
                  <Col />
                  <Col className={styles.titleBottom}>
                    <Button
                      style={ButtonStyles.transparent}
                      onClick={() => editHandler(legal)}
                      icon="button-edit"
                      iconSize={IconSizes.ss}
                      constant
                      isFullWidth
                    >
                      <Typography msg={t(Messages.buttonEdit)} tag="span" />
                    </Button>
                  </Col>
                  <Col />
                  <Col textCenter>
                    <div
                      //eslint-disable-next-line
                      tabIndex={0}
                      className={styles.removeContainer}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          if (user?.email === legal.email && caseId) {
                            if (isWillAdmin) {
                              dispatch(
                                updateDC.started({
                                  id: caseId,
                                  isAlsoAdministrator: false,
                                  relationship: legal.relationship,
                                })
                              );
                            } else {
                              updateDCMutation({
                                variables: {
                                  input: {
                                    id: caseId,
                                    isAlsoLegal: false,
                                    isAlsoOtherAdvisor: false,
                                    relationship: legal.relationship,
                                  },
                                },
                              });
                            }
                          } else {
                            deleteHandler(legal.id);
                          }
                        }
                      }}
                      onClick={() => {
                        if (user?.email === legal.email && caseId) {
                          if (isWillAdmin) {
                            dispatch(
                              updateDC.started({
                                id: caseId,
                                isAlsoAdministrator: false,
                                relationship: legal.relationship,
                              })
                            );
                          } else {
                            updateDCMutation({
                              variables: {
                                input: {
                                  id: caseId,
                                  isAlsoLegal: false,
                                  isAlsoOtherAdvisor: false,
                                  relationship: legal.relationship,
                                },
                              },
                            });
                          }
                        } else {
                          deleteHandler(legal.id);
                        }
                      }}
                    >
                      <Row constant justifyCenter className={classNames(styles.topSpacing, styles.removeContainer)}>
                        <Col textCenter>
                          <Icon icon="trash" />
                        </Col>
                        <Col textCenter>
                          <Typography
                            className={styles.removeTextSpacing}
                            msg={t(Messages.buttonRemove)}
                            tag="div"
                            size="m"
                            color="red"
                            bold
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </GrayPaper>
      ))}
    </div>
  );
};

export default KnownLegals;
