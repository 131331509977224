import classNames from 'classnames';
import { FieldProps, useField } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import InputContainer from '@Components/form/inputs/InputContainer';
import Icon, { IconSizes } from '@Components/Icon';
import { EnterKeyCode } from '@Config/constants';
import { hasFieldError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { notificationSuccess } from '@Utils/notificationUtils';

import styles from './TextInput.scss';

export enum InputTypes {
  password = 'password',
  text = 'text',
  textArea = 'textarea',
  copy = 'copy',
  link = 'link',
}

export interface TextInputProps {
  placeholder: string;
  label: string;
  required?: boolean;
  showIcon?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  className?: string;
  withInputIcon?: boolean;
  type: InputTypes;
  fullWidth?: boolean;
  isAutoComplete?: boolean;
  boldLabel?: string;
  toggleBold?: boolean;
  infoMessage?: string;
  infoTitle?: string;
  onChange?: (e?: any) => void;
  onBlurChange?: (e?: any) => void;
  enteredValue?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  controlDescription?: string;
  labelBold?: boolean;
  increaseHeight?: string;
  disableResize?: boolean;
  helperText?: string;
}

interface TextInputPrivateProps extends TextInputProps, FieldProps {}

const TextInput: React.FunctionComponent<TextInputPrivateProps> = (props) => {
  const {
    helperText,
    label,
    required,
    placeholder,
    form,
    withInputIcon,
    type,
    fullWidth,
    className,
    boldLabel,
    toggleBold = false,
    infoMessage,
    infoTitle,
    onChange,
    onBlurChange,
    enteredValue,
    onBlur,
    onFocus,
    controlDescription,
    labelBold,
    increaseHeight,
    disableResize = false,
    ...restProps
  } = props;
  const [field, meta] = useField(props.field);
  const [inputType, changeInputType] = React.useState(type as string);
  const hasError = hasFieldError(meta, form);
  const isAutoCompleteEnabled = props.isAutoComplete ? 'on' : 'off';
  const dispatch = useDispatch();

  return (
    <InputContainer
      label={label}
      meta={meta}
      form={form}
      required={required}
      fullWidth={fullWidth}
      containerClass={styles.wrapper}
      showErrorIcon={true}
      boldLabel={boldLabel}
      toggleBold={toggleBold}
      infoMessage={infoMessage}
      infoTitle={infoTitle}
      controlDescription={controlDescription}
      labelBold={labelBold}
      helperText={helperText}
    >
      {withInputIcon && (
        <Icon className={classNames(styles.inputIcon, { [styles.red]: hasError })} size={IconSizes.sss} icon={type} />
      )}
      {type === 'textarea' && onBlurChange && (
        <textarea
          {...field}
          {...restProps}
          placeholder={placeholder}
          aria-label={placeholder}
          autoComplete="off"
          style={{ height: increaseHeight }}
          onBlur={(e: any) => onBlurChange(e)}
          className={classNames(styles.textArea, className, {
            [styles.error]: hasError,
            [styles.disableResize]: disableResize,
          })}
        />
      )}
      {type === 'textarea' && onChange && (
        <textarea
          {...field}
          {...restProps}
          placeholder={placeholder}
          aria-label={placeholder}
          autoComplete="off"
          // autoFocus
          style={{ height: increaseHeight }}
          // onFocus={(e: any) =>
          //   e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
          // }
          onChange={(e: any) => onChange(e)}
          className={classNames(styles.textArea, className, {
            [styles.error]: hasError,
            [styles.disableResize]: disableResize,
          })}
        />
      )}
      {type === 'textarea' && !onChange && !onBlurChange && (
        <textarea
          {...field}
          {...restProps}
          placeholder={placeholder}
          aria-label={placeholder}
          autoComplete="off"
          className={classNames(styles.textArea, className, { [styles.error]: hasError })}
        />
      )}
      {onChange && type !== 'textarea' && (
        <input
          {...field}
          {...restProps}
          placeholder={placeholder}
          aria-label={placeholder}
          type={inputType}
          value={enteredValue}
          onChange={(e: any) => onChange(e)}
          onBlur={() => onBlur && onBlur()}
          onFocus={() => onFocus && onFocus()}
          autoComplete={isAutoCompleteEnabled}
          className={classNames(styles.input, {
            [styles.error]: hasError,
            [styles.withInputIcon]: withInputIcon,
            [styles.disabled]: restProps.disabled,
          })}
        />
      )}
      {!onChange && type !== 'textarea' && (
        <input
          {...field}
          {...restProps}
          placeholder={placeholder}
          aria-label={placeholder}
          type={inputType}
          onBlur={() => onBlur && onBlur()}
          onFocus={() => onFocus && onFocus()}
          autoComplete={isAutoCompleteEnabled}
          className={classNames(styles.input, {
            [styles.error]: hasError,
            [styles.withInputIcon]: withInputIcon,
            [styles.disabled]: restProps.disabled,
          })}
        />
      )}
      {type === 'password' && (
        <Icon
          icon={inputType === 'password' ? 'eye' : 'eye-off'}
          className={classNames(styles.visibilityToggleIcon, { [styles.error]: hasError })}
          onClick={() => (inputType === 'password' ? changeInputType('text') : changeInputType('password'))}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              return inputType === 'password' ? changeInputType('text') : changeInputType('password');
            }
            return noop;
          }}
          size={IconSizes.s}
        />
      )}
      {type === 'copy' && (
        <Icon
          icon="copy"
          className={classNames(styles.visibilityToggleIcon, { [styles.error]: hasError })}
          onClick={() => {
            navigator.clipboard.writeText(field.value);
            dispatch(notificationSuccess('Copied to clipboard'));
          }}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              return navigator.clipboard.writeText(field.value);
            }
            return noop;
          }}
          size={IconSizes.s}
        />
      )}
      {type === 'link' && (
        <Icon
          icon="external-link"
          className={classNames(styles.visibilityToggleIcon, { [styles.error]: hasError })}
          onClick={() => window.open(`https://${field.value}/`, '_blank')}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              return window.open(`https://${field.value}/`, '_blank');
            }
            return noop;
          }}
          size={IconSizes.s}
        />
      )}
    </InputContainer>
  );
};

export default TextInput;
