import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import Button, { ButtonTypes } from '@Components/Button';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './TransUnionProcedure.scss';

export interface TransUnionProcedureProps extends NavigationProps {
  isYoti?: boolean;
  fromNotification?: boolean;
}

const TransUnionProcedure: React.FunctionComponent<TransUnionProcedureProps> = ({
  next,
  setSubpage,
  fromNotification = false,
}) => {
  const t = useTranslations();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const buttonContainerSize = fromNotification ? 12 : 2;

  return (
    <div id="divVerificationConsent">
      <div className={classNames({ [styles.transUnionIntro]: !fromNotification })}>
        <Typography msg={t(Messages.transUnionCheckHeader)} tag="h4" className={styles.transUnionHeader} />
        <Typography msg={t(Messages.transUnionSubTxt1)} tag="p" className={styles.transUnionInfo} />
        <Typography msg={t(Messages.transUnionSubTxt2)} tag="p" className={styles.transUnionInfo} />
        <Typography msg={t(Messages.transUnionSubTxt3)} tag="p" className={styles.transUnionInfo} />

        <Typography msg={t(Messages.msgUserVerificationConsent)} tag="p" className={styles.transUnionInfo} />
      </div>
      <Row className={classNames(styles.navigationContainer, { [styles.notificationForm]: fromNotification })} constant>
        <Col size={isTablet ? 10 : buttonContainerSize}>
          <Button
            type={ButtonTypes.button}
            isFullWidth={isTablet || fromNotification}
            onClick={() => {
              setSubpage(next || '');
            }}
            label="Continue"
          />
        </Col>
      </Row>
    </div>
  );
};

export default TransUnionProcedure;
