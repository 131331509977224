import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import LinkButton from '@Components/LinkButton';
import LoadingSpinner from '@Components/LoadingSpinner';
import Paper from '@Components/Paper';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { mutationChangeOwnership } from '@Graphql/collaborators/mutations';
import { queryCollaboratorsList } from '@Graphql/collaborators/queries';
import {
  ChangeOwnershipMutation,
  ChangeOwnershipMutationVariables,
  CollaboratorPermission,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { noop } from '@Utils/helpers';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './GenericModalBody.scss';

export interface GenericModalBodyProps {
  id: string;
  icon: string;
  title: string;
  bodyText: string;
  firstButtonMsg: string;
  secondButtonMsg?: string;
  isSecondButtonLink?: boolean;
  genericType: GenericModalType;
  iconSize?: IconSizes;
  extraParameter?: string;
  onClose?: () => void;
}

export enum GenericModalType {
  TransferOwnership = 'TransferOwnership',
}

const GenericModalBody: React.FunctionComponent<ModalProviderProps<GenericModalBodyProps>> = ({
  closeModal,
  modalData: {
    icon,
    id,
    title,
    bodyText,
    firstButtonMsg,
    secondButtonMsg = '',
    iconSize = IconSizes.m,
    extraParameter = '',
    onClose = noop,
  },
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [caseTransferOwnership, { loading }] = useMutation<ChangeOwnershipMutation, ChangeOwnershipMutationVariables>(
    mutationChangeOwnership,
    {
      refetchQueries: [getOperationName(queryCollaboratorsList) || ''],
      onCompleted: () => {
        dispatch(notificationSuccess(t('msg_ownership_transferred' as Messages)));
        closeModal();
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
        closeModal();
      },
    }
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  const permissionType = () => {
    if (extraParameter === CollaboratorPermission.FullAccess) {
      return CollaboratorPermission.FullAccess;
    }
    if (extraParameter === CollaboratorPermission.Owner) {
      return CollaboratorPermission.Owner;
    }
    return CollaboratorPermission.ReadOnly;
  };

  return (
    <Paper width="width-full" className={styles.container}>
      <Icon icon={icon} size={iconSize} />

      <Typography msg={title} tag="h4" size="xl" bold className={styles.bottomSpacing} />

      <Typography msg={bodyText} tag="div" size="l" className={styles.bottomSpacing} />
      <Button
        onClick={() => {
          if (id !== '') {
            caseTransferOwnership({
              variables: {
                input: {
                  id,
                  permission: permissionType(),
                },
              },
            });
          } else {
            closeModal();
          }
        }}
        label={firstButtonMsg}
        isFullWidth
      />

      <LinkButton
        textCenter
        className={styles.mt18}
        linkTitle={secondButtonMsg}
        onClick={() => {
          closeModal();
          onClose();
        }}
      />
    </Paper>
  );
};

export default GenericModalBody;
