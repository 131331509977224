import classNames from 'classnames';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon/Icon';
import Col from '@Components/layout/Col/Col';
import Row from '@Components/layout/Row/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { allStatus } from '@Config/constants';
import { UserNode, useCaseServiceProvidersQuery, useStaffUsersQuery } from '@Graphql/graphqlTypes.generated';
import StaffAssignee from '@Routes/dashboard/StaffPage/StaffAssignee/StaffAssignee';
import { NotificationColumns } from '@Routes/dashboard/StaffPage/StaffPage';
import { djangoDateFormat, formatDateForExchange, stringToDate } from '@Utils/dates';

import styles from '../../dashboard/StaffPage/StaffPage.scss';

export interface OtherNotificationsProps {
  baseCaseId: string;
  caseServiceProviderId: string;
}

const OtherNotifications: React.FunctionComponent<OtherNotificationsProps> = ({
  baseCaseId,
  caseServiceProviderId,
}) => {
  // const [filteredResultCount, setFilteredResultCount] = React.useState(0);
  const [globalFilterValue, setGlobalFilterValue] = React.useState('');
  const [sortField, setSortField] = React.useState('dueBy');
  const [sortOrder, setSortOrder] = React.useState<1 | -1 | null | undefined>(-1);
  //   const [filteredValues, setFilteredValues] = React.useState({
  //     deathCaseLink: [] as string[],
  //     spLink: [] as string[],
  //     notifierType: [] as string[],
  //     notifierChannel: [] as string[],
  //     assignee: [] as string[],
  //     status: [] as string[],
  //   });
  const staffUserData = useStaffUsersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const caseServiceData = useCaseServiceProvidersQuery({
    variables: {
      baseCase: baseCaseId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  //eslint-disable-next-line
  const filters = {
    deathCaseName: { value: null, matchMode: FilterMatchMode.IN },
    spName: { value: null, matchMode: FilterMatchMode.IN },
    notifierType: { value: null, matchMode: FilterMatchMode.IN },
    notificationChannel: { value: null, matchMode: FilterMatchMode.IN },
    staffAssigned: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.IN },
    filterDueBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    filterSentAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    filterResentAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    filterResponseDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    filterCompletedAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  };

  if (caseServiceData.loading || staffUserData.loading) {
    return <LoadingSpinner />;
  }

  const currentPageData = caseServiceData.data?.caseServiceProviders.edges
    ?.map((edge: any) => edge.node)
    .filter((x: any) => x.id !== caseServiceProviderId)
    .reverse();

  const notificationData = currentPageData?.map((node: any) => {
    return {
      deathCaseName: node.deceasedFirstname ? `${node.deceasedFirstname} ${node.deceasedSurname}` : '-',
      deathCaseLink: node.baseCaseHyperlink,
      spName: node.serviceProvider ? node.serviceProvider.name : '-',
      spLink: node.serviceProviderHyperlink,
      notifierName: node.notifier
        ? `${node.notifier.firstName} ${node.notifier.lastName} - ${node.notifier.relationship}`
        : '-',
      notifierLink: node.notifierHyperlink,
      notifierType: node.notifier ? node.notifier.accountType : '-',
      dueBy: node.dueBy ? node.dueBy : '',
      filterDueBy: node.dueBy ? stringToDate(formatDateForExchange(node.dueBy)) : null,
      status: node.status ? node.status : '-',
      sentAt: node.sentAt ? node.sentAt : '',
      filterSentAt: node.sentAt ? stringToDate(formatDateForExchange(node.sentAt)) : null,
      isSeen: node.isSeen ? node.isSeen : false,
      completedAt: node.completedAt ? node.completedAt : '',
      filterCompletedAt: node.completedAt ? stringToDate(formatDateForExchange(node.completedAt)) : null,
      accessCode: node.accessCode ? node.accessCode : '',
      ref: node.ref ? `NO${node.ref}` : '-',
      resentAt: node.resentAt ? node.resentAt : '',
      filterResentAt: node.resentAt ? stringToDate(formatDateForExchange(node.resentAt)) : null,
      latestComment: node.latestComment ? node.latestComment : '-',
      notificationChannel: node.notificationChannel ? node.notificationChannel : '-',
      assignee: node.assignee,
      notificationId: node.id,
      responseDate: node.responseDate ? node.responseDate : '',
      filterResponseDate: node.responseDate ? stringToDate(formatDateForExchange(node.responseDate)) : null,
      refHyperlink: node.refHyperlink,
      staffAssigned: node.staff ? `${node.staff.firstName} ${node.staff.lastName}` : 'Unassigned',
    } as NotificationColumns;
  });

  const onGlobalFilterChange = (e: any) => {
    const { value } = e.target;
    setGlobalFilterValue(value);
  };

  const filteredData = (): any[] => {
    const searchedData = notificationData?.filter((rowData: NotificationColumns) => {
      return (
        rowData.ref.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.deathCaseName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.spName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.notifierName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.notifierType.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.dueBy.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.responseDate.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.status.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.sentAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.resentAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.completedAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.accessCode.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.latestComment.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.notificationChannel.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
        rowData.staffAssigned.toLowerCase().includes(globalFilterValue.toLowerCase())
        // &&
        // (filteredValues.status.length === 0 || filteredValues.status.includes(rowData.status)) &&
        // (filteredValues.deathCaseLink.length === 0 || filteredValues.deathCaseLink.includes(rowData.deathCaseName)) &&
        // (filteredValues.spLink.length === 0 || filteredValues.spLink.includes(rowData.spName)) &&
        // (filteredValues.notifierChannel.length === 0 ||
        //   filteredValues.notifierChannel.includes(rowData.notificationChannel)) &&
        // (filteredValues.notifierType.length === 0 || filteredValues.notifierType.includes(rowData.notifierType)) &&
        // (filteredValues.assignee.length === 0 ||
        //   (rowData.assignee &&
        //     filteredValues.assignee.includes(`${rowData.assignee.firstName} ${rowData.assignee.lastName}`)))
      );
    });

    const newData = [...new Map(searchedData?.map((item: any) => [item.ref, item])).values()];
    // if (globalFilterValue !== '') {
    //   setFilteredResultCount(newData.length || 0);
    // } else {
    //     setFilteredResultCount(0);
    // }

    return newData;
  };

  const onSort = (event: any) => {
    setSortOrder(event.sortField !== sortField ? -1 : event.sortOrder);
    setSortField(event.sortField);
  };

  const renderDeathCaseMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={deathCaseLinkOptions}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        placeholder="Select Death Case Name"
        style={{ maxWidth: '14rem' }}
      />
    );
  };

  const deathCaseLinkOptions = Array.from(
    new Set(notificationData?.map((rowData: NotificationColumns) => rowData.deathCaseName))
  );
  const spLinkOptions = Array.from(new Set(notificationData?.map((rowData: NotificationColumns) => rowData.spName)));
  const notifierChannelOptions = Array.from(
    new Set(notificationData?.map((rowData: NotificationColumns) => rowData.notificationChannel))
  );
  const notifierTypeOptions = Array.from(
    new Set(notificationData?.map((rowData: NotificationColumns) => rowData.notifierType))
  );

  const renderSPLinkMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={spLinkOptions}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        placeholder="Select SP Name"
      />
    );
  };

  const renderNotificationChannelMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={notifierChannelOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select Notification Channel"
      />
    );
  };

  const renderNotificationTypeMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={notifierTypeOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select Notification Type"
      />
    );
  };

  const dataForSelect = (queryData: any) => {
    const defaultOption = [
      { label: 'Unassigned', value: '', labelDisplay: <div className={styles.unAssigned}>Unassigned</div> },
    ];
    const staffOptions = queryData?.map((item: UserNode) => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
        labelDisplay: '',
      };
    });
    return defaultOption.concat(staffOptions);
  };

  const staffUsersList = dataForSelect(
    staffUserData.data?.staffUsers.filter((u: any) => u.firstName !== '' && u.lastName !== '')
  );
  const staffOptions = Array.from(new Set(staffUsersList?.map((rowData: any) => rowData.label)));

  const renderAssigneeMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={staffOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select Assignee"
      />
    );
  };

  const renderStatusMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={options.value}
        options={allStatus}
        showSelectAll={false}
        showClear={false}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select Status"
      />
    );
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const hostName = window.location.hostname === 'app.lifeledger.com' ? 'api.lifeledger.com' : 'dev.api.lifeledger.com';

  return (
    <div className={styles.tableContainer}>
      <Row constant size={12}>
        <Col constant size={2} className={classNames(styles.relPosition)}>
          <input
            type="text"
            onChange={onGlobalFilterChange}
            className={styles.input}
            autoComplete="off"
            placeholder="Search"
            value={globalFilterValue}
          />
          <Icon icon="search" className={styles.visibilityToggleIcon} size={IconSizes.s} />
        </Col>
      </Row>

      {/* {filteredResultCount > 0 && (
        <Row className={styles.mb18}>
          <Typography msg="Filtered results:" tag="div" size="m" color="footerColor" />
          <Typography msg={filteredResultCount.toString()} color="footerColor" tag="div" size="m" />
        </Row>
      )} */}

      <DataTable
        value={filteredData()}
        id="notificationsTable"
        stripedRows
        // scrollHeight="490px"
        filters={filters}
        // removableSort
        // sortMode="multiple"
        // multiSortMeta={[{ field: 'dueBy', order: -1 }]}
        sortField={sortField}
        sortOrder={sortOrder}
        scrollable
        onSort={(e: any) => onSort(e)}
        rows={100}
        className={styles.fonts}
        filterDisplay="menu"
        //rowsPerPageOptions={[5, 10, 25, 50]}
        resizableColumns
        globalFilterFields={[
          'ref',
          'deathCaseName',
          'spName',
          'notifierName',
          'notifierType',
          'filterDueBy',
          'filterSentAt',
          'filterResentAt',
          'filterCompletedAt',
          'filterResponseDate',
          'responseDate',
          'status',
          'sentAt',
          'resentAt',
          'completedAt',
          'accessCode',
          'latestComment',
          'notificationChannel',
        ]}
        emptyMessage="No records found"
      >
        <Column
          field="ref"
          header="Ref"
          sortable
          frozen
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`https://${hostName}${rowData.refHyperlink}`}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.ref}
              </a>
            );
          }}
        />
        <Column
          field="deathCaseName"
          sortable
          frozen
          header="Death case link"
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`https://${hostName}${rowData.deathCaseLink}`}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.deathCaseName}
              </a>
            );
          }}
          style={{ minWidth: '15rem' }}
          filter
          filterField="deathCaseName"
          filterMenuStyle={{ width: '14rem' }}
          showFilterMenu={true}
          showFilterMatchModes={false}
          filterElement={renderDeathCaseMultiSelect}
        />
        <Column
          field="spName"
          sortable
          frozen
          header="SP link"
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`https://${hostName}${rowData.spLink}`}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.spName}
              </a>
            );
          }}
          style={{ minWidth: '15rem' }}
          filter
          filterField="spName"
          showFilterMenu={true}
          showFilterMatchModes={false}
          filterElement={renderSPLinkMultiSelect}
        />
        <Column
          header="Notification link"
          body={(rowData: any) => {
            return (
              <a
                className={styles.linkStyle}
                href={`${window.location.origin}/notification/${rowData.accessCode}`}
                target="_blank"
                rel="noreferrer"
              >
                Notification page link
              </a>
            );
          }}
        />
        <Column
          field="notifierName"
          sortable
          header="Notifier link"
          body={(rowData: any) => {
            return (
              <>
                {rowData.notifierName !== '-' ? (
                  <a
                    className={styles.linkStyle}
                    href={`https://${hostName}${rowData.notifierLink}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {rowData.notifierName}
                  </a>
                ) : (
                  <div>{rowData.notifierName}</div>
                )}
              </>
            );
          }}
        />
        <Column
          field="notifierType"
          sortable
          header="Notifier Type"
          filter
          filterField="notifierType"
          showFilterMenu={true}
          showFilterMatchModes={false}
          filterElement={renderNotificationTypeMultiSelect}
        />
        <Column
          field="notificationChannel"
          sortable
          header="Notification Channel"
          style={{ minWidth: '18rem' }}
          filter
          filterField="notificationChannel"
          showFilterMenu={true}
          showFilterMatchModes={false}
          filterElement={renderNotificationChannelMultiSelect}
        />
        <Column
          header="Assignee"
          field="staffAssigned"
          sortField="staffAssigned"
          body={(rowData: any) => {
            return <StaffAssignee data={rowData} staffUsersList={staffUsersList} />;
          }}
          style={{ minWidth: '15rem' }}
          filter
          filterField="staffAssigned"
          showFilterMenu={true}
          showFilterMatchModes={false}
          filterElement={renderAssigneeMultiSelect}
        />
        <Column
          field="dueBy"
          sortable
          header="Due by"
          filterField="filterDueBy"
          dataType="date"
          filter
          filterElement={dateFilterTemplate}
          showFilterMenu={true}
          body={(rowData: any) => {
            return rowData.dueBy ? djangoDateFormat(rowData.dueBy) : '-';
          }}
        />
        <Column
          field="responseDate"
          sortable
          header="Response date"
          filterField="filterResponseDate"
          dataType="date"
          filter
          filterElement={dateFilterTemplate}
          showFilterMenu={true}
          body={(rowData: any) => {
            return rowData.responseDate ? djangoDateFormat(rowData.responseDate) : '-';
          }}
        />
        <Column
          field="status"
          sortable
          header="Status"
          style={{ minWidth: '15rem' }}
          filter
          filterField="status"
          showFilterMenu={true}
          showFilterMatchModes={false}
          filterElement={renderStatusMultiSelect}
        />
        <Column
          field="sentAt"
          sortable
          header="Sent at"
          filterField="filterSentAt"
          dataType="date"
          filter
          filterElement={dateFilterTemplate}
          showFilterMenu={true}
          body={(rowData: any) => {
            return rowData.sentAt ? djangoDateFormat(rowData.sentAt) : '-';
          }}
        />
        <Column
          field="resentAt"
          sortable
          header="Resent at"
          filterField="filterResentAt"
          dataType="date"
          filter
          filterElement={dateFilterTemplate}
          showFilterMenu={true}
          body={(rowData: any) => {
            return rowData.resentAt ? djangoDateFormat(rowData.resentAt) : '-';
          }}
        />
        <Column
          field="isSeen"
          sortable
          header="Is Seen"
          body={(rowData: any) => {
            return (
              <Icon
                className={classNames({ [styles.closeIcon]: !rowData.isSeen })}
                icon={rowData.isSeen ? 'tick' : 'close'}
                size={IconSizes.sss}
              />
            );
          }}
        />
        <Column
          field="completedAt"
          sortable
          header="Completed at"
          filterField="filterCompletedAt"
          dataType="date"
          filter
          filterElement={dateFilterTemplate}
          showFilterMenu={true}
          body={(rowData: any) => {
            return rowData.completedAt ? djangoDateFormat(rowData.completedAt) : '-';
          }}
        />
        <Column field="accessCode" sortable header="Access code" />
        <Column
          field="latestComment"
          sortable
          header="Latest comment"
          body={(rowData: any) => {
            return (
              <div
                //eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: rowData.latestComment,
                }}
              />
            );
          }}
        />
      </DataTable>
    </div>
  );
};

export default OtherNotifications;
