import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { NodeDefiner, LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorRelationshipFe,
  DeathCaseNode,
  LifeCaseNode,
  useRefDeathByIdQuery,
  useRefLifeByIdQuery,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { updateDC } from '@Store/dc/dc.actions';
import { updateLC } from '@Store/lc/lc.actions';
import { manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { deleteFromLocalStorage, getFromLocalStorageString, setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import { validateAny, validateEmail, validateName, validatePhone } from '@Utils/validator';

import styles from './PartnerForm.scss';

export interface PartnerFormProps extends NavigationProps {
  containerClasses?: string;
  isLifeCase?: boolean;
}

export interface PartnersFormStateProps {
  data: LoadableItem<DeathCaseNode> | LoadableItem<LifeCaseNode>;
  user: UserNode | null;
}

export interface PartnerFormDispatchProps {
  updateRequest: typeof updateDC.started | typeof updateLC.started;
}

interface PartnerFormPrivateProps extends PartnerFormProps, PartnersFormStateProps, PartnerFormDispatchProps {}

enum PartnerFormFields {
  hasPartner = 'hasPartner',
  isUserPartner = 'isUserPartner',
  partnersFirstName = 'partnersFirstName',
  partnersLastName = 'partnersLastName',
  partnersEmail = 'partnersEmail',
  partnersPhone = 'partnersPhone',
  shouldPassPartnersUtility = 'shouldPassPartnersUtility',
  shouldNotifyPartner = 'shouldNotifyPartner',
}

type PartnerFormValues = {
  hasPartner: boolean;
  isUserPartner: boolean;
  partnersFirstName: string;
  partnersLastName: string;
  partnersEmail: string;
  partnersPhone: string;
  shouldPassPartnersUtility: boolean;
  shouldNotifyPartner: boolean;
};

type PartnerCollaboratorFields = Extract<
  PartnerFormFields,
  | PartnerFormFields.partnersFirstName
  | PartnerFormFields.partnersLastName
  | PartnerFormFields.partnersEmail
  | PartnerFormFields.partnersPhone
  | PartnerFormFields.shouldNotifyPartner
>;

const emptyFormValues = {
  [PartnerFormFields.partnersFirstName]: '',
  [PartnerFormFields.partnersLastName]: '',
  [PartnerFormFields.partnersEmail]: '',
  [PartnerFormFields.partnersPhone]: '+44',
  [PartnerFormFields.shouldNotifyPartner]: false,
};

const PartnerForm: React.FunctionComponent<PartnerFormPrivateProps> = ({
  user,
  data,
  updateRequest,
  back,
  next,
  setSubpage,
  containerClasses,
  isLifeCase,
}) => {
  const t = useTranslations();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const refDeathCaseDataId = getFromLocalStorageString(`${LocalStorage.referenceDeathCase}_${user?.firstName}`);
  const refLifeCaseDataId = getFromLocalStorageString(`${LocalStorage.referenceLifeCase}_${user?.firstName}`);
  const dispatch = useDispatch();
  const referCaseQueryData = useRefDeathByIdQuery({
    variables: {
      id: refDeathCaseDataId,
    },
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: refDeathCaseDataId === '',
  });

  const referLifeCaseQueryData = useRefLifeByIdQuery({
    variables: {
      id: refLifeCaseDataId,
    },
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: refLifeCaseDataId === '',
  });

  if (referCaseQueryData.loading || referLifeCaseQueryData.loading) {
    return <LoadingSpinner />;
  }

  const refDeathCaseData = referCaseQueryData.data?.refDeathById;
  const refLifeCaseData = referLifeCaseQueryData.data?.refLifeById;

  const userDataValues = {
    [PartnerFormFields.partnersFirstName]: user?.firstName,
    [PartnerFormFields.partnersLastName]: user?.lastName,
    [PartnerFormFields.partnersEmail]: user?.email,
    [PartnerFormFields.partnersPhone]: user?.phone,
    [PartnerFormFields.shouldNotifyPartner]: false,
  };

  const deathCasePartnerValues = {
    [PartnerFormFields.partnersFirstName]:
      data?.item?.partner?.firstName || emptyFormValues[PartnerFormFields.partnersFirstName],
    [PartnerFormFields.partnersLastName]:
      data?.item?.partner?.lastName || emptyFormValues[PartnerFormFields.partnersLastName],
    [PartnerFormFields.partnersEmail]: data?.item?.partner?.email || emptyFormValues[PartnerFormFields.partnersEmail],
    [PartnerFormFields.partnersPhone]: data?.item?.partner?.phone || emptyFormValues[PartnerFormFields.partnersPhone],
    [PartnerFormFields.shouldNotifyPartner]:
      !data?.item?.partner?.shouldNotify || emptyFormValues[PartnerFormFields.shouldNotifyPartner],
  };

  const referredDeathCasePartnerValues = {
    [PartnerFormFields.partnersFirstName]:
      refDeathCaseData?.partnerFirstName || refLifeCaseData?.partner.firstName || '',
    [PartnerFormFields.partnersLastName]: refDeathCaseData?.partnerLastName || refLifeCaseData?.partner.lastName || '',
    [PartnerFormFields.partnersEmail]: refDeathCaseData?.partnerEmail || refLifeCaseData?.partner.email || '',
    [PartnerFormFields.partnersPhone]: refDeathCaseData?.partnerPhone || refLifeCaseData?.partner.phone || '+44',
    [PartnerFormFields.shouldNotifyPartner]: false,
  };

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm, values } = useFormikContext();
    const { isUserPartner, hasPartner, shouldNotifyPartner, partnersEmail } = values as PartnerFormValues;

    React.useEffect(() => {
      const getFieldValue = (fieldName: PartnerCollaboratorFields) => {
        if (!hasPartner) {
          return emptyFormValues[fieldName];
        }
        const partnerDetails =
          refDeathCaseData !== undefined || refLifeCaseData !== undefined
            ? referredDeathCasePartnerValues[fieldName]
            : deathCasePartnerValues[fieldName];
        return isUserPartner ? userDataValues[fieldName] : partnerDetails;
      };

      const togglePartnerFormField = (fieldName: PartnerCollaboratorFields) => {
        setFieldValue(fieldName, getFieldValue(fieldName), false);
        setFieldTouched(fieldName, false, false);
      };

      if (!hasPartner) {
        setFieldValue(PartnerFormFields.isUserPartner, false);
        setFieldValue(PartnerFormFields.shouldPassPartnersUtility, false);
      }
      if (
        (!shouldNotifyPartner && data.item?.partner) ||
        (data.item?.partner && !data.item?.partner.shouldNotify) ||
        isUserPartner ||
        (!isUserPartner && partnersEmail === user?.email)
      ) {
        togglePartnerFormField(PartnerFormFields.partnersFirstName);
        togglePartnerFormField(PartnerFormFields.partnersLastName);
        togglePartnerFormField(PartnerFormFields.partnersEmail);
        togglePartnerFormField(PartnerFormFields.partnersPhone);
      } else {
        setFieldValue(PartnerFormFields.partnersEmail, '', false);
      }
      if (isUserPartner) {
        togglePartnerFormField(PartnerFormFields.shouldNotifyPartner);
      }
      //eslint-disable-next-line
    }, [setFieldValue, setFieldTouched, resetForm, hasPartner, isUserPartner, shouldNotifyPartner]);

    return null;
  };

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  setToLocalStorage(LocalStorage.lifeCaseId, id);

  const setNextStep = () => (next ? setSubpage(next) : {});

  const isYourself = (data?.item?.__typename === NodeDefiner.LifeCase && data?.item?.isSelf) || false;

  const label_caution_lc_partner = manipulateTranslations(
    t,
    'label_caution_lc_partner',
    'Completing this section is not essential to send notifications. However, we would recommend finishing it either now or later if accounts need to be switched.'
  );

  const label_caution_dc_partner = manipulateTranslations(
    t,
    'label_caution_dc_partner',
    'Completing this section is not essential to send notifications. However, we would recommend finishing it either now or later if accounts need to be switched.'
  );

  const validatePartnerEmail = (values: any) => {
    if (values.hasPartner) {
      if (values.isUserPartner) {
        return false;
      }
      return !values.shouldNotifyPartner;
    }
    return false;
  };

  const onFocus = () => {
    const emailInput = document.getElementById('emailInput');
    const cautionBox = document.getElementById('cautionBox');
    if (emailInput && cautionBox)
      emailInput.addEventListener('focus', () => {
        cautionBox.style.display = 'flex';
      });
    if (emailInput && cautionBox)
      emailInput.addEventListener('blur', () => {
        cautionBox.style.display = 'none';
      });
  };

  return (
    <>
      <Row size={isTablet ? 12 : 10} className={styles.notesBottomSpacing}>
        <CautionBox
          containerClassName={styles.cautionBox1}
          info={isLifeCase ? label_caution_lc_partner : label_caution_dc_partner}
          title={t(Messages.labelTip)}
        />
      </Row>
      <Formik<PartnerFormValues>
        initialValues={{
          [PartnerFormFields.hasPartner]: !!data?.item?.hasPartner,
          [PartnerFormFields.isUserPartner]: !!data?.item?.isUserPartner,
          [PartnerFormFields.partnersFirstName]: '',
          [PartnerFormFields.partnersLastName]: '',
          [PartnerFormFields.partnersEmail]: '',
          [PartnerFormFields.partnersPhone]: '+44',
          [PartnerFormFields.shouldPassPartnersUtility]: !!data?.item?.shouldPassPartnersUtility,
          [PartnerFormFields.shouldNotifyPartner]: data.item?.partner ? !data.item?.partner?.shouldNotify : false,
        }}
        validationSchema={() =>
          Yup.lazy((values: any) =>
            Yup.object({
              [PartnerFormFields.partnersFirstName]:
                values.hasPartner && !values.isUserPartner ? validateName(t) : validateAny(),
              [PartnerFormFields.partnersLastName]:
                values.hasPartner && !values.isUserPartner ? validateName(t) : validateAny(),
              [PartnerFormFields.partnersEmail]: validatePartnerEmail(values) ? validateEmail(t) : validateAny(),
              [PartnerFormFields.partnersPhone]:
                values.hasPartner && !values.isUserPartner ? validatePhone(t) : validateAny(),
            })
          )
        }
        onSubmit={(values, { setSubmitting, setStatus }) => {
          const submitValues = values.isUserPartner ? { ...values, ...emptyFormValues } : values;
          const { partnersPhone, partnersEmail, shouldNotifyPartner, ...restValues } = submitValues;
          if (
            !data.item?.isAlsoExecutor &&
            !data.item?.isAlsoLegal &&
            !data.item?.isAlsoAdministrator &&
            !data.item?.isAlsoOtherAdvisor &&
            data.item?.relationship === CollaboratorRelationshipFe.Partner &&
            !restValues.hasPartner
          ) {
            setSubmitting(false);
            dispatch(
              notificationError(
                isLifeCase
                  ? t('msg_life_partner_relationship_empty' as Messages)
                  : t('msg_partner_relationship_empty' as Messages)
              )
            );
          } else {
            updateRequest({
              setSubmitting,
              setStatus,
              setNextStep,
              id,
              partnersPhone: removeLeadingZeroAndSpaceInContact(partnersPhone),
              partnersEmail: partnersEmail.trim(),
              shouldNotifyPartner: !shouldNotifyPartner,

              ...restValues,
            });
          }
          deleteFromLocalStorage(`${LocalStorage.referenceDeathCase}_${user?.firstName}`);
        }}
      >
        {({ isSubmitting, status, values }) => {
          return (
            <Form>
              <div className={containerClasses}>
                <Row justifyBetween>
                  <Col size={5}>
                    <Field
                      name={PartnerFormFields.hasPartner}
                      component={ToggleSwitchInput}
                      label={
                        isLifeCase
                          ? 'Does the person whose life you are registering have a partner?'
                          : t('label_has_partner' as Messages)
                      }
                    />
                  </Col>
                </Row>
                {values.hasPartner && (
                  <>
                    {!isYourself && (
                      <Row justifyBetween>
                        <Col size={4}>
                          <Field
                            name={PartnerFormFields.isUserPartner}
                            component={ToggleSwitchInput}
                            label={isLifeCase ? 'Are you their partner?' : 'Are you the deceased’s partner?'}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col size={5}>
                        <Field
                          name={PartnerFormFields.partnersFirstName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldPartnersFirstName)}
                          disabled={values.isUserPartner}
                          placeholder="Enter first name(s)"
                        />
                      </Col>
                      <Col size={1} />
                      <Col size={5}>
                        <Field
                          name={PartnerFormFields.partnersLastName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldPartnersLastName)}
                          disabled={values.isUserPartner}
                          placeholder="Enter surname(s)"
                        />
                      </Col>
                    </Row>
                    <Row justifyBetween>
                      <Col size={8}>
                        <Field
                          name={PartnerFormFields.partnersEmail}
                          type="text"
                          id="emailInput"
                          component={TextInput}
                          label={t(Messages.fieldPartnersEmail)}
                          disabled={values.isUserPartner || values.shouldNotifyPartner}
                          placeholder={values.shouldNotifyPartner ? 'No email address' : 'Enter email address'}
                          onFocus={onFocus}
                        />

                        <CautionBox
                          containerClassName={styles.cautionBox}
                          info={manipulateTranslations(
                            t,
                            'label_email_caution',
                            "We'll be sending an email to this individual, inviting them to make an account so they can see the information you've given."
                          )}
                          id="cautionBox"
                        />
                        {!values.isUserPartner && (
                          <Field
                            name={PartnerFormFields.shouldNotifyPartner}
                            label={t('label_no_email_address' as Messages)}
                            component={Checkbox}
                            type={CheckBoxTypes.checkbox}
                          />
                        )}

                        {values.shouldNotifyPartner && (
                          <CautionBox
                            containerClassName={styles.yellowCautionBox}
                            info={t('label_no_email_warning_msg' as Messages)}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col size={8}>
                        <Field
                          name={PartnerFormFields.partnersPhone}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldPartnersPhone)}
                          disabled={values.isUserPartner}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col className={styles.notesContainer} size={8}>
                      <Typography msg={t(Messages.partnerNotifiedNotes)} tag="span" size="m" />
                    </Col> */}
                    </Row>
                    {/* <Row justifyBetween>
                      <Col size={12}>
                        <Field
                          name={PartnerFormFields.shouldPassPartnersUtility}
                          component={ToggleSwitchInput}
                          label={t(Messages.labelPartnerUtilitiesRequired)}
                          containerClassName={styles.whiteSpaceUnset}
                        />
                      </Col>
                    </Row> */}
                  </>
                )}
                <AutoToggleForm />
                <FormError formError={status} />
              </div>
              <NavigationBlock isSubmitting={isSubmitting} back={back} next={next} setSubpage={setSubpage} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PartnerForm;
