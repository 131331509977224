import classNames from 'classnames';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Col from '@Components/layout/Col/Col';
import Row from '@Components/layout/Row/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Typography from '@Components/Typography/Typography';
import {
  useAllNotificationsForStaffQuery,
  CaseServiceProviderStatus,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import StaffPage from '@Routes/dashboard/StaffPage/StaffPage';
import { logout } from '@Store/auth/auth.actions';
import { getUser } from '@Store/auth/auth.selector';

import styles from './StaffDashboardPage.scss';

export const NotificationStatuses = [
  CaseServiceProviderStatus.Sent,
  CaseServiceProviderStatus.InProgress,
  CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier,
  CaseServiceProviderStatus.InProgressAwaitingInformationFromSp,
  CaseServiceProviderStatus.InProgressAutoPopulationFailed,
];

const StaffDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [allStatusSelected, setAllStatusSelected] = React.useState(false);
  const [filteredResultCount, setFilteredResultCount] = React.useState(0);

  const [statusSelected, setStatusSelected] = React.useState(NotificationStatuses);
  const [staffNotificationsCount, setStaffNotificationsCount] = React.useState(0);

  const isStaff = user?.accountType === UserAccountType.Individual && user.isStaff;

  const caseData = useAllNotificationsForStaffQuery({
    variables: {
      status: allStatusSelected ? [] : statusSelected,
      first: 100,
    },
    fetchPolicy: 'network-only',
    skip: !isStaff,
    onCompleted: () => {
      setStaffNotificationsCount(caseData.data?.allNotificationsForStaff.totalCount || 0);
    },
  });

  const totalCount = (count: number) => {
    setFilteredResultCount(count);
  };

  const initiateDataLoad = (status: CaseServiceProviderStatus[], allStatus: boolean) => {
    setStatusSelected(status);
    setAllStatusSelected(allStatus);
  };

  return (
    <>
      <div className={classNames(styles.headerContainer, { [styles.pb30]: isStaff })}>
        <Row justifyBetween alignCenter>
          <Col>
            <Row alignCenter className={styles.gap3}>
              <Typography msg="Notifications" bold tag="h3" size="xxl" />
              <Typography
                className={styles.mt6}
                msg={`(${staffNotificationsCount.toLocaleString('en-GB')})`}
                tag="div"
                size="l"
                color="dividerColor"
              />
            </Row>
          </Col>
          <Col>
            <Typography
              className={styles.letterSpacing}
              underline
              onClick={() => dispatch(logout())}
              msg="Log out"
              size="l"
              tag="div"
              color="footerColor"
            />
          </Col>
        </Row>
      </div>

      {!caseData.loading && caseData.data && caseData.data.allNotificationsForStaff.totalCount > 0 ? (
        <StaffPage
          totalCountCallback={totalCount}
          filteredResultCount={filteredResultCount}
          initialData={caseData}
          statuses={statusSelected}
          allStatusSelected={allStatusSelected}
          statusCallback={initiateDataLoad}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default StaffDashboard;
