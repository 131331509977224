import { ExecutionResult } from 'apollo-link';

import ApolloClient from '@Graphql/graphqlClient';
import {
  DeleteDocumentMutation,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UploadUserDocumentPayload,
} from '@Graphql/graphqlTypes.generated';
import { mutationDeleteIdDocument, mutationUpdateUser, mutationUploadIdDocument } from '@Graphql/settings/mutations';

export const updateUserMutation = async (input: UpdateUserInput) => {
  const result: ExecutionResult<UpdateUserMutation> = await ApolloClient.mutate<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >({
    mutation: mutationUpdateUser,
    variables: {
      input,
    },
  });

  return result.data?.updateUser;
};

export const uploadIdDocumentsMutation = async (input: any) => {
  const result: ExecutionResult<UploadUserDocumentPayload> = await ApolloClient.mutate<any, any>({
    mutation: mutationUploadIdDocument,
    variables: {
      input,
    },
  });

  return result.data?.document;
};

export const deleteIdDocumentsMutation = async (input: any) => {
  const result: ExecutionResult<DeleteDocumentMutation> = await ApolloClient.mutate<any, any>({
    mutation: mutationDeleteIdDocument,
    variables: {
      input,
    },
  });

  return result;
};
