import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonTypes } from '@Components/Button';
import Footer from '@Components/Footer';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Container from '@Components/layout/Container';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import TitleWithSubtitle from '@Components/TitleWithSubtitle';
import { emptySelectorValue, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { NadReferredDeathRelationship } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { currentDateTime, normalizeDate } from '@Utils/dates';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateDate, validateEmail, validateName, validateSelector } from '@Utils/validator';

import headerStyles from '../../../components/Header/Header.scss';

import styles from './ReferredDeathCase.scss';

export interface ReferredDeathCaseProps {}

export enum RegisteredDeathCaseFields {
  customerEmail = 'customerEmail',
  customerFirstName = 'customerFirstName',
  customerLastName = 'customerLastName',
  deceasedFirstName = 'deceasedFirstName',
  deceasedLastName = 'deceasedLastName',
  deceasedDob = 'deceasedDob',
  dateOfDeath = 'dateOfDeath',
  dateOfFuneral = 'dateOfFuneral',
  relationship = 'relationship',
  // partnerFirstName = 'partnerFirstName',
  // partnerLastName = 'partnerLastName',
  // partnerEmail = 'partnerEmail',
}

// enum RequestMode {
//   cors =  "cors" , navigate = "navigate" , noCors = "no-cors",  sameOrigin = "same-origin"
//   }

const ReferredDeathCase: React.FunctionComponent<ReferredDeathCaseProps> = () => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();

  const activities = Object.values(NadReferredDeathRelationship).map((relationship) => ({
    label: relationship,
    value: relationship,
  }));

  const domainName = () => {
    const webDomain = window.location.hostname;
    if (webDomain === 'app.dev.lifeledger.com') {
      return 'https://app.dev.lifeledger.com/notify/add/';
    }
    if (webDomain === 'app.lifeledger.com') {
      return 'https://app.lifeledger.com/notify/add/';
    }
    return 'https://dev.api.lifeledger.com/notify/add/';
  };

  return (
    <>
      <Container style={{ marginTop: '30px' }}>
        <div className={headerStyles.logoContainer}>
          <a href={Links.lifeLedger}>
            <Icon icon="logo-full" size={IconSizes.logoFull} />
          </a>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleWithSubtitle title="Register a death case" subtitle="" />
          <Formik
            initialValues={{
              [RegisteredDeathCaseFields.customerEmail]: '',
              [RegisteredDeathCaseFields.customerFirstName]: '',
              [RegisteredDeathCaseFields.customerLastName]: '',
              [RegisteredDeathCaseFields.deceasedFirstName]: '',
              [RegisteredDeathCaseFields.deceasedLastName]: '',
              [RegisteredDeathCaseFields.deceasedDob]: '',
              [RegisteredDeathCaseFields.dateOfDeath]: '',
              [RegisteredDeathCaseFields.dateOfFuneral]: '',
              [RegisteredDeathCaseFields.relationship]: emptySelectorValue,
              // [RegisteredDeathCaseFields.partnerFirstName]: '',
              // [RegisteredDeathCaseFields.partnerLastName]: '',
              // [RegisteredDeathCaseFields.partnerEmail]: '',
            }}
            validationSchema={() => {
              return Yup.object({
                [RegisteredDeathCaseFields.customerEmail]: validateEmail(t),
                [RegisteredDeathCaseFields.customerFirstName]: validateName(t),
                [RegisteredDeathCaseFields.customerLastName]: validateName(t),
                [RegisteredDeathCaseFields.deceasedFirstName]: validateName(t),
                [RegisteredDeathCaseFields.deceasedLastName]: validateName(t),
                [RegisteredDeathCaseFields.deceasedDob]: validateDate(t, true),
                [RegisteredDeathCaseFields.dateOfDeath]: validateDate(t, true),
                [RegisteredDeathCaseFields.dateOfFuneral]: validateDate(t, true),
                [RegisteredDeathCaseFields.relationship]: validateSelector(t),
                // [RegisteredDeathCaseFields.partnerFirstName]: validateName(t),
                // [RegisteredDeathCaseFields.partnerLastName]: validateName(t),
                // [RegisteredDeathCaseFields.partnerEmail]: validateEmail(t),
              });
            }}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                cases: [
                  {
                    customer_id: Math.random().toString(),
                    customer_email: values.customerEmail.trim(),
                    customer_first_name: values.customerFirstName,
                    customer_last_name: values.customerLastName,
                    general: {
                      deceased_first_name: values.deceasedFirstName,
                      deceased_last_name: values.deceasedLastName,
                      deceased_dob: values.deceasedDob,
                      date_of_death: values.dateOfDeath,
                      date_of_funeral: values.dateOfFuneral,
                      relationship: values.relationship.value,
                    },
                    // partner: {
                    //   first_name: values.partnerFirstName,
                    //   last_name: values.partnerLastName,
                    //   email: values.partnerEmail,
                    // },
                    added_on: currentDateTime(),
                  },
                ],
              };
              const requestOptions = {
                method: 'POST',
                // mode: RequestMode.cors,
                headers: {
                  'x-client-token': 'key1',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              };
              fetch(domainName(), requestOptions).then((response) => {
                if (response.ok) {
                  setSubmitting(false);
                  dispatch(notificationSuccess('Death case added successfully'));
                } else {
                  dispatch(notificationError(response.statusText));
                  setSubmitting(false);
                }
              });
            }}
          >
            {({ isSubmitting, status }) => (
              <Form>
                <div>
                  <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.customerFirstName}
                        type="text"
                        component={TextInput}
                        label="Customer first name"
                        required
                      />
                    </GridCol>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.customerLastName}
                        type="text"
                        component={TextInput}
                        label="Customer last name"
                        required
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.customerEmail}
                        type="text"
                        component={TextInput}
                        label="Customer email"
                        required
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.deceasedFirstName}
                        type="text"
                        component={TextInput}
                        label="Deceased first name"
                        required
                      />
                    </GridCol>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.deceasedLastName}
                        type="text"
                        component={TextInput}
                        label="Deceased last name"
                        required
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.deceasedDob}
                        type="text"
                        component={DatePickerInput}
                        parse={normalizeDate(currentLanguage)}
                        label="Date of birth"
                        maxDateRequired={true}
                        required
                      />
                    </GridCol>

                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.dateOfDeath}
                        type="text"
                        component={DatePickerInput}
                        parse={normalizeDate(currentLanguage)}
                        label="Date of death"
                        maxDateRequired={true}
                        required
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.dateOfFuneral}
                        type="text"
                        component={DatePickerInput}
                        parse={normalizeDate(currentLanguage)}
                        label="Date of funeral"
                        maxDateRequired={true}
                        required
                      />
                    </GridCol>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.relationship}
                        options={activities}
                        type="text"
                        component={SelectInput}
                        label={t(Messages.fieldRelationship)}
                      />
                    </GridCol>
                  </GridRow>
                  {/* <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.partnerFirstName}
                        type="text"
                        component={TextInput}
                        label="Partner first name"
                        required
                      />
                    </GridCol>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.partnerLastName}
                        type="text"
                        component={TextInput}
                        label="Partner last name"
                        required
                      />
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol size={6}>
                      <Field
                        name={RegisteredDeathCaseFields.partnerEmail}
                        type="text"
                        component={TextInput}
                        label="Partner email"
                        required
                      />
                    </GridCol>
                  </GridRow> */}

                  <FormError formError={status} />
                </div>
                <GridRow className={styles.buttonSpacing}>
                  <Button type={ButtonTypes.submit} loading={isSubmitting} label={t(Messages.buttonSave)} />
                </GridRow>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ReferredDeathCase;
