import { LOCATION_CHANGE, push } from 'connected-react-router';
import { all, put, takeEvery, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { getGoogleTagManagerId } from '@Store/app/app.selector';

import { locationChange } from './navigation.actions';
import { NavigationActionPayload } from './navigation.types';

function* locationRedirect(action: Action<NavigationActionPayload>) {
  yield put(push(action.payload.path));
}

function* onNavigate() {
  const googleTagManagerId = select(getGoogleTagManagerId);
  if (googleTagManagerId) {
    const dataLayer = window.dataLayer || [];
    yield dataLayer.push({ event: 'pageview', 'content-name': window.location.pathname });
  }
}

export function* navigationSaga() {
  yield all([yield takeEvery(locationChange, locationRedirect), yield takeEvery(LOCATION_CHANGE, onNavigate)]);
}
