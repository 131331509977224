import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@Components/Button';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables,
  LifeCaseFragment,
  SubscriptionPaymentNode,
} from '@Graphql/graphqlTypes.generated';
import { mutationCancelSubscription } from '@Graphql/lc/mutations';
import { queryLCList } from '@Graphql/lc/queries';
import { queryUserSubscriptionPayments } from '@Graphql/payments/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { capitalize } from '@Utils/helpers';
import { cancelSubscriptionConfirmHandler, paymentUpgradeHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import styles from './LcSubscriptionCard.scss';

export interface LcSubscriptionCardProps {
  userSubscription: SubscriptionPaymentNode;
  caseData?: LifeCaseFragment;
}

const LcSubscriptionCard: React.FunctionComponent<LcSubscriptionCardProps> = ({ userSubscription }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const user = useSelector(getUser);

  const [cancelSubscription] = useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(
    mutationCancelSubscription,
    {
      refetchQueries: [getOperationName(queryUserSubscriptionPayments) || '', getOperationName(queryLCList) || ''],
      onCompleted: () => {},
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const firstTwoChars = `${userSubscription.subscription.baseCase.firstName
    .charAt(0)
    .toUpperCase()} ${userSubscription.subscription.baseCase.lastName.charAt(0).toUpperCase()}`;
  const name = `${capitalize(userSubscription.subscription.baseCase.title)} ${capitalize(
    userSubscription.subscription.baseCase.firstName
  )} ${capitalize(userSubscription.subscription.baseCase.lastName)}`;

  const isDiscovery = userSubscription.product.features[0].toLowerCase() === 'discovery';

  const isDiscoveryActive = () => {
    if (!isDiscovery) {
      return t('label_discovery_not_active' as Messages);
    }
    const multipleBanks = 'bank accounts connected';
    const oneBank = 'bank account connected';
    if (userSubscription.subscription.credits) {
      if (userSubscription.subscription.credits.totalObCredits > 0) {
        if (userSubscription.subscription.credits.remainingObCredits === 1) {
          return `1 ${oneBank}`;
        }
        if (userSubscription.subscription.credits.remainingObCredits === 0) {
          return `2 ${multipleBanks}`;
        }
        return `0 ${oneBank}`;
      }
      return `0 ${oneBank}`;
    }
    return `0 ${oneBank}`;
  };

  return (
    <div className={styles.container}>
      <Row className={styles.gap24} constant>
        <Col className={styles.personContainer}>
          <Typography msg={firstTwoChars} tag="div" size="lcx" color="white" bold className={styles.opace} />
        </Col>
        <Col>
          <Typography msg={name} tag="div" size="l" bold />
        </Col>
      </Row>
      <Row alignCenter>
        <Col size={1} className={styles.ml3} />
        <Col size={3}>
          <Typography msg="Account type" tag="div" size="m" color="footerColor" />
        </Col>
        <Col size={6}>
          <Typography
            msg={t(`label_subscription_type_${userSubscription.product.features[0].toLowerCase()}` as Messages)}
            tag="div"
            size="l"
            bold
          />
        </Col>
        <Col size={1}>
          <Typography
            msg={`${userSubscription.product.currencySymbol}${userSubscription.product.unitAmount}`}
            tag="div"
            size="l"
            bold
          />
        </Col>
      </Row>

      <Row className={styles.mt13} alignCenter>
        <Col size={1} className={styles.ml3} />
        <Col size={3}>
          <Typography msg="Discovery" tag="div" size="m" color="footerColor" />
        </Col>
        <Col size={6}>
          <Typography
            msg={isDiscoveryActive()}
            tag="div"
            size="l"
            bold
            color={!isDiscovery ? 'black' : 'successColor'}
          />
        </Col>
      </Row>

      <Row className={styles.mt13} alignCenter>
        <Col size={1} className={styles.ml3} />
        <Col size={3}>
          <Typography msg="Renewal date" tag="div" size="m" color="footerColor" />
        </Col>
        <Col size={6}>
          <Typography
            msg={languageBasedDate(userSubscription.subscription.subscriptionRenewal, currentLanguage)}
            tag="div"
            size="l"
            bold
          />
        </Col>
      </Row>

      {!isDiscovery && user?.enableDiscovery && (
        <Button
          onClick={() => {
            paymentUpgradeHandler(t, dispatch, userSubscription, true);
          }}
          icon="star"
          constant
          label="Upgrade to Discovery"
          isFullWidth
          iconSize={IconSizes.ss}
          className={styles.mt70}
        />
      )}

      <Typography
        msg="I would like to cancel this subscription"
        tag="div"
        size="l"
        textCenter
        tabIndex={0}
        color="footerColor"
        className={styles.mt8}
        onClick={() => {
          if (!isDiscovery) {
            dispatch(
              showModal({
                type: ModalTypes.confirmModal,
                params: {
                  data: {
                    onClose: () => {
                      cancelSubscription({
                        variables: {
                          input: {
                            subscription: userSubscription.subscription.id,
                          },
                        },
                      });
                    },
                    secondButtonText: t(Messages.buttonGoBack),
                    buttonText: t(Messages.labelYes),
                  },
                  title: 'Do you want to cancel the subscription?',
                  modalSize: PaperWidths.m,
                },
              })
            );
          } else {
            cancelSubscriptionConfirmHandler(dispatch, userSubscription);
          }
        }}
        onKeyDown={(e: any) => {
          if (e.keyCode === EnterKeyCode) {
            if (!isDiscovery) {
              dispatch(
                showModal({
                  type: ModalTypes.confirmModal,
                  params: {
                    data: {
                      onClose: () => {
                        cancelSubscription({
                          variables: {
                            input: {
                              subscription: userSubscription.subscription.id,
                            },
                          },
                        });
                      },
                      secondButtonText: t(Messages.buttonGoBack),
                      buttonText: t(Messages.labelYes),
                    },
                    title: 'Do you want to cancel the subscription?',
                    modalSize: PaperWidths.m,
                  },
                })
              );
            } else {
              cancelSubscriptionConfirmHandler(dispatch, userSubscription);
            }
          }
        }}
        underline
      />
    </div>
  );
};

export default LcSubscriptionCard;
