import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';

import Button, { ButtonTypes } from '@Components/Button';
import FileInputUser from '@Components/form/inputs/FileInputUser';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import LoadingSpinner from '@Components/LoadingSpinner';
import { NavigationProps } from '@Components/NavigationBlock';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { Links, LocalStorage, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  UserDocumentsDocumentType,
  IdEnhancedCheckMutation,
  IdEnhancedCheckMutationVariables,
  useDeathCasesQuery,
  useLifeCasesQuery,
  DeathCaseStatus,
  UserDocumentsNode,
  CaseServiceProviderNode,
} from '@Graphql/graphqlTypes.generated';
import { mutationUploadIdDocument, mutationIdEnhancedCheck } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { deleteIdDcoument } from '@Store/settings/settings.actions';
import { normaliseGqlError } from '@Utils/form';
import { deleteFromLocalStorage, getBooleanValueCookie, setToLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './SupportingDocuments.scss';

export interface SupportingDocumentsProps extends NavigationProps {
  isYoti?: boolean;
  fromNotification?: boolean;
  deathCaseServiceProviderId?: string;
  deathCaseServiceProvider?: CaseServiceProviderNode;
}

const SupportingDocuments: React.FunctionComponent<SupportingDocumentsProps> = ({
  back,
  next,
  setSubpage,
  fromNotification,
  deathCaseServiceProviderId,
  deathCaseServiceProvider,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const history = useHistory();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [userIdDocuments, setUserIdDocuments] = React.useState(user?.idDocuments || []);

  const deleteIdVerifyDocuments = (id: string) => {
    dispatch(deleteIdDcoument.started({ id }));
    const documentNotTobeRemoved = userIdDocuments.filter((doc: UserDocumentsNode) => doc.id !== id);
    setUserIdDocuments(documentNotTobeRemoved);
  };

  const [updateIdDocumentsMutation] = useMutation<any, any>(mutationUploadIdDocument, {
    onCompleted: (userDocuments: any) => {
      const existingDocs = userIdDocuments.concat(userDocuments.uploadIdDocument.document);
      setUserIdDocuments(existingDocs);
    },
  });

  // const tuCreationNavigation: NavigationConfig<TransUnionSubpages> = {
  //   [TransUnionSubpages.DecideComponent]: {
  //     back: null,
  //     next: TransUnionSubpages.Summary,
  //   },
  //   [TransUnionSubpages.Summary]: {
  //     back: TransUnionSubpages.DecideComponent,
  //     next: TransUnionSubpages.SupportingDocuments,
  //   },
  //   [TransUnionSubpages.SupportingDocuments]: {
  //     back: null,
  //     next: TransUnionSubpages.VerficationStatus,
  //   },
  //   [TransUnionSubpages.VerficationStatus]: { back: TransUnionSubpages.SupportingDocuments, next: null },
  // };

  // const firstButtonPage =
  //   tuCreationNavigation[TransUnionSubpages.Summary as keyof SubpageConfig<TransUnionSubpages>].back;

  const errorStatusHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.idValidationErrorModal,
        params: {
          data: {
            onClose: () => {
              const firstFail = getBooleanValueCookie(LocalStorage.idVerificationFirstFail);
              if (firstFail) {
                deleteFromLocalStorage(LocalStorage.idVerificationFirstFail);
                history.push(generatePath(Links.settings, { subpage: SettingsSubpages.IDValidation }));
              } else {
                setToLocalStorage(LocalStorage.idVerificationFirstFail, true);
                // setSubpage(firstButtonPage || '');
              }
            },
            isSettings: false,
            fromNotification,
            deathCaseServiceProviderId,
          },
          modalSize: PaperWidths.ml,
        },
      })
    );
  };

  const setNextStep = () => next && setSubpage(next);

  const [checkIdInTransunion] = useMutation<IdEnhancedCheckMutation, IdEnhancedCheckMutationVariables>(
    mutationIdEnhancedCheck,
    {
      // refetchQueries: [getOperationName(queryGetUser) || ''],
      onCompleted: (sucessMsg: any) => {
        setIsSubmitting(false);
        dispatch(notificationSuccess(t(sucessMsg.idEnhancedCheck.message)));
        if (sucessMsg.idEnhancedCheck.result !== 'Fail') {
          if (!fromNotification) {
            dispatch(
              showModal({
                type: ModalTypes.deathRegisterSuccessFormModal,
                params: {
                  data: {
                    caseId,
                    isLifeCase,
                    title: deathCaseServiceProvider?.serviceProvider.isUserValidationRequired
                      ? t('label_id_validation_successful' as Messages)
                      : t('label_id_doc_upload_success' as Messages),
                    bodyMsg: !isLifeCase
                      ? t(Messages.bodyMessageDeathNotification)
                      : t(Messages.bodyMessageLifeNotification),
                    firstButtonMsg: !isLifeCase
                      ? t(Messages.buttonMessageDeathNotification)
                      : t(Messages.buttonMessageLifeNotification),
                    makeCenter: true,
                    deathCaseServiceProviderId,
                    isVerificationSuccess: true,
                  },
                  title: '',
                  modalSize: PaperWidths.ml,
                  showCloseIcon: false,
                },
              })
            );
          } else {
            setNextStep();
          }
        } else {
          errorStatusHandler();
        }
      },
      onError: (error: any) => {
        setIsSubmitting(false);
        dispatch(notificationError(normaliseGqlError(error.message)));
        errorStatusHandler();
      },
    }
  );

  const deleteHandler = (id: string) => {
    if (fromNotification) {
      deleteIdVerifyDocuments(id);
    } else {
      dispatch(
        showModal({
          type: ModalTypes.confirmModal,
          params: {
            data: {
              onClose: () => deleteIdVerifyDocuments(id),
              secondButtonText: t(Messages.buttonGoBack),
              buttonText: t(Messages.labelYes),
            },
            title: t(Messages.modalDeleteIdVerifyDocument),
            subtitle: t(Messages.modalRemoveDocumentSubtitle),
            modalSize: PaperWidths.m,
          },
        })
      );
    }
  };

  const dcData = useDeathCasesQuery({
    variables: {
      status: [DeathCaseStatus.Approved, DeathCaseStatus.Active, DeathCaseStatus.Completed, DeathCaseStatus.Draft],
    },
    fetchPolicy: 'cache-and-network',
  });

  const lcData = useLifeCasesQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (dcData.loading) {
    return <LoadingSpinner />;
  }

  if (lcData.loading) {
    return <LoadingSpinner />;
  }

  const getCaseId = () => {
    if (dcData.data && dcData.data?.deathCases.edges.length > 0) {
      return dcData.data.deathCases.edges[0].node.id;
    }
    if (lcData.data && lcData.data?.lifeCases.edges.length > 0) {
      return lcData.data?.lifeCases.edges[0].node.id;
    }
    return '';
  };

  const caseId = getCaseId();

  const isLifeCase = dcData.data?.deathCases.totalCount === 0;

  const desktopContinueButton = fromNotification ? 12 : 4;

  return (
    <div id="divUploadDocs">
      <Typography msg={t(Messages.idCheckDetailsP1)} className={styles.bottomSpacing} tag="div" size="m" />
      <Typography msg={t(Messages.idCheckDetailsHeader)} className={styles.mb30} tag="div" bold size="l" />
      {/* <Typography msg={t(Messages.idCheckDetailsP2)} className={styles.mb30} tag="div" size="m" /> */}

      {/* <Typography msg={t(Messages.acceptableDocHeader)} className={styles.mb18} tag="div" bold size="l" />
      <ul>
        <li>
          <Typography msg={t(Messages.acceptableDocPassport)} tag="div" size="m" />
        </li>
        <li>
          <Typography msg={t(Messages.acceptableDocdl)} tag="div" size="m" />
        </li>
        <li>
          <Typography msg={t(Messages.acceptableDocBrp)} className={styles.mb30} tag="div" size="m" />
        </li>
      </ul> */}
      <div className={fromNotification ? styles.notificationForm : styles.fileContainer}>
        <FileInputUser
          onDrop={(files: File[]) =>
            updateIdDocumentsMutation({
              variables: {
                input: {
                  userId: user?.id,
                  documentType: UserDocumentsDocumentType.Passport,
                  files,
                },
              },
            })
          }
          onRemoveClick={deleteHandler}
          files={userIdDocuments}
        />
      </div>
      <Col
        className={classNames(styles.navigationContainer, { [styles.notificationContinueButton]: fromNotification })}
        size={isTablet ? 10 : desktopContinueButton}
      >
        <Button
          type={ButtonTypes.button}
          loading={isSubmitting}
          isFullWidth
          onClick={() => {
            if (userIdDocuments.length > 0) {
              setIsSubmitting(true);
              checkIdInTransunion({
                variables: {
                  input: {
                    serviceProviderId: deathCaseServiceProvider?.serviceProvider.id,
                  },
                },
              });
            } else {
              dispatch(notificationError(t('msg_tu_upload_document' as Messages)));
            }
          }}
          label="Continue"
        />
      </Col>

      {back && !fromNotification && (
        <Row size={6}>
          <LinkButton
            className={styles.backToPrevStep}
            linkTitle={t(Messages.backToPrevStep)}
            onClick={() => setSubpage(back)}
          />
        </Row>
      )}
    </div>
  );
};

export default SupportingDocuments;
