import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { Cookies } from 'react-cookie-consent';
import { useMediaQuery } from 'react-responsive';
import Switch from 'react-switch';

import Button from '@Components/Button';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { noop } from '@Utils/helpers';
import { getBooleanValueCookie, setToLocalStorage } from '@Utils/localStorage';

import { privacyCookie, essentialCookie, functionalCookie, analyticCookie } from '../../../model/CookieTypes';
import { ModalProviderProps } from '../Modal';

import styles from './CookieSettingsModalBody.scss';

export interface CookieSettingsModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
}

const CookieSettingsModalBody: React.FunctionComponent<ModalProviderProps<CookieSettingsModalBodyProps>> = ({
  modalData: { onClose = noop },
  closeModal,
}) => {
  const [analyticCookieRequired, setAnalyticCookieRequired] = useState(
    getBooleanValueCookie(LocalStorage.analyticsCookie)
  );
  const [functionalCookieRequired, setFunctionalCookieRequired] = useState(
    getBooleanValueCookie(LocalStorage.functionalCookie)
  );

  const [selectedCookieType, setSelectedCookieType] = useState(privacyCookie.type);
  const [selectedCookieRightPanel, setSelectedCookieRightPannel] = useState(privacyCookie);

  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const onSavePreferencesClick = () => {
    if (!analyticCookieRequired || !functionalCookieRequired) {
      Cookies.remove('_ga');
      Cookies.remove('_gat');
      Cookies.remove('_gat_UA-167375481-1');
      Cookies.remove('_gid');
      Cookies.remove('__zlcmid');
      Cookies.remove('_hjid');
      setToLocalStorage(LocalStorage.enablePerformanceCookie, false);
    } else {
      setToLocalStorage(LocalStorage.enablePerformanceCookie, true);
    }
    setToLocalStorage(LocalStorage.cookieConsent, true);
    onClose();
    closeModal();
  };

  const onAcceptAllClick = () => {
    setToLocalStorage(LocalStorage.enablePerformanceCookie, true);
    setToLocalStorage(LocalStorage.cookieConsent, true);
    setToLocalStorage(LocalStorage.analyticsCookie, true);
    setToLocalStorage(LocalStorage.functionalCookie, true);
    onClose();
    closeModal();
  };

  return (
    <>
      {!isMobile && (
        <div className={styles.fullContainer}>
          <div className={styles.leftSection}>
            <Row justifyCenter className={styles.bottomSpacing15}>
              <img
                width="44"
                height="50"
                src="https://lifeledger.com/wp-content/plugins/master-popups-cookieplus/assets/public/images/logo-privacy-center2.png"
                alt=""
              />
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(privacyCookie.type);
                setSelectedCookieRightPannel(privacyCookie);
              }}
              className={
                selectedCookieType === privacyCookie.type ? styles.selectedCookieContainer : styles.cookieTypeContainer
              }
            >
              {privacyCookie.name}
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(essentialCookie.type);
                setSelectedCookieRightPannel(essentialCookie);
              }}
              className={
                selectedCookieType === essentialCookie.type
                  ? styles.selectedCookieContainer
                  : styles.cookieTypeContainer
              }
            >
              {essentialCookie.name}
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(functionalCookie.type);
                setSelectedCookieRightPannel(functionalCookie);
              }}
              className={
                selectedCookieType === functionalCookie.type
                  ? styles.selectedCookieContainer
                  : styles.cookieTypeContainer
              }
            >
              {functionalCookie.name}
            </Row>
            <Row
              onClick={() => {
                setSelectedCookieType(analyticCookie.type);
                setSelectedCookieRightPannel(analyticCookie);
              }}
              className={
                selectedCookieType === analyticCookie.type ? styles.selectedCookieContainer : styles.cookieTypeContainer
              }
            >
              {analyticCookie.name}
            </Row>
            <Row
              onClick={() => window.open('https://lifeledger.com/cookies/?nab=1')}
              className={classNames(styles.cookieTypeContainer, styles.bottomSpacing30)}
            >
              How we use cookies
            </Row>
          </div>
          <div className={styles.rightSection}>
            {/* <Icon icon="close-gray" onClick={closeModal} className={styles.closeIcon} size={IconSizes.sss} /> */}
            <Row className={styles.titleContainer}>
              <Typography msg="Cookie Settings Center" tag="div" size="xl" className={styles.title} bold />
            </Row>
            <div className={styles.rightPanel}>
              <Typography className={styles.cookieTitle} msg={selectedCookieRightPanel.name} tag="h3" size="xxl" bold />
              <Typography
                msg={selectedCookieRightPanel.description}
                tag="p"
                size="xss"
                className={styles.bottomSpacing30}
              />
              <Row className={styles.bottomSpacing30} justifyEnd>
                {selectedCookieRightPanel === essentialCookie && (
                  <>
                    <Switch
                      onChange={() => noop}
                      checked={true}
                      disabled={true}
                      className={styles.switch}
                      height={25}
                    />
                    <Typography msg="Always Active" tag="div" size="xss" bold />
                  </>
                )}

                {selectedCookieRightPanel === functionalCookie && (
                  <>
                    <Switch
                      onChange={() => {
                        setFunctionalCookieRequired(!functionalCookieRequired);
                        setToLocalStorage(LocalStorage.functionalCookie, !functionalCookieRequired);
                      }}
                      checked={functionalCookieRequired}
                      height={25}
                      className={styles.switch}
                    />
                    <Typography msg={functionalCookieRequired ? 'On' : 'Off'} tag="div" size="xss" bold />
                  </>
                )}

                {selectedCookieRightPanel === analyticCookie && (
                  <>
                    <Switch
                      onChange={() => {
                        setAnalyticCookieRequired(!analyticCookieRequired);
                        setToLocalStorage(LocalStorage.analyticsCookie, !analyticCookieRequired);
                      }}
                      checked={analyticCookieRequired}
                      className={styles.switch}
                      height={25}
                    />
                    <Typography msg={analyticCookieRequired ? 'On' : 'Off'} tag="div" size="xss" bold />
                  </>
                )}
              </Row>
              {/* {selectedCookieRightPanel !== privacyCookie && ( */}
              <Row>
                <Button onClick={() => onSavePreferencesClick()} isFullWidth>
                  <Typography msg="Save Preferences" tag="div" size="xss" />
                </Button>
                <Button onClick={() => onAcceptAllClick()} className={styles.buttonSpacing} isFullWidth>
                  <Typography msg="Accept all cookies" tag="div" size="xss" />
                </Button>
              </Row>
              {/* )} */}
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <div className={styles.mobileLayout}>
            <Row alignCenter justifyCenter className={styles.bottomSpacing10}>
              <img
                width="44"
                height="50"
                src="https://lifeledger.com/wp-content/plugins/master-popups-cookieplus/assets/public/images/logo-privacy-center2.png"
                alt=""
              />
              <Typography msg="Cookie Settings Center" tag="div" size="xl" className={styles.title} bold />
            </Row>
            <Collapsible
              trigger={
                <Typography msg={privacyCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
              }
            >
              <div className={styles.contentSpacing}>
                <Typography className={styles.cookieTitle} msg={privacyCookie.name} tag="h3" size="xxl" bold />
                <Typography msg={privacyCookie.description} tag="p" size="xss" />
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <Typography msg={essentialCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
              }
            >
              <div className={styles.contentSpacing}>
                <Typography className={styles.cookieTitle} msg={essentialCookie.name} tag="h3" size="xxl" bold />
                <Typography msg={essentialCookie.description} tag="p" size="xss" />
                <Row constant className={styles.mt10}>
                  <Switch onChange={() => noop} checked={true} disabled={true} className={styles.switch} height={25} />
                  <Typography msg="Always Active" tag="div" bold size="xss" />
                </Row>
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <Typography msg={functionalCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
              }
            >
              <div className={styles.contentSpacing}>
                <Typography className={styles.cookieTitle} msg={functionalCookie.name} tag="h3" size="xxl" bold />
                <Typography msg={functionalCookie.description} tag="p" size="xss" />
                <Row constant className={styles.mt10}>
                  <Switch
                    onChange={() => {
                      setFunctionalCookieRequired(!functionalCookieRequired);
                      setToLocalStorage(LocalStorage.functionalCookie, !functionalCookieRequired);
                    }}
                    checked={functionalCookieRequired}
                    height={25}
                    className={styles.switch}
                  />
                  <Typography msg={functionalCookieRequired ? 'On' : 'Off'} tag="div" bold size="xss" />
                </Row>
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <Typography msg={analyticCookie.name} tag="div" size="xss" className={styles.mobileCookieHeader} />
              }
            >
              <div className={styles.contentSpacing}>
                <Typography className={styles.cookieTitle} msg={analyticCookie.name} tag="h3" size="xxl" bold />
                <Typography msg={analyticCookie.description} tag="p" size="xss" />
                <Row constant className={styles.mt10}>
                  <Switch
                    onChange={() => {
                      setAnalyticCookieRequired(!analyticCookieRequired);
                      setToLocalStorage(LocalStorage.analyticsCookie, !analyticCookieRequired);
                    }}
                    checked={analyticCookieRequired}
                    height={25}
                    className={styles.switch}
                  />
                  <Typography msg={analyticCookieRequired ? 'On' : 'Off'} tag="div" bold size="xss" />
                </Row>
              </div>
            </Collapsible>
            <Row
              onClick={() => window.open('https://lifeledger.com/cookies/?nab=1')}
              className={classNames(styles.mobileCookieHeader, styles.bottomSpacing30)}
            >
              <Typography msg="How we use cookies" tag="div" size="xss" />
            </Row>
            <Row constant justifyCenter className={styles.buttonDistance}>
              <Button label="Save Preferences" onClick={() => onSavePreferencesClick()} isFullWidth />

              <Button label="Accept all cookies" onClick={() => onAcceptAllClick()} isFullWidth />
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default CookieSettingsModalBody;
