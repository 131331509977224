import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useParams } from 'react-router';

import MobileNavigator from '@Components/application/MobileNavigator';
import UserLayoutNew from '@Components/layout/UserLayoutNew';
import LeftSidebar from '@Components/LeftSidebar';
import { ChangeAddressModalFields } from '@Components/Modal/ChangeAddressModalBody/ChangeAddressModalBody';
import Stepper from '@Components/Stepper';
import Typography from '@Components/Typography';
import { EnterKeyCode, Links, ReactResponsiveQueries } from '@Config/constants';
import { FpHolderNode } from '@Graphql/graphqlTypes.generated';

import styles from './LifeEventPage.scss';
import {
  LifeEventSubPages,
  LifeEventSubPagesForSomeone,
  LifeEventSubPagesMenu,
  LifeEventSubPagesSomeoneConfig,
  lifeEventSubPageConfig,
} from './LifeEventSubpages';

export interface LifeEventProps {
  id?: string;
  preferred?: LifeEventSubPages | LifeEventSubPagesForSomeone;
}

const LifeEventPage: React.FunctionComponent<LifeEventProps> = ({ preferred }) => {
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const history = useHistory();
  const queryString = history.location.search;
  const { type } = useParams();
  const isSelfChange = type === ChangeAddressModalFields.selfAddress;

  const refLifeCaseData: FpHolderNode = history.location.state as FpHolderNode;

  const chosenSelfSubpage =
    preferred && lifeEventSubPageConfig[preferred as LifeEventSubPages]
      ? lifeEventSubPageConfig[preferred as LifeEventSubPages]
      : lifeEventSubPageConfig[LifeEventSubPages.Address];

  const chosenSomeoneSubpage =
    preferred && LifeEventSubPagesSomeoneConfig[preferred as LifeEventSubPagesForSomeone]
      ? LifeEventSubPagesSomeoneConfig[preferred as LifeEventSubPagesForSomeone]
      : LifeEventSubPagesSomeoneConfig[LifeEventSubPagesForSomeone.PersonDetails];

  const chosenSubpage = isSelfChange ? chosenSelfSubpage : chosenSomeoneSubpage;

  const [subpage, setSubpage] = React.useState(chosenSubpage);

  React.useEffect(() => {
    setSubpage(chosenSubpage);
  }, [chosenSubpage, setSubpage]);

  const RightPanelForm = (subpage as any).component;
  const { stepIndex = -1, totalSteps, back, next } = subpage as any;

  const setSubpageHandler = (page: LifeEventSubPages | LifeEventSubPagesForSomeone) => {
    const newPage = isSelfChange
      ? lifeEventSubPageConfig[page as LifeEventSubPages]
      : LifeEventSubPagesSomeoneConfig[page as LifeEventSubPagesForSomeone];

    history.push(
      generatePath(`${Links.lifeEventEdit}${queryString}`, {
        subpage: newPage.current,
      })
    );
    setSubpage(newPage);
  };

  const stepIndexClick = (selectedNumber: number) => {
    let newSubpage = isSelfChange ? LifeEventSubPages.Address : LifeEventSubPagesForSomeone.PersonDetails;

    if (
      subpage.current === LifeEventSubPagesForSomeone.PersonDetails ||
      subpage.current === LifeEventSubPagesForSomeone.Address
    ) {
      newSubpage =
        selectedNumber === 1 ? LifeEventSubPagesForSomeone.Address : LifeEventSubPagesForSomeone.PersonDetails;
    }

    return history.push(
      generatePath(`${Links.dcEdit}${queryString}`, {
        subpage: newSubpage,
      })
    );
  };

  const getRightPanel = () => (
    <>
      <Typography msg={subpage.title} tag="h3" size="xxl" bold />

      {stepIndex > -1 && !isSelfChange ? (
        <Stepper
          onKeyDown={(e: any, selectedNumber: number) => {
            if (e.keyCode === EnterKeyCode) {
              stepIndexClick(selectedNumber);
            }
          }}
          onClick={stepIndexClick}
          currentIndex={stepIndex}
          total={totalSteps}
        />
      ) : null}

      <RightPanelForm
        back={back}
        refLifeCaseData={refLifeCaseData}
        next={next}
        setSubpage={setSubpageHandler}
        containerClasses={styles.formContainer}
        isLifeCase
      />
    </>
  );

  const activeStepIdx = Object.values(LifeEventSubPagesMenu).indexOf(subpage.step as LifeEventSubPagesMenu);

  return (
    <UserLayoutNew
      leftSideComponent={
        isMobile ? (
          <MobileNavigator
            activeStepIdx={activeStepIdx}
            steps={Object.values(LifeEventSubPages)}
            title=""
            // id={id}
          />
        ) : (
          <LeftSidebar
            activeStepIdx={activeStepIdx}
            subpages={LifeEventSubPages}
            title=""
            isLifeEvent
            // id={id}
          />
        )
      }
      rightSideComponent={getRightPanel()}
      hasStepper
      hasSeparator
    />
  );
};

export default LifeEventPage;
