import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import LoadingSpinner from '@Components/LoadingSpinner';
import { emptySelectorValue } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryAgentPortalUserInvitations, queryAgentPortalUsers } from '@Graphql/agent/queries';
import {
  AddInvitationMutation,
  AddInvitationMutationVariables,
  ServiceProviderPortalUserNode,
  PortalUpdateUserMutation,
  PortalUpdateUserMutationVariables,
  UserAccountType,
  useServiceProviderCompaniesQuery,
  PortalInvitationUpdateMutationVariables,
  PortalInvitationUpdateMutation,
  useServiceProviderQuery,
} from '@Graphql/graphqlTypes.generated';
import {
  mutationAddInvitation,
  mutationPortalInvitationUpdate,
  mutationPortalUpdateUser,
} from '@Graphql/serviceProvidersPortal/mutations';
import {
  queryProfessionalPortalUserInvitations,
  queryProfessionalPortalUsers,
  queryServiceProviderPortalUsers,
  queryServiceProviderUserInvitations,
} from '@Graphql/serviceProvidersPortal/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateAny, validateEmail, validateSelector } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import userStyles from './AddUser.scss';

export interface AddUserProps {
  userNode?: ServiceProviderPortalUserNode;
  firstButtonText: string;
  isPending?: boolean;
}

enum AddUserFields {
  email = 'email',
  isPortalAdmin = 'isPortalAdmin',
  serviceProvider = 'serviceProvider',
  //isMfaEnabled = 'isMfaEnabled',
}

const AddUser: React.FunctionComponent<ModalProviderProps<AddUserProps>> = ({
  modalData: { userNode, firstButtonText, isPending },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const onButtonClick = () => {
    closeModal();
  };

  const secondaryQueryName =
    user?.accountType === UserAccountType.Professional ? queryProfessionalPortalUsers : queryAgentPortalUsers;
  const secondaryQueryInvite =
    user?.accountType === UserAccountType.Professional
      ? queryProfessionalPortalUserInvitations
      : queryAgentPortalUserInvitations;

  const [addInvite] = useMutation<AddInvitationMutation, AddInvitationMutationVariables>(mutationAddInvitation, {
    refetchQueries: [
      getOperationName(
        user?.accountType === UserAccountType.ServiceProvider ? queryServiceProviderPortalUsers : secondaryQueryName
      ) || '',
      getOperationName(
        user?.accountType === UserAccountType.ServiceProvider
          ? queryServiceProviderUserInvitations
          : secondaryQueryInvite
      ) || '',
    ],
    onCompleted: (response: any) => {
      dispatch(notificationSuccess(t(response.addInvitation.message)));
      dispatch(closeModal());
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [updatePortalUser] = useMutation<PortalUpdateUserMutation, PortalUpdateUserMutationVariables>(
    mutationPortalUpdateUser,
    {
      refetchQueries: [
        getOperationName(
          user?.accountType === UserAccountType.ServiceProvider ? queryServiceProviderPortalUsers : secondaryQueryName
        ) || '',
        getOperationName(
          user?.accountType === UserAccountType.ServiceProvider
            ? queryServiceProviderUserInvitations
            : secondaryQueryInvite
        ) || '',
      ],
      onCompleted: (response: any) => {
        dispatch(notificationSuccess(t(response.portalUpdateUser.message)));
        dispatch(closeModal());
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [updatePortalInvitation] = useMutation<PortalInvitationUpdateMutation, PortalInvitationUpdateMutationVariables>(
    mutationPortalInvitationUpdate,
    {
      refetchQueries: [
        getOperationName(
          user?.accountType === UserAccountType.ServiceProvider ? queryServiceProviderPortalUsers : secondaryQueryName
        ) || '',
        getOperationName(
          user?.accountType === UserAccountType.ServiceProvider
            ? queryServiceProviderUserInvitations
            : secondaryQueryInvite
        ) || '',
      ],
      onCompleted: (response: any) => {
        dispatch(notificationSuccess(t(response.portalInvitationUpdate.message)));
        dispatch(closeModal());
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const providerInfo = useServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: user?.accountType !== UserAccountType.ServiceProvider,
    variables: {
      id: user?.serviceProvider || '',
    },
  });

  const spGroups = useServiceProviderCompaniesQuery({
    fetchPolicy: 'network-only',
    skip: user?.accountType !== UserAccountType.ServiceProvider,
  });

  if (spGroups.loading || providerInfo.loading) {
    return <LoadingSpinner />;
  }

  const extractServiceProviders = (data: any) => {
    const result: { label: string; value: string }[] = [];
    const uniqueSet = new Set<string>();
    //eslint-disable-next-line
    data?.serviceProviderCompanies.forEach((company: any) => {
      // Helper function to add unique entries
      const addUniqueEntry = (entry: { label: string; value: string }) => {
        const uniqueKey = `${entry.label}-${entry.value}`;
        if (!uniqueSet.has(uniqueKey)) {
          uniqueSet.add(uniqueKey);
          result.push(entry);
        }
      };

      // Add serviceProviderGroup info
      addUniqueEntry({
        label: company.serviceProviderGroup.name,
        value: company.serviceProviderGroup.id,
      });

      // Add serviceProviderCompany info
      addUniqueEntry({
        label: company.serviceProviderCompany.name,
        value: company.serviceProviderCompany.id,
      });

      // Add all serviceProvider nodes info
      company.serviceProvider.edges.forEach((edge: any) => {
        addUniqueEntry({
          label: edge.node.name,
          value: edge.node.id,
        });
      });
    });

    return result;
  };

  const serviceProviderOptions = extractServiceProviders(spGroups.data);

  const selectedOption =
    userNode?.serviceProvider && serviceProviderOptions
      ? serviceProviderOptions.find((sp: any) => sp.value === userNode.serviceProvider.id)
      : emptySelectorValue;

  return (
    <>
      <Formik
        initialValues={{
          [AddUserFields.email]: userNode?.info.email || '',
          [AddUserFields.isPortalAdmin]: userNode?.isAdmin || false,
          [AddUserFields.serviceProvider]: selectedOption,
          //[AddUserFields.isMfaEnabled]: userNode?.info.isMfaEnabled || false,
        }}
        validationSchema={Yup.object({
          [AddUserFields.email]: validateEmail(t),
          [AddUserFields.serviceProvider]:
            (serviceProviderOptions?.length ?? 0) > 1 ? validateSelector(t) : validateAny(),
        })}
        onSubmit={(values) => {
          if (userNode) {
            // if (user?.accountType === UserAccountType.ServiceProvider) {
            if (isPending) {
              updatePortalInvitation({
                variables: {
                  input: {
                    id: userNode.id,
                    isAdmin: values.isPortalAdmin,
                    serviceProviderId:
                      (serviceProviderOptions?.length ?? 0) > 1
                        ? values.serviceProvider?.value
                        : serviceProviderOptions?.[0]?.value,
                  },
                },
              });
            } else {
              updatePortalUser({
                variables: {
                  input: {
                    id: userNode.id,
                    isPortalAdmin: values.isPortalAdmin,
                    serviceProviderId:
                      (serviceProviderOptions?.length ?? 0) > 1
                        ? values.serviceProvider?.value
                        : serviceProviderOptions?.[0]?.value,
                  },
                },
              });
            }
            //}
          } else {
            addInvite({
              variables: {
                input: {
                  email: values.email.trim(),
                  isAdmin: values.isPortalAdmin,
                  serviceProvider:
                    (serviceProviderOptions?.length ?? 0) > 1
                      ? values.serviceProvider?.value
                      : serviceProviderOptions?.[0]?.value,
                  //  isMfaEnabled: values.isMfaEnabled,
                },
              },
            });
          }
        }}
      >
        {({ isSubmitting, status }) => (
          <Form className={userStyles.formContainer}>
            <GridRow>
              <GridCol size={10}>
                <Field
                  name={AddUserFields.email}
                  type="text"
                  component={TextInput}
                  disabled={!!userNode}
                  label={t(Messages.labelProviderEmailAddress)}
                />
              </GridCol>
            </GridRow>

            {(serviceProviderOptions?.length ?? 0) > 1 && (
              <GridRow>
                <GridCol size={10}>
                  <Field
                    name={AddUserFields.serviceProvider}
                    label={t('label_sp_level_of_access' as Messages)}
                    component={SelectInput}
                    options={serviceProviderOptions}
                  />
                </GridCol>
              </GridRow>
            )}

            <GridRow className={userStyles.providerRoleSection}>
              <GridCol size={12}>
                <Field
                  name={AddUserFields.isPortalAdmin}
                  label={t(Messages.labelProviderRole)}
                  component={ToggleSwitchInput}
                />
              </GridCol>
            </GridRow>

            {/* <GridRow className={userStyles.mfaEnabledSection}>
              <GridCol size={12}>
                <Field
                  name={AddUserFields.isMfaEnabled}
                  label={t(Messages.msgEnableMultiFactorAuthentication)}
                  component={ToggleSwitchInput}
                />
              </GridCol>
            </GridRow> */}

            <div className={userStyles.actions}>
              <GridRow fullWidth withRowGap>
                <GridCol size={7}>
                  <Button
                    label={firstButtonText}
                    size={ButtonSizes.fill}
                    type={ButtonTypes.submit}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </GridCol>

                <GridCol size={5}>
                  <Button
                    label={t(Messages.buttonCancel)}
                    size={ButtonSizes.fill}
                    style={ButtonStyles.white}
                    onClick={onButtonClick}
                  />
                </GridCol>
              </GridRow>
            </div>
            <FormError formError={status} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;
