import { useMutation } from '@apollo/react-hooks';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import ScrollableFeed from 'react-scrollable-feed';

import Paper from '@Components/application/GrayPaper';
import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import TextInput, { InputTypes } from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { LocalStorage, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  NotificationChatNode,
  CaseChatCreateMutation,
  CaseChatCreateMutationVariables,
  RestrictedCaseServiceProviderNode,
  NotificationChatChatType,
  NotificationChatUserType,
} from '@Graphql/graphqlTypes.generated';
import { mutationCaseChatCreate } from '@Graphql/providers/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { professionalAccessCode } from '@Store/provider/provider.actions';
import { currentDateTime, getDayMonth } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { plotMessageUserName } from '@Utils/helpers';
import { getFromLocalStorage } from '@Utils/localStorage';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './MessageBox.scss';

export interface MessageBoxProps {
  caseChats: any[];
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode;
  chatTitlePersonName: string;
  chatType: NotificationChatChatType;
  accessCode: string;
}

enum MessageBoxFields {
  message = 'message',
}

const MessageBox: React.FunctionComponent<MessageBoxProps> = ({
  caseChats,
  deathCaseServiceProvider,
  chatTitlePersonName,
  chatType,
  accessCode,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [data, setData] = useState<JSX.Element[]>([]);

  const [chats, setChats] = useState(caseChats);
  const [message, setMessage] = useState('');
  const userEmail = getFromLocalStorage(LocalStorage.providerEmail);

  const [createCaseChat] = useMutation<CaseChatCreateMutation, CaseChatCreateMutationVariables>(
    mutationCaseChatCreate,
    {
      onCompleted: () => {
        setChats([
          ...chats,
          {
            commentedAt: currentDateTime(),
            commentedBy: userEmail,
            chatText: message,
            commentedByName: deathCaseServiceProvider.serviceProvider.name,
            userType: chatType,
          },
        ]);
        dispatch(professionalAccessCode.started({ accessCode, isStaff: false }));
        dispatch(notificationSuccess('Message sent'));
      },
      onError: (apiError) => {
        dispatch(notificationError(normaliseGqlError(apiError.message)));
      },
    }
  );

  const sortChatOnDate = (newChat: any[]) => {
    return newChat.sort((a, b) => {
      const aDate = new Date(a.commentedAt);
      const bDate = new Date(b.commentedAt);
      if (aDate > bDate) return 1;
      if (aDate < bDate) return -1;
      return 0;
    });
  };

  React.useEffect(() => {
    const sortChatsDesc = sortChatOnDate(chats);

    const getLogo = (chat: NotificationChatNode) => {
      if (chat.userType === NotificationChatUserType.ServiceProvider) {
        return deathCaseServiceProvider.serviceProvider.logo !== '' ? (
          <Col textCenter className={styles.logoWrapper}>
            <img
              src={
                deathCaseServiceProvider.serviceProvider
                  ? deathCaseServiceProvider.serviceProvider.logo
                  : 'lifeledger-chat'
              }
              alt=""
            />
          </Col>
        ) : (
          <Col textCenter className={styles.personContainer}>
            <Typography
              className={styles.iconSpacing}
              msg={deathCaseServiceProvider.serviceProvider.name.charAt(0).toUpperCase()}
              tag="span"
              color="white"
              bold
              size="lcx"
            />
          </Col>
        );
      }
      if (chat.userType === NotificationChatUserType.Lifeledger) {
        return (
          <Col textCenter className={styles.logoWrapper}>
            <Icon icon="lifeledger-chat" />
          </Col>
        );
      }
      return (
        <Col textCenter className={styles.personContainer}>
          <Typography
            className={styles.iconSpacing}
            msg={plotMessageUserName(chat)}
            tag="span"
            color="white"
            bold
            size="lcx"
          />
        </Col>
      );
    };

    const renderChatMessages = (messageString: string) => {
      const splitMsgs = messageString.split(/\r?\n/);
      return splitMsgs.map((m: string, idx: number) => {
        if (m !== '') {
          const htmlString = `<div class=${styles.chatMessageText}>${m}</div>`;
          return ReactHtmlParser(htmlString);
        }
        return <br key={idx} />;
      });
    };

    const uploads = sortChatsDesc.map((chat: NotificationChatNode) => (
      <Row className={styles.bigBottomSpacing} key={chat.id} size={12}>
        {getLogo(chat)}
        <Col size={11}>
          <Row className={styles.chatMessageSpacing} column>
            <Col>
              <Typography
                msg={
                  chat.userType === NotificationChatUserType.ServiceProvider
                    ? `${deathCaseServiceProvider.serviceProvider.name}`
                    : chat.commentedByName
                }
                tag="span"
                size="s"
                bold
                className={styles.rightSpacing}
              />
              <Typography msg={getDayMonth(new Date(chat.commentedAt))} tag="span" size="s" color="footerColor" />
            </Col>
            {chat.chatSubject && (
              <Col className={styles.sendButtonSpacing}>
                <Typography msg={t(chat.chatSubject as Messages)} tag="span" size="m" bold />
              </Col>
            )}
            <Col>{renderChatMessages(chat.chatText)}</Col>
          </Row>
        </Col>
      </Row>
    ));

    setData(uploads);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, chats, deathCaseServiceProvider.serviceProvider.name, deathCaseServiceProvider.serviceProvider.logo]);

  const getUserEmailHandler = (queryMessage: string) => {
    dispatch(
      showModal({
        type: ModalTypes.userInputFormModal,
        params: {
          data: {
            onClose: () => {
              const emailId = getFromLocalStorage(LocalStorage.providerEmail);
              if (emailId) {
                createCaseChat({
                  variables: {
                    input: {
                      comment: queryMessage,
                      deathCaseProviderId: deathCaseServiceProvider.id,
                      commentedBy: emailId,
                      commentedByName: deathCaseServiceProvider.serviceProvider.name,
                      chatType,
                    },
                  },
                });
                setMessage(queryMessage);
                // showYotiMedia();
              }
            },
            secondButtonText: 'Send message',
            buttonText: t(Messages.buttonCancel),
            deathCaseServiceProvider,
          },
          title: t(Messages.modalUserInputtTitle),
          subtitle: t(Messages.modalUserInputSubtitle),
          modalSize: PaperWidths.l,
        },
      })
    );
  };

  const MessageInputForm = () => (
    <Formik
      initialValues={{
        [MessageBoxFields.message]: '',
      }}
      onSubmit={(values) => {
        if (values.message.trim() !== '') {
          // eslint-disable-next-line
          values.message = values.message.replace(/^\s+|\s+$/g, '');
          if (userEmail !== null) {
            setMessage(values.message);
            createCaseChat({
              variables: {
                input: {
                  comment: values.message,
                  deathCaseProviderId: deathCaseServiceProvider.id,
                  commentedBy: userEmail,
                  commentedByName: deathCaseServiceProvider.serviceProvider.name,
                  chatType,
                },
              },
            });
          } else {
            getUserEmailHandler(values.message);
          }
        } else {
          dispatch(notificationError(t(Messages.msgEnterMessage)));
        }
      }}
    >
      {({ status }) => (
        <>
          <Form>
            <div className={styles.messageBoxContainer}>
              <div className={styles.messageInputBox}>
                <Field
                  name={MessageBoxFields.message}
                  type={InputTypes.textArea}
                  component={TextInput}
                  className={styles.chatInputBox}
                  placeholder="Message"
                  fullWidth
                  required
                />
              </div>
              <div className={styles.buttonSpacing}>
                <Button type={ButtonTypes.submit} icon="paper-plane" iconSize={IconSizes.ssm} size={ButtonSizes.ss} />
              </div>
            </div>
            <FormError formError={status} />
          </Form>
        </>
      )}
    </Formik>
  );
  return (
    <Paper key={chatType} noInsideSpacing={true} width="width-full">
      <Row className={styles.header}>
        <CardTitle heading={`Chat with ${chatTitlePersonName}`} />
      </Row>
      <div className={styles.internalSpacing}>
        {data.length > 0 ? (
          <div id="chatMessagesContainer" className={styles.chatMessagesContainer}>
            <ScrollableFeed>
              {data}
              {/* <AlwaysScrollToBottom /> */}
            </ScrollableFeed>
          </div>
        ) : (
          <div className={styles.emptyMessageContainer}>
            <div className={styles.emptyMsgSpacing}>
              <Icon icon="message-square" />
            </div>
            <Row className={styles.mtop20} size={12} justifyCenter>
              <Col size={3} />
              <Col size={7} textCenter>
                <Typography
                  size="m"
                  tag="div"
                  msg="You currently have no messages. If you wish to send a message select a notification under Open chats, to the left, and then use the box below to add a message to that notification."
                  color="footerColor"
                />
              </Col>
              <Col size={3} />
            </Row>
          </div>
        )}
      </div>
      <div className={styles.internalSpacing}>
        <MessageInputForm />
      </div>
    </Paper>
  );
};

export default MessageBox;
